import { AddressData, AddressDetailsInterface } from '@galileo/models/Corporate/interfaces/Address'
import { CorpProfileAddress, BvdAddressDetails } from '@galileo/models/Corporate/interfaces/CorpReg'
import { appendAddressString, parseFixedFormatLine1 } from '@galileo/utilities/address.utility'
import { STREET_TYPES } from '@galileo/constants/streetTypes'
export const parseCorpProfileAddress = (corpProfileAddress: CorpProfileAddress): AddressData => {
  // Free format
  if (corpProfileAddress.freeFormat) {
    return {
      addressLine1: corpProfileAddress.freeFormat.addressLine1,
      addressLine2: corpProfileAddress.freeFormat.addressLine2 ?? '',
      addressLine3: corpProfileAddress.freeFormat.addressLine3 ?? '',
      postCode: corpProfileAddress.freeFormat.postalCode,
      state: corpProfileAddress.freeFormat.county ?? '',
      city: corpProfileAddress.freeFormat.place,
      address: '',
      streetname: '',
      streetnumber: '',
      streettype: '',
      unit: corpProfileAddress.freeFormat.unit ?? '',
    }
  }

  // Fixed format
  if (corpProfileAddress.fixedFormat) {
    const unit = corpProfileAddress.fixedFormat.unit ?? ''
    const streetNumber = corpProfileAddress.fixedFormat.streetNumber
    const streetName = corpProfileAddress.fixedFormat.streetName
    const streetType = corpProfileAddress.fixedFormat.streetType
    const line1 = parseFixedFormatLine1({ unit, streetNumber, streetName, streetType })
    // Optional keys
    const line2 = ''
    const state = corpProfileAddress.fixedFormat.state ?? ''

    const fixedAddress = {
      addressLine1: line1,
      addressLine2: line2,
      addressLine3: '',
      postCode: corpProfileAddress.fixedFormat.postalCode,
      state,
      city: corpProfileAddress.fixedFormat.place,
      address: '',
      streetname: corpProfileAddress.fixedFormat.streetName,
      streetnumber: corpProfileAddress.fixedFormat.streetNumber,
      streettype: corpProfileAddress.fixedFormat.streetType,
      unit,
    }
    return fixedAddress
  }
  return {} as AddressData
}

export const parseAddressDetails = (
  addressDetails: AddressDetailsInterface
): AddressData => {
  const addressObject: AddressData = {
    // Free format
    addressLine1: addressDetails.addressLine1,
    addressLine2: addressDetails.addressLine2,
    addressLine3: addressDetails.addressLine3,
    // Fixed format
    streetname: '', // this is set below
    streettype: '', // this is set below
    streetnumber: addressDetails.buildingNumber,
    unit: addressDetails.subBuilding,
    state: addressDetails.provinceName,
    city: addressDetails.city,
    // Shared
    postCode: addressDetails.postCode,
    address: '',
  }

  const streetInfo = extractStreetNameAndStreetType(addressDetails.street)
  addressObject.streettype = streetInfo.streetType
  addressObject.streetname = streetInfo.streetName

  return addressObject
}

export const getOneLineAddress = (addressObj: AddressData, countryCode: string): string => {
  let oneLineAddress = ''
  // scenario 1: address with free format
  oneLineAddress = appendAddressString(oneLineAddress, addressObj.addressLine1, '')
  oneLineAddress = appendAddressString(oneLineAddress, addressObj.addressLine2, ' ')
  oneLineAddress = appendAddressString(oneLineAddress, addressObj.addressLine3, ' ')

  // Scenario 2: address with fixed format
  if (!oneLineAddress.includes(addressObj.unit)) {
    oneLineAddress = appendAddressString(oneLineAddress, addressObj.unit, ' ')
  }
  if (
    addressObj.streetnumber &&
    addressObj.streetnumber.length > 0 &&
    !oneLineAddress.includes(addressObj.streetnumber)
  ) {
    oneLineAddress = appendAddressString(oneLineAddress, addressObj.streetnumber, '-')
  }
  if (
    addressObj.streetnumber &&
    addressObj.streetname.length > 0 &&
    !oneLineAddress.includes(addressObj.streetname)
  ) {
    oneLineAddress = appendAddressString(oneLineAddress, addressObj.streetname, ' ')
  }
  oneLineAddress = appendAddressString(oneLineAddress, addressObj.suburb ?? '')

  // Shared scenarios
  oneLineAddress = appendAddressString(oneLineAddress, addressObj.city)
  oneLineAddress = appendAddressString(oneLineAddress, addressObj.state)

  // Capitalize text
  oneLineAddress = oneLineAddress
    .replace(/^\s+|\s+$/g, '')
    .toLocaleLowerCase()
    .replace(/^\w|\s\w/g, (letter) => letter.toUpperCase())

  if (oneLineAddress.length > 0) {
    oneLineAddress = appendAddressString(oneLineAddress, countryCode.toUpperCase())
    oneLineAddress = appendAddressString(oneLineAddress, addressObj.postCode)
  }

  return oneLineAddress
}

export const mapBvdAddressToCorpProfileAddress = (
  selectedAddress: BvdAddressDetails,
  country: string
) => {
  const streetInfo = extractStreetNameAndStreetType(selectedAddress.street)

  const addressMapping: Record<string, () => CorpProfileAddress> = {
    CA: () => ({
      country,
      fixedFormat: {
        unit: safeTrim(selectedAddress.subBuilding),
        streetNumber: safeTrim(selectedAddress.buildingNumber),
        streetName: safeTrim(streetInfo?.streetName),
        streetType: safeTrim(streetInfo?.streetType),
        buildingName: safeTrim(selectedAddress.buildingName),
        place: safeTrim(selectedAddress.city),
        state: safeTrim(selectedAddress.provinceName),
        postalCode: safeTrim(selectedAddress.postCode),
      },
    }),
    US: () => ({
      country,
      freeFormat: {
        addressLine1: safeTrim(selectedAddress.addressLine1),
        unit: safeTrim(selectedAddress.subBuilding),
        place: safeTrim(selectedAddress.city),
        county: safeTrim(selectedAddress.provinceName),
        postalCode: safeTrim(selectedAddress.postCode),
      },
    }),
    AU: () => ({
      country,
      fixedFormat: {
        unit: safeTrim(selectedAddress.subBuilding),
        streetNumber: safeTrim(selectedAddress.buildingNumber),
        streetName: safeTrim(streetInfo?.streetName),
        streetType: safeTrim(streetInfo?.streetType),
        place: safeTrim(selectedAddress.city),
        state: safeTrim(selectedAddress.provinceName),
        postalCode: safeTrim(selectedAddress.postCode),
      },
    }),
    GB: () => ({
      country,
      freeFormat: {
        addressLine1: safeTrim(selectedAddress.subBuilding),
        addressLine2: safeTrim(
          [selectedAddress.buildingNumber, selectedAddress.street].filter(Boolean).join(' ')
        ),
        addressLine3: safeTrim(selectedAddress.addressLine3),
        place: safeTrim(selectedAddress.city),
        county: safeTrim(selectedAddress.provinceName),
        postalCode: safeTrim(selectedAddress.postCode),
      },
    }),
    NZ: () => ({
      country,
      fixedFormat: {
        unit: safeTrim(selectedAddress.subBuilding),
        streetNumber: safeTrim(selectedAddress.buildingNumber),
        streetName: safeTrim(streetInfo?.streetName),
        streetType: safeTrim(streetInfo?.streetType),
        place: safeTrim(selectedAddress.city),
        postalCode: safeTrim(selectedAddress.postCode),
        area: safeTrim(selectedAddress.district),
      },
    }),
    DEFAULT: () => ({
      country,
      freeFormat: {
        addressLine1: safeTrim(selectedAddress.addressLine1),
        addressLine2: safeTrim(selectedAddress.addressLine2),
        addressLine3: safeTrim(selectedAddress.addressLine3),
        place: safeTrim(selectedAddress.city),
        county: safeTrim(selectedAddress.provinceName),
        postalCode: safeTrim(selectedAddress.postCode),
      },
    }),
  }
  const translator = addressMapping[country] || addressMapping.DEFAULT
  return translator()
}

const safeTrim = (value: string | null | undefined): string => {
  return value?.trim() ?? ''
}

const extractStreetNameAndStreetType = (
  streetName: string
): { streetName: string; streetType: string } => {
  if (!streetName) {
    return { streetName: '', streetType: '' }
  }

  const streetTypes = STREET_TYPES
  let parts = streetName.split(' ')
  if (parts.length <= 0) {
    return { streetName: '', streetType: '' }
  }

  // Last part (word) of streetName is the street type
  let streetType = parts[parts.length - 1]
  if (streetType.length === 1 && parts.length >= 3) {
    // Handle the case when the street type contains 2 words e.g. Avenue North (Ave N)
    const streetTypePrefix = parts[parts.length - 2]
    streetType = `${streetTypePrefix} ${streetType}`
  }
  let newStreetName = streetName.replace(streetType, '').trim()
  streetType = streetType.toUpperCase()
  let streetTypeValue = ''
  if (streetType in streetTypes) {
    streetTypeValue = streetTypes[streetType as keyof typeof streetTypes]
  } else {
    for (const key in streetTypes) {
      const value = streetTypes[key as keyof typeof streetTypes]
      if (value && value.toUpperCase() === streetType) {
        streetTypeValue = streetTypes[key as keyof typeof streetTypes]
        break
      }
    }
  }

  if (!streetTypeValue) {
    newStreetName = streetName
  }

  const newStreetValues = {
    streetName: newStreetName,
    streetType: streetTypeValue,
  }

  return newStreetValues
}
export const getOneLineAddressFromAddressDetails = (
  addressDetails: AddressDetailsInterface
): string => {
  return `${addressDetails.addressLine1} ${addressDetails.addressLine2} ${addressDetails.addressLine3}`
}
