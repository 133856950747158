import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'
// import { resolve } from 'core-js/fn/promise'

export default new APIHandler(
  API,
  ({ profileId, recipientId }, config) => {
    return {
      ...config,
      method: 'GET',
      url: `recipients/${profileId}/validate/${recipientId}`,
    }
  },
  // response handler is skipped when status code is 400
  (response) => {
    if (response.status === 200) {
      return null
    }
    return response
  },
  (errorResponse) => {
    let errors = errorResponse.response.data.data.errors
    if (errors !== null && errors.length > 0) {
      const invalidRecipientFields = errors
        .map((error) => {
          // Ignore errors that have no field name
          if (error.parameters && error.parameters.fieldName) {
            return error.parameters.fieldName
          } else if (error.id) {
            // This handles missing info in O2O contracts when parameters field is missing
            // Example: NAT_CLEAR_CODE for UK recipients is missing the parameter field,
            // while for CA recipients the field is present
            return error.id
          }
        })
        .filter((error) => {
          if (error) {
            return error
          }
        })
      throw invalidRecipientFields
    }
  }
)
