import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

class RecipientBankDetails {
  constructor({
    bankName,
    bankAddressOne,
    bankAddressTwo,
    bankAddressThree,
    bankBic,
    city,
    regionProvince,
    zipPostalCode,
    bicCode,
    connectedBic,
    countryName,
    countryCode,
    correctedTriggerField,
    isParentBank,
    isSepaCompliant,
  }) {
    this.bankName = bankName
    this.bankAddressOne = bankAddressOne
    this.bankAddressTwo = bankAddressTwo
    this.bankAddressThree = bankAddressThree
    this.bankBic = bankBic
    this.city = city
    this.regionProvince = regionProvince
    this.zipPostalCode = zipPostalCode
    this.bicCode = bicCode
    this.connectedBic = connectedBic
    this.countryName = countryName
    this.countryCode = countryCode
    this.correctedTriggerField = correctedTriggerField
    this.isParentBank = isParentBank
    this.isSepaCompliant = isSepaCompliant
  }

  /*
      data:
      {
          "bankName": "COMMERZBANK AG",
          "bankAddressOne": "KAISERSTRASSE 16",
          "bankAddressTwo": "FRANKFURT AM MAIN",
          "bankAddressThree": "",
          "bankBic": "COBADEFFXXX",
          "city": "FRANKFURT AM MAIN",
          "regionProvince": "HESSE",
          "zipPostalCode": "60261",
          "bicCode": "COBADEFFXXX",
          "connectedBic": "COBADEFFXXX",
          "countryName": "GERMANY",
          "countryCode": "DE",
          "correctedTriggerField": "DE89370400440532013000",
          "isParentBank": false,
          "isSepaCompliant": true
      }
    */
}

export default new APIHandler(
  API,
  ({ country, currency, params, controller }, config) => {
    return {
      ...config,
      signal: controller?.signal,
      method: 'GET',
      url: `/recipients/bankdetails/${country}/${currency}`,
      params: params,
    }
  },
  (response) => {
    if (!response.data) {
      return response
    }
    return {
      ...response,
      data: new RecipientBankDetails(response.data),
    }
  },
  (error) => {
    throw error
  }
)
