import APIHandler, { useHeaders } from '@galileo/api/APIHandler'
import { useAuthStore } from '@galileo/stores'
import { redirectToRoute } from '@galileo/composables/useRouter'

const { _headers, setHeader, removeHeader } = useHeaders()

export default class LaunchpadAPIHandler extends APIHandler {
  constructor(API, requestHandler, responseHandler, errorHandler = null) {
    super(API, requestHandler, responseHandler, errorHandler)
    // Before each request add request headers
    this.addRequestInterceptor({
      key: 'add-request-headers',
      requestHandler: (config) => {
        Object.keys(_headers).forEach((key) => {
          // Add header if not define in initial request
          if (!config.headers.common[key]) {
            let value = _headers[key]

            config.headers.common[key] = value
          }
        })
        return config
      },
    })

    // If any request unhandled 401 or 403 redirect user to login page
    this.addResponseInterceptor({
      key: '401-error-response',
      errorHandler: (error) => {
        const status = error?.response?.status
        const throwResponse = error?.config?.throwResponse
        if (
          (status === 401 || status === 403) &&
          !throwResponse &&
          useAuthStore().isAuthenticated
        ) {
          redirectToRoute({ name: 'Login' })
          return null
        }
        return Promise.reject(error)
      },
    })
  }
}

export { setHeader, removeHeader }

let debounceId = 0
export const debounce = (fn, delay = 250) => {
  return new Promise((resolve) => {
    debounceId++
    if (debounceId > 100000) {
      debounceId = 0
    }
    const id = debounceId
    window.setTimeout(() => {
      if (debounceId === id) {
        fn()
        resolve(true)
      } else {
        resolve(false)
      }
    }, delay)
  })
}
