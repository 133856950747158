import jwtDecode from 'jwt-decode'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'

import {
  useSendMoneyStore,
  useAppStore,
  useAnalyticsStore,
  useAuthStore,
  useQuickTransferStore,
  usePromotionStore,
  useActivityStore,
} from '@galileo/stores'



import { DELIVERY_METHODS } from '@galileo/constants/sendMoneyFlow.const'

export const preventSendMoneyCreationNav = (router) => {
  const isPreventedPage = (to) => to?.path === '/send-money/creating'
  const isFinishingPage = (to) => to?.path === '/send-money/finished' || to?.path === '/Activity'
  const isFailedPage = (to) =>
    to?.path === '/send-money/failed' || to?.path === '/send-money/open-banking/cancelled'

  const changeCurrentPathTo = (to, from) => {
    const appStore = useAppStore()
    appStore.setCurrentRoute({ ...to, from: from })
  }

  router.beforeEach((to, from, next) => {
    const appStore = useAppStore()
    const authStore = useAuthStore()
    const sendMoneyStore = useSendMoneyStore()
    const quickTransferStore = useQuickTransferStore()

    const shouldCheckForRateChange = sendMoneyStore.shouldCheckForRateChange
    let shouldBypass = sendMoneyStore.bypassSendMoneyCreatingRedirection

    const currentRoute = appStore.getCurrentRoute

    if (from.name === 'Activity' && to.name === 'SendMoneyCreating') {
      return next(false)
    }

    const routesEnablesByBalances = [
      'OneStepTransfer',
      'ConvertBalances',
      'FundBalance',
      'BalancesOverview',
      'IndividualCurrency',
    ]
    const routesEnablesByQuickTransfer = ['QuickTransfer']

    if (routesEnablesByBalances.includes(to.name) && !authStore.getIsBalancesEnabled) {
      return next(false)
    }
    if (routesEnablesByQuickTransfer.includes(to.name) && !authStore.getIsQuickTransferEnabled) {
      return next(false)
    }

    if (isPreventedPage(to)) {
      if (
        shouldCheckForRateChange &&
        sendMoneyStore.didRateChange &&
        !quickTransferStore.isCurrentTransactionQuickTransfer
      ) {
        //change active step
        sendMoneyStore.setActiveStep('/send-money/summary')
        //set to display alert that rate changed
        sendMoneyStore.setShouldDisplayRateChangedAlert(true)
        let toOverride = {
          path: '/send-money/summary',
        }
        changeCurrentPathTo(toOverride, from)
        return next(toOverride.path)
      } else if (isFinishingPage(from)) {
        let toOverride = {
          path: '/Activity',
        }
        changeCurrentPathTo(toOverride, from)
        return next(toOverride.path)
      } else if (isFailedPage(from)) {
        return next(false)
      }
      sendMoneyStore.setShouldDisplayRateChangedAlert(false)
      changeCurrentPathTo(to, from)
      return next()
    } else if (
      isPreventedPage(currentRoute) &&
      !(isFinishingPage(to) || isFailedPage(to)) &&
      !shouldBypass
    ) {
      //check if we can go to summary after card transaction
      return next(false)
    } else {
      sendMoneyStore.bypassSendMoneyCreatingRedirection = false
      changeCurrentPathTo(to, from)
      return next()
    }
  })
}

export const useBeforeEachAuth = (router) => {
  router.beforeEach((to, from, next) => {
    const authStore = useAuthStore()
    const { isAuthenticated } = authStore

    if (to.matched.every((record) => record.meta.auth === false)) {
      next()
    } else if (!isAuthenticated) {
      console.warn('User not authenticated, redirecting to login.')
      authStore.setReturnRoute(to)
      next({ name: 'Login' })
    } else {
      next()
    }
  })
}

export const useBeforeEachGtmInitialise = (router) => {
  router.beforeEach((to, from, next) => {
    const analyticsStore = useAnalyticsStore()
    if (to.name === 'BalancesAndPayments' || to.name === 'MarketOrder' || to.name === 'Payments') {
      analyticsStore.gtmDeInitialize()
    } else {
      analyticsStore.gtmInitialize()
    }
    next()
  })
}

export const resetRecipient = (to, from, next) => {
  next()
}

export const isPromoAllowed = async (to, from, next) => {
  const sendMoneyStore = useSendMoneyStore()
  const promotionStore = usePromotionStore()
  const analyticsStore = useAnalyticsStore()

  const { activePromotion, getCanAccessPromotion } = promotionStore

  if (to.name === 'InviteFriend') {
    analyticsStore.track({
      event: SEGMENT_EVENTS.REFER_A_FRIEND_CLICKED,
      traits: {
        loggedIn: true,
        email: useAuthStore().lastLogin,
      },
    })
  }

  if (getCanAccessPromotion()) {
    if (
      (!sendMoneyStore.form.countryTo || activePromotion?.promotionCode?.valid) &&
      to.name !== 'InviteFriend'
    ) {
      next({ name: 'SendMoneyAmount' })
    }

    next()
  }
}

export const checkJWTToken = async (to, from, next) => {
  const authStore = useAuthStore()
  const currentToken = authStore.token
  if (!currentToken) return next()
  const { exp } = jwtDecode(currentToken)
  if (exp < (new Date().getTime() + 1) / 1000) {
    const loginUrl = authStore.loginUrl
    window.removeEventListener('beforeunload', window.handleWindowClose)
    await authStore.reset()
    window.location.href = loginUrl.replace('signup', 'continueAs')
  }
  return next()
}

export const resetSendMoneyForm = async (to, from, next) => {
  const sendMoneyStore = useSendMoneyStore()
  const activityStore = useActivityStore()
  const authStore = useAuthStore()

  if (!from) next()
  // Extract send money form data before reset
  // This data will persist through each new transaction
  const selectedRecipient = sendMoneyStore.getRecipient

  // Always reset send money form, unless route is in SMF
  const ignoreRoutes = ['SendMoneyRecipient', 'SendMoneySummary']

  if (!ignoreRoutes.includes(from.name)) {
    sendMoneyStore.restoreDefaultForm()

    // Set extract data back to store to persist for new transaction
  }

  // Redirect new users to quote screen
  if (
    (!activityStore.totalTransactions || activityStore.totalTransactions === 0) &&
    authStore.isConsumerAccount &&
    from.name
  ) {
    await sendMoneyStore.populateQuoteScreenForFirstTimeUser()
  }

  // Keeps selected recipient when route start is recipients
  if (from?.name === 'Recipients' && selectedRecipient) {
    sendMoneyStore.calculatorSelectCountryTo(selectedRecipient.country)
    sendMoneyStore.form.currencyTo = selectedRecipient.currency
    sendMoneyStore.form.recipient = selectedRecipient

    if (!selectedRecipient.isBankAccount && !selectedRecipient.isMobileWallet) {
      sendMoneyStore.setDeliveryMethodAction(DELIVERY_METHODS.CASH_PAYOUT)
    } else if (!selectedRecipient.isBankAccount && selectedRecipient.isMobileWallet) {
      sendMoneyStore.setDeliveryMethodAction(DELIVERY_METHODS.MOBILE_WALLET)
    }

    sendMoneyStore.form.isFromRecipient = true
  }

  // Reset form errors
  sendMoneyStore.form.quoteErrorFrom = null
  sendMoneyStore.form.quoteErrorTo = null

  next()
}
