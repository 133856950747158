import API from '@galileo/api/corporate'
import APIHandler from '@galileo/api/APIHandler'
import { PendingActionsData } from '@galileo/models/Corporate/interfaces/CorpReg'
import { AxiosResponse } from 'axios'

export default new APIHandler(
  API,
  (ekycRefreshData: PendingActionsData) => {
    return {
      method: 'POST',
      url: `/client/kycRefresh`,
      data: ekycRefreshData,
    }
  },
  (response: AxiosResponse<PendingActionsData>) => {
    return { ...response.data }
  }
)
