/*

---------------------------------
HOW TO IMPORT 
--------------------------------
import singleBankRecipientFieldsMapping, {nonBankRecipientFieldsMapping, bankRecipientFieldsMapping} from '@galileo/constants/recipients/recipientFields.const.js'
*/

export const singleBankRecipientFieldsMapping = {
  RECIPIENT_ID: 'id',
  BANK_ADDRESS_COUNTRY_CODE: 'country',
  ADDRESS_COUNTRY_CODE: 'addressCountry',
  CURRENCY_CODE: 'currency',
  IS_OWN_ACCOUNT: 'isMyself',
  IS_BUSINESS: 'isBusiness',
  ACCOUNT_NAME: 'fullName',
  ACCOUNT_NAME: 'accountName',
  FIRST_NAME: 'firstName',
  MIDDLE_NAME: 'middleName',
  LAST_NAME: 'lastName',
  ACCOUNT_NUM: 'bankAccountNumber',
  BANK_BIC: 'bankRoutingCode',
  BANK_NAME: 'bankName',
  EMAIL: 'emailAddress',
  NICKNAME: 'nickNames',
  INDUSTRY_TYPE: 'industryTypeId',
  RECIPIENT_CITY: 'recipientCity',
  RECIPIENT_POST_CODE: 'recipientPostCode',
  RECIPIENT_STATE: 'recipientState',
  CA_TRANSIT_INSTITUTION: 'institutionNumber',
  CA_TRANSIT_BRANCH: 'branchNumber',
  RECIPIENT_TAX_NUMBER: 'taxNumber',
  BANK_BRANCH_NUMBER: 'bankBranchNumber',
  RECIPIENT_ID_NUMBER: 'recipientIdNumber',
  ID_NUMBER_TYPE_CODE: 'recipientIdNumberCode',
  NAME_VERIFIED: 'nameVerified',
  ADDRESS_LINE1: 'address',
  ADDRESS_LINE2: 'addressLine2',
  IBAN_OR_ACCOUNT_NUM: 'ibanOrAccountNumber',
}

export const nonBankRecipientFieldsMapping = {
  Id: 'id',
  FirstName: 'firstName',
  LastNames: 'lastName1',
  AddressState: 'recipientState',
  AddressCity: 'recipientCity',
  CurrencyCode: 'currency',
  CountryCode: 'country',
  MiddleName: 'middleName',
  MobileWalletAccountNo: 'mobileWalletAccountNo',
  MobileWalletPrefix: 'mobileWalletPrefix',
  MobileWalletLocationId: 'mobileWalletLocationId',
  MobileWalletCorrespondentId: 'mobileWalletCorrespondentId',
  RelationToBeneficiaryCode: 'relationToBeneficiaryCode',
  RelationToBeneficiaryOther: 'relationToBeneficiaryOther',
}

export const bankRecipientFieldsMapping = {
  IS_OWN_ACCOUNT: 'isOwnAccount',
  IS_BUSINESS: 'isBusinessAccount',
  ADDRESS_COUNTRY_CODE: 'addressCountry',
  ADDRESS_LINE1: 'address',
  ADDRESS_LINE2: 'addressLine2',
  BANK_BIC: 'bankBicSwift',
  NAT_CLEAR_CODE: 'bankRoutingCode',
  IBAN: 'bankIban',
  ACCOUNT_NAME: 'accountName',
  FIRST_NAME: 'firstName',
  MIDDLE_NAME: 'middleName',
  LAST_NAME: 'lastName',
  BANK_ACCOUNT_TYPE_CODE: 'bankAccountTypeId',
  NICKNAME: 'nickName',
  INDUSTRY_TYPE: 'industryTypeId',
  BANK_ADDRESS_LINE1: 'bankAddressLine1',
  BANK_ADDRESS_LINE2: 'bankAddressLine2',
  BANK_ADDRESS_LINE3: 'bankAddressLine3',
  EMAIL: 'emailAddress',
  RECIPIENT_PHONE: 'telephoneNumber',
  DEACTIVATED: 'deactivated',
  HAS_RIA_ALERT_ACCEPTED: 'riaAlertAccepted',
  RECIPIENT_CITY: 'recipientCity',
  RECIPIENT_POST_CODE: 'recipientPostCode',
  RECIPIENT_STATE: 'recipientState',
  CA_TRANSIT_INSTITUTION: 'institutionNumber',
  CA_TRANSIT_BRANCH: 'branchNumber',
  RECIPIENT_TAX_NUMBER: 'taxNumber',
  BANK_BRANCH_NUMBER: 'bankBranchNumber',
  RECIPIENT_ID: 'id',
  RECIPIENT_ID_NUMBER: 'recipientIdNumber',
  ID_NUMBER_TYPE_CODE: 'recipientIdNumberCode',
  NAME_VERIFIED: 'nameVerified',
  IBAN_OR_ACCOUNT_NUM: 'ibanOrAccountNumber',
}

export const API_VERSIONS = {
  V2: 'v2',
  default: 'default',
}
