import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  ({ lastKycRefreshDate, preferredLanguage, profileId }, config) => {
    let payload = {
      lastKycRefreshDate,
      preferredLanguage,
    }

    return {
      ...config,
      method: 'PATCH',
      url: `profiles/${profileId}`,
      data: payload,
    }
  },
  (response) => {
    return {
      ...response,
      data: response.data,
    }
  }
)
