import FieldValidation from '@galileo/models/DynamicField/FieldValidation'

// Ref: carried from apollo for reference DynamicForms validator types
// enum FormValidatorType {
//   Required = 'Required',
//   Regex = 'Pattern',
//   MinValue = 'MinValue',
//   MaxValue = 'MaxValue',
//   MinLength = 'MinLength',
//   MaxLength = 'MaxLength',
//   Custom = 'Custom',
//   RelativeMinDate = 'RelativeMinDate',
// }

export default class XeFieldValidation extends FieldValidation {
  constructor(validation) {
    let value = null
    let type = validation.type
    // Note: for Required no need ot set the value
    if (type === 'MinValue') {
      value = validation.minValue
    } else if (type === 'MaxValue') {
      value = validation.maxValue
    } else if (type === 'MinLength') {
      value = validation.minLength
    } else if (type === 'MaxLength') {
      value = validation.maxLength
    } else if (type.indexOf('Regex') >= 0 && validation.regex) {
      value = validation.regex

      // \p{L} and \p{Lu} is not supported by Javascript Regex
      const lowercase = 'a-záàâäãåçéèêëíìîïñóòôöõúùûüýÿæœ'
      const uppercase = 'A-ZÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ'
      value = value.replace(/\\p{L}/g, lowercase + uppercase)
      value = value.replace(/\\p{Lu}/g, uppercase)

      type = 'Regex'
    } else if (type === 'RelativeMinDate') {
      // TODO: what should be assigned to value?
    } else if (type === 'Custom') {
      // TODO: what should be assigned to value?
    }

    super({
      message: validation.message || null,
      type: type,
      value: value,
    })
  }
}
