import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  ({ profileId, versionAccepted }, config) => {
    return {
      ...config,
      method: 'PUT',
      url: `/profiles/${profileId}/termsandconditions`,
      data: {
        versionAccepted,
      },
    }
  },
  async (response) => {
    if (response) {
      return response
    } else {
      throw new Error('Failed to update terms and conditions')
    }
  }
)
