export const sendMoneySteps = [
  {
    name: 'Amount',
    title: 'Amount',
    path: '/send-money',
    pageTitle: 'How much are you sending?',
    titleKey: 'PageSendMoney.StepAmountTitle',
    pageTitleKey: 'PageSendMoney.StepAmountPageTitle',
    available: true,
    // $t('PageSendMoney.StepAmountTitle')
    // $t('PageSendMoney.StepAmountPageTitle')
  },
  {
    name: 'Recipient',
    title: 'Recipient',
    path: '/send-money/recipient',
    pageTitle: 'Recipient',
    titleKey: 'PageSendMoney.StepRecipientTitle',
    pageTitleKey: 'PageSendMoney.StepRecipientPageTitle',
    available: true,
    // $t('PageSendMoney.StepRecipientTitle')
    // $t('PageSendMoney.StepRecipientPageTitle')
  },
  {
    name: 'Delivery',
    title: 'Delivery',
    path: '/send-money/delivery',
    pageTitle: 'Delivery',
    titleKey: 'PageSendMoney.StepDeliveryTitle',
    pageTitleKey: 'PageSendMoney.StepDeliveryPageTitle',
    available: false,
    // $t('PageSendMoney.StepDeliveryTitle')
    // $t('PageSendMoney.StepDeliveryPageTitle')
  },
  {
    name: 'Payment',
    title: 'Payment',
    path: '/send-money/payment',
    pageTitle: 'Payment',
    titleKey: 'PageSendMoney.StepPaymentTitle',
    pageTitleKey: 'PageSendMoney.StepPaymentPageTitle',
    available: true,
    // $t('PageSendMoney.StepPaymentTitle')
    // $t('PageSendMoney.StepPaymentPageTitle')
  },
  {
    name: 'Verification',
    title: 'Verification',
    path: '/send-money/summary/verification',
    pageTitle: 'Verification',
    titleKey: 'PageSendMoney.StepVerificationTitle',
    pageTitleKey: 'PageSendMoney.StepVerificationPageTitle',
    available: false,
    // $t('PageSendMoney.StepVerificationTitle')
    // $t('PageSendMoney.StepVerificationPageTitle')
  },
  {
    name: 'Send',
    title: 'Send',
    path: '/send-money/summary',
    pageTitle: 'Summary',
    titleKey: 'PageSendMoney.StepSummaryTitle',
    pageTitleKey: 'PageSendMoney.StepSummaryPageTitle',
    available: true,
    // $t('PageSendMoney.StepSummaryTitle')
    // $t('PageSendMoney.StepSummaryPageTitle')
  },
]

export const SMFSteps = [
  {
    name: 'Amount',
    title: 'Amount',
    path: '/send-money',
    pageTitle: 'Send money',
    titleKey: 'PageSendMoney.StepAmountTitle',
    pageTitleKey: 'PageSendMoney.StepAmountPageTitle',
    available: true,
    // $t('PageSendMoney.StepAmountTitle')
    // $t('PageSendMoney.StepAmountPageTitle')
  },
  {
    name: 'Recipient',
    title: 'Recipient',
    path: '/send-money/recipient',
    pageTitle: 'Recipient',
    titleKey: 'PageSendMoney.StepRecipientTitle',
    pageTitleKey: 'PageSendMoney.StepRecipientPageTitle',
    available: true,
    // $t('PageSendMoney.StepRecipientTitle')
    // $t('PageSendMoney.StepRecipientPageTitle')
  },
  {
    name: 'Delivery',
    title: 'Delivery',
    path: '/send-money/delivery',
    pageTitle: 'Delivery',
    titleKey: 'PageSendMoney.StepDeliveryTitle',
    pageTitleKey: 'PageSendMoney.StepDeliveryPageTitle',
    available: false,
    // $t('PageSendMoney.StepDeliveryTitle')
    // $t('PageSendMoney.StepDeliveryPageTitle')
  },
  {
    name: 'Payment',
    title: 'Payment',
    path: '/send-money/payment',
    pageTitle: 'Payment',
    titleKey: 'PageSendMoney.StepPaymentTitle',
    pageTitleKey: 'PageSendMoney.StepPaymentPageTitle',
    available: true,
    // $t('PageSendMoney.StepPaymentTitle')
    // $t('PageSendMoney.StepPaymentPageTitle')
  },
  {
    name: 'Verification',
    title: 'Verification',
    path: '/send-money/summary/verification',
    pageTitle: 'Verification',
    titleKey: 'PageSendMoney.StepVerificationTitle',
    pageTitleKey: 'PageSendMoney.StepVerificationPageTitle',
    available: false,
    // $t('PageSendMoney.StepVerificationTitle')
    // $t('PageSendMoney.StepVerificationPageTitle')
  },
  {
    name: 'Send',
    title: 'Send',
    path: '/send-money/summary',
    pageTitle: 'Summary',
    titleKey: 'PageSendMoney.StepSummaryTitle',
    pageTitleKey: 'PageSendMoney.StepSummaryPageTitle',
    available: true,
    // $t('PageSendMoney.StepSummaryTitle')
    // $t('PageSendMoney.StepSummaryPageTitle')
  },
]

export const O2OSteps = [
  {
    name: 'Recipient',
    title: 'Recipient',
    path: '/send-money/recipient',
    pageTitle: 'Recipient',
    titleKey: 'PageSendMoney.StepRecipientTitle',
    pageTitleKey: 'PageSendMoney.StepRecipientPageTitle',
    available: true,
    // $t('PageSendMoney.StepRecipientTitle')
    // $t('PageSendMoney.StepRecipientPageTitle')
  },
  {
    name: 'Review',
    title: 'Review',
    path: '/send-money/summary',
    pageTitle: 'Summary',
    titleKey: 'PageSendMoney.StepSummaryReviewTitle',
    pageTitleKey: 'PageSendMoney.StepSummaryPageReviewTitle',
    available: true,
    // $t('PageSendMoney.StepSummaryTitle')
    // $t('PageSendMoney.StepSummaryPageTitle')
  },
  {
    name: 'Verification',
    title: 'Verification',
    path: '/send-money/summary/verification',
    pageTitle: 'Verification',
    titleKey: 'PageSendMoney.StepVerificationTitle',
    pageTitleKey: 'PageSendMoney.StepVerificationPageTitle',
    available: false,
    // $t('PageSendMoney.StepVerificationTitle')
    // $t('PageSendMoney.StepVerificationPageTitle')
  },
]

// export default sendMoneySteps
