import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/LaunchpadAPIHandler'
import { useEnvStore } from '@galileo/stores'

import { getCorrectedString } from '@galileo/utilities/sanitizers/sanitizeString'
import sanitizeProperties from '@galileo/utilities/sanitizers/sanitizeProperties'

class ProfileResult {
  constructor(data) {
    //sanitise backend address
    data.extraFields = sanitizeProperties(data.extraFields)
    data.address = getCorrectedString(data.address)

    this.accountType = data.accountType
    this.onlineCredentialId = data.onlineCredentialId
    this.region = data.region

    this.country = data.country
    this.disabled = data.disabled
    this.extraFields = data.extraFields

    this.extraFields.state = data.extraFields.state ?? data.extraFields.county

    this.address = data.address
      ? data.address.replaceAll('\n', ', ')
      : `${data.extraFields.streetNumber} ${data.extraFields.streetName} ${data.extraFields.streetType}`

    this.hasTaxId = data.hasTaxId

    this.homePhone = data.homePhone // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!
    this.identification = data.identification // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!
    this.mobilePhone = data.mobilePhone // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!

    this.language = data.preferredLanguage

    this.firstName = data.profile.fullName?.firstName || ''
    this.lastName = data.profile.fullName?.lastName || ''
    this.middleName = data.profile.fullName?.middleName || ''
    this.country = data.profile.country
    this.expectedTradeCurrency = data.profile.expectedTradeCurrency
    this.expectedPayoutCurrency = data.profile.expectedPayoutCurrency
    this.regionalAccountingCurrency = data.profile.regionalAccountingCurrency
    this.street = data.extraFields.addressLine1
      ? data.extraFields.addressLine1
      : (data.extraFields.streetName + ' ' + data.extraFields.streetNumber).trim()
    this.city = data.extraFields.place
    this.state = data.extraFields.county ? data.extraFields.county : data.extraFields.state
    this.postalCode = data.extraFields.postalCode
    this.isKycRefreshRequired = data.isKycRefreshRequired

    // feature toggle for SCP
    const envStore = useEnvStore()
    let environment = envStore.env.VUE_APP_ENV

    this.sameCurrencySupported = data.profile.sameCurrencySupported

    this.customer = {
      onlineDirectDebitEnabled: data.profile.onlineDirectDebitEnabled,
      canUseOnlineDealing: data.profile.canUseOnlineDealing,
      accountName: data.profile.accountName,
      accountRestricted: data.profile.accountRestricted,
      accountStatus: data.profile.accountStatus,
      accountKycStatus: data.profile.accountKycStatus,
      accountType: data.profile.accountType,
      brandRegionId: data.profile.brandRegionId,
      businessUnitId: data.profile.businessUnitId,
      clientId: data.profile.clientId,
      clientNumber: data.profile.clientNumber,
      country: data.profile.country,
      countryOfBirth: data.profile.countryOfBirth,
      dateOfBirth: data.profile.dateOfBirth,
      email: data.profile.email,
      //data.profile.fixedFormatAddress: {streetNumber: "11", streetName: "test", streetType: "Street", place: "Auckland", postalCode: "0612"}
      firstName: data.profile.fullName?.firstName || '',
      lastName: data.profile.fullName?.lastName || '',
      middleName: data.profile.fullName?.middleName || '',
      ...(data.profile.idDocDetails && { idDocDetails: data.profile.idDocDetails }),
      //data.profile.idDocDetails: {idType: "DriverLicence", idExpiryDate: "-", licenceNumber: "AA123123", licenceVersion: "123"}
      //data.profile.mobileNumber: {mobilePrefix: "+64", mobilePhone: "211081260"}
      nationality: data.profile.nationality,
      occupation: data.profile.occupation,
      createdDate: data.profile.createdDate,
      onlineCredentialId: data.profile.onlineCredentialId,
      preferredLanguage: data.profile.preferredLanguage,
      privacyPolicyId: data.profile.privacyPolicyId,
      id: data.profile.profileId,
      promptForMarketingConsent: data.profile.promptForMarketingConsent,
      region: data.profile.region,
      termsAndConditions: data.profile.termsAndConditions,
      //data.profile.termsAndConditions: {acceptedDate: "2021-03-15T22:28:26.875646+00:00", isValid: true}
      trmBusinessUnitId: data.profile.trmBusinessUnitId,
      kycRefreshStatus: data.profile.kycRefreshStatus,
      features: data.profile.features,
      documentStatus: data.profile.documentStatus,
      cardPaymentGateway: data.profile.cardPaymentGateway,
      quickTransactionsEnabled: data.profile.features.quickTransactionsEnabled,
      balancesEnabled: data.profile.balancesEnabled,
    }

    /*
    this.securityQuestions = data.securityQuestions // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!
    this.canTransact = data.status.canTransact
    this.kycFailed = data.status.kycFailed
    this.profileActionsRequired = data.status.profileActionsRequired // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    */

    this.willReceiveEmailMarketing = data.willReceiveEmailMarketing
    this.willReceivePhoneMarketing = data.willReceivePhoneMarketing
    this.willReceivePostalMarketing = data.willReceivePostalMarketing
    this.willReceiveSmsMarketing = data.willReceiveSmsMarketing
    this.willReceiveSmsNotifications = data.willReceiveSmsNotifications
    this.fxWebCorpMigrated = data.fxWebCorpMigrated
  }
}

export default new APIHandler(
  API,
  (profileId, config) => {
    return {
      ...config,
      method: 'GET',
      url: `profiles/${profileId}`,
    }
  },
  (response) => {
    return {
      ...response,
      data: new ProfileResult(response.data),
    }
  }
)
