export default class AddressDetails {
  constructor(address) {
    this.id = address.id
    this.language = address.language
    this.buildingName = address.buildingName
    this.buildingNumber = address.buildingNumber
    this.subBuilding = address.subBuilding
    this.addressLine1 = address.xeLine1 ?? address.line1
    this.addressLine2 = address.xeLine1 ? '' : address.line2
    this.addressLine3 = address.xeLine1 ? '' : address.line3
    this.street = address.street
    this.city = address.city
    this.district = address.district
    this.countryCode = address.countryIso2
    this.postCode = address.postalCode
    this.provinceName = address.provinceName
    this.provinceCode = address.provinceCode
  }
}
