import { watch } from '@vue/composition-api'
import { useNetwork } from '@vueuse/core'
import { useI18nStore } from '@galileo/stores'
import { useAlert } from '@oen.web.vue2/ui'

export const useNetworkStatusAlert = () => {
  const { $t } = useI18nStore()
  const { add } = useAlert()
  const { isOnline } = useNetwork()
  watch(isOnline, (online) => {
    if (!online) {
      add($t('App.OfflineNotification').value)
    }
  })
}
