export const UNAVAILABLE_REASON = {
  GENERIC_ERROR: {
    TEXT_KEY: 'QuoteErrors.GenericError',
  },
  FIX_AMOUNT_ABOVE_MAX_AMOUNT: {
    CODE: 'FixedAmountAboveMaxAmount',
    TEXT_KEY: 'QuoteErrors.QUOTE035',
    TEXT_KEY_CONSUMERS: 'QuoteErrors.QUOTE035_consumers',
  },
  NON_FIXED_AMOUNT_ABOVE_MAX_AMOUNT: {
    CODE: 'NonFixedAmountAboveMaxAmount',
    TEXT_KEY: 'QuoteErrors.QUOTE035',
    TEXT_KEY_CONSUMERS: 'QuoteErrors.QUOTE035_consumers',
  },
  NON_FIXED_AMOUNT_BELOW_MIN_AMOUNT: {
    CODE: 'NonFixedAmountBelowMinAmount',
    TEXT_KEY: 'QuoteErrors.QUOTE034',
  },
  CLIENT_EXPOSURE_LIMIT_EXCEEDED: {
    CODE: 'ClientExposureLimitExceeded',
    TEXT_KEY: 'QuoteErrors.QUOTE032',
  },
  MAX_TRADE_AMOUNT_PER_DAY_EXCEEDED: {
    CODE: 'MaxTradeAmountPerDayExceeded',
    TEXT_KEY: 'QuoteErrors.QUOTE030',
  },
  MAX_FAILED_CARD_AUTHORIZATION_ATTEMPTS_EXCEEDED: {
    CODE: 'MaxFailedCardAuthorizationAttemptsExceeded',
    TEXT_KEY: 'QuoteErrors.MaxFailedCardAuthorization',
  },
  DAILY_TRADE_AMOUNT_EXCEEDED: {
    CODE: 'DailyTradeAmountExceeded',
    TEXT_KEY: 'QuoteErrors.QUOTE030',
  },
  DAILY_TRADE_COUNT_EXCEEDED: {
    CODE: 'DailyTradeCountExceeded',
    TEXT_KEY: 'QuoteErrors.QUOTE031',
  },
  FIXED_AMOUNT_BELOW_MIN_AMOUNT: {
    CODE: 'FixedAmountBelowMinAmount',
    TEXT_KEY: 'QuoteErrors.QUOTE034',
    TEXT_KEY_WIRE_TRANSFER: 'QuoteErrors.QUOTE034_WireTransfer',
  },
  MAX_DIRECT_DEBIT_TRADES_PER_DAY_EXCEEDED: {
    CODE: 'MaxDirectDebitTradesPerDayExceeded',
    TEXT_KEY: 'QuoteErrors.QUOTE030_DirectDebit',
  },
  MAX_DIRECT_DEBIT_AMOUNT_PER_PERIOD_EXCEEDED: {
    CODE: 'MaxDirectDebitAmountForPeriodExceeded',
    TEXT_KEY: 'QuoteErrors.QUOTE030_DirectDebit',
  },
  MAX_FAILED_CARD_AUTHENTICATION_ATTEMPTS_EXCEEDED: {
    CODE: 'MaxFailedCardAuthenticationAttemptsExceeded',
    TEXT_KEY: 'QuoteErrors.MaxFailedCardAuthorization',
  },
  INSUFFICIENT_BALANCE_FUNDS: {
    CODE: 'InsufficientBalanceFunds',
    TEXT_KEY: 'QuoteErrors.InsufficientFundsOnBalance',
  },
}

export const FUND_BALANCE_DELIVERY_ERRORS = {
  QUOTE101: {
    CODE: 'QUOTE101',
    TEXT_KEY: 'QuoteErrors.QUOTE101',
  },
  QUOTE102: {
    CODE: 'QUOTE102',
    TEXT_KEY: 'QuoteErrors.QUOTE102',
  },
}
