import { useRecipientForm, setAccNumberAsFirst } from '@galileo/forms/RecipientForm'
import { ref } from '@vue/composition-api'
import { useDefaultValues } from '@galileo/composables/useFormHelpers'

import { useAuthStore, useAppStore, useRecipientsStore } from '@galileo/stores'

import { removeDiacritics } from '@galileo/utilities/diacritics-convertor.utility'
import { sepaMapping } from '@galileo/constants/sepa'

const recipientFormAddressCountry = ref(null)

export const getSepaCompliantValue = (value) => {
  let mappedValue = ''

  for (let char of value) {
    if (sepaMapping.hasOwnProperty(char)) {
      mappedValue += sepaMapping[char]
    } else {
      mappedValue += char
    }
  }

  // remove diacritics from characters that are not in SEPA character table
  mappedValue = removeDiacritics(mappedValue)
  return mappedValue
}

export const parseSepaFields = (fields, propNames) => {
  fields.forEach((field) => {
    if (propNames.includes(field.id) && field.value) {
      field.value = getSepaCompliantValue(field.value)
    }
  })
}

export default function (recipient, isMissingInformation = false) {
  const {
    setup,
    formFields,
    formValidation,
    allFields,
    setFormFieldValue,
    additionalFields,
    selectFormFields,
  } = useRecipientForm(isMissingInformation)

  const isLoadingState = ref(false)
  const loadedFields = ref(null)
  const authStore = useAuthStore()

  const { getDefaultValues } = useDefaultValues()

  const completeFieldsDefinition = ref([])

  /**
   * Function that will fetch form fields from the api
   * will parse and populate all fields
   *
   * @void
   */
  const fetchFormFields = async (country = null) => {
    let recipientAddressCountry = null
    const appStore = useAppStore()

    if (!recipientFormAddressCountry.value) {
      recipientAddressCountry = recipient.addressCountry
    } else {
      recipientAddressCountry = recipientFormAddressCountry.value
    }

    isLoadingState.value = true
    try {
      const customerId = authStore.userProfile?.customer?.id
      const recipientsStore = useRecipientsStore()

      let _fields = await recipientsStore.getFields({
        id: customerId,
        country: country ?? recipient.country,
        currency: recipient.currency,
        isBusiness: recipient.isBusinessAccount,
        addressCountry: recipientAddressCountry,
      })
      completeFieldsDefinition.value = _fields

      setAccNumberAsFirst(_fields)

      if (_fields) {
        loadedFields.value = _fields

        let shouldClearValues = true
        let defaultValues = getDefaultValues(recipient)

        setup(loadedFields.value, defaultValues, shouldClearValues, recipient.isBusinessAccount)

        if (formFields) {
          isLoadingState.value = false
          return
        }
      }
    } catch (ex) {
      appStore.logException('Exception during fetching recipient fields', ex)
    }
    appStore.messageBoxGenericError()
    isLoadingState.value = false
  }

  /**
   * Function to update the recipient
   * @param {object} payload object that will containt all the data of the recipient,
   * leave empty if  isBankAccount as it will get the default payload
   * @param {function} onUpdateSucceed callback when everythign went ok
   */
  const updateRecipient = async (payload, onUpdateSucceed) => {
    if (!payload) {
      payload = createPayload()
    }

    const fields = []
    const appStore = useAppStore()
    const recipientsStore = useRecipientsStore()

    // Make name fields SEPA compliant
    const nameFields = [
      'ACCOUNT_NAME',
      'FIRST_NAME',
      'MIDDLE_NAME',
      'LAST_NAME',
      'NICKNAME',
      'FirstName',
      'LastNames',
      'MiddleName',
    ]
    parseSepaFields(payload.fields, nameFields)

    //format properly the fields object
    payload.fields.forEach((field) => {
      if (
        field.value ||
        field.id === 'MIDDLE_NAME' ||
        field.id === 'NICKNAME' ||
        field.optional ||
        field.fieldDefinition?.disableErrorValueValidation ||
        field.id == 'MiddleName'
      ) {
        fields.push({
          name: field.id,
          value: field.value,
        })
      }
    })

    if (newFields.value) {
      fields.push(...newFields.value)
    }
    if (recipient.isMobileWallet) {
      fields.push({ name: 'PaymentMethod', value: 'MobileWallet' })
    }

    payload.fields = fields

    try {
      let updatedRecipient = null
      //update bank recipient
      if (recipient.isBankAccount) {
        updatedRecipient = await recipientsStore.updateRecipient({
          id: recipient.id,
          formData: payload,
        })
      } else {
        //update cash recipient
        updatedRecipient = await recipientsStore.updateCashRecipient({
          id: recipient.id,
          formData: payload,
          deliveryMethod: recipient.deliveryMethod,
        })
      }

      //on success callback
      onUpdateSucceed(updatedRecipient)
    } catch (ex) {
      appStore.logException('Exception during updating recipient in recipients', ex)
      appStore.messageBoxGenericError()
    } finally {
      isLoadingState.value = false
    }
  }

  /**
   *  create the payload for the recipient update
   *
   * @returns return well formatted payload
   */
  const createPayload = () => {
    let isOwnAccount = recipient.isOwnAccount
    let isBusiness = recipient.isBusinessAccount

    if (recipient.isBankAccount) {
      const hasFirstName = allFields.value.find((field) => field.name === 'FIRST_NAME')

      const payload = {
        currency: recipient.currency,
        country: recipient.country,
        isOwnAccount: isOwnAccount,
        isBusiness: isBusiness,
        fields: allFields.value,
      }
      if (hasFirstName) {
        payload.nameVerified = true
      }
      return payload
    }
  }

  const newFields = ref(null)

  //add new fields to be sent in the payload
  const addFormFieldValue = (id, value) => {
    if (!newFields.value) {
      newFields.value = []
    }
    newFields.value.push({ name: id, value: value })
  }

  return {
    fetchFormFields,
    isLoadingState,
    setFormFieldValue,
    allFields,
    formValidation,
    formFields,
    updateRecipient,
    addFormFieldValue,
    additionalFields,
    completeFieldsDefinition,
    selectFormFields,
    recipientFormAddressCountry,
  }
}
