import corporateAddressValidationRules from '@galileo/components/KYCRefreshCorporate/KYCRefreshCorporateAddress/Validation/corporateAddressValidationRules.json'
import {
  ValidationConfig,
  CountryValidationRules,
} from '@galileo/components/KYCRefreshCorporate/KYCRefreshCorporateAddress/Validation/CorporateAddressValidationRules'
import { useI18nStore } from '@galileo/stores'

const validationRules: ValidationConfig = corporateAddressValidationRules

export const getCorporateAddressValidation = (
  country: keyof ValidationConfig,
  field: keyof CountryValidationRules
) => {
  const { $t } = useI18nStore()
  
  return {
    $validator(v: string) {
      if (!v) {
        return true
      }
      const countryRules = validationRules[country] // Get country-specific rules
      const fieldRule = countryRules[field]

      if (fieldRule) {
        const fieldRegex = new RegExp(fieldRule.regex)
        return fieldRegex.test(v)
      }
      return true
    },
    $message:
      $t(validationRules[country]?.[field]?.messagePath).value ||
      $t('UseFieldValidation.FieldIsNotValidError').value,
  }
}
