import { computed, ref } from '@vue/composition-api'
import { defineStore } from 'pinia'
import { SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'

export const useOtpStore = defineStore('otp', () => {
  const securityCode = ref<string>('')
  const maskedPhone = ref<string>('')
  const maskedEmail = ref<string>('')
  const code = ref<string>('')
  const action = ref<string>('')
  const referenceValue = ref<string>('')
  const countryId = ref<string>('')
  const rememberDevice = ref<boolean>(false)
  const nextRoute = ref<string>('')
  const emailOtp = ref<boolean>(false)
  const deliveryType = ref<string>('')
  const otpType = ref<string>('')

  const getRememberDevice = computed(() => rememberDevice.value)
  const getMaskedEmail = computed(() => maskedEmail.value)
  const getMaskedPhone = computed(() => maskedPhone.value)
  const getCode = computed(() => code.value || '')
  const getNextRoute = computed(() => nextRoute.value)
  const isEmailOtp = computed(() => emailOtp.value)
  const getSegmentLocation = computed(() => {
    switch (otpType.value) {
      case 'login':
        return SEGMENT_LOCATIONS.LOGIN
      case 'registration':
        return SEGMENT_LOCATIONS.REGISTRATION
      case 'forgot-password':
        return SEGMENT_LOCATIONS.FORGOT_PASSWORD
      case 'edit-phone':
        return SEGMENT_LOCATIONS.CHANGE_PHONE
      default:
        return ''
    }
  })
  const getSecurityCode = computed(() => securityCode.value)
  const getReferenceValue = computed(() => referenceValue.value)

  const setNextRoute = (newNextRoute: string) => {
    nextRoute.value = newNextRoute
  }
  const setSecurityCode = (newSecurityCode: string) => {
    securityCode.value = newSecurityCode
  }
  const setMaskedPhone = (newMaskedPhone: string) => {
    maskedPhone.value = newMaskedPhone
    maskedEmail.value = ''
  }
  const setMaskedEmail = (newMaskedEmail: string) => {
    maskedEmail.value = newMaskedEmail
    maskedPhone.value = ''
  }
  const setAction = (newAction: string) => {
    action.value = newAction
  }
  const setCountryId = (newCountryId: string) => {
    countryId.value = newCountryId
  }
  const setCode = (newCode: string) => {
    code.value = newCode
  }
  const setRememberDevice = (newRemember: boolean) => {
    rememberDevice.value = newRemember
  }
  const setEmailOtp = (newEmailOtp: boolean) => {
    emailOtp.value = newEmailOtp
  }
  const setDeliveryType = (newDeliveryType: string) => {
    deliveryType.value = newDeliveryType
  }
  const setOtpType = (newType: string) => {
    otpType.value = newType
  }
  const setReferenceValue = (newReferenceValue: any) => {
    referenceValue.value = newReferenceValue
  }
  const reset = () => {
    code.value = ''
    securityCode.value = ''
    maskedPhone.value = ''
    maskedEmail.value = ''
    action.value = ''
    referenceValue.value = ''
    countryId.value = ''
    emailOtp.value = false
    deliveryType.value = ''
  }
  const setFromResult = (result: any) => {
    setMaskedPhone(result.maskedValue)
    setReferenceValue(result.referenceValue)
    setSecurityCode(result.securityCode)
    setCode(result.otpCode || '')
    setEmailOtp(result.isEmailOtp)
    setNextRoute(result.nextRoute || null)
  }

  return {
    // State
    securityCode,
    maskedPhone,
    maskedEmail,
    code,
    action,
    referenceValue,
    countryId,
    rememberDevice,
    nextRoute,
    emailOtp,
    deliveryType,
    otpType,

    // Getters
    getMaskedEmail,
    getMaskedPhone,
    getCode,
    getNextRoute,
    isEmailOtp,
    getSegmentLocation,
    getRememberDevice,
    getSecurityCode,
    getReferenceValue,

    // Actions
    setNextRoute,
    setSecurityCode,
    setMaskedPhone,
    setMaskedEmail,
    setAction,
    setCountryId,
    setCode,
    setRememberDevice,
    setEmailOtp,
    setDeliveryType,
    setOtpType,
    setReferenceValue,
    reset,
    setFromResult,
    // requestCode,
  }
})
