<template>
  <InformationAppModal :value="modelToUse" icon="warningYellow">
    <h1 class="title">{{ $t('UnableToSetProvider.ModalTitle').value }}</h1>
    <p>{{ $t('UnableToSetProvider.ModalText').value }}</p>

    <template #footer>
      <AppButton @click="gotIt">{{ $t('UnableToSetProvider.GotitButton').value }}</AppButton>
      <AppButton theme="secondary" @click="helpCentre">
        {{ $t('UnableToSetProvider.HelpCentreButton').value }}
      </AppButton>
    </template>
  </InformationAppModal>
</template>

<script>
import { ref, computed } from '@vue/composition-api'

import { useRouter } from '@galileo/composables/useRouter'

import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'

import { AppButton, useVModel } from '@oen.web.vue2/ui'
import HelpDesk, { HelpDeskProperties } from '@galileo/constants/helpdesk.const'

import { useI18nStore, useAppStore, useSendMoneyStore } from '@galileo/stores'

export default {
  name: 'UnableToSetProviderModal',
  components: {
    AppButton,
    InformationAppModal,
  },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: undefined,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)
    const router = useRouter()
    const appStore = useAppStore()
    const sendMoneyStore = useSendMoneyStore()

    const modelToUse = computed({
      get() {
        if (model.value === undefined) {
          return sendMoneyStore.unableToSetProvider
        } else {
          return model.value
        }
      },
      set(newValue) {
        if (model.value === undefined) {
          sendMoneyStore.unableToSetProvider = newValue
        } else {
          model.value = newValue
        }
      },
    })
    const loadingHelpDesk = ref(false)

    const gotIt = () => {
      sendMoneyStore.unableToSetProvider = false
      router.push('/send-money')
    }

    const helpCentre = () => {
      appStore.openHelpDesk({
        loadingRef: loadingHelpDesk,
        constName: HelpDeskProperties.WHY_COULDN_T_MY_TRANSFER_BE_COMPLETED,
        article: HelpDesk[HelpDeskProperties.WHY_COULDN_T_MY_TRANSFER_BE_COMPLETED],
        defaultHelpCentre: true,
      })
    }

    return {
      $t,
      model,
      modelToUse,
      gotIt,
      helpCentre,
    }
  },
}
</script>

<style scoped></style>
