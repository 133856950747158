import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  ({ profileId }, config) => {
    return {
      ...config,
      method: 'GET',
      url: `profiles/${profileId}/gtmtaginfo`,
    }
  },
  (response) => {
    return response
  }
)
