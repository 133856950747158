import { DeliveryMethod } from '@galileo/models/Transaction/app'
import {
  singleBankRecipientFieldsMapping,
  bankRecipientFieldsMapping,
  nonBankRecipientFieldsMapping,
} from '@galileo/constants/recipients/recipientFields.const.js'

export class RecipientResult {
  constructor(data, deliveryMethod) {
    this.deliveryMethod = deliveryMethod

    for (const { name, value } of data.fields) {
      const fieldName = nonBankRecipientFieldsMapping[name]
      if (fieldName) {
        this[fieldName] = value
      }
    }
  }
}

export class BankRecipientResult extends RecipientResult {
  constructor(data, isGlobal = false) {
    super(data, DeliveryMethod.BankDeposit.enumName)

    //from get all recipient response
    if (data.recipient) {
      this.isGlobal = isGlobal

      this.id = data.recipient.recipientId
      this.bankAccountNumber = data.recipient.accountNumber
      this.country = data.recipient.bankCountryCode
      this.bankName = data.recipient.bankName
      this.currency = data.recipient.currencyCode
      this.firstName = data.recipient.recipientDisplayName
      this.fullName = data.recipient.recipientDisplayName
    } else {
      //from get single recipient
      for (const { name, value } of data.fields) {
        const property = singleBankRecipientFieldsMapping[name]
        if (property) {
          this[property] = value
        }
      }
    }
    this.fieldValues = {}
    const hasFirstName = data.fields.find((field) => field.name === 'FIRST_NAME')

    for (const { name, value } of data.fields) {
      const property = bankRecipientFieldsMapping[name]

      if (property) {
        this.fieldValues[name] = value
        switch (name) {
          case 'NAME_VERIFIED':
            // if there's no first name,that means split name feature is disabled,
            // so set nameVerified to true
            if (!hasFirstName) {
              this[property] = true
            } else {
              this[property] = value !== 'False'
            }
            break
          case 'IS_OWN_ACCOUNT':
          case 'IS_BUSINESS':
            this.handleIsOwnAccountOrBusiness(value, property)
            break
          case 'NICKNAME':
            this.handleNickname(value, property)
            break
          case 'DEACTIVATED':
            this.handleDeactivated(value, property)
            break
          case 'HAS_RIA_ALERT_ACCEPTED':
            this.handleHasRiaAlertAccepted(value, property)
            break
          default:
            this[property] = value
            break
        }
      }
    }

    this.handleNameMapping(data)

    this.jsonResponse = data
  }

  handleIsOwnAccountOrBusiness(value, property) {
    if (value && ['True', 'False'].includes(value)) {
      this[property] = value === 'True'
    } else {
      this[property] = undefined
    }
  }

  handleNickname(value, property) {
    this[property] = value

    if (this.isGlobal) {
      value = this.accountName
    }

    this.fullName = value ?? this.accountName
  }

  handleDeactivated(value, property) {
    this[property] = value === 'True'
  }

  handleHasRiaAlertAccepted(value, property) {
    this[property] = value ? value === 'True' : false
  }

  handleNameMapping(data) {
    const hasFirstName = data.fields.find((field) => field.name === 'FIRST_NAME')

    if (this.accountName && !hasFirstName) {
      const [firstName, ...rest] = this.accountName.split(' ')
      this.firstName = firstName
      this.lastName = rest.join(' ')
    } else {
      this.firstName = data.fields.find((field) => field.name === 'FIRST_NAME').value
      this.lastName = data.fields.find((field) => field.name === 'LAST_NAME').value
      this.middleName = data.fields.find((field) => field.name === 'MIDDLE_NAME').value
    }
  }
}

export class CashPickupRecipientResult extends RecipientResult {
  constructor(data) {
    super(data, DeliveryMethod.CashPickup.enumName)
  }
}

export class MobileWalletRecipientResult extends RecipientResult {
  constructor(data) {
    super(data, DeliveryMethod.MobileWallet.enumName)
  }
}
