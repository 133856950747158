<template>
  <AppModal v-model="model" :title="$t('EditNameModal.Title').value" @show="onShow">
    <template #header="{ dismiss }">
      <AppModalHeader>
        <h1>{{ $t('EditNameModal.Title').value }}</h1>
        <template #right>
          <XeBackButton analytics-name="change-name-close" icon="x" @click="dismiss" />
        </template>
      </AppModalHeader>
    </template>

    <template #default>
      <AppSpinnerBig :loading="isLoading" inline />

      <form v-if="!isLoading" id="name-form" @submit.prevent>
        <p class="name-note">{{ $t('EditNameModal.NameNoticeText').value }}</p>
        <AppInputText
          v-model="firstName"
          type="text"
          :label="$t('EditNameModal.FirstNameLabel').value"
          :validation="validation.firstName"
        />
        <AppInputText
          v-if="!isCorporateAccount && isAPAC"
          v-model="middleName"
          type="text"
          :label="$t('EditNameModal.MiddleNameLabel').value"
          :validation="validation.middleName"
        />
        <AppInputText
          v-model="lastName"
          type="text"
          :label="$t('EditNameModal.LastNamesLabel').value"
          :helper-text="$t('EditNameModal.MultipleSurnamesText').value"
          :validation="validation.lastName"
        />
      </form>
    </template>

    <template #footer>
      <AppModalFooter>
        <AppButton
          type="submit"
          form="name-form"
          analytics-name="change-name-update-button"
          :loading="isSaving"
          :disabled="validation.$anyInvalid || isLoading"
          @click="updateName"
        >
          {{ $t('EditNameModal.UpdateButton').value }}
        </AppButton>
      </AppModalFooter>
    </template>
  </AppModal>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { storeToRefs } from 'pinia'

import { useValidation } from 'vue-composable'

import { required } from '@vuelidate/validators'

import XeBackButton from '@galileo/components/XeBackButton/XeBackButton'

import {
  useVModel,
  useAlert,
  AppModal,
  AppModalFooter,
  AppButton,
  AppCardHeader,
  AppInputText,
  AppModalHeader,
  AppSpinnerBig,
} from '@oen.web.vue2/ui'

import { useProfileStore, useAppStore, useI18nStore, useAuthStore } from '@galileo/stores'

export default {
  name: 'EditNameModal',
  components: {
    AppModal,
    AppModalFooter,
    AppButton,
    AppCardHeader,
    AppInputText,
    XeBackButton,
    AppModalHeader,
    AppSpinnerBig,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    onSave: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { model } = useVModel(props, emit)
    const { $t } = useI18nStore()
    const { add } = useAlert()
    const isLoading = ref(false)
    const authStore = useAuthStore()
    const appStore = useAppStore()
    const profileStore = useProfileStore()

    const firstName = ref(props.user.firstName)
    const lastName = ref(props.user.lastName)
    const middleName = ref(props.user.middleName)

    const isSaving = ref(false)
    const { isCorporateAccount } = storeToRefs(authStore)
    const customerCountry = computed(() => authStore.userProfile.country)
    const isAPAC = computed(() => customerCountry.value === 'AU' || customerCountry.value === 'NZ')

    const { getFirstNameRegex, getMiddleNameRegex, getLastNameRegex } = storeToRefs(profileStore)

    const onShow = async () => {
      isLoading.value = true
      if (!isCorporateAccount.value && isAPAC.value) {
        await profileStore.getNameRegexes()
      }
      isLoading.value = false
    }

    const validation = useValidation({
      firstName: {
        $value: ref(firstName),
        required: {
          $validator: required.$validator,
          $message: $t('EditPersonalInfoModal.FirstNameRequiredError').value,
        },
        validFirstName: {
          $validator(v) {
            return getFirstNameRegex.value.test(v)
          },
          $message: $t('EditNameModal.EnterValidFirstNameError').value,
        },
      },
      middleName: {
        $value: ref(middleName),
        validMiddleName: {
          $validator(v) {
            if (v.length > 0) {
              return getMiddleNameRegex.value.test(v)
            }
            return true
          },
          $message: $t('EditNameModal.EnterValidMiddleNameError').value,
        },
      },
      lastName: {
        $value: ref(lastName),
        required: {
          $validator: required.$validator,
          $message: $t('EditPersonalInfoModal.LastNameRequiredError').value,
        },
        validLastName: {
          $validator(v) {
            return getLastNameRegex.value.test(v)
          },
          $message: $t('EditNameModal.EnterValidLastNameError').value,
        },
      },
    })

    const updateName = async () => {
      isSaving.value = true
      const payload = {
        firstName: firstName.value,
        lastName: lastName.value,
        middleName: middleName.value,
      }
      profileStore.updateUserInfo(payload).then((success) => {
        if (success) {
          props.onSave()
          add(`${$t('EditNameModal.NameUpdated').value}`)
          model.value = false
        } else {
          appStore.messageBoxGenericError()
        }
        isSaving.value = false
      })
    }

    return {
      model,
      $t,
      validation,
      isSaving,
      firstName,
      lastName,
      middleName,
      updateName,
      isCorporateAccount,
      isAPAC,
      onShow,
      isLoading,
    }
  },
}
</script>

<style scoped>
.name-note {
  @apply mb-6 text-gray-text;
}

::v-deep .card-content {
  @apply px-12;
  .card-content-block {
    @apply mb-6 text-sm leading-5;
  }
}

::v-deep .card.card--has-footer {
  @screen sm {
    @apply pb-12;
  }
}

.card-footer {
  .button {
    @apply w-full;
  }
}

.button.button--primary {
  &:disabled {
    &:not(.button--loading) {
      @apply text-white;
    }

    background: linear-gradient(
        to right,
        theme('colors.blue.button-disabled'),
        theme('colors.blue.button-disabled')
      ),
      linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
  }
}
</style>
