import { useCountriesStore } from '@galileo/stores'

function currency(value, currency) {
  const formatterCurrency = currency ? currency : 'USD'
  const formatterParameters = {
    style: 'currency',
    currency: formatterCurrency,
    currencyDisplay: 'code',
  }

  let locale = getLocaleLang()
  if (typeof value !== 'number') {
    return value
  }
  locale = 'en-US'

  const stringValue = value.toString()
  if (stringValue.includes('.')) {
    const splitValue = stringValue.split('.')
    const numberOfDecimals = splitValue[1].length
    formatterParameters.minimumFractionDigits = numberOfDecimals
  }

  let formatter = new Intl.NumberFormat(locale ?? 'en-US', formatterParameters)
  return (
    formatter.format(value).replace(formatterCurrency, '').trim() + (currency ? ' ' + currency : '')
  )
}

function getLocaleLang() {
  if (navigator.languages != undefined) return navigator.languages[0]
  return navigator.language
}

function maxLength(value, length) {
  if (value.length > length) {
    let sub = value.substring(0, length - 3)
    sub += '...'
    return sub
  }
  return value
}

const addPrecision = (amount) => {
  const isIntiger = Number.isSafeInteger(amount)
  if (isIntiger) {
    return amount + '.00'
  }
  return amount
}

const getCountryName = (countryIsoCode) => {
  const countriesStore = useCountriesStore()

  const country = countriesStore.getCountryByIsoCode(countryIsoCode)
  if (country) {
    return country.text
  }
  return countryIsoCode
}

export { currency, maxLength, addPrecision, getCountryName }
