export const SEGMENT_ERRORS = {
  LOGIN_ATTEMPTS: 'Too many failed login attempts',
  LOGIN_LOCK: 'Your account is locked for security reasons',
}

export const SEGMENT_EVENTS = {
  ACCOUNT_ACCESSED: 'Account accessed',
  ACCOUNT_CREATION_COMPLETED: 'Account Creation Completed (Client-side)',
  ACCOUNT_NOT_APPROVED: 'Account Not Approved Prompt Displayed',
  ACTIVITY_ACCESSED: 'Activity Accessed',
  ADDITIONAL_DETAILS_ENTERED: 'Additional Details Entered',
  ADDRESS_CREATED: 'Address Created',
  ADDRESS_UPDATED: 'Address Updated',
  BANK_ACCOUNT_SELECTION_STARTED: 'Bank Account Selection Started',
  BANK_ACCOUNT_SELECTION_COMPLETED: 'Bank Account Selection Completed',
  BANK_VERIFICATION_COMPLETED: 'Bank Verification Completed',
  BANK_VERIFICATION_FAILED: 'Bank Verification Failed',
  BANK_VERIFICATION_STARTED: 'Bank Verification Started',
  BANK_VERIFICATION_SUCCESS: 'Bank Verification Completed',
  COUNTRY_CONFIRMED: 'Country Confirmed',
  CVV_ENTERED: 'CVV Entered',
  DESTINATION_COUNTRY_SELECTED: 'Destination Country Selected',
  FORGOT_PASSWORD_STARTED: 'Password Recovery Started',
  ID_UPDATED: 'ID Updated',
  IDENTITY_VERIFICATION_STARTED: 'Identity Verification Started',
  LANGUAGE_UPDATED: 'Language Updated',
  LANGUAGE_SELECTED: 'Language Selected',
  LOGIN_FAILED: 'Login Failed',
  NEW_PAYMENT_METHOD_ADDED: 'New Payment Method Added',
  NEW_PAYMENT_METHOD_STARTED: 'New Payment Method Started',
  NOTIFICATIONS_UPDATED: 'Notifications Updated',
  OTP_HELP_SELECTED: 'OTP Help Selected',
  PASSWORD_CREATED: 'Password Created',
  PAYMENT_METHOD_DELETED: 'Payment Method Deleted',
  PAYMENT_METHOD_SELECTED: 'Payment Method Selected',
  PAYMENT_METHOD_UPDATED: 'Payment Method Updated',
  PAYMENT_METHODS_ACCESSED: 'Payment Methods Accessed',
  PAYOUT_METHOD_SELECTED: 'Payout Method Selected',
  PAYMENT_FAILED: 'Payment Failed',
  PEP_QUESTION_ANSWERED: 'PEP Question Answered',
  PHONE_ADDED: 'Phone Added',
  PHONE_FAILED: 'Phone Failed',
  PHONE_UPDATED: 'Phone Updated',
  NON_SUPPORTED_PHONE_NUMBER_TYPE_ENTERED: 'Non Supported Phone Number Type Entered',
  PICKUP_AGENT_SELECTED: 'Pick Up Agent Selected',
  PAYOUT_LOCATION_SELECTED: 'Payout Location Selected',
  PICKUP_LOCATION_SELECTED: 'Pick Up Location Selected',
  PAYOUT_LOCATION_CHANGED: 'Payout Location Changed',
  PROFILE_CREATED: 'Profile Created',
  PROFILE_UPDATED: 'Profile Updated',
  REFER_A_FRIEND_CLICKED: 'Refer a Friend Clicked',
  REFER_A_FRIEND_CODE_SHARED: 'Refer a Friend Code Shared',
  PROMOTIONS_ACCESSED: 'Promotions Accessed',
  PROMO_CODE_ADDED: 'Promo Code Added',
  PROMOTION_SELECTED: 'Promotion Selected',
  PROMOTION_REMOVED: 'Promotion Removed',
  DESTINATION_COUNTRY_STARTED: 'Destination Country Started',
  QUOTE_ACCESSED: 'Quote Accessed',
  QUOTE_CALCULATED: 'Quote Calculated',
  QUOTE_CREATED: 'Quote Created',
  QUOTE_CONFIRMED: 'Quote Confirmed',
  QUOTE_CANCELLED: 'Quote Cancelled',
  QUOTE_ERROR: 'Quote Error',
  QUOTE_PAYOUT_METHOD_SELECTED: 'Quote Payout Method Selected',
  QUOTE_PAYOUT_METHOD_SELECTION_STARTED: 'Quote Payout Method Selection Started',
  QUOTE_PAYMENT_METHOD_CHANGED: 'Quote Payment Method Changed',
  QUOTE_DUPLICATED_WARNING_DISPLAYED: 'Duplicate Quote Warning Displayed',
  QUOTE_DUPLICATED_WARNING_PROCEEDED: 'Duplicate Quote Warning Proceeded',
  QUOTE_DUPLICATED_WARNING_CANCELLED: 'Duplicate Quote Warning Cancelled',
  RECIPIENT_ACCESSED: 'Recipients Accessed',
  RECIPIENT_BANK_DETAILS_ADDED: 'Recipient Bank Details Added',
  RECIPIENT_CREATED: 'Recipient Created',
  RECIPIENT_SELECTED: 'Recipient Selected',
  RECIPIENT_SELECTION_FAILED: 'Recipient Selection Failed',
  RECIPIENT_STARTED: 'Recipient Creation Started',
  RECIPIENT_ADDRESS_STARTED: 'Recipient Address Started',
  RECIPIENT_ADDRESS_COMPLETED: 'Recipient Address Completed',
  RECIPIENT_INFO_ADDED: 'Recipient Info Added',
  RECIPIENT_LOCATION_ADDED: 'Recipient Location Added',
  RECIPIENT_TYPE_SELECTED: 'Recipient Type Selected',
  RECIPIENT_INFORMATION_MISSING_STARTED: 'Recipient Information Missing Started',
  RECIPIENT_INFORMATION_MISSING_COMPLETED: 'Recipient Information Missing Completed',
  GLOBAL_RECIPIENT_STARTED: 'Global Recipient Started',
  REGISTRATION_STARTED: 'Registration Started',
  REMEMBER_DEVICE_SELECTED: 'Remember Device Selected',
  SECURITY_METHOD_STARTED: 'Security Method Started',
  TRANSACTION_EDIT: 'Transaction Edit',
  BANK_TRANSFER_INSTRUCTIONS_ACCEPTED: 'Bank Transfer Instructions Accepted',
  TRANSACTION_SUMMARY_CONFIRMED: 'Transaction Summary Confirmed',
  REVIEW_TRANSFER: 'Review Transfer',
  REVIEW_TRANSACTION_SUMMARY_CONFIRMED: 'Review Transaction Summary Confirmed',
  BANK_DETAILS_VIEWED: 'Bank Details Viewed',
  TRANSACTION_CANCELLATION_REQUESTED: 'Transaction Cancellation Requested',
  TRANSACTION_RECIPIENT_CHANGE_REQUESTED: 'Transaction Recipient Change Requested',
  RECEIPT_VIEWED: 'Receipt Viewed',
  REPEAT_TRANSFER_STARTED: 'Repeat Transfer Started',
  TRANSFER_DETAILS_ACCESSED: 'Transfer Details Accessed',
  TRANSFER_DETAILS_SHARED: 'Transfer Details Shared',
  REGULAR_PAYMENTS: 'Regular Payments Accessed',
  PAYMENTS_BALANCES: 'Payments & Balances Accessed',
  MARKET_ORDERS: 'Market Orders Accessed',
  MORE_MENU: 'More Menu Accessed',
  CHANGE_EMAIL_STARTED: 'Change Email Started',
  CHANGE_PASSWORD_STARTED: 'Change Password Started',
  CHANGE_NAME_STARTED: 'Change Name Started',
  CHANGE_PHONE_NUMBER_STARTED: 'Change Phone Number Started',
  CHANGE_PERSONAL_ADDRESS_STARTED: 'Change Personal Address Started',
  CHANGE_OCCUPATION_STARTED: 'Change Occupation Started',
  SETTINGS_ACCESSED: 'Settings Accessed',
  SOMETHING_WENT_WRONG: 'Something Went Wrong',
  UPLOAD_DOCUMENTS_ACCESSED: 'Upload Documents Accessed',
  KYC_REFRESH_REQUIRED: 'KYC Refresh Required',
  KYC_REFRESH_CONFIRMED: 'KYC Refresh Confirmed',
  KYC_REFRESH_COMPLETED: 'KYC Refresh Completed',
  KYC_REFRESH_SKIPPED: 'KYC Refresh Skipped',
  KYC_REFRESH_DETAIL_EDIT_STARTED: 'KYC Refresh Detail Edit Started',
  KYC_REFRESH_BUSINESS_NAME_SAVED: 'KYC Refresh Business Name Saved',
  KYC_REFRESH_ADDRESS_SAVED: 'KYC Refresh Address Saved',
  KYC_REFRESH_INDIVIDUAL_INDIVIDUAL_SAVED: 'KYC Refresh Individual Saved',
  KYC_REFRESH_INDIVIDUAL_EDIT_STARTED: 'KYC Refresh Individual Edit Started',
  KYC_REFRESH_INDIVIDUAL_ADD_STARTED: 'KYC Refresh Individual Add Started',
  KYC_REFRESH_INDIVIDUAL_DELETED: 'KYC Refresh Individual Deleted',
  KYC_REFRESH_ALL_INDIVIDUALS_SAVED: 'KYC Refresh All Individuals Saved',
  KYS_MISSING_INFORMATION_PROMPT_DISPLAYED: 'KYC Missing Information Prompt Displayed',
  EDD_TRANSFER_REASON_PROOF_COMPLETED: 'EDD Transfer Reason Proof Completed',
  EDD_PERSONAL_INFORMATION_COMPLETED: 'EDD Personal Information Completed',
  EDD_ID_INFORMATION_COMPLETED: 'EDD ID Information Completed',
  EDD_ID_MOBILE_NUMBER_COMPLETED: 'EDD ID Mobile Number Completed',
  EDD_RELATIONSHIP_TO_BENEFICARY_COMPLETED: 'EDD Relationship To Beneficary Completed',
  EDD_SOURCE_OF_FUNDS_COMPLETED: 'EDD Source of Funds Completed',
  EDD_PROOF_OF_ADDRESS_COMPLETED: 'EDD Proof Of Address Completed',
  EDD_CERTIFIED_PROOF_OF_IDENTITY_COMPLETED: 'EDD Proof Of Identity Completed',
  SWITCH_TO_CLASSIC_STARTED: 'Switch to Classic Started',
  MARKETING_CONSENT_EMAIL_SEND: 'Marketing Consent email send',
  SWITCH_TO_CLASSIC_CONFIRMED: 'Switch to Classic Confirmed',
  OFFLINE_TO_ONLINE_START: 'Offline to Online Start',
  WALLET_PROVIDER_SELECTED: 'Wallet Provider Selected',
  WALLET_DETAILS_STARTED: 'Wallet Details Started',
  WALLET_DETAILS_ADDED: 'Wallet Details Added',
  WALLET_DETAILS_FAILED: 'Wallet Validation Failed',
  INVALID_RECIPIENT_POPUP_DISPLAYED: 'Invalid Recipient Popup Displayed',
  INVALID_RECIPIENT_POPUP_ACK: 'Invalid Recipient Popup Acknowledged',
  PENDING_DIRECT_DEBIT_VERIFICATION: 'Pending Verification Prompt Displayed',
  MANUAL_PAYMENT_METHOD_PROMPT_DISPLAYED: 'Manual Payment Method Prompt Displayed',
  PAYMENT_METHOD_PICKER_ACCESSED: 'Payment Method Picker Accessed',
  NEW_SITE_PROMPT_DISPLAYED: 'New Site Prompt Displayed',
  LEGACY_SITE_PROMPT_DISPLAYED: 'Legacy Site Prompt Displayed',
  LEGACY_SITE_INITIATED: 'Legacy Site Initiated',
  MICRO_DEPOSIT_LINK_PENDING: 'Micro Deposit Link Pending',
  RECIPIENT_DETAILS_CONFIRM_STARTED: 'Recipient Details Confirm Started',
  RECIPIENT_DETAILS_CONFIRMED: 'Recipient Details Confirmed',
  FEEDBACK_SUBMITTED: 'Feedback Submitted',
  BIOMETRIC_CONSENT_PROVIDED: 'Biometric Consent Provided',
  BIOMETRIC_VERIFICATION_STARTED: 'Biometric Verification Started',
  BIOMETRIC_VERIFICATION_EXITED: 'Biometric Verification Exited',
  BIOMETRIC_VERIFICATION_SUBMITTED: 'Biometric Verification Submitted',
  BIOMETRIC_VERIFICATION_COMPLETED: 'Biometric Verification Completed [client-side]',
  QUICK_TRANSFER_ACCESSED: 'Quick Transfer Accessed',
  QUICK_TRANSFER_COMPLETED: 'Quick Transfer Completed',
  OPEN_BANKING_INFO_PAGE_DISPLAYED: 'Open Banking Info Page Displayed',
  OPEN_BANKING_QUOTE_EXPIRED: 'Open Banking Quote Expired',
  CARD_QUOTE_EXPIRED: 'Card Quote Expired',
  CHANGE_PAYMENT_METHOD_TRIGGERED: 'Change Payment Method Triggered',
  TRY_AGAIN_TRIGGERED: 'Try Again Triggered',
  OPEN_BANKING_CANCELLED_TRIGGERED: 'Open Banking Cancelled By User Triggered',
  RATE_UPDATE_MODAL_DISPLAYED: 'Rate Update Modal Displayed',
  INTERAC_TRANSFER_INSTRUCTIONS_ACCEPTED: 'Interac Transfer Instructions Accepted',
  FUND_BALANCE_ACCESSED: 'Fund Balance Accessed',
  FUND_BALANCE_COMPLETED: 'Fund Balance Completed',
  CONVERT_BALANCE_COMPLETED: 'Convert Balance Completed',
  CONVERT_BALANCE_ACCESSED: 'Convert Balance Accessed',
  CONFIG_ISSUE_DETECTED: 'Config issue detected',
  SAVED_PAYMENT_METHODS: 'Saved Payment Methods',
  PAYMENT_METHOD_EXPIRED: 'Payment Method Expired',
  PAYMENT_METHOD_CHANGED: 'Payment Method Changed',
  CVV_PROMPTED: 'CVV Prompted',
  UPDATE_CARD_PROMPT: 'Update Card Prompt',
  NEW_CARD_ADDED_ERROR: 'New Card Added Error',
  CARD_AUTHORISATION_STARTED: 'Card Authorisation Started',
  CARD_AUTHORISATION_COMPLETED: 'Card Authorisation Completed',
  CARD_AUTHORISATION_FAILED: 'Card Authorisation Failed',
  TRANSACTION_CANCELLED: 'Transaction Cancelled',
  REPEAT_PAYMENT_STARTED: 'Repeat Payment Started',
  REPEAT_PAYMENT_METHOD_NOT_AVAILABLE: 'Repeat Payment Method Not Available',
  REPEAT_PAYMENT_RESUMED: 'Repeat Payment Resumed',
  EDIT_CARD_BILLING_ADDRESS_STARTED: 'Edit Card Billing Address Started',
  EDIT_CARD_BILLING_ADDRESS_MANUALLY: 'Edit Card Billing Address Manually',
  EDIT_CARD_BILLING_ADDRESS_SELECTED: 'Edit Card Billing Address Selected',
  CARD_BILLING_ADDRESS_SAME_AS_RESIDENTIAL:
    'Card Billing Address Same as Residential Address Ticked',
  CARD_BILLING_ADDRESS_NOT_SAME_AS_RESIDENTIAL:
    'Card Billing Address Same as Residential Address Unticked',
  PAYMENT_METHOD_ADD_ATTEMPT_LIMIT_REACHED: 'Payment Method Add Attempt Limit Reached',
  NON_SUPPORTED_CARD_BILLING_COUNTRY_ADDED: 'Non Supported Card Billing Country Added',
  UPDATE_PAYMENT_METHOD_STARTED: 'Update Payment Method Started',
  LIQUIDITY_MANAGER_CHANGED: 'Liquidity Manager Changed',
  LIQUIDITY_MANAGER_CHANGE_HELP_CLICKED: 'Liquidity Manager Change Help Clicked',
  LIQUIDITY_MANAGER_CHANGE_ACCEPTED: 'Liquidity Manager Change Accepted',
  LIQUIDITY_MANAGER_CHANGE_DECLINED: 'Liquidity Manager Change Declined',
}

export const MICRO_DEPOSIT_TYPE = {
  SAME_DAY: 'Same Day',
  AUTOMATED: 'Automated',
}

export const SEGMENT_IDENTITY_VERIFICATION_PLATFORMS = {
  // IDOLOGY: 'IDology',
  ONFIDO: 'Onfido',
  VERIFF: 'Veriff',
  // TRUNARRATIVE: 'TruNarrative',
}

export const SEGMENT_PAYMENT_METHOD_VERIFICATION_TYPES = {
  MANUAL: 'Manual',
  PLAID: 'Plaid',
}

export const SEGMENT_LOCATIONS = {
  ACTIVITY: 'Activity',
  REGISTRATION: 'Registration',
  FORGOT_PASSWORD: 'Forgot Password',
  CHANGE_PHONE: 'Change phone',
  LOGIN: 'Log in',
  SEND_MONEY: 'sendMoney',
  QUOTE: 'Quote',
  SEND_CTA: 'send CTA',
  RECIPIENT: 'Recipients',
  RECIPIENT_LIST: 'recipient-list',
  ACCOUNT: 'Account',
  NOT_SEND_MONEY: 'Not Send Money',
  SEND_AGAIN: 'Send Again',
  SETTINGS: 'settings',
  TRANSACTION_SUMMARY: 'transaction_summary',
  TRANSFER_DETAILS: 'Transfer Details',
  CASH_RECIPIENT_DETAILS: 'CashRecipientDetails',
  KYC_REFRESH: 'kyc-refresh',
  REVIEW_TRANSFER: 'review_transfer',
  QUICK_TRANSFER: 'quick transfer',
  INDIVIDUAL_CURRENCY: 'singleCurrencyPage',
  OPEN_BALANCE: 'openBalance',
  FUND: 'fund',
  I_WANT_TO_MENU: 'iWantToMenu',
  BALANCES_OVERVIEW: 'balancesOverview',
  SINGLE_CURRENCY_PAGE: 'singleCurrencyPage',
}

export const SEGMENT_LOCATIONS_ROUTES = {
  ACTIVITY: 'activity',
  QUICK_TRANSFER: 'QuickTransfer',
  BALANCES_OVERVIEW: 'BalancesOverview',
  INDIVIDUAL_CURRENCY: 'IndividualCurrency',
}
export const SEGMENT_LOGIN_METHODS = {
  EMAIL: 'Email',
  PHONE: 'Phone',
  REGISTRATION: 'Registration',
  RECOVERY: 'Password Recovery',
}

export const SEGMENT_PAYMENT_CARD_STATUS = {
  EXPIRED: 'Expired',
  ACTIVE: 'Active',
  INVALID: 'Invalid',
}

export const SEGMENT_PAYMENT_METHOD_TYPES = {
  DEBIT: 'DebitCard',
  CREDIT: 'CreditCard',
  STAGED: 'bank_transfer',
  BANK_ACCOUNT: 'DirectDebit',
  INTERAC: 'Interac',
}

export const SEGMENT_PAYOUT_METHODS = {
  BANK_DEPOSIT: 'Bank',
  OFFICE_PICKUP: 'Cash',
  HOME_DELIVERY: 'Home Delivery',
  MOBILE_PAYMENT: 'Wallet',
}

export const SEGMENT_PAYOUT_AGENT_TYPE = {
  OPEN_COUNTRY: 'Open Country',
  OPEN_NETWORK: 'Open Network',
  OPEN_PAYMENT: 'Open Payment',
  POINT_TO_POINT: 'Point-to-Point',
}

export const SEGMENT_RECIPIENT_ORIGIN = {
  EXISTING: 'existing',
  NEW: 'new',
}

export const SEGMENT_RECIPIENT_TYPE = {
  SELF: 'Self',
  INDIVIDUAL: 'Individual',
  BUSINESS: 'Business',
}

export const SEGMENT_RECIPIENT_GLOBAL_NAME = {
  ATO: 'ATO',
  NONE: 'None',
}

export const SEGMENT_EKYC_REFRESH_TYPE = {
  TRADING_ADDRESS: 'Trading Address',
  REGISTERED_ADDRESS: 'Registered Address'
}
