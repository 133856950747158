import API from '@galileo/api/rmt'
import APIHandler from '@galileo/api/APIHandler'
import { toApi } from '@galileo/models/Country/countryMapping'

export default new APIHandler(
  API,
  ({ nameOrCode, country }, config) => {
    const request = {
      bankCode: nameOrCode,
      bankName: nameOrCode + '%',
      countryTo: toApi(country),
    }
    return {
      ...config,
      method: 'PUT',
      url: 'bank/search',
      data: request,
    }
  },
  (response) => {
    if (response && response.data && response.data.model) {
      return {
        ...response,
        data: response.data.model.map((bank) => {
          return {
            ...bank,
            value: bank.bankId,
            name: bank.bankName?.toLowerCase(),
          }
        }),
      }
    } else {
      throw new Error('Failed to search for bank')
    }
  },
  (error) => {
    const firstError = error?.response?.data?.errorResponse?.errors[0]

    if (firstError && firstError.errorCode === 'BankNotFoundException') {
      return {
        ...error,
        //return empty banks array
        data: [],
      }
    } else {
      throw new Error('Failed to search for bank')
    }
  }
)
