import { setHeader, removeHeader } from '@galileo/api/APIHandler'
import * as apiHandler from '@galileo/api/APIHandler'
import { defineStore } from 'pinia'

import signInApi from '@galileo/api/launchpad/authorization/signin/post'
import changePasswordApi from '@galileo/api/launchpad/authorization/changePassword/post'
import changeEmailApi from '@galileo/api/launchpad/authorization/changeEmail/post'
import changeEmailConfirmApi from '@galileo/api/launchpad/authorization/changeEmailConfirm/post'
import changeEmailResendApi from '@galileo/api/launchpad/authorization/changeEmailResend/post'

import getProfilesApi from '@galileo/api/launchpad/profiles/get'
import getProfileApi from '@galileo/api/launchpad/profiles/_profileId/get'
import postSelectProfileApi from '@galileo/api/launchpad/authorization/selectProfile/post'
import refreshApi from '@galileo/api/launchpad/authorization/refresh/post'

import OTP from '@galileo/api/ct/OTP/post'
import putCustomerProfileApi from '@galileo/api/rmt/customer-profile/put'
import getVerifyQuestionApi from '@galileo/api/rmt/authorization/verify/get'
import getMissingRequiredFieldsApi from '@galileo/api/ct/customer/missingRequiredFields/get'
import updateTermsAndConditionsApi from '@galileo/api/launchpad/profiles/_profileId/termsandconditions/put'
import verifyQuestionApi from '@galileo/api/rmt/authorization/verify/post'
import Session from '@galileo/models/Session/app'
import postUserSettingApi from '@galileo/api/ct/user/setting/post'

import jwtDecode from 'jwt-decode'
import LogRocket from 'logrocket'
import SessionLogPayload from '@galileo/models/Logging/SessionLogPayload'
import router from '@galileo/router'
import logoutApi from '@galileo/api/launchpad/authorization/logout/post'
import { SEGMENT_EVENTS, SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'

import getGsoTokenApi from '@galileo/api/corporate/authorize/gsoToken/get'
import getLaunchpadGsoTokenApi from '@galileo/api/launchpad/authorization/gsoToken/get'
import { ACCOUNT_TYPE_ENUM } from '@galileo/constants/accountType.const'
import Cookies from 'js-cookie'

import {
  useStoredOrderStore,
  useEnvStore,
  useResourcesStore,
  useAppStore,
  useDeviceStore,
  useCardAccountStore,
  useOtpStore,
  useSessionStore,
  useBankAccountStore,
  useRegistrationStore,
  useRecipientsStore,
  usePaymentsStore,
  useAnalyticsStore,
  useProfileStore,
  useActivityStore,
  useThemeStore,
  useSendMoneyStore,
  usePromotionStore,
  useCorporateStore,
  useUserPreferencesStore,
} from '@galileo/stores'

import { ref, computed } from '@vue/composition-api'
import StringObject from '@galileo/types/StringObject'
import {
  IUserTraits,
  MatchedToken,
  SignInFields,
  SecurityQuestions,
  ResendOTPRequest,
  Token,
  UserProfile,
  ReturnRoute,
  AccessToken,
} from '@galileo/models/Auth/Interfaces/AuthFields'
// Change interfaces to lowercase
import { RawLocation, VueRouter } from 'vue-router/types/router'
import { PendingLogin } from '@galileo/models/Session/interfaces/Session'
import useOpenBanking from '@galileo/composables/useOpenBanking'
import { CALL_US_NUMBER } from '@galileo/constants/sendMoneyFlow.const'
import { StoredOrder } from '@galileo/models/StoredOrder/interfaces'
import AddressModel from '@galileo/models/Address/BillingAddresses/AddressModel'
import { useGalileoLoginUrlValidator } from '@galileo/composables/useGalileoLoginUrlValidator'
import { PRISMIC_BRAND_NAMES } from '@galileo/constants/prismic'

// copied from useRouter composable to avoid circular imports
export function redirectToRoute(redirectRoute: any) {
  if (router.currentRoute.path !== redirectRoute) {
    router.replace(redirectRoute)
  }
}

export const useAuthStore = defineStore(
  'auth',
  () => {
    // State start
    let signInFields = ref<SignInFields>({} as SignInFields)
    let token = ref<Token>({} as Token)
    let lastLogin = ref('')
    let refreshTimeout = ref<number | null>(null)
    let refreshRetries = ref<number>(0)
    let refreshLastDate = ref<Date | null>(null)
    let visibilityChangeListener = ref<EventListenerOrEventListenerObject | null>(null)
    let profileId = ref<string | null>(null)
    let userProfile = ref<UserProfile>({} as UserProfile)
    let userProfiles = ref<Array<Object> | null>(null)
    let alreadyAcceptedNewTerms = ref<boolean>(false)
    let pendingVerifyUser = ref<string | null>(null)
    let returnRoute = ref<ReturnRoute | null>(null)
    let defaultRoute = ref<ReturnRoute | null>({ name: 'Activity' })
    let loginMethod = ref<string | null>(null)
    let isTermsAndConditionsAcceptanceRequired = ref<boolean>(false)
    let termsAndConditionsRequiredVersion = ref<null>()
    let isKYCRefreshRequired = ref<boolean>(false)
    let showTACAfterAddressChange = ref<boolean>(false)
    let isAccountRestricted = ref<boolean>(false)
    let missingRequiredFields = ref<boolean | null>()
    let loginUrl = ref<string | null>('')
    let tokenUrl = ref<string | null>(null)
    let selectedUser = ref<UserProfile | null>(null)
    // State end
    const lastApiCall = ref<Date>(new Date())
    const analyticsStore = useAnalyticsStore()
    const deviceStore = useDeviceStore()
    const sessionStore = useSessionStore()

    const setLastApiCall = () => {
      lastApiCall.value = new Date()
    }

    // Getters start
    const isAuthenticated = computed((): boolean => {
      if (profileId.value && token.value) {
        return true
      }
      return false
    })

    const getIsBalancesEnabled = computed((): boolean => {
      if (userProfile?.value?.customer) {
        return userProfile.value.customer.balancesEnabled
      }

      return false
    })

    const getIsQuickTransferEnabled = computed((): boolean => {
      if (userProfile.value?.customer) {
        return userProfile.value.customer.quickTransactionsEnabled
      }

      return false
    })

    const isQuickTransferSpecificAccount = computed(
      () => getIsQuickTransferEnabled.value && !getIsBalancesEnabled.value
    )

    const getUserIdentificationDetails = computed((): string | null => {
      if (userProfile.value?.identification?.details) {
        return userProfile.value?.identification?.details
      }
      return null
    })

    // Not in use?
    const getUserId = computed((): number | undefined => {
      if (userProfile) {
        return userProfile.value?.id
      }
      return undefined
    })

    const isPendingVerifyUser = computed(() => {
      return pendingVerifyUser.value === lastLogin.value
    })

    const getPostLoginRoute = computed((): ReturnRoute | null => {
      if (returnRoute.value && returnRoute.value?.name !== 'NotFound') {
        return returnRoute.value
      }
      return defaultRoute.value
    })

    const isCorporateAccount = computed((): boolean => {
      return userProfile.value?.customer?.accountType === 'Corporate'
    })

    const isConsumerAccount = computed((): boolean => {
      return userProfile.value?.customer?.accountType === 'Consumer'
    })

    const getIsDirectDebitEnabledAccount = computed(() => {
      return userProfile.value?.customer?.onlineDirectDebitEnabled
    })

    // Not used ??
    const getCanUseOnlineDealing = computed(() => {
      return userProfile.value?.customer?.canUseOnlineDealing
    })

    // Not used ??
    const getUserRegion = computed(() => {
      return userProfile.value?.customer?.region
    })

    const getUserAddressAsRecipient = computed(() => {
      const profile = userProfile
      const addressLine1Regex = useRecipientsStore().getAddressLine1FieldRegex
      let addressLine1 = ''

      if (profile.value?.extraFields?.unit) {
        addressLine1 = `${profile.value.extraFields.unit},`
      }

      addressLine1 += profile.value?.extraFields?.addressLine1 ?? profile.value?.street

      if (profile.value?.extraFields?.addressLine2) {
        addressLine1 = `${addressLine1}, ${profile.value.extraFields.addressLine2}`
      }

      if (
        !addressLine1Regex.test(addressLine1) &&
        (profile.value?.extraFields?.addressLine1 || profile.value?.street)
      ) {
        addressLine1 = profile.value.extraFields.addressLine1 ?? profile.value.street
      }
      return addressLine1
    })
    // Getters end

    // Actions start
    function resetState(): void {
      signInFields.value = {} as SignInFields
      token.value = {} as Token
      refreshTimeout.value = null
      refreshRetries.value = 0
      refreshLastDate.value = null
      visibilityChangeListener.value = null
      profileId.value = null
      userProfile.value = {} as UserProfile
      userProfiles.value = null
      alreadyAcceptedNewTerms.value = false
      defaultRoute.value = { name: 'Activity' }
      loginMethod.value = null
      isTermsAndConditionsAcceptanceRequired.value = false
      termsAndConditionsRequiredVersion.value = null
      isKYCRefreshRequired.value = false
      showTACAfterAddressChange.value = false
      isAccountRestricted.value = false
      missingRequiredFields.value = false
      loginUrl.value = ''
      tokenUrl.value = null
      selectedUser.value = null
    }

    const replaceLoginUri = (urlAction = 'login', redirectName = 'redirect_uri') => {
      const envStore = useEnvStore()
      const urlParams = new URLSearchParams(window.location.search)
      const actions = urlParams.get('galileoAction')

      const parts = loginUrl.value?.split('?')

      if (parts && parts.length === 2) {
        let params = parts[1].split('&')

        params = params.map((param: string) => {
          if (param && param.indexOf('redirect_uri=') === 0) {
            param =
              redirectName +
              '=' +
              encodeURIComponent(envStore.appUrlTransfer) +
              'account/login/home'
          }
          return param
        })

        let url = parts[0].replace('/signup', '/' + urlAction) + '?' + params.join('&')

        if (actions) {
          url += '&state=' + encodeURIComponent(`?galileoAction=${actions}`)
        }
        return url
      }
      return null
    }

    async function changePassword(
      newPassword: string,
      oldPassword: string,
      username: string
    ): Promise<void> {
      const appStore = useAppStore()

      const payload = {
        deviceId: signInFields.value.deviceId,
        deviceToken: signInFields.value.deviceToken,
        devicePlatform: signInFields.value.devicePlatform,
        newPassword: newPassword,
        oldPassword: oldPassword,
        password: newPassword,
        username: username,
      }

      try {
        const result = await changePasswordApi.exec(payload)
        return result
      } catch (ex) {
        appStore.logException('Unable to change password', ex)
        throw ex
      }
    }

    async function changeEmail(newEmail: string): Promise<void> {
      const appStore = useAppStore()
      try {
        const response = await changeEmailApi.exec({ newEmail })
        if (response) {
          return response
        }
      } catch (ex) {
        appStore.logException('Unable to change email', ex)
        throw ex
      }
    }

    async function changeEmailConfirm(code: string, newEmail: string): Promise<void> {
      const appStore = useAppStore()
      try {
        const response = await changeEmailConfirmApi.exec({ code, newEmail })
        if (response) {
          return response
        }
      } catch (ex) {
        appStore.logException('Unable to verify the code', ex)
        throw ex
      }
    }

    async function changeEmailResend(): Promise<void> {
      const appStore = useAppStore()
      try {
        const response = await changeEmailResendApi.exec()
        if (response) {
          return response
        }
      } catch (ex) {
        appStore.logException('Unable to send the new code', ex)
        throw ex
      }
    }

    async function login({
      username,
      password,
      router,
      verifyCode = '',
    }: {
      username: string
      password: string
      router?: any
      verifyCode?: string
    }): Promise<any> {
      const appStore = useAppStore()
      const sessionStore = useSessionStore()
      const deviceStore = useDeviceStore()

      const { pendingLogin, session, sessionDeviceId, sessionDeviceToken, sessionId, token } =
        sessionStore

      const {
        country,
        countryId,
        deviceId,
        lastTransactionCountryTo,
        locale,
        newLookModalAlreadyDisplayed,
        publicSiteCookie,
        tokens,
        welcomePageMessage,
      } = deviceStore

      try {
        let captcha: Nullable<string> = null
        const envStore = useEnvStore()
        const environment = envStore.getVariable('VUE_APP_ENV')

        if (environment === 'production') {
          try {
            captcha = await appStore.getCaptchaToken()
          } catch (ex) {
            useAppStore().logException('Exception creating captcha')
          }
        }

        // Call the Login API
        const { data } = await signInApi.exec({
          username,
          password,
          device: {
            country,
            countryId,
            deviceId,
            lastTransactionCountryTo,
            locale,
            newLookModalAlreadyDisplayed,
            publicSiteCookie,
            tokens,
            welcomePageMessage,
          },
          session: {
            pendingLogin,
            session,
            sessionDeviceId,
            sessionDeviceToken,
            sessionId,
            token,
          },
          captcha,
          verifyCode,
        })

        // If there is a result, set it into the API
        if (data) {
          lastLogin.value = username
          refreshLastDate.value = new Date()
          if (data.require2FA) {
            sessionStore.setPendingLogin({ username, password } as PendingLogin)
          } else {
            setToken(data)
            data.profiles = await getUserProfiles()

            if (data.profiles.length === 1) {
              const profile = data.profiles[0]
              profileId.value = profile.id

              await initializeUser(router)
            } else {
              profileId.value = null
              data.hasProfile = false
            }
          }
          return data
        }
      } catch (ex) {
        appStore.logException('Unable to login', ex)
        throw ex
      }
      return null
    }

    function setReturnRoute(route: ReturnRoute): void {
      returnRoute.value = route
    }

    function updateUserProfile(user: UserProfile): void {
      userProfile.value = user
    }

    async function selectProfile(
      profilesId: string,
      accountType: string,
      router: VueRouter
    ): Promise<void> {
      const envStore = useEnvStore()
      const sessionStore = useSessionStore()
      const deviceStore = useDeviceStore()

      const {
        pendingLogin,
        session,
        sessionDeviceId,
        sessionDeviceToken,
        sessionId,
        token: tokenVal,
      } = sessionStore

      const {
        country,
        countryId,
        deviceId,
        lastTransactionCountryTo,
        locale,
        newLookModalAlreadyDisplayed,
        publicSiteCookie,
        tokens,
        welcomePageMessage,
      } = deviceStore

      const selectedProfile = {
        username: lastLogin.value,
        device: {
          country,
          countryId,
          deviceId,
          lastTransactionCountryTo,
          locale,
          newLookModalAlreadyDisplayed,
          publicSiteCookie,
          tokens,
          welcomePageMessage,
        },
        session: {
          pendingLogin,
          session,
          sessionDeviceId,
          sessionDeviceToken,
          sessionId,
          tokenVal,
        },
        refreshToken: token.value?.refreshToken,
        selectedProfileId: profilesId,
      }
      if (accountType === 'Corporate') {
        window.location.href = envStore.appUrlTransfer
      } else if (accountType === 'Corporate') {
        const { data } = await postSelectProfileApi.exec(selectedProfile)
        setToken(data)
        profileId.value = profilesId
        initializeUser(router)
      }
    }

    async function getUserProfiles(): Promise<Array<Object>> {
      const { data } = await getProfilesApi.exec()

      if (data && data.length > 0) {
        userProfiles.value = data
        return data
      } else {
        throw new Error(`No user profile`)
      }
    }

    async function getUserAction(profilesId: any): Promise<void> {
      const deviceStore = useDeviceStore()
      let { data } = await getProfileApi.exec(profilesId)

      profileId.value = profilesId
      userProfile.value = data

      deviceStore.setCountry(data.country)

      isTermsAndConditionsAcceptanceRequired.value = !data.customer?.termsAndConditions?.isValid
      termsAndConditionsRequiredVersion.value = data.customer?.termsAndConditions?.versionAvailable

      const isCorporate = isCorporateAccount.value

      if (!isCorporate) {
        isKYCRefreshRequired.value = data.isKycRefreshRequired
      }

      analyticsStore.trackUser()
      analyticsStore.gtmTrackVariables({
        variables: {
          accountType: isCorporate ? ACCOUNT_TYPE_ENUM.CORPORATE : ACCOUNT_TYPE_ENUM.CONSUMER,
          clientNumber: data.customer.clientNumber,
          userId: profileId,
        },
      })
    }

    const refreshUser = async () => {
      try {
        let { data } = await getProfileApi.exec(profileId.value)
        userProfile.value = data
        await useProfileStore().loadHomePageMessage()
      } catch (ex) {
        useAppStore().logException('Error refreshing the user')
      }
    }

    async function changeLanguage(language: string): Promise<void> {
      const deviceStore = useDeviceStore()
      const resourcesStore = useResourcesStore()

      const { data } = await postUserSettingApi.exec(language)

      if (data) {
        if (userProfile.value) {
          userProfile.value.language = language
        }

        deviceStore.setLocale(language)

        await resourcesStore.getSystemFields()

        analyticsStore.track({
          event: SEGMENT_EVENTS.LANGUAGE_UPDATED,
          traits: {
            location: SEGMENT_LOCATIONS.SETTINGS,
            userLanguage: language,
          },
        })
      } else {
        throw new Error('Change User Setting')
      }
    }

    async function putCustomerProfile(customerProfile: Object) {
      const { data } = await putCustomerProfileApi.exec(customerProfile)
      if (data) {
        userProfile.value = data
      } else {
        throw new Error(`No user profile`)
      }
    }

    async function getUserMissingRequiredFields(): Promise<void> {
      const { data } = await getMissingRequiredFieldsApi.exec()
      missingRequiredFields.value = data
    }

    async function getSecurityQuestions(payload: { securityCode: string }): Promise<Object> {
      const { data } = await getVerifyQuestionApi.exec(payload.securityCode)
      if (data) {
        return data
      } else {
        throw new Error('No questions received')
      }
    }

    async function verifySecurityQuestion(
      question: string,
      answer: string,
      securityCode: string
    ): Promise<Object> {
      const securityQuestions: SecurityQuestions = {
        deviceId: deviceStore.deviceId || sessionStore.sessionDeviceId,
        question: question,
        answer: answer,
        securityCode: securityCode,
      }
      const { data } = await verifyQuestionApi.exec(securityQuestions)

      if (data?.deviceToken) {
        sessionStore.setSessionDeviceToken(data.deviceToken)

        // If securityCode supplied, verification is being used for reset password, not login
        if (!securityCode) {
          await refresh(token.value?.refreshToken)
        }
        return data
      } else {
        throw new Error(`Failed to verify question`)
      }
    }

    async function acceptTermsAndConditions(): Promise<any> {
      const corporateStore = useCorporateStore()
      const resourcesStore = useResourcesStore()
      const latestTermsAndConditionsVersion = termsAndConditionsRequiredVersion.value

      await resourcesStore.getSystemFields()

      try {
        if (isCorporateAccount.value) {
          await corporateStore.agreeTermsAndConditions(latestTermsAndConditionsVersion)
          return { status: 200 }
        } else {
          const result = await updateTermsAndConditionsApi.exec({
            profileId: userProfile.value?.customer.id,
            versionAccepted: latestTermsAndConditionsVersion,
          })
          return { status: result.status }
        }
      } catch (e) {
        useAppStore().logException(`Failed to accept new terms & conditions`, e)
        throw e
      }
    }
    async function acceptNewTerms(): Promise<void> {
      const resourcesStore = useResourcesStore()
      try {
        await resourcesStore.getSystemFields()
        const { status } = await acceptTermsAndConditions()

        if (status === 200) {
          await getUserAction(userProfile.value?.customer.id)
          showTACAfterAddressChange.value = false
        }
      } catch (error) {
        throw new Error(`Failed to accept new terms & conditions`)
      }
    }

    async function initializeUser(router: VueRouter): Promise<void> {
      const deviceStore = useDeviceStore()
      const otpStore = useOtpStore()
      const sessionStore = useSessionStore()
      const appStore = useAppStore()
      const resourcesStore = useResourcesStore()
      const envStore = useEnvStore()
      const corporateStore = useCorporateStore()
      const userPreferencesStore = useUserPreferencesStore()

      if (otpStore.rememberDevice) {
        const tokens = deviceStore.tokens
        const matchedToken = tokens.find(
          (token: MatchedToken) => token.loginName === lastLogin.value
        )
        const int = {
          loginName: lastLogin.value,
          token: sessionStore.sessionDeviceToken,
          deviceId: sessionStore.sessionDeviceId,
        }
        if (!matchedToken) {
          tokens.push(int)
        }
        deviceStore.setTokens(tokens)
        deviceStore.setId(sessionStore.sessionDeviceId)
        sessionStore.setStpDocumentRequiredAcknowledged(false)
      }

      await getUserAction(profileId.value)

      if (userProfile.value) {
        try {
          const sessionLogPayload = new SessionLogPayload(userProfile.value)
          const userTraits = sessionLogPayload.getPayload() as IUserTraits

          LogRocket.identify(`${sessionLogPayload.userId}`, userTraits)
        } catch (ex) {
          appStore.logException('Unable to identify user in LogRocket', ex)
        }

        deviceStore.setCountry(userProfile?.value?.country)

        await useDeviceStore().setInitialLocale()
        await resourcesStore.getSystemFields()
        //getting data about promotions
        await usePromotionStore().getAvailablePromotionsPerUserCountry()

        isAccountRestricted.value = userProfile.value.customer?.accountRestricted ?? false
        await userPreferencesStore.init()
      }

      // TODO pinia syntax
      const isCorporate = isCorporateAccount.value

      if (isCorporate) {
        try {
          await corporateStore.fetchClientInfo()
          await corporateStore.fetchUserDetails()
        } catch (ex) {
          console.warn(ex)
        }
        try {
          await corporateStore.fetchMarketingPreferenceConsent()
        } catch (ex) {
          console.warn(ex)
        }
      }

      startAutoRefresh()

      if (router) {
        const shouldUseRedirect = envStore.useGalileoLogin
        await checkLoginRequirements(shouldUseRedirect)
      }

      alreadyAcceptedNewTerms.value = false
    }
    async function startAutoRefresh(): Promise<void> {
      // TODO IMPROVE

      // A function to refresh JWT auto token, using refresh token
      const refreshToken = async (timeSecs?: number) => {
        // to be on the safe side if the expiry returns 0 or a strange number
        if (!timeSecs || timeSecs < 60) {
          timeSecs = 60
        }
        removeRefreshTimer()
        // Log user out if refresh retries attempts exceed 5

        if (refreshRetries.value >= 5) {
          await logout(true)
          redirectToRoute({ name: 'Login' })
        } else {
          // Create new timer to refresh JWT auth token
          const refreshTokenTimeout = window.setTimeout(async () => {
            await refresh(token.value?.refreshToken)
            refreshToken(token.value?.refreshTokenExpiry)
          }, Math.max(timeSecs - 10, 5) * 1000)

          // Set store refresh timer
          refreshTimeout.value = refreshTokenTimeout
        }
      }

      // Call refresh function using current JWT auth token's refreshTokenExpiry
      await refreshToken(token.value?.refreshTokenExpiry)

      // Check if document visibility change handler already defined and remove it
      removeDocumentVisibilityChangeListener()

      // Create hidden variable to keep track of document hidden state
      let hidden = false

      // Define document visibility change listener
      // https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event
      // Safari issues see https://caniuse.com/?search=visibilitychange

      // When executed use a combination of document.hidden and hidden state (defined above)
      // to remove/set refresh timer and trigger refreshing of JWT auth token
      const INACTIVITY_LOGOUT_TIMER = 15
      const inactivityLogoutInSeconds = INACTIVITY_LOGOUT_TIMER * 60

      const visibilityChangeListenerFunc = async function () {
        const appStore = useAppStore()

        // Check document hidden state
        if (document.hidden && !hidden) {
          appStore.logInfo('Application is in background')
          // Update hidden state
          hidden = true

          // Remove refresh timer if define
          // Timer removed due to Chrome 88 timeout throttling
          // https://developer.chrome.com/blog/timer-throttling-in-chrome-88/
          removeRefreshTimer()
        } else if (hidden) {
          // Set a default passedSeconds of 60 - Why is 60 used here?
          let passedSeconds = 60

          // Calculate, time in seconds passed, between last refresh and now
          if (refreshLastDate.value) {
            passedSeconds = (new Date().getTime() - refreshLastDate.value?.getTime()) / 1000
          }

          // Log user out if JWT auth token is not defined
          // or passed seconds is greater than JWT auth token refreshTokenExpiry
          if (!token.value || passedSeconds >= inactivityLogoutInSeconds) {
            appStore.logInfo(
              'Application gets in foreground again and session has expired meanwhile'
            )
            await logout(true)
            redirectToRoute({ name: 'Login' })
          } else {
            // Section contains logic for refreshing JWT auth token when app regains visibility
            appStore.logInfo('Application gets in foreground again - refresh session')

            // Refresh JWT auth token by calling refresh api
            // and set new refresh timer using new JWT auth token refreshTokenExpiry
            if (passedSeconds >= 60) {
              await refresh(token.value.refreshToken)
              refreshToken(token.value?.refreshTokenExpiry || 0)
            } else {
              // Set new refresh timer using the difference in time between
              // current JWT auth token refreshTokenExpiry and passedSeconds
              refreshToken((token.value?.refreshTokenExpiry || 0) - passedSeconds) //Criss
            }
          }

          // Update hidden state
          hidden = false
        }
      }

      const onInactivityLogout = async () => {
        let passedSeconds = 60

        passedSeconds = (new Date().getTime() - lastApiCall.value?.getTime()) / 1000

        if (!token.value || passedSeconds >= inactivityLogoutInSeconds) {
          await logout(true)
          redirectToRoute({ name: 'Login' })
        }
      }

      setInterval(onInactivityLogout, 50000)

      // Add visibility change listener and commit to store
      visibilityChangeListener.value = visibilityChangeListenerFunc
      document.addEventListener('visibilitychange', visibilityChangeListener.value, false)
    }
    function removeRefreshTimer(): void {
      let timeout = refreshTimeout.value
      if (timeout) {
        window.clearTimeout(timeout)
        refreshTimeout.value = null
      }
    }
    function removeDocumentVisibilityChangeListener(): void {
      if (visibilityChangeListener.value) {
        document.removeEventListener('visibilitychange', visibilityChangeListener.value, false)
        visibilityChangeListener.value = null
      }
    }
    async function checkLoginRequirements(redirect: boolean = true): Promise<void> {
      if (userProfile?.value?.mustAcceptNewLicense) {
        // TODO
        if (alreadyAcceptedNewTerms.value) {
          await acceptNewTerms()
        } else {
          // temporary fix for the backend not reflecting the terms and conditions correctly
          await router.push({ name: 'LoginNewTerms' })
          return
        }
      }

      if (
        redirect &&
        getPostLoginRoute.value &&
        router?.currentRoute.name !== getPostLoginRoute.value?.name
      ) {
        await router.replace(getPostLoginRoute.value as RawLocation)
        returnRoute.value = null
      }
    }
    async function verifyOTP(code: string) {
      const sessionStore = useSessionStore()

      const request = sessionStore.pendingLogin
      request.verifyCode = code

      return login(request)
    }
    async function resendOTP(deliveryType: string, action: Object) {
      const deviceStore = useDeviceStore()
      const otpStore = useOtpStore()

      const request: ResendOTPRequest = {
        securityCode: otpStore.securityCode,
        referenceValue: otpStore.referenceValue,
        deliveryType: deliveryType,
        deviceId: deviceStore.getId(),
        deviceSimCard: deviceStore.getId(),
        action,
      }
      const { data } = await OTP.exec(request)

      if (data) {
        const { result } = data
        otpStore.setSecurityCode(result.securityCode)
        otpStore.setCode(result.code || '')
        otpStore.setDeliveryType(deliveryType)
        if (result.maskedPhone || result.maskedEmail) {
          otpStore.setMaskedPhone(result.maskedPhone || result.maskedEmail)
        }
        return data
      } else {
        throw new Error(`Failed to verify OTP`)
      }
    }
    async function restoreSession(): Promise<boolean> {
      const appStore = useAppStore()
      const sessionStore = useSessionStore()
      // TODO
      if (isAuthenticated.value) {
        try {
          setToken(token.value)
          await refresh(token.value?.refreshToken)
          await initializeUser(router)

          return true
        } catch (error) {
          appStore.logError('Unable to restore the session', '', error)

          //await logout()
          sessionStore.setSession(null)
          //await router.push({ name: 'Login' })
        }
      } else {
        sessionStore.setSession(null)
      }
      return false
    }
    async function refresh(refreshToken?: string) {
      if (!refreshToken) {
        refreshToken = token.value?.refreshToken
      }
      const { data } = await refreshApi.exec(refreshToken)

      if (data?.token) {
        setToken(data)
        refreshLastDate.value = new Date()
        return data
      } else {
        refreshRetries.value += 1
        throw new Error(`Failed to refresh token`)
      }
    }
    function setToken(loginResponse: Token): void {
      const sessionStore = useSessionStore()

      const jwt = jwtDecode(loginResponse.token ?? '')

      const session = new Session(jwt)

      sessionStore.setSession(session)

      // Set tokenf
      token.value = loginResponse

      // Set Axios instance Auth Header
      setHeader('Authorization', `${loginResponse?.tokenType} ${loginResponse?.token}`)
      apiHandler.setHeader('Authorization', `${loginResponse?.tokenType} ${loginResponse?.token}`)
    }

    async function logout(routeToLogin = false): Promise<void> {
      const envStore = useEnvStore()
      const appStore = useAppStore()

      let routeUrl = null
      const brand = sessionStore.brand
      //brand specific cookies are dropped by launchapd while logging in
      const launchpadCookieExist = Cookies.get(`${brand}_sso_id_launchpad`)

      if (routeToLogin) {
        try {
          routeUrl = replaceLoginUri()
        } catch (ex) {
          appStore.logException('Unable to get routeUrl', ex)
        }
        if (!routeUrl) {
          routeUrl = envStore.appUrlTransfer
        }
        appStore.setReady(false)
      }

      if (isAuthenticated.value) {
        if (!launchpadCookieExist) {
          try {
            await logoutApi.exec()
          } catch (ex) {
            appStore.logException('Unable to logout', ex)
          }
        }

        try {
          const url = replaceLoginUri('logout', 'logout_uri')
          if (url) {
            // Logout from Fxweb
            const transUrl = envStore.appUrlTransfer + 'account/logout'

            await Promise.all([
              fetch(url, { credentials: 'include', mode: 'no-cors' }),
              fetch(transUrl, { credentials: 'include', mode: 'no-cors' }),
            ])
          }
          // AuthStore's loginURL would be null for white labels. We still need to logout from the Fxweb
          //Need to refactor to not call corp for xe
          else if (useThemeStore().isBranded) {
            const transUrl = envStore.appUrlTransfer + 'account/logout'
            await fetch(transUrl, { credentials: 'include', mode: 'no-cors' })
          }
        } catch (ex) {
          appStore.logException('Unable to logout GSO', ex)
        }
      }
      reset()

      //If launchpad sso cookie exists, user signin from Account-Site, we redirecting back to Account-Site
      if (launchpadCookieExist) {
        try {
          const logoutUrl = envStore.appLaunchpadEndpoint + 'logout'
          await fetch(logoutUrl, { credentials: 'include', mode: 'no-cors' })
          window.location.href = envStore.appAccountSite
        } catch (error) {
          window.location.href = envStore.appAccountSite
        }
      }
      //In the pre-prod env, 'britline_sso_launchpad' cookie won't be present, but would have already logged out, so taking to Account-site
      else if (brand !== PRISMIC_BRAND_NAMES.XE && !envStore.isProduction) {
        window.location.href = envStore.appAccountSite
      } else if (routeUrl) {
        window.location.href = routeUrl
      }
    }

    function setPendingVerifyUser(): void {
      pendingVerifyUser.value = lastLogin.value

      const storedOrderTemp = { ...useSendMoneyStore().form }
      storedOrderTemp.login = lastLogin.value
      useStoredOrderStore().setStoredOrder(storedOrderTemp as unknown as StoredOrder)
    }
    function reset(): void {
      useBankAccountStore().resetForm()
      useSendMoneyStore().resetForm()
      useActivityStore().resetForm()
      useCardAccountStore().resetForm()
      useOtpStore().reset()
      usePaymentsStore().resetForm()
      useProfileStore().resetForm()
      useRecipientsStore().resetForm()
      useRegistrationStore().resetForm()
      useSessionStore().deleteSession()
      usePromotionStore().resetState()
      removeRefreshTimer()
      removeDocumentVisibilityChangeListener()
      removeHeader('Authorization')
      apiHandler.removeHeader('Authorization')
      resetState()
    }

    async function initGso(): Promise<void> {
      const appStore = useAppStore()
      try {
        let tokenUrlVar = ''
        const envStore = useEnvStore()

        tokenUrlVar = `${envStore.getVariable('VUE_APP_ENDPOINTS_LAUNCHPADAPI')}getGSOTokens`
        loginUrl.value = envStore.appAccountSite
        tokenUrl.value = tokenUrlVar
      } catch (ex) {
        appStore.logException('Unable to get SSO settings', ex)
      }
    }

    async function checkGso(router: VueRouter): Promise<boolean> {
      const appStore = useAppStore()
      const deviceStore = useDeviceStore()
      let accessToken = null
      let refreshToken = null

      try {
        let data = null

        if (useThemeStore().isBranded) {
          const result = await getLaunchpadGsoTokenApi.exec(tokenUrl.value)
          data = result.data
        } else {
          const result = await getGsoTokenApi.exec(tokenUrl.value)
          data = result.data
        }

        if (data) {
          accessToken = data.accessToken
          refreshToken = data.refreshToken
        }
      } catch (e) {
        appStore.logInfo('Unable to get SSO settings', {} as StringObject)

        const { isValidRedirect } = useOpenBanking()

        //bypass authentication if it's volt redirect back to XE
        if (isValidRedirect()) {
          return true
        }
        const { isGalileoLoginUrl } = useGalileoLoginUrlValidator()


        if (!useThemeStore().isWhiteLabelUrl && !isGalileoLoginUrl.value) {
          redirectToAcountsUi()
        } else {
          useEnvStore().setUseGalileoLogin()
        }
      }
      try {
        if (accessToken && refreshToken) {
          // setHeader('Authorization', `Bearer ${accessToken}`)
          setToken({
            tokenType: 'Bearer',
            token: accessToken,
          })
          const decoded: AccessToken = jwtDecode(accessToken)
          deviceStore.setRememberDevice(decoded.browser_remembered)

          if (decoded?.browser_remembered) {
            deviceStore.setRememberDevice(decoded.browser_remembered)
          }
          if (decoded?.email) {
            lastLogin.value = decoded.email
          } else {
            lastLogin.value = ''
          }
          if (decoded?.profile?.profileId) {
            await refresh(refreshToken)
            profileId.value = decoded.profile.profileId
            await getUserProfiles()
            await initializeUser(router)

            // check if already registered
            if (decoded.profile.firstName) {
              if (window.location.pathname !== '/activity') {
                //load transactions before handling redirects
                await useActivityStore().getTransactions()

                await appStore.handleRedirects(router)
              }
              return true
            }
            if (window.location.href.indexOf('?code=') !== -1) {
              await router.replace('/corporate/business-info')
            }
          }
        }
      } catch (ex) {
        appStore.logException('Unable to login', ex)
      }
      return true
    }

    function redirectToAcountsUi(): void | boolean {
      const appStore = useAppStore()
      const url = replaceLoginUri()

      if (url) {
        appStore.logInfo(`Redirecting to accounts UI ${url}`, {} as StringObject)
        window.location.href = url
        return false
      }
    }
    function redirectToFxWebAndRefreshSession(): void {
      const transferUrl = new URL(`${useEnvStore().appUrlTransfer}account/auth/home`)
      //adding queryPram to refresh fxweb session
      transferUrl.searchParams.set('requestRefresh', 'true')
      window.location.href = transferUrl.href
    }
    // Actions end

    const shouldDisplayCallUsCTA = (): boolean => {
      if (!isAuthenticated || useThemeStore().isBranded) return false

      // we assume user is logged in if customer exists
      if (userProfile.value?.customer) {
        const allowedCountries = ['US', 'CA']
        return allowedCountries.includes(userProfile.value.customer.country)
      }
      return false
    }

    const getCallUsNumber = (): string | null => {
      if (shouldDisplayCallUsCTA()) {
        if (isCorporateAccount.value) {
          return CALL_US_NUMBER.CORPORATE
        } else {
          return CALL_US_NUMBER.CONSUMERS
        }
      }
      return null
    }

    const getUserAddress = (): AddressModel => {
      const userAddress = new AddressModel(
        userProfile.value.address,
        '',
        '',
        userProfile.value.city,
        userProfile.value.state,
        userProfile.value.postalCode,
        userProfile.value.country
      )

      return userAddress
    }

    return {
      changePassword,
      changeEmail,
      changeEmailConfirm,
      changeEmailResend,
      selectProfile,
      getUserProfiles,
      changeLanguage,
      putCustomerProfile,
      getUserMissingRequiredFields,
      getSecurityQuestions,
      verifySecurityQuestion,
      verifyOTP,
      resendOTP,
      acceptNewTerms,
      acceptTermsAndConditions,
      restoreSession,
      setPendingVerifyUser,
      initGso,
      checkGso,
      redirectToAcountsUi,
      redirectToFxWebAndRefreshSession,
      updateUserProfile,
      getUserAction,
      getUserIdentificationDetails,
      getUserId,
      isPendingVerifyUser,
      getIsDirectDebitEnabledAccount,
      getCanUseOnlineDealing,
      getUserRegion,
      getUserAddressAsRecipient,
      isCorporateAccount,
      signInFields,
      token,
      lastLogin,
      refreshTimeout,
      refreshRetries,
      refreshLastDate,
      visibilityChangeListener,
      profileId,
      user: userProfile,
      userProfile,
      userProfiles,
      alreadyAcceptedNewTerms,
      pendingVerifyUser,
      returnRoute,
      defaultRoute,
      loginMethod,
      isTermsAndConditionsAcceptanceRequired,
      termsAndConditionsRequiredVersion,
      isKYCRefreshRequired,
      showTACAfterAddressChange,
      isAccountRestricted,
      missingRequiredFields,
      loginUrl,
      tokenUrl,
      selectedUser,
      initializeUser,
      logout,
      login,
      setReturnRoute,
      resetState,
      isAuthenticated,
      isConsumerAccount,
      setLastApiCall,
      getIsBalancesEnabled,
      getIsQuickTransferEnabled,
      shouldDisplayCallUsCTA,
      getCallUsNumber,
      refreshUser,
      isQuickTransferSpecificAccount,
      getUserAddress,
      refresh
    }
  },
  {
    persist: {
      storage: sessionStorage,
    },
  }
)
