






























































import { computed, defineComponent, onMounted } from '@vue/composition-api'
import { storeToRefs } from 'pinia'
import { useRouter } from '@galileo/composables/useRouter'

import {
  AppModalFooter,
  AppButton,
  AppCardImageTitle,
  AppIcon,
  AppCardHeader
} from '@oen.web.vue2/ui'

import KYCRefreshCorporateCard from './KYCRefreshCorporateCard.vue'
import IconClose from '@oen.web.vue2/icons/src/components/IconClose.vue'
import {
  useI18nStore,
  useAuthStore,
  useKYCRefreshCorporateStore,
  useAnalyticsStore,
  useCorporateStore,
} from '@galileo/stores'
import { KycRefreshCorporateStepEnum } from '@galileo/stores/kycRefreshCorporate'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'

export default defineComponent({
  name: 'KYCRefreshCorporateWarningCard',
  components: {
    AppModalFooter,
    AppButton,
    AppCardImageTitle,
    AppIcon,
    IconClose,
    KYCRefreshCorporateCard,
    AppCardHeader,
  },
  setup(_, { emit }) {
    const { $t } = useI18nStore()
    const kycRefreshCorporateStore = useKYCRefreshCorporateStore()
    const authStore = useAuthStore()
    const analyticsStore = useAnalyticsStore()
    const corporateStore = useCorporateStore()
    const router = useRouter()

    const { user } = storeToRefs(authStore)

    // Store data
    const isGracePeriod = computed(() => kycRefreshCorporateStore.isGracePeriod)
    const daysLeft = computed(() => kycRefreshCorporateStore.daysLeft)
    const fewDaysLeft= computed(() => kycRefreshCorporateStore.daysLeft <= kycRefreshCorporateStore.DAYS_LEFT_WARNING)
    const showGoHomeBtn = computed(() => kycRefreshCorporateStore.showGoHomeBtn)

    // Title
    const cardTitle = computed(() => isGracePeriod.value
      ? $t('ComponentKYCRefreshCorporateModal.VerifyAvoidRestriction').value
      : $t('ComponentKYCRefreshCorporateModal.VerifyEnableTransfer').value
    )

    const goHome = () => {
      kycRefreshCorporateStore.hideModal()
      router.push({ name: 'Activity' })
    }

    onMounted(() => {
      const tbuName = corporateStore.clientUserDetails?.tbuName ?? ''
      const daysLeftValue = daysLeft.value < 0 ? 0 : daysLeft.value
      analyticsStore.track({
        event: SEGMENT_EVENTS.KYC_REFRESH_REQUIRED,
        traits: {
          daysLeft: daysLeftValue,
          tbuName
        }
      })
    })

    return {
      $t,
      emit,
      isGracePeriod,
      user,
      daysLeft,
      fewDaysLeft,
      showGoHomeBtn,
      goToStep: kycRefreshCorporateStore.goToStep,
      KycRefreshCorporateStepEnum,
      cardTitle,
      goHome,
    }
  },
})
