import StringObject from "@galileo/types/StringObject"

const language : StringObject = {
  en: 'English',
  es: 'Español',
  de: 'Deutsch',
  fr: 'Français',
  nl: 'Nederlands',
  da: 'Dansk',
  fi: 'Suomalainen',
  it: 'Italiano',
  lu: 'Lëtzebuergesch',
  nb: 'Norsk',
  sv: 'Svenska',
}

export default language
