import {
  useAuthStore,
  useSendMoneyStore,
  usePaymentsStore,
  useAnalyticsStore,
  storeToRefs,
  useAppStore,
  useI18nStore,
} from '@galileo/stores'

import { computed, ref } from '@vue/composition-api'
import { PAYMENT_METHODS, VOLT, TRANSFER } from '@galileo/constants/sendMoneyFlow.const'
import { SEGMENT_EVENTS, SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'

export default function (router = null) {
  const { $t } = useI18nStore()

  // router needed for addTabListener and handleOpenBankingTransfer

  //listen to the transfer stage change from new tab
  let channel = null

  const authStore = useAuthStore()
  const sendMoneyStore = useSendMoneyStore()
  const analyticsStore = useAnalyticsStore()
  const { voltCheckoutComplete, voltCheckoutStatus, hasVoltInfoBeenShown } =
    storeToRefs(sendMoneyStore)

  const lock = ref(false)

  const isOpenBanking = computed(
    () => sendMoneyStore.form.paymentMethod === PAYMENT_METHODS.OPEN_BANKING
  )
  const isPayTo = computed(() => {
    const userCountry = authStore.user.customer.country
    const currencyFrom = sendMoneyStore.form.currencyFrom

    return userCountry === 'AU' && currencyFrom === 'AUD' && isOpenBanking.value
  })

  //use this property when in SendMoneyAmount / SendMoneyPayment when we still don't have paymentMethod specified
  const shouldUsePayTo = computed(() => {
    const userCountry = authStore.user.customer.country
    const currencyFrom = sendMoneyStore.form.currencyFrom

    return userCountry === 'AU' && currencyFrom === 'AUD'
  })

  const cancelCheckout = () => {
    const isComplete = true
    sendMoneyStore.changeVoltCheckoutComplete(isComplete, { status: VOLT.STATUS.CANCELLED_BY_USER })
    if (!isPayTo.value) {
      window.close()
    }
  }

  //#region Volt redirection utilities

  const addTabListener = (orderCreateHandler) => {
    if (!router) {
      useAppStore().logException('router not provided needed for addTabListener', router)
    }

    if (!channel) {
      initBroadcastChannel()
    }

    useAppStore().logInfo('Volt - AddTabListener initalized')

    channel.onmessage = async (event) => {
      if (event.data === 'focus-tab' || event.data.closeWindow) {
        // Bring the current tab to the foreground
        window.focus()
        return
      }

      if (!voltCheckoutComplete.value && event.data.voltCheckoutComplete && !lock.value) {
        channel.postMessage({
          closeWindow: true,
        })

        const data = event.data.voltCheckoutStatus

        const currency = sendMoneyStore.form.currencyFrom

        lock.value = true

        if (event.data.voltCheckoutStatus.status) {
          voltCheckoutStatus.value = event.data.voltCheckoutStatus.status
        } else {
          try {
            voltCheckoutStatus.value = await usePaymentsStore().getVoltRedirect(
              data.voltEncodedPayload,
              data.voltSignature,
              data.voltTimestamp,
              currency
            )
          } catch (ex) {
            useAppStore().logException('Exception validating volt parameters')
            router.replace('/send-money/failed')
          }
        }

        if (event.data.voltCheckoutComplete) {
          if (
            voltCheckoutStatus.value === VOLT.STATUS.COMPLETED ||
            voltCheckoutStatus.value === VOLT.STATUS.DELAYED_AT_BANK
          ) {
            //checkout complete only when the status is complete
            voltCheckoutComplete.value = event.data.voltCheckoutComplete

            //finish order
            await orderCreateHandler()
          } else if (voltCheckoutStatus.value === VOLT.STATUS.CANCELLED_BY_USER) {
            await analyticsStore.track({
              event: SEGMENT_EVENTS.OPEN_BANKING_CANCELLED_TRIGGERED,
              traits: {
                sendMethod: sendMoneyStore.form.paymentMethod,
                accountType: authStore.user.customer.accountType,
              },
            })
            router.replace({ name: 'SendMoneyVoltPaymentCancelled' })
          } else {
            router.replace('/send-money/failed')
          }
        }
      }
    }
  }

  const shouldUseOpenBankingFlow = () => {
    return (
      sendMoneyStore.form.paymentMethod === PAYMENT_METHODS.OPEN_BANKING &&
      sendMoneyStore.transferStage === TRANSFER.STAGES.EDD &&
      (sendMoneyStore.isAUvoltCompleted || !voltCheckoutComplete.value)
    )
  }

  const handleOpenBankingTransfer = async (orderCreateHandler) => {
    if (!router) {
      useAppStore().logException('router not provided needed for handleOpenBankingTransfer', router)
    }

    //check volt
    if (!voltCheckoutComplete.value) {
      //we open volt in new tab to finish checkout
      if (hasVoltInfoBeenShown.value && !sendMoneyStore.isAUvoltCompleted) {
        const routeData = router.resolve({ name: 'SendMoneyVolt' })
        if (isPayTo.value) {
          router.forceRedirect({ name: 'SendMoneyVolt' })
        } else {
          initBroadcastChannel()

          setTimeout(() => {
            const voltPopup = window.open(routeData.href, '_blank')
            try {
              voltPopup.focus()
            } catch (e) {
              // popup alert need a copy
              let confirmed = confirm($t('SendMoneyCreating.VoltRedirectPopupMessage').value)
              if (confirmed) {
                return orderCreateHandler()
              } else {
                router.forceRedirect({ name: 'SendMoneySummary' })
              }
            }
          }, 1000)
        }
        hasVoltInfoBeenShown.value = false
      } else {
        closeBroadcastChannel()
        router.forceRedirect({ name: 'SendMoneyVoltInfo' })
      }
      return null
    } else if (sendMoneyStore.isAUvoltCompleted) {
      const result = await sendMoneyStore.orderCreate()
      sendMoneyStore.isAUvoltCompleted = false
      return result
    }
  }

  //#endregion

  const initBroadcastChannel = () => {
    try {
      channel = new BroadcastChannel('volt-checkout-complete')
    } catch (ex) {
      useAppStore().logException('BroadcastChannel not supported', ex)
    }
  }

  const isValidRedirect = () => {
    let isValid = false
    if (window.location.href.indexOf(VOLT_QUERY_PARAMS.VOLT_REDIRECT) > 0) {
      const searchParams = new URLSearchParams(window.location.search)
      isValid =
        searchParams.has(VOLT_QUERY_PARAMS.SIGNATURE) &&
        searchParams.has(VOLT_QUERY_PARAMS.TIMESTAMPT) &&
        searchParams.has(VOLT_QUERY_PARAMS.VOLT)
    }

    return isValid
  }

  const closeBroadcastChannel = () => {
    if (channel) {
      channel.close()
      channel = null
    }
  }

  return {
    shouldUsePayTo,
    isPayTo,
    isOpenBanking,
    cancelCheckout,
    addTabListener,
    shouldUseOpenBankingFlow,
    handleOpenBankingTransfer,
    closeBroadcastChannel,
    isValidRedirect,
  }
}

export const VOLT_QUERY_PARAMS = {
  VOLT_REDIRECT: 'volt_redirect',
  VOLT: 'volt',
  SIGNATURE: 'volt-signature',
  TIMESTAMPT: 'volt-timestamp',
}
