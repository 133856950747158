import { computed, ref, reactive, toRef } from '@vue/composition-api'
import { useValidation } from 'vue-composable'
import { useFieldParser, useFieldValidation } from '@galileo/composables/useFormHelpers'
import { useDynamicForm } from '@galileo/forms/useDynamicForm'

import { useI18nStore, useRecipientsStore, useAppStore } from '@galileo/stores'

const createFormFieldValidation = (formField, getFieldValidation) => {
  const validation = getFieldValidation(formField)

  return {
    ...validation,
    errorValue: {
      $validator: (v) => {
        if (!formField.fieldDefinition.disableErrorValueValidation && !formField.disabled) {
          return !(formField.errorValue === v || !v)
        }
        return true
      },
      // $error: 'TEST',
      $message: ' ',
    },
  }
}

export const setAccNumberAsFirst = (_fields) => {
  let accNumField = _fields.find((field) => field.id === 'ACCOUNT_NUM')

  if (accNumField) {
    let indexOfAccNumber = _fields.indexOf(accNumField)
    let temp = _fields[0]
    _fields[0] = accNumField
    _fields[indexOfAccNumber] = temp
  }
}

export const setPhoneNumberAsLast = (_fields) => {
  let phoneNumber = _fields.find((field) => field.id === 'RECIPIENT_PHONE')

  if (phoneNumber) {
    let indexOfPhoneNumber = _fields.indexOf(phoneNumber)
    const phoneNumberField = _fields[indexOfPhoneNumber]

    for (let index = indexOfPhoneNumber; index < _fields.length - 1; index++) {
      _fields[index] = _fields[index + 1]
    }
    _fields[_fields.length] = phoneNumberField
  }
}

export const useRecipientForm = (isMissingInformation = false) => {
  const recipientForm = reactive({
    fields: [],
    groups: {},
    additionalFields: [],
    defaultValues: {},
    values: {},
  })
  const additionalValidation = ref({})

  const { formValidation, formFields, hasFormFields, isFormInvalid } = useDynamicForm()

  const currentFormPage = ref(null)

  const setup = async (
    fields,
    defaultValues = {},
    shouldClearValues = true,
    business = false,
    shouldStayOnTheCurrentPage = false
  ) => {
    recipientForm.values = {}

    setAccNumberAsFirst(fields)
    setPhoneNumberAsLast(fields)
    if (!shouldClearValues && recipientForm.fields) {
      const parseFieldValues = (fields) => {
        fields.forEach((field) => {
          let fieldName = field.id

          if (defaultValues[fieldName]) {
            return
          }

          let fieldHasValue = field.value

          if (fieldHasValue) {
            recipientForm.values[fieldName] = field.value
          }
          if (field.children && field.children.length > 0) {
            parseFieldValues(field.children)
          }
        })
      }
      parseFieldValues(recipientForm.fields)
    }

    // prepare order for the fields
    const fieldOrder = [
      //'GRP_RECIPIENT_NAME_DETAILS',
      'FIRST_NAME',
      'MIDDLE_NAME',
      'LAST_NAME',
      'GRP_RECIPIENT_DETAILS',
      'IBAN',
      'ACCOUNT_NUM',
      'NICKNAME',
      'RECIPIENT_TAX_NUMBER',
      'IBAN_OR_ACCOUNT_NUM',
      'CA_TRANSIT_INSTITUTION',
      'CA_TRANSIT_BRANCH',
      'NAT_CLEAR_CODE',
      'BANK_BIC',
      'BANK_NAME',
      'BANK_ADDRESS_LINE1',
      'BANK_ADDRESS_LINE2',
      'BANK_ADDRESS_LINE3',
      'GRP_BANK_DETAILS',
      'GRP_ADDITIONAL_DETAILS',
    ]

    const fieldMap = {}
    fields.forEach((field) => {
      fieldMap[field.id] = field
      if (fieldOrder.indexOf(field.id) === -1) {
        const inx = fieldOrder.indexOf(field.group)
        if (inx >= 0) {
          fieldOrder.splice(inx, 0, field.id)
        } else {
          fieldOrder.push(field.id)
        }
      }
    })

    // sort the fields according to the desired order
    fields = []
    fieldOrder.forEach((name) => {
      if (name.startsWith('GRP_')) {
        return
      }
      const field = fieldMap[name]
      if (field) {
        fields.push(field)
      }
    })

    clearValues(defaultValues, shouldStayOnTheCurrentPage)

    const { getFormField } = useFieldParser(recipientForm, business)

    //Test for Adding RECIPIENT_ID_NUMBER_COUNTRY - country selector but throwing error on save
    // fields.push({
    //   children: [],
    //   displayName: {
    //     externalCode: 'RECIPIENT_ID_NUMBER_COUNTRY',
    //     text: 'Country Number',
    //   },
    //   group: 'GRP_RECIPIENT_DETAILS',
    //   id: 'RECIPIENT_ID_NUMBER_COUNTRY',
    //   label: 'Country Number',
    //   mandatory: true,
    //   match: null,
    //   options: null,
    //   type: 'country',
    //   useEnrichment: false,
    //   validations: null,
    // })

    fields.forEach((field) => {
      let formField = getFormField(field)

      if (formField) {
        recipientForm.fields.push(formField)
      }
      // parseField(field, fields, business)
    })

    await createFormFields(
      recipientForm.additionalFields,
      additionalValidation,
      recipientForm.additionalFields
    )

    // order the groups as per UI requirements
    nextFormFields(false, true)
  }

  const clearValues = (defaultValues = null, shouldStayOnTheCurrentPage = false) => {
    if (defaultValues) {
      recipientForm.defaultValues = defaultValues
    }

    if (shouldStayOnTheCurrentPage) {
      currentFormPage.value = null
    }

    recipientForm.fields = []

    recipientForm.additionalFields = []
    additionalValidation.value = {}

    recipientForm.groups = {
      nameFields: [],
      bankFields: [],
      addressFields: [],
      otherFields: [],
    }
  }

  const shouldShowAddressFields = () => {
    if (recipientForm.groups.addressFields) {
      return recipientForm.groups.addressFields.length > 0
    }
  }

  const createFormFields = async (fields, refFormValidation, refFormFields) => {
    const fieldData = []
    const validationData = {}

    const { getFieldValidation } = useFieldValidation()

    for (const formField of fields) {
      formField.validation = formField.validationDefinition

      validationData[formField.name] = createFormFieldValidation(formField, getFieldValidation)

      if (formField.name === 'iban') {
        // Redefine the generated validator method
        validationData[formField.name].regex.$validator = (v) => {
          v = v.replace(/ /g, '')
          const regex = formField.validationDefinition.find(
            (validator) => validator.type === 'Regex'
          )
          const validationRegex = new RegExp(regex.value)
          return validationRegex.test(v)
        }
      }

      fieldData.push(formField)
    }

    refFormValidation.value = useValidation(validationData)

    for (const field of fieldData) {
      field.validation = refFormValidation.value[field.name]
      if (field.id === 'RECIPIENT_PHONE') {
        field.validation.$reset()
      } else if (field.value) {
        field.validation.$touch()
      }
    }

    for (const field of fieldData) {
      if (
        field.fieldDefinition &&
        field.fieldDefinition.group === 'GRP_BANK_DETAILS' &&
        field.fieldDefinition.useEnrichment
      ) {
        await enrichBankFields(field)
      }

      //??
      if (field.parent) {
        field.parent = fieldData.find((item) => item.id === field.parent)
      }
    }

    refFormFields.value = fieldData
  }

  const populateSelectedFields = () => {
    const fields = recipientForm.groups[currentFormPage.value]
    //createFormFields(fields)
    createFormFields(fields, formValidation, formFields)
  }

  const selectFormFields = (name) => {
    currentFormPage.value = name
    populateSelectedFields()
  }

  const nextFormFields = (forceStart = false, shouldRefresh = false) => {
    let useNext = forceStart || !currentFormPage.value

    for (const groupName in recipientForm.groups) {
      const values = recipientForm.groups[groupName]

      let isNotEmpty = values && values.length > 0

      if (isNotEmpty) {
        if (useNext) {
          selectFormFields(groupName)
          return true
        }
        if (currentFormPage.value === groupName) {
          if (shouldRefresh) {
            selectFormFields(groupName)
          }
          useNext = true
        }
      }
    }
    return false
  }

  const previousFormFields = () => {
    formValidation.value = {}

    if (currentFormPage.value) {
      let prevName = null
      for (const groupName in recipientForm.groups) {
        const values = recipientForm.groups[groupName]

        let isNotEmpty = values && values.length > 0

        if (isNotEmpty) {
          if (currentFormPage.value === groupName) {
            if (!prevName) {
              return false
            }
            selectFormFields(prevName)
            return true
          }
          prevName = groupName
        }
      }
    }
    return false
  }

  const enrichBankFields = async (formField) => {
    let children = null
    let fieldName
    if (formField.fieldDefinition.NCC && formField.fieldDefinition.NCC.id === 'NAT_CLEAR_CODE') {
      fieldName = 'NCC'
      children = formField.fieldDefinition.NCC.children
    } else if (formField.parent && formField.parent.id === 'NAT_CLEAR_CODE') {
      fieldName = 'NCC'
      children = formField.parent.children
    } else if (formField.id === 'NAT_CLEAR_CODE') {
      fieldName = 'NCC'
    } else if (formField.id === 'BANK_BIC') {
      fieldName = 'BIC'
    } else if (formField.id === 'IBAN') {
      fieldName = 'IBAN'
    } else {
      fieldName = formField.id
    }

    const clearFields = {
      BANK_NAME: null,
      BANK_ADDRESS_LINE1: null,
      BANK_ADDRESS_LINE2: null,
      BANK_ADDRESS_LINE3: null,
      ...(fieldName !== 'BIC' && {
        BANK_BIC: null,
      }), // not resetting BIC in case we are actually inserting an invalid BIC
    }
    // TODO IMPROVE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    formField.listeners = {
      input: async (value) => {
        if (children) {
          value = ''
          const recipientValues = {}
          for (const formField of recipientForm.fields) {
            recipientValues[formField.id] = formField.value
          }
          for (const child of children) {
            if (!child || !recipientValues[child.id]) {
              // wait until all childs have a value
              return
            }
            value += recipientValues[child.id]
          }
        }
        let result = false
        if (value && value.length > 2) {
          result = await enrichBankFieldListenerHandler(value, fieldName, formField)
        }

        for (const formField of recipientForm.fields) {
          const fieldName = formField.id
          if (fieldName in clearFields && !result) {
            //if we enrich the field and is empty we don't enable the fields
            if (!value && value !== '') {
              formField.disabled = false
            }
            formField.value = clearFields[fieldName]
          } else if (fieldName in clearFields) {
            if (formField.value) {
              formField.disabled = true
            } else if (!formField.value && value) {
              //we are enabling the fields that are missing values but are mandatory
              formField.disabled = false
            }
          }
        }
      },
    }
    // const temp = formField.value
    // formField.value = null

    if (formField.value && isMissingInformation) {
      await formField.listeners.input(formField.value)
      // const event = new Event('input')
      // formField.dispatchEvent(event)
    }
  }

  const enrichBankFieldListenerHandler = async (value, fieldName, formField) => {
    if (fieldName === 'IBAN' && value) {
      if (value.length < 10) {
        return
      } else {
        value = value.replace(/ /g, '') // replace spaces
      }
    }

    formField.enrichValue = value
    const params = {
      fieldName: fieldName,
      fieldValue: value,
    }
    const appStore = useAppStore()

    // TODO TRY/CATCH ERROR HANDLING !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    let fields = null
    const loadingRef = toRef(formField.props, 'loading')

    loadingRef.value = true

    let raisedException = false
    const recipientsStore = useRecipientsStore()
    try {
      fields = await recipientsStore.getBankDetails({
        country: recipientForm.defaultValues['COUNTRY_CODE'],
        currency: recipientForm.defaultValues['CURRENCY_CODE'],
        params,
      })
    } catch (ex) {
      if (formField.id === 'IBAN_OR_ACCOUNT_NUM') {
        let field = recipientForm.fields.find((field) => field.id === 'BANK_BIC')
        field.disabled = false

        if (ex.response?.data.statusCode === 'NotFound') {
          field.disabled = true
          raisedException = true

          if (!formField.validation.$anyInvalid) {
            const i18nStore = useI18nStore()
            formField.validation.errorValue.$invalid = true
            formField.validation.errorValue.$error = i18nStore.$t(
              'UseFieldValidation.FieldIsNotValidError'
            ).value
          }
        }
      } else {
        console.info('Enrichment data not found')
        if (formField.enrichValue !== value) {
          return
        }
        raisedException = true

        if (!formField.validation.$anyInvalid && formField.id !== 'IBAN_OR_ACCOUNT_NUM') {
          const i18nStore = useI18nStore()
          formField.validation.errorValue.$invalid = true
          formField.validation.errorValue.$error = i18nStore.$t(
            'UseFieldValidation.FieldIsNotValidError'
          ).value
        }
      }
    }

    if (formField.enrichValue !== value) {
      return
    }

    loadingRef.value = false

    //if we reached here IBAN Should be already valid. So we are going to replace spaces if there are any.
    if (!raisedException && fieldName === 'IBAN' && value) {
      if (formField.value.includes(' ')) {
        formField.value = formField.value?.replace(/\s/g, '')
      }
    }

    if (fields) {
      let idValue = {}
      let bicHasValue = false

      if (
        formField.validation.$anyInvalid &&
        fields['bankName'] &&
        fields['correctedTriggerField']
      ) {
        let correctedFieldMatchRegex = true

        //#region check if corrected field can be used in the selected field
        const regexValidation = formField.fieldDefinition.validations.find(
          (validation) => validation.type === 'Regex'
        )?.value

        const correctedTriggerField = fields['correctedTriggerField']

        if (regexValidation) {
          const jsRegex = new RegExp(regexValidation)
          correctedFieldMatchRegex = jsRegex.test(correctedTriggerField)
        }

        if (correctedFieldMatchRegex) {
          formField.value = correctedTriggerField
        }
      }

      for (const field in fields) {
        const value = fields[field]
        let id = null
        if (field === 'bankName') {
          id = 'BANK_NAME'
        } else if (field === 'bankAddressOne') {
          id = 'BANK_ADDRESS_LINE1'
        } else if (field === 'bankAddressTwo') {
          id = 'BANK_ADDRESS_LINE2'
        } else if (field === 'bankAddressThree') {
          id = 'BANK_ADDRESS_LINE3'
        } else if (field === 'bicCode' || field === 'bankBic') {
          if (value) {
            bicHasValue = true
          } else if (bicHasValue) {
            continue
          } // don't assign an empty value when it has been filled already
          id = 'BANK_BIC'
        } else if (field === 'connectedBic' && !idValue['BANK_BIC']) {
          //we use connectedBic if there is no other bic information availabe
          //if we didn't fill it in already
          id = 'BANK_BIC'
        }
        idValue[id] = value
      }

      //out of for loop for imporved performance
      for (const formField of recipientForm.fields) {
        let fieldName = formField.id
        if (fieldName in idValue) {
          let value = idValue[fieldName]
          formField.value = value
        }
      }

      return true
    }
    return false
  }

  const setFormFieldValue = (id, value) => {
    for (const field of recipientForm.fields) {
      if (field.id === id) {
        field.value = value
        break
      }
    }
    for (const field of formFields.value) {
      if (field.id === id) {
        field.value = value
        break
      }
    }
  }

  //add new fields to be sent in the payload
  const addFormFieldValue = (name, value) => {
    if (!recipientForm.fields) {
      recipientForm.fields = []
    }
    recipientForm.fields.push({ id: name, value })
  }

  return {
    setup,
    nextFormFields,
    previousFormFields,
    selectFormFields,
    currentRecipientFormPage: currentFormPage,
    hasFormFields,
    isFormInvalid,
    formFields: computed(() => formFields.value),
    formValidation: computed(() => formValidation.value),
    allGroups: computed(() => recipientForm.groups),
    allFields: computed(() => recipientForm.fields),
    additionalFields: computed(() => recipientForm.additionalFields),
    additionalValidation,
    setFormFieldValue,
    addFormFieldValue,
    shouldShowAddressFields,
  }
}
