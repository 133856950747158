import { defineStore } from 'pinia'
import { computed, Ref, ref } from '@vue/composition-api'
import { MessageBoxError, MessageBoxOk } from '@galileo/models/MessageBoxes/app'
import getHelpCentreToken from '@galileo/api/launchpad/care/helpcentretoken/get'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'
import { actionExists, GALILEO_LANDING } from '@galileo/constants/actions.const'
import StringObject from '@galileo/types/StringObject'
//@ts-ignore
import mapboxSdk, { GeocodeService } from '@mapbox/mapbox-sdk/services/geocoding'

import router from '@galileo/router'
import HelpDesk, { HelpDeskProperties } from '@galileo/constants/helpdesk.const'

import { CurrentRoute } from '@galileo/models/App/interfaces/CurrentRoute'

import {
  useCorporateStore,
  useI18nStore,
  useEnvStore,
  useApiStore,
  useDeviceStore,
  useDevStore,
  useAuthStore,
  useAnalyticsStore,
  useThemeStore,
  useActivityStore,
  useResourcesStore
} from '@galileo/stores'

import useOpenBanking from '@galileo/composables/useOpenBanking'

export const useAppStore = defineStore('app', () => {
  const messageBox = ref<MessageBoxOk>()
  const hasMessage = ref<boolean>(false)
  const loadedScripts = ref<{ [key: string]: Promise<HTMLElement> | null }>({}) //TODO - CHECK
  const xeAppUrl = ref<string>('https://app.xe.com/')
  const mapboxGeocoder = ref<GeocodeService>()
  const currentRoute = ref<CurrentRoute>()
  const captchaPromise = ref<HTMLElement>()
  const accessedFormsFromPath = ref<string>('/activity')
  const accessIndividualCurrencyFromPath = ref<string>('/activity')
  const accessBalancesListFromPath = ref<string>('/activity')

  //Config modal flag
  const shouldShowConfigModal = ref<boolean>(false)

  const appIsReady = ref(false)
  const brandedHelpDeskLinks = ref<StringObject | null>(null)

  const getMessageBox = computed(() => {
    return messageBox.value
  })

  const getHasMessage = computed(() => {
    return hasMessage.value
  })

  const getLoadedScripts = computed(() => {
    return loadedScripts.value
  })

  const getIsReady = computed<boolean>((): boolean => {
    return appIsReady.value || router.currentRoute.name === 'Dev'
  })

  const getXeAppUrl = computed((path: string) => {
    const deviceStore = useDeviceStore()
    const locale = deviceStore.getLocaleLowerCase
    return `${xeAppUrl}${locale}/${path}`
  })

  const getCurrentRoute = computed(() => {
    return currentRoute.value
  })

  const isFromIncompleteTransaction = computed(() => {
    if (currentRoute.value) {
      return (
        currentRoute.value.from.name === 'SendMoneyVoltInfo' ||
        currentRoute.value.from.name === 'SendMoneyFailed'
      )
    }
  })

  function showConfigIssueModal(): void {
    shouldShowConfigModal.value = true
  }
  function hideConfigIssueModal(): void {
    shouldShowConfigModal.value = false
  }

  function setXeAppUrl(currentXeAppUrl: string): void {
    xeAppUrl.value = currentXeAppUrl
  }

  function setHasMessage(currentHasMessage: boolean): void {
    hasMessage.value = currentHasMessage
  }

  function setReady(isAppReady: boolean): void {
    appIsReady.value = isAppReady
  }

  function setCaptchaPromise(currentCaptchaPromise: HTMLElement | null): void {
    if (currentCaptchaPromise) captchaPromise.value = currentCaptchaPromise
  }

  function setMapboxGeocoder(currentCapboxGeocoder: GeocodeService): void {
    mapboxGeocoder.value = currentCapboxGeocoder
  }

  function setCurrentRoute(route: CurrentRoute): void {
    currentRoute.value = route
  }

  function setBrandedHelpDeskLinks(value: StringObject): void {
    brandedHelpDeskLinks.value = value
  }

  async function fetchMessageBox(message: MessageBoxOk) {
    messageBox.value = message
    hasMessage.value = true
  }

  async function messageBoxGenericOk(title: string, text: string, extraParams?: any) {
    if (!text) {
      const i18nStore = useI18nStore()
      const { i18n } = i18nStore
      text = title
      title = i18n.$t('UseMobieWalletsWarning.SomethingWentWrong.Title').value
      fetchMessageBox(new MessageBoxOk(title, text, extraParams))
    } else {
      fetchMessageBox(new MessageBoxOk(title, text, extraParams))
    }
    setHasMessage(true)
  }

  async function messageBoxGenericError(extraParams?: any | null) {
    const i18nStore = useI18nStore()
    const analyticsStore = useAnalyticsStore()
    const { i18n } = i18nStore
    let title = i18n.$t('ComponentXeGenericErrorModal.Title').value
    let text = i18n.$t('ComponentXeGenericErrorModal.FigureContent').value

    // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!
    // title = 'Oops! Something happened'
    // text = 'An unexpected error occurred — please try again'
    fetchMessageBox(new MessageBoxError(title, text, extraParams))
    analyticsStore.track({
      event: SEGMENT_EVENTS.SOMETHING_WENT_WRONG,
    })
    setHasMessage(true)
  }

  async function hideMessageBox() {
    setHasMessage(false)
  }

  async function loadScript(url: string, forceReload = false, isAdyen = false) {
    const scripts = getLoadedScripts

    if (scripts.value[url]) {
      if (forceReload) {
        const script = await scripts.value[url]
        if (script) {
          script.parentNode?.removeChild(script)
        }
      } else {
        return scripts.value[url]
      }
    }

    const promise = new Promise<HTMLElement>((resolve, reject) => {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.defer = true
      script.src = url

      if (isAdyen) {
        script.integrity = 'sha384-SGA+BK9i1sG5N4BTCgRH6EGbopUK8WG/azn/TeIHYeBEXmEaB+NT+410Z9b1ii7Z'
        script.crossOrigin = 'anonymous'
      }
      script.onload = () => {
        resolve(script)
      }
      script.onerror = (err) => {
        scripts.value[url] = null
        reject(err)
      }
      document.body.appendChild(script)
    })

    scripts.value[url] = promise

    return promise
  }

  async function loadScriptPlaid() {
    const envStore = useEnvStore()
    return loadScript(envStore.env.VUE_APP_PLAID_SCRIPT_URL)
  }

  async function loadScriptCardinal() {
    const envStore = useEnvStore()

    return loadScript(envStore.env.VUE_APP_3DSV2_SCRIPT_URL, true)
  }

  async function loadScriptMapboxGeocoder() {
    const envStore = useEnvStore()
    if (mapboxGeocoder) {
      return mapboxGeocoder
    }
    await loadScript(envStore.env.VUE_APP_MAP_BOX_GEO_CODER)
    const mapboxClient = mapboxSdk({
      accessToken: envStore.env.VUE_APP_MAP_BOX_ACCESS_TOKEN,
    })
    const geocoder = mapboxClient
    setMapboxGeocoder(geocoder)
    return geocoder
  }

  async function loadScriptVolt() {
    const envStore = useEnvStore()
    return loadScript(envStore.env.VUE_APP_VOLT_SCRIPT_URL)
  }

  async function log(text: string) {
    // TODO !!!!!!!!!!!!!!!!!!!!!!!
    // eslint-disable-next-line no-console
    console.log('LOG: ' + text)
  }

  async function logInfo(text: string, details?: StringObject) {
    // TODO !!!!!!!!!!!!!!!!!!!!!!!
    // eslint-disable-next-line no-console
    console.log('INFO: ' + text)
    if (details) {
      console.log(details)
    }
  }

  async function logIntegration(text: string, details: any = null) {
    if (!useEnvStore().isProduction) {
      console.info(`INTEGRATION LOGS: ${text}`)
      if (details) {
        console.log("details: ", details)
      }
    }
  }

  async function logError(text: string, errorType?: string, details?: any | null) {
    // TODO !!!!!!!!!!!!!!!!!!!!!!!
    // eslint-disable-next-line no-console
    console.error('ERROR: ' + text)

    if (details) {
      console.info(`${errorType}`, details)
    }
  }
  async function logException(text: string, exception?: any) {
    // TODO !!!!!!!!!!!!!!!!!!!!!!!
    // eslint-disable-next-line no-console
    const analyticsStore = useAnalyticsStore()
    console.error('EXCEPTION: ' + text, exception || '')
    analyticsStore.track({
      event: SEGMENT_EVENTS.SOMETHING_WENT_WRONG,
    })
  }

  async function init(i18n: any | null, emit: any | null, $prismic: any) {
    setReady(false)
    const envStore = useEnvStore()
    const devStore = useDevStore()
    const analyticsStore = useAnalyticsStore()
    const authStore = useAuthStore()
    const corporateStore = useCorporateStore()

    // Set environment Variables
    await envStore.init()

    // Set up theme - required for API brand request header to be set
    await useThemeStore().init($prismic)

    // Set up the API
    const apiStore = useApiStore()
    const deviceStore = useDeviceStore()
    const deviceId = deviceStore.getDeviceId()
    apiStore.init(deviceId)

    const environment = envStore.getVariable('VUE_APP_ENV')

    // Set Up Dev Page
    if (process.env.VUE_APP_ENV === 'development' || process.env.NODE_ENV === 'development') {
      devStore.init()
    }

    if (i18n) {
      const i18nStore = useI18nStore()
      i18nStore.setI18n(i18n)
      
      //used to set corporate testing languages
      i18nStore.setTestingLanguages([])
    }

    await deviceStore.init()

    if (environment === 'production') {
      initCaptcha()
    }

    // Initialize analytics provider based on MKT consent

    analyticsStore.setConsentReady()

    analyticsStore.init(emit)
    analyticsStore.setAnalyticsReady()

    await authStore.initGso()

    const hasSession = await authStore.restoreSession()
    const isCorporateAccount = authStore.isCorporateAccount

    if (hasSession) {
      if (!isCorporateAccount) {
        await useActivityStore().getTransactions()
      }

      if (
        window.location.pathname === '' ||
        window.location.pathname === '/' ||
        window.location.pathname.indexOf('/login') === 0
      ) {
        await handleRedirects(router)
      }
    } else {
      const continueInit = await authStore.checkGso(router)
      if (!continueInit) {
        return
      }
    }


    const app_version = envStore.getVariable('VUE_APP_XE_VERSION')
    console.info('Xe Version: ', app_version)


    if (isCorporateAccount) {
      corporateStore.getTermsAndConditions()
    }

    //need this to set the device id to the header.
    //need for authentication
    if (environment != 'production') {
      const resourcesStore = useResourcesStore()
      await resourcesStore.getSystemFields()
    }

    setReady(true)
  }

  async function handleRedirects(router: any) {
    const { isValidRedirect: isOpenBankingRedirect } = useOpenBanking()
    const authStore = useAuthStore()
    const activityStore = useActivityStore()
    // Redirect new users to quote screen

    let route = `/activity`

    const isVoltRedirect = isOpenBankingRedirect()

    if (isVoltRedirect) {
      console.info('OpenBanking redirects back to Xe')
    } else {
      let action = actionExists()

      const referrer = document.referrer
      const transferUrl = useEnvStore().getVariable('VUE_APP_URL_TRANSFER')
      const isBranded = useThemeStore().isBranded

      const shouldBeRedirectedToQuoteScreen =
        !activityStore.hasTransactions &&
        authStore.isConsumerAccount &&
        referrer === transferUrl &&
        !isBranded



      if (action && authStore.getIsDirectDebitEnabledAccount) {
        route = GALILEO_LANDING[action]
        route += `?galileoAction=${action}`
      } else if (shouldBeRedirectedToQuoteScreen) {
        route = '/send-money'
      }

      await router.replace(route)
    }
  }

  function navigateToXeApp() {
    window.location.href = xeAppUrl.value
  }

  async function initCaptcha() {
    const envStore = useEnvStore()

    const captchaPromise = await loadScript(
      `${envStore.env.VUE_APP_RECAPTCHA_SCRIPT_URL}${envStore.env.VUE_APP_RECAPTCHA_PUBLIC_KEY}`
    )
    setCaptchaPromise(captchaPromise)
    return captchaPromise
  }

  async function getCaptchaToken(): Promise<string> {
    const envStore = useEnvStore()


    if (captchaPromise) {
      await captchaPromise
    }
    //@ts-ignore
    if (!captchaPromise || typeof grecaptcha === 'undefined') {
      await initCaptcha()
    }
    return new Promise((resolve) => {
      // eslint-disable-next-line no-undef
      //@ts-ignore
      grecaptcha.ready(() => {
        // eslint-disable-next-line no-undef
        //@ts-ignore
        grecaptcha
          .execute(envStore.env.VUE_APP_RECAPTCHA_PUBLIC_KEY, { action: 'submit' })
          .then((token: any) => {
            resolve(token)
          })
      })
    })
  }


  // TODO: investigate do we really neeed to use articale argument here and in openHelpDesk method
  const getHelpDeskUrl = (article: string | null, articleKey: string | null): string => {

    function addLangTag(str: string, lang: string) {
      // Split the string into two parts at the "hc/" substring
      const parts = str.split("hc/")

      // If the string does not contain "hc/", return the original string
      if (parts.length !== 2) {
        return str
      }

      // Join the parts back together with the lang tag inserted
      return `${parts[0]}hc/${lang}/${parts[1]}`
    }


    const linksSource = brandedHelpDeskLinks.value ?? HelpDesk

    const baseUrl = linksSource[HelpDeskProperties.BASE]

    let articlePath = articleKey ? linksSource[articleKey] : article

    //check if there is a separate article for corporate
    //[TODO-TBD] can be improved by adding corporate brand
    if (articleKey && useAuthStore().isCorporateAccount) {
      const corpArticleKey = `${articleKey}_corporate`
      article = linksSource[corpArticleKey]

      if (article) {
        articlePath = article
      }
    }

    let lang = useI18nStore().localeLanguagePart
    const originalLocaleValue = useI18nStore().i18n.locale
    if (lang === 'en' || lang === 'nb')
      lang = originalLocaleValue.toLowerCase()

    if (articlePath) {
      articlePath = addLangTag(articlePath, lang)
      return `${baseUrl}/${articlePath}`
    }

    return `${baseUrl}/hc/${lang}`
  }

  async function openHelpDesk({
    loadingRef,
    article = null,
    defaultHelpCentre = false,
    constName = null,
  }: {
    loadingRef: Ref<boolean | null>
    article?: string | null
    defaultHelpCentre?: boolean
    constName?: string | null
  }) {
    const isProduction = useEnvStore().isProduction

    function isIOS() {
      return (
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
          navigator.platform
        ) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document) ||
        /iPad|iPhone|iPod/.test(navigator.userAgent)
      )
    }

    //setting this to true will not make the login to helpcenter
    if (!isProduction || isIOS()) {
      defaultHelpCentre = true
    }

    const authStore = useAuthStore()
    if (loadingRef.value) {
      loadingRef.value = true
    }

    let url = getHelpDeskUrl(article, constName)

    const isAuthenticated = authStore.isAuthenticated
    const isBranded = useThemeStore().isBranded

    if (isBranded) {
      window.open(url, '_blank')?.focus()
      if (loadingRef.value) {
        loadingRef.value = false
      }
      return
    } else if (isAuthenticated && !defaultHelpCentre) {
      try {
        const { data } = await getHelpCentreToken.exec()
        if (!data) {
          throw new Error('Missing Zendesk token')
        }
        url =
          useEnvStore().env.VUE_APP_HELPCENTRE_URL + data + '&return_to=' + encodeURIComponent(url)
      } catch (ex) {
        logException('Failed to open Zendesk with JWT', ex)
      }
    }

    try {
      const openWindow = window.open(url, '_blank')
      if (openWindow) {
        openWindow.focus()
      } else {
        const link = document.createElement('a')
        link.href = url
        link.target = '_blank'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    } catch (ex) {
      logException('Failed to open Zendesk in new tab', ex)
      window.location.href = url
    }
    
    if (loadingRef.value) {
      loadingRef.value = false
    }
  }

  //CHECK TYPE OF URL
  async function openWindow(url: string) {
    setTimeout(() => {
      let lastEx = null
      try {
        window.open(url, '_blank')
        return
      } catch (ex) {
        lastEx = ex
      }
      try {
        window.open(url, '_top')
        return
      } catch (ex) {
        lastEx = ex
      }
      logException('Failed to open URL in new tab', lastEx)
      window.location.href = url
    })
  }

  return {
    brandedHelpDeskLinks,
    messageBox,
    hasMessage,
    loadedScripts,
    xeAppUrl,
    mapboxGeocoder,
    currentRoute,
    captchaPromise,
    getMessageBox,
    getHasMessage,
    getLoadedScripts,
    getIsReady,
    getXeAppUrl,
    getCurrentRoute,
    accessedFormsFromPath,
    accessIndividualCurrencyFromPath,
    accessBalancesListFromPath,
    isFromIncompleteTransaction,
    setXeAppUrl,
    setHasMessage,
    setReady,
    setCaptchaPromise,
    setMapboxGeocoder,
    setCurrentRoute,
    setBrandedHelpDeskLinks,
    fetchMessageBox,
    messageBoxGenericOk,
    messageBoxGenericError,
    hideMessageBox,
    loadScript,
    loadScriptPlaid,
    loadScriptCardinal,
    loadScriptMapboxGeocoder,
    loadScriptVolt,
    log,
    logInfo,
    logError,
    logException,
    init,
    navigateToXeApp,
    initCaptcha,
    getCaptchaToken,
    openHelpDesk,
    openWindow,
    handleRedirects,
    showConfigIssueModal,
    hideConfigIssueModal,
    shouldShowConfigModal,
    getHelpDeskUrl,
    logIntegration
  }
})
