import { parse, isValid, differenceInYears, isFuture } from 'date-fns'

const isOlderThan = (v: string, age: number) => {
  // Expects format 'YYYY-MM-DD'.
  if (!isValidDate(v)) {
    return true // ! WILL NOT SHOW MSG IF DATE IS INVALID. USE IN COMBINATION WITH isValidDate
  }
  const today = new Date()
  const newDate = new Date(`${v}T00:00:00`)
  if (newDate > today) {
    return true // ! THIS WILL NOT SHOW ERROR MSG IF DATE IS IN THE FUTURE. USE noFutureDate VALIDATION FOR THAT
  }
  
  return differenceInYears(today, newDate) >= age
}

export const isPersonUnder120 = (v: string) => {
  return !isOlderThan(v, 120)
}

export const isPersonOver18 = (v: string) => {
  return isOlderThan(v, 18)
}

export const noFutureDate = (v: string) => {
  // Expects format 'YYYY-MM-DD'.
  if (!isValidDate(v)) {
    return true // ! WILL NOT SHOW MSG IF DATE IS INVALID. USE IN COMBINATION WITH isValidDate
  }
  const newDate = new Date(`${v}T00:00:00`)

  return !isFuture(newDate)
}

export const isValidDate = (v: string) => {
  // Regular expression to check if the year part of the date is exactly 4 digits
  // First, check if the format of the date string matches 'yyyy-MM-dd'
  if (!/^\d{4}-\d{2}-\d{2}$/.test(v)) {
    return false
  }
  // Parse the date string
  const parsedDate = parse(v, 'yyyy-MM-dd', new Date())
  // Check if the parsed date is valid
  return isValid(parsedDate)
}