import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'
import { useI18nStore } from '@galileo/stores'

export default new APIHandler(
  API,
  ({ newEmail }, config) => {
    return {
      ...config,
      method: 'POST',
      url: 'authorization/changeEmail',
      data: {
        newEmail,
        preferredLanguage: useI18nStore().localeLanguagePart,
      },
    }
  },
  async (response) => {
    let savedSucessfully = false

    if (response.status === 200) {
      savedSucessfully = true
    }

    return savedSucessfully
  }
)
