<template>
  <InformationAppModal :value="value" icon="warning">
    <template>
      <h1 class="title">
        {{ $t('AddressChangeDisallowedModal.Title').value }}
      </h1>

      <p>
        {{ $t('AddressChangeDisallowedModal.ParagraphText').value }}
      </p>
    </template>

    <template #footer>
      <AppButton @click="gotIt">
        {{ $t('AddressChangeDisallowedModal.GotItButton').value }}
      </AppButton>
      <AppButton theme="secondary" @click="closeModal">
        {{ $t('AddressChangeDisallowedModal.CloseButton').value }}
      </AppButton>
    </template>
  </InformationAppModal>
</template>

<script>
import { useI18nStore } from '@galileo/stores'

import { AppButton } from '@oen.web.vue2/ui'

import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'

export default {
  name: 'AddressChangeDisallowedModal',
  components: {
    AppButton,
    InformationAppModal,
  },
  emits: ['close'],
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()

    const gotIt = () => {
      emit('gotIt')
    }

    const closeModal = () => {
      emit('close')
    }

    return { gotIt, closeModal, $t }
  },
}
</script>

<style scoped>
::v-deep .card-header {
  @apply mb-9 py-0;
  .card-header-title {
    @apply h-14;
    svg {
      @apply h-14 w-14;
    }
  }
}
::v-deep .card.card--has-footer {
  @screen sm {
    @apply py-12;
  }
}

::v-deep .card-footer .button--secondary {
  @screen sm {
    @apply mb-0 !important;
  }
}

::v-deep .card-footer .button--primary {
  @screen sm {
    @apply mb-6 !important;
  }
}

::v-deep .modal-body {
  @apply mb-8;
  p {
    @apply text-base leading-6;
  }
}
</style>
