import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'
import { PAYMENT_ERRORS } from '@galileo/constants/payments/payment.const'
import { APIError } from '@galileo/models/api/interfaces/APIError'

export default new APIHandler(
  API,
  ({ versionNumber, bankAccountId }) => {
    return {
      method: 'DELETE',
      url: `/client-bank-accounts?VersionNumber=${versionNumber}&ClientBankAccountId=${bankAccountId}`,
    }
  },
  (response) => {
    if (response.status === 200) {
      return response
    }
  },
  (error) => {
    const errorMessage = error.response.data.message.includes(
      PAYMENT_ERRORS.CANNOT_DELETE_BANK_ACCOUNT
    )
    if (errorMessage) {
      throw new APIError(PAYMENT_ERRORS.CANNOT_DELETE_BANK_ACCOUNT, '400')
    } else {
      throw new APIError('Failed to delete bank account', '400')
    }
  }
)
