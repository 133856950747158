import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  ({ currency, country, cvn, trxRef, profileId, isNewCard }, config) => {
    return {
      ...config,
      method: 'POST',
      url: 'cards/card-transactions',
      data: {
        country,
        ccy: currency,
        cvn,
        deviceDataCollectionRefId: trxRef,
        isNewCard,
        transactionReference: trxRef,
        userId: profileId,
      },
    }
  },
  (response) => {
    return {
      ...response,
      data: {
        encryptedCvn: response.data.encryptedCvn,
        fields: response.data.fields,
        payerAuthenticationAccessToken: response.data.transactionalJwt,
        flexPublicKey: response.data.flexPublicKey,
      },
    }
  }
)
