import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/LaunchpadAPIHandler'
import { GALILEO_PLATFORM } from '@galileo/constants/platformType'

class RefreshResult {
  constructor(data) {
    if (data.authToken) {
      this.token = data.authToken.jwtToken
      this.tokenType = 'Bearer'
      this.refreshTokenExpiry = data.authToken.expiresInSeconds
      this.refreshToken = data.refreshToken
      this.idToken = data.idToken
    }
  }
}

export default new APIHandler(
  API,
  (refreshToken, config) => {
    return {
      ...config,
      method: 'POST',
      url: 'authorization/refresh',
      data: {
        refreshToken,
        devicePlatform: GALILEO_PLATFORM,
      },
    }
  },
  async (response) => {
    return {
      ...response,
      data: new RefreshResult(response.data),
    }
  }
)
