import API from '@galileo/api/ct'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  (userInformation, config) => {
    return {
      ...config,
      method: 'POST',
      url: 'Account/ConfirmResourceUpdate',
      data: userInformation,
    }
  },
  (response) => {
    return response
  }
)
