














































import { defineComponent, computed, onMounted } from '@vue/composition-api'
import { storeToRefs } from 'pinia'
import {
  useMediaQuery,
  useAppOverlay,
  AppCard,
  AppCardHeader,
  AppIcon,
  AppOverlay,
} from '@oen.web.vue2/ui'
import KYCRefreshCorporateWarningCard from './KYCRefreshCorporateWarningCard.vue'
import KYCRefreshCorporateSuccessCard from './KYCRefreshCorporateSuccessCard.vue'
import KYCRefreshCorporateUBOList from './KYCRefreshCorporateUBOList.vue'
import KYCRefreshCorporateUBOForm from './KYCRefreshCorporateUBOForm.vue'
import KYCRefreshCorporateBusinessName from './KYCRefreshCorporateBusinessName.vue'
import KYCRefreshCorporateCard from './KYCRefreshCorporateCard.vue';
import IconClose from '@oen.web.vue2/icons/src/components/IconClose.vue'
import IconChevronLeft from '@oen.web.vue2/icons/src/components/carbon/IconChevronLeft.vue'
import { useKYCRefreshCorporateStore, useAnalyticsStore } from '@galileo/stores'
import KYCRefreshCorporateConfirmationCard from './KYCRefreshCorporateConfirmationCard.vue'
import KYCRefreshCorporateAddress from './KYCRefreshCorporateAddress/KYCRefreshCorporateAddress.vue'
import { KycRefreshCorporateStepEnum } from '@galileo/stores/kycRefreshCorporate'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'
import { CorpProfileAddress } from '@galileo/models/Corporate/interfaces/CorpReg'

export default defineComponent({
  name: 'KYCRefreshCorporateModal',
  components: {
    AppOverlay,
    AppCard,
    AppCardHeader,
    AppIcon,
    IconClose,
    IconChevronLeft,
    KYCRefreshCorporateWarningCard,
    KYCRefreshCorporateUBOList,
    KYCRefreshCorporateUBOForm,
    KYCRefreshCorporateBusinessName,
    KYCRefreshCorporateCard,
    KYCRefreshCorporateConfirmationCard,
    KYCRefreshCorporateAddress,
    KYCRefreshCorporateSuccessCard,
  },
  emits: ['input', 'showing', 'show', 'shown', 'hiding', 'hide', 'hidden'],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit, slots }) {
    const { dismiss, visible, onShowing, onShow, onShown, onHiding, onHide, onHidden } =
      useAppOverlay(props, emit)

    // Layout stuff
    const mq = useMediaQuery()
    const classes = computed(() => {
      return {
        'modal--xs': mq.current === 'xs',
      }
    })

    // Store
    const kycRefreshCorporateStore = useKYCRefreshCorporateStore()
    const analyticsStore = useAnalyticsStore()
    const { currentStep } = storeToRefs(kycRefreshCorporateStore)

    // Modal
    const showModal = computed(() => kycRefreshCorporateStore.isModalVisible)
    const isHidding = (...args: unknown[]) => {
      if (kycRefreshCorporateStore.currentStep !== KycRefreshCorporateStepEnum.SUCCESS) {
        // Trigger "KYC Refresh Skipped" event
        analyticsStore.track({ event: SEGMENT_EVENTS.KYC_REFRESH_SKIPPED })
      }
      kycRefreshCorporateStore.hideModal()
      onHiding(args)
    }

    // Section variables
    const registrationAddress = computed(()=>kycRefreshCorporateStore.registrationAddressStep.data || {} as CorpProfileAddress)
    const tradingAddress = computed(()=>kycRefreshCorporateStore.tradingAddressStep.data || {} as CorpProfileAddress)

    onMounted(() => {
      // Maybe add a flag to indicate that this was started (and to prevent data loss)
      if (kycRefreshCorporateStore.isGracePeriod || kycRefreshCorporateStore.isOverdue) {
        kycRefreshCorporateStore.startKycRefresh()
        kycRefreshCorporateStore.showModal();
      }
    })

    return {
      isHidding,
      dismiss,
      visible,
      classes,
      onShowing,
      onShow,
      onShown,
      onHiding,
      onHide,
      onHidden,
      slots,
      // Modal
      showModal,
      // Section variable
      registrationAddress,
      tradingAddress,
      currentStep,
      goToStep: kycRefreshCorporateStore.goToStep,
      KycRefreshCorporateStepEnum,
      isRolledOut: kycRefreshCorporateStore.isRolledOut
    }
  },
})
