const charsMap = {
  а: 'a',
  б: 'b',
  в: 'v',
  г: 'g',
  ѓ: 'g`',
  ґ: 'g`',
  д: 'd',
  ђ: 'd',
  е: 'e',
  ё: 'yo',
  є: 'ye',
  ж: 'zh',
  з: 'z',
  ѕ: 'z`',
  и: 'i',
  й: 'j',
  ј: 'j',
  і: 'i`',
  ї: 'yi',
  к: 'k',
  ќ: 'k`',
  л: 'l',
  љ: 'l`',
  м: 'm',
  н: 'n',
  њ: 'n`',
  о: 'o',
  п: 'p',
  р: 'r',
  с: 's',
  т: 't',
  ћ: 'c',
  у: 'u',
  ў: 'u`',
  ф: 'f',
  х: 'kh',
  ц: 'cz',
  ч: 'ch',
  џ: 'dh',
  ш: 'sh',
  щ: 'shh',
  ъ: '``',
  ы: 'y`',
  ь: '`',
  э: 'e`',
  ю: 'yu',
  я: 'ya',
  '’': "'",
  ѣ: 'ye',
  ѳ: 'fh',
  ѵ: 'yh',
  ѫ: 'o`',
  '№': '#',
  А: 'A',
  Б: 'B',
  В: 'V',
  Г: 'G',
  Ѓ: 'G`',
  Ґ: 'G`',
  Д: 'D',
  Ђ: 'D',
  Е: 'E',
  Ё: 'Yo',
  Є: 'Ye',
  Ж: 'Zh',
  З: 'Z',
  Ѕ: 'Z`',
  И: 'I',
  Й: 'J',
  Ј: 'J',
  І: 'I`',
  Ї: 'Yi',
  К: 'K',
  Ќ: 'K`',
  Л: 'L',
  Љ: 'L`',
  М: 'M',
  Н: 'N',
  Њ: 'N`',
  О: 'O',
  П: 'P',
  Р: 'R',
  С: 'S',
  Т: 'T',
  Ћ: 'C',
  У: 'U',
  Ў: 'U`',
  Ф: 'F',
  Х: 'Kh',
  Ц: 'Cz',
  Ч: 'Ch',
  Џ: 'Dh',
  Ш: 'Sh',
  Щ: 'Shh',
  Ъ: '``',
  Ы: 'Y`',
  Ь: '`',
  Э: 'E`',
  Ю: 'Yu',
  Я: 'Ya',
  Ѣ: 'Ye',
  Ѳ: 'Fh',
  Ѵ: 'Yh',
  Ѫ: 'O`',
  α: 'a',
  β: 'v',
  γ: 'g',
  δ: 'd',
  ε: 'e',
  ζ: 'z',
  η: 'ē',
  θ: 'th',
  ι: 'i',
  κ: 'k',
  λ: 'l',
  μ: 'm',
  ν: 'n',
  ξ: 'x',
  ο: 'o',
  π: 'p',
  ρ: 'r',
  σ: 's',
  ς: 's',
  τ: 't',
  υ: 'u',
  φ: 'ph',
  χ: 'kh',
  ψ: 'ps',
  ω: 'ō',
  ά: 'á',
  έ: 'é',
  ί: 'í',
  ό: 'ó',
  ύ: 'ú',
  ή: 'ḗ',
  ώ: 'ṓ',
  ϊ: 'ï',
  ϋ: 'ü',
  ΰ: 'ǘ',
  ΐ: 'ḯ',
  Α: 'A',
  Β: 'V',
  Γ: 'G',
  Δ: 'D',
  Ε: 'E',
  Ζ: 'Z',
  Η: 'Ē',
  Θ: 'Th',
  Ι: 'I',
  Κ: 'K',
  Λ: 'L',
  Μ: 'M',
  Ν: 'N',
  Ξ: 'X',
  Ο: 'O',
  Π: 'P',
  Ρ: 'R',
  Σ: 'S',
  Τ: 'T',
  Υ: 'U',
  Φ: 'Ph',
  Χ: 'Kh',
  Ψ: 'Ps',
  Ω: 'Ō',
  Ά: 'Á',
  Έ: 'É',
  Ί: 'Í',
  Ό: 'Ó',
  Ύ: 'Ú',
  Ή: 'Ḗ',
  Ώ: 'Ṓ',
  Ϊ: 'Ï',
  Ϋ: 'Ü',
}

const translit = (text = '') =>
  text
    .split('')
    .map((char) => charsMap[char.toString()] || char)
    .join('')
    .replaceAll('`', '')

const slug = (text = '', separator = '-') =>
  translit(text)
    .replace(/ /g, separator)
    .replace(/[^a-zA-Z0-9-_]/g, '')
    .toLowerCase()

export { translit, slug }
