import API from '@galileo/api/ct'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  (language, config) => {
    const request = {
      NewSettingValue: language,
      // TODO document this
      SettingArea: 1,
      SettingType: 11,
    }
    return {
      ...config,
      method: 'POST',
      url: `User/Setting`,
      data: request,
    }
  },
  (response) => {
    return {
      ...response,
      data: response,
    }
  }
)
