import XeFieldValidation from './XeFieldValidation'

import { default as useNationalityList } from '@galileo/composables/useAdditionalDetails/useNationalityList'

import { useI18nStore, useResourcesStore } from '@galileo/stores'

export default class XeTransferField {
  constructor(field) {
    this.id = field.id
    this.uniqueId = 'transfer_' + field.id
    this.validations = []
    this.label = field.id // TODO TRANSLATE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    this.hasAsyncValues = field.validation?.url ? true : false
    this.options = []
    this.parent = null
    this.readonly = false
    this.showOnParentValues = []
    this.type = this.createType(field)
    this.value = field.Value
    this.placeholder = field.placeholder
    this.group = field.group
    this.selectedId = ''
    this.text = ''
    const resourcesStore = useResourcesStore()

    if (field.validation && field.validation?.type !== '') {
      if (field.id === 'dateOfBirth') {
        field.validation.type = 'dob'
      } else if (field.id === 'ria_bene_dateOfBirth' || field.id === 'idIssueDate') {
        field.validation.type = 'pastDate'
      }

      this.validations.push(new XeFieldValidation(field.validation))
    }
    this.validations.push({
      type: 'required',
    })

    // extra treatment - copied from Apollo
    if (this.group === 'RiaField') {
      // TODO IMPROVE: should come from the backend
      if (
        this.id === 'ria_phoneCountryCode' ||
        this.id === 'mobileNumber.mobilePrefix' ||
        this.id === 'socialSecurityNumber' ||
        this.id === 'mobileNumber.mobilePrefix' ||
        this.id === 'mobileNumber.mobilePrefix'
      ) {
        this.type = 'RegexNumber' // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
      } else if (
        this.id === 'ria_phoneNo' ||
        this.id === 'mobileNumber.mobilePhone' ||
        this.id === 'ria_bene_phone' ||
        this.id === 'ria_bene_mobile'
      ) {
        this.type = 'RegexPhone' // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
      }
    }
    if (this.id.indexOf('ria_bene_') === 0 || this.id.indexOf('ria_Bene') === 0) {
      this.group = 'recipient'
    }

    if (field.validation?.type === 'YesNo') {
      this.type = 'YesNo' // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    }

    if (
      this.id === 'dateOfBirth' ||
      this.id === 'ria_bene_dateOfBirth' ||
      this.id === 'idIssueDate'
    ) {
      this.type = 'PastDate' // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    } else if (this.type === 'Date') {
      // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
      this.validations.push({
        type: 'RelativeMinDate',
      })
    } else if (this.id === 'IBAN') {
      this.type = 'Iban' // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    } else if (field.id === 'socialSecurityNumber') {
      this.type = 'SocalSecurityNumber' // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    }

    if (this.hasTransferFieldValues(field) && (this.id !== 'ria_bene_nationality' && this.id !== 'occupation')) {
      this.type = 'optionList'
      this.options = []
      for (const element of field.validation?.values) {
        const value = element
        this.options.push({
          id: value.id,
          name: value.description, // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! translation
        })
      }
    } else if (this.id === 'occupation') {
      this.type = 'optionList'
      this.hasAsyncValues = true
      if (field.validation && field.validation.type === 'MatchingList') {   
        const validationValues = field.validation.values
        const matchIndex = validationValues.findIndex((x) => x.id === 'occupationMatch')
        const matches = validationValues[matchIndex].matchOn
        this.options = matches
      }
    }
    else if (this.id === 'relationToBeneficiary') {
      this.type = 'optionList'
      this.hasAsyncValues = true
      this.getAsyncValues = async () => {
        const i18nStore = useI18nStore()
        const relationshipList = await resourcesStore.getRelationsToBeneficiary()
        const mappedResult = relationshipList.map((option) => {
          const translationKey = option.value.toLowerCase()
          return {
            value: option.value,
            text: i18nStore.$t(`RelationshipToBene.${translationKey}`).value,
          }
        })
        return mappedResult
      }
    }
    if (this.id === 'idType') {
      this.type = 'optionList'
      this.hasAsyncValues = true
      const options = field.validation.values
      this.getAsyncValues = () => {
        const i18nStore = useI18nStore()
        return options.map((option) => ({
          text: i18nStore.$t(`PersonalIdentity.${option.id.toLowerCase()}.text`).value,
          value: option.id,
        }))
      }
    } else if (this.id === 'sourceOfFunds') {
      this.type = 'optionList'
      this.hasAsyncValues = true
      this.getAsyncValues = () => {
        return resourcesStore.getSourceOfFunds
      }
    } else if (this.id === 'countryOfBirth' || this.id === 'passportIssuer') {
      this.type = 'country'
    } else if (this.id === 'nationality' || this.id === 'ria_bene_nationality') {
      this.type = 'optionList'
      this.hasAsyncValues = true

      this.getAsyncValues = () => {
        const { getNationalityOptionList } = useNationalityList()
        const nationalityList = getNationalityOptionList()
        const i18nStore = useI18nStore()
        return nationalityList.map((option) => ({
          text: i18nStore.$t(option.translatedText).value,
          value: option.value,
        }))
      }
    } else if (field.validation?.type === 'Regex' && field.validation?.regex) {
      const regex = field.validation?.regex
      if (
        regex.indexOf('^[0-9]{') === 0 &&
        regex.indexOf('{', 7) === -1 &&
        regex.indexOf('[', 7) === -1
      ) {
        // ensure that just one number block is used
        this.type = 'number'
      } // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    }
  }

  createType(field) {
    const { type, id, validation } = field
    if (id === 'phonenumber') {
      return 'phoneNumber'
    }
    if (type === 'Date') {
      return 'date'
    }
    if (validation?.url && validation?.url !== 'lookup') {
      return 'optionList'
    }
    if (id === 'country') {
      return 'country'
    }
    return 'default'
  }

  hasTransferFieldValues(transferField) {
    return transferField?.validation?.values?.length > 0
  }
}
