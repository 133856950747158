/*
 There is an assumption that we will ALWAYS use codes in this format 'aa-AA',
 there are some other variants we may need to support in the future, eg 'aaa-AA' or 'aa-AAA'
 https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
*/
import enGB from '@galileo/locales/en_GB.json'
import custom1 from '@galileo/locales/custom_1.json'
import { i18nDefinition } from 'vue-composable'
import { Lang } from '@galileo/types/i18n'

// TODO: replace any with the type of the dictionary
const messages: Record<Lang, any> = {
  'en-GB': enGB,
  'es-ES': () => import('@galileo/locales/es_ES.json'),
  'de-DE': () => import('@galileo/locales/de_DE.json'),
  'nl-NL': () => import('@galileo/locales/nl_NL.json'),
  'fr-FR': () => import('@galileo/locales/fr_FR.json'),
  'nb-NO': () => import('@galileo/locales/nb_NO.json'),
  'sv-SE': () => import('@galileo/locales/sv_SE.json'),
  'da-DK': () => import('@galileo/locales/da_DK.json'),
  'it-IT': () => import('@galileo/locales/it_IT.json'),
  'pt-PT': () => import('@galileo/locales/pt_PT.json'),
  'custom-1': custom1,
}

export type messagesType = typeof messages

const config: i18nDefinition<messagesType> = {
  locale: 'en-GB',
  fallback: 'custom-1',
  messages,
}

export default config
