import Vue from 'vue'
import { provide, inject, reactive, getCurrentInstance, onUnmounted } from '@vue/composition-api'
import router from '@galileo/router'

import { useSendMoneyStore } from '@galileo/stores'

const RouterSymbol = Symbol()

export function provideRouter(router) {
  provide(RouterSymbol, reactive(router))
}
export function useRouter() {
  return inject(RouterSymbol)
}

export function getRoutePath(args) {
  let path = args
  if (args.path) {
    path = args.path
  }

  if (args.name) {
    const { route } = router.resolve({
      name: args.name,
      params: args?.params, // Required when route contains required params
    })

    path = route.fullPath
  }

  return path
}

let routerStackPaths = [window.location.pathname]
let routerStackStates = [window.history.state]
const routerGo = router.go
const routerReplace = router.replace
const routerPush = router.push
router.go = (steps) => {
  if (steps < 0) {
    for (let i = 0; i < Math.abs(steps); i++) {
      routerStackPaths.pop()
      routerStackStates.pop()
    }
  }
  routerGo.call(router, steps)
}

router.replace = async (args) => {
  const path = getRoutePath(args)

  routerStackPaths.pop()
  routerStackStates.pop()

  routerReplace.call(router, args)

  routerStackPaths.push(path)
  routerStackStates.push(window.history.state)
}

router.push = async (args) => {
  const path = getRoutePath(args)

  routerPush.call(router, args)

  routerStackPaths.push(path)
  routerStackStates.push(window.history.state)
}
router.pop = () => {
  router.go(-1)
}
router.goBackSteps = async (steps) => {
  if (steps < 1) {
    return false
  }

  const pos = routerStackStates.length - 1 - steps
  if (pos < 0) {
    return false
  }

  // unfortunately we can't fully rely on our stack as i.e. Onfido pushes pages to the window.history - hence we look for the correct state we should have and restore to it
  let lastState = routerStackStates[routerStackStates.length - 1]
  if (window.history.state && lastState && window.history.state.key !== lastState.key) {
    let maxSize = window.history.length

    let promiseResolve
    const promise = new Promise((resolve) => {
      promiseResolve = resolve
    })
    const popstate = window.onpopstate
    window.onpopstate = () => {
      if (!window.history.state) {
        promiseResolve(false)
        return
      }

      if (window.history.state.key === lastState.key) {
        promiseResolve(true)
        return
      }

      maxSize--
      if (maxSize <= 0 || window.history.length <= 0) {
        promiseResolve(false)
        return
      }

      routerGo.call(router, -1)
    }
    routerGo.call(router, -1)
    const result = await promise
    window.onpopstate = popstate
    if (!result) {
      return false
    }
  }

  let waitResolve
  const waitPop = new Promise((resolve) => {
    waitResolve = resolve
  })
  const popstate = window.onpopstate
  window.onpopstate = () => {
    waitResolve(true)
  }

  routerGo.call(router, steps * -1)

  routerStackPaths = routerStackPaths.slice(0, pos + 1)
  routerStackStates = routerStackStates.slice(0, pos + 1)
  if (routerStackStates.length > 0) {
    lastState = routerStackStates[routerStackStates.length - 1]
  } else {
    lastState = null
  }

  await waitPop
  window.onpopstate = popstate

  // it seems 3DS is also messing with our states history hence it can be that when we do an order with 3DS window we don't get to the correct position
  if (lastState && window.history.state && lastState.key !== window.history.state.key) {
    return false
  }

  return true
}

// Go back to specified route args
// Force - When route is not found in navigation stack force navigation, default - false
// Force will replace the current route
router.goBackTo = async (args, force = false) => {
  const path = getRoutePath(args)

  const pos = routerStackPaths.lastIndexOf(path)

  let result = false

  if (pos >= 0) {
    const steps = routerStackPaths.length - 1 - pos
    result = await router.goBackSteps(steps)
  }

  if (!result && force) {
    await router.replace(path)
  }

  return result
}

router.getParentRoute = () => {
  const { matched } = router.currentRoute
  let parent = null

  if (matched.length < 2) {
    //no parents redirect to home
    return '/'
  } else {
    //take second last element of matched object
    parent = matched[matched.length - 2]
    return { name: parent.name }
  }
}

router.toParentRoute = () => {
  const { matched } = router.currentRoute
  let parent = null

  if (matched.length < 2) {
    //no parents redirect to home
    router.replace('/')
  } else {
    //take second last element of matched object
    parent = matched[matched.length - 2]
    router.replace({ name: parent.name })
  }
}
router.forceRedirect = (path) => {
  const sendMoneyStore = useSendMoneyStore()
  sendMoneyStore.bypassSendMoneyCreatingRedirection = true
  router.replace(path)
}

export function redirectToRoute(redirectRoute) {
  if (router.currentRoute.path !== redirectRoute) {
    router.replace(redirectRoute)
  }
}

const unregisterCallback = (callbacks, handler) => {
  const index = callbacks.indexOf(handler)
  if (index > -1) {
    callbacks.splice(index, 1)
  }
}

export function onHook(name, callback) {
  const vm = getCurrentInstance()
  const merge = Vue.config.optionMergeStrategies[name]
  if (vm && merge) {
    const prototype = Object.getPrototypeOf(vm.proxy.$options)
    prototype[name] = merge(vm.proxy.$options[name], callback)

    onUnmounted(() => unregisterCallback(prototype[name], callback))
  }
}

export function onBeforeRouteUpdate(callback) {
  return onHook('beforeRouteUpdate', callback)
}

export function onBeforeRouteLeave(callback) {
  return onHook('beforeRouteLeave', callback)
}
