<template>
  <div
    ref="tooltipElementWrapper"
    class="tooltip-wrapper"
    @mouseover="mouseoverHandler"
    @mouseleave="mouseleaveHandler"
    @click.stop="clickHandlerWrappedElement"
  >
    <slot name="wrappedElement" class="slot"></slot>

    <Transition name="fade">
      <div
        v-if="active"
        ref="tooltipElement"
        :style="{ width: tooltipWidth }"
        :class="['tooltip-tip', side]"
        @click.stop="clickHandlerTooltip"
      >
        <slot name="tooltipContent"></slot>
      </div>
    </Transition>
  </div>
</template>

<script>
import { ref, onBeforeUnmount, onMounted, watch } from '@vue/composition-api'

export default {
  name: 'XeTooltip',
  props: {
    side: {
      type: String,
      default: 'bottom',
      validator(value) {
        return ['top', 'right', 'bottom', 'left', 'bottomInfo'].includes(value)
      },
      required: true,
    },
    activation: {
      type: String,
      default: 'hover',
      validator(value) {
        return ['click', 'hover'].includes(value)
      },
      required: true,
    },
  },
  setup(props) {
    const active = ref(false)
    const tooltipWidth = ref('24rem')
    const tooltipElement = ref(null)
    const tooltipElementWrapper = ref(null)

    onBeforeUnmount(() => {
      if (props.activation === 'click') {
        document.removeEventListener('click', () => {
          active.value = false
        })
      }
    })

    onMounted(() => {
      if (props.activation === 'click') {
        document.addEventListener('click', () => {
          active.value = false
        })
      }
    })

    const resizeTooltip = () => {
      const viewportWidth = window.innerWidth
      const wrapperPosition = tooltipElementWrapper.value.getBoundingClientRect()

      let width
      if (props.side === 'top' || props.side === 'bottom') {
        width = Math.min(wrapperPosition.left * 2, (viewportWidth - wrapperPosition.right) * 2)
      } else if (props.side === 'left') {
        width = wrapperPosition.left - 20
      } else if (props.side === 'right') {
        width = viewportWidth - wrapperPosition.right - 30
      }

      tooltipWidth.value = `${width}px`
    }

    window.addEventListener('resize', resizeTooltip)

    watch(active, (newValue, oldValue) => {
      if (newValue === true && oldValue === false) {
        resizeTooltip()
      }
    })

    const clickHandlerWrappedElement = () => {
      if (props.activation === 'click') {
        active.value = !active.value
      }
    }

    const clickHandlerTooltip = () => {
      if (props.activation === 'click') {
        active.value = true
      }
    }

    const mouseoverHandler = () => {
      if (props.activation === 'hover') {
        active.value = true
      }
    }

    const mouseleaveHandler = () => {
      if (props.activation === 'hover') {
        active.value = false
      }
    }

    return {
      active,
      clickHandlerWrappedElement,
      clickHandlerTooltip,
      mouseoverHandler,
      mouseleaveHandler,
      tooltipWidth,
      tooltipElement,
      tooltipElementWrapper,
    }
  },
}
</script>

<style scoped>
/* Custom properties */
/* Wrapping */
.tooltip-wrapper {
  display: inline-block;
  position: relative;
  cursor: pointer;
  --tooltip-text-color: rgba(255, 255, 255, 1);
  --tooltip-background-color: rgba(51, 51, 51, 1);
  --tooltip-margin: 10px;
  --tooltip-arrow-size: 6px;
}

/* Absolute positioning */
.tooltip-tip {
  @apply absolute left-1/2;
  @apply px-4 py-1 rounded-lg;
  font-size: 15px;
  @apply leading-6;
  transform: translateX(-50%);
  color: var(--tooltip-text-color);
  background: var(--tooltip-background-color);
  z-index: 100;

  max-width: 24rem;
  min-width: fit-content;

  /* white-space: nowrap; */
}

/* CSS border triangles */
.tooltip-tip::before {
  content: ' ';
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: var(--tooltip-arrow-size);
  margin-left: calc(var(--tooltip-arrow-size) * -1);
}

/* Absolute positioning */
.tooltip-tip.top {
  top: 0;
  transform: translateX(-50%) translateY(calc(-100% - var(--tooltip-margin)));
}
/* CSS border triangles */
.tooltip-tip.top::before {
  top: 100%;
  border-top-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.tooltip-tip.right {
  left: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.tooltip-tip.right::before {
  left: calc(var(--tooltip-arrow-size) * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: var(--tooltip-background-color);
}

.tooltip-tip.bottomInfo {
  bottom: 0;
  transform: translateX(-50%) translateY(calc(100% + var(--tooltip-margin)));
  @apply w-52;
  @apply h-32;
  padding: 4px, 16px, 4px, 16px;
}
/* CSS border triangles */
.tooltip-tip.bottomInfo::before {
  bottom: 100%;
  border-bottom-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.tooltip-tip.bottom {
  bottom: 0;
  transform: translateX(-50%) translateY(calc(100% + var(--tooltip-margin)));
}
/* CSS border triangles */
.tooltip-tip.bottom::before {
  bottom: 100%;
  border-bottom-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.tooltip-tip.left {
  left: auto;
  right: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.tooltip-tip.left::before {
  left: auto;
  right: calc(var(--tooltip-arrow-size) * -2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-left-color: var(--tooltip-background-color);
}
</style>
