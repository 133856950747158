import GenericAccount from '@galileo/models/Payment/GenericAccount'

export default class BankAccount extends GenericAccount {
  constructor(bankAccount) {
    super(bankAccount)
    this.bankName = bankAccount.bankName
    this.accountName = bankAccount.accountName
    this.nickname = bankAccount.accountAlias || bankAccount.bankName
    this.accountType = bankAccount.accountType
    this.localRoutingCode = bankAccount.localRoutingCode
    this.accountNumberUnmasked = bankAccount.accountNumberUnmasked

    // this.routingCodeType = bankAccount.routingCodeType
    this.verificationStatus = bankAccount.verificationStatus
    this.versionNumber = bankAccount.accountId
    this.id = bankAccount.paymentId
    this.title = this.bankName
    this.subTitle = this.type + ' account'
    this.image = 'bank/finance.svg'
  }
}
