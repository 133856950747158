<template>
  <AppModal v-model="isTermsAndConditionsAcceptanceRequired" title="" @show="onShow">
    <template #figureTitle>
      <AppCardImageTitle
        alt="Alarm clock"
        :title="$t('ComponentTermsAndConditionsModal.TitleTermsAndConditions').value"
        :src="require(`@galileo/assets/images/illustrations/alarm-clock.svg`)"
      />
    </template>

    <template #default>
      <div class="mini-card">
        <p
          v-html="
            $t('ComponentTermsAndConditionsModal.TextTermsAndConditions', {
              privacyNoticeURL: privacyNoticeURL,
            }).value
          "
        ></p>
        <AppInputCheckbox v-model="isAcceptedTermsAndConditionsCheckmark">
          <span
            v-html="
              $t('ComponentTermsAndConditionsModal.TextIAgree', {
                termsAndConditionsURL: termsAndConditionsURL,
              }).value
            "
          ></span>
        </AppInputCheckbox>
      </div>
    </template>

    <template #footer>
      <AppModalFooter>
        <AppButton
          analytics-name="login-new-terms-agree"
          :loading="loading"
          :disabled="!isAcceptedTermsAndConditionsCheckmark"
          @click="acceptTermsAndConditions"
        >
          {{ $t('ComponentTermsAndConditionsModal.ButtonContinue').value }}
        </AppButton>
      </AppModalFooter>
    </template>
  </AppModal>
</template>

<script>
import { computed, ref } from '@vue/composition-api'

import {
  AppModalFooter,
  AppModal,
  AppCardImageTitle,
  AppInputCheckbox,
  AppButton,
} from '@oen.web.vue2/ui'

import { useAuthStore, useI18nStore, useResourcesStore } from '@galileo/stores'

export default {
  name: 'TermsAndConditionsModal',
  components: {
    AppModal,
    AppModalFooter,
    AppButton,
    AppCardImageTitle,
    AppInputCheckbox,
  },
  setup() {
    const { $t } = useI18nStore()
    const resourcesStore = useResourcesStore()
    const authStore = useAuthStore()

    const loading = ref(false)
    const isAcceptedTermsAndConditionsCheckmark = ref(false)
    const isTermsAndConditionsAcceptanceRequired = computed(() => {
      return (
        authStore.isTermsAndConditionsAcceptanceRequired ||
        (authStore.isTermsAndConditionsAcceptanceRequired && authStore.showTACAfterAddressChange)
      )
    })
    const showTermsAndConditionsModal = ref(isTermsAndConditionsAcceptanceRequired)
    const termsAndConditionsURL = computed(() => {
      return resourcesStore.getTermsAndConditionsUrl
    })
    const privacyNoticeURL = computed(() => {
      return resourcesStore.getPrivacyPolicyUrl
    })

    const acceptTermsAndConditions = async () => {
      loading.value = true
      await authStore.acceptNewTerms()
      loading.value = false
    }

    const onShow = () => {
      isAcceptedTermsAndConditionsCheckmark.value = false
    }

    return {
      $t,
      loading,
      showTermsAndConditionsModal,
      isAcceptedTermsAndConditionsCheckmark,
      isTermsAndConditionsAcceptanceRequired,
      termsAndConditionsURL,
      privacyNoticeURL,
      acceptTermsAndConditions,
      onShow,
    }
  },
}
</script>

<style scoped>
::v-deep .card-content {
  .card-content-block {
    @apply mb-6 text-sm leading-5;
  }
}

.card-header {
  @apply px-12 pt-12;

  &.card-header--without-title {
    @apply pb-12 mb-0;
  }
}

::v-deep .card-image-title {
  .card-image-title-title {
    @apply text-2xl mb-0;
    line-height: 32px;
  }
}

::v-deep .card.card--has-footer {
  @screen sm {
    @apply pb-12;
  }
}

.card-footer {
  .button {
    @apply w-full;
  }
}

.mini-card {
  @apply p-6;
  @apply rounded-2xl;
  @apply shadow-ria-1;
  border: 0.5px solid #dddddd;

  p {
    @apply mb-4;
  }
}

::v-deep .input-checkbox-virtual-checkbox {
  @apply w-min-content h-auto rounded-none;
}

.button.button--primary {
  &:disabled {
    &:not(.button--loading) {
      @apply text-white;
    }

    background: linear-gradient(
        to right,
        theme('colors.blue.button-disabled'),
        theme('colors.blue.button-disabled')
      ),
      linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
  }
}
</style>
