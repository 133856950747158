<script>
import { AppBackButton } from '@oen.web.vue2/ui'

import { computed } from '@vue/composition-api'
import {
  IconCloseFilled,
  IconChevronLeftFilled,
  IconChevronLeft,
  IconClose,
} from '@oen.web.vue2/icons'

export default {
  name: 'XeBackButton',
  extends: AppBackButton,
  props: {
    iconStyle: {
      type: String,
      default: 'outline',
    },
  },
  setup(props) {
    const component = computed(() => {
      switch (props.icon) {
        case 'x':
          return IconClose
        case 'xx':
          return IconCloseFilled
        case '<<':
          return IconChevronLeftFilled
        case '<':
          return IconChevronLeft
      }
    })

    return {
      ...AppBackButton.setup(),
      component,
    }
  },
}
</script>

<style scoped>
.button {
  max-width: 48px;
}
.button.button--icon {
  @screen sm {
    @apply w-6 h-6 !important;
    &:hover:not(:disabled) {
      @apply bg-transparent;
    }
    ::v-deep .button-inner {
      @apply w-6 h-6 text-tertiary-text;
      &:hover {
        @apply text-gray-darker;
      }
    }
  }
}
</style>
