import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  ({ payload, profileId }, config) => {
    return {
      ...config,
      method: 'PATCH',
      url: `profiles/${profileId}`,
      data: payload,
    }
  },
  async (response) => {
    let savedSucessfully = false
    if (response.status === 200) {
      savedSucessfully = true
    }
    return savedSucessfully
  }
)
