import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'
import TransactionResult from '@galileo/models/Transaction/result.ts'

export default new APIHandler(
  API,
  ({ profileId, pageSize = 50, pageNumber = 1, currencyCode = null }, config) => {
    let requestUrl = `transactions?profileId=${profileId}&pageSize=${pageSize}&pageNumber=${pageNumber}`

    if (currencyCode) {
      requestUrl += `&currencyCode=${currencyCode}`
    }

    return {
      ...config,
      method: 'GET',
      url: requestUrl,
    }
  },
  (response) => {
    let transactions = []
    if (response.data.items) {
      transactions = response.data.items.map((transaction) => new TransactionResult(transaction))
    }
    return {
      data: {
        totalItems: response.data.totalItems,
        transactions,
        pages: response.data.totalPages,
      },
    }
  }
)
