<template>
  <AppModal
    v-model="model"
    :title="$t('AdditionalInformationModal.Title').value"
    @show="clearIdFields"
  >
    <template #header="{ dismiss }">
      <AppModalHeader>
        <h1>{{ $t('AdditionalInformationModal.Title').value }}</h1>
        <template #left>
          <XeBackButton
            analytics-name="additional-information-modal-close"
            icon="<"
            @click="dismiss"
          />
        </template>
      </AppModalHeader>
    </template>

    <template #default>
      <AppAlert class="id-info-alert" theme="yellow">
        <p>
          <span role="img" aria-label="Warning emoji">⚠️</span>
          {{ $t('AdditionalInformationModal.AlertMessageText').value }}
        </p>
      </AppAlert>

      <!-- Australia and New Zealand -->
      <form
        v-if="country === 'AU' || country === 'NZ'"
        id="additional-information-form"
        @submit.prevent
      >
        <!-- TODO: Handle displaying of different ID types for different countries -->
        <AppInputDropdown
          v-model="idFields.idType"
          :label="$t('AdditionalInformationModal.IdTypeLabel').value"
          :options="idTypes"
          :placeholder="$t('AdditionalInformationModal.IdTypePlaceholder').value"
          selected-display-field="name"
          selected-value-field="value"
          @change="clearIdFields"
        />

        <!---- Australian driver's license ----->
        <div v-if="idFields.idType === 'DriverLicence' && country === 'AU'" :key="idFields.idType">
          <AppInputDropdown
            v-model="idFields.licenceIssuer"
            :options="states"
            :validation="idFieldsValidation.licenceIssuer"
            selected-display-field="name"
            selected-value-field="value"
            data-key="id"
            :label="$t('AdditionalInformationModal.IssuedByLabel').value"
            :placeholder="$t('AdditionalInformationModal.IssuedByStatePlaceholder').value"
          />
          <AppInputText
            v-model="idFields.licenceNumber"
            :validation="idFieldsValidation.licenceNumber"
            :label="$t('AdditionalInformationModal.LicenceNumberLabel').value"
            :placeholder="$t('AdditionalInformationModal.EnterNumberPlaceholder').value"
          />
          <AppInputText
            v-model="idFields.licenceCardNumber"
            :validation="idFieldsValidation.licenceCardNumber"
            :label="$t('AdditionalInformationModal.LicenceCardNumberLabel').value"
            :placeholder="$t('AdditionalInformationModal.EnterNumberPlaceholder').value"
            class="card-number"
          >
            <template #labelRight>
              <XeTooltip activation="click" side="bottom">
                <template #wrappedElement>
                  <AppIcon name=" ">
                    <IconInformation />
                  </AppIcon>
                </template>
                <template #tooltipContent>
                  {{ $t('AdditionalInformationModal.CardNumberTooltipText').value }}
                </template>
              </XeTooltip>
            </template>
          </AppInputText>
          <XeInputDate
            :key="idFields.idType"
            v-model="idFields.idExpiryDate"
            :validation="allowedDateRangeValidation.idExpiryDate"
            :label="$t('AdditionalInformationModal.IdExpiryDateLabel').value"
            input-format="DD/MM/YYYY"
          />
        </div>

        <!---- New Zealand driver's license ----->
        <div v-if="idFields.idType === 'DriverLicence' && country === 'NZ'" :key="idFields.idType">
          <AppInputText
            v-model="idFields.licenceNumber"
            :validation="idFieldsValidation.licenceNumber"
            :label="$t('AdditionalInformationModal.LicenceNumberLabel').value"
            :placeholder="$t('AdditionalInformationModal.EnterNumberPlaceholder').value"
          />
          <AppInputText
            v-model="idFields.licenceVersion"
            :validation="idFieldsValidation.licenceVersion"
            :label="$t('AdditionalInformationModal.LicenceVersionLabel').value"
            :placeholder="$t('AdditionalInformationModal.LicenceVersionPlaceholder').value"
          />
          <XeInputDate
            :key="idFields.idType"
            v-model="idFields.idExpiryDate"
            :validation="allowedDateRangeValidation.idExpiryDate"
            :label="$t('AdditionalInformationModal.IdExpiryDateLabel').value"
            input-format="DD/MM/YYYY"
          />
        </div>

        <!-- Passport -->
        <div v-if="idFields.idType === 'Passport'" :key="idFields.idType">
          <AppInputText
            v-model="idFields.passportNumber"
            :validation="idFieldsValidation.passportNumber"
            :label="$t('AdditionalInformationModal.PassportNumberLabel').value"
            :placeholder="$t('AdditionalInformationModal.EnterNumberPlaceholder').value"
          />
          <AppInputDropdown
            v-model="idFields.passportIssuer"
            :options="countries"
            :validation="idFieldsValidation.passportIssuer"
            selected-display-field="text"
            selected-value-field="value"
            data-key="id"
            :label="$t('AdditionalInformationModal.IssuedByLabel').value"
            :placeholder="$t('AdditionalInformationModal.IssuedByCountryPlaceholder').value"
            @hide="passportIssuerChanged"
          />
          <!-- File number input - Only When using Indian passport with New Zealand address -->
          <AppInputText
            v-if="passportFileNumberVisible"
            :helperText="$t('AdditionalInformationModal.FileNumberTooltipLabel').value"
            v-model="idFields.passportFileNumber"
            :validation="idFieldsValidation.passportFileNumber"
            :label="'File number'"
            :placeholder="'File number input'"
            class="file-number-input"
          >
            <template #labelRight>
              <XeTooltip activation="hover" side="bottom">
                <template #wrappedElement>
                  <AppIcon>
                    <IconInformation />
                  </AppIcon>
                </template>
                <template #tooltipContent>
                  <p>{{ $t('AdditionalInformationModal.FileNumberTooltipTitle').value }}</p>
                  <ul>
                    <li>{{ $t('AdditionalInformationModal.FileNumberTooltipText1').value }}</li>
                    <li>{{ $t('AdditionalInformationModal.FileNumberTooltipText2').value }}</li>
                  </ul>
                </template>
              </XeTooltip>
            </template>
          </AppInputText>
          <XeInputDate
            :key="idFields.idType"
            v-model="idFields.idExpiryDate"
            :validation="allowedDateRangeValidation.idExpiryDate"
            :label="$t('AdditionalInformationModal.IdExpiryDateLabel').value"
            input-format="DD/MM/YYYY"
          />
        </div>
      </form>

      <form v-else-if="country === 'SA'" id="additional-information-form" @submit.prevent>
        <AppInputText
          v-model="idFields.nationalIdNumber"
          label="S.A. National ID number"
          placeholder="e.g. 1234567890000"
          helper-text="If you have one, we can use your ID number to speed up the verification of your identity"
        />
      </form>
    </template>

    <template #footer>
      <AppModalFooter>
        <AppButton
          type="submit"
          form="additional-information-form"
          analytics-name="change-additional-information-update-button"
          :disabled="
            idFieldsValidation.$anyInvalid ||
            allowedDateRangeValidation.$anyInvalid ||
            dateRangeRegexValidation.$anyInvalid
          "
          :loading="isSaving"
          @click="updateAdditionalInformationAndAddress"
        >
          {{ $t('AdditionalInformationModal.ContinueButton').value }}
        </AppButton>
      </AppModalFooter>
    </template>
  </AppModal>
</template>

<script>
import { ref, reactive, computed, toRef, onBeforeMount } from '@vue/composition-api'
import { useValidation } from 'vue-composable'

import { required, helpers } from '@vuelidate/validators'
import getValueRequiredValidation from '@galileo/utilities/validations.utility'

import { parse, addYears, setMonth, setDate, isBefore, isAfter } from 'date-fns'
import XeBackButton from '@galileo/components/XeBackButton/XeBackButton'
import XeInputDate from '@galileo/components/XeInputDate/XeInputDate'
import XeTooltip from '@galileo/components/XeTooltip/XeTooltip'
import UserPersonalInformation from '@galileo/models/UserProfile/UserPersonalInformation'
import { IDTYPE } from '@galileo/constants/idType.const'

import { IconInformation } from '@oen.web.vue2/icons'

import {
  useVModel,
  useAlert,
  AppAlert,
  AppModal,
  AppInputDropdown,
  AppInputText,
  AppModalFooter,
  AppButton,
  AppModalHeader,
  AppIcon,
} from '@oen.web.vue2/ui'

import {
  useCountriesStore,
  useAuthStore,
  useI18nStore,
  useAppStore,
  useProfileStore,
} from '@galileo/stores'

export default {
  name: 'AdditionalInformationModal',
  components: {
    AppAlert,
    AppModal,
    AppInputText,
    AppInputDropdown,
    AppModalFooter,
    AppButton,
    AppIcon,
    IconInformation,
    XeBackButton,
    XeInputDate,
    XeTooltip,
    AppModalHeader,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    country: {
      type: String,
      default: '',
      required: true,
    },
    addressFields: {
      type: Object,
      default: () => {},
    },
    onSave: {
      type: Function,
      required: false,
      default: () => {},
    },
    idType: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { add } = useAlert()
    const { model } = useVModel(props, emit)
    const countriesStore = useCountriesStore()
    const authStore = useAuthStore()
    const appStore = useAppStore()
    const profileStore = useProfileStore()

    let userInfo = new UserPersonalInformation(props.user)
    let userTemp = reactive(userInfo)
    const valueRequired = getValueRequiredValidation()
    const isSaving = ref(false)
    const passportFileNumberVisible = ref(false)
    const states = ref([])

    const idFields = reactive({
      idType: '',
      idExpiryDate: '',
      passportNumber: '',
      passportIssuer: '',
      licenceCardNumber: '',
      licenceVersion: '',
      licenceNumber: '',
      licenceIssuer: '',
      nationalIdNumber: '',
      passportFileNumber: '',
    })

    const idTypes = computed(() => {
      let idTypesOptions = []
      let labelName = ''
      let idValue = ''

      if (props.idType && props.idType.validation && props.idType.validation.values) {
        for (const idTypeOption of props.idType.validation.values) {
          switch (idTypeOption.id) {
            case IDTYPE.PASSPORT:
              labelName = $t('AdditionalInformationModal.PassportLabel').value
              idValue = idTypeOption.id
              idTypesOptions.push({
                name: labelName,
                value: idValue,
              })
              break

            case IDTYPE.DRIVERLICENCE:
              labelName =
                props.country === 'AU'
                  ? $t('AdditionalInformationModal.AustralianDriversLicenceLabel').value
                  : $t('AdditionalInformationModal.DriversLicenceLabel').value
              idValue = idTypeOption.id
              idTypesOptions.push({
                name: labelName,
                value: idValue,
              })
              break

            case IDTYPE.NATIONALID:
              labelName = $t('AdditionalInformationModal.NationalIdLabel').value
              idValue = idTypeOption.id
              idTypesOptions.push({
                name: labelName,
                value: idValue,
              })
              break
          }
        }

        return idTypesOptions
      }
    })

    onBeforeMount(async () => {
      states.value = await countriesStore.getStatesByCountryCode(props.country)
    })

    const idFieldsValidation = computed(() => {
      const idFieldsValidation = {
        NZDriverLicence: idFieldsValidationNZDriverLicence,
        NZPassport: idFieldsValidationPassport,
        AUDriverLicence: idFieldsValidationAUDriverLicence,
        AUPassport: idFieldsValidationPassport,
      }

      return idFieldsValidation[props.country + idFields.idType] || false
    })

    const idFieldsValidationNZDriverLicence = useValidation({
      idType: {
        $value: toRef(idFields, 'idType'),
        valueRequired,
      },
      idExpiryDate: {
        $value: toRef(idFields, 'idExpiryDate'),
        required: {
          $validator: required.$validator,
          $message: $t('AdditionalInformationModal.ErrorRequiredField').value,
        },
      },
      licenceVersion: {
        $value: toRef(idFields, 'licenceVersion'),
        required: {
          $validator: required.$validator,
          $message: $t('AdditionalInformationModal.ErrorRequiredField').value,
        },
        licenceVersionValid: {
          $validator: helpers.regex(/^\d{3}$|-/),
          $message: $t('AdditionalInformationModal.ErrorInvalidField').value,
        },
      },
      licenceNumber: {
        $value: toRef(idFields, 'licenceNumber'),
        required: {
          $validator: required.$validator,
          $message: $t('AdditionalInformationModal.ErrorRequiredField').value,
        },
        licenceNumberValid: {
          $validator: helpers.regex(/^[\p{Lu}\d][\p{L}\d-]{0,68}[\p{L}\d]$/u),
          $message: $t('AdditionalInformationModal.ErrorInvalidField').value,
        },
      },
    })

    const idFieldsValidationAUDriverLicence = useValidation({
      idType: {
        $value: toRef(idFields, 'idType'),
        valueRequired,
      },
      idExpiryDate: {
        $value: toRef(idFields, 'idExpiryDate'),
        required: {
          $validator: required.$validator,
          $message: $t('AdditionalInformationModal.ErrorRequiredField').value,
        },
      },
      licenceNumber: {
        $value: toRef(idFields, 'licenceNumber'),
        required: {
          $validator: required.$validator,
          $message: $t('AdditionalInformationModal.ErrorRequiredField').value,
        },
        licenceNumberValid: {
          $validator: helpers.regex(/^[A-Za-z0-9]{1,10}$/),
          $message: $t('AdditionalInformationModal.ErrorInvalidField').value,
        },
      },
      licenceIssuer: {
        $value: toRef(idFields, 'licenceIssuer'),
        required: {
          $validator: required.$validator,
          $message: $t('AdditionalInformationModal.ErrorRequiredField').value,
        },
        licenceIssuerValid: {
          $validator: helpers.regex(/^[\p{Lu}][ \p{L}]{0,68}[\p{L}]$|-/u),
          $message: $t('AdditionalInformationModal.ErrorInvalidField').value,
        },
      },
      licenceCardNumber: {
        $value: toRef(idFields, 'licenceCardNumber'),
        required: {
          $validator: required.$validator,
          $message: $t('AdditionalInformationModal.ErrorRequiredField').value,
        },
        licenceCardNumberValid: {
          $validator: helpers.regex(/^[A-Za-z0-9]{1,10}$/),
          $message: $t('AdditionalInformationModal.ErrorInvalidField').value,
        },
      },
    })

    const idFieldsValidationPassport = useValidation({
      idType: {
        $value: toRef(idFields, 'idType'),
        valueRequired,
      },
      idExpiryDate: {
        $value: toRef(idFields, 'idExpiryDate'),
        required: {
          $validator: required.$validator,
          $message: $t('AdditionalInformationModal.ErrorRequiredField').value,
        },
      },
      passportNumber: {
        $value: toRef(idFields, 'passportNumber'),
        required: {
          $validator: required.$validator,
          $message: $t('AdditionalInformationModal.ErrorRequiredField').value,
        },
        passportNumberValid: {
          $validator: helpers.regex(/^[A-Za-z0-9]{1,15}$/),
          $message: $t('AdditionalInformationModal.ErrorInvalidField').value,
        },
      },
      passportIssuer: {
        $value: toRef(idFields, 'passportIssuer'),
        required: {
          $validator: required.$validator,
          $message: $t('AdditionalInformationModal.ErrorRequiredField').value,
        },
        passportIssuerValid: {
          $validator: helpers.regex(/^[\p{Lu}][ \p{L}-]{0,68}[\p{L}]$/u),
          $message: $t('AdditionalInformationModal.ErrorInvalidField').value,
        },
      },
      passportFileNumber: {
        $value: toRef(idFields, 'passportFileNumber'),
        required: {
          $validator(v) {
            if (props.country === 'NZ' && idFields.passportIssuer === 'IN') {
              return !!v
            }
            return true
          },
          $message: $t('AdditionalInformationModal.ErrorRequiredField').value,
        },
        passportFileNumberValid: {
          $validator: helpers.regex(/^[A-Z0-9]{12,15}$/),
          $message: $t('AdditionalInformationModal.ErrorInvalidField').value,
        },
      },
    })

    const passportIssuerChanged = () => {
      if (props.country === 'NZ' && idFields.passportIssuer === 'IN') {
        passportFileNumberVisible.value = true
      } else {
        passportFileNumberVisible.value = false
      }
    }

    const dateRangeRegexValidation = useValidation({
      $value: toRef(idFields, 'idExpiryDate'),
      isValidRegex: {
        $validator(dateInput) {
          const idExpiryDateRegex = new RegExp(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
          const isValidRegex = idExpiryDateRegex.test(dateInput)
          return isValidRegex
        },
      },
    })

    const allowedDateRangeValidation = useValidation({
      idExpiryDate: {
        $value: toRef(idFields, 'idExpiryDate'),
        expiryDateInsideAllowedRange: {
          $validator(dateInput) {
            if (!dateRangeRegexValidation.isValidRegex.$invalid) {
              const idExpiryDateRegex = new RegExp(
                /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
              )
              const isValidRegex = idExpiryDateRegex.test(dateInput)
              // If date entered is in valid format, check if it's also
              // in valid range (current date to 10 years in the future)
              if (isValidRegex) {
                let isValidRange = true
                if (dateInput.length === 0) {
                  return true
                }

                const date = parse(dateInput, 'yyyy-MM-dd', new Date())
                const now = new Date()
                now.setHours(0, 0, 0, 0)
                let maxDate = addYears(now, 10)
                maxDate = setMonth(maxDate, 11)
                maxDate = setDate(maxDate, 31)

                if (isBefore(date, now) || isAfter(date, maxDate)) {
                  isValidRange = false
                }

                return isValidRange && isValidRegex
              }
            }
            return true
          },
          $message: $t('AdditionalInformationModal.ExpirationDateRangeError').value,
        },
      },
    })

    const resetValidations = () => {
      if (idFieldsValidation.value) {
        idFieldsValidation.value.$reset()
      }
    }

    const clearIdFields = () => {
      idFields.idExpiryDate = ''
      idFields.passportNumber = ''
      idFields.passportIssuer = ''
      idFields.passportFileNumber = ''
      idFields.licenceCardNumber = ''
      idFields.licenceVersion = ''
      idFields.licenceNumber = ''
      idFields.licenceIssuer = ''
      idFields.nationalIdNumber = ''
      resetValidations()
    }

    const countries = computed(() => {
      return countriesStore.getCountries.map((country, idx) => {
        return {
          id: idx,
          text: country.text,
          value: country.value,
        }
      })
    })

    const updateAdditionalInformationAndAddress = async () => {
      isSaving.value = true
      userTemp.removeNotMappedProperties()
      userTemp.removePhoneNumber()
      userTemp.removeFullName()
      userTemp.setAddress(props.addressFields)
      userTemp.setAdditionalFields(idFields)

      profileStore.updateUserInfo(userTemp).then((success) => {
        if (success) {
          add(`${$t('EditAddressModal.AddressUpdated').value}`)
          authStore.showTACAfterAddressChange = true
          props.onSave()
          emit('close')
          model.value = false
        } else {
          appStore.messageBoxGenericError()
        }
        isSaving.value = false
      })
    }

    return {
      $t,
      model,
      idFields,
      idFieldsValidation,
      idTypes,
      isSaving,
      countries,
      states,
      clearIdFields,
      allowedDateRangeValidation,
      updateAdditionalInformationAndAddress,
      dateRangeRegexValidation,
      passportIssuerChanged,
      passportFileNumberVisible,
    }
  },
}
</script>

<style scoped>
.id-info-alert {
  @apply mb-6;
  color: rgba(229, 112, 73, 1);
}
.card-number {
  ::v-deep .tooltip-wrapper {
    @apply relative text-gray-dark mx-1;
    bottom: -6px;

    > div:not(.tooltip-tip) {
      @apply flex justify-center;
    }
  }
}

.file-number-input {
  ::v-deep ul {
    margin-left: 24px;
    li {
      @apply relative leading-6 mt-4 font-normal;
      font-size: 15px;
    }

    li::before {
      @apply absolute block rounded-full;
      content: '';
      width: 5px;
      height: 5px;
      top: 9px;
      left: -15px;
      background: white;
    }
  }
  ::v-deep .tooltip-wrapper {
    left: 6px;
    bottom: -6px;
    .app-icon {
      @apply text-tertiary-text !important;
    }
    .tooltip-tip {
      max-width: 270px;
      padding: 24px 16px 32px;
    }
  }
}
</style>
