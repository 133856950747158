import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'
import Location from '@galileo/models/Agent/location'
import { toApi } from '@galileo/models/Country/countryMapping'

export default new APIHandler(
  API,
  ({ agent, country, city, amount, currency }, config) => {
    const params = {
      City: city.cityName,
      State: city.state,
      CountryCode: toApi(country),
      Amount: amount,
      CurrencyCode: currency,
    }
    return {
      ...config,
      method: 'GET',
      url: `Destinations/correspondents/${agent.id}/locations`,
      params: params,
      agent,
    }
  },
  (response) => {
    const locations = response.data.map((location) => {
      return new Location({
        agentToId: response.config.agent.id,
        agentToName: response.config.agent.name,
        businessHours: location.businessHours,
        currency: location.currencyCode,
        latitude: location.latitude,
        locationAddress: location.address,
        locationAddress2: location.address2,
        locationCity: location.city,
        locationId: location.id,
        locationName: location.name,
        locationState: location.state,
        longitude: location.longitude,
        phone: location.phone,
      })
    })

    if (response.data) {
      return {
        ...response,
        data: locations,
      }
    } else {
      throw new Error('Failed to get locations')
    }
  }
)
