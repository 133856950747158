<template>
  <div>
    <AppModal
      v-model="showTermsAndConditionsModal"
      :title="$t('ComponentAddressChangeTermsAndConditionsModal.Title').value"
      @show="onShow"
    >
      <template #figureTitle>
        <AppCardImageTitle
          alt="Alarm clock"
          :title="$t('ComponentAddressChangeTermsAndConditionsModal.Title').value"
          :src="require(`@galileo/assets/images/warning.svg`)"
        />
      </template>

      <template #default>
        <p class="address-changed-text">
          {{ $t('ComponentAddressChangeTermsAndConditionsModal.TextAddressChanged').value }}
        </p>
        <div class="mini-card">
          <p
            v-html="
              $t('ComponentAddressChangeTermsAndConditionsModal.TextTermsAndConditions', {
                privacyNoticeURL: privacyNoticeURL,
                termsAndConditionsURL: termsAndConditionsURL,
              }).value
            "
          ></p>
          <AppInputCheckbox v-model="isAcceptedTermsAndConditionsCheckmark">
            <span
              v-html="
                $t('ComponentAddressChangeTermsAndConditionsModal.TextIAgree', {
                  termsAndConditionsURL: termsAndConditionsURL,
                }).value
              "
            ></span>
          </AppInputCheckbox>
        </div>
      </template>

      <template #footer>
        <AppModalFooter>
          <AppButton
            analytics-name="login-new-terms-agree"
            :loading="loading"
            :disabled="!isAcceptedTermsAndConditionsCheckmark"
            @click="acceptTermsAndConditions"
          >
            {{ $t('ComponentAddressChangeTermsAndConditionsModal.ButtonContinue').value }}
          </AppButton>
        </AppModalFooter>
      </template>
    </AppModal>

    <InformationAppModal
      :value="showWireTransferNoticeModal"
      icon="bank"
      class="wire-transfer-notice-modal"
    >
      <h1 class="title">{{ $t('WireTransferNoticeModal.Title').value }}</h1>
      <p class="wire-notice-text">{{ $t('WireTransferNoticeModal.FirstParagraph').value }}</p>
      <p class="wire-notice-text">{{ $t('WireTransferNoticeModal.SecondParagraph').value }}</p>

      <template #footer>
        <AppButton @click="showWireTransferNoticeModal = false">
          {{ $t('ComponentAddressChangeTermsAndConditionsModal.ButtonContinue').value }}
        </AppButton>
      </template>
    </InformationAppModal>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'

import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'

import {
  AppModal,
  AppModalFooter,
  AppButton,
  AppInputCheckbox,
  AppCardImageTitle,
} from '@oen.web.vue2/ui'


import { useResourcesStore,useI18nStore, useAuthStore } from '@galileo/stores'

export default {
  name: 'AddressChangeTermsAndConditionsModal',
  components: {
    AppModal,
    AppModalFooter,
    AppButton,
    AppInputCheckbox,
    AppCardImageTitle,
    InformationAppModal,
  },
  setup() {
    const { $t } = useI18nStore()
    const resourcesStore = useResourcesStore()
    const authStore = useAuthStore()

    const loading = ref(false)
    const isAcceptedTermsAndConditionsCheckmark = ref(false)
    const isTermsAndConditionsAcceptanceRequired = computed(() => {
      return authStore.isTermsAndConditionsAcceptanceRequired && authStore.showTACAfterAddressChange
    })
    const showTermsAndConditionsModal = ref(isTermsAndConditionsAcceptanceRequired)
    const showWireTransferNoticeModal = ref(false)
    const termsAndConditionsURL = computed(() => {
      return resourcesStore.getTermsAndConditionsUrl
    })
    const privacyNoticeURL = computed(() => {
      return resourcesStore.getPrivacyPolicyUrl
    })

    const acceptTermsAndConditions = async () => {
      loading.value = true
      await authStore.acceptNewTerms()
      loading.value = false
      showWireTransferNoticeModal.value = true
    }

    const onShow = () => {
      isAcceptedTermsAndConditionsCheckmark.value = false
    }

    return {
      $t,
      loading,
      showTermsAndConditionsModal,
      showWireTransferNoticeModal,
      isAcceptedTermsAndConditionsCheckmark,
      termsAndConditionsURL,
      privacyNoticeURL,
      acceptTermsAndConditions,
      onShow,
    }
  },
}
</script>

<style scoped>
::v-deep .card-content {
  .card-content-block {
    @apply mb-6 text-sm leading-6;
  }
}

.card-header {
  @apply px-12 pt-12;

  &.card-header--without-title {
    @apply pb-12 mb-0;
  }
}

::v-deep .card-image-title {
  .card-image-title-title {
    @apply text-2xl mb-0;
    line-height: 32px;
  }
}

::v-deep .card.card--has-footer {
  @screen sm {
    @apply pb-12;
  }
}

.card-footer {
  .button {
    @apply w-full;
  }
}

.mini-card {
  @apply p-6;
  @apply rounded-2xl;
  @apply shadow-ria-1;
  border: 0.5px solid #dddddd;

  p {
    @apply mb-4;
  }
}

.input-checkbox {
  @apply bg-blue-lighter px-1 py-3 rounded-lg leading-5;
}

::v-deep .input-checkbox-virtual-checkbox {
  @apply w-min-content h-auto rounded-none;
}

.button.button--primary {
  &:disabled {
    &:not(.button--loading) {
      @apply text-white;
    }

    background: linear-gradient(
        to right,
        theme('colors.blue.button-disabled'),
        theme('colors.blue.button-disabled')
      ),
      linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
  }
}

.address-changed-text {
  @apply mb-6 font-normal text-base leading-6;
}

.wire-transfer-notice-modal {
  .wire-notice-text {
    @apply text-left;
  }

  ::v-deep .card-header {
    @apply pt-12 pb-8;
  }

  p {
    @apply font-normal leading-6 mt-4;
  }
}
</style>
