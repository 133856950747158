










export default {
  name: 'XeBadge',
  props: {
    theme: {
      type: String,
      default: 'orange',
    },
    label: {
      type: String,
      default: 'Action',
    },
  },
}
