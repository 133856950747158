import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  (payment) => {
    let payload = {
      versionNumber: payment.versionNumber,
      countryCode: payment.country,
      currency: payment.currency,
      fields: [
        {
          name: 'AccountName',
          value: payment.accountName,
        },
        {
          name: 'BankName',
          value: payment.bankName,
        },
        {
          name: 'AccountNumber',
          value: payment.accountNumberUnmasked,
        },
        {
          name: 'AccountType',
          value: payment.accountType,
        },
        {
          name: 'Nickname',
          value: payment.nickname,
        },
        {
          name: 'RoutingNumber',
          value: payment.localRoutingCode,
        },
        {
          name: 'Id',
          value: payment.id,
        },
      ],
    }

    return {
      method: 'PUT',
      url: '/client-bank-accounts',
      data: payload,
    }
  },
  (response) => {
    if (response && response.data) {
      return response.data
    } else {
      throw new Error('Failed to update bank account')
    }
  }
  // (error) => {
  //   throw error.response.data.errors[0]
  // }
)
