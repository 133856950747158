import API from '@galileo/api/launchpad'
import APIHandler, { setHeader } from '@/api/LaunchpadAPIHandler'
import { GALILEO_PLATFORM } from '@galileo/constants/platformType'

export class LoginResult {
  constructor(data) {
    if (data?.authenticationResult?.cognitoError?.code === 'MfaResendThrottleException') {
      this.require2FA = true
      this.hasProfile = true // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    } else {
      if (data.authToken) {
        this.token = data.authToken.jwtToken
        this.tokenType = 'Bearer'
        this.refreshTokenExpiry = data.authToken.expiresInSeconds
        this.refreshToken = data.refreshToken
        this.idToken = data.idToken
      }
      this.require2FA = data.require2FA
      this.hasProfile = false
      if (data.customerProfile?.firstName) {
        this.hasProfile = true
      }
    }
  }
}

export default new APIHandler(
  API,
  (
    { username, password, device, session, preferredLanguage, captcha, verifyCode = null },
    config
  ) => {
    // Look for device token with same loginName/username
    const deviceToken = device?.tokens.find(({ loginName }) => loginName === username)

    return {
      ...config,
      method: 'POST',
      url: 'authorization/signin',
      data: {
        username,
        password,
        captcha,
        devicePlatform: GALILEO_PLATFORM,
        deviceToken: session?.sessionDeviceToken ?? deviceToken?.token,
        deviceId: device.id ?? session.sessionDeviceId,
        preferredLanguage: preferredLanguage,
        verifyCode: verifyCode,
        brand: session.brand,
      },
    }
  },
  async (response) => {
    return {
      ...response,
      data: new LoginResult(response.data),
    }
  }
)
