import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  ({ profileId }) => {
    return {
      method: 'GET',
      url: `profiles/${profileId}/marketingpreferences`,
    }
  },
  (response) => {
    return response
  }
)
