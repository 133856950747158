import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/LaunchpadAPIHandler'

export default new APIHandler(
  API,
  ({ encodedPayload, signature, timestamp, currency }, config) => {
    return {
      ...config,
      method: 'POST',
      url: 'OpenBanking/redirect/volt',
      data: { encodedPayload, signature, timestamp, currency },
    }
  },
  (response) => {
    if (response?.data) {
      return response.data.status
    } else {
      throw new Error('Failed to fetch Volt redirect status.')
    }
  }
)
