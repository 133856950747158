import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'
import Recipient from '@galileo/models/Recipient/app'
import { BankRecipientResult } from '@galileo/models/Recipient/SpecificRecipientResult/app.js'
export default new APIHandler(
  API,
  (profileId, config) => {
    return {
      ...config,
      method: 'GET',
      url: `recipients/${profileId}/getallrecipients`,
    }
  },
  (response) => {
    let result = {
      ...response,
      data: [],
    }

    let recipients = []
    response.data.recipients.forEach((profile) => {
      try {
        const result = new BankRecipientResult(profile)
        recipients.push(new Recipient(result))
      } catch (err) {
        console.error(
          'Recipient could not be retrieved, recipient Id: ' + profile.recipient.recipientId
        )
      }
    })

    let globalRecipients = response.data.globalRecipients.map((profile) => {
      let isGlobal = true
      const result = new BankRecipientResult(profile, isGlobal)
      return new Recipient(result)
    })

    result.data.push(...recipients)
    result.data.push(...globalRecipients)
    return result
  },
  (error) => {
    if (error?.response?.status === 404) {
      //no recipients
      return {
        ...error,
        data: [],
      }
    }
    throw error
  }
)
