import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  (apexxData, config) => {
    return {
      ...config,
      method: 'POST',
      url: 'cardsV2/card-transactions',
      data: apexxData,
    }
  },
  (response) => {
    let authResponse = null
    if (response.data?.data?.requirePayerAuthentication) {
      authResponse = {
        acsUrl: response.data.data.acsUrl,
        paReq: response.data.data.paReq,
        paTransactionId: response.data.data.authenticationTransactionId,
      }
    }

    return {
      data: {
        requirePayerAuthentication: response.data.data.requirePayerAuthentication,
        payerAuthenticateResponse: authResponse,
        payerAuthentication: response.data.data.payerAuthentication,
      },
    }
  }
)
