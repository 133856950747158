export class MessageBoxButton {
  constructor(text, click = null, theme = '') {
    this.text = text
    this.click = click
    this.theme = theme
  }
}

export class MessageBox {
  constructor(title, text = '', buttons = null) {
    this.title = title
    this.text = text
    this.buttons = buttons
    this.img = null
  }
}

export class MessageBoxOk extends MessageBox {
  constructor(title, text = '', extraParams = {}) {
    const buttons = [
      {
        text: 'Ok',
      },
    ]
    if (extraParams?.click) {
      buttons[0].click = extraParams.click
    }
    super(title, text, buttons)
  }
}

export class MessageBoxError extends MessageBoxOk {
  constructor(
    title = 'Oops! Something happened',
    text = 'An unexpected error occurred — please try again',
    extraParams = {}
  ) {
    super(title, text, extraParams)
    this.img = '@galileo/assets/images/illustrations/warning.svg'
  }
}
