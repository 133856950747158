












































































import { computed, defineComponent } from '@vue/composition-api'

import {
  AppModalFooter,
  AppButton,
  AppIcon,
  AppList,
  AppListItem,
  AppListItemTitle,
  AppFigure,
  AppListItemCaption,
} from '@oen.web.vue2/ui'
import XeBadge from '@galileo/components/XeBadge/XeBadge.vue'

import KYCRefreshCorporateCard from './KYCRefreshCorporateCard.vue'
import IconClose from '@oen.web.vue2/icons/src/components/IconClose.vue'
import IconEdit from '@oen.web.vue2/icons/src/components/carbon/IconEdit.vue'
import IconTrashCan from '@oen.web.vue2/icons/src/components/carbon/IconTrashCan.vue'
import IconUndo from '@oen.web.vue2/icons/src/components/carbon/IconUndo.vue'

import {
  useI18nStore,
  useKYCRefreshCorporateStore,
  useAnalyticsStore,
} from '@galileo/stores'
import { PersonnelItemEnhanced, UboStepData } from '@galileo/models/Corporate/interfaces/CorpReg'
import { KycRefreshCorporateStepEnum } from '@galileo/stores/kycRefreshCorporate'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'

export default defineComponent({
  name: 'KYCRefreshCorporateUBOList',
  components: {
    AppModalFooter,
    AppButton,
    AppFigure,
    AppIcon,
    IconClose,
    IconTrashCan,
    IconUndo,
    IconEdit,
    KYCRefreshCorporateCard,
    AppList,
    AppListItem,
    AppListItemTitle,
    AppListItemCaption,
    XeBadge,
  },
  setup(_, { emit }) {
    const { $t } = useI18nStore()
    const kycRefreshCorporateStore = useKYCRefreshCorporateStore()
    const analyticsStore = useAnalyticsStore()

    const uboList = computed<PersonnelItemEnhanced[]>(() => {
      // this component will display the dirty values
      return kycRefreshCorporateStore.uboStep.data!.map((ubo: UboStepData) => ubo.dirtyValues)
    })
    const getUboInitials = (ubo: PersonnelItemEnhanced): string => {
      let initials = ''

      if (ubo.firstName) {
        initials += ubo.firstName[0]
      }
      if (ubo.lastName) {
        initials += ubo.lastName[0]
      }
      return initials.toUpperCase()
    }
    const getUboRoles = (ubo: PersonnelItemEnhanced): string => {
      let roleArr = []
      if (ubo.isSignatory) {
        roleArr.push($t('ComponentKYCRefreshCorporateUBOList.AuthSignatory').value)
      }
      if (ubo.isDirector) {
        roleArr.push($t('ComponentKYCRefreshCorporateUBOList.Director').value)
      }
      if (ubo.isUbo) {
        roleArr.push($t('ComponentKYCRefreshCorporateUBOList.BusinessOwner').value)
      }

      if (roleArr.length === 0 && ubo.isOther && ubo.role) {
        return ubo.role
      } else if (ubo.isSignatory && ubo.isOther) {
        return $t('ComponentKYCRefreshCorporateUBOList.Has2Roles', {
            role1: roleArr[0],
            role2: ubo.role,
          }).value
      }

      switch (roleArr.length) {
        case 3:
          return $t('ComponentKYCRefreshCorporateUBOList.Has3Roles', {
            role1: roleArr[0],
            role2: roleArr[1],
            role3: roleArr[2],
          }).value
        case 2:
        return $t('ComponentKYCRefreshCorporateUBOList.Has2Roles', {
            role1: roleArr[0],
            role2: roleArr[1],
          }).value
        default:
          return roleArr[0]
      }
    }
    const getUboFullName = (ubo: PersonnelItemEnhanced) => {
      if (ubo.middleName) {
        return `${ubo.firstName} ${ubo.middleName} ${ubo.lastName}`
      }
      return `${ubo.firstName} ${ubo.lastName}`
    }
    const editUbo = (uboId: string) => {
      kycRefreshCorporateStore.selectUbo(uboId)
      // Trigger "KYC Refresh Individual Edit Started" event
      analyticsStore.track({ event: SEGMENT_EVENTS.KYC_REFRESH_INDIVIDUAL_EDIT_STARTED })
      kycRefreshCorporateStore.goToStep(KycRefreshCorporateStepEnum.UBO_FORM)
    }
    const toggleUboDeletion = (uboId: string) => {
      kycRefreshCorporateStore.toggleUboDeletion(uboId)
    }
    const addUbo = () => {
      kycRefreshCorporateStore.selectUbo(null)
      // Trigger "KYC Refresh Individual Add Started" event
      analyticsStore.track({ event: SEGMENT_EVENTS.KYC_REFRESH_INDIVIDUAL_ADD_STARTED })
      kycRefreshCorporateStore.goToStep(KycRefreshCorporateStepEnum.UBO_FORM)
    }

    return {
      $t,
      emit,
      uboList,
      getUboInitials,
      getUboRoles,
      getUboFullName,
      confirmUboList: kycRefreshCorporateStore.confirmUboList,
      KycRefreshCorporateStepEnum,
      goToStep: kycRefreshCorporateStore.goToStep,
      editUbo,
      toggleUboDeletion,
      addUbo
    }
  },
})
