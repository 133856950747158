/**
 * @name Get address in a proper way
 * @description shows the address formatted with addressLine1, addressLine2, addressLine3 if applicable
 *
 * @returns {string} return string with human readable address
 */
function getFormattedStreetName(address) {
  let result = address.addressLine1
  if (address.addressLine2) {
    result = `${result}, ${address.addressLine2}`
  }
  if (address.addressLine3) {
    result = `${result}, ${address.addressLine3}`
  }
  return result
}

/**
 * @name Get value as a double digit
 * @description If the value is less than 10 it appends a 0 in front.
 *
 * @returns {string} double digit string rappresenting number
 */
function getDoubleDigitNumbers(val) {
  if (val < 10) {
    return `0${val}`
  }
  return val
}

/**
 * @name Get Fromatted date for changing language
 * @description Get today date as YYYY-MM-DD
 *
 * @returns {string} formatted date as a string
 */
function getFormattedDateForLanguageChange() {
  /*
   * return the date in the following format yyyy-mm-dd
   */
  let date = new Date()
  let formattedDate = `${date.getFullYear()}-${getDoubleDigitNumbers(
    date.getMonth()
  )}-${getDoubleDigitNumbers(date.getDate())}`
  return formattedDate
}

export { getFormattedStreetName, getDoubleDigitNumbers, getFormattedDateForLanguageChange }
