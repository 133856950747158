import { defineStore } from 'pinia'
import getIndustryCategoriesApi from '@galileo/api/launchpad/resources/industrycategories/get'
import getIndustriesApi from '@galileo/api/launchpad/resources/industries/get'
import { IndustryCategory } from '@galileo/models/Industry/industryCategory'
import { IndustryType } from '@galileo/models/Industry/industryType'
import { useI18nStore } from './i18n'

export const useOccupationStore = defineStore(
  'occupation',
  () => {     
    const {localeLanguagePart} = useI18nStore()

    async function getIndustries(): Promise<Array<IndustryCategory>> {
      try
      {
        const { data } = await getIndustryCategoriesApi.exec()
        return data.data
      }
      catch
      {
        throw new Error(`Error while fetching industries`)
      }     
    }

    async function getSubIndustries(): Promise<Array<IndustryType>> {
      try
      {
        const { data } = await getIndustriesApi.exec({lang: localeLanguagePart})
        return data
      }
      catch
      {
        throw new Error(`Error while fetching sub industries`)
      }    
    }

    return {
      getIndustries,
      getSubIndustries
    }
  },
  {
    persist: true
  }
)
