import { defineStore } from 'pinia'
import { computed, ref, reactive } from '@vue/composition-api'
import router from '@galileo/router'
import { useAlert } from '@oen.web.vue2/ui'
import { debounce } from '@galileo/api/RMTAPIHandler'
import calculateAPI from '@galileo/api/launchpad/quotes/v2/post'
import getQuoteAPI from '@galileo/api/launchpad/quotes/v2/_quoteId/get'
import putQuoteAPI from '@galileo/api/launchpad/quotes/v2/_quoteId/put'
import postTransfer from '@galileo/api/launchpad/transfer/post'
import postTransferDocumentAPI from '@galileo/api/launchpad/transfer/_transferId/docs/_docId/post'
import getMobileWalletProvidersAPI from '@galileo/api/launchpad/destinations/mobilewallets/get'
import getRecipientAPI from '@galileo/api/launchpad/recipients/_profileId/recipient/_id/get'
import getAvailableCurrencies from '@galileo/api/launchpad/resources/currencies/get'
import getStates from '@galileo/api/launchpad/resources/countries/_countryIso/states/get'
import validatePaymentRefrenceNumber from '@galileo/api/launchpad/recipients/PRNvalidation/get'

import { getAllInfoByCountry } from '@galileo/composables/useIsoCountryCurrencyLibrary'
import useCardHelper from '@galileo/composables/useCardHelper'
import { maskFormatAbbreviated } from '@galileo/composables/useMaskHelpers'

import {
  getSegmentPaymentMethod,
  getSegmentPayoutMethod,
} from '@galileo/composables/useSegmentHelpers'

import { useFavorites } from '@galileo/composables/useFavorites'
import { useCurrency, formatCurrency, formatExchangeRate } from '@galileo/composables/useCurrency'
import DefaultCurrencies from '@galileo/models/DefaultCurrencies'
import useRecipientValidation from '@galileo/composables/useRecipientHelpers/useRecipientValidation'
import { CustomError } from '@galileo/utilities/CustomError'

import useQuoteErrors from '@galileo/composables/useQuoteErrorsFromandTo/useQuoteErrors'

import {
  useAuthStore,
  useEnvStore,
  useRecipientsStore,
  useResourcesStore,
  useI18nStore,
  useCountriesStore,
  useDeviceStore,
  useAnalyticsStore,
  useApiStore,
  usePaymentsStore,
  useCardAccountStore,
  useActivityStore,
  useAppStore,
  usePaymentReferenceStore,
  useQuickTransferStore,
  usePromotionStore,
  useBalancesStore,
} from '@galileo/stores'

import {
  DeliveryMethod,
  PaymentMethodEnums,
  DeliveryMethodEnums,
} from '@galileo/models/Transaction/app'

import {
  SEGMENT_EVENTS,
  SEGMENT_LOCATIONS,
  SEGMENT_PAYMENT_METHOD_TYPES,
  SEGMENT_PAYOUT_AGENT_TYPE,
} from '@galileo/constants/segmentAnalytics'

import {
  Transfer,
  MobileWalletWarning,
  DeliveryMethodType,
  Quote,
  DeliveryMethodAgent,
  CalculateResponse,
  MobileWallet,
  MobileWallets,
} from '@galileo/models/SendMoney/interfaces/index'

import { UnavailableReason } from '@galileo/models/SendMoney/interfaces/DeliveryMethodType'

import { Traits } from '@galileo/models/Analytics/interfaces'
import { Recipient } from '@galileo/models/Recipient/interfaces'
import { DefaultFormState, FormBase } from '@galileo/models/SendMoney/defaultFormState'
import { GenericField } from '@galileo/models/Resources/interfaces/SystemFields'
import { Step } from '@galileo/models/SendMoney/interfaces/Step'
import { KYC_STATUS } from '@galileo/models/Cst/appVariables'
import XeTransferField from '@galileo/models/DynamicField/XeTransferField'
import {
  TRANSFER_TYPE,
  QUOTE_SCREEN,
  TRANSFER,
  PAYMENT_METHODS,
  DIRECT_DEBIT,
  DELIVERY_METHODS,
} from '@galileo/constants/sendMoneyFlow.const'
import { sendMoneySteps, SMFSteps, O2OSteps } from '@galileo/constants/sendMoney/sendMoneySteps'
import { VERIFICATION_OUTCOMES, VerificationOutcomes } from '@galileo/constants/Verification.const'
import useOpenBanking from '@galileo/composables/useOpenBanking'

import { TransactionDetails } from '@galileo/models/SendMoney/interfaces/TransactionDetails'
import { TransactionListItem } from '@galileo/models/Activity/interfaces/Transactions'
import { REQUEST_CANCELATION_TOKEN_ENUMS } from '@galileo/constants/requestCancelationTokens'
import { DEFAULT_AMOUNT_FROM } from '@galileo/constants/quotes/quoteAmounts.const'

import { AdyenData } from '@galileo/models/CardAccount'
import CardAccount from '@galileo/models/Payment/CardAccount'
import { Route } from 'vue-router/types/router'

export const useSendMoneyStore = defineStore(
  'sendMoney',
  () => {
    const isAwaitingBalance = ref<boolean>(true)
    const activeStepIdx = ref<number>(0)
    const stepDirectionForward = ref<boolean>(true)
    const orderSteps = ref<any>()
    const steps = ref<Array<Step>>(sendMoneySteps as Array<Step>)
    const additionalFields = ref<[]>([])
    const recipientFields = ref<[]>([])
    const pepQuestions = ref<[]>([])
    const form = reactive<DefaultFormState>(new DefaultFormState())
    const hasStoredForm = ref<boolean>(false)
    const refreshQuote = ref<Nullable<number | NodeJS.Timeout>>(null)
    const shouldDisplayUsWireTransferWarning = ref<boolean>(false)
    const paymentReferenceType = ref(null)
    const showCPOWarning = ref<boolean>(false)
    const previousDeliveryMethod = ref(null)
    const CPOunavailableReason = ref<Nullable<UnavailableReason>>(null)
    const showDuplicateQuoteWarning = ref<boolean>(false)
    const duplicatedQuoteFound = ref<boolean>(false)
    const availableCurrencies = ref<[]>([])
    const isPaymentMethodError = ref<boolean>(false)
    const accountIsRestricted = ref<boolean>(false)
    const isO2OContract = ref<boolean>(false)
    const liquidityManager = ref<string>('')
    const isRecipientRequired = ref<boolean>(false)
    const shouldDisplayRateChangedAlert = ref<boolean>(false)
    const showDeliveryDetailsChangedWarning = ref<boolean>(false)
    const unavailableReasonCodes = ref<Record<string, any>[]>([])
    const shouldCheckForRateChange = ref<boolean>(true)
    const promiseLock = ref<Promise<any>>()
    const mobileWalletProviders = ref<MobileWallets>({} as MobileWallets)
    const lastRate = ref<number | null>()
    const mobileWalletProvider = ref<MobileWallet>({} as MobileWallet)
    const mobileWalletWarning = ref<MobileWalletWarning>({
      hasError: false,
      errorType: null,
    })
    const licenceCardNumberMissing = ref<boolean>(false)
    const shouldRefreshQuote = ref<boolean>(true)
    const unableToSetProvider = ref<boolean>(false)
    const bypassSendMoneyCreatingRedirection = ref<boolean>(false)
    const shouldShowRecipientNotFoundModal = ref<boolean>(false)
    const shouldDisplayPaymentMethodUnavailableModal = ref<boolean>(false)
    const transferBasicResponse = ref<any>() //TODO : CHECK TYPE
    const transferStage = ref('')
    const riaGeneralStopMessage = ref<boolean>(false)
    const isPaymentMethodTypeSelected = ref<boolean>(false)
    const quoteExpired = ref<boolean>(true)
    const showVerifyNotSuppliedModal = ref<boolean>(false)
    const transfer = ref<Transfer>({} as Transfer)
    const bankDetailsChangedIsVisible = ref<boolean>(false)
    const isSurveyOpen = ref<boolean>(false)

    const isVerificationPending = ref(false)
    const verificationPlatform = ref(null) // 'Veriff' | 'Onfido' | null
    const verificationApplicationId = ref(null)
    const verificationBaseUrl = ref(null)
    const verificationToken = ref(null)
    const veriffBaseUrl = ref<string>('https://magic.falcon-1-eu.veriff.me/v/')
    const isProofOfAddressPending = ref(false)

    const voltCheckoutComplete = ref<boolean>(false)
    const voltCheckoutStatus = ref<string>('')
    const hasVoltInfoBeenShown = ref<boolean>(false)
    const isAUvoltCompleted = ref<boolean>(false)
    const adyenData = ref<Nullable<AdyenData>>(null)
    const adyenCheckoutComplete = ref<boolean>(false)
    const i18nStore = useI18nStore()
    const authStore = useAuthStore()
    const cardAccountStore = useCardAccountStore()
    const activityStore = useActivityStore()
    const analyticsStore = useAnalyticsStore()
    const quickTransferStore = useQuickTransferStore()
    const balancesStore = useBalancesStore()
    const isVerifiedFromConfirmStage = ref<boolean>(false)
    const shouldNotCalculate = ref<boolean>(false)
    const isValidRecipient = ref<boolean>(true)
    const isSendAgainPaymentMethodAvailable = ref<boolean>(true)

    //quote refresh promise and
    const quoteRefreshPromise = ref<Nullable<Promise<CalculateResponse | null>>>(null)
    const quoteRefreshResolved = ref<boolean>(true)
    const limitExceededDialogOpened = ref<boolean>(false)

    // Mutations
    async function lockPromise(promise: Promise<CalculateResponse>): Promise<void> {
      const lock = Promise.resolve()
      if (promiseLock.value === undefined) {
        promiseLock.value = promise
      } else {
        try {
          await Promise.race([promiseLock.value, lock])
          promiseLock.value = promise
        } catch (ex) {
          promiseLock.value = undefined
        }
      }
    }

    function setShouldDisplayRateChangedAlert(value: boolean): void {
      if (shouldDisplayRateChangedAlert.value && !value) {
        shouldCheckForRateChange.value = false
      }
      shouldDisplayRateChangedAlert.value = value
    }

    function setPaymentMethodType(value: string): void {
      form.paymentMethodType = value
      isPaymentMethodTypeSelected.value = true
    }

    function setPaymentMethod(value: string): void {
      form.lastPaymentMethod = form.paymentMethod
      form.paymentMethod = value
    }

    function setCalculateResult(result: CalculateResponse) {
      form.transactionId = '' // to be on the safe side
      form.rate = result.rate
      form.quoteId = result.quoteId
      form.expiryTime = result.expiryTime
      form.isOpenPaymentCountry = result.isOpenPaymentCountry
      form.fee = result.fee
      form.delivery = result.delivery
      form.totalAmount = result.totalAmount
      form.countryTo = result.countryTo
      form.currencyTo = result.currencyTo
      form.fixedAmountInUsd = result.fixedAmountInUsd

      if (result.currencyFrom) {
        form.currencyFrom = result.currencyFrom
      }

      if (result.currenciesTo) {
        form.currenciesTo = result.currenciesTo
      }

      if (result.deliveryMethods) {
        form.tempDeliveryMethods = result.deliveryMethods.map(function (
          method: DeliveryMethodType
        ) {
          const TypedDeliveryMethodEnums: Record<string, any> = DeliveryMethodEnums
          if (TypedDeliveryMethodEnums[method.value]) {
            method.text = i18nStore.$t(TypedDeliveryMethodEnums[method.value].textKey).value
          }
          return method
        })
      }

      if (result.availableQuotes) {
        form.availableQuotes = result.availableQuotes
      }
    }

    function setShowDuplicateQuoteWarning(value: boolean): void {
      showDuplicateQuoteWarning.value = value
      duplicatedQuoteFound.value = false
    }

    function setVerificationStepVisibility(availability: boolean): void {
      steps.value.forEach(function (step) {
        if (step.name === 'Verification') {
          step.available = availability
        }
      })
    }

    function clearRefreshQuote(): void {
      if (refreshQuote.value) {
        clearInterval(refreshQuote.value)
      }
      refreshQuote.value = null
    }

    function restoreDefaultForm() {
      // This will abort any current new quote or refresh quote requests
      useApiStore().abortApiRequest(REQUEST_CANCELATION_TOKEN_ENUMS.CALCULATE_NEW_QUOTE)
      useApiStore().abortApiRequest(REQUEST_CANCELATION_TOKEN_ENUMS.REFRESH_QUOTE)
      quickTransferStore.isCurrentTransactionQuickTransfer = false
      const paymentReferenceStore = usePaymentReferenceStore()
      isO2OContract.value = false
      steps.value = SMFSteps
      resetForm()
      setShouldDisplayRateChangedAlert(false)
      lastRate.value = null
      isAwaitingBalance.value = true
      shouldRefreshQuote.value = true
      transferStage.value = ''
      bankDetailsChangedIsVisible.value = false
      isSurveyOpen.value = true
      paymentReferenceStore.resetPaymentReferenceForm()
      quoteExpired.value = false
      resetVoltProps()
      resetAdyenProps()
      usePromotionStore().resetState()
      isVerifiedFromConfirmStage.value = false
    }

    function resetForm(): void {
      const countriesStore = useCountriesStore()
      const defaultCurrency = countriesStore.getCountryDefaultCurrency

      accountIsRestricted.value = false
      activeStepIdx.value = 0
      stepDirectionForward.value = true
      additionalFields.value = []
      recipientFields.value = []
      pepQuestions.value = []
      previousDeliveryMethod.value = null

      if (refreshQuote.value) {
        clearInterval(refreshQuote.value)
      }
      refreshQuote.value = null

      Object.assign(form, new DefaultFormState())

      form.currenciesFrom = [{ value: defaultCurrency, text: defaultCurrency }]
      form.currencyFrom = defaultCurrency
      paymentReferenceType.value = null
      isO2OContract.value = false
      isRecipientRequired.value = false
      shouldCheckForRateChange.value = true
      shouldDisplayRateChangedAlert.value = false
      transferBasicResponse.value = null
      adyenData.value = null
      isPaymentMethodError.value = false
    }

    function resetFormOnError(): void {
      previousDeliveryMethod.value = null
      Object.assign(form, {
        ...form,
        paymentMethods: [],
        deliveryMethods: [],
        tempDeliveryMethods: [],
        amountTo: null,
        currencyTo: null,
        totalAmount: null,
        fee: null,
      })
      paymentReferenceType.value = null
    }

    function resetVoltProps(): void {
      voltCheckoutComplete.value = false
      voltCheckoutStatus.value = ''
      hasVoltInfoBeenShown.value = false
      isAUvoltCompleted.value = false
    }

    function resetAdyenProps(): void {
      adyenCheckoutComplete.value = false
    }

    function setMobileWalletWarning(warning: string | null): void {
      if (warning !== null) {
        mobileWalletWarning.value = {
          hasError: true,
          errorType: warning,
        }
        try {
          const myError = warning.split('|')
          mobileWalletWarning.value = {
            ...mobileWalletWarning.value,
            errorCode: +myError[1],
            errorText: myError[0],
            errorDescription: myError[2],
          }
        } catch (ex) {
          console.log('Exception parsing validation error ', ex)
        }
      } else {
        mobileWalletWarning.value = {
          hasError: false,
          errorType: null,
        }
      }
    }

    function setFailedTransferData({
      transferError,
      stage,
    }: {
      transferError: Record<string, any> | null
      stage: any
    }): void {
      transfer.value.error = transferError
      transfer.value.stage = stage
      transfer.value.cardDeclined = false

      if (transfer.value.error) {
        if (transfer.value.error?.reasonDesc?.includes('Card declined')) {
          form.cardSecurityCode = null
          transfer.value.cardDeclined = true
        }
      }
    }

    function updatePaymentAndDeliveryMethods(): void {
      form.lastUpdate = new Date()
      if (form.availableQuotes) {
        const methods: any = {}
        let firstQuote: Quote = null as any
        let firstDeliveryQuote: Quote = null as any
        let firstPaymentQuote: Quote = null as any
        let similarQuote: Quote = null as any
        let selectedQuote: Quote = null as any

        const { setQuoteErrorText, setFundOnBalanceDeliveryErrorText } = useQuoteErrors()

        if (form.tempDeliveryMethods) {
          form.tempDeliveryMethods.forEach((method: DeliveryMethodType) => {
            method.isAvailable = false // check if have a quote before switching it to isAvailable
            methods[method.value] = method
          })

          const cashPickupDeliveryMethod = form.tempDeliveryMethods.find(
            (method: any) => method.value === DELIVERY_METHODS.CASH_PAYOUT
          )

          if (cashPickupDeliveryMethod?.unavailableReason) {
            CPOunavailableReason.value = cashPickupDeliveryMethod.unavailableReason
          }
        }
        form.paymentMethods = []

        const country = authStore?.userProfile?.country

        form.availableQuotes.forEach((quote: Quote) => {
          const { currency } = useCurrency()
          // We can get the amounts with wrong fractions from the backend i.e. sending to Albania with ALL we get 11000.58 instead of 11001
          // Then this will clash with out useCurrencyInput since it works with the correct fraction will alter the amount correctly and then
          // cause to trigger in SendMoneyAmount.vue to update the amountTo with the adjusted amount and cause a switch to shouldCalcAmountFrom
          // with a following calculate which shouldn't be done.
          if (quote.amountFrom) {
            const amountFormatted = currency(form.currencyFrom, quote.amountFrom, 'en-US')
            quote.amountFrom = parseFloat(
              amountFormatted.formattedValue.replace(new RegExp(amountFormatted.thousands, 'g'), '')
            )
          }
          if (quote.amountTo) {
            const amountFormatted = currency(form.currencyTo, quote.amountTo, 'en-US')
            quote.amountTo = parseFloat(
              amountFormatted.formattedValue.replace(new RegExp(amountFormatted.thousands, 'g'), '')
            )
          }

          if (quote.unavailableReason) {
            const { quoteErrorText, isQuoteErrorTo } = setQuoteErrorText(quote)
            if (quoteErrorText) {
              quote.errorText = quoteErrorText
              if (isQuoteErrorTo) {
              } else {
                quote.errorFrom = quoteErrorText
              }
            }
          }

          const deliveryMethod = methods[quote.deliveryMethod]

          let paymentMethod = methods[quote.paymentMethod]

          if (
            quote.paymentMethod === PAYMENT_METHODS.FUNDS_ON_BALANCE &&
            !paymentMethod?.isPaymentMethod
          ) {
            paymentMethod = null
          }

          if (!paymentMethod) {
            let text = quote.paymentMethod

            const TypedPaymentMethodEnums: Record<string, any> = PaymentMethodEnums
            let paymentMethodExist = TypedPaymentMethodEnums[quote.paymentMethod]

            if (paymentMethodExist) {
              let { textKey } = paymentMethodExist

              if (quote.paymentMethod === PAYMENT_METHODS.DIRECT_DEBIT && country === 'US') {
                text = i18nStore.$t('ComponentXeTransferDetails.PaymentMethodBankAccountACH').value
              } else if (quote.paymentMethod === PAYMENT_METHODS.DIRECT_DEBIT && country === 'CA') {
                text = i18nStore.$t('ComponentXeTransferDetails.PaymentMethodBankAccountEFT').value
              } else if (
                quote.paymentMethod === PAYMENT_METHODS.BANK_TRANSFER &&
                country === 'US'
              ) {
                text = i18nStore.$t('ComponentXeTransferDetails.PaymentMethodBankTransferUS').value
              } else if (quote.paymentMethod === PAYMENT_METHODS.OPEN_BANKING) {
                text = i18nStore.$t('SendMoneySummary.VoltPaymentTextSummary').value
              } else if (quote.paymentMethod === PAYMENT_METHODS.FUNDS_ON_BALANCE) {
                text = i18nStore.$t('ComponentXeTransferDetails.Balance').value
              } else {
                text = i18nStore.$t(textKey).value
              }
            }

            paymentMethod = {
              value: quote.paymentMethod,
              text,
              isAvailable: false,
              isAvailableForDeliveryMethod: false,
              unavailableReason: quote.unavailableReason,
              quote: quote,
              isPaymentMethod: true,
            }

            form.paymentMethods?.push(paymentMethod) //TODO: CHANGE PAYMENTMETHODS TO NOT NULL MOST PROB
            methods[quote.paymentMethod] = paymentMethod
          }

          if (!similarQuote) {
            similarQuote = quote
          } else if (
            quote.deliveryMethod === form.deliveryMethod &&
            quote.paymentMethod === form.paymentMethod
          ) {
            similarQuote = quote
          }

          if (quote.isEnabled && !quote.unavailableReason) {
            if (
              quote.deliveryMethod === form.deliveryMethod &&
              quote.paymentMethod === form.paymentMethod
            ) {
              selectedQuote = quote
            } else if (!firstDeliveryQuote && quote.deliveryMethod === form.deliveryMethod) {
              firstDeliveryQuote = quote
            } else if (!firstPaymentQuote && quote.paymentMethod === form.paymentMethod) {
              firstPaymentQuote = quote
            }
            if (!firstQuote) {
              firstQuote = quote
            }

            if (
              quote.deliveryMethod === form.deliveryMethod ||
              (!form.deliveryMethod && quote.deliveryMethod === firstQuote.deliveryMethod)
            ) {
              paymentMethod.isAvailable = true
              paymentMethod.quote = quote
            }

            if (
              quote.paymentMethod === form.paymentMethod ||
              (!form.paymentMethod && quote.paymentMethod === firstQuote.paymentMethod)
            ) {
              deliveryMethod.isAvailable = true
              deliveryMethod.quote = quote
            }
          }

          if (
            paymentMethod.unavailableReason ||
            quote.deliveryMethod === form.deliveryMethod ||
            (firstQuote &&
              !form.deliveryMethod &&
              quote.deliveryMethod === firstQuote.deliveryMethod)
          ) {
            paymentMethod.isAvailableForDeliveryMethod = true
          }
        })

        if (!selectedQuote) {
          selectedQuote = firstDeliveryQuote
        }
        if (!selectedQuote) {
          selectedQuote = firstPaymentQuote
        }
        if (!selectedQuote) {
          selectedQuote = firstQuote
        }

        form.selectedQuote = selectedQuote
        if (selectedQuote) {
          form.amountFrom = selectedQuote.amountFrom
          form.amountTo = selectedQuote.amountTo
          form.rate = selectedQuote.rate
          form.fee = selectedQuote.fee
          form.totalAmount = selectedQuote.totalAmount

          const isPaymentMethodChanged = form.paymentMethod !== selectedQuote.paymentMethod
          const isMissingPaymentMethodId =
            !form.paymentMethodId &&
            form.paymentMethod !== PAYMENT_METHODS.FUNDS_ON_BALANCE &&
            form.paymentMethod !== PAYMENT_METHODS.OPEN_BANKING

          if (
            form.sendAgain &&
            form.paymentMethod &&
            (isPaymentMethodChanged || isMissingPaymentMethodId)
          ) {
            // if we have missing recipient info and unavailable payment method, we want to prioritize missing info.
            // We don't want to throw payment method error at this point, insted we we'll take care of unavailable payment method in SMF
            if (!isValidRecipient.value) {
              isSendAgainPaymentMethodAvailable.value = false
            } else if (
              !shouldDisplayPaymentMethodUnavailableModal.value &&
              !shouldShowRecipientNotFoundModal.value
            ) {
              const error = Error('Payment method unavailable')
              Object.assign(error, { errorCode: 'PaymentMethodUnavailable' })
              throw error
            }
          }
          const paymentsStore = usePaymentsStore()
          const selectedBankAccount = paymentsStore.paymentMethods.bankAccounts?.find(
            (method: any) => method.id === form.paymentMethodId
          )

          const { add } = useAlert()

          const isFundingBalance = form.deliveryMethod === DELIVERY_METHODS.FUNDS_ON_BALANCE

          const isOneStepTransaction =
            form.deliveryMethod === DELIVERY_METHODS.FUNDS_ON_BALANCE ||
            form.paymentMethod === PAYMENT_METHODS.FUNDS_ON_BALANCE ||
            quickTransferStore.isCurrentTransactionQuickTransfer

          const isCurrentPaymentMethodAvailable = form.availableQuotes.find(
            (method) => method.paymentMethod === form.paymentMethod
          )

          if (
            form.paymentMethod &&
            form.paymentMethod !== selectedQuote.paymentMethod &&
            (!isOneStepTransaction || !isCurrentPaymentMethodAvailable)
          ) {
            // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! check
            //check if we have minimun amount quote warning
            let isBelowMinimumAmount =
              form.warnings?.find((warning: any) => warning.type === 'QUOTE034') != null //TODO: CHECK WARNINGS SAME FOR NULLABLE

            if (isBelowMinimumAmount && country === 'US' && form.currencyFrom === 'USD') {
              shouldDisplayUsWireTransferWarning.value = true
            }

            const directDebitNotAvailableForTheAmount: any = form.paymentMethods.find(
              (method: any) =>
                method.unavailableReason?.code === 'FixedAmountAboveMaxAmount' ||
                method.unavailableReason?.code === 'MaxDirectDebitAmountForPeriodExceeded' ||
                method.unavailableReason?.code === 'FixedAmountBelowMinAmount'
            )

            let messageToDisplay = null
            switch (selectedQuote.paymentMethod) {
              case 'DirectDebit':
                messageToDisplay = `${i18nStore.$t('PaymentMethodChanged.DirectDebit').value} 🏦`
                break
              case 'CreditCard':
                messageToDisplay = `${i18nStore.$t('PaymentMethodChanged.CreditCard').value} 💳`
                break
              case 'DebitCard':
                messageToDisplay = `${i18nStore.$t('PaymentMethodChanged.DebitCard').value} 💳`
                break
              case 'BankTransfer':
                messageToDisplay = directDebitNotAvailableForTheAmount
                  ? `${i18nStore.$t('PaymentMethodChanged.BankTransfer').value} 🏦` // text to be displayed if Direct Debit is not available for the amount
                  : i18nStore.$t('PaymentMethodChanged.QuickTransferBankAccount', {
                    currency: form.currencyFrom,
                    paymentMethod: country === 'US' ? DIRECT_DEBIT.ACH : DIRECT_DEBIT.EFT, // text to display when selected currency doesn’t allow the use of Direct Debit.
                  }).value
                break
            }
            if (messageToDisplay) {
              add(messageToDisplay)
            }
          } else if (
            form.paymentMethod === PAYMENT_METHODS.DIRECT_DEBIT &&
            selectedBankAccount &&
            selectedBankAccount.currency !== form.currencyFrom
          ) {
            add(i18nStore.$t('BankSelectionDropdown.SelectNewAccountToast').value) // text to display when Direct Debit is available, but selected currency and selected bank account currency are not the same
          }
          const shouldChangeFundBalancePaymentMethod =
            form.transferType === TRANSFER_TYPE.FUND_BALANCE &&
            form.paymentMethod === PAYMENT_METHODS.FUNDS_ON_BALANCE
          if (
            !isOneStepTransaction ||
            !isCurrentPaymentMethodAvailable ||
            shouldChangeFundBalancePaymentMethod
          ) {
            form.paymentMethod = selectedQuote.paymentMethod
          }

          let shouldChangeDeliveryMethod = true

          if (
            form.deliveryMethod &&
            form.deliveryMethod !== selectedQuote.deliveryMethod &&
            !isFundingBalance
          ) {
            const isMobileWalletUnavailableForCurrencyPair = unavailableReasonCodes.value.find(
              (reason) => reason.code === 'QUOTE066'
            )
            if (
              form.deliveryMethod === 'MobileWallet' &&
              isMobileWalletUnavailableForCurrencyPair
            ) {
              showDeliveryDetailsChangedWarning.value = true
            }

            let isMobileWalletAmountFromExceeded = unavailableReasonCodes.value.find(
              (reason) => reason.code === 'QUOTE068'
            )

            if (form.deliveryMethod === 'MobileWallet' && isMobileWalletAmountFromExceeded) {
              const currency = isMobileWalletAmountFromExceeded.parameters.find((item: any) => {
                if (item.key === 'ccyCode') {
                  return item
                }
              })

              const amount = isMobileWalletAmountFromExceeded.parameters.find((item: any) => {
                if (item.key === 'maxAmount') {
                  return item
                }
              })
              let maxAmountTo = `${amount.value} ${currency.value}`
              shouldChangeDeliveryMethod = false
              form.quoteErrorFrom = i18nStore.$t('MobileWalletAmountExceeded.QUOTE068', {
                maxAmountTo,
              }).value
            }
            let isMobileWalletAmountToExceeded = unavailableReasonCodes.value.find(
              (reason) => reason.code === 'QUOTE067'
            )

            if (form.deliveryMethod === 'MobileWallet' && isMobileWalletAmountToExceeded) {
              const currency = isMobileWalletAmountToExceeded.parameters.find((item: any) => {
                if (item.key === 'ccyCode') {
                  return item
                }
              })
              const maxAmount = isMobileWalletAmountToExceeded.parameters.find((item: any) => {
                if (item.key === 'maxAmount') {
                  return item
                }
              })
              const minAmount = isMobileWalletAmountToExceeded.parameters.find((item: any) => {
                if (item.key === 'minAmount') {
                  return item
                }
              })

              let maxAmountTo = maxAmount.value + ' ' + currency.value
              let minAmountTo = minAmount.value + ' ' + currency.value

              shouldChangeDeliveryMethod = false

              if (form.amountTo < parseInt(minAmount.value)) {
                form.quoteErrorTo = i18nStore.$t('MobileWalletAmountUnder.QUOTE067', {
                  minAmountTo,
                }).value
              } else if (form.amountTo > parseInt(maxAmount.value)) {
                form.quoteErrorTo = i18nStore.$t('MobileWalletAmountExceeded.QUOTE067', {
                  maxAmountTo,
                }).value
              }
            }
            // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            if (form.currencyFrom === 'USD' && form.deliveryMethod === 'CashPayout') {
              showCPOWarning.value = true
            }

            const { add } = useAlert()
            let messageToDisplay = null
            switch (selectedQuote.deliveryMethod) {
              case 'CashPickup':
                messageToDisplay = i18nStore.$t('DeliveryMethodChanged.CashPickup').value //`Delivery method changed to cash pickup`
                break
              case 'BankAccount':
                messageToDisplay = i18nStore.$t('DeliveryMethodChanged.BankAccount').value //`Delivery method changed to bank account`
                break
            }
            if (shouldChangeDeliveryMethod) {
              add(messageToDisplay)
            }
          }
          if (shouldChangeDeliveryMethod && !isFundingBalance) {
            form.deliveryMethod = selectedQuote.deliveryMethod
          }
          if (isFundingBalance) {
            form.quoteErrorTo = setFundOnBalanceDeliveryErrorText()
          }
          if (previousDeliveryMethod.value) {
            const prevDeliveryMethod: DeliveryMethodType | undefined =
              form.tempDeliveryMethods?.find(
                (method: any) => method.value === previousDeliveryMethod.value
              )
            if (prevDeliveryMethod?.isAvailable) {
              form.deliveryMethod = previousDeliveryMethod.value
              previousDeliveryMethod.value = null
            }
          }
          similarQuote = selectedQuote
        } else {
          // to display calculated amounts in the case when there is no selected quote
          form.amountFrom = form.availableQuotes[0].amountFrom
          form.amountTo = form.availableQuotes[0].amountTo
          const errorText = i18nStore.$t('QuoteErrors.GenericError').value

          if (form.shouldCalcAmountFrom) {
            form.quoteErrorTo = errorText
            if (form.deliveryMethod === DELIVERY_METHODS.FUNDS_ON_BALANCE) {
              form.quoteErrorTo = setFundOnBalanceDeliveryErrorText()
            }
          } else {
            form.quoteErrorFrom = errorText
          }
        }
        if (similarQuote) {
          if (similarQuote.errorFrom) {
            form.quoteErrorFrom = similarQuote.errorFrom
          }
          if (similarQuote.errorTo) {
            form.quoteErrorTo = similarQuote.errorTo
          }
        }
        let stepsVisibility: any = {}

        if (form.deliveryMethod === DeliveryMethod.CashPickup.enumName) {
          stepsVisibility.Delivery = { available: true }
        } else {
          stepsVisibility.Delivery = { available: false }
        }
        stepsVisibility.Payment = { available: true }

        let verificationStep = steps.value.find(
          (step) => step.path === '/send-money/summary/verification'
        )

        if (!verificationStep)
          verificationStep = {
            name: '',
            title: '',
            path: '',
            pageTitle: '',
            titleKey: '',
            pageTitleKey: '',
            available: false,
          }

        stepsVisibility.Verification = { available: verificationStep.available }

        steps.value.forEach((step) => {
          if (step.name && stepsVisibility[step.name]) {
            step.available = stepsVisibility[step.name].available
          }
        })
      }

      form.deliveryMethods = form.tempDeliveryMethods
    }

    //GETTERS

    const activeStep = computed(() => {
      const activeStep = activeStepIdx.value
      return getAvailableSteps.value[activeStep]
    })

    function getStepByPath(path: string): Step | null {
      let closestMatch = null
      let steps = getAvailableSteps.value

      for (let step of steps) {
        if (step.path === path) {
          return step
        }

        if (
          path.indexOf(step.path) === 0 &&
          (!closestMatch || step.path.length > closestMatch.path.length)
        ) {
          // i.e. step has path /send-money/payment and current path is /send-money/payment/card
          closestMatch = step
        }
      }
      return closestMatch
    }

    function getStepIdx(step: any): number {
      //CHECK
      return getAvailableSteps.value.indexOf(step)
    }

    const getAvailableSteps = computed(() => {
      let stepsAvailable = steps.value.filter((step) => step.available)

      const i18nStore = useI18nStore()

      stepsAvailable.forEach((step) => {
        step.title = i18nStore.$t(step.titleKey).value
        step.pageTitle = i18nStore.$t(step.pageTitleKey).value
      })
      return stepsAvailable
    })

    const getNextAvailableStep = computed(() => {
      let activeStepIdxValue = activeStepIdx.value
      let availableSteps = getAvailableSteps.value
      // Next step in order is default next available step
      activeStepIdxValue = Math.min(activeStepIdxValue + 1, availableSteps.length)

      for (let index = activeStepIdxValue; index < availableSteps.length; index++) {
        let step = availableSteps[index]
        if (step.available) {
          return step
        }
      }
      return null
    })

    const getCountryTo = computed(() => {
      if (form.countryTo) {
        return form.countryTo
      }
      return ''
    })

    const getCountryToFromOfferedCountries = computed(() => {
      let country = getCountryTo.value
      const countriesStore = useCountriesStore()
      if (country) {
        return countriesStore.getOfferedCountryByIsoCode(country)
      }
      return void 0
    })

    const getCurrencyFrom = computed(() => {
      const countriesStore = useCountriesStore()
      const defaultCurrencyFrom = countriesStore.getCountryDefaultCurrency

      if (form.currencyFrom) {
        return form.currencyFrom
      }
      return defaultCurrencyFrom
    })

    const getCurrencyTo = computed(() => {
      if (form.currencyTo) {
        return form.currencyTo
      }
      return null
    })

    const getQuoteId = computed(() => {
      if (form.quoteId) {
        return form.quoteId.toString()
      }
      return ''
    })

    function getRate(formatted: boolean): any | null {
      const value = form.rate
      if (formatted && value) {
        return formatExchangeRate(value)
      }
      return value
    }

    function getLastRate(formatted: boolean): any | null | undefined {
      let value = lastRate.value
      if (value === null) {
        value = form.rate
      }
      if (formatted && value) {
        return formatExchangeRate(value)
      }
      return value
    }

    function getRateFrom(formatted: boolean) {
      const value = 1
      if (formatted) {
        return formatCurrency(form.currencyFrom, value, useDeviceStore().locale)
      }
      return value
    }

    const getAvailableCurrenciesFrom = computed(() => {
      const resourcesStore = useResourcesStore()
      if (availableCurrencies.value.length) {
        const ccy = availableCurrencies.value.filter(
          (currency: any) => currency.enableFrom === true
        )
        return ccy
      }
      return resourcesStore.systemFields.currenciesFrom || []
    })

    const getAvailableCurrenciesTo = computed(() => {
      const resourcesStore = useResourcesStore()
      if (availableCurrencies.value.length) {
        const ccy = availableCurrencies.value.filter((currency: any) => currency.enableTo === true)
        return ccy
      }
      return resourcesStore.systemFields.currenciesTo || []
    })

    const getCombinedAvailableCurrenciesFrom = computed(() => {
      const resourcesStore = useResourcesStore()
      if (availableCurrencies.value.length && resourcesStore.systemFields) {
        const items = getAvailableCurrenciesFrom.value
        const favorites = resourcesStore.getTopCurrencies
        const currencies = useFavorites(items, favorites)
        return currencies
      }
      return []
    })

    const getCombinedAvailableCurrenciesTo = computed(() => {
      const resourcesStore = useResourcesStore()
      if (availableCurrencies.value.length && resourcesStore.systemFields) {
        const items = getAvailableCurrenciesTo.value
        const favorites = resourcesStore.getTopCurrencies
        const currencies = useFavorites(items, favorites)
        return currencies
      }
      return []
    })

    function getFirstFavoriteCurrency(selectedCurrency: string): GenericField {
      const resourcesStore = useResourcesStore()
      const favoriteCurrencies = resourcesStore.getTopCurrencies
      let result = favoriteCurrencies.filter((currency: any) => currency.value !== selectedCurrency)
      return result[0]
    }

    function getSplitCurrencies(formatted: boolean): any[] | null {
      //CHECK
      const value = form.splitCurrencies
      if (formatted && value) {
        return Object.keys(value)
          .map((key: any) => {
            const { amountTo, rate } = value[key]
            return {
              currency: key,
              amountTo: formatCurrency(key, amountTo, useDeviceStore().locale),
              rate: formatCurrency(key, rate, useDeviceStore().locale),
            }
          })
          .reduce((acc: any, { currency, amountTo, rate }) => {
            if (!acc[currency]) {
              return {
                ...acc,
                [currency]: {
                  amountTo,
                  rate,
                },
              }
            }
            return acc
          }, {})
      }
      return value
    }

    const isSubIndustrySelected = computed(() => {
      return form.filledAdditionalFields?.corpSubIndustry
    })

    function setConfirmedRate(): void {
      form.confirmedRate = form.rate
    }

    function resetConfirmedRate(): void {
      form.confirmedRate = null
    }

    function resetTransferInformation(): void {
      setFailedTransferData({ transferError: transfer.value.error, stage: transfer.value.stage })
    }

    async function validatePaymentReferenceNumber({
      profileId,
      recipientId,
      paymentRefrenceNumber,
    }: {
      profileId: string
      recipientId: string
      paymentRefrenceNumber: number
    }): Promise<any> {
      const appStore = useAppStore()
      try {
        const response = await validatePaymentRefrenceNumber.exec({
          profileId: profileId,
          recipientId: recipientId,
          paymentRefrenceNumber: paymentRefrenceNumber,
        })

        return response.status === 200 && response.data.message === 'Valid Data'
      } catch (ex) {
        appStore.logException('Invalid payment reference number', ex)
      }
    }

    function setShowCPOWarning(value: boolean) {
      showCPOWarning.value = value
    }

    function setFilledAdditionalField({ field, value }: { field: any; value: any }) {
      //TODO - CHECK TYPES
      const filledAdditionalFields = { ...form.filledAdditionalFields }
      filledAdditionalFields[field] = value
      setFilledAdditionalFields(filledAdditionalFields)
    }

    function setFilledAdditionalFields(filledAdditionalFields: any) {
      form.filledAdditionalFields = filledAdditionalFields
      if (filledAdditionalFields) form.requireAdditionalVerification = true
    }

    async function setAvailableCurrencies(countryTo: string) {
      const userCountry = authStore?.userProfile?.country
      const { data } = await getAvailableCurrencies.exec({
        countryFrom: userCountry,
        countryTo: countryTo,
      })
      const availableCurrenciesList = data.filter((currency: any) => currency.value !== 'HRK')
      availableCurrencies.value = availableCurrenciesList
      return availableCurrenciesList
    }

    async function setMobileWalletProviders({
      country,
      currency,
      amount,
    }: {
      country: string
      currency: string
      amount: number
    }): Promise<any> {
      const request = {
        country: country,
        currency: currency,
        amount: amount,
      }
      // check if we already have the wallet providers for the exact same request and if available use them
      const walletProviders = mobileWalletProviders.value

      if (walletProviders && JSON.stringify(walletProviders.request) === JSON.stringify(request)) {
        return walletProviders
      }

      const { data } = await getMobileWalletProvidersAPI.exec(request)

      if (data) {
        mobileWalletProviders.value.request = request // cache the request so we can check if we do the same request later
        mobileWalletProviders.value.providers = data
        return data
      } else {
        throw new Error('Failed to get mobile wallet providers')
      }
    }

    function setMobileWalletProvider(provider: any) {
      if (provider) {
        form.riaCorrespondentId = provider.partnerId
        form.riaCorrespondentLocId = provider.id
        mobileWalletProvider.value = provider
      }
    }

    async function calculatorGetRecipients() {
      await useRecipientsStore().getRecipientsByCountryAndCurrency({
        country: form.countryTo,
        currency: form.currencyTo,
        deliveryMethod: form.deliveryMethod, //TODO : CHECK THIS
      })
    }

    //TODO: Lack of consistency, the same method should be used in SendMoneySummary and RiaTransferDetails components
    function updateDeliveryMethodText() {
      const i18nStore = useI18nStore()
      const { i18n } = i18nStore
      switch (form.deliveryMethod) {
        case DeliveryMethod.BankDeposit.enumName: {
          const prefix = i18n.$t('ComponentXeTransferDetails.DeliveryMethodBankDeposit').value
          const bankAccountNumber = form.recipient?.bankAccountNumber
            ? maskFormatAbbreviated(form.recipient.bankAccountNumber)
            : maskFormatAbbreviated(form.recipient?.bankAccountNumberUnitary)
          form.deliveryMethodText = `${prefix} ${bankAccountNumber}`
          break
        }
        case DeliveryMethod.CashPickup.enumName: {
          const isOpenPayment = form.isOpenPaymentCountry

          if (isOpenPayment) {
            form.deliveryMethodText = i18n.$t(
              'PageSendMoneySummary.DeliveryMethodCashPickupAny'
            ).value
          } else {
            const agent: DeliveryMethodAgent | null = form.deliveryMethodAgent

            let deliveryMethodText = `${agent?.name} (${i18n.$t('PageSendMoneySummary.DeliveryMethodCashPickup').value})`

            if (!agent?.openPayment) {
              deliveryMethodText = i18n.$t(
                'PageSendMoneySummary.DeliveryMethodCashPickupPointToPoint'
              ).value
            }

            form.deliveryMethodText = deliveryMethodText
          }
          break
        }
        case DeliveryMethod.HomeDelivery.enumName:
          form.deliveryMethodText = i18n.$t('PageSendMoneySummary.DeliveryMethodHomeDelivery').value
          break
      }
    }

    function setForm(value: any) {
      const mergedForm = Object.assign({}, form, value)
      Object.assign(form, mergedForm)
    }

    async function continueOrder(order: any) {
      resetForm()
      setForm(order)

      try {
        await calculate({ summary: false, debounceTime: 0 }) // first without summary to get the quote
        await calculate({ summary: true, debounceTime: 0 }) // then with summary to update the quote
      } catch (error: any) {
        const calculateError = typeof error === 'string' ? new Error(error) : error
        calculateError.errorCode = 'SendAgainCannotBePerformed'
        throw calculateError
      }
    }

    function clearCardData() {
      form.cardSecurityCode = null
      cardAccountStore.setApexxData(null)
    }

    async function selectCard({
      card,
      securityCode,
    }: {
      card: any
      securityCode: string //CHECK TYPE
    }) {
      if (cardAccountStore.useApexx) {
        const user = authStore.user
        const encryptedData = await cardAccountStore.encryptCardApexx(securityCode)

        cardAccountStore.setApexxData({
          merchantReference: null, // will be filled during authorization
          payerAuthReferenceId: null, // will be filled during authorization
          ccy: card.currency,
          amount: null, // will be filled during authorization
          countryCode: card.country,
          cardEncryptedData: encryptedData,
          cardId: card.cardV2Data.id, // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
          customerIpAddress: null, // is filled in Launchpad
          emailAddress: user?.customer.email,
        })
      } else {
        form.cardSecurityCode = securityCode
      }
    }

    async function uploadDocument(file: any): Promise<any> {
      let response = await postTransferDocumentAPI.exec({
        transferId: form.transferId,
        docId: form.uploadDocId,
        file,
      })

      return response
    }

    async function transactionCreatedSegmentEvent(segmentEventName: string) {
      const analyticsStore = useAnalyticsStore()
      const findCashPayoutType = () => {
        let analyticsPayoutType = ''
        if (form.deliveryMethod === 'CashPayout') {
          const isOpenPayment = form.isOpenPaymentCountry
          const isAgentOpenPayment = getDeliveryMethodAgentOpenPayment.value
          if (isOpenPayment) {
            analyticsPayoutType = SEGMENT_PAYOUT_AGENT_TYPE.OPEN_PAYMENT
          } else if (isAgentOpenPayment) {
            analyticsPayoutType = SEGMENT_PAYOUT_AGENT_TYPE.OPEN_NETWORK
          } else {
            analyticsPayoutType = SEGMENT_PAYOUT_AGENT_TYPE.POINT_TO_POINT
          }
          return analyticsPayoutType
        }
        return analyticsPayoutType
      }
      const paymentMethod = form.paymentMethod
      const deliveryMethod = form.deliveryMethod

      const currencyFrom = getCurrencyFrom.value
      const currencyTo = form.currencyTo

      analyticsStore.track({
        event: segmentEventName,
        traits: {
          location: SEGMENT_LOCATIONS.REVIEW_TRANSFER,
          send_amount: getAmountFrom(false),
          send_currency: getCurrencyFrom.value,
          sendMethod: getSegmentPaymentMethod(paymentMethod),
          sender_country: authStore?.userProfile?.country,
          payout_amount: getAmountTo(false),
          payout_currency: form.currencyTo,
          payoutMethod: getSegmentPayoutMethod(deliveryMethod),
          liquidityManager: getLiquidityManager.value,
          userType:
            authStore?.userProfile?.customer.accountType === 'Consumer' ? 'Personal' : 'Corporate',
          payoutCountryType: findCashPayoutType(),
          destination_country: getCountryTo.value,
          isRiaTransfer: getLiquidityManager.value === 'Ria',
          sameCurrency: currencyFrom === currencyTo,
        },
      })
    }

    function initCalculatorCurrencyFrom() {
      const countriesStore = useCountriesStore()
      //get default currency based on device country id
      if (!form.currencyFrom) {
        const defaultCurrency = countriesStore.getCountryDefaultCurrency
        form.currenciesFrom = [{ value: defaultCurrency, text: defaultCurrency }]
        form.currencyFrom = defaultCurrency
      }
    }

    function calculatorSelectCountryTo(countryTo: string) {
      // TODO can we refactor to use setCountryTo
      /*
      - If the country selection has changed
        - Clear up payment methods and recipient
        - Set the country
        - Reset Amount
     */
      if (countryTo !== form.countryTo) {
        // Clean up recipient
        form.recipient = null //TODO: CHECK THIS - BEFORE WAS {}
        // Set Country
        form.countryTo = countryTo
      }
    }

    async function processMobileWalletSteps(transferId: string | null): Promise<any> {
      const analyticsStore = useAnalyticsStore()
      const steps = orderSteps.value

      let nextPage = '/send-money/payment'

      if (steps && steps.length > 0) {
        const step = steps.shift()
        if (step.fields) {
          form.mobileWalletAdditionalFields = step.fields
          return null
        }
      } else if (form.requireAdditionalVerification) {
        form.requireAdditionalVerification = false

        let response = transferBasicResponse.value

        //if we don't have the transferId we retrieve it here
        if (!response || response.errors?.length > 0) {
          response = await sendTransfer({ stage: TRANSFER.STAGES.BASIC })
        }

        if (!response.errors) {
          form.transferId = response.transferId
        }

        if (form.transferId) {
          response = await sendTransfer({ stage: TRANSFER.STAGES.WALLET_VALIDATE })
        }

        //we check here if there were any mw validation errors
        if (response.verified === TRANSFER.RESPONSE.VERIFIED.STOP) {
          setMobileWalletWarning(response.message)
          const error = mobileWalletWarning.value
          if (error.hasError) {
            const provider = mobileWalletProvider.value
            const currencyTo = form.currencyTo
            const countryTo = getCountryTo.value
            analyticsStore.track({
              event: SEGMENT_EVENTS.WALLET_DETAILS_FAILED,
              traits: {
                destinationCountry: countryTo,
                payoutCurrency: currencyTo,
                walletProvider: provider?.name,
                errorCode: error.errorCode,
                errorText: error.errorText,
                errorDescription: error.errorDescription,
              },
            })
          }
          //don't return the nextPage in case there are validation errors
          return
        } else {
          setMobileWalletWarning(null)
        }

        nextPage = await prepareOrderSteps({
          transferId,
          response,
          isMobileWallet: true,
        })
      }

      await setFilledAdditionalFields(null)
      form.mobileWalletAdditionalFields = null
      return nextPage
    }

    async function resetAdditionalFields() {
      await setFilledAdditionalFields(null)
    }

    async function openSendMoney() {
      steps.value = SMFSteps
      await router.push('/send-money')
    }

    // TODO Seperate calculator for simplicity?
    async function initCalculator() {
      shouldDisplayUsWireTransferWarning.value = false
      /* Init the calculator
        - Country
          - If the user has a stored form
             - Use stored form country
          -Else
            - Fetch user transactions
            - Get user transactions
            - If transactions exist, use the most recent transaction country
              - Else
                - Dont render a country.
                  TODO BUG, the currency input fails when no currency is set
    
        - Promo Codes
         - Fetch and get promo codes
       */
      await initCalculatorCountry()

      await initCalculatorCurrencyFrom()
    }

    async function initCalculatorCountry() {
      /*
    Handle Initial Country
    - Country
          - If the user has a stored form
             - Use stored form country
          -Else
            - Fetch user transactions
            - Get user LastTransaction
            - If transactions exist, use the most recent transaction country
              - Else
                - Dont render a country.
    * */
      // When the country is stored, on nothing
      const appStore = useAppStore()
      try {
        const hasStoredCountry = form.countryTo
        const deviceStore = useDeviceStore()

        if (!hasStoredCountry) {
          let lastTransactionCountryTo = deviceStore.getLastTransaction

          // Else fetch previous transactions
          await activityStore.getTransactions(1)

          let lastTransaction = activityStore.getLastTransaction
          if (lastTransaction) {
            const { id } = lastTransaction

            const { countryTo } = await activityStore.getTransactionDetails(id)
            if (lastTransactionCountryTo === countryTo) {
              calculatorSelectCountryTo(countryTo)
            }
          }
        }
      } catch (ex) {
        appStore.logException('Exception during getting latest transaction', ex)
      }
    }

    async function sendOnfido(onfidoResult: any) {
      let ids = ''

      for (const name in onfidoResult) {
        const document = onfidoResult[name]
        if (document.id) {
          if (ids.length > 0) {
            ids += ','
          }
          ids += document.id
        }
      }

      const filledAdditionalFields = {
        proofOfId: ids,
      }
      setFilledAdditionalFields(filledAdditionalFields)
      form.requireAdditionalVerification = false

      const result = await sendTransfer({ stage: TRANSFER.STAGES.EDD })

      // const result = { verified: 'True' }
      if (result.verified === TRANSFER.RESPONSE.VERIFIED.TRUE) {
        return KYC_STATUS.KYC_SUCCESS
      } else if (result.verified === TRANSFER.RESPONSE.VERIFIED.PENDING) {
        return KYC_STATUS.KYC_IN_PROGRESS
      } else if (result.verified === TRANSFER.RESPONSE.VERIFIED.STOP) {
        return KYC_STATUS.KYC_SUSPECTED
      }
      return KYC_STATUS.KYC_CONSIDER
    }

    async function sendTransfer({
      stage,
      shouldSendRecipient = true,
    }: {
      stage: string
      shouldSendRecipient?: boolean
    }) {
      const user = authStore.user

      transferStage.value = stage

      const request = {
        quoteId: form.quoteId,
        profileId: user?.customer?.id,
        recipientId: shouldSendRecipient ? form?.recipient?.id : null,
        sendMoneyForm: form,
        country: user?.country,
        // if stage is confirmed we do not send additional fields
        ...(stage !== TRANSFER.STAGES.CONFIRM && {
          filledAdditionalFields: form.filledAdditionalFields,
        }),
        stage,
      }
      try {
        const { data } = await postTransfer.exec(request)

        if (stage === TRANSFER.STAGES.BASIC) {
          // store the basic response so we can retain our logic everywhere and don't need to call it again later
          transferBasicResponse.value = data
        }

        setFailedTransferData({ transferError: null, stage })
        return data
      } catch (ex: any) {
        //save transfer call data
        setFailedTransferData({ transferError: ex.errorObject, stage })
        throw ex
      }
    }

    async function addRecipientO2OTransfer() {
      //creating device blackbox
      await createBlackbox()

      let response = transferBasicResponse.value
      if (!response) {
        response = await sendTransfer({ stage: TRANSFER.STAGES.BASIC })
      }

      if (
        !response.errors &&
        response.verified !== TRANSFER.RESPONSE.VERIFIED.STOP &&
        response.verified !== TRANSFER.RESPONSE.VERIFIED.RESTRICTED
      ) {
        form.transferId = response.transferId
      }

      let promise = await sendTransfer({ stage: TRANSFER.STAGES.EDD })

      //we reset the transferId after adding the recipient so it will continue
      //as per normal flow if there is any user information missing
      form.transferId = ''

      return await promise
    }

    async function createBlackbox() {
      const appStore = useAppStore()
      /// some AdBlockers do block the ioVation script,
      /// at this point the blackbox is not used by the backend, so if it does fail
      /// we will still send the transaction data to the backend
      /// some Ad Blockers does block it some of them doesn't so this will fix the problem on preventing the
      /// successful transaction to fail.

      try {
        await appStore.loadScript(useEnvStore().env.VUE_APP_IOVATION_SCRIPT_URL)

        let blackbox = null
        // eslint-disable-next-line no-undef
        // @ts-ignore
        if (typeof ioGetBlackbox !== 'undefined' && ioGetBlackbox) {
          // eslint-disable-next-line no-undef
          // @ts-ignore
          blackbox = ioGetBlackbox().blackbox
          form.blackbox = blackbox
        }
      } catch (ex) {
        appStore.logException('Exception creating blackbox', ex)
      }
    }

    async function processOrder() {
      return new Promise(async (resolve, reject) => {
        //creating black box
        await createBlackbox()

        riaGeneralStopMessage.value = false

        //get saved basic response
        let response = transferBasicResponse.value

        //get exisiting transfer id
        let transferId = form.transferId || response?.transferId
        try {
          //either basic response or transferId doesn't exist or there are errors
          //get basic again
          if (!response || (!transferId && !response) || response?.errors?.length > 0) {
            response = await sendTransfer({ stage: TRANSFER.STAGES.BASIC })
            transferId = response.transferId
          }

          if (
            !response.errors &&
            response.verified !== TRANSFER.RESPONSE.VERIFIED.STOP &&
            response.verified !== TRANSFER.RESPONSE.VERIFIED.RESTRICTED
          ) {
            form.transferId = transferId
            response = await sendTransfer({ stage: TRANSFER.STAGES.EDD })
          }
        } catch (ex) {
          // do we need to go directly to failed?
          reject('/send-money/failed')
          return
        }

        // TODO improve handling`
        if (
          response.verified === TRANSFER.RESPONSE.VERIFIED.STOP ||
          response.verified === TRANSFER.RESPONSE.VERIFIED.RESTRICTED
        ) {
          if (response.message.includes(TRANSFER.RESPONSE.MESSAGE.RIA_GENERAL_STOP)) {
            riaGeneralStopMessage.value = true
            reject('/send-money/failed')
            return
          }
          if (response.message.includes(TRANSFER.RESPONSE.MESSAGE.PAYMENT_STOPPED)) {
            riaGeneralStopMessage.value = true
            isPaymentMethodError.value = false
            reject('/send-money/failed')
            return
          }
          accountIsRestricted.value = true
          reject(new Error(`Transfer has been stopped`))
          return
        } else if (response.verified === TRANSFER.RESPONSE.VERIFIED.PENDING) {
          isVerificationPending.value = true
          if (response.message === TRANSFER.RESPONSE.MESSAGE.DOCUMENT_RESULT_PENDING) {
            setIsProofOfAddressPending(true)
          }
          reject('/send-money/summary')
          return
        }

        let result = await prepareOrderSteps({
          transferId,
          response
        })

        resolve(result)
      })
    }

    async function prepareOrderSteps({
      transferId,
      response,
      isMobileWallet = false,
    }: {
      transferId: string | null
      response: any
      isMobileWallet?: boolean | null
    }): Promise<string> {
      const fieldDefs: any = {}

      const getFieldDefs = (fieldDefinitions: []) => {
        for (const element of fieldDefinitions) {
          const fieldDef = element as any
          fieldDefs[fieldDef.id] = fieldDef
          if (fieldDef.children && fieldDef.children.length > 0) {
            getFieldDefs(fieldDef.children)
          }
          if (fieldDef?.validation?.values) {
            getFieldDefs(fieldDef.validation.values)
          }
        }
      }

      let additionalFieldsList: any = []
      const addIdDocFields = (error: any) => {
        if (error?.fieldDefinition?.validation?.values) {
          error.fieldDefinition.validation.values.forEach((val: any) => {
            if (val.children) {
              const reversedChildren = val.children.reverse()
              reversedChildren.forEach((child: any) => {
                let field = new XeTransferField(child)
                field.selectedId = val.id
                additionalFieldsList.push(field)
              })
            }
          })
        }
      }

      return new Promise((resolve) => {
        if (response.fieldDefinitions) {
          getFieldDefs(response.fieldDefinitions)
        }

        let steps = []
        let uploadFields = []

        if (response.errors) {
          //we trigger all the idType group to display so we can show previously set value
          if (response.errors.find((error: any) => error.id === 'licenceCardNumber')) {
            response.errors.push({
              type: 'Verify',
              id: 'idType',
              reasonCode: 'Verify_NotSupplied',
              reasonDesc:
                'There is no stored value for this id and it was not supplied in the request but is mandatory',
            })
            //we remove the property as it will appear anyway
            response.errors = response.errors.filter(
              (error: any) => error.id !== 'licenceCardNumber'
            )
            licenceCardNumberMissing.value = true
          } else {
            licenceCardNumberMissing.value = false
          }

          //we ask for occupation again if the backend requires us to the free text
          if (response.errors.find((error: any) => error.id === 'occupationFreeText')) {
            response.errors.push({
              type: 'Verify',
              id: 'occupation',
              reasonCode: 'Verify_NotSupplied',
              reasonDesc:
                'There is no stored value for this id and it was not supplied in the request but is mandatory',
            })
            response.errors = response.errors.filter(
              (error: any) => error.id !== 'occupationFreeText'
            )
          }

          for (const element of response.errors) {
            let hasFieldDefinition = true
            const error = element

            error.fieldDefinition = fieldDefs[error.id]

            if (!error.fieldDefinition) {
              error.fieldDefinition = {}
              hasFieldDefinition = false
            }

            if (error.id === 'proofOfId' && error.fieldDefinition.validation.biometric) {
              const biometricData = error.fieldDefinition.validation.biometric
              verificationPlatform.value = biometricData.tokenType ?? null
              verificationToken.value = biometricData.token ?? null
              verificationApplicationId.value = biometricData.applicationId ?? null
              verificationBaseUrl.value = biometricData.baseUrl ?? null
              steps.push({ id: error.id })
            } else if (error.fieldDefinition.type === 'Document') {
              steps.push({ upload: error.id })
              uploadFields.push(error.id)
            } else if (hasFieldDefinition) {
              const transferField = new XeTransferField(error.fieldDefinition)
              if (error.fieldDefinition?.validation?.type === 'YesNo') {
                transferField.text = error.fieldDefinition.label
              }
              additionalFieldsList.push(transferField)

              const freeTextField = fieldDefs[error.id + 'FreeText']
              if (freeTextField) {
                additionalFieldsList.push(new XeTransferField(freeTextField))
              }
              addIdDocFields(error)
            }
          }
        }

        // double check if the field is already part of additional fields and then remove it to avoid asking for that field twice
        uploadFields.forEach((uploadId) => {
          let len = additionalFieldsList.length
          for (let i = 0; i < len; i++) {
            const field = additionalFieldsList[i]
            const fieldId = field.id.replace('FreeText', '')
            if (uploadId.indexOf(fieldId) === 0) {
              // i.e. sourceOfFundsCopy and sourceOfFunds
              additionalFieldsList.splice(i, 1)
              len--
              i--
            }
          }
        })

        if (additionalFieldsList.length > 0) {
          steps.unshift({ fields: additionalFieldsList })
        }

        orderSteps.value = steps
        if (!isMobileWallet) {
          resolve(processOrderSteps(transferId))
        } else {
          resolve(processMobileWalletSteps(transferId))
        }
      })
    }

    const getDefaultAmountFrom = (): number => {
      if (authStore.isCorporateAccount) {
        return DEFAULT_AMOUNT_FROM.CORPORATE
      } else {
        return DEFAULT_AMOUNT_FROM.CONSUMER
      }
    }
    async function processOrderSteps(transferId: string | null): Promise<any> {
      return new Promise(async (resolve, reject) => {
        const steps = orderSteps.value

        let nextPage = '/creating'

        try {
          // if the quote didn't finish to refresh we will await for it to check the new rates
          if (!quoteRefreshResolved.value && quoteRefreshPromise.value) {
            await quoteRefreshPromise.value
          }
        } catch (ex) {
          useAppStore().logException('Error awaiting promise', ex)
        }

        const isFastTransaction =
          form.deliveryMethod === DELIVERY_METHODS.FUNDS_ON_BALANCE ||
          form.paymentMethod === PAYMENT_METHODS.FUNDS_ON_BALANCE ||
          quickTransferStore.isCurrentTransactionQuickTransfer

        //if we should to to creating but the rate changed we go to summary instead
        if (
          nextPage === '/creating' &&
          shouldCheckForRateChange.value &&
          didRateChange.value &&
          !isVerifiedFromConfirmStage.value
        ) {
          //temp solution works only for QT should be reversed
          if (isFastTransaction) {
            quickTransferStore.showRateChangedModal()
            reject()
            return null
          }

          //need flag if it's normal SMF
          nextPage = '/send-money/summary'
        }

        if (steps && steps.length > 0) {
          if (!shouldRefreshQuote.value && !isVerifiedFromConfirmStage.value) {
            shouldRefreshQuote.value = true
            calculate({ refresh: true })
          }

          const step = steps.shift()

          if (step.upload) {
            form.uploadDocId = step.upload
            nextPage = '/send-money/summary/upload-document'
          } else if (step.id === 'proofOfId') {
            nextPage = '/send-money/summary/verify-identity'
          } else if (step.fields) {
            additionalFields.value = step.fields
            nextPage = '/send-money/summary/additional-details'
            changeVerificationVisibility(true)
          }
        } else if (form.requireAdditionalVerification) {
          let response
          let cachedResponse = transferBasicResponse.value

          if ((!transferId && !cachedResponse) || cachedResponse?.errors?.length > 0) {
            response = await sendTransfer({ stage: TRANSFER.STAGES.BASIC })

            if (!response.errors) {
              transferId = response.transferId
              form.requireAdditionalVerification = false
              setFilledAdditionalFields(null)
            } else {
              transferId = null
              setFilledAdditionalFields(null)
              form.requireAdditionalVerification = true
            }

            transferId ||= form.transferId
            form.transferId = transferId
          }
          try {
            if (form.transferId || transferId) {
              form.transferId = transferId
              response = await sendTransfer({ stage: TRANSFER.STAGES.EDD })
              setFilledAdditionalFields(null)
              form.requireAdditionalVerification = false
            }
          } catch (ex) {
            nextPage = '/send-money/failed'
            reject(nextPage)
            return
          }

          resolve(
            prepareOrderSteps({
              transferId,
              response
            })
          )
          return
        }

        resolve(nextPage)
      })
    }

    function changeVoltCheckoutComplete(isSuccess: boolean, status: Object) {
      voltCheckoutComplete.value = isSuccess

      const channel = new BroadcastChannel('volt-checkout-complete')

      channel.postMessage({
        voltCheckoutComplete: voltCheckoutComplete.value,
        voltCheckoutStatus: status,
      })
    }

    function changeAdyenCheckoutComplete(isSuccess: boolean, payload: Object) {
      adyenCheckoutComplete.value = isSuccess

      const channel = new BroadcastChannel('adyen-checkout-complete')

      channel.postMessage({
        adyenCheckoutComplete: adyenCheckoutComplete.value,
        adyenCheckoutPayload: payload,
      })
    }

    async function processMobileWalletOrder({
      transferId
    }: {
      transferId: string | null
    }): Promise<void> {
      //reset
      await setFilledAdditionalFields(null)
      form.mobileWalletAdditionalFields = null
      setMobileWalletWarning(null)

      let response = transferBasicResponse.value
      // we call transfer with stage basic to get the transferId

      if (!response) {
        response = await sendTransfer({ stage: TRANSFER.STAGES.BASIC })
      }

      //if there were no errors, we proceed to retrieve the fields for the wallet
      if (
        !response.errors &&
        response.verified !== TRANSFER.RESPONSE.VERIFIED.STOP &&
        response.verified !== TRANSFER.RESPONSE.VERIFIED.RESTRICTED
      ) {
        form.transferId = response.transferId
        response = await sendTransfer({ stage: TRANSFER.STAGES.WALLET_VALIDATE })
      }

      if (response.verified === TRANSFER.RESPONSE.VERIFIED.STOP) {
        accountIsRestricted.value = true
        throw new Error(`Transfer has been stopped`)
      } else if (response.verified === TRANSFER.RESPONSE.VERIFIED.RESTRICTED) {
        throw new Error(`Account is restricted`)
      }

      //we use the same action as per normal transfer but with mobileWallet flag set to true
      await prepareOrderSteps({
        transferId,
        response,
        isMobileWallet: true,
      })
    }

    async function changeVerificationVisibility(availability: boolean) {
      setVerificationStepVisibility(availability)
    }

    async function goToNextStep(): Promise<Nullable<Route>> {
      const analyticsStore = useAnalyticsStore()
      // TODO: Proper steps management
      // Get current step
      const currentStep = getAvailableSteps.value[activeStepIdx.value]
      const nextStep = getNextAvailableStep.value
      const isOpenPayment = form.isOpenPaymentCountry
      let deliveryStepName = 'Delivery'

      // 1. check current step, look for "delivery"
      // 2. if different then next available step that means leaving the delivery step so submit Segment payout method selected
      if (currentStep.name === deliveryStepName && nextStep?.name !== deliveryStepName) {
        const deliveryMethod = form.deliveryMethod

        let analyticsPayout = {}

        analyticsStore.track({
          event: SEGMENT_EVENTS.PAYOUT_METHOD_SELECTED,
          traits: {
            location: SEGMENT_LOCATIONS.SEND_MONEY,
            payoutMethod: getSegmentPayoutMethod(deliveryMethod),
            ...analyticsPayout,
          },
        })

        const selectedSegmentPaymentType = getSegmentPaymentMethod(form.paymentMethod)
        if (selectedSegmentPaymentType === SEGMENT_PAYMENT_METHOD_TYPES.STAGED) {
          analyticsStore.track({
            event: SEGMENT_EVENTS.PAYMENT_METHOD_SELECTED,
            traits: {
              paymentType: selectedSegmentPaymentType,
            },
          })
        }
      }

      if (nextStep) {
        if (nextStep.name === 'Recipient') {
          await router.push('/send-money/recipient')
        } else if (nextStep.name === 'Delivery') {
          // TODO: Is it possible that delivery method is not set?
          if (form.deliveryMethod === DeliveryMethod.BankDeposit.enumName) {
            router.push('/send-money/delivery/bank-account')
          } else if (
            form.deliveryMethod === DeliveryMethod.HomeDelivery.enumName ||
            (form.deliveryMethod === DeliveryMethod.CashPickup.enumName && !isOpenPayment)
          ) {
            await router.push('/send-money/delivery/choose-network')
          } else {
            await router.push('/send-money/delivery/open-payment')
          }
        } else if (nextStep.name === 'Payment') {
          await router.push('/send-money/payment')
        } else if (nextStep.name === 'Send') {
          await router.push('/send-money/summary')
        }
      }

      return null
    }

    async function goToAddPaymentMethod() {
      if (form.paymentMethod === 'BankAccount') {
        await router.push('/send-money/payment/bank')
      } else if (form.paymentMethod === 'CreditCard' || form.paymentMethod === 'DebitCard') {
        await router.push('/send-money/payment/card')
      }
    }

    async function submitAdditionalDetails({
      filledAdditionalDetailsFields,
      isMobileWallet = false,
    }: {
      filledAdditionalDetailsFields: any
      isMobileWallet: boolean
    }) {
      return new Promise((resolve) => {
        setFilledAdditionalFields(filledAdditionalDetailsFields)
        additionalFields.value = []
        if (!isMobileWallet) {
          resolve(processOrderSteps(form.transferId))
        } else {
          resolve(processMobileWalletSteps(form.transferId))
        }
      })
    }

    async function verifyIdentity(onfidoResult: any): Promise<VerificationOutcomes> {
      if (!onfidoResult) {
        onfidoResult = {
          document_front: {
            id: '4e1eb6cd-062c-4662-8fd3-1eaad60c7b2c',
            type: 'driving_licence',
            side: 'front',
            variant: 'standard',
          },
        }
      }

      let ids = ''

      for (const name in onfidoResult) {
        const document = onfidoResult[name]
        if (document.id) {
          if (ids.length > 0) {
            ids += ','
          }
          ids += document.id
        }
      }

      const filledAdditionalFields = {
        proofOfId: ids,
      }
      setFilledAdditionalFields(filledAdditionalFields)
      form.requireAdditionalVerification = false

      let result
      try {
        result = await sendTransfer({ stage: TRANSFER.STAGES.EDD })
      } catch (error) {
        result = { verified: TRANSFER.RESPONSE.VERIFIED.FALSE }
      }
      // Don't send the segment event if the result is pending as this indicates we are still polling.
      if (result.verified !== TRANSFER.RESPONSE.VERIFIED.PENDING) {
        analyticsStore.track({
          event: SEGMENT_EVENTS.BIOMETRIC_VERIFICATION_COMPLETED,
          traits: {
            biometricPlatform: verificationPlatform.value,
            externalSessionId: verificationApplicationId.value,
            biometricStatus: result.verified,
            location: 'sendMoney',
          },
        })
      }

      switch (result.verified) {
        case TRANSFER.RESPONSE.VERIFIED.TRUE:
          return VERIFICATION_OUTCOMES.Success

        case TRANSFER.RESPONSE.VERIFIED.PENDING:
          return VERIFICATION_OUTCOMES.Pending
        case TRANSFER.RESPONSE.VERIFIED.STOP:
          if (result.message?.includes('CLOSED')) {
            return VERIFICATION_OUTCOMES.Closed
          }
        case TRANSFER.RESPONSE.VERIFIED.RESTRICTED:
          analyticsStore.track({
            event: SEGMENT_EVENTS.SOMETHING_WENT_WRONG,
            traits: {
              location: 'sendMoney',
              category: 'ID Verification',
              error: result.message,
              errorDescription: result.message,
            },
          })
          return VERIFICATION_OUTCOMES.Rejected

        case TRANSFER.RESPONSE.VERIFIED.FALSE:
          if (result.errors) {
            return VERIFICATION_OUTCOMES.EDDRequired
          } else {
            return VERIFICATION_OUTCOMES.Rejected
          }
      }
      return VERIFICATION_OUTCOMES.Rejected
    }

    async function orderCreate() {
      const analyticsStore = useAnalyticsStore()
      let shouldSendRecipient = true
      if (isO2OContract.value) {
        shouldSendRecipient = isRecipientRequired.value
      }

      const data = await sendTransfer({
        stage: TRANSFER.STAGES.CONFIRM,
        shouldSendRecipient: shouldSendRecipient,
      })
      if (data) {
        if (data.errors) {
          //get exisiting transfer id
          let transferId = form.transferId || data?.transferId

          bypassSendMoneyCreatingRedirection.value = true
          isVerifiedFromConfirmStage.value = true

          let nextPage = await prepareOrderSteps({
            transferId,
            response: data
          })

          return {
            success: false,
            nextPage,
          }
        }
        if (data.success) {
          isVerifiedFromConfirmStage.value = false

          clearCardData() // to be on the safe side try to get rid of it

          if (isO2OContract.value) {
            data.orderNumber = form.contractId
          }

          form.createdOrderNumber = data.orderNumber

          analyticsStore.gtmTrackVariables({
            variables: {
              Dealing_ContractNumber: data.orderNumber,
            },
          })
          if (
            form.deliveryMethod === DeliveryMethod.CashPickup.enumName ||
            form.deliveryMethod === DeliveryMethod.HomeDelivery.enumName
          ) {
            const orderData = await activityStore.getTransactionDetails(data.orderNumber)
            if (orderData) {
              form.createdPin = orderData.pin
            } else {
              throw new Error(`Failed to get order status`)
            }
          }
        }

        if (data.verified !== TRANSFER.RESPONSE.VERIFIED.TRUE) {
          if (data.verified === TRANSFER.RESPONSE.VERIFIED.RESTRICTED) {
            accountIsRestricted.value = true
          } else {
            riaGeneralStopMessage.value = true
            isPaymentMethodError.value = false
            throw Error(TRANSFER.RESPONSE.VERIFIED.STOP)
          }
        }

        const deviceStore = useDeviceStore()
        deviceStore.setLastTransaction(form.countryTo)
        return data
      } else {
        isPaymentMethodError.value = true
        throw new Error(`Failed to create order`)
      }
    }

    function getAmountFrom(formatted: boolean): any {
      const value = form.amountFrom
      if (formatted) {
        return formatCurrency(form.currencyFrom, value, useDeviceStore().locale)
      }
      return value
    }

    //MISSING SENDAMOUNTUSD
    function getSendAmountUSD(formatted: boolean): any {
      const value = form.sendAmountUSD
      if (formatted) {
        return formatCurrency('USD', value, useDeviceStore().locale)
      }
      return value
    }

    function getAmountTo(formatted: boolean): any {
      const value = form.amountTo
      if (formatted) {
        return formatCurrency(form.currencyTo, value, useDeviceStore().locale)
      }
      return value
    }

    function getFee(formatted: boolean): any {
      const value = form.fee || form.amountFee //TODO - AMOUNTFEE IS MISSING
      if (formatted) {
        return formatCurrency(form.currencyFrom, value, useDeviceStore().locale)
      }
      return value
    }

    function getCalculatedFee(formatted: boolean): any {
      return getFee(formatted)
    }

    const getIsIncludedFee = computed(() => {
      if (form.selectedQuote && form.selectedQuote.paymentMethodMarginFee > 0) {
        return true
      }
      return false
    })

    function getTransferFee(formatted: boolean): any {
      // TODO- CHECK SELECTEDQUOTE
      if (form.selectedQuote) {
        if (formatted) {
          return formatCurrency(
            form.currencyFrom,
            form.selectedQuote.transferFee,
            useDeviceStore().locale
          )
        } else {
          return form.selectedQuote.transferFee
        }
      }
      return 0
    }

    function getPaymentMethodFee(formatted: boolean): any {
      if (form.selectedQuote) {
        if (formatted) {
          return formatCurrency(
            form.currencyFrom,
            form.selectedQuote.paymentMethodFee,
            useDeviceStore().locale
          )
        } else {
          return form.selectedQuote.paymentMethodFee
        }
      }
      return 0
    }

    const getLiquidityManager = computed(() => {
      if (form.selectedQuote) {
        return form.selectedQuote.liquidityManager
      } else if (isO2OContract.value) {
        return liquidityManager.value
      }
      return null
    })

    function getFeeDiscount(formatted: boolean): any {
      const value = form.feeDiscount
      if (formatted && value) {
        return formatCurrency(form.currencyFrom, value, useDeviceStore().locale)
      }
      return value
    }

    // TODO - MISSING amountPromoBonus
    function getPromoBonusAmount(formatted: boolean): any {
      const value = form.amountPromoBonus
      if (formatted && value) {
        return formatCurrency(form.currencyFrom, value, useDeviceStore().locale)
      }
      return value
    }

    function getTotalAmount(formatted: boolean): any {
      const value = form.totalAmount
      if (formatted) {
        return formatCurrency(form.currencyFrom, value, useDeviceStore().locale)
      }
      return value
    }

    const getRecipient = computed(() => {
      if (form.recipient?.id) {
        return form.recipient
      }
      return null
    })

    const getRecipientName = computed(() => {
      const recipient = getRecipient.value
      if (recipient) {
        if (recipient.fullName) {
          return recipient.fullName
        }
        return recipient.firstName + ' ' + recipient.lastName
      }
      return ''
    })

    const getRecipientCityName = computed(() => {
      const recipient = getRecipient.value
      if (recipient) {
        return recipient.recipientCity || recipient.addressLine2
      }
      return ''
    })

    async function getRecipientStateName(): Promise<String> {
      const recipient = getRecipient.value
      if (recipient) {
        let stateName = recipient.recipientState
        try {
          const { data } = await getStates.exec(recipient.country)
          const state = data.find((s: any) => s.value === stateName)
          if (state) {
            stateName = state.name
          }
        } catch (ex) {
          // skip
        }
        return stateName
      }
      return ''
    }

    const getCountryToText = computed(() => {
      const countriesStore = useCountriesStore()
      const countries = countriesStore.getOfferedCountries
      // TODO use store.settings.countries, also what is this doing?
      for (const country of countries) {
        if (country.value === form.countryTo) {
          return country.text
        }
      }
      return ''
    })

    const getPaymentMethodText = computed(() => {
      if (form.paymentMethodText) {
        return form.paymentMethodText
      }
      if (form.paymentMethods) {
        for (const method of form.paymentMethods) {
          if (method.value === form.paymentMethod) {
            return method.text
          }
        }
      }

      return ''
    })

    const getDeliveryMethodText = computed(() => {
      if (form.deliveryMethodText) {
        return form.deliveryMethodText
      }
      if (form.deliveryMethods) {
        for (const method of form.deliveryMethods) {
          if (method.value === form.deliveryMethod) {
            return method.text
          }
        }
      }
      return ''
    })

    const getDeliveryMethodAgentOpenPayment = computed(() => {
      const { deliveryMethodAgent } = form
      return deliveryMethodAgent?.openPayment
    })

    const getCountries = computed(() => {
      const countriesStore = useCountriesStore()
      return countriesStore.getCountries
    })

    const getCombinedCountries = computed(() => {
      const countries = getCountries.value
      const resourcesStore = useResourcesStore()
      const favoriteCountries = resourcesStore.getTopCountries
      return useFavorites(countries, favoriteCountries)
    })

    function getConfirmedRate(formatted: boolean): any {
      const value = form.confirmedRate
      if (formatted && value) {
        return formatExchangeRate(value)
      }
      return value
    }

    const didRateChange = computed(() => {
      const confirmedRate = getConfirmedRate(true)
      const rate = getRate(true)

      if (confirmedRate === null) {
        return false
      }
      return confirmedRate !== rate
    })

    const getAvailableQuotes = computed(() => {
      if (form.availableQuotes) {
        return form.availableQuotes
      }
      return []
    })

    const isCardPayment = computed(() => {
      return (
        form.paymentMethod === PAYMENT_METHODS.CREDIT_CARD ||
        form.paymentMethod === PAYMENT_METHODS.DEBIT_CARD
      )
    })

    const getCardPaymentAvailable = computed((): boolean => {
      //early return
      if (!form?.paymentMethods || authStore.isCorporateAccount) {
        return false
      }

      const isCreditCardAvailable =
        form.paymentMethods.find((pm) => pm.value === PAYMENT_METHODS.CREDIT_CARD)?.isAvailable ??
        false
      const isDebitCardAvailable =
        form.paymentMethods.find((pm) => pm.value === PAYMENT_METHODS.DEBIT_CARD)?.isAvailable ??
        false

      return isCreditCardAvailable || isDebitCardAvailable
    })

    function getQuotesForDeliveryMethod(quotes: any, deliveryMethod: string): any {
      //TODO : CHECK QUOTES TYPE
      return quotes.filter((quote: any) => quote.deliveryMethod === deliveryMethod)
    }
    /**
     * This function will only ever return 1 quote for the specified payment method.
     * You should pass in a filtered list of individual quotes e.g. filtered by delivery method
     * otherwise it's possible you won't get the expected result here.
     */
    function getQuoteForPaymentMethod(quotes: any, paymentMethod: string): any {
      return quotes.find((quote: any) => quote.paymentMethod === paymentMethod)
    }

    function isDeliveryMethodAvailable(deliveryMethods: any, deliveryMethod: string): any {
      return (
        deliveryMethods.find((method: any) => method.value === deliveryMethod)?.isAvailable ?? false
      )
    }

    function isPaymentMethodAvailable(paymentMethods: any, paymentMethod: string): any {
      return (
        paymentMethods.find((method: any) => method.value === paymentMethod)?.isAvailable ?? false
      )
    }

    const getQuoteCreatedTraits = computed(() => {
      try {
        const deliveryMethods = form.deliveryMethods
        const paymentMethods = form.paymentMethods
        const user = authStore.user

        let traits: Traits = {
          isCashPickupAvailable: isDeliveryMethodAvailable(deliveryMethods, 'CashPayout'),
          isBankDepositAvailable: isDeliveryMethodAvailable(deliveryMethods, 'BankAccount'),
          isWalletDeliveryAvailable: isDeliveryMethodAvailable(deliveryMethods, 'MobileWallet'),
          isCreditCardAvailable: isPaymentMethodAvailable(paymentMethods, 'CreditCard'),
          isDebitCardAvailable: isPaymentMethodAvailable(paymentMethods, 'DebitCard'),
          isDirectDebitAvailable: isPaymentMethodAvailable(paymentMethods, 'DirectDebit'),
          isBankTransferAvailable: isPaymentMethodAvailable(paymentMethods, 'BankTrasfer'),
          isOpenBankingAvailable: isPaymentMethodAvailable(paymentMethods, 'OpenBanking'),
          isInteracAvailable: isPaymentMethodAvailable(paymentMethods, 'Interac'),
          isSameCurrency: form.currencyFrom === form.currencyTo,
          destinationCountry: form.countryTo,
          senderCountry: user?.country,
          payoutCurrency: form.currencyTo,
          sendCurrency: form.currencyFrom,
          userId: user?.customer.id,
          recipientRequired: false,
          fixedAmountInUsd: form.fixedAmountInUsd,
        }

        const availableQuotes = getAvailableQuotes.value

        const location = analyticsStore.getSegmentEventCurrentLocation()
        const balanceForCurrency = balancesStore.userActiveBalanceList.find(
          (balance: any) => balance.value === form.currencyFrom
        )
        //Waits update
        if (balanceForCurrency && authStore.getIsBalancesEnabled) {
          traits = {
            ...traits,
            totalBalance: balanceForCurrency.totalBalance,
            availableBalance: balanceForCurrency.availableBalance,
          }
        }

        if (traits.isBankDepositAvailable) {
          // #region BankAccount properties
          const bankAccountQuotes = getQuotesForDeliveryMethod(availableQuotes, 'BankAccount')
          const bankToBankQuote = getQuoteForPaymentMethod(bankAccountQuotes, 'BankTransfer')
          const ccToBankQuote = getQuoteForPaymentMethod(bankAccountQuotes, 'CreditCard')
          const dcToBankQuote = getQuoteForPaymentMethod(bankAccountQuotes, 'DebitCard')
          const ddToBankQuote = getQuoteForPaymentMethod(bankAccountQuotes, 'DirectDebit')
          const obToBankQuote = getQuoteForPaymentMethod(bankAccountQuotes, 'OpenBanking')
          const intToBankQuote = getQuoteForPaymentMethod(bankAccountQuotes, 'Interac')

          if (bankToBankQuote) {
            traits = {
              ...traits,
              bankDepositDeliveryBankTransferFee: bankToBankQuote.fee,
              bankDepositDeliveryBankTransferMarginFee: bankToBankQuote.paymentMethodMarginFee,
              bankDepositDeliveryBankTransferTotalFee: bankToBankQuote.fee,
              bankDepositDeliveryBankTransferPayoutAmount: bankToBankQuote.amountTo,
              bankDepositDeliveryBankTransferRate: bankToBankQuote.rate,
              bankDepositDeliveryBankTransferSendAmount: bankToBankQuote.amountFrom,
            }
          }

          if (ccToBankQuote) {
            traits = {
              ...traits,
              bankDepositDeliveryCreditCardFee: ccToBankQuote.fee,
              bankDepositDeliveryCreditCardMarginFee: ccToBankQuote.paymentMethodMarginFee,
              bankDepositDeliveryCreditCardTotalFee: ccToBankQuote.fee,
              bankDepositDeliveryCreditCardPayoutAmount: ccToBankQuote.amountTo,
              bankDepositDeliveryCreditCardRate: ccToBankQuote.rate,
              bankDepositDeliveryCreditCardSendAmount: ccToBankQuote.amountFrom,
            }
          }

          if (dcToBankQuote) {
            traits = {
              ...traits,
              bankDepositDeliveryDebitCardFee: dcToBankQuote.fee,
              bankDepositDeliveryDebitCardMarginFee: dcToBankQuote.paymentMethodMarginFee,
              bankDepositDeliveryDebitCardTotalFee: dcToBankQuote.fee,
              bankDepositDeliveryDebitCardPayoutAmount: dcToBankQuote.amountTo,
              bankDepositDeliveryDebitCardRate: dcToBankQuote.rate,
              bankDepositDeliveryDebitCardSendAmount: dcToBankQuote.amountFrom,
            }
          }

          if (ddToBankQuote) {
            traits = {
              ...traits,
              bankDepositDeliveryDirectDebitFee: ddToBankQuote.fee,
              bankDepositDeliveryDirectDebitMarginFee: ddToBankQuote.paymentMethodMarginFee,
              bankDepositDeliveryDirectDebitTotalFee: ddToBankQuote.fee,
              bankDepositDeliveryDirectDebitPayoutAmount: ddToBankQuote.amountTo,
              bankDepositDeliveryDirectDebitRate: ddToBankQuote.rate,
              bankDepositDeliveryDirectDebitSendAmount: ddToBankQuote.amountFrom,
            }
          }

          if (obToBankQuote) {
            traits = {
              ...traits,
              bankDepositDeliveryOpenBankingFee: obToBankQuote.fee,
              bankDepositDeliveryOpenBankingMarginFee: obToBankQuote.paymentMethodMarginFee,
              bankDepositDeliveryOpenBankingTotalFee: obToBankQuote.fee,
              bankDepositDeliveryOpenBankingPayoutAmount: obToBankQuote.amountTo,
              bankDepositDeliveryOpenBankingRate: obToBankQuote.rate,
              bankDepositDeliveryOpenBankingSendAmount: obToBankQuote.amountFrom,
            }
          }

          if (intToBankQuote) {
            traits = {
              ...traits,
              bankDepositDeliveryInteracFee: intToBankQuote.fee,
              bankDepositDeliveryInteracMarginFee: intToBankQuote.paymentMethodMarginFee,
              bankDepositDeliveryInteracTotalFee: intToBankQuote.fee,
              bankDepositDeliveryInteracPayoutAmount: intToBankQuote.amountTo,
              bankDepositDeliveryInteracRate: intToBankQuote.rate,
              bankDepositDeliveryInteracSendAmount: intToBankQuote.amountFrom,
            }
          }
          // #endregion
        }

        if (traits.isCashPickupAvailable) {
          // #region BankAccount properties
          const cashPayoutQuotes = getQuotesForDeliveryMethod(availableQuotes, 'CashPayout')
          const ccToCashQuote = getQuoteForPaymentMethod(cashPayoutQuotes, 'CreditCard')
          const dcToCashQuote = getQuoteForPaymentMethod(cashPayoutQuotes, 'DebitCard')
          const ddToCashQuote = getQuoteForPaymentMethod(cashPayoutQuotes, 'DirectDebit')
          const obToCashQuote = getQuoteForPaymentMethod(cashPayoutQuotes, 'OpenBanking')

          if (ccToCashQuote) {
            traits = {
              ...traits,
              cashDeliveryCreditCardFee: ccToCashQuote.fee,
              cashDeliveryCreditCardMarginFee: ccToCashQuote.paymentMethodMarginFee,
              cashDeliveryCreditCardTotalFee: ccToCashQuote.fee,
              cashDeliveryCreditCardPayoutAmount: ccToCashQuote.amountTo,
              cashDeliveryCreditCardRate: ccToCashQuote.rate,
              cashDeliveryCreditCardSendAmount: ccToCashQuote.amountFrom,
            }
          }

          if (dcToCashQuote) {
            traits = {
              ...traits,
              cashDeliveryDebitCardFee: dcToCashQuote.fee,
              cashDeliveryDebitCardMarginFee: dcToCashQuote.paymentMethodMarginFee,
              cashDeliveryDebitCardTotalFee: dcToCashQuote.fee,
              cashDeliveryDebitCardPayoutAmount: dcToCashQuote.amountTo,
              cashDeliveryDebitCardRate: dcToCashQuote.rate,
              cashDeliveryDebitCardSendAmount: dcToCashQuote.amountFrom,
            }
          }

          if (ddToCashQuote) {
            traits = {
              ...traits,
              cashDeliveryDirectDebitFee: ddToCashQuote.fee,
              cashDeliveryDirectDebitMarginFee: ddToCashQuote.paymentMethodMarginFee,
              cashDeliveryDirectDebitTotalFee: ddToCashQuote.fee,
              cashDeliveryDirectDebitPayoutAmount: ddToCashQuote.amountTo,
              cashDeliveryDirectDebitRate: ddToCashQuote.rate,
              cashDeliveryDirectDebitSendAmount: ddToCashQuote.amountFrom,
            }
          }

          if (obToCashQuote) {
            traits = {
              ...traits,
              cashDeliveryOpenBankingFee: obToCashQuote.fee,
              cashDeliveryOpenBankingMarginFee: obToCashQuote.paymentMethodMarginFee,
              cashDeliveryOpenBankingTotalFee: obToCashQuote.fee,
              cashDeliveryOpenBankingPayoutAmount: obToCashQuote.amountTo,
              cashDeliveryOpenBankingRate: obToCashQuote.rate,
              cashDeliveryOpenBankingSendAmount: obToCashQuote.amountFrom,
            }
          }
          // #endregion
        }

        if (traits.isWalletDeliveryAvailable) {
          // #region BankAccount properties
          const mobileWalletQuotes = getQuotesForDeliveryMethod(availableQuotes, 'MobileWallet')
          const ccToMwQuote = getQuoteForPaymentMethod(mobileWalletQuotes, 'CreditCard')
          const dcToMwQuote = getQuoteForPaymentMethod(mobileWalletQuotes, 'DebitCard')
          const ddToMwQuote = getQuoteForPaymentMethod(mobileWalletQuotes, 'DirectDebit')
          const obToMwQuote = getQuoteForPaymentMethod(mobileWalletQuotes, 'OpenBanking')

          if (ccToMwQuote) {
            traits = {
              ...traits,
              walletDeliveryCreditCardFee: ccToMwQuote.fee,
              walletDeliveryCreditCardMarginFee: ccToMwQuote.paymentMethodMarginFee,
              walletDeliveryCreditCardTotalFee: ccToMwQuote.fee,
              walletDeliveryCreditCardPayoutAmount: ccToMwQuote.amountTo,
              walletDeliveryCreditCardRate: ccToMwQuote.rate,
              walletDeliveryCreditCardSendAmount: ccToMwQuote.amountFrom,
            }
          }

          if (dcToMwQuote) {
            traits = {
              ...traits,
              walletDeliveryDebitCardFee: dcToMwQuote.fee,
              walletDeliveryDebitCardMarginFee: dcToMwQuote.paymentMethodMarginFee,
              walletDeliveryDebitCardTotalFee: dcToMwQuote.fee,
              walletDeliveryDebitCardPayoutAmount: dcToMwQuote.amountTo,
              walletDeliveryDebitCardRate: dcToMwQuote.rate,
              walletDeliveryDebitCardSendAmount: dcToMwQuote.amountFrom,
            }
          }

          if (ddToMwQuote) {
            traits = {
              ...traits,
              walletDeliveryDirectDebitFee: ddToMwQuote.fee,
              walletDeliveryDirectDebitMarginFee: ddToMwQuote.paymentMethodMarginFee,
              walletDeliveryDirectDebitTotalFee: ddToMwQuote.fee,
              walletDeliveryDirectDebitPayoutAmount: ddToMwQuote.amountTo,
              walletDeliveryDirectDebitRate: ddToMwQuote.rate,
              walletDeliveryDirectDebitSendAmount: ddToMwQuote.amountFrom,
            }
          }

          if (obToMwQuote) {
            traits = {
              ...traits,
              walletDeliveryOpenBankingFee: obToMwQuote.fee,
              walletDeliveryOpenBankingMarginFee: obToMwQuote.paymentMethodMarginFee,
              walletDeliveryOpenBankingTotalFee: obToMwQuote.fee,
              walletDeliveryOpenBankingPayoutAmount: obToMwQuote.amountTo,
              walletDeliveryOpenBankingRate: obToMwQuote.rate,
              walletDeliveryOpenBankingSendAmount: obToMwQuote.amountFrom,
            }
          }
          // #endregion
        }
        traits = {
          ...traits,
          location: location,
        }
        return traits
      } catch (ex) {
        useAppStore().logException('Exception getting quote create traits')
      }
      return null
    })

    const getQuoteErrorTraits = computed(() => {
      const user = authStore.user
      const quoteError = !!form.quoteErrorFrom ? form.quoteErrorFrom : form.quoteErrorTo

      const baseTraits = {
        userId: user?.customer.id,
        destinationCountry: form.countryTo,
        senderCountry: user?.country,
        payoutCurrency: form.currencyTo,
        sendCurrency: form.currencyFrom,
        quoteError,
        amountTo: form.amountTo,
        amountFrom: form.amountFrom,
      }
      const location = analyticsStore.getSegmentEventCurrentLocation()

      let quoteTraits = {}
      if (form.availableQuotes) {
        const quote = form.availableQuotes.find(
          (qoute) => qoute.paymentMethod === form.paymentMethod
        )
        if (quote) {
          quoteTraits = {
            amountTo: quote.amountTo,
            amountFrom: quote.amountFrom,
            errorCode: quote.unavailableReason?.code,
            location: location,
          }
        }
      }

      return {
        ...baseTraits,
        ...quoteTraits,
      }
    })

    const isQuoteUnavailable = computed(() => !form.selectedQuote)

    const hasSecurityCode = computed(() => {
      return !!form.cardSecurityCode
    })

    //// ACTIONS START ///////////

    async function calculate({
      summary = false,
      debounceTime = 700,
      updateResult = true,
      refresh = false,
      repeated = false,
      focused = QUOTE_SCREEN.AMOUNT_FROM, //check what field is focused
    }: {
      summary?: boolean
      debounceTime?: number
      updateResult?: boolean
      refresh?: boolean
      repeated?: boolean
      focused?: string
    }): Promise<CalculateResponse | null> {
      const appStore = useAppStore()
      const analyticsStore = useAnalyticsStore()
      const i18nStore = useI18nStore()
      const { i18n } = i18nStore

      if (refreshQuote.value) {
        clearInterval(refreshQuote.value)
      }
      refreshQuote.value = null

      return new Promise((resolve, reject) => {
        const executed = debounce(async () => {
          if (!summary && !refresh) {
            clearCardData() // to be on the safe side try to get rid of it
          }

          try {
            let promise = null
            const formObj = { ...form }
            const user = authStore.user

            if (user?.customer) {
              formObj.countryFrom = user.customer.country
            }

            if (
              cardAccountStore.useApexx &&
              (formObj.paymentMethod === PAYMENT_METHODS.CREDIT_CARD ||
                formObj.paymentMethod === PAYMENT_METHODS.DEBIT_CARD)
            ) {
              formObj.isApexx = true
            }

            //if the amountFrom is less than 1 and is not focused means we did recalculate based on other fields
            //sw we can keep continue getting the quote
            if (formObj.amountFrom < 1 && focused === QUOTE_SCREEN.AMOUNT_FROM) {
              reject(Error('Amount from less then 1'))
              return
            }

            let shouldCheckForDuplicateQuote = false

            //update quote has priority over refresh
            let updateQuote = false
            let shouldResetConfirmedRate = false
            if (summary) {
              //#region UPDATE QUOTE
              updateQuote = true
              shouldRefreshQuote.value = false
              promise = await putQuoteAPI.execWithSafeGuard(formObj)
              //#endregion
            } else if (refresh) {
              //#region REFRESH QUOTE
              //disabling refresh quote if the setShouldRefreshQuote is to false
              if (shouldRefreshQuote.value && !isO2OContract.value) {
                const refreshQuoteController = useApiStore().createAndRetrieveCancellationToken(
                  REQUEST_CANCELATION_TOKEN_ENUMS.REFRESH_QUOTE
                )

                promise = await getQuoteAPI.execWithSafeGuard({
                  quoteId: formObj.quoteId,
                  paymentMethod: formObj.paymentMethod,
                  controller: refreshQuoteController,
                })
              } else {
                resolve(null)
              }
              //#endregion
            } else {
              //#region CREATE QUOTE
              const apiStore = useApiStore()
              const controller = apiStore.createAndRetrieveCancellationToken(
                REQUEST_CANCELATION_TOKEN_ENUMS.CALCULATE_NEW_QUOTE
              )
              //we reset delivery Method for Consumers account on Create Quote.
              //it will be correctly added through quote update request
              if (authStore.isConsumerAccount) {
                formObj.deliveryMethod = null
              }

              promise = await calculateAPI.execWithSafeGuard({ sendMoneyForm: formObj, controller })

              if (promise.data.warnings) shouldCheckForDuplicateQuote = true
              shouldResetConfirmedRate = true
              //#endregion
            }

            await lockPromise(promise)

            const { data } = await promise

            //we are reenabling the refresh after we updated the quote
            if (updateQuote) {
              shouldRefreshQuote.value = true
            }

            if (data) {
              //check for duplicate code only if going through calculate api
              if (shouldCheckForDuplicateQuote) {
                //looking for duplicate quote warning from B/E
                const isDuplicate = data?.warnings.find(
                  (warning: any) => warning.type === 'QUOTE210'
                )
                if (isDuplicate) {
                  duplicatedQuoteFound.value = true
                } else {
                  //if we don't have it we reset the flag
                  showDuplicateQuoteWarning.value = false
                  duplicatedQuoteFound.value = false
                }
              }

              // Check if transfer limit is exceeded
              if (form.sendAgain) {
                data?.warnings.find((warning: any) => {
                  if (
                    warning.type === 'QUOTE030' ||
                    warning.type === 'QUOTE031' ||
                    warning.type === 'QUOTE032'
                  ) {
                    limitExceededDialogOpened.value = true
                  }
                })
              }

              form.quoteErrorFrom = null
              form.quoteErrorTo = null
              form.warnings = data.warnings

              setCalculateResult(data)
              updatePaymentAndDeliveryMethods()

              let interval = Math.max(data.expiryTime, 15000)

              // Check this clearInterval line
              const refreshQuoteVal = setInterval(() => {
                //reset the resolved to false
                quoteRefreshResolved.value = false
                clearInterval(refreshQuoteVal)

                //we assign the prosimse so we can have  control over it
                quoteRefreshPromise.value = calculate({ refresh: true })

                quoteRefreshPromise.value.then(() => {
                  quoteRefreshPromise.value = null
                  //we will set the flag as resolved only when it did resolve
                  quoteRefreshResolved.value = true
                })
              }, interval)

              if (!updateQuote && !refresh) {
                if (isQuoteUnavailable.value) {
                  analyticsStore.track({
                    event: SEGMENT_EVENTS.QUOTE_ERROR,
                    traits: getQuoteErrorTraits.value as Traits,
                  })
                } else {
                  analyticsStore.track({
                    event: SEGMENT_EVENTS.QUOTE_CREATED,
                    traits: getQuoteCreatedTraits.value as Traits,
                  })
                }
              }

              if (shouldResetConfirmedRate) {
                resetConfirmedRate()
              }

              refreshQuote.value = refreshQuoteVal
            }
            resolve(data)
            return
          } catch (ex: any) {
            form.availableQuotes = null
            form.quoteErrorFrom = null
            form.quoteErrorTo = null
            let errorText = i18n.$t('QuoteErrors.GenericError').value
            let errorCode = ''

            if (ex.message === QUOTE_SCREEN.REQUEST_CANCELED_ERROR) {
              form.quoteErrorFrom = null
              form.quoteErrorTo = null
              reject(ex)
              return
            }

            if (ex?.response?.status === 404 && !repeated && (summary || refresh)) {
              // if quote has been expired so create a new quote and then execute it again
              appStore.logException('Quote failed with 404 - retrying new quote', ex)
              await calculate({ repeated: true })
              const data = await calculate({
                summary,
                debounceTime,
                updateResult,
                refresh,
                repeated: true,
              })

              resolve(data)
              return
            } else if (ex?.response?.status >= 500) {
              // if quote returns 500 server error so create a new quote and then execute it again
              appStore.logException(
                `Quote failed with status ${ex.response.status} - retrying new quote`,
                ex
              )

              errorText = i18n.$t('QuoteErrors.GenericError500').value
              errorCode = ex.response.status

              await calculate({ repeated: true })
              const data = await calculate({
                summary,
                debounceTime,
                updateResult,
                refresh,
                repeated: true,
              })

              resolve(data)
            }

            let errorTo = false

            const { getBadRequestErrorText } = useQuoteErrors()

            if (ex?.response?.data?.quote?.errorMessages) {
              const errorMessages = ex.response.data.quote.errorMessages

              for (const errorKey in errorMessages) {
                // i18n.$t('QuoteErrors.QUOTE015').value
                // i18n.$t('QuoteErrors.QUOTE018').value
                // i18n.$t('QuoteErrors.QUOTE030').value
                // i18n.$t('QuoteErrors.QUOTE031').value
                // i18n.$t('QuoteErrors.QUOTE035').value
                // i18n.$t('QuoteErrors.QUOTE030').value
                // i18n.$t('QuoteErrors.QUOTE032').value
                // i18n.$t('QuoteErrors.QUOTE050').value
                // i18n.$t('QuoteErrors.QUOTE210').value
                // i18n.$t('QuoteErrors.QUOTE200').value
                // i18n.$t('QuoteErrors.QUOTE201').value
                // i18n.$t('QuoteErrors.QUOTE003').value
                // i18n.$t('QuoteErrors.QUOTE005').value
                // i18n.$t('QuoteErrors.QUOTE008').value
                // i18n.$t('QuoteErrors.QUOTE023').value
                // i18n.$t('QuoteErrors.QUOTE034').value
                // i18n.$t('QuoteErrors.QUOTE035').value
                // i18n.$t('QuoteErrors.QUOTE004').value

                const errorDetails = errorMessages[errorKey]

                errorText = getBadRequestErrorText(errorMessages)

                switch (errorKey) {
                  case 'QUOTE101':
                  case 'QUOTE102':
                    if (!form.shouldCalcAmountFrom) {
                      form.amountTo = errorDetails.amount
                      shouldNotCalculate.value = true
                    }

                    errorTo = true
                    break

                  case 'QUOTE032':
                  case 'QUOTE008':
                  case 'QUOTE005':
                  case 'QUOTE023':
                  case 'QUOTE034':
                  case 'QUOTE035':
                  case 'SellAmountAboveCashDeliverySendingMaxAmount':
                    if (form.shouldCalcAmountFrom) {
                      errorTo = true
                    }

                    break

                  case 'QUOTE070':
                    analyticsStore.track({
                      event: SEGMENT_EVENTS.RECIPIENT_SELECTION_FAILED,
                      traits: { quoteError: errorKey },
                    })
                    reject({ errorKey })
                    break

                  case 'QUOTE003':
                  case 'QUOTE006':
                  case 'QUOTE039':
                  case 'QUOTE051':
                  case 'QUOTE062':
                  case 'QUOTE063':
                  case 'QUOTE064':
                  case 'QUOTE100':
                    appStore.showConfigIssueModal()

                    break
                }
              }
            }

            if (errorTo) {
              form.quoteErrorFrom = null
              form.quoteErrorTo = errorText
            } else {
              form.quoteErrorFrom = errorText
              form.quoteErrorTo = null
            }
            const location = analyticsStore.getSegmentEventCurrentLocation()

            const errorTraits = {
              ...getQuoteErrorTraits.value,
              codeError: errorCode,
              quoteError: errorText,
              location: location,
            }
            if (!form.sendAgain) {
              analyticsStore.track({
                event: SEGMENT_EVENTS.QUOTE_ERROR,
                traits: errorTraits as Traits,
              })
            }

            reject(ex)
          }
        }, debounceTime)
        if (!executed) {
          resolve(null)
        }
      })
    }

    function setActiveStep(path: string): void {
      const step = getStepByPath(path)
      const idx = getStepIdx(step)

      // don't change it when not moving forward or backward
      if (idx > activeStepIdx.value) {
        stepDirectionForward.value = true
      } else if (idx < activeStepIdx.value) {
        stepDirectionForward.value = false
      }
      activeStepIdx.value = idx
    }

    async function goToOfflineToOnlineContract({
      transaction,
      location = SEGMENT_LOCATIONS.ACTIVITY,
    }: {
      transaction: TransactionListItem // interface???
      location: string
    }): Promise<void> {
      const analyticsStore = useAnalyticsStore()
      const recipientsStore = useRecipientsStore()
      const i18nStore = useI18nStore()

      restoreDefaultForm()

      await recipientsStore.getRecipients(transaction.deliveryMethod?.enumName)

      const userCountry = authStore?.user?.country

      // Change Payment Method
      if (userCountry === 'US') {
        form.paymentMethodTitle = i18nStore.$t('PageSendMoneyPayment.WireTransfer').value
      } else {
        form.paymentMethodTitle = i18nStore.$t('PageSendMoneyPayment.BankTransfer').value
      }

      //Preparation steps
      //check what informations are missing
      isO2OContract.value = true

      const isRecipientStep = ref<boolean>(false)
      const isAdditionalInformationRequired = ref<boolean>(false)

      isRecipientStep.value = transaction.actionsRequiredType.includes('RecipientRequired')

      isAdditionalInformationRequired.value =
        transaction.actionsRequiredType.includes('EddOutstanding')

      isAwaitingBalance.value = transaction.actionsRequiredType.includes('AwaitingBalance')

      //set information for the send money summary

      const details: TransactionDetails = await activityStore.getTransactionDetails(
        transaction.orderNumber
      )

      form.currencyTo = transaction.currencyTo
      form.amountTo = transaction.amountTo

      form.paymentMethodId = details.paymentMethodId // is this still in use

      if (details.paymentMethod?.value) {
        setPaymentMethod(details.paymentMethod.value)
      } else {
        console.warn('O2O transaction does not have a payment method')
      }

      setDeliveryMethod(transaction.deliveryMethod.enumName)

      // const details

      if (details) {
        form.amountFrom = details.amountFrom
        form.amountFee = details.amountFee
        form.totalAmount = details.amountTotal
        form.contractId = transaction.orderNumber
        form.currencyFrom = details.currencyFrom
        form.rate = details.rate
        lastRate.value = details.rate
        form.paymentMethodCaption = details.bankAccountXe.bankName
        liquidityManager.value = details.liquidityManager
      }

      if (isRecipientStep.value) {
        //setting the transfer ID as we won't need to call transfer "basic" to retrieve it
        //we need it only if we need to add a recipient
        form.transferId = transaction.launchpadTransferId

        //retrieving all recipients
        isRecipientRequired.value = true

        // O2OSteps.find((step) => step.name === 'Recipient').available = true

        O2OSteps.forEach((step) => {
          if (step.name === 'Recipient') step.available = true
        })
      } else {
        //Setting country to as it was defined due to recipient
        form.countryTo = details.countryTo

        // O2OSteps.find((step) => step.name === 'Recipient').available = false

        O2OSteps.forEach((step) => {
          if (step.name === 'Recipient') step.available = false
        })
      }

      if (isAdditionalInformationRequired.value) {
        //if transferId exists we will use existing one
        if (transaction.launchpadTransferId) {
          form.transferId = transaction.launchpadTransferId
        }

        const allRecipients = recipientsStore.recipients
        const selectedRecipient = allRecipients.find(
          (recipient: Recipient) => recipient.id === details.recipientId
        )

        if (selectedRecipient) form.recipient = selectedRecipient
        form.transferReason = details.transferReason
        form.transferReasonText = details.transferReasonText

        //Verification
        // O2OSteps.find((step) => step.name === 'Verification').available = true
        O2OSteps.forEach((step) => {
          if (step.name === 'Verification') step.available = true
        })
      } else {
        // O2OSteps.find((step) => step.name === 'Verification').available = false
        O2OSteps.forEach((step) => {
          if (step.name === 'Verification') step.available = false
        })
      }

      //replace existing steps
      steps.value = O2OSteps

      analyticsStore.track({
        event: SEGMENT_EVENTS.OFFLINE_TO_ONLINE_START,
        traits: {
          location: location,
          additionalInformationRequired: isAdditionalInformationRequired.value,
          recipientRequired: isRecipientStep.value,
        },
      })

      if (isRecipientStep.value) {
        //either send to the select recipient step
        //and set the breadcrumb accordingly
        router.push('/send-money/recipient')
      } else {
        //either send to the summary page
        //and set the breadcrumb to summary
        router.push('/send-money/summary')
      }
    }

    function setActiveStepPageTitle(pageTitle: string): void {
      steps.value[activeStepIdx.value].pageTitle = pageTitle
    }

    function setAccountIsRestricted(isRestricted: boolean): void {
      accountIsRestricted.value = isRestricted
    }

    function setCurrencyToForCountry(country: string): void {
      const resourcesStore = useResourcesStore()
      const currencies = resourcesStore.getCurrenciesTo
      let currencyTo = getAllInfoByCountry(country).currency
      const found = currencies.find((currency: any) => currency.value === currencyTo)
      if (found) {
        const isSameCurrencySupported = authStore?.userProfile?.sameCurrencySupported
        if (
          found.value !== form.currencyFrom ||
          (isSameCurrencySupported && found.sameCurrencySupported)
        ) {
          form.currencyTo = currencyTo
        } else {
          const fallbackCurrency = getFirstFavoriteCurrency(currencyTo)
          form.currencyFrom = fallbackCurrency.value
          form.currencyTo = currencyTo
        }
      } else if (!form.currencyTo) {
        form.currencyTo = currencies[0].value
      }
    }

    // Previously setPaymentMethod - changed because of name clash with the mutation of the same name
    async function setPaymentMethodAction(paymentMethod: any): Promise<void> {
      // if we change payment method in SendAgain we treat it like normal flow
      if (form.sendAgain) {
        form.sendAgain = false
      }

      // When payment method changes, clear payment method id and security code
      const paymentMethodHasChanged = form.paymentMethod !== paymentMethod

      if (paymentMethodHasChanged) {
        form.paymentMethodId = null
      }

      setPaymentMethod(paymentMethod)
      updatePaymentAndDeliveryMethods()
    }

    function setPaymentMethodName({
      paymentMethodTitle,
      paymentMethodCaption,
      paymentMethodText = '',
      paymentMethodNumber = null,
    }: {
      paymentMethodTitle: string | undefined
      paymentMethodCaption: string | undefined
      paymentMethodText: string | undefined
      paymentMethodNumber: string | null | undefined
    }): void {
      form.paymentMethodTitle = paymentMethodTitle
      form.paymentMethodCaption = paymentMethodCaption
      form.paymentMethodText = paymentMethodText
      form.paymentMethodNumber = paymentMethodNumber
    }

    async function calculatePaymentMethod(): Promise<void> {
      let methods = form.paymentMethods
      let paymentMethod = form.paymentMethod || form.lastPaymentMethod
      if (!paymentMethod && methods) {
        await setPaymentMethodAction(methods[0].value)
      } else {
        if (methods) {
          let match: any = methods.find(
            (method: DeliveryMethodType) => method.value === paymentMethod
          )
          // If a match is found, update state when selected method is not set
          if (match) {
            await setPaymentMethodAction(paymentMethod)
          } else if (match?.value !== form.paymentMethod) {
            await setPaymentMethodAction(methods[0].value)
          }
        }
      }
    }

    function setDeliveryMethod(deliveryMethod: any | null): void {
      form.lastDeliveryMethod = form.deliveryMethod
      form.deliveryMethod = deliveryMethod
    }

    async function setDeliveryMethodAction(deliveryMethod: string): Promise<void> {
      form.deliveryMethodLocation = null // setDeliveryMethodLocation this was a simple action, not sure we need it anymore
      setDeliveryMethod(deliveryMethod)
      updatePaymentAndDeliveryMethods()
    }

    async function calculateDeliveryMethod(): Promise<void> {
      let methods = form.deliveryMethods
      let deliveryMethod = form.deliveryMethod || form.lastDeliveryMethod
      if (!deliveryMethod && methods) {
        setDeliveryMethod(methods[0].value)
      } else {
        if (methods) {
          let match = methods.find((method) => method.value === deliveryMethod)
          // If a match is found, update state when selected method is not set
          if (!match) {
            setDeliveryMethod(methods[0].value)
          } else if (match.value !== form.deliveryMethod) {
            setDeliveryMethod(deliveryMethod)
          }
        }
      }
    }

    async function setPaymentMethodFromDetails(details: TransactionDetails): Promise<FormBase> {
      const i18nStore = useI18nStore()
      const { i18n } = i18nStore

      function showPaymentMethodUnavailable() {
        const { add: showSnackAlert } = useAlert()
        showSnackAlert(i18n.$t('SendAgain.PaymentMethodUnavailableText').value)
        form.nextPath = '/send-money/payment'
        form.paymentMethodId = null
      }
      const paymentsStore = usePaymentsStore()

      // @ts-ignore
      const cardHelper = useCardHelper(i18n.$t)

      const form: FormBase = {
        amountFixed: details.amountFixed,
        currencyFixed: details.currencyFixed,

        countryTo: details.countryTo,
        amountTo: details.amountTo,
        currencyTo: details.currencyTo,
        amountFrom: details.amountFrom,
        currencyFrom: details.currencyFrom,
        paymentMethodId: null,

        paymentMethod: details.paymentMethod.value,
        deliveryMethod: details.deliveryMethod?.enumName,
        //if during send again fixedccy != from currencyFrom then we set  should calc amount from to true
        shouldCalcAmountFrom: details.currencyFixed !== details.currencyFrom,

        deliveryMethodAgentId: details.agentId,
        deliveryMethodLocationId: details.agentLocationId,
        nextPath: '/send-money/summary',
      }

      let paymentMethods = await paymentsStore.getPaymentMethods()
      let selectedPaymentMethod =
        form.paymentMethod === PAYMENT_METHODS.DIRECT_DEBIT
          ? paymentMethods.bankAccounts.find((method: any) => method.id == details.bankAccountId)
          : paymentMethods.cardAccounts.find((method: any) => method.id == details.cardId) //TODO Replace with card settings

      //set payment method information to dispay on the summary screen
      if (form.paymentMethod === PAYMENT_METHODS.DIRECT_DEBIT && selectedPaymentMethod) {
        setPaymentMethodName({
          paymentMethodTitle: selectedPaymentMethod.title,
          paymentMethodCaption: selectedPaymentMethod.maskedAccountNumber,
          paymentMethodText: selectedPaymentMethod.title,
          paymentMethodNumber: selectedPaymentMethod.maskedAccountNumber,
        })
        //shouldn't this be selectedPaymentMethod.id?
        form.paymentMethodId = details.bankAccountId.toString()
      } else if (
        [PAYMENT_METHODS.CREDIT_CARD, PAYMENT_METHODS.DEBIT_CARD].includes(form.paymentMethod) &&
        selectedPaymentMethod
      ) {
        const { isExpired, cardV2Data } = selectedPaymentMethod as CardAccount

        if (!isExpired && cardV2Data?.cardState !== 'Invalid') {
          setPaymentMethodName({
            paymentMethodTitle: cardHelper.createCardTitle({
              maskedAccountNumber: selectedPaymentMethod.maskedAccountNumber,
            }),
            paymentMethodCaption: '',
            paymentMethodText: selectedPaymentMethod.title,
            paymentMethodNumber: selectedPaymentMethod.maskedAccountNumber,
          })
          form.paymentMethodId = selectedPaymentMethod.id ?? null
        } else {
          showPaymentMethodUnavailable()
        }

        //setting payment method after we correctly sorted it
        setPaymentMethod(form.paymentMethod)

        if (selectedPaymentMethod?.panFirstDigits) {
          cardAccountStore.setCardNumberForIdentification(selectedPaymentMethod.panFirstDigits)
        }
      } else if (form.paymentMethod === PAYMENT_METHODS.BANK_TRANSFER) {
        //fetch all the bank
        const bankProviders = await paymentsStore.getBankProviders(details.currencyFrom)

        //set payment method details
        setPaymentMethodName({
          paymentMethodTitle: i18n.$t(details.paymentMethod?.textKey).value,
          paymentMethodCaption: details.bankAccountXe.bankName,
          paymentMethodText: i18n.$t(details.paymentMethod?.textKey).value,
          paymentMethodNumber: details.bankAccountXe.bankName,
        })

        //check if bank exists
        const bankExist = bankProviders.find((el: any) => el.id === details.paymentMethodId)
        //if the bank does not exist, select first bank from the list

        if (bankExist) {
          form.paymentMethodId = bankExist.id.toString()
        } else {
          form.paymentMethodId = bankProviders[0].id.toString()
        }
      } else if (form.paymentMethod === PAYMENT_METHODS.OPEN_BANKING) {
        const { shouldUsePayTo } = useOpenBanking()

        setPaymentMethodName({
          paymentMethodTitle: i18n.$t('SendMoneyAmount.OpenBankingTitle').value,
          paymentMethodCaption: shouldUsePayTo.value
            ? i18n.$t('SendMoneyPayment.OpenBankingDropdownDescriptionPayTo').value // 'Pay instantly through your bank'
            : i18n.$t('SendMoneyPayment.OpenBankingDropdownDescriptionVolt').value, // 'Authorize payment from your bank',
          paymentMethodText: shouldUsePayTo.value
            ? i18n.$t('SendMoneyPayment.OpenBankingDropdownDescriptionPayTo').value // 'Pay instantly through your bank'
            : i18n.$t('SendMoneyPayment.OpenBankingDropdownDescriptionVolt').value, // 'Authorize payment from your bank',
          paymentMethodNumber: null,
        })
      } else if (
        form.paymentMethod !== PAYMENT_METHODS.FUNDS_ON_BALANCE &&
        isValidRecipient.value
      ) {
        showPaymentMethodUnavailable()
      }
      //setting payment method after we correctly sorted it
      setPaymentMethod(form.paymentMethod)

      return form
    }

    async function sendAgain(transaction: TransactionListItem) {

      const analyticsStore = useAnalyticsStore()
      const paymentsStore = usePaymentsStore()

      restoreDefaultForm()
      form.sendAgain = true

      let nextPath = '/send-money/summary'

      let details = activityStore.transactionDetails

      // Fetch transaction details
      if (!details) {
        details = await activityStore.getTransactionDetails(transaction.orderNumber)
      }

      // Create new calculate v2 payload from transaction details
      const newForm = {
        amountFixed: details.amountFixed,
        currencyFixed: details.currencyFixed,

        countryTo: details.countryTo,
        amountTo: details.amountTo,
        currencyTo: details.currencyTo,
        amountFrom: details.amountFrom,
        currencyFrom: details.currencyFrom,

        paymentMethod: details.paymentMethod.value,
        paymentMethodId: details.paymentMethodId,

        deliveryMethod: details.deliveryMethod?.enumName,

        //if during send again fixedccy != from currencyFrom then we set  should calc amount from to true
        shouldCalcAmountFrom: details.currencyFixed !== details.currencyFrom,

        deliveryMethodAgentId: details.agentId,
        deliveryMethodLocationId: details.agentLocationId,
        nextPath: nextPath,
      }

      analyticsStore.track({
        event: SEGMENT_EVENTS.REPEAT_TRANSFER_STARTED,
        traits: {
          paymentMethod: details.paymentMethod?.value,
          paymentProvider: useAuthStore().userProfile.customer.cardPaymentGateway,
          payoutMethod: transaction.deliveryMethod === DeliveryMethod.CashPickup ? 'Cash' : 'Bank',
          location: SEGMENT_LOCATIONS.ACTIVITY,
        },
      })

      const countriesStore = useCountriesStore()
      const offeredCountry = countriesStore.getOfferedCountryByIsoCode(newForm.countryTo)

      // Set calculate form data
      calculatorSelectCountryTo(newForm.countryTo)

      form.shouldCalcAmountFrom = newForm.shouldCalcAmountFrom
      form.amountFrom = newForm.amountFrom
      form.currencyFrom = newForm.currencyFrom
      form.amountTo = newForm.amountTo
      form.currencyTo = newForm.currencyTo

      setDeliveryMethod(newForm.deliveryMethod)

      if (offeredCountry?.value) {
        const { isOpenPayment } = offeredCountry.value
        if (!isOpenPayment) {
          form.deliveryMethodAgent = details.agentTo || null
          form.deliveryMethodLocation = details.agentToLocation || null
        }
      }

      //adding trasfer reason
      form.transferReason = details.transferReason
      form.transferReasonText = details.transferReasonText
      form.paymentMethodId = newForm.paymentMethodId

      let shouldGoToSMF = true

      try {
        // do checks and throw errors if not available so the selections can be shown again
        const checkRecipient = await sendAgainCheckRecipient(details)
        //if succesffully checked the recipient set it into the form.
        if (checkRecipient) {
          const recipient = getRecipient.value
          form.recipient = recipient
        }

        if (
          details.deliveryMethod !== DeliveryMethod.CashPickup &&
          details.deliveryMethod !== DeliveryMethod.MobileWallet &&
          details.paymentMethod.value !== PAYMENT_METHODS.FUNDS_ON_BALANCE
        ) {
          const { isRecipientValid, recipientValidationErrors } = useRecipientValidation()
          try {
            isValidRecipient.value = await isRecipientValid(form.recipient)
            if (!isValidRecipient.value || recipientValidationErrors.value.length > 0) {
              nextPath = '/send-money/recipient/missing'
              await useRecipientsStore().getRecipientsByCountryAndCurrency({
                country: form.countryTo,
                currency: form.currencyTo,
                deliveryMethod: form.deliveryMethod,
              })
            }
          } catch (ex) {
            nextPath = '/send-money/recipient/missing'
          }
        }

        let formUpdated = await setPaymentMethodFromDetails(details)

        // Validate if send again can be performed
        // Call calculate v2
        await calculate({ summary: false, debounceTime: 0 }) // first without summary to get the quote

        if (
          authStore.getIsBalancesEnabled &&
          form.paymentMethod === PAYMENT_METHODS.FUNDS_ON_BALANCE
        ) {
          nextPath = '/quick-transfer'
          shouldGoToSMF = false
        } else if (isValidRecipient.value) {
          Object.assign(form, formUpdated)



          // we need to sort next path
          nextPath = formUpdated.nextPath
          await calculate({ summary: true, debounceTime: 0 }) // then with summary to update the quote

          analyticsStore.track({
            event: SEGMENT_EVENTS.QUOTE_CONFIRMED,
            traits: {
              location: SEGMENT_LOCATIONS.SEND_AGAIN,
              destinationCountry: form.countryTo,
              payoutMethod: getSegmentPayoutMethod(form.deliveryMethod),
              payout_amount: form.amountTo,
              payout_currency: form.currencyTo,
              sendMethod: getSegmentPaymentMethod(form.paymentMethod),
              send_amount: form.amountFrom,
              send_currency: form.currencyFrom,
              sender_country: authStore?.userProfile?.country,
            },
          })
        }
      } catch (error: any) {
        if (
          error.errorCode === 'SendAgainCannotBePerformed' ||
          error.errorCode === 'RecipientNotFound'
        ) {
          analyticsStore.track({
            event: SEGMENT_EVENTS.INVALID_RECIPIENT_POPUP_DISPLAYED,
            traits: {
              quoteResponse: 'QUOTE033',
              screenLocation: 'track',
            },
          })
          shouldShowRecipientNotFoundModal.value = true
          return
        }

        if (error.errorCode === 'PaymentMethodUnavailable') {
          shouldDisplayPaymentMethodUnavailableModal.value = true

          analyticsStore.track({
            event: SEGMENT_EVENTS.REPEAT_PAYMENT_METHOD_NOT_AVAILABLE,
            traits: {
              paymentMethod: paymentsStore.selectedPaymentMethod.paymentMethod,
              paymentProvider: authStore.userProfile.customer.cardPaymentGateway,
              location: SEGMENT_LOCATIONS.ACTIVITY,
            },
          })
          return
        }
        const calculateError = typeof error === 'string' ? new Error(error) : error
        calculateError.errorCode = 'SendAgainCannotBePerformed'
        throw calculateError
      }

      if (shouldGoToSMF) {
        updateDeliveryMethodText()
        form.confirmedRate = form.rate

        analyticsStore.track({
          event: SEGMENT_EVENTS.PAYMENT_METHOD_SELECTED,
          traits: {
            paymentType: getSegmentPaymentMethod(form.paymentMethod),
          },
        })
        sendTransfer({ stage: TRANSFER.STAGES.BASIC })
      }

      return nextPath
    }

    async function sendAgainCheckRecipient(details: TransactionDetails): Promise<boolean> {
      const user = authStore?.user
      const recipientStore = useRecipientsStore()

      try {
        let recipient = null
        if (details.deliveryMethod === DeliveryMethod.CashPickup) {
          recipient = await recipientStore.getCashRecipient(details.recipientId)
        } else if (details.deliveryMethod === DeliveryMethod.MobileWallet) {
          recipient = await recipientStore.getMobileWalletRecipient(details.recipientId)
        } else {
          const { data } = await getRecipientAPI.exec({
            profileId: user?.customer.id,
            recipientId: details.recipientId,
          })
          recipient = data
        }

        if (recipient) {
          form.recipient = recipient
          return true
        }
      } catch (ex) {
        const error = new CustomError('Check recipient failed', 'SendAgainCannotBePerformed')
        throw error
      }
      return false
    }

    function trackshowDuplicateQuoteWarning() {
      const analyticsStore = useAnalyticsStore()
      setShowDuplicateQuoteWarning(true)
      analyticsStore.track({ event: SEGMENT_EVENTS.QUOTE_DUPLICATED_WARNING_DISPLAYED })
    }

    function hideDuplicateQuoteWarning() {
      setShowDuplicateQuoteWarning(false)
    }

    function setIsProofOfAddressPending(value: boolean) {
      isProofOfAddressPending.value = value
    }

    function resetTransferId() {
      form.transferId = null
      transferBasicResponse.value = null
    }

    async function populateQuoteScreenForFirstTimeUser() {
      const resourcesStore = useResourcesStore()
      const recipientsStore = useRecipientsStore()

      let countryTo, tradeCurrency, payoutCurrency
      let defaultCurrencies: any = DefaultCurrencies

      const userProfile = authStore.userProfile
      const combinedCountries = getCombinedCountries.value
      const validCurrenciesFrom = resourcesStore.systemFields.currenciesFrom
      const tradeCurrencyValid = validCurrenciesFrom.find(
        (el: any) => userProfile.expectedTradeCurrency === el.value
      )

      countryTo =
        combinedCountries[0].value === userProfile.country
          ? combinedCountries[1].value
          : combinedCountries[0].value

      if (userProfile.expectedTradeCurrency && tradeCurrencyValid) {
        tradeCurrency = userProfile.expectedTradeCurrency

        if (userProfile.expectedPayoutCurrency) {
          payoutCurrency = userProfile.expectedPayoutCurrency

          if (userProfile.expectedPayoutCurrency === 'EUR') {
            const topDestinations = await resourcesStore.getTopDestinations({
              senderCountry: userProfile.country,
              buyCcy: 'EUR',
            })

            countryTo = topDestinations[0].value
          } else {
            const payoutCountry = await recipientsStore.getCountriesForCurrency(
              userProfile.expectedPayoutCurrency
            )
            countryTo = payoutCountry[0].value
          }
        }
      } else {
        tradeCurrency = userProfile.regionalAccountingCurrency
        payoutCurrency = defaultCurrencies[countryTo][0]
      }

      form.countryTo = countryTo
      form.currencyFrom = tradeCurrency
      form.currencyTo = payoutCurrency
    }

    //// ACTIONS END ///////////

    return {
      //Cristian BR
      changeVoltCheckoutComplete,
      voltCheckoutComplete,
      voltCheckoutStatus,
      hasVoltInfoBeenShown,
      isAUvoltCompleted,
      resetTransferId,
      adyenCheckoutComplete,
      changeAdyenCheckoutComplete,
      resetAdyenProps,

      getCardPaymentAvailable,

      // Milan's BR
      lockPromise,
      setShouldDisplayRateChangedAlert,
      setPaymentMethodType,
      setPaymentMethod,
      setCalculateResult,
      setShowDuplicateQuoteWarning,
      setVerificationStepVisibility,
      clearRefreshQuote,
      resetForm,
      resetVoltProps,
      resetFormOnError,
      setMobileWalletWarning,
      setFailedTransferData,
      updatePaymentAndDeliveryMethods,

      // Bojan's BR
      setActiveStep,
      goToOfflineToOnlineContract,
      setActiveStepPageTitle,
      setAccountIsRestricted,
      setCurrencyToForCountry,
      setPaymentMethodName,
      calculatePaymentMethod,
      setDeliveryMethodAction,
      calculateDeliveryMethod,

      // Anxhela's BR
      transferStage,
      refreshQuote,
      promiseLock,
      hasStoredForm,
      unavailableReasonCodes,
      bankDetailsChangedIsVisible,
      isSurveyOpen,
      orderSteps,
      additionalFields,
      recipientFields,
      pepQuestions,
      paymentReferenceType,
      isO2OContract,
      isRecipientRequired,
      showCPOWarning,
      previousDeliveryMethod,
      CPOunavailableReason,
      isPaymentMethodError,
      showDeliveryDetailsChangedWarning,
      mobileWalletProviders,
      mobileWalletProvider,
      mobileWalletWarning,
      transferBasicResponse,
      licenceCardNumberMissing,
      shouldRefreshQuote,
      unableToSetProvider,
      bypassSendMoneyCreatingRedirection,
      shouldShowRecipientNotFoundModal,
      shouldDisplayPaymentMethodUnavailableModal,
      riaGeneralStopMessage,
      showVerifyNotSuppliedModal,
      quoteExpired,
      isPaymentMethodTypeSelected,
      transfer,
      isAwaitingBalance,
      stepDirectionForward,
      form,
      activeStepIdx,
      accountIsRestricted,
      showDuplicateQuoteWarning,
      shouldDisplayRateChangedAlert,
      shouldCheckForRateChange,
      duplicatedQuoteFound,
      shouldDisplayUsWireTransferWarning,
      activeStep,
      isVerificationPending,
      verificationPlatform,
      verificationApplicationId,
      verificationBaseUrl,
      verificationToken,
      veriffBaseUrl,
      getStepByPath, //NOT USED IN PRJ
      getStepIdx, //NOT USED IN PRJ
      getQuoteId, //NOT USED IN PRJ
      getAvailableSteps,
      getNextAvailableStep,
      getCountryTo,
      getCountryToFromOfferedCountries, //NOT USED IN PRJ
      getCurrencyFrom,
      getCurrencyTo,
      getRate,
      getLastRate,
      getRateFrom,
      getAvailableCurrenciesFrom,
      getCombinedAvailableCurrenciesFrom,
      getCombinedAvailableCurrenciesTo,
      getAvailableCurrenciesTo,
      getFirstFavoriteCurrency,
      getSplitCurrencies,
      getAmountFrom,
      getSendAmountUSD,
      getAmountTo,
      getFee,
      getCalculatedFee,
      getIsIncludedFee,
      getTransferFee,
      getPaymentMethodFee,
      getLiquidityManager,
      getFeeDiscount,
      getPromoBonusAmount,
      getTotalAmount,
      getRecipient,
      getRecipientName,
      getRecipientCityName,
      getRecipientStateName,
      getCountryToText,
      getPaymentMethodText,
      getDeliveryMethodText,
      getDeliveryMethodAgentOpenPayment,
      getCountries,
      getCombinedCountries,
      getConfirmedRate,
      hasSecurityCode,
      didRateChange,
      getAvailableQuotes,
      isCardPayment,
      getQuotesForDeliveryMethod,
      getQuoteForPaymentMethod,
      isDeliveryMethodAvailable,
      isPaymentMethodAvailable,
      getQuoteCreatedTraits,
      clearCardData,
      setConfirmedRate,
      setPaymentMethodAction,
      resetTransferInformation,
      setMobileWalletProvider,
      setMobileWalletProviders,
      setFilledAdditionalField,
      setFilledAdditionalFields,
      setAvailableCurrencies,
      validatePaymentReferenceNumber,
      setShowCPOWarning,
      transactionCreatedSegmentEvent,
      uploadDocument,
      selectCard,
      continueOrder,
      setForm,
      updateDeliveryMethodText,
      calculatorGetRecipients,
      initCalculatorCurrencyFrom,
      calculatorSelectCountryTo,
      processMobileWalletSteps,
      resetAdditionalFields,
      initCalculator,
      openSendMoney,
      initCalculatorCountry,
      sendOnfido,
      processMobileWalletOrder,
      processOrderSteps,
      prepareOrderSteps,
      processOrder,
      createBlackbox,
      addRecipientO2OTransfer,
      sendTransfer,
      goToNextStep,
      goToAddPaymentMethod,
      submitAdditionalDetails,
      orderCreate,
      setPaymentMethodFromDetails,
      sendAgain,
      sendAgainCheckRecipient,
      trackshowDuplicateQuoteWarning,
      hideDuplicateQuoteWarning,
      restoreDefaultForm,
      calculate,
      changeVerificationVisibility,
      verifyIdentity,
      isProofOfAddressPending,
      setIsProofOfAddressPending,
      availableCurrencies,
      resetConfirmedRate,
      steps,
      getDefaultAmountFrom,
      adyenData,
      isVerifiedFromConfirmStage,
      shouldNotCalculate,
      limitExceededDialogOpened,
      isSendAgainPaymentMethodAvailable,
      populateQuoteScreenForFirstTimeUser,

      // basic edd
      isSubIndustrySelected,
    }
  },
  {
    persist: {
      storage: sessionStorage,
    },
  }
)
