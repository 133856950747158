import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  (payload, config) => {
    return {
      ...config,
      method: 'PUT',
      url: `transfer/profile`,
      data: payload,
    }
  },
  async (response) => {
    let savedSuccessfully = response.data.data.verified === 'Basic'
    return {
      savedSuccessfully,
      data: response.data,
    }
  }
)
