<template>
  <div class="config-error-modal">
    <AppModal :title="title" v-model="shouldShow">
      <template #header>
        <AppModalHeader>
          <template #right>
            <XeBackButton
              class="mb-24"
              analytics-name="change-email-close"
              icon="x"
              @click="closeModal"
            />
          </template>
          <img
            class="config-error-icon"
            src="@galileo/assets/images/iconWarningCircled.svg"
            alt="Unsupported feature"
          />
          <h1>{{ $t('ConfigErrorModal.Title').value }}</h1>
        </AppModalHeader>
      </template>
      <template #default>
        <div class="config-error-modal-description">
          {{ $t('ConfigErrorModal.Description').value }}
        </div>
      </template>
      <template #footer>
        <AppModalFooter class="config-modal-footer">
          <AppButton theme="primary" type="button" @click="onHelpCenterClick">{{
            $t('ConfigErrorModal.HelpCentreButton').value
          }}</AppButton>
          <br />
          <AppButton class="mt-2" theme="text" type="button" @click="closeModal">{{
            $t('ConfigErrorModal.CloseButton').value
          }}</AppButton>
        </AppModalFooter>
      </template>
    </AppModal>
  </div>
</template>

<script>
import { ref, onBeforeMount, computed } from '@vue/composition-api'
import {
  useSendMoneyStore,
  useI18nStore,
  useAnalyticsStore,
  useAuthStore,
  useAppStore,
} from '@galileo/stores'
import { AppModalFooter, AppModalHeader, AppModal, AppButton } from '@oen.web.vue2/ui'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'
import XeBackButton from '@galileo/components/XeBackButton/XeBackButton'

export default {
  name: 'ConfigErrorModal',
  components: {
    AppModalFooter,
    AppModalHeader,
    AppModal,
    AppButton,
    XeBackButton,
  },
  setup() {
    const { $t } = useI18nStore()
    const analyticsStore = useAnalyticsStore()
    const authStore = useAuthStore()
    const appStore = useAppStore()

    const title = ref($t('ConfigErrorModal.Title').value)

    const onHelpCenterClick = () => {
      appStore.openHelpDesk({
        loadingRef: ref(false),
      })
    }

    const closeModal = () => {
      appStore.hideConfigIssueModal()
    }

    const shouldShow = computed(() => {
      let visible = appStore.shouldShowConfigModal
      if (visible && authStore.user.customer) {
        analyticsStore.track({
          event: SEGMENT_EVENTS.CONFIG_ISSUE_DETECTED,
          traits: {
            TbuRegion: authStore.user.customer.region,
            TbuId: authStore.user.customer.businessUnitId,
          },
        })
      }
      return visible
    })

    return {
      $t,
      shouldShow,
      title,
      onHelpCenterClick,
      closeModal,
    }
  },
}
</script>

<style scoped>
.config-modal-footer {
  display: flex;
  flex-direction: column-reverse !important;
}
.config-error-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.config-error-modal-description {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .card-header .card-header-title {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.config-error-icon {
  align-content: center;
  width: 6rem;
  height: 6rem;
}
</style>
