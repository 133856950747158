import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

class MobileWalletProvider {
  constructor(data) {
    this.amountMax = data.amountMax
    this.amountMin = data.amountMin
    this.country = data.country
    this.currency = data.currency
    this.id = data.id
    this.name = data.name
    this.partnerId = data.partnerId
    this.logoURL = `https://public.riamoneytransfer.com/agent/logo/${data.partnerId}?useMobile=true&agentToLocId=${data.id}&deliveryMethod=34`
    this.image = null
    this.loadImage()
  }

  async loadImage() {
    // load the blob of image to avoid flickering when showing the image
    // also images are returning no-cache so we would have to load them everytime again
    try {
      const response = await fetch(this.logoURL, { responseType: 'blob' })
      const imageBlob = await response.blob()
      this.image = URL.createObjectURL(imageBlob);
    } catch (ex) {
      this.image = this.logoURL
    }
  }
}

export default new APIHandler(
  API,
  ({ country, currency, amount }, config) => {
    return {
      ...config,
      method: 'GET',
      url: `destinations/mobilewallets?countryCode=${country}&currencyCode=${currency}&amount=${amount}`,
    }
  },
  (response) => {
    return {
      ...response,
      data: response.data.map((provider) => {
        return new MobileWalletProvider(provider)
      }),
    }
  }
)
