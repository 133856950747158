











































































































































import { computed, defineComponent, nextTick, reactive, ref, watch, watchEffect } from '@vue/composition-api'
import {
  useValidation,
  usePromiseLazy,
} from 'vue-composable'
import { required, email as emailValidator } from '@vuelidate/validators'

import {
  AppModalFooter,
  AppButton,
  AppIcon,
  AppInputText,
  AppInputDate,
  AppInputCheckbox,
} from '@oen.web.vue2/ui'
import XeAddressSearch from '@galileo/components/XeAddressSearch/XeAddressSearch.vue'
import KYCRefreshCorporateAddressCard from '@galileo/components/KYCRefreshCorporate/KYCRefreshCorporateAddressCard.vue'
import KYCRefreshCorporateCard from './KYCRefreshCorporateCard.vue'
import IconLocation from '@oen.web.vue2/icons/src/components/carbon/IconLocation.vue'
import IconEdit from '@oen.web.vue2/icons/src/components/carbon/IconEdit.vue'

import {
  useI18nStore,
  useKYCRefreshCorporateStore,
  useResourcesStore,
  useProfileStore,
  useCorporateStore,
  useAnalyticsStore,
} from '@galileo/stores'
import { getOneLineAddress, getOneLineAddressFromAddressDetails, parseAddressDetails } from '@galileo/composables/useAddress/useCorporateAddress'
import getValueRequiredValidation from '@galileo/utilities/validations.utility'
import { storeToRefs } from 'pinia'
import { KycRefreshCorporateStepEnum } from '@galileo/stores/kycRefreshCorporate'
import { isPersonUnder120, isPersonOver18, isValidDate, noFutureDate } from '@galileo/utilities/validators.utility'
import { AddressDetailsInterface } from '@galileo/models/Corporate/interfaces/Address'
import { PersonnelItemEnhanced } from '@galileo/models/Corporate/interfaces/CorpReg'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'

export default defineComponent({
  name: 'KYCRefreshCorporateUBOForm',
  components: {
    AppModalFooter,
    AppInputText,
    AppButton,
    AppIcon,
    AppInputDate,
    AppInputCheckbox,
    KYCRefreshCorporateCard,
    KYCRefreshCorporateAddressCard,
    XeAddressSearch,
    IconLocation,
    IconEdit,
  },
  setup(_, { emit }) {
    const { $t } = useI18nStore()
    const kycRefreshCorporateStore = useKYCRefreshCorporateStore()
    const corporateStore = useCorporateStore()
    const resourcesStore = useResourcesStore()
    const profileStore = useProfileStore()
    const analyticsStore = useAnalyticsStore()

    const countryCode = computed(() => corporateStore.clientUserDetails ? corporateStore.clientUserDetails.registrationAddress.country : '')
    const isAPAC = computed(() => ['AU', 'NZ'].includes(countryCode.value))

    // Form fields
    const loadingForm = ref(false)
    const firstName = ref('')
    const middleName = ref('')
    const lastName = ref('')
    const dob = ref('')
    const email = ref('')
    const occupation = ref('')
    const isUbo = ref(false)
    const isDirector = ref(false)
    const isOther = ref(false)
    const role = ref('')
    const addressStr = ref()
    const showMiddleName = computed(() => isAPAC.value)
    const showEmail = computed(() => !isAPAC.value)
    const showOccupation = computed(() => countryCode.value === 'CA')
    const dateFormat = computed(() => {
      switch (countryCode.value) {
        case 'US':
          return 'MM/DD/YYYY'
        case 'CA':
          return 'YYYY/MM/DD'
        default:
          return 'DD/MM/YYYY'
      }
    })
    const dobKey = ref(0)
    const resetFields = () => {
      firstName.value = ''
      middleName.value = ''
      lastName.value = ''
      dob.value = ''
      email.value = ''
      occupation.value = ''
      isUbo.value = false
      isDirector.value = false
      isOther.value = false
      role.value = ''
      addressStr.value = null
      dobKey.value = (dobKey.value + 1) % 2 // workaround to make date input refresh and display right val when set programmatically
      validation.$reset()
    }
    watch(isOther, () => {
      if (isOther.value) {
        isDirector.value = false
        isUbo.value = false
      }
    })
    watchEffect(() => {
      if (isUbo.value || isDirector.value) {
        isOther.value = false
        role.value = ''
      }
    })

    const selectedUbo = computed<PersonnelItemEnhanced | undefined>(() => kycRefreshCorporateStore.selectedUbo)
    const isUboSignatory = computed(() => selectedUbo.value ? selectedUbo.value.isSignatory : false)
    watch(selectedUbo, () => {
      loadingForm.value = true
      if (selectedUbo.value) {
        firstName.value = selectedUbo.value.firstName || ''
        middleName.value = selectedUbo.value.middleName || ''
        lastName.value = selectedUbo.value.lastName || ''
        dob.value = selectedUbo.value.dob || ''
        email.value = selectedUbo.value.email || ''
        isUbo.value = selectedUbo.value.isUbo || false
        isDirector.value = selectedUbo.value.isDirector || false
        isOther.value = !!selectedUbo.value.role || false
        role.value = selectedUbo.value.role || ''
        addressStr.value = selectedUbo.value.address || ''
        occupation.value = selectedUbo.value.occupation || ''
        // Checking the form for invalid fields
        setTimeout(() => {
          validation.$touch()
        }, 250)
        
      } else {
        resetFields()
      }
      validation.$reset()
      // Fixes issue with AppInputDate
      nextTick(() => {
        loadingForm.value = false
      })
    })

    // validation
    const valueRequired = getValueRequiredValidation()
    const { getFirstNameRegex, getMiddleNameRegex, getLastNameRegex } = storeToRefs(profileStore)
    const validation = reactive(useValidation({
      roles: {
        $value: isOther,
        validRole: {
          $validator: () => {
            return isUbo.value || isDirector.value || isOther.value
          },
          $message: $t('ComponentKYCRefreshCorporateUBOForm.RoleErrorMsg').value,
        }
      },
      otherRole: {
        $value: role,
        valueRequired: {
          $validator: (v: string) => {
            return isOther.value ? required.$validator(v) : true
          },
          $message: $t('PageSendMoneyAmount.AmountToRequired').value,
        },
        validRole: {
          $validator: (v: string) => {
            return !v ? true : getFirstNameRegex.value.test(v)
          },
          $message: $t('ComponentKYCRefreshCorporateUBOForm.InvalidValues').value,
        }
      },
      firstName: {
        $value: firstName,
        valueRequired,
        validFirstName: {
          $validator: (v: string) => {
            return !v ? true : getFirstNameRegex.value.test(v)
          },
          $message: $t('ComponentKYCRefreshCorporateUBOForm.InvalidValues').value,
        },
      },
      middleName: {
        $value: middleName,
        validMiddleName: {
          $validator: (v: string) => {
            return !showMiddleName.value || !middleName.value ? true : getMiddleNameRegex.value.test(v)
          },
          $message: $t('ComponentKYCRefreshCorporateUBOForm.InvalidValues').value,
        },
      },
      lastName: {
        $value: lastName,
        valueRequired,
        validLastName: {
          $validator: (v: string) => {
            return !v ? true : getLastNameRegex.value.test(v)
          },
          $message: $t('ComponentKYCRefreshCorporateUBOForm.InvalidValues').value,
        },
      },
      dob: {
        $value: dob,
        isValidDate: {
          $validator: isValidDate,
          $message: $t('ComponentKYCRefreshCorporateUBOForm.InvalidDate').value,
        },
        noFutureDate: {
          $validator: noFutureDate,
          $message: $t('ComponentKYCRefreshCorporateUBOForm.NoFutureDate').value,
        },
        isPersonUnder120: {
          $validator: isPersonUnder120,
          $message: $t('ComponentKYCRefreshCorporateUBOForm.Under120').value,
        },
        isPersonOver18: {
          $validator: isPersonOver18,
          $message: $t('ComponentKYCRefreshCorporateUBOForm.AtLeast18').value,
        },
      },
      email: {
        $value: email,
        valueRequired: {
          $validator: (v: string) => {
            return showEmail.value && isUboSignatory.value ? required.$validator(v) : true
          },
          $message: $t('PageSendMoneyAmount.AmountToRequired').value,
        },
        validEmail: {
          $validator: (v: string) => {
            return emailValidator.$validator(v)
          },
          $message: $t('ComponentKYCRefreshCorporateUBOForm.InvalidEmail').value,
        },
      },
      occupation: {
        $value: occupation,
        valueRequired: {
          $validator: (v: string) => {
            return showOccupation.value ? required.$validator(v) : true
          },
          $message: $t('PageSendMoneyAmount.AmountToRequired').value,
        },
        occupationValid: {
          $validator: (v: string) => {
            return !v ? true : getFirstNameRegex.value.test(v)
          },
          $message: $t('ComponentKYCRefreshCorporateUBOForm.InvalidOccupation').value,
        },
      },
    }))

    // Submission
    const canSubmit = computed(() => {
      return !validation.$anyInvalid && !!addressStr.value
    })
    const submitChanges = () => {
      let uboEditedFields = ''
      const uboData: Partial<PersonnelItemEnhanced> = {
        firstName: firstName.value,
        middleName: middleName.value,
        lastName: lastName.value,
        dob: dob.value,
        email: email.value,
        isUbo: isUbo.value,
        isDirector: isDirector.value,
        isOther: isOther.value,
        role: role.value, // ! Field "role" does not exist in Dynamo (2025-02-10). We have to save it as occupation, except for CA (MT-44803)
        address: addressStr.value,
        occupation: occupation.value
      }
      if (selectedUbo.value) {
        // Get edited fields before update data from store
        uboEditedFields = kycRefreshCorporateStore.getModifiedUboKeys(selectedUbo.value, uboData)
        kycRefreshCorporateStore.updateUbo(uboData)
      } else {
        kycRefreshCorporateStore.createUbo(uboData)
      }
      // Trigger "KYC Refresh Individual Saved" event
      analyticsStore.track({
        event: SEGMENT_EVENTS.KYC_REFRESH_INDIVIDUAL_INDIVIDUAL_SAVED,
        traits: {
          fieldEdited: uboEditedFields
        }
      })
      resetFields()
      kycRefreshCorporateStore.goToStep(KycRefreshCorporateStepEnum.UBO_LIST)
    }

    // address search
    const searchPromiseLazy = reactive(
      usePromiseLazy(({ query, selectedId = null }) => {
        let result = resourcesStore.getAddressSearchResult({
          searchTerm: query,
          searchContext: selectedId,
          country: countryCode.value,
        })
        return result
      })
    )
    const onSelectAddress = (address: AddressDetailsInterface) => {
      addressStr.value = getOneLineAddress(parseAddressDetails(address), address.countryCode)
    }
    const getAddressDetailsPromiseLazy = reactive(
      usePromiseLazy((selectedId) => {
        let result = resourcesStore.getAddressDetails(selectedId)
        return result
      })
    )
    const changeAddress = () => {
      addressStr.value = null
    }

    return {
      $t,
      emit,
      countryCode,
      loadingForm,
      addressStr,
      changeAddress,
      getOneLineAddressFromAddressDetails,
      firstName,
      middleName,
      lastName,
      dateFormat,
      dob,
      dobKey,
      email,
      occupation,
      isUbo,
      isDirector,
      isOther,
      role,
      showMiddleName,
      isUboSignatory,
      showEmail,
      showOccupation,
      validation,
      KycRefreshCorporateStepEnum,
      selectedUbo,
      goToStep: kycRefreshCorporateStore.goToStep,
      searchPromiseLazy,
      getAddressDetailsPromiseLazy,
      onSelectAddress,
      submitChanges,
      canSubmit
    }
  },
})
