import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  ({ jwt, trxRef }, config) => {
    return {
      ...config,
      method: 'POST',
      url: `cardsV2/card-authentications/${trxRef}`,
      data: {
        payerAuthResponseJwt: jwt,
      },
    }
  },
  (response) => {
    // empty response !
    return {
      ...response,
      data: response.data.data,
    }
  }
)
