import { each } from 'lodash-es'
import { computed, ref } from '@vue/composition-api'
import { defineStore } from 'pinia'
import axios from 'axios'

const configKeys = require('./configKeys')

import { useThemeStore } from '@galileo/stores'

import StringObject from '@galileo/types/StringObject'

export const useEnvStore = defineStore('env', () => {
  const useGalileoLogin = ref(false)

  const env = ref<StringObject>({})

  const getConfigKeys = computed((): StringObject => {
    return configKeys.default
  })

  // const getConfigKey = computed(() => (key: string): string => configKeys[key])

  const getVariable = computed(() => (key: string): string => {
    const value = env.value[key]
    if (value === undefined || value === null) {
      // eslint-disable-next-line no-console
      console.warn('ENV: Variable is not registed in app: ' + key)
    }
    return env.value[key]
  })

  function setVariables(variables: StringObject): void {
    // Loop through the required config keys, and ensure that each one is being accounted for
    each(configKeys.default, (_: string, key: string) => {
      let match = variables[key]
      if (match === undefined || match === null) {
        // eslint-disable-next-line no-console
        console.error('ENV: Variable not defined for this environment: ' + key)
      } else {
        env.value[key] = match
      }
    })
  }

  async function fetchVariables(): Promise<void> {
    try {
      // new way in XE
      const { data } = await axios.get(`/env/config.json`)
      setVariables(data)
    } catch (ex) {
      // old way in RIA
      const env = process.env.VUE_APP_ENV || process.env.NODE_ENV
      const { data } = await axios.get(`/env/${env}.json`)
      setVariables(data)
    }
  }

  const isProduction = computed((): boolean => {
    const environment = getVariable.value('VUE_APP_ENV')
    return environment === 'production'
  })

  const isDevelopment = computed((): boolean => {
    const environment = getVariable.value('VUE_APP_ENV')
    return environment === 'development'
  })

  async function init() {
    await fetchVariables()
  }
  function setUseGalileoLogin(value = true) {
    useGalileoLogin.value = value
  }

  //VUE_APP_URL_TRANSFER
  const appUrlTransfer = computed((): string => {
    const themeStore = useThemeStore()
    let urlTransfer = env.value.VUE_APP_URL_TRANSFER

    if (themeStore.isBranded) {
      const brand = themeStore.getThemeName
      const envKeyValue = `VUE_APP_URL_TRANSFER_${brand.toUpperCase()}`
      urlTransfer = env.value[envKeyValue]
    }
    return urlTransfer
  })

  const appLaunchpadEndpoint = computed((): string => {
    const themeStore = useThemeStore()
    let launchpadEndpoint = env.value.VUE_APP_ENDPOINTS_LAUNCHPADAPI

    if (themeStore.isBranded) {
      const brand = themeStore.getThemeName
      const envKeyValue = `VUE_APP_ENDPOINTS_LAUNCHPADAPI_${brand.toUpperCase()}`
      launchpadEndpoint = env.value[envKeyValue]
    }
    return launchpadEndpoint
  })

  const cacheLaunchpadEndpoint = computed((): string => {
    let launchpadCacheEndpoint = env.value.VUE_APP_ENDPOINTS_LAUNCHPADCACHE
    return launchpadCacheEndpoint
  })

  //VUE_APP_ACCOUNT_SITE
  const appAccountSite = computed((): string => {
    const themeStore = useThemeStore()
    let accountSite = env.value.VUE_APP_ACCOUNT_SITE
    if (themeStore.isBranded) {
      const brand = themeStore.getThemeName
      accountSite = env.value[`VUE_APP_ACCOUNT_SITE_${brand.toUpperCase()}`]
    }
    return accountSite
  })

  return {
    setUseGalileoLogin,
    useGalileoLogin,
    env: env.value,
    getConfigKeys,
    getVariable,
    setVariables,
    fetchVariables,
    init,
    isProduction,
    isDevelopment,
    appUrlTransfer,
    appAccountSite,
    appLaunchpadEndpoint,
    cacheLaunchpadEndpoint
  }
})
