export const sepaMapping = {
  '!': '.',
  '"': '',
  '#': '.',
  $: '.',
  '%': '.',
  '&': '',
  "'": '',
  '*': '.',
  ';': ',',
  '<': '',
  '=': '.',
  '>': '',
  '@': '.',
  '[': '(',
  '\\': '/',
  ']': ')',
  '^': '.',
  _: '-',
  '`': "'",
  '{': '(',
  '|': '/',
  '}': ')',
  '~': '-',
  '\u00a0': ' ',
  '\u00a1': '.',
  '\u00a2': '.',
  '\u00a3': '.',
  '\u00a7': '.',
  '\u00a9': '.',
  '\u00b0': '.',
  '\u00bf': '?',
  '\u00c0': 'A',
  '\u00c1': 'A',
  '\u00c2': 'A',
  '\u00c3': 'A',
  '\u00c4': 'A',
  '\u00c5': 'A',
  '\u00c6': 'A',
  '\u00c7': 'C',
  '\u00c8': 'E',
  '\u00c9': 'E',
  '\u00ca': 'E',
  '\u00cb': 'E',
  '\u00cc': 'I',
  '\u00cd': 'I',
  '\u00ce': 'I',
  '\u00cf': 'I',
  '\u00d0': 'D',
  '\u00d1': 'N',
  '\u00d2': 'O',
  '\u00d3': 'O',
  '\u00d4': 'O',
  '\u00d5': 'O',
  '\u00d6': 'O',
  '\u00d8': 'O',
  '\u00d9': 'U',
  '\u00da': 'U',
  '\u00db': 'U',
  '\u00dc': 'U',
  '\u00dd': 'Y',
  '\u00de': 'T',
  '\u00df': 's',
  '\u00e0': 'a',
  '\u00e1': 'a',
  '\u00e2': 'a',
  '\u00e3': 'a',
  '\u00e4': 'a',
  '\u00e5': 'a',
  '\u00e6': 'a',
  '\u00e7': 'c',
  '\u00e8': 'e',
  '\u00e9': 'e',
  '\u00ea': 'e',
  '\u00eb': 'e',
  '\u00ec': 'i',
  '\u00ed': 'i',
  '\u00ee': 'i',
  '\u00ef': 'i',
  '\u00f0': 'd',
  '\u00f1': 'n',
  '\u00f2': 'o',
  '\u00f3': 'o',
  '\u00f4': 'o',
  '\u00f5': 'o',
  '\u00f6': 'o',
  '\u00f8': 'o',
  '\u00f9': 'u',
  '\u00fa': 'u',
  '\u00fb': 'u',
  '\u00fc': 'u',
  '\u00fd': 'y',
  '\u00fe': 't',
  '\u00ff': 'y',
  '\u0100': 'A',
  '\u0101': 'a',
  '\u0102': 'A',
  '\u0103': 'a',
  '\u0104': 'A',
  '\u0105': 'a',
  '\u0106': 'C',
  '\u0107': 'c',
  '\u010a': 'C',
  '\u010b': 'c',
  '\u010c': 'C',
  '\u010d': 'c',
  '\u010e': 'D',
  '\u010f': 'd',
  '\u0110': 'D',
  '\u0111': 'd',
  '\u0112': 'E',
  '\u0113': 'e',
  '\u0116': 'E',
  '\u0117': 'e',
  '\u0118': 'E',
  '\u0119': 'e',
  '\u011a': 'E',
  '\u011b': 'e',
  '\u011c': 'G',
  '\u011d': 'g',
  '\u011e': 'G',
  '\u011f': 'g',
  '\u0122': 'G',
  '\u0123': 'g',
  '\u012a': 'I',
  '\u012b': 'i',
  '\u012e': 'I',
  '\u012f': 'i',
  '\u0130': 'I',
  '\u0131': 'i',
  '\u0132': 'I',
  '\u0133': 'i',
  '\u0136': 'K',
  '\u0137': 'k',
  '\u0139': 'L',
  '\u013a': 'l',
  '\u013b': 'L',
  '\u013c': 'l',
  '\u013d': 'L',
  ' \u013e': 'l',
  '\u0141': 'L',
  '\u0142': 'l',
  '\u0143': 'N',
  '\u0144': 'n',
  '\u0145': 'N',
  '\u0146': 'n',
  '\u0147': 'N',
  '\u0148': 'n',
  '\u0150': 'O',
  '\u0151': 'o',
  '\u0152': 'O',
  '\u0153': 'o',
  '\u0154': 'R',
  '\u0155': 'r',
  '\u0156': 'R',
  '\u0157': 'r',
  '\u0158': 'R',
  '\u0159': 'r',
  '\u015a': 'S',
  '\u015b': 's',
  '\u015e': 'S',
  '\u015f': 's',
  '\u0160': 'S',
  '\u0161': 's',
  '\u0162': 'T',
  '\u0163': 't',
  '\u0164': 'T',
  '\u0165': 't',
  '\u016a': 'U',
  '\u016b': 'u',
  '\u016e': 'U',
  '\u016f': 'u',
  '\u0170': 'U',
  '\u0171': 'u',
  '\u0172': 'U',
  '\u0173': 'u',
  '\u0178': 'Y',
  '\u0179': 'Z',
  '\u017a': 'z',
  '\u017b': 'Z',
  '\u017c': 'z',
  '\u017d': 'Z',
  '\u017e': 'z',
  '\u0218': 'S',
  '\u0219': 's',
  '\u021a': 'T',
  '\u021b': 't',
  '\u0386': 'A',
  '\u0388': 'E',
  '\u0389': 'I',
  '\u038a': 'I',
  '\u038c': 'O',
  '\u038e': 'Y',
  '\u038f': 'O',
  '\u03aa': 'I',
  '\u0391': 'A',
  '\u0392': 'V',
  '\u0393': 'G',
  '\u0394': 'D',
  '\u0395': 'E',
  '\u0396': 'Z',
  '\u0397': 'I',
  '\u0398': 'TH',
  '\u0399': 'I',
  '\u039a': 'K',
  '\u039b': 'L',
  '\u039c': 'M',
  '\u039d': 'N',
  '\u039e': 'X',
  '\u039f': 'O',
  '\u03a0': 'P',
  '\u03a1': 'R',
  '\u03a3': 'S',
  '\u03a4': 'T',
  '\u03a5': 'Y',
  '\u03a6': 'F',
  '\u03a7': 'CH',
  '\u03a8': 'PS',
  '\u03a9': 'O',
  '\u03ab': 'Y',
  '\u03ac': 'a',
  '\u03ad': 'e',
  '\u03ae': 'i',
  '\u03af': 'i',
  '\u03b0': 'y',
  '\u03b1': 'a',
  '\u03b2': 'v',
  '\u03b3': 'g',
  '\u03b4': 'd',
  '\u03b5': 'e',
  '\u03b6': 'z',
  '\u03b7': 'i',
  '\u03b8': 'th',
  '\u03b9': 'i',
  '\u03ba': 'k',
  '\u03bb': 'l',
  '\u03bc': 'm',
  '\u03bd': 'n',
  '\u03be': 'x',
  '\u03bf': 'o',
  '\u03c0': 'p',
  '\u03c1': 'r',
  '\u03c2': 's',
  '\u03c3': 's',
  '\u03c4': 't',
  '\u03c5': 'y',
  '\u03c6': 'f',
  '\u03c7': 'ch',
  '\u03c8': 'ps',
  '\u03c9': 'o',
  '\u03ca': 'i',
  '\u03cb': 'y',
  '\u03cc': 'o',
  '\u03cd': 'y',
  '\u03ce': 'o',
  '\u0410': 'A',
  '\u0411': 'B',
  '\u0412': 'V',
  '\u0413': 'G',
  '\u0414': 'D',
  '\u0415': 'E',
  '\u0416': 'ZH',
  '\u0417': 'Z',
  '\u0418': 'I',
  '\u0419': 'Y',
  '\u041a': 'K',
  '\u041b': 'L',
  '\u041c': 'M',
  '\u041d': 'N',
  '\u041e': 'O',
  '\u041f': 'P',
  '\u0420': 'R',
  '\u0421': 'S',
  '\u0422': 'T',
  '\u0423': 'U',
  '\u0424': 'F',
  '\u0425': 'H',
  '\u0426': 'TS',
  '\u0427': 'CH',
  '\u0428': 'SH',
  '\u0429': 'SHT',
  '\u042a': 'A',
  '\u042c': 'Y',
  '\u042e': 'YU',
  '\u042f': 'YA',
  '\u0430': 'a',
  '\u0431': 'b',
  '\u0432': 'v',
  '\u0433': 'g',
  '\u0434': 'd',
  '\u0435': 'e',
  '\u0436': 'zh',
  '\u0437': 'z',
  '\u0438': 'i',
  '\u0439': 'y',
  '\u043a': 'k',
  '\u043b': 'l',
  '\u043c': 'm',
  '\u043d': 'n',
  '\u043e': 'o',
  '\u043f': 'p',
  '\u0440': 'r',
  '\u0441': 's',
  '\u0442': 't',
  '\u0443': 'u',
  '\u0444': 'f',
  '\u0445': 'h',
  '\u0446': 'ts',
  '\u0447': 'ch',
  '\u0448': 'sh',
  '\u0449': 'sht',
  '\u044a': 'a',
  '\u044c': 'y',
  '\u044e': 'yu',
  '\u044f': 'ya',
  '\u20ac': 'E',
}
