/**
 *
 * This code defines a JavaScript function named useFavorites. It takes in two arguments: items and favorites.
 * The function returns a new array that consists of the following elements:
 *
 *  The elements of the favorites array, but with an added property separator on each object. The value of separator is true if the current object is the last object in the favorites array, and false otherwise.
 *
 *  The elements of the items array that are not present in the favorites array, as determined by checking if the value property of the item is equal to any of the value properties of the objects in the favorites array.
 *
 * The returned array is the concatenation of the two arrays obtained from the above steps, created using the spread operator (...).
 */

export function useFavorites(items, favorites) {
  return [
    // Map favourites, add separator to last
    ...favorites.map((v, k, a) => {
      for (let item of a) {
        item.sameCurrencySupported = items.find((i) => i.value === item.value)?.sameCurrencySupported
      }
      return {
        ...v,
        separator: k === a.length - 1,
      }
    }),
    ...items.reduce((acc, item) => {
      if (!favorites.find((favorites) => favorites.value === item.value)) {
        acc.push(item)
      }
      return acc
    }, []),
  ]
}
