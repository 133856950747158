import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  ({ jwt, trxRef, profileId }, config) => {
    return {
      ...config,
      method: 'PUT',
      url: 'cards/card-transactions/complete-payer-auth',
      data: {
        payerAuthFields: {
          JWT: jwt,
        },
        transactionReference: trxRef,
        userId: profileId,
      },
    }
  },
  (response) => {
    // empty response !
    return {
      ...response,
    }
  }
)
