export default class Field {
  constructor({
    id,
    validations,
    label,
    dependsOn,
    hasAsyncValues,
    options,
    parent,
    readonly,
    showOnParentValues,
    type,
    value,
    hidden = false,
  }) {
    this.id = id
    this.validations = validations
    this.label = label
    this.dependsOn = dependsOn
    this.hasAsyncValues = hasAsyncValues
    this.options = options
    this.parent = parent
    this.readonly = readonly
    this.showOnParentValues = showOnParentValues
    this.type = type
    this.value = value
    this.hidden = hidden
    this.getAsyncValues = null
  }
}
