import { reactive } from '@vue/composition-api'

import useFieldRules from './useFieldRules'
import { useFieldComponentHelper, useFieldPropsHelper } from '@galileo/composables/useFormHelpers'

import { DynamicFormField } from '@oen.web.vue2/ui'

export default (recipientForm, isBusinessAccount = false) => {
  const groupFunctionGenerator = {
    GRP_RECIPIENT_NAME_DETAILS(formField) {
      recipientForm.groups.nameFields.push(formField)
    },
    GRP_RECIPIENT_DETAILS(formField) {
      recipientForm.groups.addressFields.push(formField)
    },
    GRP_BANK_DETAILS(formField) {
      recipientForm.groups.bankFields.push(formField)
    },
    GRP_ADDITIONAL_DETAILS(formField) {
      recipientForm.additionalFields.push(formField)
    },
  }

  const createDynamicFormField = (field, defaultValues) => {
    let disabled = !!field.readonly
    let defaultValue = defaultValues?.[field.id] ? defaultValues[field.id] : field.value ?? ''

    const dynamicFormField = new DynamicFormField({
      field,
      value: defaultValue,
      hidden: field.hidden,
      disabled: disabled,
      props: useFieldPropsHelper(field),
      component: useFieldComponentHelper(field.type),
    })

    return reactive(dynamicFormField)
  }

  const getFormField = (field) => {
    const fieldName = field.id

    const { fieldRules } = useFieldRules(recipientForm, isBusinessAccount)
    const pivotField = fieldRules[fieldName]

    if (pivotField) {
      if (typeof pivotField === 'function') {
        field = Object.assign(field, pivotField(field))
      } else {
        field = Object.assign(field, pivotField)
      }
    }

    if (field.forceStop) {
      return null
    }

    if (field.parent && field.parent.id === 'NAT_CLEAR_CODE') {
      field.useEnrichment = true
    }

    if (recipientForm.values[fieldName]) {
      field.value = recipientForm.values[fieldName]
    }

    const formField = createDynamicFormField(field, recipientForm.defaultValues)
    // if field is readonly even tho it is required we avoid to validate it.
    // not to show the error, but will add more strict validation for other fields (through exception in enrichBankFieldListnerHandler, in RecipientForm.js)
    if (field.id === 'RECIPIENT_PHONE') {
      formField.component.props.showSelectedFlag.default = true
    }

    formField.validationDefinition = formField.validation

    if (field.readonly) {
      //empty placeholder for readonly fields
      formField.props.placeholder = ''
    }

    if (field.hint) {
      formField.props.helperText = field.hint
    }

    if ((field.mandatory || field.optional) && !field.hidden) {
      const addFormFieldToGroup = groupFunctionGenerator[field.group]

      if (addFormFieldToGroup) {
        addFormFieldToGroup(formField)
      } else {
        recipientForm.groups.otherFields.push(formField)
      }
    }

    if (field.mandatory || field.optional) {
      return formField
    } else {
      return null
    }
  }

  return {
    getFormField,
  }
}
