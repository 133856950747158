var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModal',{attrs:{"title":""},on:{"show":_vm.onShow},scopedSlots:_vm._u([{key:"figureTitle",fn:function(){return [_c('AppCardImageTitle',{attrs:{"alt":"Alarm clock","title":_vm.$t('ComponentTermsAndConditionsModal.TitleTermsAndConditions').value,"src":require("@galileo/assets/images/illustrations/alarm-clock.svg")}})]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"mini-card"},[_c('p',{domProps:{"innerHTML":_vm._s(
          _vm.$t('ComponentTermsAndConditionsModal.TextTermsAndConditions', {
            privacyNoticeURL: _vm.privacyNoticeURL,
          }).value
        )}}),_c('AppInputCheckbox',{model:{value:(_vm.isAcceptedTermsAndConditionsCheckmark),callback:function ($$v) {_vm.isAcceptedTermsAndConditionsCheckmark=$$v},expression:"isAcceptedTermsAndConditionsCheckmark"}},[_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.$t('ComponentTermsAndConditionsModal.TextIAgree', {
              termsAndConditionsURL: _vm.termsAndConditionsURL,
            }).value
          )}})])],1)]},proxy:true},{key:"footer",fn:function(){return [_c('AppModalFooter',[_c('AppButton',{attrs:{"analytics-name":"login-new-terms-agree","loading":_vm.loading,"disabled":!_vm.isAcceptedTermsAndConditionsCheckmark},on:{"click":_vm.acceptTermsAndConditions}},[_vm._v(" "+_vm._s(_vm.$t('ComponentTermsAndConditionsModal.ButtonContinue').value)+" ")])],1)]},proxy:true}]),model:{value:(_vm.isTermsAndConditionsAcceptanceRequired),callback:function ($$v) {_vm.isTermsAndConditionsAcceptanceRequired=$$v},expression:"isTermsAndConditionsAcceptanceRequired"}})}
var staticRenderFns = []

export { render, staticRenderFns }