export interface iAPIError {
  reasonCode: string
  message: string
}

export class APIError extends Error implements iAPIError {
  reasonCode: string = ''
  constructor(message: string, reasonCode: string) {
    super(message)

    this.message = message
    this.reasonCode = reasonCode
  }
}
