import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/LaunchpadAPIHandler'
import Country from '@galileo/models/Country/app'

export default new APIHandler(
  API,
  (config) => {
    return {
      ...config,
      method: 'GET',
      url: 'stp/docs',
    }
  },
  (response) => {
    if (response) {
      return {
        ...response,
      }
    }
  }
)
