import API from '@galileo/api/rmt'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  (securityCode, config) => {
    return {
      ...config,
      method: 'GET',
      url: 'authorization/verify',
      params: {
        securityCode,
      },
    }
  },
  (response) => {
    let questions = null
    if (response.data.formData && response.data.formData.SecurityVerificationForm) {
      const fields = response.data.formData.SecurityVerificationForm.formFields
      if (fields && fields.length > 0) {
        const question = fields.find((field) => field.id === 'UserSecurityQuestion')
        if (question && question.options) {
          questions = question.options.filter((question) => question.value)
        }
      }
    }
    return {
      ...response,
      data: {
        questions: questions,
      },
    }
  }
)
