import { ref } from '@vue/composition-api'
import { defineStore } from 'pinia'
import { useHash } from '@galileo/composables/useHash'
import { MessageObject } from '@galileo/models/Page/interfaces'

const { hashSha1 } = useHash()

export const usePageStore = defineStore('page', () => {
  const homePageMessageDatas = ref({} as { [key: string]: MessageObject })

  const getHomePageMessageData = (profileId: any): MessageObject => {
    const hashedProfileId = hashSha1(profileId.toString())
    return homePageMessageDatas.value[hashedProfileId]
  }

  function setHomePageMessageData({
    profileId,
    homePageMessageData
  }: {
    profileId: any
    homePageMessageData: MessageObject
  }): void {
    const hashedProfileId = hashSha1(profileId.toString())
    const current = homePageMessageDatas.value
    current[hashedProfileId] = homePageMessageData
    homePageMessageDatas.value = current
  }

  return {
    homePageMessageDatas,
    getHomePageMessageData,
    setHomePageMessageData,
  }
}, {
  persist: true
})
