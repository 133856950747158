import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  ({ currency, country }, config) => {
    return {
      ...config,
      method: 'GET',
      url: `cardsV2/card-settings?Ccy=${currency}&Country=${country}`,
    }
  },
  (response) => {
    return {
      ...response,
      data: {
        cardBillingCountries: response.data.data.cardBillingCountries,
        cardTypes: response.data.data.cardSettings.cardTypes.map((cardType) => {
          return {
            id: cardType.id,
            name: cardType.name,
            cardType: cardType.paymentType,
            cardPspCode: cardType.pspCode,
            code: cardType.code,
          }
        }),
        cardFields: response.data.data.cardFields,
        cards: response.data.data.cardSettings.cards,
        savedCardLimitReached: response.data.data.cardSettings.savedCardLimitReached,
        clientSideEncryptionKey: response.data.data.clientSideEncryptionKey,
      },
    }
  }
)
