import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

class BalanceAccount {
  constructor(account) {
    this.accountCurrency = account.accountCurrency
    this.totalBalance = account.currentBalance.accountCurrencyAmount
    this.availableBalance = account.availableBalance.accountCurrencyAmount
  }
}

export default new APIHandler(
  API,
  ({ showAll = true }, config) => {
    return {
      ...config,
      method: 'GET',
      url: `/currencyAccounts?showAll=${showAll}`,
    }
  },
  (response) => {
    if (response.data) {
      let balancesAccounts = response.data.currencyAccounts.map(
        (account) => new BalanceAccount(account)
      )
      return balancesAccounts
    }
  }
)
