import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  ({ recipientId, formData }, config) => {
    return {
      ...config,
      method: 'PATCH',
      url: `CashRecipients/${recipientId}`,
      data: formData,
    }
  },
  async (response) => {
    let savedSucessfully = false

    if (response.status === 204) {
      savedSucessfully = true
    }

    return savedSucessfully
  }
)
