import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

class CountryResult {
  constructor(data) {
    this.value = data.countryCode
    this.availableDeliveryMethods = data.deliveryMethods
  }
}

export default new APIHandler(
  API,
  (country, language, config) => {
    return {
      ...config,
      method: 'GET',
      url: `Destinations/countries?CountryFrom=${country}&language=${language}`,
    }
  },
  (response) => {
    return {
      ...response,
      data: response.data.map((country) => new CountryResult(country)),
    }
  }
)
