export default function () {
  const { nationality } = NationalityList()

  function getOrderedNationalitiesWithTraslationKey(json) {
    const options = json.optionsList.options.map((option) => {
      const translatedText = `${option.category}.${option.value}`
      return { ...option, translatedText }
    })
    return options.sort((a, b) => a.text.localeCompare(b.text))
  }

  const getNationalityOptionList = () => {
    return getOrderedNationalitiesWithTraslationKey(nationality)
  }

  return { getNationalityOptionList }
}

export function NationalityList() {
  const nationality = {
    label: 'Nationality',
    optionsList: {
      metadata: {
        tags: [],
      },
      id: 'verifyField_Nationality_list',
      options: [
        {
          metadata: {
            tags: [],
          },
          value: 'TW',
          text: 'Taiwan ',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'MH',
          text: 'Marshallese',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'WS',
          text: 'Samoan',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'KM',
          text: 'Comoran',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'KI',
          text: 'I-Kiribati',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'UG',
          text: 'Ugandan',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'NR',
          text: 'Nauruan',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'MK',
          text: 'Macedonian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'ER',
          text: 'Eritrean',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'KZ',
          text: 'Kazakh',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'IM',
          text: 'Manx',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'BN',
          text: 'Bruneian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'GW',
          text: 'Guinean (Guinea-Bissau)',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'MQ',
          text: 'Martiniquais',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'AU',
          text: 'Australian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'IT',
          text: 'Italian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'NC',
          text: 'New Caledonian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'KG',
          text: 'Kyrgyzstani',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'UA',
          text: 'Ukrainian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'BD',
          text: 'Bangladeshi',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'SS',
          text: 'South Sudanese',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'LV',
          text: 'Latvian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'SO',
          text: 'Somali',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'GN',
          text: 'Guamanian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'KY',
          text: 'Caymanian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'BQ',
          text: 'Bonaire, Sint Eustatius and Saba',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'PE',
          text: 'Peruvian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'MV',
          text: 'Maldivian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'GY',
          text: 'Guyanese',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'JE',
          text: 'Channel Islander (Jersey)',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'CG',
          text: 'Congolese ',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'ZM',
          text: 'Zambian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'UM',
          text: 'United States Minor Outlaying Islands',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'HN',
          text: 'Honduran',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'FM',
          text: 'Micronesian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'BR',
          text: 'Brazilian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'DJ',
          text: 'Djiboutian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'TT',
          text: 'Trinidadian Tobagan/Tobagonian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'PM',
          text: 'French (Saint Pierre and Miquelon)',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'CA',
          text: 'Canadian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'CN',
          text: 'Chinese',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'NE',
          text: 'Nigerien',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'GE',
          text: 'Georgian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'GG',
          text: 'Channel Islander (Guerney)',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'LU',
          text: 'Luxembourger',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'VI',
          text: 'Virgin Islander (US)',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'CI',
          text: 'Ivoirian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'IE',
          text: 'Irish',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'PW',
          text: 'Palauan',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'KP',
          text: 'Korean (DPRK)',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'MP',
          text: 'Northern Mariana Islands',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'BF',
          text: 'Burkinese',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'JP',
          text: 'Japanese',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'MN',
          text: 'Mongolian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'LA',
          text: 'Laotian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'EH',
          text: 'Sahraouian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'PH',
          text: 'Philippine',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'TG',
          text: 'Togolese',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'CO',
          text: 'Colombian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'OM',
          text: 'Omani',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'BH',
          text: 'Bahraini',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'BJ',
          text: 'Beninese',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'AT',
          text: 'Austrian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'BT',
          text: 'Bhutanese',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'CM',
          text: 'Cameroonian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'ID',
          text: 'Indonesian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'AX',
          text: 'Alander',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'NU',
          text: 'Niuean',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'TR',
          text: 'Turkish',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'HU',
          text: 'Hungarian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'KW',
          text: 'Kuwaiti',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'IS',
          text: 'Icelandic',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'MU',
          text: 'Mauritian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'IR',
          text: 'Iranian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'ET',
          text: 'Ethiopian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'AF',
          text: 'Afghan',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'ZW',
          text: 'Zimbabwean',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'GI',
          text: 'Gibraltar',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'DO',
          text: 'Dominican (Dominican Republic)',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'LB',
          text: 'Lebanese',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'VC',
          text: 'Saint Vincentian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'TL',
          text: 'Timorese',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'MO',
          text: 'Chinese (Macau)',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'NP',
          text: 'Nepalese',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'VU',
          text: 'Vanuatuan',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'VA',
          text: 'Holy See (Vatican City State)',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'SM',
          text: 'Sammarinese',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'BZ',
          text: 'Belizean',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'HM',
          text: 'Heard and McDonald Islands',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'SL',
          text: 'Sierra Leonian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'CC',
          text: 'Cocos Islander',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'SH',
          text: 'Saint Helenian ',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'AG',
          text: 'Antiguan',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'EG',
          text: 'Egyptian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'SE',
          text: 'Swedish',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'LK',
          text: 'Sri Lankan',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'MW',
          text: 'Malawian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'GS',
          text: 'South Georgia and South Sandwich Islands',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'MF',
          text: 'Saint Martin (French part)',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'CD',
          text: 'Congolese (DRC)',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'TZ',
          text: 'Tanzanian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'BI',
          text: 'Burundian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'US',
          text: 'American',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'GB',
          text: 'British',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'GD',
          text: 'Grenadian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'NA',
          text: 'Namibian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'AW',
          text: 'Aruban',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'SN',
          text: 'Senegalese',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'TH',
          text: 'Thai',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'SV',
          text: 'Salvadoran',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'MZ',
          text: 'Mozambican',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'PN',
          text: 'Pitcairn Islander',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'SR',
          text: 'Surinamese',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'BS',
          text: 'Bahamian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'ST',
          text: 'Sao Tomean',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'JO',
          text: 'Jordanian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'BG',
          text: 'Bulgarian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'WF',
          text: 'Wallis and Futuna Islander',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'PK',
          text: 'Pakistani',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'FJ',
          text: 'Fijian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'NL',
          text: 'Dutch',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'TK',
          text: 'Tokelauan',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'LR',
          text: 'Liberian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'RU',
          text: 'Russian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'VE',
          text: 'Venezuelan',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'FK',
          text: 'Falkland Islander',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'BM',
          text: 'Bermudian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'SZ',
          text: 'Swazi',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'SI',
          text: 'Slovenian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'GL',
          text: 'Greenlandic',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'SD',
          text: 'Sudanese',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'SX',
          text: 'Sint Maarten (Dutch part)',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'TD',
          text: 'Chadian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'FO',
          text: 'Faroese',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'VG',
          text: 'Virgin Islander (British)',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'YE',
          text: 'Yemeni',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'MA',
          text: 'Moroccan',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'GR',
          text: 'Greek',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'AN',
          text: 'Dutch Antillean',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'KE',
          text: 'Kenyan',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'NG',
          text: 'Nigerian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'PT',
          text: 'Portuguese',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'AZ',
          text: 'Azerbaijani',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'TM',
          text: 'Turkmen',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'EE',
          text: 'Estonian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'BV',
          text: 'Bouvet Island',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'MM',
          text: 'Burmese',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'PL',
          text: 'Polish',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'SB',
          text: 'Solomon Islander',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'FI',
          text: 'Finnish',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'CU',
          text: 'Cuban',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'GP',
          text: 'Guadeloupean',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'SY',
          text: 'Syrian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'LI',
          text: 'Liechtenstein',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'BL',
          text: 'Saint Barthélemy',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'KH',
          text: 'Cambodian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'MD',
          text: 'Moldovan',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'VN',
          text: 'Vietnamese',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'DZ',
          text: 'Algerian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'GH',
          text: 'Ghanaian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'TJ',
          text: 'Tajikistani',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'MT',
          text: 'Maltese',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'MG',
          text: 'Malagasy',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'MX',
          text: 'Mexican',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'BY',
          text: 'Belorussian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'MR',
          text: 'Mauritanian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'LT',
          text: 'Lithuanian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'CW',
          text: 'Curacao',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'AD',
          text: 'Andorran',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'DE',
          text: 'German',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'HK',
          text: 'Chinese (Hong Kong)',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'ML',
          text: 'Malian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'CL',
          text: 'Chilean',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'ES',
          text: 'Spanish',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'CZ',
          text: 'Czech',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'BW',
          text: 'Botswanan',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'TV',
          text: 'Tuvaluan',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'SK',
          text: 'Slovak',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'EC',
          text: 'Ecuadorean',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'XK',
          text: 'Kosovan',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'CK',
          text: 'Cook Islander',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'BA',
          text: 'Bosnian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'QA',
          text: 'Qatari',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'AS',
          text: 'American Samoan',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'UZ',
          text: 'Uzbek',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'DK',
          text: 'Danish',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'CR',
          text: 'Costa Rican',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'KR',
          text: 'Korean (ROK)',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'CY',
          text: 'Cypriot',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'TF',
          text: 'French (French Southern Territories)',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'YT',
          text: 'Mahoran',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'UY',
          text: 'Uruguayan',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'PF',
          text: 'French Polynesian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'AE',
          text: 'Emirati',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'GF',
          text: 'French Guianese',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'CX',
          text: 'Christmas Island',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'RO',
          text: 'Romanian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'CH',
          text: 'Swiss',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'LS',
          text: 'Basotho',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'CV',
          text: 'Cabo Verdean',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'IN',
          text: 'Indian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'FR',
          text: 'French',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'CF',
          text: 'Central African',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'PA',
          text: 'Panamanian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'AQ',
          text: 'Antarctican',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'BB',
          text: 'Barbadian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'TC',
          text: 'Turks and Caicos Islander',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'MY',
          text: 'Malaysian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'ME',
          text: 'Montenegrin',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'JM',
          text: 'Jamaican',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'RS',
          text: 'Serbian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'AO',
          text: 'Angolan',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'SG',
          text: 'Singaporean',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'AM',
          text: 'Armenian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'PY',
          text: 'Paraguayan',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'NF',
          text: 'Norfolk Islander',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'AI',
          text: 'Anguillan',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'ZA',
          text: 'South African',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'IL',
          text: 'Israeli',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'LC',
          text: 'Saint Lucian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'RW',
          text: 'Rwandan',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'GA',
          text: 'Gabonese',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'AL',
          text: 'Albanian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'MS',
          text: 'Montserratian ',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'DM',
          text: 'Dominican',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'AR',
          text: 'Argentinian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'NO',
          text: 'Norwegian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'BO',
          text: 'Bolivian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'HR',
          text: 'Croatian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'PR',
          text: 'Puerto Rican',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'GU',
          text: 'Guinean',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'SJ',
          text: 'Svalbard and Jan Mayen Islands',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'GM',
          text: 'Gambian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'KN',
          text: 'Kittitian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'NZ',
          text: 'New Zealander',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'HT',
          text: 'Haitian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'TO',
          text: 'Tongan',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'NI',
          text: 'Nicaraguan',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'SC',
          text: 'Seychellois',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'GQ',
          text: 'Equatoguinean',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'IO',
          text: 'Chagossian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'TN',
          text: 'Tunisian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'LY',
          text: 'Libyan',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'IQ',
          text: 'Iraqi',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'SA',
          text: 'Saudi',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'BE',
          text: 'Belgian',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'MC',
          text: 'Monacan',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'PG',
          text: 'Papua New Guinean',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'RE',
          text: 'Réunionese',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'GT',
          text: 'Guatemalan',
          category: 'verifyField_Nationality_list',
        },
        {
          metadata: {
            tags: [],
          },
          value: 'PS',
          text: 'Palestinian',
          category: 'verifyField_Nationality_list',
        },
      ],
    },
    placeholder: 'Select your nationality',
  }

  return { nationality }
}

// $t('verifyField_Nationality_list.TW').value
// $t('verifyField_Nationality_list.MH').value
// $t('verifyField_Nationality_list.WS').value
// $t('verifyField_Nationality_list.KM').value
// $t('verifyField_Nationality_list.KI').value
// $t('verifyField_Nationality_list.UG').value
// $t('verifyField_Nationality_list.NR').value
// $t('verifyField_Nationality_list.MK').value
// $t('verifyField_Nationality_list.ER').value
// $t('verifyField_Nationality_list.KZ').value
// $t('verifyField_Nationality_list.IM').value
// $t('verifyField_Nationality_list.BN').value
// $t('verifyField_Nationality_list.GW').value
// $t('verifyField_Nationality_list.MQ').value
// $t('verifyField_Nationality_list.AU').value
// $t('verifyField_Nationality_list.IT').value
// $t('verifyField_Nationality_list.NC').value
// $t('verifyField_Nationality_list.KG').value
// $t('verifyField_Nationality_list.UA').value
// $t('verifyField_Nationality_list.BD').value
// $t('verifyField_Nationality_list.SS').value
// $t('verifyField_Nationality_list.LV').value
// $t('verifyField_Nationality_list.SO').value
// $t('verifyField_Nationality_list.GN').value
// $t('verifyField_Nationality_list.KY').value
// $t('verifyField_Nationality_list.BQ').value
// $t('verifyField_Nationality_list.PE').value
// $t('verifyField_Nationality_list.MV').value
// $t('verifyField_Nationality_list.GY').value
// $t('verifyField_Nationality_list.JE').value
// $t('verifyField_Nationality_list.CG').value
// $t('verifyField_Nationality_list.ZM').value
// $t('verifyField_Nationality_list.UM').value
// $t('verifyField_Nationality_list.HN').value
// $t('verifyField_Nationality_list.FM').value
// $t('verifyField_Nationality_list.BR').value
// $t('verifyField_Nationality_list.DJ').value
// $t('verifyField_Nationality_list.TT').value
// $t('verifyField_Nationality_list.PM').value
// $t('verifyField_Nationality_list.CA').value
// $t('verifyField_Nationality_list.CN').value
// $t('verifyField_Nationality_list.NE').value
// $t('verifyField_Nationality_list.GE').value
// $t('verifyField_Nationality_list.GG').value
// $t('verifyField_Nationality_list.LU').value
// $t('verifyField_Nationality_list.VI').value
// $t('verifyField_Nationality_list.CI').value
// $t('verifyField_Nationality_list.IE').value
// $t('verifyField_Nationality_list.PW').value
// $t('verifyField_Nationality_list.KP').value
// $t('verifyField_Nationality_list.MP').value
// $t('verifyField_Nationality_list.BF').value
// $t('verifyField_Nationality_list.JP').value
// $t('verifyField_Nationality_list.MN').value
// $t('verifyField_Nationality_list.LA').value
// $t('verifyField_Nationality_list.EH').value
// $t('verifyField_Nationality_list.PH').value
// $t('verifyField_Nationality_list.TG').value
// $t('verifyField_Nationality_list.CO').value
// $t('verifyField_Nationality_list.OM').value
// $t('verifyField_Nationality_list.BH').value
// $t('verifyField_Nationality_list.BJ').value
// $t('verifyField_Nationality_list.AT').value
// $t('verifyField_Nationality_list.BT').value
// $t('verifyField_Nationality_list.CM').value
// $t('verifyField_Nationality_list.ID').value
// $t('verifyField_Nationality_list.AX').value
// $t('verifyField_Nationality_list.NU').value
// $t('verifyField_Nationality_list.TR').value
// $t('verifyField_Nationality_list.HU').value
// $t('verifyField_Nationality_list.KW').value
// $t('verifyField_Nationality_list.IS').value
// $t('verifyField_Nationality_list.MU').value
// $t('verifyField_Nationality_list.IR').value
// $t('verifyField_Nationality_list.ET').value
// $t('verifyField_Nationality_list.AF').value
// $t('verifyField_Nationality_list.ZW').value
// $t('verifyField_Nationality_list.GI').value
// $t('verifyField_Nationality_list.DO').value
// $t('verifyField_Nationality_list.LB').value
// $t('verifyField_Nationality_list.VC').value
// $t('verifyField_Nationality_list.TL').value
// $t('verifyField_Nationality_list.MO').value
// $t('verifyField_Nationality_list.NP').value
// $t('verifyField_Nationality_list.VU').value
// $t('verifyField_Nationality_list.VA').value
// $t('verifyField_Nationality_list.SM').value
// $t('verifyField_Nationality_list.BZ').value
// $t('verifyField_Nationality_list.HM').value
// $t('verifyField_Nationality_list.SL').value
// $t('verifyField_Nationality_list.CC').value
// $t('verifyField_Nationality_list.SH').value
// $t('verifyField_Nationality_list.AG').value
// $t('verifyField_Nationality_list.EG').value
// $t('verifyField_Nationality_list.SE').value
// $t('verifyField_Nationality_list.LK').value
// $t('verifyField_Nationality_list.MW').value
// $t('verifyField_Nationality_list.GS').value
// $t('verifyField_Nationality_list.MF').value
// $t('verifyField_Nationality_list.CD').value
// $t('verifyField_Nationality_list.TZ').value
// $t('verifyField_Nationality_list.BI').value
// $t('verifyField_Nationality_list.US').value
// $t('verifyField_Nationality_list.GB').value
// $t('verifyField_Nationality_list.GD').value
// $t('verifyField_Nationality_list.NA').value
// $t('verifyField_Nationality_list.AW').value
// $t('verifyField_Nationality_list.SN').value
// $t('verifyField_Nationality_list.TH').value
// $t('verifyField_Nationality_list.SV').value
// $t('verifyField_Nationality_list.MZ').value
// $t('verifyField_Nationality_list.PN').value
// $t('verifyField_Nationality_list.SR').value
// $t('verifyField_Nationality_list.BS').value
// $t('verifyField_Nationality_list.ST').value
// $t('verifyField_Nationality_list.JO').value
// $t('verifyField_Nationality_list.BG').value
// $t('verifyField_Nationality_list.WF').value
// $t('verifyField_Nationality_list.PK').value
// $t('verifyField_Nationality_list.FJ').value
// $t('verifyField_Nationality_list.NL').value
// $t('verifyField_Nationality_list.TK').value
// $t('verifyField_Nationality_list.LR').value
// $t('verifyField_Nationality_list.RU').value
// $t('verifyField_Nationality_list.VE').value
// $t('verifyField_Nationality_list.FK').value
// $t('verifyField_Nationality_list.BM').value
// $t('verifyField_Nationality_list.SZ').value
// $t('verifyField_Nationality_list.SI').value
// $t('verifyField_Nationality_list.GL').value
// $t('verifyField_Nationality_list.SD').value
// $t('verifyField_Nationality_list.SX').value
// $t('verifyField_Nationality_list.TD').value
// $t('verifyField_Nationality_list.FO').value
// $t('verifyField_Nationality_list.VG').value
// $t('verifyField_Nationality_list.YE').value
// $t('verifyField_Nationality_list.MA').value
// $t('verifyField_Nationality_list.GR').value
// $t('verifyField_Nationality_list.AN').value
// $t('verifyField_Nationality_list.KE').value
// $t('verifyField_Nationality_list.NG').value
// $t('verifyField_Nationality_list.PT').value
// $t('verifyField_Nationality_list.AZ').value
// $t('verifyField_Nationality_list.TM').value
// $t('verifyField_Nationality_list.EE').value
// $t('verifyField_Nationality_list.BV').value
// $t('verifyField_Nationality_list.MM').value
// $t('verifyField_Nationality_list.PL').value
// $t('verifyField_Nationality_list.SB').value
// $t('verifyField_Nationality_list.FI').value
// $t('verifyField_Nationality_list.CU').value
// $t('verifyField_Nationality_list.GP').value
// $t('verifyField_Nationality_list.SY').value
// $t('verifyField_Nationality_list.LI').value
// $t('verifyField_Nationality_list.BL').value
// $t('verifyField_Nationality_list.KH').value
// $t('verifyField_Nationality_list.MD').value
// $t('verifyField_Nationality_list.VN').value
// $t('verifyField_Nationality_list.DZ').value
// $t('verifyField_Nationality_list.GH').value
// $t('verifyField_Nationality_list.TJ').value
// $t('verifyField_Nationality_list.MT').value
// $t('verifyField_Nationality_list.MG').value
// $t('verifyField_Nationality_list.MX').value
// $t('verifyField_Nationality_list.BY').value
// $t('verifyField_Nationality_list.MR').value
// $t('verifyField_Nationality_list.LT').value
// $t('verifyField_Nationality_list.CW').value
// $t('verifyField_Nationality_list.AD').value
// $t('verifyField_Nationality_list.DE').value
// $t('verifyField_Nationality_list.HK').value
// $t('verifyField_Nationality_list.ML').value
// $t('verifyField_Nationality_list.CL').value
// $t('verifyField_Nationality_list.ES').value
// $t('verifyField_Nationality_list.CZ').value
// $t('verifyField_Nationality_list.BW').value
// $t('verifyField_Nationality_list.TV').value
// $t('verifyField_Nationality_list.SK').value
// $t('verifyField_Nationality_list.EC').value
// $t('verifyField_Nationality_list.XK').value
// $t('verifyField_Nationality_list.CK').value
// $t('verifyField_Nationality_list.BA').value
// $t('verifyField_Nationality_list.QA').value
// $t('verifyField_Nationality_list.AS').value
// $t('verifyField_Nationality_list.UZ').value
// $t('verifyField_Nationality_list.DK').value
// $t('verifyField_Nationality_list.CR').value
// $t('verifyField_Nationality_list.KR').value
// $t('verifyField_Nationality_list.CY').value
// $t('verifyField_Nationality_list.TF').value
// $t('verifyField_Nationality_list.YT').value
// $t('verifyField_Nationality_list.UY').value
// $t('verifyField_Nationality_list.PF').value
// $t('verifyField_Nationality_list.AE').value
// $t('verifyField_Nationality_list.GF').value
// $t('verifyField_Nationality_list.CX').value
// $t('verifyField_Nationality_list.RO').value
// $t('verifyField_Nationality_list.CH').value
// $t('verifyField_Nationality_list.LS').value
// $t('verifyField_Nationality_list.CV').value
// $t('verifyField_Nationality_list.IN').value
// $t('verifyField_Nationality_list.FR').value
// $t('verifyField_Nationality_list.CF').value
// $t('verifyField_Nationality_list.PA').value
// $t('verifyField_Nationality_list.AQ').value
// $t('verifyField_Nationality_list.BB').value
// $t('verifyField_Nationality_list.TC').value
// $t('verifyField_Nationality_list.MY').value
// $t('verifyField_Nationality_list.ME').value
// $t('verifyField_Nationality_list.JM').value
// $t('verifyField_Nationality_list.RS').value
// $t('verifyField_Nationality_list.AO').value
// $t('verifyField_Nationality_list.SG').value
// $t('verifyField_Nationality_list.AM').value
// $t('verifyField_Nationality_list.PY').value
// $t('verifyField_Nationality_list.NF').value
// $t('verifyField_Nationality_list.AI').value
// $t('verifyField_Nationality_list.ZA').value
// $t('verifyField_Nationality_list.IL').value
// $t('verifyField_Nationality_list.LC').value
// $t('verifyField_Nationality_list.RW').value
// $t('verifyField_Nationality_list.GA').value
// $t('verifyField_Nationality_list.AL').value
// $t('verifyField_Nationality_list.MS').value
// $t('verifyField_Nationality_list.DM').value
// $t('verifyField_Nationality_list.AR').value
// $t('verifyField_Nationality_list.NO').value
// $t('verifyField_Nationality_list.BO').value
// $t('verifyField_Nationality_list.HR').value
// $t('verifyField_Nationality_list.PR').value
// $t('verifyField_Nationality_list.GU').value
// $t('verifyField_Nationality_list.SJ').value
// $t('verifyField_Nationality_list.GM').value
// $t('verifyField_Nationality_list.KN').value
// $t('verifyField_Nationality_list.NZ').value
// $t('verifyField_Nationality_list.HT').value
// $t('verifyField_Nationality_list.TO').value
// $t('verifyField_Nationality_list.NI').value
// $t('verifyField_Nationality_list.SC').value
// $t('verifyField_Nationality_list.GQ').value
// $t('verifyField_Nationality_list.IO').value
// $t('verifyField_Nationality_list.TN').value
// $t('verifyField_Nationality_list.LY').value
// $t('verifyField_Nationality_list.IQ').value
// $t('verifyField_Nationality_list.SA').value
// $t('verifyField_Nationality_list.BE').value
// $t('verifyField_Nationality_list.MC').value
// $t('verifyField_Nationality_list.PG').value
// $t('verifyField_Nationality_list.RE').value
// $t('verifyField_Nationality_list.GT').value
// $t('verifyField_Nationality_list.PS').value
