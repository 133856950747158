import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'
import XeRecipientField from '@galileo/models/DynamicField/XeRecipientField'

export default new APIHandler(
  API,
  ({ id, country, currency }, config) => {
    return {
      ...config,
      method: 'GET',
      url: `recipients/fields/${id}/${country}/${currency}`,
    }
  },
  (response) => {
    if (!response.data) {
      return response
    }
    return {
      ...response,
      data: response.data.fieldDefinitions.map((field) => new XeRecipientField(field)),
    }
  }
)
