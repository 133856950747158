<template>
  <div>
    <AppModal v-model="showKYCRefreshModal" title="" @show="onShow">
      <template #figureTitle>
        <AppCardImageTitle
          alt="Hand stop gesture image"
          :title="$t('ComponentKYCRefreshModal.WelcomeBackTitle').value"
          :src="require(`@galileo/assets/images/illustrations/hand-stop.svg`)"
        />
      </template>

      <template #default>
        <p class="confirmation-text">
          {{ $t('ComponentKYCRefreshModal.ConfirmationText').value }}
        </p>
      </template>

      <template #footer>
        <AppModalFooter>
          <AppButton
            analytics-name="login-new-terms-agree"
            theme="primary"
            class="mb-4"
            @click="showKYCEditModalHandler"
          >
            {{ $t('ComponentKYCRefreshModal.ConfirmButton').value }}
          </AppButton>

          <AppButton analytics-name="login-new-terms-agree" theme="secondary" @click="logout">
            {{ $t('ComponentProfileSelectionDropdown.LogoutText').value }}
          </AppButton>
        </AppModalFooter>
      </template>
    </AppModal>

    <AppModal v-model="showKYCEditModal" title="">
      <template #header>
        <XeModalHeader>
          <template #left>
            <XeBackButton analytics-name="" icon="<" @click="closeKYCEditModal" />
          </template>
          <h1>{{ $t('ComponentKYCRefreshModal.ConfirmYourDetailsText').value }}</h1>
        </XeModalHeader>
      </template>

      <template #default>
        <XeAppDescriptionList borderTop>
          <AppDescriptionListItem>
            <template #title>{{ $t('PageAccount.PersonalInfoSectionNameLabel').value }}</template>
            <template #content>
              <p>{{ user.customer.accountName }}</p>
              <AppButton theme="icon" @click="editName">
                <AppIcon name=" ">
                  <IconEdit />
                </AppIcon>
              </AppButton>
            </template>
          </AppDescriptionListItem>
        </XeAppDescriptionList>
        <XeAppDescriptionList borderTop>
          <AppDescriptionListItem>
            <template #title>{{ $t('PageAccount.AccountSectionEmailLabel').value }}</template>
            <template #content>
              <p>{{ user.customer.email }}</p>
              <AppButton theme="icon" @click="editEmail">
                <AppIcon name=" ">
                  <IconEdit />
                </AppIcon>
              </AppButton>
            </template>
          </AppDescriptionListItem>
        </XeAppDescriptionList>
        <XeAppDescriptionList borderTop>
          <AppDescriptionListItem>
            <template #title>{{ $t('PageAccount.PersonalInfoSectionPhoneLabel').value }}</template>
            <template #content>
              <p>{{ user.mobilePhone.phoneNumberFormatted }}</p>
              <AppButton theme="icon" @click="editPhone">
                <AppIcon name=" ">
                  <IconEdit />
                </AppIcon>
              </AppButton>
            </template>
          </AppDescriptionListItem>
        </XeAppDescriptionList>
        <XeAppDescriptionList borderTop>
          <AppDescriptionListItem>
            <template #title>
              {{ $t('PageAccount.PersonalInfoSectionAddressLabel').value }}
            </template>
            <template #content>
              <AppButton theme="icon" @click="editAddress">
                <AppIcon name=" ">
                  <IconEdit />
                </AppIcon>
              </AppButton>
            </template>
            <template #action> </template>
          </AppDescriptionListItem>
          <p class="description-list-item-definition">
            {{ formattedAddress }}
          </p>
        </XeAppDescriptionList>
        <XeAppDescriptionList v-if="user.customer.occupation" borderTop>
          <AppDescriptionListItem>
            <template #title>{{ $t('ComponentKYCRefreshModal.OccupationTitle').value }}</template>
            <template #content>
              <p>{{ user.customer.occupation }}</p>
              <AppButton theme="icon" @click="editOccupation">
                <AppIcon name=" ">
                  <IconEdit />
                </AppIcon>
              </AppButton>
            </template>
          </AppDescriptionListItem>
        </XeAppDescriptionList>

        <div class="mini-card">
          <AppInputCheckbox v-model="isConfirmDetailsCheckmark">
            {{ $t('ComponentKYCRefreshModal.IConfirmText').value }}
          </AppInputCheckbox>
        </div>
      </template>

      <template #footer>
        <AppModalFooter>
          <AppButton
            analytics-name="login-new-terms-agree"
            theme="primary"
            :disabled="!isConfirmDetailsCheckmark"
            :loading="isSaving"
            @click="confirmDetails"
          >
            {{ $t('ComponentKYCRefreshModal.ContinueButton').value }}
          </AppButton>
        </AppModalFooter>
      </template>
    </AppModal>

    <!-- Wrapped in template so it wouldn't trigger error messages on login 
        screen when user object is still unavailable -->
    <template v-if="user">
      <EditAddressModal
        v-if="showEditAddressModal"
        v-model="showEditAddressModal"
        :user="user"
        :on-save="onSaveAddress"
      />
      <EditNameModal
        v-if="showEditNameModal"
        v-model="showEditNameModal"
        :user="user"
        :on-save="onSaveSettings"
      />
      <EditPhoneModal
        v-if="showEditPhoneModal"
        v-model="showEditPhoneModal"
        :user="user"
        :on-save="onSaveSettings"
      />
      <EditEmailModal
        v-if="showEditEmailModal"
        v-model="showEditEmailModal"
        :user="user"
        :on-save="onSaveSettings"
      />
      <EditOccupationModal
        v-if="showEditOccupationModal"
        v-model="showEditOccupationModal"
        :user="user"
        :on-save="onSaveSettings"
      />
    </template>

    <InformationAppModal :value="showEditEmailAlertModal" icon="mailBox" class="email-edit-alert">
      <h1 class="title">{{ $t('ChangeEmailModal.Title').value }}</h1>
      <p v-html="$t('ChangeEmailModal.Text').value" />

      <template #footer>
        <AppButton @click="showEmailEdit()">
          {{ $t('ComponentCardCVVModal.ButtonGotIt').value }}
        </AppButton>
        <AppButton theme="secondary" @click="showEditEmailAlertModal = false">
          {{ $t('PageAccount.ButtonLogoutNo').value }}
        </AppButton>
      </template>
    </InformationAppModal>

    <SignOutModal v-if="showLogoutDialog" v-model="showLogoutDialog" />
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { storeToRefs } from 'pinia'

import {
  AppModal,
  AppModalFooter,
  AppButton,
  AppCardImageTitle,
  AppInputCheckbox,
  AppDescriptionListItem,
  AppIcon,
} from '@oen.web.vue2/ui'

import { IconEdit } from '@oen.web.vue2/icons'
import XeAppDescriptionList from '@galileo/components/XeAppDescriptionList/XeAppDescriptionList'
import XeModalHeader from '@galileo/components/XeModalHeader/XeModalHeader'
import XeBackButton from '@galileo/components/XeBackButton/XeBackButton'
import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'

import EditAddressModal from '@galileo/components/Views/Account/EditSettingsModal/EditAddressModal/EditAddressModal'
import EditNameModal from '@galileo/components/Views/Account/EditSettingsModal/EditNameModal'
import EditPhoneModal from '@galileo/components/Views/Account/EditSettingsModal/EditPhoneModal'
import EditEmailModal from '@galileo/components/Views/Account/EditSettingsModal/EditEmailModal'
import EditOccupationModal from '@galileo/components/Views/Account/EditSettingsModal/EditOccupationModal'

import { SEGMENT_EVENTS, SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'
import Address from '@galileo/models/Address/address'
import { useAddress } from '@galileo/composables/useAddress'
import { useRouter } from '@galileo/composables/useRouter'

import {
  useAnalyticsStore,
  useI18nStore,
  useResourcesStore,
  useAuthStore,
  useProfileStore,
  usePromotionStore,
} from '@galileo/stores'
import SignOutModal from '@galileo/components/Views/Account/Profile/ProfileModal/SignOutModal'

export default {
  name: 'KYCRefreshModal',
  components: {
    AppModal,
    AppModalFooter,
    AppButton,
    AppCardImageTitle,
    AppInputCheckbox,
    AppIcon,
    IconEdit,
    XeAppDescriptionList,
    XeModalHeader,
    XeBackButton,
    AppDescriptionListItem,
    InformationAppModal,
    EditAddressModal,
    EditNameModal,
    EditPhoneModal,
    EditEmailModal,
    EditOccupationModal,
    SignOutModal,
  },
  setup() {
    const { $t } = useI18nStore()
    const analyticsStore = useAnalyticsStore()
    const router = useRouter()
    const authStore = useAuthStore()
    const profileStore = useProfileStore()

    const { user } = storeToRefs(authStore)

    const isKYCRefreshRequired = computed(() => {
      return router.currentRoute.name !== 'Login' && authStore.isKYCRefreshRequired
    })

    const showKYCRefreshModal = ref(isKYCRefreshRequired)
    const showKYCEditModal = ref(false)
    const isConfirmDetailsCheckmark = ref(false)
    const isSaving = ref(false)

    const showEditAddressModal = ref(false)
    const showEditNameModal = ref(false)
    const showEditPhoneModal = ref(false)
    const showEditEmailModal = ref(false)
    const showEditEmailAlertModal = ref(false)
    const showEditOccupationModal = ref(false)
    const showLogoutDialog = ref(false)

    const formattedAddress = computed(() => {
      if (user?.value) {
        const address = new Address(
          user.value.address,
          user.value.extraFields.place,
          user.value.extraFields.state,
          user.value.extraFields.postalCode,
          user.value.country
        )
        const { formattedAddress } = useAddress(address)
        return formattedAddress.value
      }
    })

    const showKYCEditModalHandler = () => {
      showKYCEditModal.value = true
    }

    const closeKYCEditModal = () => {
      showKYCEditModal.value = false
    }

    const onShow = () => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.KYC_REFRESH_REQUIRED,
      })
    }

    const logout = async () => {
      showLogoutDialog.value = true
    }

    const confirmDetails = async () => {
      isSaving.value = true
      await profileStore.updateKYCLastRefresh()
      await authStore.getUserAction(user?.value?.customer?.id)
      analyticsStore.track({
        event: SEGMENT_EVENTS.KYC_REFRESH_CONFIRMED,
      })
      isSaving.value = false
      showKYCEditModal.value = false
    }

    async function onSaveSettings() {
      // Fetch the user again
      await authStore.getUserAction(user?.value?.customer?.id)
    }

    async function onSaveAddress() {
      analyticsStore.track({
        event: SEGMENT_EVENTS.ADDRESS_UPDATED,
        traits: {
          location: SEGMENT_LOCATIONS.KYC_REFRESH,
        },
      })
      // Fetch the user again
      await authStore.getUserAction(user?.value?.customer?.id)
      // Fetch the system fields again to update T&Cs URLs
      const resourcesStore = useResourcesStore()
      await resourcesStore.getSystemFields()
      await authStore.refresh(authStore.token?.refreshToken)
      await usePromotionStore().getAvailablePromotionsPerUserCountry()
    }

    const editName = () => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.CHANGE_NAME_STARTED,
        traits: {
          location: SEGMENT_LOCATIONS.KYC_REFRESH,
        },
      })
      showEditNameModal.value = true
    }

    const showEmailEdit = () => {
      showEditEmailModal.value = true
      showEditEmailAlertModal.value = false
    }

    const editEmail = () => {
      showEditEmailAlertModal.value = true
      analyticsStore.track({
        event: SEGMENT_EVENTS.CHANGE_EMAIL_STARTED,
        traits: {
          location: SEGMENT_LOCATIONS.KYC_REFRESH,
        },
      })
    }

    const editAddress = () => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.CHANGE_PERSONAL_ADDRESS_STARTED,
        traits: {
          location: SEGMENT_LOCATIONS.KYC_REFRESH,
        },
      })
      showEditAddressModal.value = true
    }

    const editPhone = () => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.CHANGE_PHONE_NUMBER_STARTED,
        traits: {
          location: SEGMENT_LOCATIONS.KYC_REFRESH,
        },
      })
      showEditPhoneModal.value = true
    }

    const editOccupation = () => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.CHANGE_OCCUPATION_STARTED,
        traits: {
          location: SEGMENT_LOCATIONS.KYC_REFRESH,
        },
      })

      showEditOccupationModal.value = true
    }

    return {
      $t,
      showKYCRefreshModal,
      showKYCEditModal,
      showEditAddressModal,
      showEditNameModal,
      showEditPhoneModal,
      showEditEmailModal,
      showEditEmailAlertModal,
      showEditOccupationModal,
      isConfirmDetailsCheckmark,
      isSaving,
      user,
      formattedAddress,
      showKYCEditModalHandler,
      logout,
      confirmDetails,
      closeKYCEditModal,
      onSaveSettings,
      onSaveAddress,
      editName,
      editEmail,
      showEmailEdit,
      editAddress,
      editPhone,
      editOccupation,
      onShow,
      showLogoutDialog,
    }
  },
}
</script>

<style scoped>
::v-deep .card-content {
  .card-content-block {
    @apply mb-6 text-sm leading-5;
  }
}

.card-header {
  @apply px-12 pt-12;

  &.card-header--without-title {
    @apply pb-12 mb-0;
  }
}

::v-deep .card-image-title {
  .card-image-title-title {
    @apply text-2xl mb-0 w-80;
    line-height: 32px;
  }
}

::v-deep .card.card--has-footer {
  @screen sm {
    @apply pb-12;
  }
}

.card-footer {
  @apply flex-col-reverse;
  .button {
    @apply w-full;
  }
}

.mini-card {
  @apply p-6 mt-6;
  @apply rounded-2xl;
  @apply shadow-ria-1;
  border: 0.5px solid #dddddd;

  ::v-deep .input-checkbox-label {
    @apply font-normal text-sm leading-5 text-gray-text;
  }
}

::v-deep .input-checkbox-virtual-checkbox {
  @apply w-min-content h-auto rounded-none;
}

.button.button--primary {
  &:disabled {
    &:not(.button--loading) {
      @apply text-white;
    }

    background: linear-gradient(
        to right,
        theme('colors.blue.button-disabled'),
        theme('colors.blue.button-disabled')
      ),
      linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
  }
}

.confirmation-text {
  @apply text-center text-gray-text;
}

::v-deep .description-list-item-definition {
  @apply flex flex-row;
  @apply font-normal text-sm leading-5 text-gray-text;

  * {
    @apply flex-auto;
  }

  .button {
    @apply w-auto;
  }

  > p {
    @apply pl-2 font-normal text-sm leading-5 text-gray-text;
    overflow-wrap: anywhere;
  }
}

::v-deep .description-list-item .description-list-item-term {
  @apply font-medium leading-6 self-center;
  font-size: 15px;
}

::v-deep .button.button--icon {
  @apply ml-2;
  .button-inner {
    @apply flex w-auto h-auto rounded-none;
  }
}

::v-deep .description-list {
  @apply py-2.5;
}
</style>
