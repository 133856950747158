import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  ({ profileId, recipientId }) => {
    return {
      //...config,
      method: 'DELETE',
      url: `/recipients/${profileId}/${recipientId}`,
    }
  },
  (response) => {
    if (!response.data) {
      return response
    }
    return {
      ...response,
      data: response.data,
    }
  }
)
