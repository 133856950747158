import AT from '@galileo/regions/AT'
import AU from '@galileo/regions/AU'
import BE from '@galileo/regions/BE'
import CA from '@galileo/regions/CA'
import DE from '@galileo/regions/DE'
import DK from '@galileo/regions/DK'
import ES from '@galileo/regions/ES'
import FI from '@galileo/regions/FI'
import FR from '@galileo/regions/FR'
import GB from '@galileo/regions/GB'
import IE from '@galileo/regions/IE'
import IT from '@galileo/regions/IT'
import LU from '@galileo/regions/LU'
import NL from '@galileo/regions/NL'
import NO from '@galileo/regions/NO'
import SE from '@galileo/regions/SE'
import US from '@galileo/regions/US'

const countryConfigs = [AU, CA, ES, GB, US, FR, DE, IT, LU, BE, NL, AT, IE, DK, FI, SE, NO];

export default countryConfigs
