import { defineStore } from 'pinia'
import { ref, computed, reactive } from '@vue/composition-api'

import getPaymentAccountsV2 from '@galileo/api/launchpad/cardsV2/accounts/_profileId/get'
import deleteCardPaymentMethod from '@galileo/api/launchpad/cards/delete'
import deleteCardV2 from '@galileo/api/launchpad/cardsV2/card/delete'
import deletePaymentMethodAccount from '@galileo/api/launchpad/client-bank-accounts/delete'
import editBillingAddress from '@galileo/api/launchpad/cards/_cardId/putV2'
import editCardDetails from '@galileo/api/launchpad/cards/_cardId/put'
import editBankAccountDetails from '@galileo/api/rmt/account/bank/edit/post'
import editBankAccountNicknameDetails from '@galileo/api/launchpad/client-bank-accounts/put'
import getOurBankAccounts from '@galileo/api/launchpad/ourBankAccounts/get'
import postOpenBankingSessions from '@galileo/api/launchpad/openBanking/sessions/post'
import postOpenBankingRedirectVolt from '@galileo/api/launchpad/openBanking/redirect/volt/post'
import Card from "@galileo/models/CardAccount/Cards"

import {
  useAlert,
} from '@oen.web.vue2/ui'

import {
  BankAccount,
  CardAccount,
  PaymentAccount,
  PaymentAccountPayload,
  PaymentMethods,
  CardPaymentMethod,
  BankProvider,
  BankAccountNicknameResult,
} from '@galileo/models/payments/interfaces/paymentsTypes'

import { PAYMENT_METHODS } from '@galileo/constants/sendMoneyFlow.const'
import { VoltPaymentSessionResponse } from '@galileo/models/payments/VoltPaymentSessionResponse'
import { VoltPaymentSessionRequest } from '@galileo/models/payments/VoltPaymentSessionRequest'
import { PAYMENT_ERRORS } from '@galileo/constants/payments/payment.const'
import { APIError } from '@galileo/models/api/interfaces/APIError'

import {
  useSendMoneyStore,
  useCountriesStore,
  useDeviceStore,
  useAuthStore,
  useCardAccountStore,
  useQuickTransferStore,
  useI18nStore,
  useAppStore,
} from '@galileo/stores'

// @ts-ignore
import md5 from 'crypto-js/md5'
import useOpenBanking from '@galileo/composables/useOpenBanking'

export const usePaymentsStore = defineStore('payments', () => {
  function orderAlphaPaymentMethods(accounts: any) {
    return accounts.sort((a: any, b: any) => {
      return a.title.toLowerCase().localeCompare(b.title.toLowerCase())
    })
  }

  const paymentMethods = ref<PaymentMethods>({} as PaymentMethods)
  const selectedPaymentMethod = ref<CardAccount | BankAccount>({} as CardAccount | BankAccount) // Does this make any sense???

  const allPaymentMethods = ref<Array<BankAccount | CardAccount>>([])


  const bankVerificationCounter = reactive<Record<string, number>>({})

  const cardAccount = useCardAccountStore()
  const { $t } = useI18nStore()
  const { add } = useAlert()

  const getBankVerificationCounter = computed(() => (accountId: string) => {
    if (bankVerificationCounter[accountId]) {
      return bankVerificationCounter[accountId]
    }
  })

  const getPaymentMethodsByType = (accountType: string, isSMF = false) => {
    const sendMoneyStore = useSendMoneyStore()
    let selectedCurrency = sendMoneyStore.getCurrencyFrom

    let arrPaymentMethods = []

    if (accountType === PAYMENT_METHODS.CREDIT_CARD) {
      arrPaymentMethods = paymentMethods.value.cardAccounts.filter(
        (account) => !account.isDebitCard
      )
    } else if (accountType === PAYMENT_METHODS.DEBIT_CARD) {
      arrPaymentMethods = paymentMethods.value.cardAccounts.filter((account) => account.isDebitCard)
    } else if (accountType === PAYMENT_METHODS.DIRECT_DEBIT) {
      arrPaymentMethods = paymentMethods.value.bankAccounts
    } else {
      //default return all
      arrPaymentMethods = allPaymentMethods.value
    }

    if (isSMF) {
      arrPaymentMethods = arrPaymentMethods.filter(
        (pm) => pm.currency === selectedCurrency
      )
    }

    return orderAlphaPaymentMethods(arrPaymentMethods)
  }

  async function getPaymentMethods(currency: string | null = null): Promise<PaymentMethods> {

    const countriesStore = useCountriesStore()
    const user = useAuthStore().user
    try {
      if (!currency) {
        currency = countriesStore.getCountryDefaultCurrency
      }

      paymentMethods.value = await getPaymentAccountsV2.exec({
        profileId: user?.customer.id,
        country: user?.customer.country,
        currency,
      })
      if (useSendMoneyStore().getCardPaymentAvailable) {
        try {
          const cardSettings = await useCardAccountStore().fetchCardSettings()
          if (cardSettings?.cards) {
            paymentMethods.value.cardAccounts.forEach((cardAccount: CardAccount) => {
              const cardFromSettings = cardSettings.cards.find((card: Card) => card.id === cardAccount.id)
              if (cardFromSettings) {
                cardAccount.cardState = cardFromSettings.cardState
              }
            })
          }
        } catch (ex) {
          useAppStore().logException("Error fetching card settings", ex)
        }
      }
    } catch (ex) {
      console.log("Error fetching payment accounts ", ex);
      paymentMethods.value.bankAccounts = []
      paymentMethods.value.cardAccounts = []
    }

    if (paymentMethods.value) {
      allPaymentMethods.value = [
        ...paymentMethods.value.bankAccounts,
        ...paymentMethods.value.cardAccounts,
      ]
      return paymentMethods.value
    } else {
      throw new Error(`No data returned from API`)
    }
  }

  function setSelectedPaymentMethod(selectedMethod: CardAccount | BankAccount) {
    selectedPaymentMethod.value = selectedMethod
  }

  function getSelectedPaymentMethod(): CardAccount | BankAccount {
    return selectedPaymentMethod.value
  }

  const getSelectedBankAccount = computed(() => {
    const sendMoneyStore = useSendMoneyStore()
    const selectedPaymentMethodId = sendMoneyStore.form.paymentMethodId

    let result = null

    if (
      sendMoneyStore.form.paymentMethod === PAYMENT_METHODS.DIRECT_DEBIT &&
      paymentMethods.value.bankAccounts &&
      paymentMethods.value.bankAccounts.length > 0
    ) {
      const bankAccount = paymentMethods.value.bankAccounts.find((account) => {
        return account.id === selectedPaymentMethodId
      })

      if (bankAccount) {
        result = bankAccount
      }
    }

    return result
  })

  function decrementVerificationCounter(payment: CardAccount | BankAccount): void {
    if (payment.id) {
      Object.assign(bankVerificationCounter, {
        ...bankVerificationCounter,
        [payment.id]: --bankVerificationCounter[payment.id] || 3,
      })
    }
  }

  async function deletePaymentAccount(id: string): Promise<object | void> {
    const userProfile = useAuthStore().userProfile
    try {
      let result = null
      if (selectedPaymentMethod.value) {
        if (selectedPaymentMethod.value.isCard) {
          let deleteCall = deleteCardPaymentMethod
          if (cardAccount.useApexx || cardAccount.useAdyen) {
            deleteCall = deleteCardV2
          }
          const { data } = await deleteCall.exec({
            profileId: userProfile?.customer.id,
            cardId: id,
          })
          result = data
        } else {
          const selectedBankAccount = selectedPaymentMethod.value as BankAccount
          await deletePaymentMethodAccount.exec({
            versionNumber: selectedBankAccount.versionNumber,
            bankAccountId: selectedBankAccount.id,
          })
          result = selectedBankAccount.id
        }
      }

      if (result) {
        await getPaymentMethods()
        const { bankAccounts, cardAccounts } = paymentMethods.value

        paymentMethods.value = {
          bankAccounts: bankAccounts.filter((payment) => payment.id !== id),
          cardAccounts: cardAccounts.filter((payment) => payment.id !== id),
        }

        selectedPaymentMethod.value = {} as CardAccount | BankAccount

        const quickTransferStore = useQuickTransferStore()
        if (quickTransferStore.isCurrentTransactionQuickTransfer) {
          add($t('PageQuickTransfer.AccountDeletedAlert').value)
        }

        return result
      }
    } catch (e: APIError | any) {
      if (e.message === PAYMENT_ERRORS.CANNOT_DELETE_BANK_ACCOUNT) {
        throw e
      }
      throw new Error('Failed to delete payment method')
    }
  }

  function editSelectedPaymentMethod(paymentMethod: CardPaymentMethod): void {
    selectedPaymentMethod.value = {
      ...selectedPaymentMethod.value,
      ...paymentMethod,
    }
  }

  async function updateCardBillingAddressV2(
    paymentAccount: PaymentAccount,
    payload: PaymentAccountPayload
  ): Promise<object> {
    payload = {
      billingAddress: payload.billingAddress,
      billingAddressCounty: payload.billingState,
      billingAddressCity: payload.billingCity,
      billingAddressPostCode: payload.billingPostalCode,
      billingAddressCountry: payload.billingCountry,
    }

    const response = await editBillingAddress.exec({ cardId: paymentAccount.id, payload })
    return response
  }

  async function updateCardBillingAddressLegacy(
    paymentAccount: PaymentAccount,
    addressLegacyPayload: PaymentAccountPayload
  ): Promise<object> {
    let cardHolderName = addressLegacyPayload.cardHolderName

    if (cardHolderName) {
      const nameSurname = cardHolderName.split(' ')
      paymentAccount.firstName = nameSurname[0]
      nameSurname.shift()
      paymentAccount.lastName = nameSurname.join(' ')
    }
    const expDate = paymentAccount.expiryDate as string
    let xpDate = expDate.split('/')
    xpDate[1] = '20' + xpDate[1]
    let finalPayload = {
      currencyCode: paymentAccount.currency,
      cardTypeId: paymentAccount.isDebitCard ? 2 : 1,
      expiryDate: xpDate[0] + '-' + xpDate[1], //paymentAccount.expiryDate,
      firstName: paymentAccount.firstName,
      lastName: paymentAccount.lastName,
      billingAddress: {
        line1: addressLegacyPayload.billingAddress,
        line2: addressLegacyPayload.billingAddress2,
        state: addressLegacyPayload.billingState,
        place: addressLegacyPayload.billingCity,
        postCode: addressLegacyPayload.billingPostalCode,
        countryCode: addressLegacyPayload.billingCountry,
      },
    }

    const response = await editCardDetails.exec({
      cardId: paymentAccount.id,
      payload: finalPayload,
    })

    return response
  }

  async function updateCard(
    paymentAccount: PaymentAccount,
    payload: PaymentAccountPayload
  ): Promise<object> {
    let response = null
    if (cardAccount.useApexx || cardAccount.useAdyen) {
      response = await updateCardBillingAddressV2(paymentAccount, payload)
    } else {
      response = await updateCardBillingAddressLegacy(paymentAccount, payload)
    }

    if (response) {
      await getPaymentMethods()
    }

    return response
  }

  async function editBankAccount(payment: BankAccount): Promise<any> {
    const data = await editBankAccountDetails.exec(payment)

    selectedPaymentMethod.value = {} as CardAccount | BankAccount
    getPaymentMethods()
    return data
  } // IS THIS STILL IN USE??????????????????????????

  async function editBankAccountNickname(
    payment: BankAccount
  ): Promise<BankAccountNicknameResult | void> {
    try {
      const result: BankAccountNicknameResult = await editBankAccountNicknameDetails.exec(payment)
      if (result) {
        await getPaymentMethods()

        // send notification nickname changed
        const quickTransferStore = useQuickTransferStore()
        if (quickTransferStore.isCurrentTransactionQuickTransfer) {
          add($t('PageQuickTransfer.AccountInfoUpdatedAlert').value)
        }

        return result
      }
    } catch (ex) {
      throw ex
    }
  }

  function addCard(cardType: string): void {
    const userProfile = useAuthStore().user

    if (userProfile) {
      const newCard: PaymentAccount = {
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        billingCountry: userProfile.country,
        billingStreet: userProfile.address,
        billingCity: userProfile.city,
        billingState: userProfile.state,
        billingPostalCode: userProfile.postalCode,
        cardNumber: '',
        cardBrand: '',
        expirationDate: '',
        securityCode: '',
        isCard: true,
        cardType,
      }

      selectedPaymentMethod.value = newCard
    }
  }

  async function getBankProviders(currency: string): Promise<Array<BankProvider>> {
    const { data } = await getOurBankAccounts.exec(currency)
    return data
  }

  function createTransactionReference(): string {
    const dt = new Date()
    const deviceStore = useDeviceStore()
    let date = dt.getUTCFullYear() + '-' + (dt.getUTCMonth() + 1) + '-' + dt.getUTCDate()
    date += ' ' + dt.getUTCHours() + ':' + dt.getUTCMinutes() + ':' + dt.getUTCSeconds()

    let random = date
    random += '_' + new Date().getMilliseconds().toString() + '_' + deviceStore.getId + '_'
    for (let i = 0; i < 10; i++) {
      random += Math.floor(Math.random() * 10).toString()
    }
    random = md5(random)
    date = date.replace(/-/g, '').replace(/:/g, '').replace(/ /g, '')
    const trxRef = 'XW_' + date + '_' + random // careful that shouldn't be too long otherwise we could get CYB004 errors from Cybersource ! (i.e. 'XE_web_' + date + '_' + random)
    return trxRef
  }

  function resetForm(): void {
    paymentMethods.value = {
      bankAccounts: [],
      cardAccounts: [],
    }
  }

  const createVoltPaymentSessionPayload = (request: VoltPaymentSessionRequest, includeRecipient: boolean) => {
    const payload: any = {
      reference: request.reference,
      currency: request.currency,
      amount: request.amount,
      transferReason: request.transferReason,
    }
  
    if (includeRecipient) {
      payload.recipientId = request.recipientId;
    }
  
    return payload
  }

  async function getVoltSession(): Promise<VoltPaymentSessionResponse> {
    const sendMoneyStore = useSendMoneyStore()
    const openBanking = useOpenBanking()
    const includeRecipientId = !!(openBanking.isPayTo.value && sendMoneyStore.form.recipient)

    let voltPaymentRequest = new VoltPaymentSessionRequest(
      sendMoneyStore.form.transferId,
      sendMoneyStore.form.currencyFrom,
      sendMoneyStore.form.totalAmount,
      sendMoneyStore.form.transferReason,
      sendMoneyStore.form.recipient?.id
    )

    const payload = createVoltPaymentSessionPayload(voltPaymentRequest, includeRecipientId)

    let response = new VoltPaymentSessionResponse()

    try {
      const sessionResponse = await postOpenBankingSessions.exec(payload)

      response.data = sessionResponse.data
      response.provider = sessionResponse.provider
    } catch (ex: any) {
      let exception = { ...ex }
      if (exception != null) {
        response.errorCode = ex.response.data.ErrorCode
      }
    }

    return response
  }

  async function getVoltRedirect(
    voltEncodedPayload: string,
    voltSignature: string,
    voltTimestamp: number,
    currency: string
  ): Promise<string> {
    const payloadForRedirectVolt = {
      encodedPayload: voltEncodedPayload,
      signature: voltSignature,
      timestamp: Number(voltTimestamp),
      currency: currency,
    }

    const response = await postOpenBankingRedirectVolt.exec(payloadForRedirectVolt)
    return response
  }

  return {
    getSelectedBankAccount,
    getPaymentMethods,
    deletePaymentAccount,
    selectedPaymentMethod,
    editSelectedPaymentMethod,
    updateCardBillingAddressV2,
    updateCardBillingAddressLegacy,
    updateCard,
    editBankAccountNickname,
    addCard,
    getBankProviders,
    createTransactionReference,
    resetForm,
    paymentMethods,
    allPaymentMethods,
    getPaymentMethodsByType,
    setSelectedPaymentMethod,
    getSelectedPaymentMethod,
    editBankAccount,
    getBankVerificationCounter,
    decrementVerificationCounter,
    getVoltSession,
    getVoltRedirect,
  }
},
  {
    persist: {
      storage: sessionStorage,
    },
  }
)
