import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'
import { useI18nStore } from '@galileo/stores'

export default new APIHandler(
  API,
  (config) => {
    return {
      ...config,
      method: 'GET',
      url: `resources/industrytypesbycategory?language=${useI18nStore().localeLanguagePart}`,
    }
  },
  (response) => {
    return {
      ...response,
      data: response.data,
    }
  }
)
