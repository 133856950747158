import API from '@galileo/api/launchpad-cache'
import APIHandler from '@galileo/api/LaunchpadAPIHandler'

import { usePromotionStore } from '@galileo/stores'
export default new APIHandler(
  API,
  ({ country }, config) => {
    return {
      ...config,
      method: 'GET',
      url: `/promotionsdata/${country}`,
    }
  },
  ({ data }) => {
    const promotionStore = usePromotionStore()

    for (let promotion of data.promotions) {
      promotionStore.parsePromotionData(promotion)
    }

    return [...data.promotions]
  }
)
