import { render, staticRenderFns } from "./KYCRefreshCorporateBusinessName.vue?vue&type=template&id=8c11efe8&scoped=true&"
import script from "./KYCRefreshCorporateBusinessName.vue?vue&type=script&lang=ts&"
export * from "./KYCRefreshCorporateBusinessName.vue?vue&type=script&lang=ts&"
import style0 from "./KYCRefreshCorporateBusinessName.vue?vue&type=style&index=0&id=8c11efe8&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.2_babel-core@7.0.0-bridge.0_@babel+core@7.26.0__cache-loader@4.1.0_webp_dbc1f76d1931dbdfddfdff69f826e4c8/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c11efe8",
  null
  
)

export default component.exports