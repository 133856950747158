import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/LaunchpadAPIHandler'

export default new APIHandler(
  API,
  (payloadSession, config) => {
    return {
      ...config,
      method: 'POST',
      url: 'OpenBanking/sessions',
      data: payloadSession,
    }
  },
  (response) => {
    if (response?.data) {
      return response.data
    } else {
      throw new Error('Failed to fetch Volt session.')
    }
  }
)
