import API, { ResponseFromCTApi } from '@galileo/api/ct'
import APIHandler from '@galileo/api/APIHandler'
import CstField from '@galileo/models/DynamicField/CstField'

export default new APIHandler(
  API,
  (countryId, config) => {
    return {
      ...config,
      method: 'GET',
      url: 'Customer/MissingRequiredFields',
    }
  },
  (response) => {
    const data = new ResponseFromCTApi(null, response.data)
    let fields = null
    if (data.status.code !== 1) {
      fields = []
      let startStep = 0
      if (data.status.code === 40) {
        // skip the first step
        startStep = 1
      }
      for (let i = startStep; i < response.data.Value.length; i++) {
        const step = response.data.Value[i]
        for (let f = 0; f < step.Fields.length; f++) {
          const rawField = step.Fields[f]
          const field = new CstField(rawField)
          if (!field.readonly) {
            fields.push(field)
          }
        }

        if (data.status.code === 20) {
          // in that case only the first step
          break
        }
      }
    }
    return {
      ...response,
      data: fields,
    }
  }
)
