import API, { ResponseFromCTApi } from '@galileo/api/ct'
import APIHandler from '@galileo/api/APIHandler'
import { DELIVERY_TYPE } from '@galileo/models/Cst/appVariables'

class OtpValidateResponse {
  constructor(validateOtpResponse) {
    const { SessionId, SecurityCode, Code, MaskedEmail, MaskedPhone } = validateOtpResponse || {}
    this.sessionId = SessionId
    this.securityCode = SecurityCode
    this.code = Code
    this.maskedEmail = MaskedEmail
    this.maskedPhone = MaskedPhone
  }
}

export default new APIHandler(
  API,
  (
    { action, referenceValue, securityCode, code, deviceId, deviceSimCard, deliveryType },
    config
  ) => {
    return {
      ...config,
      method: 'POST',
      url: 'OTP',
      data: {
        action,
        referenceValue,
        securityCode,
        code,
        deviceId,
        deviceSimCard,
        deliveryType: DELIVERY_TYPE[deliveryType.toUpperCase()],
      },
    }
  },
  (response) => {
    const { data: responseData } = response
    if (!responseData) {
      throw new Error('Failed to verify OTP')
    }

    const data = new ResponseFromCTApi(null, responseData)

    if (data.status.code !== 1) {
      throw new Error(`${data.status.code} ${data.status.message}`)
    }

    data.result = new OtpValidateResponse(responseData.Value)

    return {
      ...response,
      data,
    }
  }
)
