export class VoltPaymentSessionRequest {
  reference: string | null = null
  currency: string | null = null
  amount: number | null = null
  transferReason: string | null = null
  recipientId: string | number | null = null

  constructor(
    reference: string | null = null,
    currency: string | null = null,
    amount: number | null = null,
    transferReason: string | null = null,
    recipientId: string | number | null = null) {
    this.reference = reference
    this.currency = currency
    this.amount = amount
    this.transferReason = transferReason
    this.recipientId = recipientId
  }
}