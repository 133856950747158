import { reactive, ref } from '@vue/composition-api'

import { useDeviceStore, useResourcesStore, useI18nStore, useCountriesStore } from '@galileo/stores'

export default (field) => {
  const props = {}
  props.analyticsName = 'dynamic-form-field-' + field.id.toString().toLowerCase()

  if (field.useEnrichment) {
    props.loading = ref(false)
  }

  if (field.options && field.options.length > 0) {
    let options = field.options.filter((option) => option.id)
    options = options.map((option) => {
      return {
        value: option.id.toString(),
        text: option.name,
      }
    })
    props.options = ref(options)
  } else if (field.hasAsyncValues) {
    props.options = [{}]
    props.loading = false
  }

  if (props.options) {
    const i18nStore = useI18nStore()
    props.placeholder =
      field.options.filter((option) => option.id === '')[0]?.name ||
      i18nStore.$t('UseFieldPropsHelper.DropDownDefaultText').value
  }

  const countriesStore = useCountriesStore()

  if (field.type === 'phoneNumber') {
    props.options = countriesStore.offeredCountriesDialingCodesOptions
  } else if (field.type === 'country') {
    const resourcesStore = useResourcesStore()
    props.countries = resourcesStore.getCountries
  } else if (field.type === 'date') {
    // TODO - Get input format based of locale data
    const deviceStore = useDeviceStore()
    const countryId = deviceStore.getCountryId
    const countryConfig = countriesStore.getCountryConfigById(countryId)
    props.inputFormat = countryConfig.dateFormat
  }

  if (props.options || props.countries) {
    props.selectedDisplayField = 'text'
    props.selectedValueField = 'value'
  }

  if (field.placeholder) {
    props.placeholder = field.placeholder
  } else if (!props.placeholder) {
    props.placeholder = 'Enter ' + field.label // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  }
  const parenthesesRemoverRegex = / \([^)]*\)/g
  props.placeholder = props.placeholder.replace(parenthesesRemoverRegex, '').trim() // remove any comments present in the label (i.e. 'Street (Spain)' or 'Phone number (optional)')

  if (field.mask) {
    props.mask = field.mask
    props.inputmode = field.inputmode
    // to get the value masked instead of raw (i.e. for SSN we would otherwise get 123456789 instead of 123-45-6789)
    props.masked = true
    if (field.masked === false) {
      props.masked = false
    }
    // props.masked = field.masked
  }

  if (!field.shouldDisplayLabels) {
    props.shouldDisplayLabels = field.shouldDisplayLabels
  }

  props.tooltipText = field.tooltipText
  props.validation = field.validation

  return reactive(props)
}
