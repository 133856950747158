import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'
import { CalculateResponse } from '@galileo/api/launchpad/quotes/v2/CalculateResponse'
import { useI18nStore, useAuthStore } from '@galileo/stores'
import { GALILEO_PLATFORM } from '@galileo/constants/platformType'

export default new APIHandler(
  API,
  ({ quoteId, paymentMethod, controller }, config) => {
    const { user } = useAuthStore()
    return {
      ...config,
      signal: controller?.signal,
      method: 'GET',
      url: `v2/quotes/${quoteId}?refreshPaymentMethods=false&quoteId=${quoteId}&paymentMethod=${paymentMethod}&language=${
        useI18nStore().localeLanguagePart
      }&country=${user.country}&platformType=${GALILEO_PLATFORM}`,
    }
  },
  (response) => {
    const calculateResponse = new CalculateResponse(response.data)
    return {
      ...response,
      data: calculateResponse,
    }
  }
)
