// Imports
import Cookies from 'js-cookie'
const clientAffiliateCookie = 'HiFX_ClientAffiliate'
const dfxCookie = 'HiFX_DfxId'

// Consent model validation (Check value type)
export const validateConsentModel = (consent) => {
  try {
    const { performance, marketing, compliance } = consent

    return (typeof(performance) === 'boolean' && typeof(marketing) === 'boolean' && compliance === true)
  } catch (_e) {
    return false
  }
}

// Gets the consent from cookie
export const getConsentFromCookie = () => {
  // Get consent
  const rawCookie = Cookies.get('xeConsentState')
  const consentCookie = JSON.parse(decodeURIComponent(rawCookie || "{}"))

  // Validate consent model state
  if (validateConsentModel(consentCookie)) {
    return consentCookie
  }
  return false;
}

// Gets the consent from datalayer
export const getConsentFromDatalayer = () => {
  const { dataLayer } = window
  const lastConsentChange = dataLayer.filter(value => value.event === 'consentStateChange').pop();
  if (validateConsentModel(lastConsentChange)) {
    return lastConsentChange
  }
  return {
    performance: true,
    marketing: true,
    compliance: true,
  }
}

// Set Dfxid cookie
export const setDfxIdCookie = (queryParams) => {
  const _dfxid_reg = /^[0-9]{1,10}$/
  const _dfxid = queryParams.get('_dfxid')
  if(_dfxid_reg.test(_dfxid)) {
    
    const current_dfxid = Cookies.get(dfxCookie)
    if (current_dfxid === undefined || current_dfxid !== _dfxid) {
      Cookies.set(dfxCookie, `_dfxid=${_dfxid}`, { expires: 28})
    }
  }
}

// Set client affiliate cookie
export const setClienAffiliateCookie = (queryParams) => {
  const _clientagentid_reg = /^[0-9|A-z]{14}$/
  const _clientagentid = queryParams.get('_clientagentid')
  if(_clientagentid_reg.test(_clientagentid)) {
    
    const current_clientAffiliate = Cookies.get(clientAffiliateCookie)
    if (current_clientAffiliate === undefined || current_clientAffiliate !== _clientagentid) {
      Cookies.set(clientAffiliateCookie, `_clientagentid=${_clientagentid}`, { expires: 28 })
    }
  }
}

export const cleanMarketingCookies = () => {
  Cookies.remove(dfxCookie)
  Cookies.remove(clientAffiliateCookie)
}
