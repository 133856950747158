import { defineStore } from 'pinia'
import { ref, reactive, computed } from '@vue/composition-api'

import { default as CTAPI } from '@galileo/api/ct'
import { default as RMTAPI } from '@galileo/api/rmt'
import { default as LaunchpadAPI } from '@galileo/api/launchpad'
import { default as CorporateAPI } from '@galileo/api/corporate'
import Cookies from 'universal-cookie'
import { timeInSeconds } from '@galileo/composables/useTimeHelpers'


import { useDeviceStore, useEnvStore } from '@galileo/stores'

import { Environment } from '@galileo/models/Dev/interfaces/Environment'
export const DEFAULT_ENVIRONMENT = 'Default'

export const useDevStore = defineStore(
  'dev',
  () => {
    const environment = ref<string>(DEFAULT_ENVIRONMENT)

    const environments: Record<string, Environment> = reactive({
      Default: {
        cst: 'https://staging-customertoolkit.riamoneytransfer.com/api/',
        remittance: 'https://staging-public.riamoneytransfer.com/',
        launchpad: 'https://launchpad-api-staging.xe.com/',
        corporate: 'https://corpreg-api-staging.xe.com/',
      },
      QA1: {
        cst: 'https://qa01-customertoolkit.riamoneytransfer.com/api/',
        remittance: 'https://qa01-public.riamoneytransfer.com/',
        launchpad: 'https://launchpad-api-integration.xe.com/',
        corporate: 'https://corpreg-api-staging.xe.com/',
      },
      Prod: {
        cst: 'https://customertoolkit.riaenvia.net/api/',
        remittance: 'https://public.riamoneytransfer.com/',
        launchpad: 'https://launchpad-api.xe.com/',
        corporate: 'https://corpreg-api.xe.com/',
      },
    })

    const shouldFakePublicSiteCookie = ref<boolean>(false)

    const getSelectedEnvironment = computed((): Environment => {
      return environments[environment.value]
    })

    function addDefaultEnvironment(): void {
      const envStore = useEnvStore()
      environments['Default'] = {
        cst: envStore.env.VUE_APP_ENDPOINTS_CTAPI,
        remittance: envStore.env.VUE_APP_ENDPOINTS_RMTAPI,
        launchpad: envStore.appLaunchpadEndpoint,
        corporate: envStore.env.VUE_APP_ENDPOINTS_CORPORATEAPI,
      }
    }

    function setEnvironment(env: string): void {
      environment.value = env
      let selectedEnv = getSelectedEnvironment.value
      if (CTAPI.defaults.baseURL !== selectedEnv.cst) {
        CTAPI.defaults.baseURL = selectedEnv.cst
        RMTAPI.defaults.baseURL = selectedEnv.remittance

        LaunchpadAPI.defaults.baseURL = selectedEnv.launchpad
        CorporateAPI.defaults.baseURL = selectedEnv.corporate
      }
    }

    function setEnvironments(envs: Record<string, Environment>): void {
      Object.assign(environments, envs)
    }

    function setLocaleCookie(): void {
      const cookies = new Cookies()

      const data = {
        i18n: {
          locale: 'es-US',
        },
      }

      const options = {
        domain: '',
        path: '/',
        secure: true,
        maxAge: timeInSeconds(1),
      }

      cookies.set('public-private', data, options)
    }

    function setShouldFakePublicSiteCookie(value: boolean): void {
      shouldFakePublicSiteCookie.value = value
    }

    function init(): void {
      const deviceStore = useDeviceStore()
      if (environment.value) {
        setEnvironment(environment.value)
      }
      if (shouldFakePublicSiteCookie.value) {
        setTimeout(() => {
          setShouldFakePublicSiteCookie(false)
        }, 1000)
        deviceStore.resetLocale()
        setLocaleCookie()
      }
    }

    return {
      environment,
      environments,
      shouldFakePublicSiteCookie,
      getSelectedEnvironment,
      addDefaultEnvironment,
      setEnvironment,
      setEnvironments,
      setLocaleCookie,
      setShouldFakePublicSiteCookie,
      init,
    }
  },
  {
    persist: {
      storage: sessionStorage,
    }
  }
)
