import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'
import Recipient from '@galileo/models/Recipient/app'

import { CashPickupRecipientResult } from '@galileo/models/Recipient/SpecificRecipientResult/app.js'

//#region Api Response
/* 
XE Cashrecipient response
[{
"fields":   
  [{
      "name": "Id",
      "value": "2565473"
  }, {
      "name": "FirstName",
      "value": "CPO"
  }, {
      "name": "LastNames",
      "value": "Test"
  }, {
      "name": "AddressState",
      "value": "Baja California"
  }, {
      "name": "AddressCity",
      "value": "Tijuana"
  }, {
      "name": "CurrencyCode",
      "value": "MXN"
  }, {
      "name": "CountryCode",
      "value": "MX"
  }]
}]
*/
//#endregion

export default new APIHandler(
  API,
  (config) => {
    return {
      ...config,
      method: 'GET',
      url: `CashRecipients`,
    }
  },
  (response) => {
    return {
      ...response,
      data: response.data.map((profile) => {
        const result = new CashPickupRecipientResult(profile)
        return new Recipient(result)
      }),
    }
  }
)
