<template>
  <div class="layout-fill">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'LayoutFill',
  emits: [],
  props: {},
}
</script>

<style scoped>
.layout-fill {
  @apply absolute;
  @apply w-full h-full;
}
</style>
