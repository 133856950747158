export const ADYEN_CARD_BRAND = {
    MASTERCARD: 'mc',
    VISA: 'visa',
}

export const ADYEN_CARD_TYPE = {
    DEBIT: 'debit',
    CREDIT: 'credit',
}

export interface AdyenData {
    binValue: string
    panLastDigits: string
    paymentMethod?: {
        [key: string]: any
        checkoutAttemptId?: string
    }
    isValid: boolean
    validationError: Nullable<string>
    cardFieldsLoaded: boolean
    cardBrand: string
    cardType: Nullable<string>
    loading: boolean
}

export interface CbObjOnBrand {
    type: string
    rootNode: HTMLElement
    brand: string
    cvcPolicy: CVCPolicyType
    expiryDatePolicy?: DatePolicyType
    cvcText: string
    showSocialSecurityNumber?: boolean
    brandImageUrl?: string
}

type CVCPolicyType = typeof REQUIRED | typeof OPTIONAL | typeof HIDDEN
type DatePolicyType = typeof REQUIRED | typeof OPTIONAL | typeof HIDDEN

export interface CbObjOnBinValue {
    type: string
    binValue: string
    uuid?: string
    encryptedBin?: string
}

export interface CbObjOnBinLookup {
    type?: string
    detectedBrands?: string[]
    supportedBrands?: string[]
    brands?: string[]
    issuingCountryCode?: string
    supportedBrandsRaw?: BrandObject[]
    rootNode?: HTMLElement
    isReset?: boolean
}

export interface BrandObject {
    brand: string
    cvcPolicy: CVCPolicyType
    enableLuhnCheck: boolean
    showExpiryDate?: boolean
    expiryDatePolicy?: DatePolicyType
    showSocialSecurityNumber?: boolean
    supported: boolean
    brandImageUrl?: string
    panLength?: number
    paymentMethodVariant?: string
}

declare const REQUIRED = 'required'
declare const OPTIONAL = 'optional'
declare const HIDDEN = 'hidden'

export interface CbObjOnFieldValid {
    fieldType: string
    encryptedFieldName: string
    uid: string
    valid: boolean
    type: string
    rootNode: HTMLElement
    blob?: string
    endDigits?: string
    expiryDate?: string
    issuerBin?: number
}
