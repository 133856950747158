import {
  useSendMoneyStore,
  useI18nStore,
  useAuthStore,
  useDeviceStore,
  useAppStore,
} from '@galileo/stores'
import { DELIVERY_METHODS } from '@galileo/constants/sendMoneyFlow.const'
import { formatTimeWithTimezone } from '@galileo/composables/useTimeHelpers'
import { Quote } from '@galileo/models/SendMoney/interfaces/index'
import { formatCurrency } from '@galileo/composables/useCurrency'
import DeliveryMethodType, {
  Parameter,
} from '@galileo/models/SendMoney/interfaces/DeliveryMethodType'
import { PaymentMethod } from '@galileo/models/Transaction/app'
import {
  UNAVAILABLE_REASON,
  FUND_BALANCE_DELIVERY_ERRORS,
} from '@galileo/constants/quoteErrorsUnavailableReasons.const'
import HelpDesk, { HelpDeskProperties } from '@galileo/constants/helpdesk.const'

export default function () {
  const sendMoneyStore = useSendMoneyStore()
  const i18nStore = useI18nStore()
  const authStore = useAuthStore()
  const appStore = useAppStore()

  const getBadRequestErrorText = (errorMessages: any) => {
    for (const errorKey in errorMessages) {
      const mappedBadRequestError = {
        code: errorKey,
        parameters: Object.entries(errorMessages[errorKey]).map(([key, value]) => {
          return { key, value }
        }),
      }

      let quoteError = i18nStore.$t('QuoteErrors.' + errorKey).value

      return replaceParameters(mappedBadRequestError.parameters as Array<Parameter>, quoteError)
    }
  }

  const setFundOnBalanceDeliveryErrorText = () => {
    let errorText = null
    const fundsOnBalanceMethod: Nullable<DeliveryMethodType> =
      sendMoneyStore.form.tempDeliveryMethods?.find(
        (method: DeliveryMethodType) => method.value === DELIVERY_METHODS.FUNDS_ON_BALANCE
      ) ?? null

    if (!fundsOnBalanceMethod?.unavailableReason) return

    const { parameters, code } = fundsOnBalanceMethod.unavailableReason

    const getParameter = (key: string) => parameters.find((item: Parameter) => item.key === key)

    const ccy = getParameter('ccy')
    const minAmount = getParameter('minAmount')
    const maxAmount = getParameter('maxAmount')

    switch (code) {
      case FUND_BALANCE_DELIVERY_ERRORS.QUOTE101.CODE:
        errorText = i18nStore.$t(FUND_BALANCE_DELIVERY_ERRORS.QUOTE101.TEXT_KEY, {
          toCurrency: ccy?.value,
          MinAmount: formatCurrency(ccy?.value, Number(minAmount?.value), useDeviceStore().locale),
        }).value
        break
      case FUND_BALANCE_DELIVERY_ERRORS.QUOTE102.CODE:
        errorText = i18nStore.$t(FUND_BALANCE_DELIVERY_ERRORS.QUOTE102.TEXT_KEY, {
          toCurrency: ccy?.value,
          MaxAmount: formatCurrency(ccy?.value, Number(maxAmount?.value), useDeviceStore().locale),
        }).value
        break
      default:
        errorText = i18nStore.$t(UNAVAILABLE_REASON.GENERIC_ERROR.TEXT_KEY).value
        break
    }

    return errorText
  }

  const setQuoteErrorText = (quote: Quote) => {
    const isCorporate = authStore.isCorporateAccount
    // const country = authStore.userProfile?.country

    const HelpCenterLink = appStore.getHelpDeskUrl(
      HelpDesk[HelpDeskProperties.CONTACT_DETAILS],
      HelpDeskProperties.CONTACT_DETAILS
    )

    let isQuoteErrorTo = false
    let quoteErrorText = ''

    const paymentMethodTextMap = {
      [PaymentMethod.DirectDebit.enumName]: i18nStore.$t('PaymentMethodText.DirectDebit').value,
      [PaymentMethod.CreditCard.enumName]: i18nStore.$t('PaymentMethodText.CreditCard').value,
      [PaymentMethod.DebitCard.enumName]: i18nStore.$t('PaymentMethodText.DebitCard').value,
      [PaymentMethod.OpenBanking.enumName]: i18nStore.$t('PaymentMethodText.OpenBanking').value,
      [PaymentMethod.Interac.enumName]: i18nStore.$t('PaymentMethodText.Interac').value,
      [PaymentMethod.FundsOnBalance.enumName]: i18nStore.$t('PaymentMethodText.FundsOnBalance')
        .value,
    }

    const paymentMethodTextFormatted =
      paymentMethodTextMap[quote.paymentMethod] ||
      i18nStore.$t('PaymentMethodText.BankTransfer').value

    let errorKey: string

    const addParameter = (key: string, value: string) => {
      quote.unavailableReason?.parameters?.push({
        key: key,
        value: value,
      })
    }

    switch (quote.unavailableReason?.code) {
      case UNAVAILABLE_REASON.FIX_AMOUNT_ABOVE_MAX_AMOUNT.CODE:
        addParameter('ccycode', quote.fixedCcy)
        errorKey = isCorporate
          ? UNAVAILABLE_REASON.FIX_AMOUNT_ABOVE_MAX_AMOUNT.TEXT_KEY
          : UNAVAILABLE_REASON.FIX_AMOUNT_ABOVE_MAX_AMOUNT.TEXT_KEY_CONSUMERS
        break

      case UNAVAILABLE_REASON.NON_FIXED_AMOUNT_ABOVE_MAX_AMOUNT.CODE:
        addParameter('ccycode', quote.nonFixedCcy)
        errorKey = isCorporate
          ? UNAVAILABLE_REASON.FIX_AMOUNT_ABOVE_MAX_AMOUNT.TEXT_KEY
          : UNAVAILABLE_REASON.FIX_AMOUNT_ABOVE_MAX_AMOUNT.TEXT_KEY_CONSUMERS
        break

      case UNAVAILABLE_REASON.CLIENT_EXPOSURE_LIMIT_EXCEEDED.CODE:
        errorKey = UNAVAILABLE_REASON.CLIENT_EXPOSURE_LIMIT_EXCEEDED.TEXT_KEY
        if (sendMoneyStore.form.shouldCalcAmountFrom) {
          isQuoteErrorTo = true
        }
        break

      case UNAVAILABLE_REASON.NON_FIXED_AMOUNT_BELOW_MIN_AMOUNT.CODE:
        addParameter('ccycode', quote.nonFixedCcy)
        errorKey = UNAVAILABLE_REASON.NON_FIXED_AMOUNT_BELOW_MIN_AMOUNT.TEXT_KEY
        break

      case UNAVAILABLE_REASON.MAX_TRADE_AMOUNT_PER_DAY_EXCEEDED.CODE:
        errorKey = UNAVAILABLE_REASON.MAX_TRADE_AMOUNT_PER_DAY_EXCEEDED.TEXT_KEY
        break

      case UNAVAILABLE_REASON.MAX_FAILED_CARD_AUTHORIZATION_ATTEMPTS_EXCEEDED.CODE:
        errorKey = UNAVAILABLE_REASON.MAX_FAILED_CARD_AUTHORIZATION_ATTEMPTS_EXCEEDED.TEXT_KEY
        break

      case UNAVAILABLE_REASON.DAILY_TRADE_AMOUNT_EXCEEDED.CODE:
        errorKey = UNAVAILABLE_REASON.DAILY_TRADE_AMOUNT_EXCEEDED.TEXT_KEY
        break

      case UNAVAILABLE_REASON.DAILY_TRADE_COUNT_EXCEEDED.CODE:
        errorKey = UNAVAILABLE_REASON.DAILY_TRADE_COUNT_EXCEEDED.TEXT_KEY
        break

      case UNAVAILABLE_REASON.FIXED_AMOUNT_BELOW_MIN_AMOUNT.CODE:
        addParameter('ccycode', quote.fixedCcy)
        errorKey = UNAVAILABLE_REASON.FIXED_AMOUNT_BELOW_MIN_AMOUNT.TEXT_KEY
        if (sendMoneyStore.form.shouldCalcAmountFrom) {
          isQuoteErrorTo = true
        }
        // if (country === 'US' && quote.paymentMethod === PaymentMethod.BankTransfer.enumName) {
        //   errorKey = UNAVAILABLE_REASON.FIXED_AMOUNT_BELOW_MIN_AMOUNT.TEXT_KEY_WIRE_TRANSFER
        // }
        break

      case UNAVAILABLE_REASON.MAX_DIRECT_DEBIT_TRADES_PER_DAY_EXCEEDED.CODE:
      case UNAVAILABLE_REASON.MAX_DIRECT_DEBIT_AMOUNT_PER_PERIOD_EXCEEDED.CODE:
        errorKey = UNAVAILABLE_REASON.MAX_DIRECT_DEBIT_TRADES_PER_DAY_EXCEEDED.TEXT_KEY
        break

      case UNAVAILABLE_REASON.MAX_FAILED_CARD_AUTHENTICATION_ATTEMPTS_EXCEEDED.CODE:
        errorKey = UNAVAILABLE_REASON.MAX_FAILED_CARD_AUTHENTICATION_ATTEMPTS_EXCEEDED.TEXT_KEY
        break

      case UNAVAILABLE_REASON.INSUFFICIENT_BALANCE_FUNDS.CODE:
        errorKey = UNAVAILABLE_REASON.INSUFFICIENT_BALANCE_FUNDS.TEXT_KEY
        break

      default:
        errorKey = UNAVAILABLE_REASON.GENERIC_ERROR.TEXT_KEY
        break
    }

    if (errorKey) {
      quoteErrorText = i18nStore.$t(errorKey, {
        HelpCenterLink,
        paymentMethodTextFormatted,
      }).value
    }

    if (quoteErrorText && quote.unavailableReason?.parameters) {
      quoteErrorText = replaceParameters(quote.unavailableReason.parameters, quoteErrorText)
    }

    return {
      quoteErrorText,
      isQuoteErrorTo,
    }
  }

  const replaceParameters = (parameters: Array<Parameter>, quoteErrorText: string) => {
    const mappedParameters: Record<string, any> = {}
    parameters.forEach((param: any) => {
      mappedParameters[param.key] = param.value
    })

    let currency = sendMoneyStore.form.currencyFrom
    if (mappedParameters.ccycode) {
      currency = mappedParameters.ccycode
    }

    const replacements: Record<string, string> = {
      '{MinAmount}': `${currency} ${formatCurrency(
        currency,
        mappedParameters.MinAmount || mappedParameters.minAmount,
        useDeviceStore().locale
      )}`,
      '{MaxAmount}': `${currency} ${formatCurrency(
        currency,
        mappedParameters.MaxAmount || mappedParameters.maxAmount,
        useDeviceStore().locale
      )}`,
      '{StartTime}':
        mappedParameters.StartTime ||
        formatTimeWithTimezone(mappedParameters.openingtime, mappedParameters.timezonename),
      '{EndTime}':
        mappedParameters.EndTime ||
        formatTimeWithTimezone(mappedParameters.closingtime, mappedParameters.timezonename),
      '{timeZone}': mappedParameters.timezonename || mappedParameters.timeZone,
      '{fromCurrency}': sendMoneyStore.form.currencyFrom,
      '{toCurrency}': sendMoneyStore.form.currencyTo,
      '{currency}': sendMoneyStore.form.currencyFrom,
      '{ccyCode}': currency,
    }

    Object.keys(replacements).forEach((placeholder) => {
      quoteErrorText = quoteErrorText.replace(placeholder, replacements[placeholder] || '')
    })

    quoteErrorText = quoteErrorText.replace(/{/g, '').replace(/}/g, '')

    return quoteErrorText
  }

  return {
    setFundOnBalanceDeliveryErrorText,
    setQuoteErrorText,
    getBadRequestErrorText,
  }
}
