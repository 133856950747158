<template>
  <AppDialog v-if="messageBox" v-model="hasMessage">
    <template #header>
      <AppDialogHeader>
        <h3 v-if="messageBox.title">{{ messageBox.title }}</h3>
      </AppDialogHeader>
    </template>
    <p>
      {{ messageBox.text }}
    </p>
    <template #footer>
      <AppDialogFooter v-if="messageBox.buttons">
        <AppButton
          v-for="(button, idx) of messageBox.buttons"
          :key="idx"
          :analytics-name="analyticsName ? analyticsName + '-button-' + idx : null"
          :theme="button.theme"
          @click="onMessageBoxButton(button)"
        >
          {{ button.text }}
        </AppButton>
      </AppDialogFooter>
    </template>
  </AppDialog>
</template>

<script>
import { computed } from '@vue/composition-api'

import { AppDialogFooter, AppButton, AppDialogHeader, AppDialog } from '@oen.web.vue2/ui'

import { useEnvStore, useAppStore } from '@galileo/stores'

export default {
  name: 'XeMessageBox',
  components: { AppDialogFooter, AppButton, AppDialogHeader, AppDialog },
  props: {
    analyticsName: {
      type: String,
      default: 'message-box',
      required: () => {
        const envStore = useEnvStore()
        return !!envStore.env.VUE_APP_ANALYTICS_ENABLED
      },
    },
  },
  setup() {
    const appStore = useAppStore()
    const messageBox = computed(() => appStore.getMessageBox)
    const hasMessage = computed(() => appStore.getHasMessage)
    const onMessageBoxButton = (button) => {
      if (button.click) {
        if (!button.click()) {
          return
        }
      }
      appStore.hideMessageBox()
    }
    return {
      messageBox,
      hasMessage,
      onMessageBoxButton,
    }
  },
}
</script>

<style scoped>
::v-deep .dialog--card.card {
  @apply pb-3 px-5 !important;
}
</style>
