











































































import { computed, defineComponent, ref } from '@vue/composition-api'
import IconEdit from '@oen.web.vue2/icons/src/components/carbon/IconEdit.vue'
import { storeToRefs } from 'pinia'
import {
  useI18nStore,
  useCorporateStore,
  useKYCRefreshCorporateStore,
  useAnalyticsStore,
} from '@galileo/stores'

// Components
import XeBadge from '@galileo/components/XeBadge/XeBadge.vue'
import {
  AppModal,
  AppModalFooter,
  AppButton,
  AppDescriptionListItem,
  AppIcon,
  AppSpinner,
  AppListItemTitle,
  AppListItem,
  AppList,
  AppListItemCaption,
} from '@oen.web.vue2/ui'

// Steps
import KYCRefreshCorporateCard from './KYCRefreshCorporateCard.vue'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'
import { KycRefreshCorporateStepEnum } from '@galileo/stores/kycRefreshCorporate'

export default defineComponent({
  name: 'KYCRefreshModalConfirmation',
  components: {
    AppListItemTitle,
    AppListItem,
    AppList,
    AppListItemCaption,
    AppButton,
    AppDescriptionListItem,
    AppModalFooter,
    AppModal,
    AppIcon,
    AppSpinner,
    KYCRefreshCorporateCard,
    XeBadge,
    IconEdit,
  },
  setup() {
    const { $t } = useI18nStore()
    const corporateStore = useCorporateStore()
    const kycRefreshCorporateStore = useKYCRefreshCorporateStore()
    const analyticsStore = useAnalyticsStore()
    const { accountDetailsData } = storeToRefs(kycRefreshCorporateStore)

    const isLoading = ref(false)

    // Content
    const showGoHomeBtn = computed(() => kycRefreshCorporateStore.showGoHomeBtn)

    // Submission
    const isSaving = ref(false)
    const confirmDetails = async (): Promise<void> => {
      isSaving.value = true
      // Save new ekyc refresh and fetch client info (Update EKYC refresh date)
      try {
        await corporateStore.submitEkycRefresh(kycRefreshCorporateStore.parseEkycData)
      } catch (error) {
        kycRefreshCorporateStore.hideModal()
        return
      }
      kycRefreshCorporateStore.savedEKYCStatus = kycRefreshCorporateStore.status
      await corporateStore.fetchClientInfo()
      // Trigger "KYC Refresh Completed" event
      analyticsStore.track({ event: SEGMENT_EVENTS.KYC_REFRESH_COMPLETED })
      // Go to success modal
      kycRefreshCorporateStore.goToStep(KycRefreshCorporateStepEnum.SUCCESS)
      isSaving.value = false
    }
    const notNowClicked = (): void => {
      kycRefreshCorporateStore.hideModal()
    }
    const onEdit = (stepName: KycRefreshCorporateStepEnum, detailName: string) => {
      // Trigger "KYC Refresh Detail Edit Started" event
      analyticsStore.track({
        event: SEGMENT_EVENTS.KYC_REFRESH_DETAIL_EDIT_STARTED,
        traits: { detailName }
      })
      kycRefreshCorporateStore.goToStep(stepName)
    }

    return {
      $t,
      confirmDetails,
      notNowClicked,
      accountDetailsData,
      isLoading,
      isSaving,
      goToStep: kycRefreshCorporateStore.goToStep,
      isOverdue: kycRefreshCorporateStore.isOverdue,
      showGoHomeBtn,
      onEdit,
    }
  },
})
