import { useI18nStore } from '@galileo/stores'

const disallowedFullNameWordsAndCharactersRegex = new RegExp(
  /(?:(^\s*(Mr|Mrs|Ms|Miss)\b)|(\s)(\b(and|or|et|ou|y)\b)(\s)|(&|\+|\\|\/))/i
)

const allowedFullNameCharactersRegex = new RegExp(/^[a-zA-Z0-9\s\(\)\+\-'/\?\.:,]{1,70}$/m)
const allowedFullNameCharactersExtendedRegex = new RegExp(
  /^[a-zA-Z\p{Script=Latin}0-9\s\(\)\+\-'/\?\.:,]{1,70}$/mu
)

function useFullNameValidation(fullNameField, isBusinessAccount = false) {
  const { $t } = useI18nStore()

  const containsInvalidWordsOrPrefixes = {
    $validator(inputValue) {
      if (!isBusinessAccount) {
        return !disallowedFullNameWordsAndCharactersRegex.test(inputValue)
      } else {
        return true
      }
    },
    $message: $t('ComponentModifyRecipientModal.DialogFullNameInfo.ValidationTextFullName').value,
  }

  const containsInvalidCharacters = {
    $validator(inputValue, context) {
      if (
        !(
          (context[fullNameField].containsInvalidWordsOrPrefixes &&
            context[fullNameField].containsInvalidWordsOrPrefixes.$invalid) ||
          context[fullNameField].valueRequired.$invalid
        ) &&
        !isBusinessAccount
      ) {
        return allowedFullNameCharactersExtendedRegex.test(inputValue)
      }
      return true
    },
    $message: $t('ComponentModifyRecipientModal.DialogFullNameInfo.ValidationTextSpecialCharacters')
      .value,
  }

  const containsFirstAndLastName = {
    $validator(inputValue, context) {
      if (
        !(
          (context[fullNameField].containsInvalidWordsOrPrefixes &&
            context[fullNameField].containsInvalidWordsOrPrefixes.$invalid) ||
          (context[fullNameField].containsInvalidCharacters &&
            context[fullNameField].containsInvalidCharacters.$invalid) ||
          context[fullNameField].valueRequired.$invalid
        ) &&
        !isBusinessAccount
      ) {
        return inputValue.trim().split(/\s+/).length >= 2
      } else {
        return true
      }
    },
    $message: $t('ComponentModifyRecipientModal.DialogFullNameInfo.ValidationTextFirstLastName'),
  }

  const containsAtLeastTwoCharsInNames = {
    $validator(inputValue, context) {
      if (
        !(
          (context[fullNameField].containsInvalidWordsOrPrefixes &&
            context[fullNameField].containsInvalidWordsOrPrefixes.$invalid) ||
          (context[fullNameField].containsInvalidCharacters &&
            context[fullNameField].containsInvalidCharacters.$invalid) ||
          (context[fullNameField].containsFirstAndLastName &&
            context[fullNameField].containsFirstAndLastName.$invalid) ||
          context[fullNameField].valueRequired.$invalid
        ) &&
        !isBusinessAccount
      ) {
        return inputValue
          .trim()
          .split(/\s+/)
          .every((word) => word.length >= 2)
      } else {
        return true
      }
    },
    $message: $t(
      'ComponentModifyRecipientModal.DialogFullNameInfo.ValidationTextSpecialCharacters'
    ),
  }

  return {
    containsInvalidWordsOrPrefixes,
    containsInvalidCharacters,
    containsFirstAndLastName,
    containsAtLeastTwoCharsInNames,
  }
}

export { disallowedFullNameWordsAndCharactersRegex, allowedFullNameCharactersRegex }
export default useFullNameValidation
