export default {
  AF: ['AFN', 'USD'],
  AX: ['SEK', 'EUR'],
  AL: ['ALL', 'EUR'],
  DZ: ['DZD', 'EUR'],
  AS: ['WST', 'USD'],
  AD: ['EUR', 'USD'],
  AO: ['AOA', 'USD'],
  AI: ['XCD', 'USD'],
  AQ: ['EUR', 'USD'],
  AG: ['XCD', 'USD'],
  AR: ['ARS', 'USD'],
  AM: ['AMD', 'USD'],
  AW: ['AWG', 'EUR'],
  AU: ['AUD', 'USD'],
  AT: ['EUR', 'USD'],
  AZ: ['AZN', 'USD'],
  BS: ['BSD', 'GBP'],
  BH: ['BHD', 'USD'],
  BD: ['BDT', 'USD'],
  BB: ['BBD', 'GBP'],
  BY: ['BYN', 'EUR'],
  BE: ['EUR', 'USD'],
  BZ: ['BZD', 'USD'],
  BJ: ['XOF', 'USD'],
  BM: ['BMD', 'GBP'],
  BT: ['BTN', 'USD'],
  BO: ['BOB', 'USD'],
  BQ: ['EUR', 'USD'],
  BA: ['BAM', 'USD'],
  BW: ['BWP', 'USD'],
  BR: ['BRL', 'USD'],
  IO: ['PHP', 'USD'],
  VG: ['GBP', 'USD'],
  BN: ['BND', 'USD'],
  BG: ['BGN', 'USD'],
  BF: ['XOF', 'USD'],
  BI: ['BIF', 'USD'],
  KH: ['KHR', 'USD'],
  CM: ['XAF', 'USD'],
  CA: ['CAD', 'USD'],
  CV: ['CVE', 'EUR'],
  KY: ['KYD', 'USD'],
  CF: ['XAF', 'USD'],
  TD: ['XAF', 'USD'],
  CL: ['CLP', 'USD'],
  CN: ['CNY', 'USD'],
  CX: ['AUD', 'MYR'], // TODO check order
  CC: ['IDR', 'AUD'], // TODO check order
  CO: ['COP', 'USD'],
  KM: ['KMF', 'USD'],
  CG: ['XAF', 'USD'],
  CK: ['NZD', 'USD'],
  CR: ['CRC', 'USD'],
  CI: ['XOF', 'USD'],
  HR: ['HRK', 'EUR'],
  CU: ['CUP', 'CUC', 'EUR'],
  CW: ['ANG', 'USD'],
  CY: ['GBP', 'EUR'],
  CZ: ['CZK', 'EUR'],
  CD: ['CDF', 'USD', 'EUR'],
  DK: ['DKK', 'EUR'],
  DJ: ['DJF', 'EUR'],
  DM: ['XCD', 'USD'],
  DO: ['DOP', 'USD'],
  EC: ['COP', 'USD'],
  EG: ['EGP', 'USD'],
  SV: ['SVC', 'USD'],
  GQ: ['XAF', 'USD'],
  ER: ['ERN', 'EUR', 'USD'],
  EE: ['EUR', 'USD'],
  ET: ['ETB', 'USD'],
  FK: ['FKP', 'USD'],
  FO: ['DKK', 'USD'],
  FM: ['PHP', 'USD'],
  FJ: ['FJD', 'USD'],
  FI: ['EUR', 'USD'],
  FR: ['EUR', 'USD'],
  GF: ['EUR', 'USD'],
  PF: ['XPF', 'USD'],
  GA: ['XAF', 'USD'],
  GM: ['GMD', 'USD'],
  GE: ['GEL', 'USD'],
  DE: ['EUR', 'USD'],
  GH: ['GHS', 'USD'],
  GI: ['GIP', 'EUR'],
  GR: ['EUR', 'USD'],
  GL: ['DKK', 'USD'],
  GD: ['XCD', 'USD'],
  GP: ['EUR', 'USD'],
  GU: ['PHP', 'USD'],
  GT: ['GTQ', 'USD'],
  GG: ['GBP', 'USD'],
  GN: ['GNF', 'USD'],
  GW: ['XOF', 'USD'],
  GY: ['GYD', 'USD'],
  HT: ['HTG', 'USD'],
  VA: ['EUR', 'USD'],
  HN: ['HNL', 'USD'],
  HK: ['HKD', 'JPY'],
  HU: ['HUF', 'EUR'],
  IS: ['ISK', 'EUR'],
  IN: ['INR', 'USD'],
  ID: ['IDR', 'USD'],
  IR: ['IRR', 'EUR', 'USD'],
  IQ: ['IQD', 'USD'],
  IE: ['GBP', 'EUR'],
  IM: ['GBP', 'USD'],
  IL: ['ILS', 'USD'],
  IT: ['EUR', 'USD'],
  JM: ['JMD', 'USD'],
  JP: ['JPY', 'USD'],
  JE: ['GBP', 'USD'],
  JO: ['JOD', 'USD'],
  KZ: ['KZT', 'USD'],
  KE: ['KES', 'USD'],
  KI: ['AUD', 'USD'],
  KW: ['KWD', 'USD'],
  KG: ['KGS', 'USD'],
  LA: ['LAK', 'USD'],
  LV: ['EUR', 'USD'],
  LB: ['LBP', 'EUR', 'USD'],
  LS: ['LSL', 'ZAR', 'USD'],
  LR: ['LRD', 'USD'],
  LY: ['LYD', 'USD'],
  LI: ['CHF', 'EUR'],
  LT: ['EUR', 'USD'],
  LU: ['EUR', 'GBP'],
  MO: ['MOP', 'USD'],
  MK: ['MKD', 'USD'],
  MG: ['MGA', 'EUR'],
  MW: ['MWK', 'USD'],
  MY: ['MYR', 'USD'],
  MV: ['MVR', 'USD'],
  ML: ['XOF', 'USD'],
  MT: ['GBP', 'EUR'],
  MH: ['PHP', 'USD'],
  MQ: ['EUR', 'USD'],
  MR: ['MRU', 'EUR'],
  MU: ['MUR', 'USD'],
  YT: ['EUR', 'USD'],
  MX: ['MXN', 'USD'],
  MD: ['MDL', 'EUR'],
  MC: ['EUR', 'USD'],
  MN: ['MNT', 'USD'],
  ME: ['EUR', 'USD'],
  MS: ['GBP', 'XCD'], // TODO check order
  MA: ['MAD', 'EUR'],
  MZ: ['MZN', 'USD'],
  MM: ['MMK', 'USD'],
  NA: ['NAD', 'USD'],
  NR: ['AUD', 'USD'],
  NP: ['NPR', 'USD'],
  NL: ['EUR', 'USD'],
  NC: ['AUD', 'XPF'], // TODO check order
  NZ: ['NZD', 'USD'],
  NI: ['NIO', 'USD'],
  NE: ['XOF', 'USD'],
  NG: ['NGN', 'USD'],
  NU: ['NZD', 'USD'],
  NF: ['AUD', 'USD'],
  KP: ['KPW', 'EUR'],
  MP: ['PHP', 'USD'],
  NO: ['NOK', 'USD'],
  OM: ['OMR', 'INR'],
  PK: ['PKR', 'USD'],
  PW: ['PHP', 'USD'],
  PS: ['ILS', 'USD'],
  PA: ['PAB', 'EUR', 'USD'],
  PG: ['PGK', 'USD'],
  PY: ['PYG', 'USD'],
  PE: ['PEN', 'USD'],
  PH: ['PHP', 'USD'],
  PN: ['NZD', 'USD'],
  PL: ['PLN', 'EUR'],
  PT: ['GBP', 'EUR'],
  PR: ['EUR', 'USD'],
  QA: ['QAR', 'INR'],
  RE: ['EUR', 'USD'],
  RO: ['RON', 'EUR'],
  RU: ['RUB', 'USD'],
  RW: ['RWF', 'USD'],
  BL: ['EUR', 'USD'],
  KN: ['XCD', 'USD'],
  LC: ['XCD', 'USD'],
  MF: ['EUR', 'USD'],
  VC: ['XCD', 'USD'],
  WS: ['WST', 'NZD'],
  SM: ['EUR', 'USD'],
  ST: ['STN', 'EUR'],
  SA: ['SAR', 'USD'],
  SN: ['XOF', 'USD'],
  RS: ['RSD', 'EUR'],
  SC: ['SCR', 'USD'],
  SL: ['SLL', 'USD'],
  SG: ['SGD', 'USD'],
  SX: ['EUR', 'ANG', 'USD'], // TODO check order
  SK: ['EUR', 'USD'],
  SI: ['EUR', 'USD'],
  SB: ['SBD', 'USD'],
  SO: ['SOS', 'USD'],
  ZA: ['ZAR', 'USD'],
  GS: ['KRW', 'USD'],
  SS: ['SSP', 'UGX', 'USD'],
  ES: ['EUR', 'USD'],
  LK: ['LKR', 'USD'],
  SH: ['SHP', 'ZAR', 'GBP'],
  PM: ['CAD', 'EUR'],
  SD: ['SDG', 'USD'],
  SR: ['SRD', 'USD', 'EUR'],
  SZ: ['SZL', 'USD', 'ZAR'],
  SE: ['SEK', 'EUR'],
  CH: ['CHF', 'EUR'],
  SY: ['SYP', 'USD'],
  TW: ['TWD', 'USD'],
  TJ: ['TJS', 'USD'],
  TZ: ['TZS', 'USD'],
  TH: ['THB', 'USD'],
  TL: ['IDR', 'USD'],
  TG: ['XOF', 'USD'],
  TK: ['WST', 'NZD'], // TODO check order
  TO: ['TOP', 'NZD'],
  TT: ['TTD', 'USD'],
  TN: ['TND', 'EUR'],
  TR: ['TRY', 'USD'],
  TM: ['TMT', 'USD'],
  TC: ['GBP', 'USD'],
  TV: ['FJD', 'AUD'],
  UG: ['UGX', 'USD'],
  UA: ['UAH', 'EUR', 'USD'],
  AE: ['AED', 'INR'],
  GB: ['GBP', 'EUR'],
  US: ['USD', 'EUR'],
  UY: ['UYU', 'USD'],
  UM: ['NZD', 'USD'],
  VI: ['EUR', 'USD'],
  UZ: ['UZS', 'USD'],
  VU: ['VUV', 'AUD'],
  VE: ['VES', 'USD'],
  VN: ['VND', 'USD'],
  WF: ['XPF', 'EUR'],
  YE: ['YER', 'USD'],
  ZM: ['ZMW', 'USD'],
  ZW: [],
}
