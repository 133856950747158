import { onBeforeMount, reactive, computed } from '@vue/composition-api'
import { usePromiseLazy } from 'vue-composable'
import Address from '@galileo/models/Address/address'
import getStates from '@galileo/api/launchpad/resources/countries/_countryIso/states/get'
import { useCountriesStore } from '@galileo/stores'

export {
  stateSanitizerRegex,
  countrySanitizerRegex,
  streetSanitizerRegex,
  citySanitizerRegex,
  postalCodeSanitizerRegex,
  default as useAddressValidation,
} from '@galileo/composables/useAddress/useAddressValidation'

export function useAddress(address) {
  if (!(address instanceof Address)) {
    // eslint-disable-next-line no-console
    console.error('useAddress - accepts address of type Address only')
  }
  const countriesStore = useCountriesStore()

  const { result, loading: loadingAddress, exec } = usePromiseLazy((args) => getStates.exec(args))

  const { state: stateCode, country: countryCode } = address

  const newAddress = reactive(address)

  onBeforeMount(async () => {
    await exec(countryCode)

    if (result?.value?.data) {
      let state = result.value.data.find((state) => {
        return state.value === stateCode
      })
      if (state) {
        if (state.name) {
          newAddress.state = state.name
        }
      }
    }
  })

  const getCountryName = (countryCode) => {
    const country = countriesStore.getOfferedCountryByIsoCode(countryCode)
    return country?.name || countryCode
  }

  const formattedAddress = computed(() => {
    const cityState = `${[newAddress.city ?? '', newAddress.state ?? '']
      .filter(Boolean)
      .join(', ')}`

    return `${newAddress.street ?? ''},
        ${[cityState ?? '', newAddress.postalCode ?? ''].filter(Boolean).join(', ')},
        ${getCountryName(newAddress.country) ?? ''}`
  })

  return {
    loadingAddress,
    address: newAddress,
    formattedAddress,
  }
}
