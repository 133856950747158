import postCardTransactionsV2 from '@galileo/api/launchpad/cardsV2/card-transactions/post'
import putAllocateCardTransaction from '@galileo/api/launchpad/cards/card-transactions/allocate/put'
import postCardAuthenticationsV2 from '@galileo/api/launchpad/cardsV2/card-authentications/post'
import putCompletePayerAuth from '@galileo/api/launchpad/cards/card-transactions/complete-payer-auth/put'
import { isRef } from '@vue/composition-api'
import { TRANSFER } from '@galileo/constants/sendMoneyFlow.const'

import {
  useSendMoneyStore,
  usePaymentsStore,
  useCardAccountStore,
  useAppStore,
  useAuthStore,
} from '@galileo/stores'

export default function useAuthorize3DSV2(
  authContainer,
  referenceId,
  encryptedCvn,
  showCardAuth,
  finishCardAuth,
  cancelCardAuth,
  loadingState = null
) {
  const authStore = useAuthStore()
  const user = authStore.user
  const paymentsStore = usePaymentsStore()
  const sendMoneyStore = useSendMoneyStore()

  let allocateCardStarted = false

  let inprogress = false
  let paTransactionId = null

  let resolve = null
  let reject = null

  async function setupResolveReject(resolveMain, rejectMain) {
    resolve = resolveMain
    reject = rejectMain
  }

  //allocate card method
  const allocateCard = async (setupCompleteData) => {
    allocateCardStarted = true
    const appStore = useAppStore()
    const cardAccount = useCardAccountStore()

    appStore.logInfo({
      text: 'Cardinal setup complete',
      details: setupCompleteData,
    })

    try {
      appStore.logInfo('Start Cardinal session ID: ' + setupCompleteData.sessionId)

      let auth = null

      try {
        if (cardAccount.useApexx) {
          let apexxData = cardAccount.cardAccountForm.apexxData
          if (!apexxData) {
            // in case of existing apexx cards we dont have the apexxData so we need to create it
            let paymentMethod = paymentsStore.selectedPaymentMethod
            // TODO: check again since paymentMethod.id wil be empty
            apexxData = await cardAccount.retrieveApexxData(paymentMethod)
          }

          apexxData.merchantReference = cardAccount.getCorrectTransferId(referenceId)
          apexxData.payerAuthReferenceId = setupCompleteData.sessionId
          apexxData.amount = sendMoneyStore.getTotalAmount(true)

          const { data } = await postCardTransactionsV2.exec(apexxData)

          auth = data
        } else {
          const { data } = await putAllocateCardTransaction.exec({
            amount: sendMoneyStore.getTotalAmount(false),
            cardId: sendMoneyStore.form.paymentMethodId,
            trxRef: referenceId,
            sessionId: setupCompleteData.sessionId, //referenceId,
            profileId: user.customer.id,
          })
          auth = data
        }
      } catch (ex) {
        const { errorCode } = ex
        if (errorCode === 'PSAPI_008') {
          ex = {
            message: TRANSFER.RESPONSE.VERIFIED.STOP,
          }
          sendMoneyStore.isPaymentMethodError = false
          throw ex
        }
        if (errorCode) {
          throw ex
        }
        auth = ex.error ?? ex.errorObject
      }

      if (
        auth?.payerAuthenticateResponse?.acsUrl &&
        auth?.payerAuthenticateResponse?.paReq &&
        auth?.payerAuthenticateResponse?.paTransactionId
      ) {
        appStore.logInfo('Requires payment authorization - proceed with CCA')

        paTransactionId = auth.payerAuthenticateResponse.paTransactionId
        sendMoneyStore.form.cardTransactionId = paTransactionId

        showCardAuth()

        const details = {
          OrderDetails: {
            TransactionId: paTransactionId,
          },
        }

        Cardinal.continue(
          'cca',
          {
            AcsUrl: auth.payerAuthenticateResponse.acsUrl,
            Payload: auth.payerAuthenticateResponse.paReq,
          },
          details
        )
      } else if (auth && !auth.payerAuthenticateResponse) {
        // frictionless
        appStore.logInfo('Frictionless payment - proceed with order creation')

        try {
          sendMoneyStore.form.cardAuthenticationResult = {
            deviceFingerprint: referenceId,
            encryptedCvn,
            transactionReference: cardAccount.getCorrectTransferId(referenceId),
          }
          await finishCardAuth()
          resolve(true)
          resetCardinalListeners()
        } catch (ex) {
          appStore.logError('Failed to complete payer authentication', 'Payment')
          reject(ex)
        }
      } else {
        appStore.logError('Failed to validate order with reference ID', 'Payment', auth)
        reject(auth)
      }
    } catch (ex) {
      appStore.logError('Failed to allocate card transaction', 'Payment')
      reject(ex)
    }
  }

  async function cardinalSetupCompleteHandler(setupCompleteData) {
    const cardAccount = useCardAccountStore()
    Cardinal.trigger('bin.process', cardAccount.getCardInformation.cardNumber)
      .then(async (results) => {
        if (results.Status) {
          console.info('Bin profiling was successful')
        } else {
          console.warn('Bin profiling failed')
        }
        await allocateCard(setupCompleteData)
      })
      .catch(async (error) => {
        console.error('An error occurred during profiling', error)
        if (!allocateCardStarted) {
          await allocateCard(setupCompleteData)
        }
      })
  }

  async function cardinalPaymentValidatedHadler(data, jwt) {
    setLoadingState(true)
    const appStore = useAppStore()
    const cardAccount = useCardAccountStore()

    if (data?.Payment?.ExtendedData?.ChallengeCancel === '01') {
      appStore.logInfo('Manually cancelled payment', data)
      cancelCardAuth()
      resolve(true)
    } else if (
      data &&
      data?.ErrorNumber === 0 &&
      data?.ActionCode !== 'ERROR' &&
      jwt &&
      paTransactionId
    ) {
      if (inprogress) {
        // Cardinal sometimes sends it several times (I think it wasn't like that in the beginning and the behaviour changed recently)
        appStore.logInfo(
          'Cardinal payments validated again although it is already in progress',
          data
        )
      } else {
        appStore.logInfo({
          text: 'Cardinal payments validated',
          details: data,
        })
        inprogress = true

        try {
          if (cardAccount.useApexx) {
            await postCardAuthenticationsV2.exec({
              trxRef: cardAccount.getCorrectTransferId(referenceId),
              jwt,
            })
          } else {
            await putCompletePayerAuth.exec({
              trxRef: referenceId,
              profileId: user.customer.id,
              jwt,
            })
          }

          sendMoneyStore.form.cardAuthenticationResult = {
            deviceFingerprint: referenceId,
            encryptedCvn,
            transactionReference: cardAccount.getCorrectTransferId(referenceId),
            //jwt // not needed ?
          }

          await finishCardAuth()
          setLoadingState(true)

          resetCardinalListeners()
          resolve(true)
        } catch (ex) {
          appStore.logError('Failed to complete payer authentication', 'Payment')
          reject(ex)
        }
      }
    } else {
      appStore.logError('Cardinal payments validated unknown error', 'Payment', data)
      reject()
    }
  }

  async function cardinalOnInlineSetupHandler(htmlTemplate, details, resolveSetup, rejectSetup) {
    setLoadingState(true)
    const appStore = useAppStore()

    if (htmlTemplate) {
      appStore.logInfo({
        text: 'Cardinal setup inline UI successful',
        details,
      })
      authContainer.innerHTML = htmlTemplate
      resolveSetup()
    } else {
      appStore.logError(
        'Cardinal inline UI setup failed',
        'Payment', // TODO !!!!!!!!!!!!!!!!!
        details
      )
      rejectSetup()
      reject()
    }
    setLoadingState(false)
  }

  function setLoadingState(value) {
    if (loadingState && isRef(loadingState)) {
      loadingState.value = value
    }
  }

  function resetCardinalListeners() {
    if (typeof Cardinal !== 'undefined') {
      Cardinal.off('payments.validated')
      Cardinal.off('ui.inline.setup')
      Cardinal.off('payments.setupComplete')
    }
  }

  return {
    cardinalSetupCompleteHandler,
    cardinalPaymentValidatedHadler,
    cardinalOnInlineSetupHandler,
    resetCardinalListeners,
    setupResolveReject,
  }
}
