import API from '@galileo/api/launchpad'
import APIHandler, { setHeader } from '@/api/LaunchpadAPIHandler'

export default new APIHandler(
  API,
  (config) => {
    setHeader('Access-Control-Request-Methods', 'DELETE')
    return {
      ...config,
      method: 'DELETE',
      url: 'authorization/forgetdevice',
      withCredentials: true,
    }
  },
  async (response) => {
    if (response.status === 200) {
      return true
    }
    return false
  }
)
