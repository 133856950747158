import { computed, ref, watch } from '@vue/composition-api'
import { defineStore } from 'pinia'
import { useValidation } from 'vue-composable'
import { helpers, minLength, maxLength } from '@vuelidate/validators'
import { useBusinessNameValidation } from '@galileo/composables/useRecipientHelpers'
import { removeDiacritics } from '@galileo/utilities/diacritics-convertor.utility'

import {
  useI18nStore,
  useAuthStore,
  useSendMoneyStore,
  useResourcesStore,
  useQuickTransferStore,
} from '@galileo/stores'

// Payment reference and reason for transfer handled in this store
export const usePaymentReferenceStore = defineStore('paymentReference', () => {

  const sendMoneyStore = useSendMoneyStore()
  const authStore = useAuthStore()
  const resourcesStore = useResourcesStore()

  const isCorporateAccount = authStore.isCorporateAccount
  const { $t } = useI18nStore()

  const paymentReferenceInputLabel = ref('')

  const reasonForTransferLocked = ref(false)

  const REFERENCE_MAX_LEN = 35
  const REFERENCE_MIN_LEN = 2

  const setIsReasonForTransferLocked = (newValue: boolean) =>
    (reasonForTransferLocked.value = newValue)

  const { maxNumOfCharacters } = useBusinessNameValidation('name')

  const paymentReferenceFieldRegex = ref<string>('')

  const getPaymentReferenceFieldRegex = computed((): RegExp => {
    let defaultRegex = new RegExp(/^[a-zA-Z0-9, \(\)\+\-'/\?\.:]{0,70}$/i)
    if (paymentReferenceFieldRegex.value) {
      return new RegExp(paymentReferenceFieldRegex.value)
    }
    return defaultRegex
  })

  function setPaymentReferenceFieldRegex(value: string): void {
    paymentReferenceFieldRegex.value = value
  }

  const selectedReferenceOption = ref('')

  const includeReference = ref(false)

  const paymentReferenceForm = computed(() => {
    if (!_paymentReferenceForm.value) {
      _paymentReferenceForm.value = _createValidationForm()
    }
    return _paymentReferenceForm.value
  })

  const _paymentReferenceForm = ref<any>(null)

  let _createValidationForm = () =>

    useValidation({
      includeReference: {
        $value: computed({
          get: () => includeReference.value,
          set: (newValue) => {
            includeReference.value = newValue
            paymentReferenceForm.value.$reset()
            if (!newValue && paymentReferenceForm.value) {
              paymentReferenceForm.value.referenceText.$value = ''
              selectedReferenceOption.value = ''
            }
            if (onlyAllowInvoiceOrderReferenceOption) {
              selectedReferenceOption.value = 'INVOICE_ORDER_NUMBER'
            }
          },
        }),
      },
      referenceText: {
      $value: ref(''),
        requiredText: {
          $validator: (value: string, form: any) => {
            if (form.includeReference.$value) return value.length > 0
            return true
          },
          $message: $t('QuickTransfer.ReferenceRequiredError').value,
        },
        alphaNumAndMinLength: {
          $validator: (value: string, form: any) => {
            if (form.referenceText.maxLength.$invalid || !form.includeReference.$value) return true

            const alphaNumValidator = helpers.regex(getPaymentReferenceFieldRegex.value)

            return (
              alphaNumValidator(removeDiacritics(value)) &&
              minLength(REFERENCE_MIN_LEN).$validator(value)
            )
          },
          $message: isCorporateAccount
            ? $t('QuickTransfer.ReferenceAlphaNumAndMinLengthError').value
            : $t('PaymentReferenceModal.OnlyIncludeLettersOrNumbersText').value,
        },

        maxLength: {
          $validator: (value: string) => maxLength(REFERENCE_MAX_LEN).$validator(value),
          $message: $t('QuickTransfer.ReferenceMaxLengthError').value,
        },
      },
      reasonForTransferOption: {
      $value: ref(''),
        required: {
          $validator(value: string) {
            return value.length > 0
          },
          $message: $t('QuickTransfer.ReasonRequiredError').value,
        },
      },
      reasonForTransferText: {
      $value: ref(''),
        required: {
          $validator(value: string, form: any) {
            if (!form.reasonForTransferOption.$value.includes('_OTHER')) return true
            return value.length > 0
          },
          $message: $t('QuickTransfer.ReasonRequiredError').value,
        },
        alphaNumAndMinLength: {
          $validator: (value: string, form: any) => {
            if (form.reasonForTransferText.maxLength.$invalid) return true

            const alphaNumValidator = helpers.regex(getPaymentReferenceFieldRegex.value)

            return alphaNumValidator(value) && minLength(REFERENCE_MIN_LEN).$validator(value)
          },
          $message: $t('QuickTransfer.ReasonAlphaNumAndMinLengthError').value,
        },
        maxLength: {
          $validator: (value: string) => maxLength(REFERENCE_MAX_LEN).$validator(value),
          $message: $t('QuickTransfer.ReasonMaxLengthError').value,
        },
      },
    })


  const getReferenceOptions = () => {
    return [
      { text: $t('PaymentReferenceModal.MyNameText').value, value: 'MY_NAME' },
      { text: $t('PaymentReferenceModal.ReciepientNameText').value, value: 'RECIPIENT_NAME' },
      {
        text: $t('PaymentReferenceModal.InvoiceOrderNumberText').value,
        value: 'INVOICE_ORDER_NUMBER',
      },
    ]
  }

  const getIsPaymentReferenceInputDisabled = computed(() => {
    return (
      ['MY_NAME', 'RECIPIENT_NAME'].includes(selectedReferenceOption.value) && !isCorporateAccount
    )
  })

  watch(selectedReferenceOption, () => {
    if (selectedReferenceOption.value === 'RECIPIENT_NAME') {
      paymentReferenceInputLabel.value = $t('PaymentReferenceModal.ReciepientNameText').value

      if (useQuickTransferStore().isCurrentTransactionQuickTransfer) {
        if (sendMoneyStore.getRecipientName) {
          paymentReferenceForm.value.referenceText.$value = getSanitizedReference(
            sendMoneyStore.getRecipientName
          )
        } else {
          paymentReferenceForm.value.referenceText.$value = ''
        }
      }
    } else if (selectedReferenceOption.value === 'MY_NAME') {
      paymentReferenceInputLabel.value = $t('PaymentReferenceModal.MyNameText').value
      let sanitizedReferenceMyName = removeDiacritics(authStore.userProfile?.customer.accountName)
      if (isCorporateAccount) {
        sanitizedReferenceMyName = getSanitizedReference(
          authStore.userProfile?.customer.accountName ?? ''
        )
      }
      paymentReferenceForm.value.referenceText.$value = sanitizedReferenceMyName
    } else if (selectedReferenceOption.value === 'INVOICE_ORDER_NUMBER') {
      paymentReferenceForm.value.referenceText.$value = ''
      paymentReferenceInputLabel.value = $t('PaymentReferenceModal.InvoiceOrderNumberText').value
    }
  })

  const reasonForTransferList = computed(() => {
    if (!resourcesStore.setSystemFields) {
      return []
    }

    const isIndianRecipient =
      sendMoneyStore.form.countryTo === 'IN' && sendMoneyStore.form.currencyTo === 'INR'

    if (authStore.isCorporateAccount) {
      if (isIndianRecipient) {
        return resourcesStore.systemFields.purposeOfPaymentCorporateInr
      } else {
        return resourcesStore.systemFields.purposeOfPaymentCorporateDefault
      }
    }
    if (isIndianRecipient) {
      return resourcesStore.systemFields.purposeOfPaymentPrivateInr
    } else {
      return resourcesStore.systemFields.purposeOfPaymentPrivateDefault
    }
  })

  const onlyAllowInvoiceOrderReferenceOption =
    authStore.userProfile?.country === 'MY' && authStore.isCorporateAccount

  const getSanitizedReference = (reference: string) => {
    let sanitizedReference = removeDiacritics(reference)

    const paymentRefrenceMaxNumOfChar = maxNumOfCharacters(getPaymentReferenceFieldRegex.value)

    sanitizedReference = sanitizedReference
      .replace('&', 'and')
      .slice(0, paymentRefrenceMaxNumOfChar)

    return sanitizedReference
  }

  const updateSMFormWithPaymentReference = () => {
    sendMoneyStore.form.transferReason = paymentReferenceForm.value.reasonForTransferOption.$value
    sendMoneyStore.form.transferReasonText = paymentReferenceForm.value.reasonForTransferText.$value
    sendMoneyStore.form.transferReference = paymentReferenceForm.value.includeReference.$value
    sendMoneyStore.form.transferReferenceText = paymentReferenceForm.value.referenceText.$value
  }

  const resetPaymentReferenceForm = () => {
    _paymentReferenceForm.value = null

    if (!reasonForTransferLocked.value) {
      paymentReferenceForm.value.reasonForTransferOption.$value = ''
      paymentReferenceForm.value.reasonForTransferText.$value = ''
      paymentReferenceForm.value.referenceText.$value = ''
      paymentReferenceForm.value.includeReference.$value = false
      paymentReferenceForm.value.$reset()
      updateSMFormWithPaymentReference()
    }
  }

  const refreshPaymentReferenceForm = () => {
    paymentReferenceForm.value.reasonForTransferOption.$value = sendMoneyStore.form.transferReason ?? ''
    paymentReferenceForm.value.reasonForTransferText.$value = sendMoneyStore.form.transferReasonText ?? ''
    paymentReferenceForm.value.referenceText.$value = sendMoneyStore.form.transferReferenceText ?? ''
    paymentReferenceForm.value.includeReference.$value = sendMoneyStore.form.transferReference ?? false
  }

  const referenceOptionValidation = computed(() => {
    return selectedReferenceOption.value === '' && paymentReferenceForm.value.referenceText.$dirty
  })

  const getIsPaymentReferenceFormValid = computed(() => !paymentReferenceForm.value.$anyInvalid)

  return {
    // State
    referenceOptions: computed(() => getReferenceOptions()),
    selectedReferenceOption,
    paymentReferenceInputLabel,
    paymentReferenceForm,
    reasonForTransferLocked,
    referenceOptionValidation,
    onlyAllowInvoiceOrderReferenceOption,

    // Actions
    updateSMFormWithPaymentReference,
    resetPaymentReferenceForm,
    refreshPaymentReferenceForm,
    setIsReasonForTransferLocked,
    setPaymentReferenceFieldRegex,

    // Getters
    getIsPaymentReferenceInputDisabled,
    getIsPaymentReferenceFormValid,
    reasonForTransferList,
  }
})
