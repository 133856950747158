import { sanitizeString } from '@galileo/utilities/sanitizers/sanitizeString.js'

function sanitizeProperties(obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  if (Array.isArray(obj)) {
    // Check if it's an array
    return obj.map((item) => {
      // Map over the array and sanitize each item
      if (typeof item === 'string') {
        return sanitizeString(item)
      } else if (typeof item === 'object' && item !== null) {
        return sanitizeProperties(item)
      } else {
        return item
      }
    })
  }

  const sanitizedObj = {}
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key]
      if (typeof value === 'string') {
        sanitizedObj[key] = sanitizeString(value)
      } else if (typeof value === 'object' && value !== null) {
        sanitizedObj[key] = sanitizeProperties(value)
      } else {
        sanitizedObj[key] = value
      }
    }
  }
  return sanitizedObj
}

export default sanitizeProperties
