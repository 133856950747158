import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'
import Recipient from '@galileo/models/Recipient/app'
import { BankRecipientResult } from '@galileo/models/Recipient/SpecificRecipientResult/app.js'

export default new APIHandler(
  API,
  ({ profileId, recipientId, formData }) => {
    return {
      //...config,
      method: 'PUT',
      url: `/recipients/${profileId}/recipient/${recipientId}`,
      data: {
        bankCountryCode: formData.country,
        currencyCode: formData.currency,
        isBusiness: formData.isBusiness,
        isOwnAccount: formData.isOwnAccount,
        fields: formData.fields,
      },
    }
  },
  (response) => {
    // TODO IMPROVE !!!!!!!!!!!!!!!!!!!!!!!! ?
    if (!response.data || response.data.message) {
      return response
    }
    let recipientResult = new BankRecipientResult(response.data.data)
    return {
      ...response,
      data: new Recipient(recipientResult),
    }
  }
)
