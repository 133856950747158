import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  ({ cardFields, trxRef, profileId }, config) => {
    return {
      ...config,
      method: 'POST',
      url: 'cards',
      data: {
        userId: profileId,
        transactionReference: trxRef,
        cardFields: cardFields,
      },
    }
  },
  (response) => {
    return {
      ...response,
      data: {
        id: response.data.cardId,
      },
    }
  }
)
