import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

import { TransactionDetails } from '@galileo/models/SendMoney/interfaces/TransactionDetails'

export const TransactionStatus = {
  AwaitingBalance: {
    value: 'AwaitingBalance',
    textKey: 'PageActivity.StatusAwaitingBalance',
  },
  AwaitingConfirmation: {
    value: 'AwaitingConfirmation',
    textKey: 'PageActivity.StatusAwaitingConfirmation',
  },
  Confirmed: {
    value: 'Confirmed',
    textKey: 'PageActivity.StatusConfirmed',
  },
  BalanceReceived: {
    value: 'BalanceReceived',
    textKey: 'PageActivity.StatusBalanceReceived',
  },
  Pending: {
    value: 'Pending',
    textKey: 'PageActivity.StatusPending',
  },
  AwaitingRelease: {
    value: 'AwaitingRelease',
    textKey: 'PageActivity.StatusAwaitingRelease',
  },
  UnclearedFundsReceived: {
    value: 'UnclearedFundsReceived',
    textKey: 'PageActivity.StatusUnclearedFundsReceived',
  },
  AwaitingFundsInvestigation: {
    value: 'AwaitingFundsInvestigation',
    textKey: 'PageActivity.StatusAwaitingFundsInvestigation',
  },
  AwaitingVerification: {
    value: 'AwaitingVerification',
    textKey: 'PageActivity.StatusAwaitingVerification',
  },
  FundsInvestigation: {
    value: 'FundsInvestigation',
    textKey: 'PageActivity.StatusFundsInvestigation',
  },
  Completed: {
    value: 'Completed',
    textKey: 'PageActivity.StatusTransferCompleted',
  },
  Cancelled: {
    value: 'Cancelled',
    textKey: 'PageActivity.StatusCancelled',
  },
  // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  PendingCancellation: {
    value: 'PendingCancellation',
    textKey: 'PendingCancellation',
  },
  UnclearedFundsRecieved: {
    value: 'UnclearedFundsRecieved',
    textKey: 'PageActivity.StatusUnclearedFundsReceived',
  },
  UnclearedFunds: {
    value: 'UnclearedFunds',
    textKey: 'UnclearedFunds',
  },
  AwaitingVerfication: {
    value: 'AwaitingVerfication',
    textKey: 'AwaitingVerfication',
  },
  FundsUnderInvestigation: {
    value: 'FundsUnderInvestigation',
    textKey: 'FundsUnderInvestigation',
  },
  PaymentReceived: {
    value: 'PaymentReceived',
    textKey: 'PaymentReceived',
  },
  ReadyForRelease: {
    value: 'ReadyForRelease',
    textKey: 'ReadyForRelease',
  },
  AwaitingRecipientDetails: {
    value: 'AwaitingRecipientDetails',
    textKey: 'AwaitingRecipientDetails',
  },
  HoldOnAccount: {
    value: 'HoldOnAccount',
    textKey: 'HoldOnAccount',
  },
  ReadyForPickup: {
    value: 'ReadyForPickup',
    textKey: 'ReadyForPickup',
  },
  Complete: {
    value: 'Complete',
    textKey: 'PageActivity.StatusTransferCompleted',
  },
  Processing: {
    value: 'Processing',
    textKey: 'PageActivity.StatusPending',
  },
  RecipientCanCollect: {
    value: 'RecipientCanCollect',
    textKey: 'ReadyForPickup',
  },
  Expired: {
    value: 'Expired',
    textKey: 'Expired',
  },
}

// $t('PageActivity.PaymentMethodBankTransfer')
// $t('PageActivity.PaymentMethodCreditCard')
// $t('PageActivity.PaymentMethodDebitCard')
// $t('PageActivity.PaymentMethodDirectDebit')
export const PaymentMethod = {
  BankTransfer: {
    value: 'BankTransfer',
    textKey: 'PageActivity.PaymentMethodBankTransfer',
  },
  CreditCard: {
    value: 'CreditCard',
    textKey: 'PageActivity.PaymentMethodCreditCard',
  },
  DebitCard: {
    value: 'DebitCard',
    textKey: 'PageActivity.PaymentMethodDebitCard',
  },
  DirectDebit: {
    value: 'DirectDebit',
    textKey: 'PageActivity.PaymentMethodDirectDebit',
  },
  OpenBanking: {
    value: 'OpenBanking',
    textKey: 'SendMoneyAmount.OpenBankingTitle',
  },
  FundsOnBalance: {
    value: 'FundsOnBalance',
    textKey: 'QuickTransfer.Balance',
  },
}

export default new APIHandler(
  API,
  (orderNumber, config) => {
    return {
      ...config,
      method: 'GET',
      url: `transactions/${orderNumber}`,
    }
  },
  (response) => {
    if (response.data) {
      return {
        ...response,
        data: new TransactionDetails(response.data),
      }
    } else {
      throw new Error('Failed to get transaction details')
    }
  }
)
