import { ref, computed } from '@vue/composition-api'
import { defineStore } from 'pinia'
import { useAuthStore } from '@galileo/stores'
import { StoredOrder } from '@galileo/models/StoredOrder/interfaces'

export const useStoredOrderStore = defineStore(
  'storedOrder',
  () => {
    const storedOrder = ref<StoredOrder | null>(null)

    function clearStoredOrder() {
      storedOrder.value = null
    }

    const getStoredOrder = computed(() => {
      if (storedOrder.value && storedOrder.value.login === useAuthStore().lastLogin) {
        return storedOrder.value
      }
      return null
    })

    const setStoredOrder = (order: StoredOrder) => {
      storedOrder.value = order
    }

    return {
      clearStoredOrder,
      storedOrder,
      getStoredOrder,
      setStoredOrder
    }
  },
  { persist: true }
)
