/*
export const PASSWORD_MIN_LENGTH: number = 6;
export const TIMEOUT_UPDATE_AMOUNT: number = 2000;
export const MAX_DECIMAL_LENGTH: number = 2;
export const MAX_SHORTCUTS: number = 4;
export const RECEIPT_TYPE: string = '0';
export const TIME_TO_ACTIVATE_RESEND_SMS: number = 60;
export const TOAST_DURATION: number = 3000;
export const COUNTRY_SETTINGS_ENABLE_VALUE: number = 1;
export const MULTIPLE_AMOUNT: number = 10;
export const EMAIL: string = `^([\\s]*)([\\w-\\.'+]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([\\w-]+\\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\\]?)([\\s]*)$`;
export const SEND_REGARDLESS_ONHOLD = true;
export const NOT_SEND_REGARDLESS_ONHOLD = false;
export const PATTERNS = {
  EMAIL: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
  PHONE: `^[0-9\\.\\-\\+\\(\\)\\[\\] ]{3,18}$`
};
*/
export const ONFIDO_MAXIMUM_ATTEMPTS_RET_VAL = 2

// added from transaction.ts
export const ORDER_STATUS = {
  PAYMENT_PENDING: 10,
  PROCESSING: 20,
  BENEFICIARY_CAN_COLLECT: 30,
  COLLECTED_BY_BENEFICIARY: 40,
  CANCELLED: 50,
  REFUNDED: 60,
  AVAILABLE_TO_REFUND: 70,
  COMPLETED: 80,
  EXPIRED: 90,
  UNFUNDED: 100,
}

// added from transaction.ts
export const TRANSACTION_CATEGORY_TYPE = {
  FUND_TRANSFER: 10,
  PROCESSING: 20,
  READY_FOR_PICKUP: 30,
  PROCESSING_REFUND: 40,
  MONEY_RECEIVED: 50,
  TRANSFER_CANCELLED: 60,
}

export const PAGES = {
  HOME: 'RedesignHomePage',
  MY_BENEFICIARIES: 'RedesignMyBeneficiariesPage',
  MY_PROFILE: 'MyProfilePage',
  MY_TRANSACTIONS: 'RedesignMyTransactionsPage',
  NEW_ORDER_AMOUNTS: 'RedesingNewOrderAmountsPage',
  NEW_ORDER_CONFIRM: 'RedesignNewOrderConfirmPage',
  NEW_ORDER_BANK_REQUIRED_DATA: 'RedesignNewOrderBankRequiredDataPage',
  NEW_ORDER_REQUIRED_DATA: 'RedesignNewOrderRequiredDataPage',
  NEW_ORDER_MANAGE_BENEFICIARY: 'RedesignNewOrderRequiredDataPage',
  NEW_ORDER_SELECT_BENEFICIARY: 'RedesignNewOrderSelectBeneficiaryPage',
  NEW_ORDER_SELECT_BENEFICIARY_DETAILS: 'RedesignNewOrderSelectBeneficiaryDetailsPage',
  NEW_ORDER_SELECT_COUNTRY: 'RedesignNewOrderSelectCountryPage',
  NEW_ORDER_SELECT_DELIVERY_METHOD: 'RedesingNewOrderSelectDeliveryMethodPage',
  NEW_ORDER_SELECT_CITY: 'RedesingNewOrderSelectCityPage',
  NEW_ORDER_SELECT_LOCATION: 'RedesignNewOrderSelectLocationPage',
  NEW_ORDER_SELECT_TYPE: 'RedesignNewOrderSelectTypePage',
  NEW_TRANSACTION: 'RedesignNewTransactionPage',
  NEW_TRANSACTION_PREVIEW: 'RedesignNewTransactionPreviewPage',
  NEW_TRANSACTION_RESULT_ERROR: 'NewTransactionErrorPage',
  NEW_TRANSACTION_RESULT_SUCCESS: 'NewTransactionSuccessPage',
  SENDING_CERTIFICATE: 'SendingCertificatePage',
  SETTINGS: 'RedesignSettingsPage',
  SIGN_IN: 'SignInPage',
  TERMS_AND_CONDITIONS: 'TermsAndConditionsPage',
  TRACK_TRANSACTION: 'RedesignTrackTransactionPage',
  TRANSACTION_DETAILS: 'RedesignTransactionDetailsPage',
  TRANSACTION_PAY: 'RedesignTransactionPayPage',
  TRANSACTION_FINISH: 'RedesignTransactionFinishPage',
  TRANSACTION_FINISH_DIGITAL: 'SendCompletedPage',
  TOKBOX: 'TokBoxPage',
  APP_STATUS: 'RedesignAppStatusPage',
  CONTACT: 'SelectContactPage',
  CONTACT_EMAIL: 'RedesignContactPage',
  CURRENCY_EXCHANGE: {
    TRANSACTION: 'RedesignCurrencyExchangePage',
    RESERVATION: 'RedesignCurrencyExchangeReservationPage',
    RESERVATION_INFO: 'RedesignCurrencyExchangeReservationInfoPage',
  },
  FIND_LOCATION: 'RedesignFindLocationPage',
  INFO: 'RedesignInfoPage',
  RESULT_INFO: 'ResultInfoPage',
  NEW_INFO: 'RedesignNewInfoPage',
  LINK_CUSTOMER: 'LinkCustomerPage',
  LINK_CUSTOMER_FORM: 'RedesignLinkCustomerFormPage',
  CUSTOMER_LINKING: {
    SMS_SCREEN: 'RedesignLinkCustomerAccountVerificationPage',
    SET_PASSWORD: 'RedesignLinkCustomerSetPasswordPage',
  },
  FORGOT_PASSWORD: 'RedesignForgotPasswordPage',
  LINK_CUSTOMER_SMS: 'RedesignLinkCustomerAccountVerificationPage',
  LINK_CUSTOMER_VIDEO: 'RedesignLinkCustomerVideoPage',
  LINK_CUSTOMER_OCR: {
    FORM: 'RedesignLinkCustomerOcrFormPage',
    SELECT_DOCUMENT: 'RedesignLinkCustomerSelectDocumentPage',
    INFO: 'RedesignLinkCustomerOcrInfoPage',
    INFO_HELP: 'LinkCustomerOrcInfoHelpPage',
    UPLOAD_IMAGES: 'RedesignLinkCustomerUploadOcrImgsPage',
    UPLOAD_IMAGES_HELP: 'LinkCustomerUploadOcrImgsHelpPage',
    LOADING: 'RedesignLinkCustomerLoadingPage',
    NEW_CUSTOMER_LOADING: 'RedesignLinkCustomerNewCustomerLoadingPage',
    ONFIDO_SELECT_DOCUMENT: 'LinkCustomerOnfidoSelectDocumentPage',
    ONFIDO_LOADING: 'LinkCustomerOnfidoLoadingPage',
  },
  LOGIN: 'RedesignLoginPage',
  OTP_SCREEN: 'OtpScreenPage',
  CHANGE_PASSWORD: 'RedesignChangePasswordPage',
  CODE_PASSWORD: 'RedesignCodePasswordPage',
  MY_BENEFICIARIES_ADDRESS: 'RedesignMyBeneficiariesAddressPage',
  MY_BENEFICIARIES_BANK: 'RedesignMyBeneficiariesBankPage',
  MY_BENEFICIARIES_BANK_DETAILS: 'RedesignMyBeneficiariesBankDetailsPage',
  MODAL_FILTER_TRANSACTIONS: 'RedesignModalFilterTransactionsPage',
  MOBILEVERIFY: 'MobileVerifyPage',
}
export const VISIBILITY_ICON = {
  OFF: 'assets/img/login-register/visibility-off.svg',
  ON: 'assets/img/login-register/visibility.svg',
}

export const ANALYTICS_PAGES = {
  HOME: 'Home',
  MY_BENEFICIARIES: 'Send money (Select a beneficiary)',
  MY_PROFILE: 'My profile',
  MY_TRANSACTIONS: 'My transactions',
  NEW_ORDER_AMOUNTS: 'New Order (Fill amounts)',
  NEW_ORDER_CONFIRM: 'New Order (Confirm order)',
  NEW_ORDER_MANAGE_BENEFICIARY: 'New Order (Manage beneficiary)',
  NEW_ORDER_SELECT_BENEFICIARY: 'New Order (Select beneficiary)',
  NEW_ORDER_SELECT_BENEFICIARY_DETAILS: 'New Order (Select beneficiary details redesign)',
  NEW_ORDER_SELECT_CITY: 'New Order (Select city)',
  NEW_ORDER_SELECT_COUNTRY: 'New Order (Select country)',
  NEW_ORDER_SELECT_DELIVERY_METHOD: 'New Order (Select delivery method)',
  NEW_ORDER_SELECT_LOCATION: 'New Order (Select location)',
  NEW_ORDER_SELECT_ORDER_REASON: 'New Order (Select reason)',
  NEW_ORDER_SELECT_TYPE: 'New Order (Select type)',
  NEW_TRANSACTION: 'Send money (Fill transaction form)',
  NEW_TRANSACTION_PREVIEW: 'Send money (Confirm transaction)',
  NEW_TRANSACTION_RESULT_ERROR: 'Send money (Transaction Summary)',
  NEW_TRANSACTION_RESULT_SUCCESS: 'Send money (Transaction Summary)',
  SENDING_CERTIFICATE: 'Sending certificate',
  SETTINGS: 'Settings',
  SIGN_IN: 'Sign in',
  TERMS_AND_CONDITIONS: 'Terms and conditions',
  TRACK_TRANSACTION: 'Track an order',
  TRANSACTION_DETAILS: 'Transaction details',
  TRANSACTION_PAY: 'Show in store',
  TOKBOX: 'Link customer (Tok box)',
  UPDATE: 'Deprecated App Version',
  OFFLINE: 'Offline status',
  CHANGE_PASSWORD: 'Change password',
  CODE_PASSWORD: 'New password (code page)',
  CONTACT: 'Contact',
  CURRENCY_EXCHANGE: {
    TRANSACTION: 'Currency Exchange Transaction',
    RESERVATION: 'Currency Exchange Reservation',
    RESERVATION_INFO: 'Currency Exchange Transaction Information',
  },
  FIND_LOCATION: 'Find location',
  FORGOT_PASSWORD: 'New password',
  INFO: 'Info',
  NEW_INFO: 'New info page',
  LINK_CUSTOMER: {
    LINK: '',
    FORM: 'Link customer (Form)',
    IDNOW: 'Link customer (Idnow)',
    TOKBOX: 'Link customer (TOKBOX)',
    MITEK: 'Link customer (MITEK)',
    CUSTOMER_LINKING: 'Link Customer (Customer Linking)',
    ONFIDO: 'Link customer (ONFIDO)',
  },
  LINK_CUSTOMER_OCR: {
    FORM: 'LinkCustomerOcrFormPage',
  },
  LOGIN: 'Login',
  OTP_SCREEN: 'OTP Input screen',
}

export const DAY_ES = ['Lun', 'Mar', 'Mier', 'Jue', 'Vie', 'Sab', 'Dom']
export const MONTH_ES = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
]
export const DAY_EN = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
export const MONTH_EN = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
export const DATE_FORMAT_EN = 'MM/DD/YYYY'
export const DATE_FORMAT = 'DD/MM/YYYY'

export const TRANSACTION_RETVAL = {
  SUCCESS: 1,
  PRICING_ERRORS: 20,
  INVALID_REQUEST_MIN_AMOUNT: 500,
  INVALID_REQUEST_MAX_AMOUNT: 510,
}

export const ADD_PROMOTIONS_RETVAL = {
  SUCCESS: 1,
  INVALID_REFERRAL_CODE: 10,
  INVALID_EXPIRATION_DATE: 20,
  REFERRAL_CODE_ALREADY_USED: 30,
}

export const FEATURES = {
  LINK_TO_CUSTOMER: 1,
  STAGED_TRANSACTION: 2,
  NEW_ORDER: 3,
  REFER_A_FRIEND: 4,
}

export const KYC_PROVIDERS = {
  IDNOW: 10,
  TOKBOX: 20,
}

export const KYC_STATUS = {
  KYC_SUCCESS: 10,
  KYC_IN_PROGRESS: 20,
  KYC_ERROR: 30,
  KYC_CONSIDER: 'consider',
  KYC_SUSPECTED: 'suspected',
}

export const KYC_ATTEMPT_STATUS = {
  NOT_ATTEMPTED: 0,
  VERIFIED: 1,
  FAIL: 2,
  IN_PROGRESS: 3,
}

export const KYC_ERROR_STATUS = {
  NONE: 0,
  DOCUMENT_NOT_READABLE: 10,
  DOCUMENT_EXPIRED: 50,
  DOCUMENT_IS_NOT_SUPPORTED: 60,
  OTHER: -10,
}

export const KYC_METHODS = {
  IDNOW: 'ID_Now',
  TOKBOX: 'TokBox',
  OCR: 'OCR+NewCustomer',
  UNAVAILABLE: 'NoKYC',
  VALIDATING: 'ValidatingKYC',
  ONFIDO: 'Onfido',
}

export const COUNTRY_SETTINGS = {
  CUSTOMERS_SERVICE_NUMBER: 27400,
  CUSTOMER_SERVICE_EMAIL: 27300,
  SIGNIN_IS_SHOWN_IN_MAIN_MENU: 26400,
  SENDMONEY_IS_SHOWN_IN_MAIN_MENU: 26500,
  SENDMONEY_HOME_ORDER_VIEW: 26550,
  MYTRANSACTIONS_IS_SHOWN_IN_MAIN_MENU: 26600,
  MYTRANSACTIONS_HOME_ORDER_VIEW: 26650,
  SENDINGCERTIFICATE_IS_SHOWN_IN_MAIN_MENU: 26700,
  MAPS_IS_SHOWN_IN_MAIN_MENU: 26800,
  MAPS_HOME_ORDER_VIEW: 26850,
  TRACKANDORDER_IS_SHOWN_IN_MAIN_MENU: 26900,
  TRACKANDORDER_HOME_ORDER_VIEW: 26950,
  SETTINGS_IS_SHOWN_IN_MAIN_MENU: 27000,
  CONTACTUS_IS_SHOWN_IN_MAIN_MENU: 27100,
  ABOUT_LINK: 27500,
  TERMS_AND_CONDITIONS_LINK: 27600,
  PRIVACY_POLICY_LINK: 27700,
  KYC: 28000,
  CURRENCY_EXCHANGE_IS_SHOWN_IN_MAIN_MENU: 29600,
  EMAIL_SIGNUP_ACTIVE: 30200,
  EMAIL_SIGNUP_REQUIRED: 30300,
  EMAIL_SIGNUP_ORDER: 30400,
  MOBILE_SIGNUP_ACTIVE: 30500,
  MOBILE_SIGNUP_REQUIRED: 30600,
  MOBILE_SIGNUP_ORDER: 30700,
  CUSTOMER_LINKING_ENABLED: 32300,
  TIME_BETWEEN_TOKENS_TYPE: 32600,
  TIME_BETWEEN_TOKENS: 32700,
  ALLOW_RESEND_SMS: 33100,
  SHOW_ORDERS_IN_SENDING_CURRENCY: 35300,
  CREATE_NEW_TRANSACTION: 36000,
  SHOW_VERACITY_DISCLOSURE: 36200,
  REQUIRE_TERMS_AND_CONDITIONS_ACCEPTANCE: 40400,
  KYC_INCLUDE_FACE_RECOGNITION: 40100,
  ATM_COLLECTOR_ENABLED: 37400,
  AMOUNT_DISPLAYED_IN_TRANSACTION_LITS: 40700,
  FAQ_LINK: 40600,
  OTP_LOGIN: 40800,
  OTP_NEW_ORDER: 40810,
  OTP_REPEAT_ORDER: 40820,
  OTP_CHANGE_PASSWORD: 42800,
  OTP_RECOVERY_PASSWORD: 42700,
  ADD_BENEFICIRY: 42900,
  ENABLE_LOCAL_AMOUNT: 43000,
  ENABLE_TOTAL_AMOUNT: 43010,
  SEND_TRANSACTION_ENABLED: 43100,
  ONFIDO_FACE_VIDEO_ENABLED: 43600,
  ONFIDO_ALLOWED_DOCUMENTS: 43800,
  OTP_MAX_ATTEMTPS: 42500,
  DIGITAL_AGENT: 44100,
  REFER_A_FRIEND: 45000,
  LOQATE_ADDRESS_ENABLE: 46900,
  DIGITAL_COUNTRY_LICENSE: 47000,
}

export const AGENT_SETTINGS = {
  MAESTRO_ALLOWED: {
    Type: 9200,
    Value: '1',
  },
}

export const FIREBASE_EVENTS = {
  USER_REGISTRATION: 'UserRegistration',
  USER_BEGIN_KYC: 'UserBeginKYC',
  USER_FINISH_KYC_OK: 'UserFinishKYC_OK',
  USER_FINISH_KYC_ERROR: 'UserFinishKYC_ERROR',
  USER_CREATES_STATEG_ORDER: 'UserCreatesStagedOrder',
}

export const EVENTS_ID = {
  USER_REGISTRATION: 6902,
  USER_BEGIN_KYC: 7002,
  USER_FINISH_KYC: 7102,
  USER_CREATES_STATEG_ORDER: 7502,
}

export const SESSION_STATE = {
  NOT_LOGGED: 'NOT_LOGGED',
  LOGGED: 'LOGGED',
  CUSTOMER: 'CUSTOMER',
}

export const USER_FEATURES = {
  LANGUAGE: { AREA: 1, TYPE: 11 },
  PREFERRED_COUNTRY: { AREA: 1, TYPE: 20 },
}

export const CUSTOMER_SETTINGS = {
  CUSTOMER_PREFERRED_COUNTRY: { AREA: 4401, TYPE: 100 },
  RIA_ALERTS_ASK_LATER: { AREA: 4401, TYPE: 210 },
  RIA_ALERTS_SO_NOT_PROMPT: { AREA: 4401, TYPE: 211 },
  MARKETING_MESSAGES: { AREA: 4401, TYPE: 217 },
  SERVICE_MESSAGES: { AREA: 4401, TYPE: 218 },
  ONFIDO_ATTEMPTS: { AREA: 4401, TYPE: 900 },
  LT_LICENSE_TERMS_AND_CONDITIONS: { AREA: 4401, TYPE: 1200 },
}

export const SERVICE_TYPE = {
  MONEY_TRANSFER: 111,
}

export const LINK_CUSTOMER_BUTTON_ACTION = {
  FORM: 1,
  VIDEO: 2,
  CONTACT: 3,
  MAP: 4,
}

export const AMOUNT_FIELD_TYPE = {
  LOCAL_AMOUNT: 1,
  TOTAL_AMOUNT: 2,
  LOCAL_TOTAL_AMOUNT: 3,
}

export const REGISTER_ERRORS = {
  INVALID_PHONE_NUMBER: 'Error_InvalidPhoneNumber',
  EXISTING_USER: 'Error_ExistingUser',
  DUPLICATE_EMAIL: 'Error_DuplicateEmail',
}

export const REGISTER_RET_VAL = {
  SIGN_IN: 1,
  REFER_A_FRIEND: 2,
  BLOCKED: 190,
}

export const LOGIN_ERRORS_NEW_TOKEN = {
  INVALID_GRANT: 10,
  OTP_ERRORS: 60,
  NO_CONTACT_DATA: 70,
  VERIFY_PHONE_NUMBER: 80,
  BLOCKED_USER: 90,
  BLOCKED_PASSWORD_USER: 100,
  BLOCKED_PASSWORD_QUESTIONS: 110,
  NO_DEVICE_INFO: 160,
  MFA_MIGRATION_NOT_VERIFIED: 170,
}

export const LOGIN_ERRORS = {
  BLOCKED_PASSWORD: 'blocked_password',
  INVALID_GRANT: 'invalid_grant',
  NO_CONTACT_DATA: 'NoContactData',
  OTP_REQUIRED: 'OTP_Required',
  OTP_ERRORS: 'OTP_Error',
  OTP_INVALID_CODE: 'invalid_code',
  OTP_EXPIRED_CODE: 'expired_code',
  OTP_INVALID_USER: 'invalid_user',
  VERIFY_PHONE_NUMBER: 'NotVerified',
}

export const LOGIN_TYPE = {
  EMAIL: 10,
  PHONE: 20,
}

export const AMOUNT_TYPE = {
  MONEY_FROM: 1,
  MONEY_TO: 2,
}

export const DELIVERY_TYPE = {
  SMS: 10,
  EMAIL: 20,
  VOICE: 30,
}

export const LANGUAGES = {
  EN: 'en-US',
  ES: 'es-ES',
  CL: 'es-CL',
  MY: 'ms-MY',
  NP: 'ne-NP',
  BD: 'bn-BD',
  PL: 'pl-PL',
  UA: 'uk-UA',
  FR: 'fr-FR',
  IT: 'it-IT',
}

export const CONTACT_US_SERVICES = [
  {
    Key: 'MoneyTransfer',
    Value: 111,
  },
  {
    Key: 'CurrencyExchange',
    Value: 511,
  },
]

export const RET_VAL = {
  HAPPY_PATH: 0,
  REQUIRED_FIELDS_VALID: 1,
  ORDER_RESTRICTIONS_MATCHED: 10, // ejemplo, ya no se utilice esa moneda, location dada de baja
  REQUIRED_FIELDS_MATCHED: 20, // campos no validos de beneficiario, por ejemplo, falta el nombre, profesion de beneficiario, puede ocurrir cuando se han añadido nuevos campos obligatorios desde el ultimo giro
  INVALID_REQUIRED_FIELDS_MATCHED: 25,
  ORDER_AMOUNT_OUT_OF_PAYOUT_AGENT_LIMITS: 30, // corresponsal pagador se ha pasado de los limites que le debe a ria
  POSSIBLE_DUPLICATED_ORDER: 40, // para evitar que se dupliquen ordenes, el usuario ha intentando hacer la misma orden en los ultimos x minutos
  SAR_VALIDATION_ON_HOLD: 50, // actividad sospechosa -> Por ejemplo, se detecta que un benef esta recibiendo dinero d varios paises
  SAR_VALIDATION_REJECTED: 55,
  WRONG_SENT_AMOUNTS: 60, // ha cambiado algun campo, como el cambio
  INVALID_SEND_CURRENCY: 65, // La moneda de envío no esta soportada por el agente
  AGENT_IS_ON_HOLD: 70, // el agente enviador esta deshabilitado
  AR_OVER_DAILY_LIMIT: 80, // si el benef se pasa de dinero recibido al dia
  AR_OVER_BALANCE_LIMIT: 90, // si el benef se pasa de dinero recibido al dia que le debe
  TRANSACTION_OVER_THE_LIMIT: 100, // si el benef se pasa de dinero recibido
  WATCHLIST_MATCHED: 110,
  CUSTOMER_ON_HOLD: 2000,
  EXPIRED_OTP: 4000,
  INVALID_OTP: 4100,
  OTP_REQUIRED: 4200,
  ERROR_CREATING_OTP: 4300,
  NO_CONTACT_DATA: 4400,
  VERIFY_PHONE_NUMBER: 4500,
  REQUIRED_IDENTITY_VERIFICATION: 4700,
}

export const SERVICEID = {
  SERVICEID_AGENT: 111,
}

export const DELIVERY_METHOD = {
  CASH_PICKUP: {
    Id: 1,
    Key: 'CashPickup',
  },
  BANK_DEPOSIT: {
    Id: 2,
    Key: 'BankDeposit',
  },
  HOME_DELIVERY: {
    Id: 3,
    Key: 'HomeDelivery',
  },
}

export const ORDER_TYPE = {
  NEW_ORDER: 1,
  RESEND_ORDER: 2,
}

export const INFO_PAGE = {
  ERROR_LOCATION: 1,
  PHONE_VERIFIED: 2,
  NOT_DEFINED: 3,
  FORM_USER_NOT_FOUND: 4,
  COMPLETE_LINK_FAILED: 5,
  CURRENCY_EXCHANGE: 6,
  MAP_LOCATIONS: 7,
  CAMERA_DENIED_PERMISSION: 8,
  INVALID_ID: 9,
  OCR_FAILED: 10,
  OTP_ACCOUNT_BLOCKED: 11,
  NO_BENEFICIARIES: 12,
  NO_TRANSACTIONS_TRACKED: 13,
  CONTACT_SENT: 14,
  GENERIC_ERROR: 15,
}

export const RESULT_INFO_PAGES = {
  ACCOUNT_CREATED: 1,
  ERROR_NOT_DEFINED: 2,
  NO_CONTACT_DATA: 3,
  INVALID_ID: 4,
  CAMERA_DENIED_PERMISSION: 5,
  OTP_ACCOUNT_BLOCKED: 6,
  OCR_FAILED: 7,
  ERROR_LOCATION: 8,
  PHONE_VERIFIED: 9,
  FORM_USER_NOT_FOUND: 10,
  NOT_DEFINED: 11,
}

export const ERROR_PAGE = {
  GENERIC_ERROR: 1,
}

export const HOMEOBJECTS = {
  SENDMONEY: {
    svg: 'assets/img/home/sendMoneyHome.svg',
    title: 'HomeSendMoneyTitle',
    msg: 'HomeSendMoneyMessage',
    button: {
      text: 'HomeSendMoneyButton',
      redirect: PAGES.MY_BENEFICIARIES,
    },
  },
  FIND_LOCATION: {
    svg: 'assets/img/home/findLocationHome.svg',
    title: 'HomeFindLocationTitle',
    msg: 'HomeFindLocationMessage',
    button: {
      text: 'HomeFindLocationButton',
      redirect: PAGES.FIND_LOCATION,
    },
  },
  TRACK_TRANSACTION: {
    svg: 'assets/img/home/trackTransactionHome.svg',
    title: 'HomeTrackTransactionTitle',
    msg: 'HomeTrackTransactionMessage',
    button: {
      text: 'HomeTrackTransactionButton',
      redirect: PAGES.TRACK_TRANSACTION,
    },
  },
  MY_TRANSACTIONS: {
    svg: 'assets/img/home/myTransactionHome.svg',
    title: 'HomeMyTransactionsTitle',
    msg: 'HomeMyTransactionsMessage',
    button: {
      text: 'HomeMyTransactionsButton',
      redirect: PAGES.MY_TRANSACTIONS,
    },
  },
}

export const LINK_CUSTOMER_RET_VAL = {
  SUCCESS: 1,
  SMS_SENT: 10,
  NO_CONTACT_DATA: 20,
  CUSTOMER_NOT_FOUND: 30,
  CUSTOMER_WRONG: 40,
  INVALID_ID: 70,
  EXPIRED_ID: 80,
  NOT_MATCHED_ID: 90,
}

export const SET_PASSWORD_RETVAL = {
  SUCCESS: 0,
}

export const COMPLETE_LINK_RET_VAL = {
  CUSTOMER_LINKED: 0,
}

export const DEEP_LINK_RET_VAL = {
  OK: 10,
  EXTERNAL_LINKING_PROCESS: 20,
  APP_LINKING_PROCESS: 30,
  INCORRECT_PHONE: 40,
}

export const ABOUTLANGUAGES = ['es', 'en', 'fr', 'it', 'pl', 'de', 'pt', 'tr', 'se']

export const EVENTS_NAME = {
  HIDE_MENU: 'hideMenu',
  SHOW_DOT: 'showDot',
  SHOW_MENU: 'showMenu',
  UPDATE_PAGE: 'updateapp',
  SESSION_SAVED: 'sessionSaved',
  REFRESH_TOKEN: 'refreshToken',
  LOGOUT: 'logout',
  SHOW_ALERT: 'showAlert',
  SHOW_TOAST: 'showToast',
  APP_OFFLINE: 'appOffline',
  HIDE_FOOTER: 'hideFooter',
  HIDE_NAV_MENU: 'hideNavMenu',
}

export const DEEPLINK_SCHEME = 'myria://'

export const DEEPLINK_REDIRECT = {
  SIGN_IN: 'sign-in',
}

export const TIME_TYPE = {
  DAY: 86400,
  HOUR: 3600,
  MINUTE: 60,
}

export const LINK_CUSTOMER_FIELD = {
  ORDER_NUMBER: 1,
}

export const VERIFY_PHONE_RET_VAL = {
  OK: 0,
  KO: 1,
}

export const MCC_CODE = {
  202: '30',
  204: '31',
  206: '32',
  208: '33',
  212: '377',
  213: '376',
  214: '34',
  216: '36',
  218: '387',
  219: '385',
  220: '381',
  222: '39',
  226: '40',
  228: '41',
  230: '420',
  231: '421',
  232: '43',
  234: '44',
  238: '45',
  240: '46',
  242: '47',
  244: '358',
  246: '370',
  247: '371',
  248: '372',
  250: '79',
  255: '380',
  257: '375',
  259: '373',
  260: '48',
  262: '49',
  266: '350',
  268: '351',
  270: '352',
  272: '353',
  274: '354',
  276: '355',
  278: '356',
  280: '357',
  282: '995',
  283: '374',
  284: '359',
  286: '90',
  288: '298',
  289: '7',
  290: '299',
  292: '378',
  293: '386',
  294: '389',
  295: '423',
  297: '382',
  302: '1',
  308: '508',
  310: '1671',
  311: '1671',
  312: '1',
  316: '1',
  334: '52',
  338: '1876',
  340: '594',
  342: '1246',
  344: '1268',
  346: '1345',
  348: '284',
  350: '1441',
  352: '1473',
  354: '1664',
  356: '1869',
  358: '1758',
  360: '1784',
  362: '599',
  363: '297',
  364: '1242',
  365: '1264',
  366: '1767',
  368: '53',
  370: '1809',
  372: '509',
  374: '1868',
  376: '1340',
  400: '994',
  401: '7',
  402: '975',
  404: '91',
  405: '91',
  410: '92',
  412: '93',
  413: '94',
  414: '95',
  415: '961',
  416: '962',
  417: '963',
  418: '964',
  419: '965',
  420: '966',
  421: '967',
  422: '968',
  424: '971',
  425: '972',
  426: '973',
  427: '974',
  428: '976',
  429: '977',
  430: '971',
  431: '971',
  432: '98',
  434: '998',
  436: '992',
  437: '996',
  438: '993',
  440: '81',
  441: '81',
  450: '82',
  452: '84',
  454: '852',
  455: '853',
  456: '855',
  457: '856',
  460: '86',
  466: '886',
  467: '850',
  470: '880',
  472: '960',
  502: '60',
  505: '61',
  510: '62',
  514: '670',
  515: '63',
  520: '66',
  525: '65',
  528: '673',
  530: '64',
  537: '675',
  539: '676',
  540: '677',
  541: '678',
  542: '679',
  544: '684',
  545: '686',
  546: '687',
  547: '689',
  548: '682',
  549: '685',
  550: '691',
  552: '680',
  555: '683',
  602: '20',
  603: '213',
  604: '212',
  605: '216',
  606: '218',
  607: '220',
  608: '221',
  609: '222',
  610: '223',
  611: '224',
  612: '225',
  613: '226',
  614: '227',
  615: '228',
  616: '229',
  617: '230',
  618: '231',
  619: '232',
  620: '233',
  621: '234',
  622: '235',
  623: '236',
  624: '237',
  625: '238',
  626: '239',
  627: '240',
  628: '241',
  629: '242',
  630: '243',
  631: '244',
  632: '245',
  633: '248',
  634: '249',
  635: '250',
  636: '251',
  637: '252',
  638: '253',
  639: '254',
  640: '255',
  641: '256',
  642: '257',
  643: '258',
  645: '260',
  646: '261',
  647: '262',
  648: '263',
  649: '264',
  650: '265',
  651: '266',
  652: '267',
  653: '268',
  654: '269',
  655: '27',
  657: '291',
  702: '501',
  704: '502',
  706: '503',
  708: '504',
  710: '505',
  712: '506',
  714: '507',
  716: '51',
  722: '54',
  724: '55',
  730: '56',
  732: '57',
  734: '58',
  736: '591',
  738: '592',
  740: '593',
  744: '595',
  746: '597',
  748: '598',
  750: '500',
  901: '882',
}

export const CODE_ERRROS = {
  LOCATION_NOT_VALID: 1001,
}

export const LOCATION_TABS = {
  MAP: 1,
  LIST: 2,
}

export const MITEK_CAPTURE = {
  BACK_IMAGE: 'back_image',
  FRONT_IMAGE: 'front_image',
  FACE_IMAGE: 'face_image',
  PASSPORT_IMAGE: 'passport_image',
}

export const LOAD_FORM_ORC_RETVAL = {
  OK: 1,
}

export const FORM_ORC_RETVAL = {
  OK: 1,
  NO_CONTACT_DATA: 20,
  INVALID_FIELDS: 50,
}

export const FORM_VALIDATION_NAMES = {
  REQUIRED: 'required',
  REGEX: 'regex',
  MIN_LENGTH: 'minlength',
  MAX_LENGTH: 'maxlength',
  MIN_VALUE: 'minvalue',
  MAX_VALUE: 'maxvalue',
}

export const OCR_FORM_RETVAL = {
  OK: 1,
  KO: 10,
  EXPIRED_ID: 20,
  OCR_TIMEOUT: 40,
}

export const OCR_CHECK_STATUS = {
  OK: 1,
  VERIFYING: 10,
}

export const OPTION_SELECT_IMAGE_TYPE = {
  COUNTRY: 9,
}

export const NEW_BENEFICIARY_ID = 0

export const CUSTOMER_STATUS = {
  ON_HOLD: true,
  NOT_ON_HOLD: false,
}

export const NEW_BENEFICIARY_FIELDS_ID = {
  FIRST_NAME: 8901,
  LAST_NAME: 9001,
  SECOND_LAST_NAME: 9101,
  ADDRES: 1201,
  CITY: 1410,
  STATE: 1401,
  COUNTRY: 1601,
}

export const OCR_IMAGES_FIELD_ID = 102
export const IMAGE_IS_NOT_LANDSCAPE = 1616
export const OCR_APPLICANT_ID = 444
export const OCR_CHECK_ID = 0

export const VALIDATE_ACCOUNT_NUMBER = {
  BANK: 0,
  NOT_BANK: 10,
}

export const FORM_BANK_INFORMATION = {
  VALID: 1,
  NOT_VALID: 20,
}

export const CUSTOMER_ON_HOLD_STATUS = {
  NOT_ON_HOLD: 1,
  ON_HOLD_CAN_SEND: 10,
  ON_HOLD_CANNOT_SEND: 20,
}

export const ACUANT_ID_TYPE = {
  ID_CARD: 1,
  PASSPORT: 3,
}

export const ACUANT_CARD_REGION = {
  USA: 0,
  CANADA: 1,
  AMERICA: 2,
  EUROPE: 3,
  AUSTRALIA: 4,
  ASIA: 5,
  GENERAL: 6,
  AFRICA: 7,
}

export const CURRENCY_FIELD_ID_BANK_DEPOSIT_FORM = 106001

export const ORDER_ONLY_VALIDATE = {
  TRUE: true,
  FALSE: false,
}

export const VERACITY_DISCLOSURE = {
  TRUE: true,
  FALSE: false,
}

export const PREFERRED_COUNTRY_DISPLAY_EURONET_LOGO = 5905

export const DENOMINATIONS_PAYMENT_METHOD = {
  CASH: 3,
  ATM: 10,
}

export const DENOMINATIONS_PAYMENT_ACTION = {
  COLLECT: 1,
  SEND: 10,
}

export const STORAGE_KEYS = {
  USER_INFO: 'user_info',
  SESSION: 'session',
  LANGUAGE_APP: 'language_app',
  FIRST_TIME: 'first_time',
  PENDING_TRANSACTION_DOT: 'pending_transaction_dot',
  REMEMBER_PREFIX: 'prefix_remebered',
  BENEFICIARIES_HOME_DELIVERY_MODAL_NOT_SHOW_AGAIN:
    'beneficiaries_home_delivery_modal_not_show_again',
  BENEFICIARIES_BANK_DEPOSIT_MODAL_NOT_SHOW_AGAIN:
    'beneficiaries_bank_deposit_modal_not_show_again',
  MARKETING_CONSENT_VALUE: 'marketing_consent',
  REFERRAL_CODE: 'referral_code',
}

export const TRANSACTION_LIST_AMOUNTS = {
  LOCAL_AMOUNT: 1,
  TOTAL_AMOUNT: 2,
  TRANSFER_AMOUNT: 3,
}

export const MENU_LANGUAGES = {
  'en-US': {
    Name: 'LANG_en-US',
    Id: 105,
  },
  'es-ES': {
    Name: 'LANG_es-ES',
    Id: 9305,
  },
  'es-CL': {
    Name: 'LANG_es-CL',
    Id: 3105,
  },
  'pl-PL': {
    Name: 'LANG_pl-PL',
    Id: 5905,
  },
  'ms-MY': {
    Name: 'LANG_ms-MY',
    Id: 1705,
  },
  'uk-UA': {
    Name: 'LANG_uk-UA',
    Id: 11511,
  },
  'bn-BD': {
    Name: 'LANG_bn-BD',
    Id: 13211,
  },
  'ne-NP': {
    Name: 'LANG_ne-NP',
    Id: 8705,
  },
  'fr-FR': {
    Name: 'LANG_fr-FR',
    Id: 805,
  },
  'it-IT': {
    Name: 'LANG_it-IT',
    Id: 1505,
  },
}

export const OTP_RESULT = {
  VALID: 10,
  INVALID: 20,
}

export const OTP_ACTIONS = {
  LOGIN: 20,
  CHANGE_PASSWORD: 30,
  RECOVERY_PASSWORD: 40,
  NEW_ORDER: 50,
  REGISTRATION: 60,
  VERIFYPHONE: 70,
  LOGINTOKEN: 80,
  VERIFYPHONE2: 90,
  PASS_SECURITY_QUESTIONS: 100,
}

export const OTP_HEADER_RET_VAL = {
  NO_CONTACT_DATA: 20,
  ACCOUNT_BLOCKED: 2010,
}

export const FORGOT_PASSWORD_OTP_RET_VAL = {
  OPT_REQUIRED: 4200,
}

export const RESEND_ACTIONS = {
  LOGIN: 1,
  NEW_ORDER: 2,
  CHANGE_PASSWORD: 3,
  RESET_PASSWORD: 4,
}

export const FLOW_STEPS = {
  WELCOME: 'welcome',
  DOCUMENT: 'document',
  FACE: 'face',
  FACE_VIDEO: 'face_video',
  FINAL: 'final',
}

export const CARD_TYPE = {
  VISA: {
    Id: 1,
    Key: 'Visa',
  },
  MASTERCARD: {
    Id: 2,
    Key: 'MasterCard',
  },
  MAESTRO: {
    Id: 3,
    Key: 'Maestro',
  },
}

export const PROMOTION_TYPE = {
  GENERICNEWCUSTOMERS: 10,
  GENERICALLCUSTOMERS: 20,
  GENERICUNLIMITED: 30,
  UNIQUE: 40,
  WINBACK: 50,
  LOYALTY: 60,
  LOYALTYCARD: 70,
  CUSTOMERREFERRAL: 80,
  INCREMENTALPROMOCODES: 90,
}

export const CUSTOMER_LINKING_FIELDS = {
  DATE_OF_BIRTH: 5,
  ID_NUMBER: 4,
  ID_EXPIRATION: 8,
}

export const LOQATE_TYPES = {
  ADDRESS: 'Address',
  STREET: 'Street',
}

export const DIGITAL_LICENSE = {
  LITUANIA: 'LT',
}

export const MOBILE_WALLET_VALIATION = {
  SUCCESS: 10,
  ERROR: 20,
  INVALID_ACCOUNT: 30,
  INVALID_BENEFICIARY: 40,
}

export const EKYC_PROCESSOR = {
  2000: 'ExperianPID',
  2001: 'ExperianKIQ',
  2002: 'IDOlogyExpectIQID',
  2006: 'JumioKYC',
  2003: 'IDOlogyPreciseID',
  10005: 'Klarna',
  2020: 'Equifax',
  1000: 'MatchedByCS',
  2005: 'MatchedByOnline',
  2030: 'ID3Global',
  10006: 'SiftScience',
  2040: 'TruNarrative',
  2070: 'Onfido',
}
