import GenericAccount from '@galileo/models/Payment/GenericAccount'
import format from 'date-fns/format'

export default class CardAccount extends GenericAccount {
  constructor(cardAccount) {
    super(cardAccount)

    let cardData = cardAccount.cardData
    let cardV2Data = null
    if (cardAccount.cardV2Data) {
      cardData = cardAccount.cardV2Data
      cardV2Data = {
        id: cardAccount.cardV2Data.id,
        cardState: cardAccount.cardV2Data.cardState,
      }
    }

    this.panLastDigits = cardData.panLastDigits
    this.currency = cardAccount.accountCurrency
    this.type = cardAccount.cardTypeName
    this.requiresSecurityCode = cardAccount.isCvnRequired
    this.isDebitCard = cardAccount.isDebit
    this.isExpired = cardAccount.cardV2Data
      ? cardAccount.cardV2Data.cardState === 'Expired'
      : cardData.isExpired
    this.expiryMonth = cardAccount.accountExpirationMonth
    this.accountExpirationMonth = cardAccount.accountExpirationMonth
    this.expiryYear = cardAccount.accountExpirationYear
    this.accountExpirationYear = cardAccount.accountExpirationYear
    this.expiryDate = format(new Date(this.expiryYear, this.expiryMonth - 1), 'MM/yy')
    this.maskedAccountNumber = cardAccount.maskedAccountNumber
    this.title = cardAccount.title
    this.subTitle = cardAccount.subTitle // this.isDebitCard ? 'Debit card' : 'Credit card'
    if (this.type) {
      if (this.type.indexOf('Visa') === 0) {
        this.image = 'cards/visa-black.svg'
      } else if (this.type.indexOf('Mastercard') === 0) {
        this.image = 'cards/mastercard-black.svg'
      } else if (this.type.indexOf('Maestro') === 0) {
        this.image = 'cards/maestro-black.svg'
      }
    }
    this.isCard = true

    this.panFirstDigits = cardData?.panFirstDigits

    if (!cardV2Data) {
      this.billingStreet = cardData.billingAddress.line1
      this.billingStreetLine2 = cardData.billingAddress.line2
      this.billingCity = cardData.billingAddress.place
      this.billingCountry = cardData.billingAddress.countryCode
      this.billingPostalCode = cardData.billingAddress.postCode
      this.billingState = cardData.billingAddress.state
    }

    if (cardAccount.cardV2Data) {
      this.cardV2Data = {
        id: cardAccount.cardV2Data.id,
        cardState: cardAccount.cardV2Data.cardState
      }
    }
  }
}
