import { defineStore } from 'pinia'
import { Ref, computed, ref } from '@vue/composition-api'

import { PAYMENT_METHODS } from '@galileo/constants/sendMoneyFlow.const'
import {
  SEGMENT_EVENTS,
  SEGMENT_LOCATIONS,
  SEGMENT_PAYOUT_METHODS,
} from '@galileo/constants/segmentAnalytics'
import { DeliveryMethod } from '@galileo/models/Transaction/app'
import {
  TRANSACTION_ACTIVITY_STATUSES,
  TRANSACTION_STATUSES_STEPS,
  TRANSACTION_SENDAGIN_ERROR,
} from '@galileo/constants/transactionStatuses'

import getLocations from '@galileo/api/launchpad/destinations/correspondents/_agentId/locations/get'
import {
  AgentToLocation,
  AgentTo,
  TransactionListItem,
} from '@galileo/models/Activity/interfaces/Transactions'
import {
  useActivityStore,
  useAppStore,
  useSendMoneyStore,
  useAnalyticsStore,
  useI18nStore,
  useAuthStore,
  useStoredOrderStore,
} from '@galileo/stores'

import { truncateRecipientName } from '@galileo/utilities/string-utils'
import { UserProfile } from '@galileo/models/Auth/Interfaces/AuthFields'

import { TransactionDetails } from '@galileo/models/SendMoney/interfaces/TransactionDetails'
import VueRouter from 'vue-router'

export const useTransactionStore = defineStore('transaction', () => {
  const appStore = useAppStore()
  const activityStore = useActivityStore()
  const sendMoneyStore = useSendMoneyStore()
  const analyticsStore = useAnalyticsStore()
  const authStore = useAuthStore()
  const { $t } = useI18nStore()

  const showSelectedTransaction = ref<boolean>(false)
  const transactionDetailsTitle = ref<string>('')
  const useActivityLoading = ref<boolean>(false)
  const allowCancel = ref<boolean>(false)
  const allowSendAgain = ref<boolean>(false)
  const allowProvideDetails = ref<boolean>(false)
  const allowCashPickupModification = ref<boolean>(false)
  const allowContactUs = ref<boolean>(false)

  const loading = ref<boolean>(true)

  const isOfflineContract = ref<boolean>(false) // it is not used anywhere
  const showSendAgainNotPossibleDialog = ref<boolean>(false)
  const showEditTransaction = ref<boolean>(false)
  const showModifyRecipientName = ref<boolean>(false)
  const allowReceipt = ref<boolean>(false)
  const loadingDetails = ref<boolean>(false)
  const loadingActivityList = ref<boolean>(false)
  const isProcessingTimeDialogOpen = ref<boolean>(false)
  const showCancelTransfer = ref<boolean>(false)
  const showCancellationSuccessful = ref<boolean>(false)
  const showCancellationFailed = ref<boolean>(false)
  const showModifyFailed = ref<boolean>(false)

  // share dialog
  const allowShare = ref<boolean>(false)
  const loadingShare = ref<boolean>(false)
  const isShareDialogOpen = ref<boolean>(false)
  const shareBody = ref<string>('')

  const canShowBankTransferTimeEstimateDialog = ref(false)

  const bankTransferTimeEstimateDialogVisible = ref<boolean>(false)

  const selectedRecipient = ref<any>(null)

  const selectedTransaction = ref<Nullable<TransactionListItem>>(null) // todo interface

  const selectedTransactionDetails = ref<Nullable<TransactionDetails>>(null) // todo interface

  const buttons = {
    Verify: 'Verify',
    Details: 'Details',
    ProvideInfo: 'ProvideInfo',
    Continue: 'Continue',
    Share: 'Share',
    Resend: 'Resend',
    TryAgain: 'TryAgain',
  }

  async function selectTransaction(
    transaction: TransactionListItem,
    router: Nullable<VueRouter> = null
  ): Promise<void> {
    transactionDetailsTitle.value = ''

    if (transaction.continueOrder) {
      useActivityLoading.value = true
      const storedOrder = useStoredOrderStore().getStoredOrder
      try {
        loadingDetails.value = true
        await sendMoneyStore.continueOrder(storedOrder)
        if (router) {
          router.push('send-money/summary')
        }
      } catch (ex) {
        appStore.logException('Exception during continuing order', ex)
        appStore.messageBoxGenericError()
      }
      useActivityLoading.value = false
    } else {
      activityStore.resetTransactionDetails()
      showSelectedTransactionModal()
      selectedTransaction.value = transaction

      allowCancel.value = false
      allowSendAgain.value = false
      allowShare.value = false
      allowProvideDetails.value = false
      allowCashPickupModification.value = false
      allowContactUs.value = false
      canShowBankTransferTimeEstimateDialog.value = false

      analyticsStore.track({
        event: SEGMENT_EVENTS.TRANSFER_DETAILS_ACCESSED,
        traits: {
          contractNumber: transaction.orderNumber,
          location: SEGMENT_LOCATIONS.ACTIVITY,
          transactionId: transaction.id,
          transactionStatus: transaction.status,
        },
      })
    }
  }

  //Check send again

  function checkTransactionSendAgain(transaction: any): boolean {
    if (transaction.transactionActivityStatus === TRANSACTION_ACTIVITY_STATUSES.COMPLETED.name) {
      return true
    } else if (
      transaction.transactionActivityStatus ===
      TRANSACTION_ACTIVITY_STATUSES.PENDING_CANCELLATION.name
    ) {
      return false
    } else if (
      transaction.deliveryMethod === DeliveryMethod.CashPickup &&
      !allowCashPickupModification.value
    ) {
      return false
    } else if (
      transaction.deliveryMethod === DeliveryMethod.CashPickup &&
      transaction.status &&
      transaction.status.value === TRANSACTION_STATUSES_STEPS.CANCELLED.name
    ) {
      return false
    } else if (transaction.deliveryMethod === DeliveryMethod.MobileWallet) {
      return false
    } else if (
      transaction.paymentMethod.value !== PAYMENT_METHODS.BANK_TRANSFER ||
      (transaction.status &&
        transaction.status.value !== TRANSACTION_STATUSES_STEPS.AWAITING_BALANCE.name)
    ) {
      return true
    }
    return false
  }

  //load single transaction

  function loadedTransferDetails(transaction: TransactionDetails) {
    //select transaction
    selectedTransactionDetails.value = transaction

    //check allowed actions per transaction
    allowReceipt.value = transaction.doesContractNoteExist
    allowCancel.value = transaction.cancellable
    allowSendAgain.value = checkTransactionSendAgain(transaction)

    //check if it's o2o contract
    isOfflineContract.value =
      selectedTransactionDetails.value.actionsRequired !== 'None' &&
      selectedTransactionDetails.value.actionsRequired !==
      TRANSACTION_STATUSES_STEPS.AWAITING_BALANCE.name

    if (!transaction.recipientId) {
      transactionDetailsTitle.value = $t('PageActivity.TitleSummarySelectedTransaction').value
    } else {
      const recipientName = selectedTransaction.value?.recipientFullName
      if (recipientName) {
        transactionDetailsTitle.value = `${$t('PageActivity.TitleSelectedTransaction').value
          } ${truncateRecipientName(recipientName, 20)}`
      }
    }

    allowShare.value = false
    if (
      transaction.status &&
      transaction.status.value !== TRANSACTION_STATUSES_STEPS.CANCELLED.name &&
      transaction.transactionActivityStatus !==
      TRANSACTION_STATUSES_STEPS.PENDING_CANCELLATION.name &&
      transaction.status.value !== TRANSACTION_STATUSES_STEPS.COMPLETED.name &&
      transaction.status.value !== TRANSACTION_STATUSES_STEPS.COMPLETE.name
    ) {
      if (transaction.deliveryMethod === DeliveryMethod.CashPickup) {
        allowCashPickupModification.value = true
        allowShare.value = true
      } else if (transaction.deliveryMethod === DeliveryMethod.MobileWallet) {
        allowShare.value = true
      }
    }
    if (
      transaction.status &&
      (transaction.status.value === TRANSACTION_STATUSES_STEPS.AWAITING_RECIPIENT_DETAILS.name ||
        transaction.status.value === TRANSACTION_STATUSES_STEPS.HOLD_ON_ACCOUNT.name)
    ) {
      allowContactUs.value = true
      allowSendAgain.value = false
      if (transaction.status.value === TRANSACTION_STATUSES_STEPS.AWAITING_RECIPIENT_DETAILS.name) {
        allowProvideDetails.value = true
        allowShare.value = false
      }
    }
    if (
      transaction.deliveryMethod === DeliveryMethod.MobileWallet &&
      transaction.status &&
      transaction.status.value === TRANSACTION_STATUSES_STEPS.AWAITING_RELEASE.name
    ) {
      allowShare.value = true
      allowSendAgain.value = false
    }
    if (
      transaction.paymentMethod?.value === PAYMENT_METHODS.BANK_TRANSFER &&
      transaction.status &&
      (transaction.status.value === TRANSACTION_STATUSES_STEPS.UNCLEARED_FUNDS_RECEIVED.name ||
        transaction.status.value === 'UnclearedFundsRecieved' ||
        transaction.status.value === TRANSACTION_STATUSES_STEPS.UNCLEARED_FUNDS.name ||
        transaction.status.value === TRANSACTION_STATUSES_STEPS.AWAITING_BALANCE.name)
    ) {
      canShowBankTransferTimeEstimateDialog.value = true
    }

    if (allowShare.value) {
      _setSharableBody(transaction).then((body: string) => {
        shareBody.value = body
      })
    }
  }

  async function _setSharableBody(transaction: TransactionDetails): Promise<string> {
    return await activityStore.getTransactionShareBody({
      transaction: transaction,
      loadingRef: ref(false),
    })
  }

  async function showReceipt(transaction: any): Promise<void> {
    useActivityLoading.value = true

    try {
      await activityStore.showPdf(transaction.orderNumber)
    } catch (ex) {
      appStore.logException('Exception during getting PDF', ex)
      const msgBox = {
        title: $t('PageActivity.ErrorDownloadTitle').value,
      }
      appStore.messageBoxGenericOk(msgBox.title, '', '') // needs 3 arguments!!!
    } finally {
      useActivityLoading.value = false
    }
  }

  async function sendAgainBase(
    transaction: TransactionListItem,
    onError: Function | null = null
  ): Promise<Nullable<string>> {
    setActivityLoading()
    useActivityLoading.value = true
    try {
      let nextPath = await sendMoneyStore.sendAgain(transaction)

      if (nextPath) {
        return nextPath
      } else if (nextPath === undefined) {
        console.warn('Next Path undefined')
      } else {
        return '/send-money/summary'
      }
    } catch (ex: any) {
      if (
        [
          TRANSACTION_SENDAGIN_ERROR.RECIPIENT_NOT_FOUND,
          TRANSACTION_SENDAGIN_ERROR.PAYMENT_METHOD_UNAVAILABLE,
          TRANSACTION_SENDAGIN_ERROR.SEND_AGAIN_CANNOT_BE_PERFORMED,
        ].includes(ex.errorCode) &&
        onError
      ) {
        onError()
      } else {
        appStore.logException('Exception during send again', ex)
        appStore.messageBoxGenericError()
      }
    } finally {
      useActivityLoading.value = false
      setActivityReady()
    }
    return null
  }

  async function sendAgain(transaction: TransactionListItem): Promise<Nullable<string>> {
    activityStore.resetTransactionDetails()
    setActivityLoading()

    analyticsStore.track({
      event: SEGMENT_EVENTS.REPEAT_TRANSFER_STARTED,
      traits: {
        payoutMethod:
          transaction.deliveryMethod === DeliveryMethod.CashPickup
            ? SEGMENT_PAYOUT_METHODS.OFFICE_PICKUP
            : SEGMENT_PAYOUT_METHODS.BANK_DEPOSIT,
      },
    })

    const nextPath = await sendAgainBase(transaction, function onError() {
      hideSelectedTransactionModal()
      // showSendAgainNotPossibleDialog.value = true
    })

    setActivityReady()
    return nextPath
  }

  async function provideInfo(transaction: TransactionListItem): Promise<void> {
    loading.value = true

    await sendMoneyStore.goToOfflineToOnlineContract({
      transaction,
      location: SEGMENT_LOCATIONS.ACTIVITY,
    })
  }

  const corporateFillGTMData = async (shouldLoadTransactions: boolean = false): Promise<void> => {
    if (shouldLoadTransactions) {
      await activityStore.getTransactions()
    }

    const totalTransactions = activityStore.getTotalTransactions

    // TODO interface for user???
    if (authStore.user) {
      const user: UserProfile = authStore.user

      analyticsStore.gtmTrack({
        event: SEGMENT_EVENTS.ACTIVITY_ACCESSED,
        variables: {
          userName: `${user.firstName} ${user.lastName}`,
          accountKycStatus: user.customer.accountKycStatus,
          transactions_count: totalTransactions,
          TBU: user.customer.region,
        },
      })
    }
  }

  //TODO: Candidate to refactor
  async function getLocationUrl({
    country,
    currency,
    cityName,
    stateName,
    agent,
    agentLocation,
    loadingRef,
  }: {
    country: string
    currency: string
    cityName: string
    stateName: string
    agent: AgentTo
    agentLocation: AgentToLocation
    loadingRef: Ref<boolean> | null
  }): Promise<string> {
    const appStore = useAppStore()

    let url = 'https://www.xe.com/locations/?source=app&zoom=13'
    url += `&code=${country}`

    if (agent && agentLocation?.latitude) {
      if (loadingRef) {
        loadingRef.value = false
      }

      try {
        const { data } = await getLocations.exec({
          agent,
          country,
          city: {
            cityName,
            state: stateName,
          },
          currency,
        })

        let location = null

        if (agentLocation?.id) {
          location = data.find((loc: any) => loc.id === agentLocation.id)
        }

        if (!location && data.length > 0) {
          location = data[0]
        }
        if (location) {
          agentLocation = location
        }
      } catch (ex) {
        appStore.logException('Exception during geocoder', ex)
      }

      if (loadingRef) {
        loadingRef.value = false
      }

      url += `&lat=${agentLocation.latitude}&lon=${agentLocation.longitude}`
    } else {
      if (loadingRef) {
        loadingRef.value = true
      }

      try {
        const geocoder: any = await appStore.loadScriptMapboxGeocoder()
        const config = {
          query: cityName + ',' + stateName,
          language: ['en'],
          types: ['locality', 'place', 'region'],
        }
        const response = await geocoder.forwardGeocode(config).send()
        // eventually simpler/better: check place_type and favor place over locality
        if (response?.body?.features?.length > 0) {
          let foundFeature = null

          for (let feature of response.body.features) {
            if (feature?.center?.length >= 2) {
              if (!foundFeature) foundFeature = feature

              if (feature.text && feature.text.toLowerCase() === cityName.toLowerCase()) {
                foundFeature = feature
                break
              }
            }
          }

          if (foundFeature) {
            url += '&lat=' + foundFeature.center[1] + '&lon=' + foundFeature.center[0]
          }
        }
      } catch (ex) {
        appStore.logException('Exception during geocoder', ex)
      }

      if (loadingRef) {
        loadingRef.value = false
      }
    }

    if (agent) {
      url += `&agentId=${agent.id}`
    }

    return url
  }

  const setTransactionDetailsDialog = (value: boolean) => {
    showSelectedTransaction.value = value
  }

  /**
   * show selected Transaction Modal
   */
  const showSelectedTransactionModal = () => {
    showSelectedTransaction.value = true
  }

  /**
   * hide selected Transaction Modal
   */
  const hideSelectedTransactionModal = () => {
    showSelectedTransaction.value = false
  }

  const setEditTransactionDialog = (value: boolean) => {
    showEditTransaction.value = value
  }
  /**
   * show edit transaction dialog
   */
  const showEditTransactionDialog = () => {
    showEditTransaction.value = true
  }
  /**
   * hide edit transaction dialog
   */
  const hideEditTransactionDialog = () => {
    showEditTransaction.value = false
  }

  const showShareTransactionDialog = () => {
    isShareDialogOpen.value = true
  }
  const hideShareTransactionDialog = () => {
    isShareDialogOpen.value = false
  }

  const setSelectedTransaction = (transaction: TransactionListItem | null) => {
    selectedTransaction.value = transaction
  }

  const resetSelectedTransaction = () => {
    selectedTransaction.value = null
    hideSelectedTransactionModal()
  }

  const setProcessingTimeDialog = (value: boolean) => {
    isProcessingTimeDialogOpen.value = value
  }
  const setModifyRecipientName = (value: boolean) => {
    showModifyRecipientName.value = value
  }

  /**
   * activate loading spinner on activity
   */
  const setActivityLoading = () => {
    loadingDetails.value = true
  }

  /**
   * deactivate loading spinner on activity
   */
  const setActivityReady = () => {
    loadingDetails.value = false
  }

  /**
   * activate app-skeleton loading when fetching transactions
   */
  const setActivityListLoading = () => {
    loadingActivityList.value = true
  }

  /**
   * deactivate app-skeleton loading when fetching transactions
   */
  const setActivityListReady = () => {
    loadingActivityList.value = false
  }

  /**
   * show dialog to cancel selected transaction
   */
  const showCancelTransactionDialog = () => {
    showCancelTransfer.value = true
  }

  /**
   * hide dialog to cancel selected transaction
   */
  const hideCancelTransactionDialog = () => {
    showCancelTransfer.value = false
  }

  /**
   * flag to check if the transaction can be editable
   */
  const getIsEditable = computed((): boolean => {
    if (selectedTransactionDetails.value) {
      const isEditable =
        selectedTransactionDetails.value?.modifiable && allowCashPickupModification.value
      return isEditable
    }
    return false
  })

  /**
   * check if the tranaction is cancellable
   */
  const getIsCancellable = computed((): boolean => {
    return allowCancel.value
  })

  /**
   * show Modify Failed Dialog
   */
  const showModifyFailedDialog = () => {
    showModifyFailed.value = true
  }

  /**
   * hide Modify Failed Dialog
   */
  const hideModifyFailedDialog = () => {
    showModifyFailed.value = false
  }

  const showBankTransferTimeEstimateDialog = () => {
    bankTransferTimeEstimateDialogVisible.value = true
  }

  const hideBankTransferTimeEstimateDialog = () => {
    bankTransferTimeEstimateDialogVisible.value = false
  }

  return {
    // states
    showSelectedTransaction,
    transactionDetailsTitle,
    useActivityLoading,
    selectedTransaction,
    allowCancel,
    allowSendAgain,
    allowProvideDetails,
    allowCashPickupModification,
    allowContactUs,
    canShowBankTransferTimeEstimateDialog,
    showSendAgainNotPossibleDialog,
    isOfflineContract,
    showEditTransaction,
    showModifyRecipientName,
    selectedTransactionDetails,
    allowReceipt,
    loadingDetails,
    loadingActivityList,
    isProcessingTimeDialogOpen,
    showCancelTransfer,
    selectedRecipient,
    showCancellationSuccessful,
    showCancellationFailed,
    showModifyFailed,
    buttons,
    loading,
    // actions
    selectTransaction,
    loadedTransferDetails,
    showReceipt,
    sendAgainBase,
    sendAgain,
    provideInfo,
    corporateFillGTMData,
    getLocationUrl,
    setEditTransactionDialog,
    setTransactionDetailsDialog,
    setSelectedTransaction,
    resetSelectedTransaction,
    setProcessingTimeDialog,
    setModifyRecipientName,
    //share dialog
    showShareTransactionDialog,
    hideShareTransactionDialog,
    shareBody,
    isShareDialogOpen,
    loadingShare,
    allowShare,
    //activity loading state
    setActivityLoading,
    setActivityReady,
    //cancel transaction dialog
    showCancelTransactionDialog,
    hideCancelTransactionDialog,
    //edit transaction dialog
    showEditTransactionDialog,
    hideEditTransactionDialog,
    // transaction editable
    getIsEditable,
    // is cancellable
    getIsCancellable,
    //modify failed dialog
    showModifyFailedDialog,
    hideModifyFailedDialog,
    //selected transaction details modal visibility
    showSelectedTransactionModal,
    hideSelectedTransactionModal,
    //bankTransferEstiatedialog aka howLongBank
    bankTransferTimeEstimateDialogVisible,
    showBankTransferTimeEstimateDialog,
    hideBankTransferTimeEstimateDialog,
    // activity list loading state
    setActivityListLoading,
    setActivityListReady,
  }
})
