import { maskFormatAbbreviated } from '@galileo/composables/useMaskHelpers'
export default class GenericAccount {
  constructor(account) {
    this.country = account.accountCountry
    this.currency = account.accountCurrency
    this.id = account.accountId
    this.alias = account.accountAlias
    this.type = account.accountType
    this.billingStreet = account.billingAddress
    this.billingCity = account.billingCity
    this.billingCountry = account.billingCountry
    this.billingPostalCode = account.billingPostalCode
    this.billingState = account.billingState
    this.createdDate = account.createdDate
    this.disabled = account.disabled
    this.duplicate = account.duplicate
    this.externalReferenceNumber = account.externalReferenceNumber
    this.firstName = account.firstName
    this.lastName = account.lastName
    this.middleName = account.middleName
    this.maskedAccountNumber = account.maskedAccountNumber
    if (this.maskedAccountNumber) {
      this.maskedAccountNumber = maskFormatAbbreviated(this.maskedAccountNumber)
    }
    this.paymentMethod = account.paymentMethod

    // has to be set by the child classes
    this.title = this.maskedAccountNumber
    this.subTitle = ''
    this.image = ''
  }
}
