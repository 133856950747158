<template>
  <AppSnackAlert :alert="alert" @dismiss="dismiss" />
</template>

<script>
import { AppSnackAlert, useAlert } from '@oen.web.vue2/ui'
export default {
  components: {
    AppSnackAlert,
  },
  setup() {
    const { alert, dismiss } = useAlert()

    return {
      alert,
      dismiss,
    }
  },
}
</script>

<style scoped></style>
