import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

import { storeToRefs } from 'pinia'

import { useAuthStore, useThemeStore } from '@galileo/stores'


export default new APIHandler(
  API,
  ({ countryFrom, countryTo }, config) => {
    const themeStore = useThemeStore()
    const authStore = useAuthStore()
    const { isCorporateAccount } = storeToRefs(authStore)

    const accountType = isCorporateAccount.value ? 2 : 1

    let url = `resources/currencies?country=${countryFrom}&countryTo=${countryTo}&accountType=${accountType}`

    const brandId = themeStore.brandId

    if (brandId) {
      url += `&brandId=${brandId}`
    }

    return {
      ...config,
      method: 'GET',
      url: url,
    }
  },
  (response) => {
    return {
      ...response,
      data: response.data.map((currency) => {
        return {
          value: currency.isoCode,
          text: currency.isoCode,
          description: currency.name,
          enableTo: currency.canBuy,
          enableFrom: currency.canSell,
          sameCurrencySupported: currency.sameCurrencySupported,
        }
      }),
    }
  }
)
