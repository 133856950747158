export const PAYMENT_METHODS = {
  BANK_TRANSFER: 'BankTransfer',
  WIRE_TRANSFER: 'WireTransfer',
  DIRECT_DEBIT: 'DirectDebit',
  DEBIT_CARD: 'DebitCard',
  CREDIT_CARD: 'CreditCard',
  OPEN_BANKING: 'OpenBanking',
  FUNDS_ON_BALANCE: 'FundsOnBalance',
  INTERAC: 'Interac',
}

export const TRANSFER_TYPE = {
  SEND_MONEY_FLOW: 'SendMoneyFlow',
  QUICK_TRANSFER: 'QuickTransfer',
  FUND_BALANCE: 'FundBalance',
  CONVERT_BALANCE: 'ConvertBalances',
  ONE_STEP_TRANSFER: 'OneStepTransfer',
}

export const TRANSFER_TYPE_NAME = {
  SEND_MONEY_FLOW: 'SMF',
  QUICK_TRANSFER: 'Quick transfer',
  FUND_BALANCE: 'Fund',
  CONVERT_BALANCE: 'Convert',
}

export const DIRECT_DEBIT = {
  EFT: 'Direct Debit (EFT)',
  ACH: 'Direct Debit (ACH)',
}

export const QUOTE_SCREEN = {
  REQUEST_CANCELED_ERROR: 'canceled',
  AMOUNT_TO: 'amountTo',
  AMOUNT_FROM: 'amountFrom',
}

export const DELIVERY_METHODS = {
  BANK_ACCOUNT: 'BankAccount',
  CASH_PAYOUT: 'CashPayout',
  MOBILE_WALLET: 'MobileWallet',
  FUNDS_ON_BALANCE: 'FundsOnBalance',
  CASH_PICKUP: 'CashPickup',
}

export const STEPS = {
  SEND_MONEY_AMOUNT: 'SendMoneyAmount',
  SEND_MONEY_CHOOSE_LOCATION: 'SendMoneyDeliveryChooseLocation',
  SEND_MONEY_PICKUP_LOCATION: 'SendMoneyCashPickupLocation',
  SEND_MONEY_RECIPIENT: 'SendMoneyRecipient',
  SEND_MONEY_WALLET_DETAILS: 'SendMoneyWalletDetails',
  SEND_MONEY_PAYMENT: 'SendMoneyPayment',
  SEND_MONEY_SUMMARY: 'SendMoneySummary',
  SEND_MONEY_ONFIDO: 'SendMoneyOnfido',
  SEND_MONEY_VERIFF: 'SendMoneyVeriff',
  SEND_MONEY_FAILED: 'SendMoneyFailed',
}

export const TRANSFER = {
  STAGES: {
    BASIC: 'Basic',
    EDD: 'Edd',
    CONFIRM: 'Confirm',
    WALLET_VALIDATE: 'WalletValidate',
  },
  RESPONSE: {
    VERIFIED: {
      TRUE: 'True', // if the user has been verified
      STOP: 'Stop', // if user account should be closed
      RESTRICTED: 'Restricted',
      PENDING: 'Pending', // if a decision has not yet been made
      FALSE: 'False', // if the users verification needs to resubmit
    },
    MESSAGE: {
      RIA_GENERAL_STOP: 'Ria General Stop',
      PAYMENT_STOPPED: 'PAYMENT|Payment stopped',
      DOCUMENT_RESULT_PENDING: 'Document results pending',
    },
  },
}

export const VOLT = {
  STATUS: {
    COMPLETED: 'COMPLETED',
    DELAYED_AT_BANK: 'DELAYED_AT_BANK',
    FAILED: 'FAILED',
    REFUSED_BY_BANK: 'REFUSED_BY_BANK',
    ERROR_AT_BANK: 'ERROR_AT_BANK',
    CANCELLED_BY_USER: 'CANCELLED_BY_USER',
  },
  ERROR: {
    SESSION_EXISTS: 'OBANKAPI_100',
  },
}

export const CALL_US_NUMBER = {
  CORPORATE: '+1-877-888-0263',
  CONSUMERS: '+1-877-877-7028',
}
