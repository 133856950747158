import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'
import { mapBankAccountFields } from '@galileo/api/launchpad/cards/accounts/_profileId/get'
import BankAccount from '@galileo/models/Payment/BankAccount'
import { PLAID_CREATION_STATUS } from '@galileo/constants/bankAccCreationStatuses.const'

export default new APIHandler(
  API,
  ({ accountId, plaidMetaData }, config) => {
    const request = {
      accountId: accountId,
      nickname: '',
      publicToken: plaidMetaData.public_token,
      verificationStatus: plaidMetaData.account?.verification_status,
    }

    return {
      ...config,
      method: 'PUT',
      url: 'plaid/v2/confirm-account',
      data: request,
    }
  },
  (response) => {
    if (response?.data) {
      let bankAccount = null
      if (response.data.creationStatus === PLAID_CREATION_STATUS.CREATED) {
        const bankAccountData = {
          accountCountry: response.data.bankAccount.countryCode,
          accountCurrency: response.data.bankAccount.currency,
          status: response.data.bankAccount.verificationStatus,
          paymentMethod: 'DirectDebit',
          accountId: response.data.bankAccount.versionNumber,
          verificationStatus: response.data.bankAccount.status,
        }
        mapBankAccountFields(bankAccountData, response.data.bankAccount.fields)

        bankAccount = new BankAccount(bankAccountData)
      }

      return {
        bankAccount,
        creationStatus: response.data.creationStatus,
      }
    }
  }
)
