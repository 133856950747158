<template>
  <AppDescriptionList :borderTop="borderTop" :class="{ 'no-border-bottom': !borderBottom }">
    <slot></slot>
  </AppDescriptionList>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { AppDescriptionList } from '@oen.web.vue2/ui'

export default defineComponent({
  components: {
    AppDescriptionList,
  },
  props: {
    borderTop: {
      type: Boolean,
      default: false,
    },
    borderBottom: {
      type: Boolean,
      default: false,
    },
  },
  setup() {},
})
</script>

<style scoped>
.no-border-bottom.description-list {
  @apply border-b-0 !important;
}
</style>
