import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  (config) => {
    return {
      ...config,
      method: 'POST',
      url: 'authorization/changeEmailResend',
    }
  },
  async (response) => {
    let savedSucessfully = false

    if (response.status === 200) {
      savedSucessfully = true
    }

    return savedSucessfully
  }
)
