<template>
  <InformationAppModal
    class="feedback-modal"
    noIcon
    :shouldDisplayCloseButton="true"
    :shouldDisplayBackButton="isFirstStep || isLastStep || isMobileView ? false : true"
    button-title="Submit"
    should-emit-back
    @close="closeSurvey"
    @emit-back="goBack"
    :value="showFeedbackSurvey"
  >
    <div v-if="!isLastStep" class="survey-form">
      <div v-if="!showFeedbackResponse || !isMobileView" class="feedback-score">
        <h1 class="survey-title">{{ $t('FeedbackSurveyModal.SurveyTitle').value }}</h1>
        <div class="score-text">
          <p>{{ $t('FeedbackSurveyModal.ScoresTextBad').value }}</p>
          <p>{{ $t('FeedbackSurveyModal.ScoresTextExcellent').value }}</p>
        </div>
        <div class="scores">
          <div class="score-btn" v-for="(score, index) in scores" :key="index">
            <button :class="{ active: score === isActive }" @click="submitScore(score)">
              {{ score.name }}
            </button>
          </div>
        </div>
      </div>
      <div v-if="showFeedbackResponse" class="feedback-response">
        <AppInputTextArea
          v-model="form.feedbackResponse"
          :value="form.feedbackResponse"
          :label="$t('FeedbackSurveyModal.TextAreaLabel').value"
          maxlength="200"
          rows="6"
          :placeholder="$t('FeedbackSurveyModal.TextAreaPlaceholder').value"
          :helperText="`${charCounter}/200`"
        />
      </div>
    </div>

    <div v-else class="last-step">
      <IconHandsUp class="last-step-icon" />
      <h1 class="last-step-title">{{ $t('FeedbackSurveyModal.WeHeardYouTitle').value }}</h1>
      <p class="last-step-text text-secondary-text">
        {{ $t('FeedbackSurveyModal.LastStepText').value }}
      </p>
    </div>

    <template #footer>
      <div>
        <AppButton v-if="showFeedbackResponse" class="submit-btn" @click="submit">{{
          $t('FeedbackSurveyModal.SubmitButton').value
        }}</AppButton>
      </div>
    </template>
  </InformationAppModal>
</template>

<script>
import { useRouter } from '@galileo/composables/useRouter'

import { ref, computed, reactive } from '@vue/composition-api'
import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'
import { AppButton, AppInputTextArea, useMediaQuery } from '@oen.web.vue2/ui'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'

import { IconHandsUp, IconStar } from '@galileo/assets/icons/vue'
import { SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'

import {
  useI18nStore,
  useAnalyticsStore,
  useSendMoneyStore,
  useAuthStore,
  useQuickTransferStore,
} from '@galileo/stores'

export default {
  name: 'FeedbackSurveyModal',
  components: {
    InformationAppModal,
    AppButton,
    AppInputTextArea,
    IconHandsUp,
    IconStar,
  },
  setup() {
    const { $t } = useI18nStore()
    const router = useRouter()
    const analyticsStore = useAnalyticsStore()
    const sendMoneyStore = useSendMoneyStore()

    const mq = useMediaQuery()

    const isMobileView = computed(() => {
      return mq.current === 'xs'
    })

    const isActive = ref(null)

    const showFeedbackSurvey = computed(() => {
      const isSendMoneyFinished = router.currentRoute.path === '/send-money/finished'

      return (
        isSendMoneyFinished && isSurveyOpen.value && !sendMoneyStore.bankDetailsChangedIsVisible
      )
    })

    const showFeedbackResponse = ref(false)

    const isSurveyOpen = computed(() => {
      let value = sendMoneyStore.isSurveyOpen
      if (value) {
        isFirstStep.value = true
        isLastStep.value = false
        resetForm()
      }
      return value
    })
    const isFirstStep = ref(true)
    const isLastStep = ref(false)

    const scoreText = ref('')

    const resetForm = () => {
      form.feedbackResponse = ''
      form.feedbackScore = null
    }

    const form = reactive({
      feedbackScore: null,
      feedbackResponse: '',
    })

    const charCounter = computed(() => {
      return form.feedbackResponse.length
    })

    const scores = [
      {
        name: '1',
        value: 1,
        text: $t('FeedbackSurveyModal.ScoreTextBad').value,
      },
      {
        name: '2',
        value: 2,
        text: $t('FeedbackSurveyModal.ScoreTextOkay').value,
      },
      {
        name: '3',
        value: 3,
        text: $t('FeedbackSurveyModal.ScoreTextGood').value,
      },
      {
        name: '4',
        value: 4,
        text: $t('FeedbackSurveyModal.ScoreTextVeryGood').value,
      },
      {
        name: '5',
        value: 5,
        text: $t('FeedbackSurveyModal.ScoreTextExcellent').value,
      },
    ]

    const sendFeedback = () => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.FEEDBACK_SUBMITTED,
        traits: {
          feedbackScore: form.feedbackScore,
          feedbackResponse: form.feedbackResponse,
          location: useQuickTransferStore().isCurrentTransactionQuickTransfer
            ? SEGMENT_LOCATIONS.QUICK_TRANSFER
            : SEGMENT_LOCATIONS.SEND_MONEY,
        },
      })
    }

    const closeSurvey = () => {
      if (form.feedbackScore && !isLastStep.value) {
        sendFeedback()
      }
      sendMoneyStore.isSurveyOpen = false
    }

    const goBack = () => {
      if (!isFirstStep.value) {
        showFeedbackResponse.value = !showFeedbackResponse.value
        isFirstStep.value = true
      }
    }

    const submitScore = (score) => {
      isActive.value = score
      showFeedbackResponse.value = true
      isFirstStep.value = false
      form.feedbackScore = score.value
      scoreText.value = score.text
    }

    const submit = () => {
      sendFeedback()
      showFeedbackResponse.value = !showFeedbackResponse.value
      isLastStep.value = true
    }

    return {
      $t,
      showFeedbackSurvey,
      closeSurvey,
      scores,
      submitScore,
      submit,
      goBack,
      showFeedbackResponse,
      form,
      isFirstStep,
      isLastStep,
      isMobileView,
      scoreText,
      charCounter,
      isSurveyOpen,
      isActive,
    }
  },
}
</script>

<style scoped>
.active {
  background-color: #f2f3f5;
}
::v-deep .card-content {
  @apply px-8;
}
.feedback-modal {
  @apply shadow-ria-1;
  position: fixed;
  top: unset;
  right: 3rem;
  bottom: 3rem;
  left: unset;
  z-index: 50;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: unset;
  border-radius: 20px;
  scrollbar-gutter: unset !important;
}

::v-deep .overlay-content .card {
  max-width: 346px;
  border: none;
  @apply pb-0 shadow-ria-1;
  .card-header {
    @apply pb-0 pt-4 px-8;
    border: none;
  }
}
::v-deep .card-content-block {
  @apply mb-0;
}

::v-deep .modal-body .survey-title {
  @apply text-left;
  @apply mb-6 mt-4;
  @apply text-xl;
}
.score-text {
  @apply flex justify-between;
  @apply pt-0 pb-1;
  @apply text-xs;
  padding-left: 10px;
  padding-right: 9px;
  color: #8890a0;
}

.scores {
  @apply flex justify-center gap-4;
  @apply mt-3;
}
.score-btn button {
  @apply h-10 w-10;
  border: 1.5px solid #d9dbe0;
  border-radius: 50%;
}

.score-btn button:hover {
  background: #f2f3f5;
}

::v-deep .input-textarea--full-width {
  resize: none;
}

.feedback-response {
  width: 100%;
  @apply mt-12;
  ::v-deep .input-label {
    @apply flex;
    @apply text-base font-medium;
    @apply my-3;
    color: #333333;
  }
  ::v-deep .input-helper {
    @apply flex justify-end;
  }
  ::v-deep .input-validation {
    @apply flex justify-end;
  }
}
::v-deep .card-footer {
  @apply px-8;
}

.submit-btn {
  width: 100% !important;
  @apply mb-12;
}

.last-step {
  @apply flex flex-col gap-4;
}

.last-step-icon {
  @apply h-10 w-10;
}
.last-step-title {
  @apply text-left text-xl;
}

.last-step-text {
  text-align: start;
  @apply text-sm font-normal;
}

@media only screen and (max-width: 600px) {
  .feedback-modal {
    @apply bg-gray-dark;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 50;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
  }

  .feedback-response {
    @apply mt-0;
    ::v-deep .input {
      @apply mt-6;
    }
  }

  .score-text {
    @apply px-0;
  }
  .feedback-score {
    @apply flex flex-col;
  }
  ::v-deep .card-content {
    @apply flex flex-col justify-center;
  }
  ::v-deep .overlay-content .card {
    max-width: 100%;
    position: fixed;
    bottom: 0;
    min-height: fit-content;
    max-height: 60%;
    border-radius: 20px 20px 0 0;

    .card-header {
      padding: 2rem 2rem 0rem 2rem;
    }
  }

  .survey-form {
    @apply flex flex-col items-center gap-4;
  }
  ::v-deep .modal-body .survey-title {
    @apply text-center text-2xl;
    @apply mt-0;
  }
}
</style>
