import API from '@/api/launchpad'
import APIHandler from '@/api/LaunchpadAPIHandler'
import AddressDetails from '@/models/Corporate/AddressDetails'

export default new APIHandler(
  API,
  (addressId) => {
    return {
      method: 'GET',
      url: `address/${addressId}`,
    }
  },
  (response) => {
    return {
      ...response,
      data: response.data.items.map((item) => new AddressDetails(item)),
    }
  }
)
