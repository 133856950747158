import { fromApi } from '@galileo/models/Country/countryMapping'

export const DELIVERY_PROVIDERS = {
  CASH_PAYOUT: 'RiaCashPayout',
  MOBILE_WALLET: 'RiaMobileWallet',
  BANK_ACCOUNT: 'XeBankTransfer',
  BALANCE: 'FundsOnBalance',
}

export const DeliveryMethod = {
  CashPickup: {
    id: 1,
    enumName: 'CashPayout',
    text: 'Cash pickup',
    textKey: 'ComponentXeTransferDetails.DeliveryMethodOfficePickup',
    // Translation KEEP: $t('ComponentXeTransferDetails.DeliveryMethodOfficePickup')
  },
  BankDeposit: {
    id: 2,
    enumName: 'BankAccount',
    text: 'Bank account',
    textKey: 'ComponentXeTransferDetails.DeliveryMethodBankDeposit',
    // Translation KEEP: $t('ComponentXeTransferDetails.DeliveryMethodBankDeposit')
  },
  HomeDelivery: {
    id: 3,
    enumName: 'HomeDelivery',
    text: 'Home delivery',
    textKey: 'ComponentXeTransferDetails.DeliveryMethodHomeDelivery',
    // Translation KEEP: $t('ComponentXeTransferDetails.DeliveryMethodHomeDelivery')
  },
  MobileWallet: {
    id: 34,
    enumName: 'MobileWallet',
    text: 'Mobile wallet',
    textKey: 'ComponentXeTransferDetails.DeliveryMethodMobileWallet',
    // Translation KEEP: $t('ComponentXeTransferDetails.DeliveryMethodMobileWallet')
  },
  FundsOnBalance: {
    id: 5,
    enumName: 'FundsOnBalance',
    text: 'Balance',
    textKey: 'ComponentXeTransferDetails.DeliveryMethodFundsOnBalance',
    // Translation KEEP: $t('ComponentXeTransferDetails.DeliveryMethodMobileWallet')
  },
}

export const mapDeliveryMethodId = (id) => {
  for (const key in DeliveryMethod) {
    const value = DeliveryMethod[key]
    if (value.id === id) {
      return value
    }
  }
  return DeliveryMethod.CashPickup
}

export const mapDeliveryMethodName = (name) => {
  for (const key in DeliveryMethod) {
    const value = DeliveryMethod[key]
    if (value.enumName === name) {
      return value
    }
  }
  return DeliveryMethod.CashPickup
}

// $t('PageActivity.StatusPaymentPending')
// $t('PageActivity.StatusInProcess')
// $t('PageActivity.StatusReadyForPickup')
// $t('PageActivity.StatusReceived')
// $t('PageActivity.StatusCanceled')
// $t('PageActivity.StatusRefunded')
// $t('PageActivity.StatusRefundPending')
// $t('PageActivity.StatusTransferCompleted')
// $t('PageActivity.StatusExpired')
// $t('PageActivity.StatusUnfunded')
/* Missing keys: InProgress, ConfirmationExpired, InReview, Paid */
export const TransactionStatus = {
  PaymentPending: {
    id: 10,
    key: 'PaymentPending',
    text: 'Payment pending',
    textKey: 'PageActivity.StatusPaymentPending',
  },
  Processing: {
    id: 20,
    key: 'InProcess',
    text: 'In progress',
    textKey: 'PageActivity.StatusInProcess',
  },
  RecipientCanCollect: {
    id: 30,
    key: 'ReadyForPickup',
    text: 'Ready for pickup',
    textKey: 'PageActivity.StatusReadyForPickup',
  },
  CollectedByRecipient: {
    id: 40,
    key: 'CollectedByRecipient',
    text: 'Received',
    textKey: 'PageActivity.StatusReceived',
  },
  Cancelled: {
    id: 50,
    key: 'Canceled',
    text: 'Canceled',
    textKey: 'PageActivity.StatusCanceled',
  },
  Refunded: {
    id: 60,
    key: 'Refunded',
    text: 'Refunded',
    textKey: 'PageActivity.StatusRefunded',
  },
  AvailableToRefund: {
    id: 70,
    key: 'AvailableToRefund',
    text: 'Refund pending',
    textKey: 'PageActivity.StatusRefundPending',
  },
  Completed: {
    id: 80,
    key: 'Completed',
    text: 'Transfer completed',
    textKey: 'PageActivity.StatusTransferCompleted',
  },
  Expired: {
    id: 90,
    key: 'Expired',
    text: 'Expired',
    textKey: 'PageActivity.StatusExpired',
  },
  Unfunded: {
    id: 100,
    key: 'Unfunded',
    text: 'Unfunded',
    textKey: 'PageActivity.StatusUnfunded',
  },
  ConfirmationExpired: {
    id: -1,
    key: 'ConfirmationExpired',
    text: 'Canceled',
    textKey: 'PageActivity.StatusCanceled',
  },
}
export const DeliveryMethodEnums = {}
for (const name in DeliveryMethod) {
  const method = DeliveryMethod[name]
  DeliveryMethodEnums[method.enumName] = method
}

// $t('ComponentXeTransferDetails.PaymentMethodAny')
// $t('ComponentXeTransferDetails.PaymentMethodCash')
// $t('ComponentXeTransferDetails.PaymentMethodCheck')
// $t('ComponentXeTransferDetails.PaymentMethodBankAccount')
// $t('ComponentXeTransferDetails.PaymentMethodCreditCard')
// $t('ComponentXeTransferDetails.PaymentMethodPayPal')
// $t('ComponentXeTransferDetails.PaymentMethodEWallet')
// $t('ComponentXeTransferDetails.PaymentMethodDebitCard')
// $t('ComponentXeTransferDetails.PaymentMethodPayNearMe')
// $t('ComponentXeTransferDetails.PaymentMethodStagedRIA')
// $t('ComponentXeTransferDetails.PaymentMethodDirectBank')
// $t('ComponentXeTransferDetails.PaymentMethodBankTransfer')
// $t('ComponentXeTransferDetails.PaymentMethodInterac')
export const PaymentMethod = {
  Any: {
    id: 0,
    enumName: 'Any',
    text: 'Any',
    textKey: 'ComponentXeTransferDetails.PaymentMethodAny',
  },
  Cash: {
    id: 10,
    enumName: 'Cash',
    text: 'Cash',
    textKey: 'ComponentXeTransferDetails.PaymentMethodCash',
  },
  Check: {
    id: 20,
    enumName: 'Check',
    text: 'Check',
    textKey: 'ComponentXeTransferDetails.PaymentMethodCheck',
  },
  BankAccount: {
    id: 30,
    enumName: 'DirectDebit',
    text: 'Bank account',
    textKey: 'ComponentXeTransferDetails.PaymentMethodBankAccount',
  },
  DirectDebit: {
    id: 30,
    enumName: 'DirectDebit',
    text: 'Direct debit',
    textKey: 'ComponentAddPaymentMethodModal.DirectDebitText',
  },
  CreditCard: {
    id: 40,
    enumName: 'CreditCard',
    text: 'Credit card',
    textKey: 'ComponentXeTransferDetails.PaymentMethodCreditCard',
  },
  PayPal: {
    id: 60,
    enumName: 'PayPal',
    text: 'PayPal',
    textKey: 'ComponentXeTransferDetails.PaymentMethodPayPal',
  },
  eWallet: {
    id: 70,
    enumName: 'EWallet',
    text: 'eWallet',
    textKey: 'ComponentXeTransferDetails.PaymentMethodEWallet',
  },
  DebitCard: {
    id: 80,
    enumName: 'DebitCard',
    text: 'Debit card',
    textKey: 'ComponentXeTransferDetails.PaymentMethodDebitCard',
  },
  PayNearMe: {
    id: 91,
    enumName: 'PayNearMe',
    text: 'Cash',
    textKey: 'ComponentXeTransferDetails.PaymentMethodPayNearMe',
  },
  StagedRIA: {
    id: 92,
    enumName: 'StagedRia',
    text: 'Pay in store',
    textKey: 'ComponentXeTransferDetails.PaymentMethodStagedRIA',
  },
  DirectBank: {
    id: 120,
    enumName: 'DirectBank',
    text: 'Direct bank',
    textKey: 'ComponentXeTransferDetails.PaymentMethodDirectBank',
  },
  Interac: {
    id: 130,
    enumName: 'Interac',
    text: 'Interac',
    textKey: 'ComponentXeTransferDetails.PaymentMethodInterac',
  },
  BankTransfer: {
    id: 9000,
    enumName: 'BankTransfer',
    text: 'Bank transfer',
    textKey: 'ComponentXeTransferDetails.PaymentMethodBankTransfer',
  },
  OpenBanking: {
    id: 1234, // TODO: ???
    enumName: 'OpenBanking',
    text: 'Open banking',
    textKey: 'ComponentXeTransferDetails.PaymentMethodDirectBank',
  },
  FundsOnBalance: {
    id: 1111, // TODO: ???
    enumName: 'FundsOnBalance',
    text: 'Balance',
    textKey: 'ComponentXeTransferDetails.Balance',
  },
}
export const PaymentMethodEnums = {}
for (const name in PaymentMethod) {
  const method = PaymentMethod[name]
  PaymentMethodEnums[method.enumName] = method
}

// export class Transaction {
//   constructor(transaction) {
//     this.amountFrom = transaction.AmountLocal
//     this.amountTo = transaction.TransferAmount
//     this.bankDepositBankName = transaction.BankName
//     this.recipientId = transaction.BenNameId
//     this.recipientFirstName = transaction.BeneficiaryFirstName
//     this.recipientLastName = transaction.BeneficiaryFirstLastName
//     this.barcode = transaction.barcode
//     this.cancellable = transaction.Cancellable
//     this.dateCancelled = transaction.CancelledTime
//     this.countryFrom = fromApi(transaction.CountryFrom)
//     this.countryTo = fromApi(transaction.CountryTo)
//     this.currencyFrom = transaction.PaymentCurrency
//     this.currencyTo = transaction.Currency
//     this.dateCreated = transaction.EnteredTime
//     this.date = transaction.Date
//     this.dateAvailablePayout = transaction.DateAvailablePayout
//     this.id = transaction.Id
//     this.isAvailableToRefund = transaction.IsAvailableToRefund
//     this.isOpenCountry = transaction.IsOpenCountry
//     this.isOpenNetwork = transaction.IsOpenNetwork
//     this.isNotProcessed = transaction.NotProcessed
//     this.orderNumber = transaction.OrderNo
//     this.datePaid = transaction.PaidTime
//     this.pin = transaction.Pin
//     this.amountTax = transaction.TaxAmount
//     this.amountTotal = transaction.TotalAmount
//     this.isVoided = transaction.Voided
//     this.status = null // filled below
//     this.statusCategoryId = transaction.OrderStatusCategory
//     this.destinationCity = transaction.PayCity
//     this.recipientInitials =
//       this.recipientFirstName.charAt(0).toUpperCase() +
//       this.recipientLastName.charAt(0).toUpperCase()

//     this.deliveryMethod = mapDeliveryMethodId(transaction.DelivMethod)

//     if (
//       this.deliveryMethod === DeliveryMethod.CashPickup ||
//       this.deliveryMethod === DeliveryMethod.HomeDelivery
//     ) {
//       this.deliveryMethodAgentId = transaction.PayAgentId
//       this.deliveryMethodLocation = {
//         agentId: transaction.PayAgentId,
//         agentName: transaction.PayAgentName,
//         id: transaction.PayAgentLocId,
//         city: transaction.PayAgentLocName,
//       }
//     }

//     for (const key in PaymentMethod) {
//       const value = PaymentMethod[key]
//       if (value.id === transaction.PaymentMethod) {
//         this.paymentMethod = value
//         break
//       }
//     }

//     for (const key in TransactionStatus) {
//       const value = TransactionStatus[key]
//       if (value.id === transaction.StatusId) {
//         this.status = value
//         break
//       }
//     }

//     //adding a flag resend transaction not available paymentMethod:bank  && spain user
//     this.isResendable = !(this.paymentMethod?.id === 120 && this.countryFrom === 'ES')

//     this.figureColor = 'purple'
//     this.iconComponent = 'IconSendAlt'
//     if (this.status === TransactionStatus.Processing) {
//       this.figureColor = 'blue'
//       this.iconComponent = 'IconTime'
//     } else if (this.status === TransactionStatus.RecipientCanCollect) {
//       this.figureColor = 'green'
//       this.iconComponent = 'IconMoney'
//     } else if (
//       this.status === TransactionStatus.Completed ||
//       this.status === TransactionStatus.CollectedByRecipient
//     ) {
//       this.figureColor = 'green'
//       this.iconComponent = 'IconCheckmark'
//     } else if (
//       this.status === TransactionStatus.Expired ||
//       this.status === TransactionStatus.Cancelled ||
//       this.status === TransactionStatus.ConfirmationExpired
//     ) {
//       this.figureColor = 'red'
//       this.iconComponent = 'IconClose'
//     } else if (this.status === TransactionStatus.AvailableToRefund) {
//       this.figureColor = 'yellow'
//       this.iconComponent = 'IconRotate'
//     } else if (this.status === TransactionStatus.Refunded) {
//       this.figureColor = 'purple'
//       //this.iconComponent = 'IconRefund' TODO !!!!!!!!
//     } else if (this.status === TransactionStatus.PaymentPending) {
//       this.figureColor = 'yellow'
//       this.iconComponent = 'IconWarningAlt'
//     }

//     /*
//     UNUSED ?
//     transaction.BankAccountNo: null
//     transaction.BenId: 0
//     transaction.BeneficiarySecondLastName: ""
//     transaction.Commission: 9
//     transaction.CustomerFirstName: "First"
//     transaction.CustomerIDNo: ""
//     transaction.CustomerIDType: ""
//     transaction.CustomerId: 28351020
//     transaction.CustomerLastName: "Last"
//     transaction.CustomerLastName2: " "
//     transaction.DelivMethodName: "Bank Deposit"
//     transaction.ExpirationDate: null
//     transaction.OriginalCustomerCharge: null
//     transaction.PayAddressLine1: "140 Aldersgate Street - London - UK"
//     transaction.PayAddressLine2: ""
//     transaction.PayAgentLocName: "Alabama"
//     transaction.PayCountry: "DE"
//     transaction.PayPostalCode: "0000"
//     transaction.PostalAddress: null
//     transaction.RecAgentId: 1094012
//     transaction.RecAgentLocId: 100101
//     transaction.RefundDatetime: null
//     transaction.Surcharge: 0
//     transaction.TransfReason: null
//     */
//   }

//   getFormattedDate(locale) {
//     const date = new Date(this.dateCreated)
//     return date.toLocaleDateString(locale, { year: 'numeric', month: 'short', day: 'numeric' })
//   }
// }
