export const PAYMENT_GATEWAY = {
  APEXX: 'Apexx',
  CYBERSOURCE: 'Cybersource',
  ADYEN: 'Adyen',
}

export const CARD_BRANDS = {
  VISA: 'Visa',
  MASTERCARD: 'Mastercard',
}

export const CARD_BRAND_MAPPINGS = {
  VISA: CARD_BRANDS.VISA,
  DELTA: CARD_BRANDS.MASTERCARD,
  MCC: CARD_BRANDS.MASTERCARD,
  MCD: CARD_BRANDS.MASTERCARD,
}

export const ADYEN_ACTION_TYPES = {
  threeDS2: 'Native',
  redirect: 'Hosted',
}

export const CARD_FULL_TYPE = {
  VISA_DEBIT: 'Visa Debit',
  VISA_CREDIT: 'Visa Credit',
  MASTERCARD_DEBIT: 'Mastercard Debit',
  MASTERCARD_CREDIT: 'Mastercard Credit',
}

export const CARD_TYPES_MAPPINGS = {
  'Visa Debit': 'Visa',
  'Visa Credit': 'Visa',
  'Mastercard Debit': 'Mastercard',
  'Mastercard Credit': 'Mastercard',
}

export const CARD_LOOKUP_ERRORS = {
  CDAPI_001: 'CDAPI_001',
  CDAPI_002: 'CDAPI_002',
  CDAPI_004: 'CDAPI_004',
  CDAPI_005: 'CDAPI_005',
}

export const APEXX_REGIONS = ['CA', 'AU', 'NZ']
