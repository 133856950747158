import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  ({ cardId, payload }, config) => {
    return {
      ...config,
      method: 'PUT',
      url: `/cards/${cardId}`,
      data: payload,
    }
  },
  (response) => {
    return {
      ...response,
    }
  }
)
