import API from '@/api/corporate'
import APIHandler from '@/api/LaunchpadAPIHandler'

export default new APIHandler(
  API,
  (country) => {
    return {
      method: 'GET',
      url: `terms-conditions/${country}`,
    }
  },
  (response) => {
    return {
      ...response,
      data: {
        version: response.data.termsAndConditionsVersion,
        url: response.data.termsAndConditionsDocumentUrl,
        privacyUrl: response.data.privacyUrl,
        preAuthorizedDebitUrl: response.data.preAuthorizedDebitUrl,
      },
    }
  }
)
