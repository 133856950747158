import { getFormattedStreetName } from '@galileo/utilities/formatters.utility.js'

export default class UserPersonalInformation {
  constructor(userInfo) {
    this.firstName = userInfo?.customer?.firstName
    this.lastName = userInfo?.customer?.lastName

    this.mobilePrefix = userInfo?.mobilePhone?.countryCode
    this.mobilePhone = userInfo?.mobilePhone?.phoneNumberFormatted
    this.phoneNumberNoAreaCode = userInfo?.mobilePhone?.phoneNumberNoAreaCode

    this.addressLine1 = userInfo?.extraFields?.addressLine1
    this.addressLine2 = userInfo?.extraFields?.addressLine2
    this.addressLine3 = userInfo?.extraFields?.addressLine3
    this.unit = userInfo?.extraFields?.unit
    this.buildingName = userInfo?.extraFields?.buildingName
    this.postalCode = userInfo?.extraFields?.postalCode
    this.country = ''
    this.place = userInfo?.extraFields?.place
    this.area = userInfo?.extraFields?.area
    this.address = userInfo?.address
    this.streetName = userInfo?.extraFields?.streetName
    this.streetNumber = userInfo?.extraFields?.streetNumber
    this.county = userInfo?.extraFields?.county ?? userInfo?.extraFields?.state
    this.state = userInfo?.extraFields?.county ?? userInfo?.extraFields?.state
  }

  resetAddress() {
    this.addressLine1 = null
    this.addressLine2 = null
    this.addressLine3 = null
    this.postalCode = null
    this.place = null
    this.address = ''
    this.streetName = null
    this.streetNumber = null
    this.state = null
    this.county = null
    this.unit = null
    this.area = null
    this.buildingName = null
  }

  setAddress(address) {
    this.country = address?.country
    this.postalCode = address?.postalCode
    this.place = address?.place
    // this.address = getFormattedStreetName(address)
    // this.address = ''
    this.addressLine1 = address?.addressLine1
    this.addressLine2 = address?.addressLine2
    this.addressLine3 = address?.addressLine3
    this.unit = address?.unit
    this.buildingName = address?.buildingName
    this.area = address?.area
    this.county = address?.county
    this.streetName = address?.streetName
    this.streetType = address?.streetType
    this.streetNumber = address?.streetNumber
    this.state = address?.state
  }

  setAdditionalFields(additionalFields) {
    this.idType = additionalFields?.idType
    this.idExpiryDate = additionalFields?.idExpiryDate
    this.passportNumber = additionalFields?.passportNumber
    this.passportIssuer = additionalFields?.passportIssuer
    this.passportFileNumber = additionalFields?.passportFileNumber
    this.licenceCardNumber = additionalFields?.licenceCardNumber
    this.licenceVersion = additionalFields?.licenceVersion
    this.licenceNumber = additionalFields?.licenceNumber
    this.licenceIssuer = additionalFields?.licenceIssuer
    this.nationalIdNumber = additionalFields?.nationalIdNumber
  }

  removePhoneNumber() {
    delete this.mobilePrefix
    delete this.mobilePhone
  }

  removeFullName() {
    delete this.firstName
    delete this.lastName
  }

  //---------------------------------------- MAPPING for Settings

  mapUser(form) {
    this.phoneNumberNoAreaCode = form.phone.$value
    this.firstName = form.firstName.$value
    this.lastName = form.lastName.$value
    this.streetType = this.streetType ?? 'street'
    this.checkPrefixProperty(form)
    this.removeNotMappedProperties()
  }

  checkPrefixProperty(form) {
    let prefix = `+${form.mobilePrefix.$value}`

    if (typeof form.mobilePrefix.$value === String && form.mobilePrefix.$value.includes('+')) {
      prefix = form.mobilePrefix.$value
    }
    this.mobilePrefix = prefix
    this.mobilePhone = `${this.phoneNumberNoAreaCode}`
  }

  checkAddressProperties() {
    this.checkPropertyIsNotEmpty('addressLine1')
    this.checkPropertyIsNotEmpty('addressLine2')
    this.checkPropertyIsNotEmpty('addressLine3')
  }

  removeNotMappedProperties() {
    delete this.phoneNumberNoAreaCode
    delete this.address
    this.checkAddressProperties()
  }

  checkPropertyIsNotEmpty(propName) {
    if (!this[propName] || this[propName] === '') {
      delete this[propName]
    }
  }

  /**
   * @name Create a list of changed properties as a payload
   * @return [{ id: changedPropertyName, value: newPropertyValue }]
   */
  createPayload() {
    const listPropertyNames = Object.keys(this)
    let payload = []
    listPropertyNames.forEach((prop) => {
      let property = {
        id: prop,
        value: this[prop],
      }
      payload.push(property)
    })
    return payload
  }

  static createPayload(user) {
    const listPropertyNames = Object.keys(user)
    let payload = []
    listPropertyNames.forEach((prop) => {
      let property = {
        id: prop,
        value: user[prop],
      }
      payload.push(property)
    })
    return payload
  }
}
