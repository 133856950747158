<template>
  <AppInputWrapper :component-validation="fieldValidation.date" v-slot="{ input }">
    <div class="row">
      <div v-for="field in fields" :key="field.id" class="column">
        <AppInputText
          v-if="field.id !== 'month'"
          :ref="field.ref"
          v-model="field.model.value"
          :analytics-name="field.analyticsName.value"
          class="input-date"
          type="text"
          :placeholder="field.placeholder.value"
          :maxlength="field.maxlength"
          v-bind="input.attrs"
          @focus="onFieldFocus(field)"
          @blur="onFieldBlur(field)"
          @keydown.delete="onFieldDelete($event, field)"
        />

        <AppInputDropdown
          v-else
          :ref="field.ref"
          v-model="field.model.value"
          :options="months"
          selected-display-field="name"
          selected-value-field="value"
          :analytics-name="field.analyticsName.value"
          :maxlength="field.maxlength"
          class="input-date"
          type="text"
          placeholder="Select month"
          v-bind="input.attrs"
          @focus="onFieldFocus(field)"
          @input="onFieldBlur(field)"
        ></AppInputDropdown>
      </div>
    </div>
  </AppInputWrapper>
</template>

<script>
import { AppInputDate, AppInputDropdown } from '@oen.web.vue2/ui'

import { useI18nStore } from '@galileo/stores'

export default {
  name: 'XeInputDate',
  components: { AppInputDropdown },
  emits: ['input', 'blur', 'inputBlur', 'focus', 'inputFocus'],
  extends: AppInputDate,
  setup(props, { emit }) {
    const { $t } = useI18nStore()

    const months = [
      { name: $t('ComponentXeInputDate.January').value, value: '01' },
      { name: $t('ComponentXeInputDate.February').value, value: '02' },
      { name: $t('ComponentXeInputDate.March').value, value: '03' },
      { name: $t('ComponentXeInputDate.April').value, value: '04' },
      { name: $t('ComponentXeInputDate.May').value, value: '05' },
      { name: $t('ComponentXeInputDate.June').value, value: '06' },
      { name: $t('ComponentXeInputDate.July').value, value: '07' },
      { name: $t('ComponentXeInputDate.August').value, value: '08' },
      { name: $t('ComponentXeInputDate.September').value, value: '09' },
      { name: $t('ComponentXeInputDate.October').value, value: '10' },
      { name: $t('ComponentXeInputDate.November').value, value: '11' },
      { name: $t('ComponentXeInputDate.December').value, value: '12' },
    ]

    return {
      ...AppInputDate.setup(props, { emit }),
      months,
      $t,
    }
  },
}
</script>

<style scoped>
.row {
  @apply flex mt-1;
}
.column {
  @apply mr-2 w-1/4;
  &:nth-child(2) {
    @apply w-1/2;
  }
  &:last-child {
    @apply mr-0;
  }
}
.input-date {
  @apply mb-0;
}
::v-deep .input-date .input-label {
  @apply font-normal;
}
</style>
