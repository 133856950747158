<template>
  <div>
    <AppSpinnerBig :loading="!isReady" />
    <AppApp v-if="isReady">
      <AppButton
        v-if="callUsCtaEnabled"
        theme="secondary"
        class="call-us-cta"
        @click="callUsHandler"
      >
        {{ $t('CalUsText').value }} {{ callUsNumber }}
      </AppButton>
      <LayoutFill>
        <RouterView />
        <div v-if="isAuthenticated">
          <XeSnackAlert />
          <XeMessageBox analytics-name="xe-message-box" />
          <KYCRefreshModal />
          <KYCRefreshCorporateModal v-if="renderKycRefreshCorpModal" />
          <TermsAndConditionsModal />
          <EmailConsentModal />
          <AccountRestrictedModal />
          <SendMoneyUnableToSetProviderModal />
          <SwitchToLegacySiteModal />
          <FeedbackSurvey />
          <DocumentsRequiredModal />
          <ConfigErrorModal />
        </div>
      </LayoutFill>
    </AppApp>
  </div>
</template>

<script>
import { computed, onBeforeMount, reactive } from '@vue/composition-api'
import { useI18n } from 'vue-composable'
import { providePrismic } from '@galileo/composables/usePrismic'
import { provideRouter, useRouter } from '@galileo/composables/useRouter'
import { useNetworkStatusAlert } from '@galileo/composables/useNetwork'
import config from '@galileo/constants/i18nConfig'
import { AppSpinnerBig, AppApp, AppButton } from '@oen.web.vue2/ui'
import XeMessageBox from '@galileo/components/XeMessageBox/XeMessageBox'
import XeSnackAlert from '@galileo/components/XeSnackAlert/XeSnackAlert'
import LayoutFill from '@galileo/components/LayoutFill/LayoutFill'
import XeConsentManagerModal from '@galileo/components/XeConsentManagerModal/XeConsentManagerModal'
import KYCRefreshModal from '@galileo/components/KYCRefresh/KYCRefreshModal'
import KYCRefreshCorporateModal from '@galileo/components/KYCRefreshCorporate/KYCRefreshCorporateModal'
import TermsAndConditionsModal from '@galileo/components/TermsConditions/TermsAndConditionsModal'
import AccountRestrictedModal from '@galileo/components/Views/AccountRestrictedModal'
import SendMoneyUnableToSetProviderModal from '@galileo/components/Views/SendMoney/SendMoneyUnableToSetProviderModal'
import SwitchToLegacySiteModal from '@galileo/components/SwitchToLegacySiteModal/SwitchToLegacySiteModal'
import FeedbackSurvey from '@galileo/components/Views/SendMoney/FeedBackSurvey/FeedBackSurvey'
import DocumentsRequiredModal from './components/Views/Activity/DocumentsRequiredModal.vue'

import { consentManager } from '@galileo/composables/useConsentManager'

import { getActionRouteIfExists } from '@galileo/constants/actions.const'

import EmailConsentModal from '@galileo/components/Views/Account/Profile/EmailConsentModal'

import ConfigErrorModal from '@galileo/components/Views/Account/Config/ConfigErrorModal'

import {
  useAppStore,
  useConsentStore,
  useAnalyticsStore,
  useThemeStore,
  useAuthStore,
  useTransactionStore,
  useCorporateStore,
} from '@galileo/stores'

export default {
  components: {
    AppApp,
    AppSpinnerBig,
    XeSnackAlert,
    XeMessageBox,
    LayoutFill,
    XeConsentManagerModal,
    TermsAndConditionsModal,
    EmailConsentModal,
    KYCRefreshModal,
    KYCRefreshCorporateModal,
    AccountRestrictedModal,
    SendMoneyUnableToSetProviderModal,
    SwitchToLegacySiteModal,
    FeedbackSurvey,
    DocumentsRequiredModal,
    AppButton,
    ConfigErrorModal,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'App',
    // all titles will be injected into this template
    titleTemplate: '',
  },
  setup(props, { root: { $router, $prismic }, emit }) {
    provideRouter($router)
    providePrismic($prismic)
    const analyticsStore = useAnalyticsStore()
    const i18n = reactive(useI18n(config))
    const router = useRouter()
    const appStore = useAppStore()

    let consentState = consentManager()

    const transactionStore = useTransactionStore()
    const corporateStore = useCorporateStore()
    const authStore = useAuthStore()

    const { shouldDisplayCallUsCTA, getCallUsNumber } = authStore
    const isAuthenticated = computed(() => useAuthStore().isAuthenticated)

    onBeforeMount(async () => {
      //  Consent Manager
      consentState = consentManager()

      useThemeStore().resetDefaultBranding()
      // Step 1: Consent OK
      const consentStore = useConsentStore()
      consentStore.setConsent(consentState)

      await appStore.init(i18n, emit, $prismic)

      const newRoute = getActionRouteIfExists()

      if (newRoute) {
        await transactionStore.corporateFillGTMData(false)
      }

      //redirect only if newRoute exists
      newRoute && router.push(newRoute)
    })

    const showTrackConsent = computed(() => {
      return analyticsStore.trackingConsent === null
    })

    const renderKycRefreshCorpModal = computed(() => {
      return authStore.isCorporateAccount && corporateStore.corporateClientDataFetched
    })

    useNetworkStatusAlert()

    // Send GTM event - 'Consent State Change'
    analyticsStore.gtmTrack({
      event: 'consentStateChange',
      variables: {
        isConsentedToPerformance: consentState.performance,
        isConsentedToMarketing: consentState.marketing,
        isConsentedToCompliance: consentState.compliance,
      },
    })

    //
    const callUsHandler = () => {
      const handled = window.open(`tel:${getCallUsNumber()}`, '_blank')
      if (!handled) {
        console.error('call us not handled')
      }
    }

    return {
      isReady: computed(() => appStore.getIsReady),
      $t: i18n.$t,
      showTrackConsent,
      isAuthenticated,
      callUsCtaEnabled: computed(() => shouldDisplayCallUsCTA()),
      callUsNumber: computed(() => getCallUsNumber()),
      callUsHandler,
      renderKycRefreshCorpModal,
    }
  },
}
</script>
