import { ref } from '@vue/composition-api'

export const stateSanitizerRegex = new RegExp(/[^a-zA-Z0-9\u0020\+\-\u0027/\.,]/g)
export const countrySanitizerRegex = new RegExp(/[^A-Z]/g)
export const streetSanitizerRegex = new RegExp(/[^a-zA-Z0-9\u0020\+\-\u0027/\.,]/g)
export const citySanitizerRegex = new RegExp(/[^a-zA-Z0-9\u0020\+\-\u0027/\.,]/g)
export const postalCodeSanitizerRegex = new RegExp(/[^0-9A-Z\u0020-]/g)

export default function () {
  const postalCodeValidator = (countryCode) => {
    const country = ref(countryCode)
    const $validator = (inputValue) => {
      if (country.value === 'US') {
        const postalCodeUSRegex = new RegExp('^[0-9]{5}(?:-[0-9]{4})?$')
        return postalCodeUSRegex.test(inputValue)
      } else if (country.value === 'GB') {
        const postalCodeGBRegex = new RegExp(
          `^([A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}|GIR ?0A{2})$`
        )
        return postalCodeGBRegex.test(inputValue)
      } else if (country.value === 'CA') {
        const postalCodeCARegex = new RegExp(
          `^(?!.*[DFIOQUdfioqu])[A-VXYa-vxy][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]$`
        )
        return postalCodeCARegex.test(inputValue)
      } else if (country.value === 'AU') {
        const postalCodeAURegex = new RegExp(`^[0-9]{4}$`)
        return postalCodeAURegex.test(inputValue)
      } else if (country.value === 'NZ') {
        const postalCodeNZRegex = new RegExp(`^[0-9]{4}$`)
        return postalCodeNZRegex.test(inputValue)
      } else {
        const postalCodeGenericRegex = new RegExp(/^[a-zA-Z\d][a-zA-Z\d -]{0,10}[a-zA-Z\d]$/gm)
        return postalCodeGenericRegex.test(inputValue)
      }
    }

    return $validator
  }

  return { postalCodeValidator }
}
