import { defineStore, storeToRefs } from 'pinia'

import getSystemFieldsAPI from '@galileo/api/launchpad/system/fields/_country/get'
import getAddress from '@galileo/api/launchpad/address/get'
import getAddressId from '@galileo/api/launchpad/address/_addressId/get'
import getBeneficiaryRelationships from '@galileo/api/launchpad/recipients/beneficiaryRelationships/get'
import getTopDestinationsApi from '@galileo/api/launchpad/resources/countries/topDestinations/get'

import { useFavorites } from '@galileo/composables/useFavorites'
import { ref, computed } from '@vue/composition-api'
import AddressSearchResult from '@galileo/models/Corporate/AddressSearchResult'
import AddressModel from '@galileo/models/Address/BillingAddresses/AddressModel'

import {
  useCorporateStore,
  useCountriesStore,
  useAuthStore,
  useDeviceStore,
  useI18nStore,
} from '@galileo/stores'

import {
  SystemFields,
  GenericField,
  Currency,
  PhoneCountry,
} from '@galileo/models/Resources/interfaces/SystemFields'

export const useResourcesStore = defineStore('resources', () => {
  const systemFields = ref<SystemFields>({} as SystemFields)
  const systemFieldsPromise = ref<{ promise: Promise<any> | null; country: string }>({
    promise: null,
    country: '',
  })

  const getCountries = computed(() => {
    if (systemFields.value.countries) {
      return systemFields.value.countries
    }
    return []
  })

  const getCurrenciesFrom = computed((): Array<Currency> => {
    if (systemFields.value.currenciesFrom) {
      return systemFields.value.currenciesFrom.filter((currency) => currency.value !== 'HRK')
    }
    return []
  })

  const getCurrenciesTo = computed((): Array<Currency> => {
    if (systemFields.value.currenciesTo) {
      return systemFields.value.currenciesTo.filter((currency) => currency.value !== 'HRK')
    }
    return []
  })

  const getCombinedCurrenciesFrom = computed((): Array<Currency> => {
    if (systemFields.value.currenciesFrom) {
      const items = getCurrenciesFrom.value
      const favorites = getTopCurrencies.value
      const currencies = useFavorites(items, favorites)
      return currencies
    }
    return []
  })

  const getCombinedCurrenciesTo = computed((): Array<Currency> => {
    if (systemFields.value.currenciesTo) {
      const items = getCurrenciesTo.value
      const favorites = getTopCurrencies.value
      const currencies = useFavorites(items, favorites)
      return currencies
    }
    return []
  })

  const getUnsortedTopCurrencies = computed((): Array<GenericField> => {
    if (systemFields.value.topCurrencies) {
      return systemFields.value.topCurrencies
    }
    return []
  })

  const getTopCurrencies = computed((): Array<GenericField> => {
    if (systemFields.value.topCurrencies) {
      const authStore = useAuthStore()
      let userCountry = ''
      if (authStore.user) {
        userCountry = authStore.user.country
      }
      const sortingArrays: { [key: string]: Array<string> } = {
        US: ['USD', 'GBP', 'CAD', 'EUR', 'NZD', 'AUD'],
        GB: ['GBP', 'EUR', 'USD', 'CAD', 'NZD', 'AUD'],
        CA: ['CAD', 'USD', 'EUR', 'GBP', 'NZD', 'AUD'],
        AU: ['AUD', 'USD', 'CAD', 'EUR', 'GBP', 'NZD'],
        NZ: ['NZD', 'USD', 'CAD', 'EUR', 'GBP', 'AUD'],
        default: ['EUR', 'USD', 'CAD', 'GBP', 'NZD', 'AUD'],
      }
      const sortingArray = sortingArrays[userCountry] || sortingArrays['default']

      return systemFields.value.topCurrencies
        .slice()
        .sort((a, b) => sortingArray.indexOf(a.value) - sortingArray.indexOf(b.value))
    }
    return []
  })

  const getTopCountries = computed((): Array<GenericField> => {
    if (systemFields.value.topCountries) {
      return systemFields.value.topCountries
    }
    return []
  })

  const getPhoneCountries = computed((): Array<PhoneCountry> => {
    if (systemFields.value.phoneCountries) {
      return systemFields.value.phoneCountries
    }
    return []
  })

  const getSupportedCountries = computed((): Array<GenericField> => {
    if (systemFields.value && systemFields.value.supportedCountries) {
      return systemFields.value.supportedCountries
    }
    return []
  })

  const getOccupations = computed((): Array<GenericField> => {
    if (systemFields.value.occupations) {
      return systemFields.value.occupations
    }
    return []
  })

  const getSourceOfFunds = computed((): Array<GenericField> => {
    if (systemFields.value.sourceOfFunds) {
      return systemFields.value.sourceOfFunds
    }
    return []
  })

  const getImportantDocuments = computed<string>(() => {
    if (systemFields.value.documents) {
      const version = systemFields.value.documents.importantInformation
      return systemFields.value.documents.documentLinks[version]
    }
    return ''
  })

  const getTermsAndConditionsVersion = computed<string>(() => {
    if (systemFields.value.documents) {
      return systemFields.value.documents.termsAndConditionsVersionAvailable
    }
    return ''
  })

  const getTermsAndConditionsUrl = computed<string>(() => {
    const corporateStore = useCorporateStore()
    const { isCorporateAccount } = storeToRefs(useAuthStore())

    if (isCorporateAccount.value) {
      const termsAndConditionsCorp = corporateStore.termsAndConditionsGetter
      if (termsAndConditionsCorp?.url) {
        return termsAndConditionsCorp.url
      }
    }

    if (systemFields.value?.documents?.documentLinks) {
      const version = systemFields.value.documents.termsAndConditionsVersionAvailable
      if (systemFields.value.documents.documentLinks[version]) {
        return systemFields.value.documents.documentLinks[version]
      }
    }
    return ''
  })

  const getPrivacyPolicyUrl = computed<string>(() => {
    const corporateStore = useCorporateStore()
    const { isCorporateAccount } = storeToRefs(useAuthStore())

    if (isCorporateAccount.value) {
      const termsAndConditionsCorp = corporateStore.termsAndConditionsGetter

      if (termsAndConditionsCorp?.privacyUrl) {
        return termsAndConditionsCorp.privacyUrl
      }
    }

    if (systemFields.value?.documents?.documentLinks) {
      const version = systemFields.value?.documents.privacyPolicyVersionAvailable
      if (systemFields.value.documents.documentLinks[version]) {
        return systemFields.value.documents.documentLinks[version]
      }
    }
    return ''
  })

  const getProductDisclosureStatementUrl = computed<string>(() => {
    if (
      systemFields.value.documents?.documentLinks &&
      systemFields.value.documents?.disclosureDocuments
    ) {
      const version = systemFields.value.documents.disclosureDocuments[0]
      if (systemFields.value.documents.documentLinks[version]) {
        return systemFields.value.documents.documentLinks[version]
      }
    }
    return ''
  })

  const getFinancialServicesGuideUrl = computed<string>(() => {
    if (
      systemFields.value.documents?.documentLinks &&
      systemFields.value.documents?.disclosureDocuments
    ) {
      const version = systemFields.value.documents.disclosureDocuments[1]
      if (systemFields.value.documents.documentLinks[version]) {
        return systemFields.value.documents.documentLinks[version]
      }
    }
    return ''
  })
  const getPreAuthorizedDebitAgreement = computed<string>(() => {
    const corporateStore = useCorporateStore()
    const { isCorporateAccount } = storeToRefs(useAuthStore())

    if (isCorporateAccount.value) {
      const termsAndConditionsCorp = corporateStore.termsAndConditionsGetter

      if (termsAndConditionsCorp?.preAuthorizedDebitUrl) {
        return termsAndConditionsCorp.preAuthorizedDebitUrl
      }
    }

    if (systemFields.value?.documents?.documentLinks) {
      const version = systemFields.value.documents.preAuthDebit
      if (systemFields.value.documents.documentLinks[version]) {
        return systemFields.value.documents.documentLinks[version]
      }
    }
    return ''
  })

  function setSystemFields(fields: SystemFields): void {
    systemFields.value = fields
  }

  async function getSystemFields(forceRefresh = false): Promise<SystemFields> {
    // if we already got the system fields for the country then return the promise/result
    let country: string | undefined = useDeviceStore().country

    if (!country) {
      country = useAuthStore().userProfile?.country
    }

    if (
      systemFieldsPromise?.value?.promise &&
      systemFieldsPromise.value.country === country &&
      !forceRefresh
    ) {
      const { data } = await systemFieldsPromise.value.promise
      setSystemFields(data)
      return data
    }
    const promise: any = getSystemFieldsAPI.exec({
      country: country,
      isCorporateAccount: useAuthStore().isCorporateAccount,
      language: useI18nStore().localeLanguagePart,
    })
    promise.country = country
    systemFieldsPromise.value.promise = promise

    const { data } = await promise
    data.country = country
    setSystemFields(data)
    const countriesStore = useCountriesStore()
    countriesStore.setOfferedCountries(data.countries)
    return data
  }

  async function getAddressSearchResult({
    searchTerm,
    country,
    searchContext,
  }: {
    searchTerm: string
    country: string
    searchContext: number
  }): Promise<Array<AddressSearchResult>> {
    const encodedSearchTerm = encodeURIComponent(searchTerm)
    const { data } = await getAddress.exec({
      searchTerm: encodedSearchTerm,
      country,
      searchContext,
    })
    return data
  }

  async function getAddressDetails(addressId: number): Promise<AddressModel> {
    const { data } = await getAddressId.exec(addressId)
    return data
  }

  async function getRelationsToBeneficiary(): Promise<GenericField> {
    const { data } = await getBeneficiaryRelationships.exec()
    return data
  }

  async function getTopDestinations(payload: any): Promise<any> {
    const { data } = await getTopDestinationsApi.exec(payload)

    return data
  }

  return {
    systemFields,
    systemFieldsPromise,
    getCountries,
    getCurrenciesFrom,
    getCurrenciesTo,
    getCombinedCurrenciesFrom,
    getCombinedCurrenciesTo,
    getUnsortedTopCurrencies,
    getTopCurrencies,
    getTopCountries,
    getPhoneCountries,
    getSupportedCountries,
    getOccupations,
    getSourceOfFunds,
    getImportantDocuments,
    getTermsAndConditionsVersion,
    getTermsAndConditionsUrl,
    getPrivacyPolicyUrl,
    getProductDisclosureStatementUrl,
    getFinancialServicesGuideUrl,
    getPreAuthorizedDebitAgreement,
    setSystemFields,
    getSystemFields,
    getAddressSearchResult,
    getAddressDetails,
    getRelationsToBeneficiary,
    getTopDestinations,
  }
})
