import useFieldParser from './useFieldParser'
import { useI18nStore } from '@galileo/stores'

function extendRegexCharset(field) {
  const regexValidation = field.validations.find((validation) => validation.type === 'Regex')
  if (regexValidation) {
    regexValidation.value = regexValidation.value.replaceAll(`a-zA-Z`, `a-zA-Z\\p{Script=Latin}`)
  }
}

export default (recipientForm, isBusinessAccount = false) => {
  let defaultValues = recipientForm.defaultValues
  let { getFormField } = useFieldParser(recipientForm, isBusinessAccount)

  //helper method

  function overrideRequiredErrorMessage(field, message) {
    const requiredValidation = field.validations.find((el) => {
      return el.type.toLowerCase() === 'required'
    })

    if (requiredValidation) {
      requiredValidation.message = message
    }
  }

  function overrideRegexErrorMessage(field, message) {
    const regexValidation = field.validations.find((validation) => validation.type === 'Regex')

    if (regexValidation) {
      regexValidation.message = message
    }
  }

  const i18nStore = useI18nStore()
  const fieldRules = {
    ACCOUNT_NUM(field) {
      if (defaultValues['COUNTRY_CODE'] === 'MX') {
        overrideRegexErrorMessage(
          field,
          i18nStore.$t('UseFieldRules.AccountNumberMexicoErrorMessage').value
        )
        return {
          label: i18nStore.$t('UseFieldRules.AccountNumberMexicoLabel').value,
          hint: i18nStore.$t('UseFieldRules.AccountNumberMexicoHint').value,
          placeholder: i18nStore.$t('UseFieldRules.AccountNumberMexicoPlaceholder').value,
        }
      }

      return {
        label: i18nStore.$t('UseFieldRules.AccountNumberLabel').value,
      }
    },
    ADDRESS_LINE1(field) {
      field.validations.push({ type: 'pobox' })

      let label = field.label
      if (!field.mandatory) {
        label = `${field.label} ${i18nStore.$t('UseFieldRules.Optional').value}`
        field.optional = true
      }

      return {
        label: label,
        validations: field.validations,
        disableErrorValueValidation: !field.mandatory,
      }
    },
    ADDRESS_LINE2(field) {
      field.validations.push({ type: 'pobox' })

      let label = field.label
      if (!field.mandatory) {
        label = `${field.label} ${i18nStore.$t('UseFieldRules.Optional').value}`
        field.optional = true
      }

      return {
        label: label,
        validations: field.validations,
        disableErrorValueValidation: !field.mandatory,
      }
    },
    RECIPIENT_CITY(field) {
      field.validations.push({ type: 'pobox' })

      if (!field.mandatory) {
        field.label = `${field.label} ${i18nStore.$t('UseFieldRules.Optional').value}`
        field.optional = true
      }

      return {
        label: field.label,
        validations: field.validations,
        disableErrorValueValidation: !field.mandatory,
      }
    },
    RECIPIENT_ID_NUMBER_COUNTRY: {
      value: defaultValues['ADDRESS_COUNTRY_CODE'],
      //mandatory: true,
    },
    RECIPIENT_STATE(field) {
      if (!field.mandatory) {
        field.label = `${field.label} ${i18nStore.$t('UseFieldRules.Optional').value}`
        field.optional = true
      }

      if (field.type === 'optionList') {
        field.validations.push({ type: 'validState' })
      }

      return {
        label: field.label,
        disableErrorValueValidation: !field.mandatory,
      }
    },
    RECIPIENT_POST_CODE(field) {
      if (!field.mandatory) {
        field.label = `${field.label} ${i18nStore.$t('UseFieldRules.Optional').value}`
        field.optional = true
      }

      return {
        label: field.label,
        disableErrorValueValidation: !field.mandatory,
      }
    },
    ACCOUNT_NAME(field) {
      if (isBusinessAccount) {
        const regexValidation = field.validations.find((validation) => validation.type === 'Regex')
        regexValidation.value = regexValidation.value.replace(']', '&]')
        extendRegexCharset(field)
        return {
          group: 'GRP_RECIPIENT_NAME_DETAILS',
          label: i18nStore.$t('UseFieldRules.BusinessNameLabel').value,
          mandatory: true,
          hidden: false,
        }
      }
      return {
        group: 'GRP_RECIPIENT_NAME_DETAILS',
        mandatory: true,
        hidden: true,
      }
    },
    FIRST_NAME(field) {
      extendRegexCharset(field)
      return {
        group: 'GRP_RECIPIENT_NAME_DETAILS',
        label: i18nStore.$t('UseFieldRules.FirstNameLabel').value,
      }
    },
    MIDDLE_NAME(field) {
      extendRegexCharset(field)

      return {
        group: 'GRP_RECIPIENT_NAME_DETAILS',
        label: i18nStore.$t('UseFieldRules.MiddleNameLabel').value,
        mandatory: true,
        hidden: false,
        disableErrorValueValidation: true,
      }
    },
    LAST_NAME(field) {
      extendRegexCharset(field)

      return {
        group: 'GRP_RECIPIENT_NAME_DETAILS',
        label: i18nStore.$t('UseFieldRules.LastNameLabel').value,
        hint: i18nStore.$t('UseFieldRules.LastNameHint').value,
      }
    },
    NICKNAME(field) {
      extendRegexCharset(field)

      return {
        group: 'GRP_RECIPIENT_NAME_DETAILS',
        label: i18nStore.$t('UseFieldRules.NicknameLabel').value, //'Nickname (optional)',
        mandatory: true,
        disableErrorValueValidation: true,
      }
    },
    HAS_RIA_ALERT_ACCEPTED: {
      mandatory: true,
    },
    BANK_NAME(field) {
      if (field.mandatory) {
        field.validations.push({ type: 'required' })
      }
      field.readonly = true
      return field
    },
    BANK_ADDRESS_LINE1: {
      readonly: true,
    },
    BANK_ADDRESS_LINE2: {
      readonly: true,
      hidden: false,
    },
    BANK_ADDRESS_LINE3: {
      readonly: true,
    },
    BANK_BIC(field) {
      // if BANK_BIC field is returned, but not marked as mandatory
      // we will treat it as mandatory but keep it hidden
      return {
        label: 'BIC / SWIFT',
        hint: i18nStore.$t('UseFieldRules.BicSwiftHint').value,
        ...(!field.mandatory && {
          mandatory: true,
          hidden: true,
        }),
      }
    },
    INDUSTRY_TYPE: {
      ...(isBusinessAccount
        ? {
            group: 'GRP_ADDITIONAL_DETAILS',
            mandatory: true,
            readonly: true,
            hidden: true,
          }
        : { forceStop: true }),
    },
    ADDRESS_COUNTRY_CODE: {
      mandatory: true,
      hidden: true,
    },
    EMAIL(field) {
      return {
        ...(defaultValues['CURRENCY_CODE'] === 'ZAR' && {
          hint: i18nStore.$t('UseFieldRules.ZAREmailHint').value,
        }), //conditionally creating the property "hint" only if currency code is ZAR
        ...(field.mandatory && {
          group: 'GRP_BANK_DETAILS',
        }), //if it's mandatory we should display it in BANK DETAILS
      }
    },
    IBAN(field) {
      if (field.mandatory) {
        let length = 27
        const lengthValidationRegex = field.validations.find(
          (validation) => validation.type === 'Regex'
        )
        const lengthRegexExtracted = lengthValidationRegex.value.match(/\{(?<ibanLength>\d+)}/)

        if (lengthRegexExtracted) {
          length = lengthRegexExtracted.groups.ibanLength
        }

        return {
          hint: i18nStore.$t('UseFieldRules.IbanHint', { length: length }).value,
          value: field.value?.replace(/\s/g, ''),
        }
      }
      return {}
    },
    CA_TRANSIT_INSTITUTION(field) {
      if (defaultValues['COUNTRY_CODE'] === 'CA') {
        field.validations.push({ type: 'required' })

        return {
          label: i18nStore.$t('UseFieldRules.TransitInstitutionLabel').value,
          mandatory: true,
          hidden: false,
          group: 'GRP_BANK_DETAILS',
          parent: null,
          NCC: field.parent,
          useEnrichment: true,
          validations: field.validations,
        }
      }
    },
    CA_TRANSIT_BRANCH(field) {
      if (defaultValues['COUNTRY_CODE'] === 'CA') {
        field.validations.push({ type: 'required' })

        return {
          label: i18nStore.$t('UseFieldRules.TransitBranchLabel').value,
          mandatory: true,
          hidden: false,
          group: 'GRP_BANK_DETAILS',
          parent: null,
          NCC: field.parent,
          useEnrichment: true,
          validations: field.validations,
        }
      }
    },
    RECIPIENT_PHONE(field) {
      field.type = 'phoneNumber'
    },
    NAT_CLEAR_CODE(field) {
      let label = i18nStore.$t('UseFieldRules.RoutingNumberLabel').value
      let hint = field.hint

      switch (defaultValues['COUNTRY_CODE']) {
        case 'IN':
          label = 'IFSC'
          hint = i18nStore.$t('UseFieldRules.IFSCHint').value
          break
        case 'UK':
          label = i18nStore.$t('UseFieldRules.SortingCodeLabel').value
          hint = i18nStore.$t('UseFieldRules.SortingCodeHint').value
          break
        case 'AU':
          label = i18nStore.$t('UseFieldRules.BSBNumberLabel').value
          break
        default:
          label = field.displayName.text ?? label
          break
      }

      if (field.children && field.children.length > 0) {
        let clonedField = Object.assign({}, field)

        // child fields of NCC are mandatory !
        for (const element of clonedField.children) {
          const child = element
          child.mandatory = true
          child.group = field.group
          child.parent = clonedField

          let formField = getFormField(child)
          if (formField) {
            recipientForm.fields.push(formField)
          }
        }

        return {
          ...clonedField,
          label: label,
          forceStop: true,
          hint: hint,
        }
      }
      return {
        label: label,
        hint: hint,
      }
    },
    RECIPIENT_TAX_NUMBER(field) {
      overrideRequiredErrorMessage(
        field,
        i18nStore.$t('UseFieldRules.BrazilianTaxRegistrationNumber.TaxNumberRequired').value
      )

      if (defaultValues['COUNTRY_CODE'] === 'BR') {
        let baseProperties = {
          label: i18nStore.$t('UseFieldRules.BrazilianTaxRegistrationNumber.labelIndividual').value,
          placeholder: '390.533.447-05',
          tooltipText: i18nStore.$t(
            'UseFieldRules.BrazilianTaxRegistrationNumber.tooltipIndividual'
          ).value,
          mask: '###.###.###-##',

          inputmode: 'numeric',
          type: 'inputMaskWithTooltip',
          masked: false,
          group: 'GRP_RECIPIENT_NAME_DETAILS',
        }

        const regexValidation = field.validations.find((el) => {
          return el.type.toLowerCase() === 'regex'
        })

        regexValidation.type = 'brazilianTaxNumber'

        // case not business account default overrides
        let overrideProperties = {}

        //case if it's business account
        if (isBusinessAccount) {
          regexValidation.isBusinessAccount = true

          //[TODO] Remove when the backend gives us the correct regex
          regexValidation.value = '^(\\d{14})$'

          overrideProperties = {
            label: i18nStore.$t('UseFieldRules.BrazilianTaxRegistrationNumber.labelCorporate')
              .value,
            placeholder: '16.727.230/0001-97',
            tooltipText: i18nStore.$t(
              'UseFieldRules.BrazilianTaxRegistrationNumber.tooltipCorporate'
            ).value,
            mask: '##.###.###/####-##',
          }
        }

        //merge changed properties if any
        baseProperties = Object.assign(baseProperties, overrideProperties)

        return baseProperties
      }

      //if it's not brasilian we just change the group
      return {
        group: 'GRP_RECIPIENT_NAME_DETAILS',
      }
    },
    IS_BUSINESS(field) {
      return {
        hidden: true,
        mandatory: true,
      }
    },
  }

  return {
    fieldRules,
  }
}
