<template>
  <div>
    <AppModal v-model="model" :title="$t('EditEmailModal.Title').value">
      <template #header="{ dismiss }">
        <AppCardHeader>
          <h1>{{ $t('EditEmailModal.Title').value }}</h1>
          <template #right>
            <XeBackButton analytics-name="change-email-close" icon="x" @click="dismiss" />
          </template>
        </AppCardHeader>
      </template>

      <template #default>
        <form id="email-form" @submit.prevent>
          <AppInputText
            v-model="validation.userEmail.$value"
            type="text"
            :label="$t('EditEmailModal.EmailLabel').value"
            :validation="validation.userEmail"
          />
        </form>
      </template>

      <template #footer>
        <AppModalFooter>
          <AppButton
            type="submit"
            form="email-form"
            analytics-name="change-email-update-button"
            :loading="isSaving"
            :disabled="validation.$anyInvalid"
            @click="updateEmail"
          >
            {{ $t('EditEmailModal.UpdateButton').value }}
          </AppButton>
        </AppModalFooter>
      </template>
    </AppModal>

    <AppModal v-model="showVerificationStep" title="Verification">
      <template #header>
        <AppCardHeader>
          <template #left>
            <XeBackButton analytics-name="change-email-close" icon="<" @click="goBack" />
          </template>
          <h1>{{ $t('EditEmailModal.Verification').value }}</h1>
        </AppCardHeader>
      </template>

      <template #default>
        <h2 class="text-secondary-text">{{ $t('EditEmailModal.CodeSentToLabel').value }}</h2>
        <h2 class="text-normal text-lg">{{ validation.userEmail.$value }}</h2>

        <p class="p-verification-text">
          {{ $t('EditEmailModal.CheckSpamFolder').value }}
        </p>

        <AppInputMask
          v-model="verificationValidation.code.$value"
          :label="$t('EditEmailModal.VerificationCode').value"
          :placeholder="$t('EditEmailModal.EnterCode').value"
          maxlength="6"
          mask="######"
          :validation="verificationValidation.code"
          inputmode="numeric"
          @input="codeChanged"
        >
        </AppInputMask>
      </template>

      <template #footer>
        <AppModalFooter>
          <AppButton
            type="submit"
            form="email-form"
            analytics-name="change-email-continue-button"
            :loading="isSaving"
            :disabled="verificationValidation.$anyInvalid"
            @click="submitOTP"
          >
            {{ $t('EditEmailModal.ContinueButton').value }}
          </AppButton>
        </AppModalFooter>
        <h1 v-if="displayTimer" class="otp-resend-text">
          {{ $t('EditEmailModal.YouCanResetCodeIn').value }} <b>{{ timerValue }} sec</b>
        </h1>
        <div v-else class="flex justify-center">
          <a class="otp-resend-text">{{ $t('EditEmailModal.DidntResendCode').value }}</a>
          <a class="resend-code" @click="resendOTP">{{ $t('EditEmailModal.ResendCode').value }}</a>
        </div>
      </template>
    </AppModal>

    <InformationAppModal :value="showSigninAgainDialog" icon="warningYellow">
      <h1 class="title">{{ $t('EditEmailModal.SingOut').value }}</h1>

      <!-- <h1 class="title">Recipient details missing</h1> -->
      <p class="infoText">
        {{ $t('EditEmailModal.SignAgain').value }}
      </p>

      <!-- <p>To send money to this recipient we will need some additional information</p> -->

      <template #footer>
        <AppButton :loading="logoutLoading" @click="logout"
          >{{ $t('EditEmailModal.SingIn').value }}
        </AppButton>
      </template>
    </InformationAppModal>

    <InformationAppModal
      :value="showFailedToUpdateDialog"
      icon="warningYellow"
      class="email-failed-alert"
    >
      <h1 class="title">{{ $t('EditEmailModal.FailedToUpdate').value }}</h1>
      <p class="textInfo">{{ $t('EditEmailModal.UnableToChangeMail').value }}</p>

      <template #footer>
        <AppButton :loading="logoutLoading" @click="showFailedToUpdateDialog = false">
          {{ $t('PageActivity.GotIt').value }}
        </AppButton>
      </template>
    </InformationAppModal>

    <InformationAppModal :value="showLimitExceededDialog" icon="blindedAlley">
      <h1 class="title">{{ $t('EditEmailModal.LimitReached').value }}</h1>
      <p>
        {{ $t('EditEmailModal.LockedAccount').value }}
      </p>

      <template #footer>
        <AppButton @click="closeHandler">
          {{ $t('EditEmailModal.Okay').value }}
        </AppButton>
        <AppButton class="secondButton" theme="secondary" @click="showContactUs">
          {{ $t('PageActivity.DetailsButtonContactUs').value }}
        </AppButton>
      </template>
    </InformationAppModal>
  </div>
</template>

<script>
import { useValidation } from 'vue-composable'

import { ref } from '@vue/composition-api'
import { required, email, numeric } from '@vuelidate/validators'

import XeBackButton from '@galileo/components/XeBackButton/XeBackButton'
import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'

import { useAppStore, useI18nStore, useAuthStore } from '@galileo/stores'

import {
  useVModel,
  useAlert,
  AppModal,
  AppButton,
  AppCardHeader,
  AppModalFooter,
  AppInputText,
  AppInputMask,
} from '@oen.web.vue2/ui'

export default {
  name: 'EditEmailModal',
  components: {
    AppModal,
    AppModalFooter,
    AppButton,
    AppCardHeader,
    AppInputText,
    XeBackButton,
    InformationAppModal,
    AppInputMask,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    onSave: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { model } = useVModel(props, emit)
    const { $t } = useI18nStore()
    const { add } = useAlert()
    const appStore = useAppStore()
    const authStore = useAuthStore()
    const code = ref('')

    const showVerificationStep = ref(false)
    const isSaving = ref(false)
    const showSigninAgainDialog = ref(false)
    const showFailedToUpdateDialog = ref(false)
    const showLimitExceededDialog = ref(false)

    //#region Interval settings to display the countdown
    const displayTimer = ref(false)

    const timerValue = ref(59)
    const intervalRef = ref(null)

    const showContactUs = () => {
      appStore.openHelpDesk({ loadingRef: logoutLoading })
    }

    const resetTimer = () => {
      timerValue.value = 59
      displayTimer.value = true
      intervalRef.value = setInterval(timerCountdown, 1000)
    }
    //go back to input email
    const goBack = () => {
      showVerificationStep.value = false
      model.value = true
      timerValue.value = 0
      clearInterval(intervalRef.value)
    }
    const timerCountdown = () => {
      if (timerValue.value > 0) {
        timerValue.value--
      } else {
        clearInterval(intervalRef.value)
        displayTimer.value = false
      }
    }
    //#endregion

    const validation = useValidation({
      userEmail: {
        $value: ref(props.user?.customer?.email),
        required: {
          $validator: required.$validator,
          $message: $t('EditEmailModal.EmailRequiredError').value,
        },
        validEmail: {
          $validator: email.$validator,
          $message: $t('EditEmailModal.EnterValidEmailError').value,
        },
      },
    })

    //add validation for length?
    const verificationValidation = useValidation({
      code: {
        $value: ref(code),
        // required,
        numeric,
        minLength: {
          $validator(v) {
            if (v.length < 6 || v.length > 6) {
              return false
            }
            return true
          },
          $message: $t('EditEmailModal.SecCodeMustContain6').value,
        },
        isIncorrectCode: {
          $validator: () => {
            if (
              except.value &&
              except.value.data.statusCode === 400 &&
              except.value.data.code === 'CodeMismatchException'
            ) {
              return false
            }
            return true
          },
          $message: $t('EditEmailModal.TryAgainIncorrectCode').value,
        },
        isExpiredCode: {
          $validator: () => {
            if (
              except.value &&
              except.value.data.statusCode === 400 &&
              except.value.data.code === 'ExpiredCodeException'
            ) {
              return false
            }
            return true
          },
          $message: $t('EditEmailModal.CodeExpiredNewCodeSent').value,
        },
      },
    })

    const codeChanged = () => {
      if (except.value) {
        except.value = null
      }
      // verificationValidation.code.$value = verificationValidation.code.$value.replace(/\D/g,'')
      // if (verificationValidation.code.$value) {
      //   verificationValidation.code.$value = verificationValidation.code.$value.substr(0,6)
      // }
    }

    const updateEmail = async () => {
      isSaving.value = true
      try {
        await authStore.changeEmail(validation.userEmail.$value)
        showVerificationStep.value = true
        model.value = false
        resetTimer()
      } catch (ex) {
        showFailedToUpdateDialog.value = true
        model.value = false
      } finally {
        isSaving.value = false
      }
    }
    let except = ref(null)
    const submitOTP = async () => {
      isSaving.value = true
      try {
        await authStore.changeEmailConfirm(
          verificationValidation.code.$value,
          validation.userEmail.$value
        )
        showVerificationStep.value = false
        model.value = false
        showSigninAgainDialog.value = true
      } catch (ex) {
        except.value = ex.response
        if (except.value.data.statusCode !== 400) {
          model.value = false
          showFailedToUpdateDialog.value = true
        } else if (
          except.value.data.statusCode === 400 &&
          except.value.data.code === 'LimitExceededException'
        ) {
          showLimitExceededDialog.value = true
          showVerificationStep.value = false
        }
        verificationValidation.code.$touch()
      } finally {
        isSaving.value = false
      }
    }

    const closeHandler = () => {
      showLimitExceededDialog.value = false
    }

    const resendOTP = async () => {
      isSaving.value = true
      try {
        await authStore.changeEmailResend()
        showVerificationStep.value = true
        model.value = false
        showSigninAgainDialog.value = false
        resetTimer()
      } catch (ex) {
        appStore.messageBoxGenericError()
      } finally {
        isSaving.value = false
      }
    }

    const logoutLoading = ref(false)
    const logout = async () => {
      logoutLoading.value = true
      await authStore.logout(true)
    }

    return {
      model,
      $t,
      validation,
      isSaving,
      email,
      updateEmail,
      showVerificationStep,
      goBack,
      verificationValidation,
      submitOTP,
      resendOTP,
      displayTimer,
      timerValue,
      showSigninAgainDialog,
      showFailedToUpdateDialog,
      showLimitExceededDialog,
      logout,
      logoutLoading,
      codeChanged,
      showContactUs,
      closeHandler,
    }
  },
}
</script>

<style scoped>
.email-note {
  @apply mb-6 text-gray-text;
}

::v-deep .secondButton {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
::v-deep .card-content {
  @apply px-12;
  .card-content-block {
    @apply mb-6 text-sm leading-5;
  }
}

.card-header {
  @apply px-12 py-12;
}

::v-deep .card-header .card-header-title {
  @apply h-auto leading-6;
  h1 {
    @apply text-lg leading-6;
  }
}

::v-deep .card.card--has-footer {
  @screen sm {
    @apply pb-12;
  }
}

.card-footer {
  .button {
    @apply w-full;
  }
}

.button.button--primary {
  &:disabled {
    &:not(.button--loading) {
      @apply text-white;
    }

    background: linear-gradient(
        to right,
        theme('colors.blue.button-disabled'),
        theme('colors.blue.button-disabled')
      ),
      linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
  }
}

.otp-resend-text {
  @apply text-center text-secondary-text mt-2;
}

.p-verification-text {
  @apply my-4 text-secondary-text text-sm;
}

.resend-code {
  @apply otp-resend-text  cursor-pointer ml-1;
  @apply text-blue !important;
}

.textInfo {
  @apply pb-4;
}

.email-failed-alert {
  ::v-deep .card-header {
    @apply pt-10;
  }
}

::v-deep .button.button--secondary .button-inner {
  @apply p-0 py-0 px-0;
}
</style>
