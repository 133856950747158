/**
 * @description Geographical region the application is currently configured for, e.g. US, Spain, Australia.
 * @region Enumeration for this region.
 * @defaultCountryFrom Default value to use for send-from country, i.e. in price calculator
 * @defaultCurrencyFrom Default value to use for send-from currency, i.e. in price calculator
 * @defaultLanguage Default language culture to use, i.e. content translations
 */
export default {
  regionId: 505,
  defaultCountryFrom: 'UK', // Silliness in Ria's database, this may need to switch to "GB" in the future?
  defaultCountryFromOnfido: 'GBR',
  defaultCountryCodeFrom: 44,
  defaultCurrencyFrom: 'GBP',
  defaultLanguageCulture: 'en-GB',
  customerServicePhone: '08000855955',
  defaultLocation: {
    latitude: 55.378051,
    longitude: -3.435973,
  },
  dateFormat: 'DD/MM/YYYY',
  postalCodeRegex: `^([A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}|GIR ?0A{2})$`,
  phoneRegex: `^[0-9\\.\\-\\+\\(\\)\\[\\] ]{10}$`,
  support: {
    email: 'support@riamoneytransfer.co.uk',
    title: 'Support request',
  },
  links: {
    linkFaq: 'https://www.riamoneytransfer.com/us/en/mobile-content/faqs',
    linkPrivacy: 'https://app.riamoneytransfer.com/app-privacy-policy',
    linkStoredCardAgreement:
      'https://www.riamoneytransfer.com/us/en/mobile-content/stored-card-agreement',
    linkTerms: 'https://app.riamoneytransfer.com/app-terms-and-conditions',
  },

  availableLanguageCultures: ['en-GB'],
  regionalIp: '66.82.123.234',
  requireAddress: false,
  isPlaidEnabled: false,
  isBankAccountEnabled: false,
  useSecurityQuestions: false,
  oldRemittanceLogin: false,
  onfidoMaxAttempts: 1,
}
