export default () => {
  const getDefaultValues = (recipient) => {
    let defaultValues = {}
    if (recipient?.rawData?.fieldValues) {
      defaultValues = { ...recipient.rawData.fieldValues }
    }
    defaultValues['CURRENCY_CODE'] = recipient.currency
    defaultValues['COUNTRY_CODE'] = recipient.country
    defaultValues['ADDRESS_COUNTRY_CODE'] = recipient.addressCountry
    defaultValues['BANK_NAME'] = recipient.bankName
    defaultValues['ACCOUNT_NUM'] = recipient.bankAccountNumber
    defaultValues['BANK_BIC'] = recipient.bankBicSwift
    defaultValues['NAT_CLEAR_CODE'] = recipient.bankRoutingCode
    defaultValues['ACCOUNT_NAME'] = recipient.accountName
    defaultValues['IBAN'] = recipient.bankIban
    defaultValues['BANK_ADDRESS_LINE1'] = recipient.bankAddressLine1
    defaultValues['BANK_ADDRESS_LINE2'] = recipient.bankAddressLine2
    defaultValues['BANK_ADDRESS_LINE3'] = recipient.bankAddressLine3
    defaultValues['INDUSTRY_TYPE'] = recipient.industryTypeId
    defaultValues['EMAIL'] = recipient.emailAddress
    defaultValues['RECIPIENT_PHONE'] = recipient.telephoneNumber
    defaultValues['BANK_ACCOUNT_TYPE_CODE'] = recipient.bankAccountTypeId
    defaultValues['NICKNAME'] = recipient.nickName
    defaultValues['HAS_RIA_ALERT_ACCEPTED'] = recipient.riaAlertAccepted
    defaultValues['CA_TRANSIT_INSTITUTION'] = recipient.institutionNumber
    defaultValues['CA_TRANSIT_BRANCH'] = recipient.branchNumber
    defaultValues['BANK_BRANCH_NUMBER'] = recipient.bankBranchNumber
    defaultValues['RECIPIENT_ID_NUMBER'] = recipient.recipientIdNumber
    defaultValues['RECIPIENT_TAX_NUMBER'] = recipient.taxNumber || recipient.recipientTaxNumber
    defaultValues['ADDRESS_LINE1'] = recipient.address
    defaultValues['ADDRESS_LINE2'] = recipient.addressLine2
    defaultValues['RECIPIENT_CITY'] = recipient.recipientCity
    defaultValues['RECIPIENT_STATE'] = recipient.recipientState
    defaultValues['RECIPIENT_POST_CODE'] = recipient.recipientPostCode || recipient.postalCode
    return defaultValues
  }

  return {
    getDefaultValues,
  }
}
