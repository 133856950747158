import { removeDiacritics } from '@galileo/utilities/diacritics-convertor.utility'
import { computed } from '@vue/composition-api'

import { useRecipientsStore, useI18nStore } from '@galileo/stores'

export default function (businessField, nicknameField) {
  const { $t } = useI18nStore()

  const extractRegexGroup = (regexString) => {
    const regex = /{(\d+,\s*\d+)}/
    const matches = regexString.toString().match(regex)
    if (matches && matches.length > 1) {
      return `{${matches[1]}}`
    }
    return null
  }

  const maxNumOfCharacters = (regex) => {
    const regexGroup = extractRegexGroup(regex)
    const numbers = regexGroup?.match(/\d+/g)
    if (numbers && numbers.length > 0) {
      const parsedNumbers = numbers.map(Number)
      return Math.max(...parsedNumbers)
    }
    return null
  }

  const businessNameRegex = computed(() => useRecipientsStore().getBusinessNameFieldRegex)
  const businessNameMaxNumOfCharGroup = extractRegexGroup(businessNameRegex.value)
  const maxNumOfBusinessNameCharacters = maxNumOfCharacters(businessNameRegex.value)
  const businessNameMaxLengthRegex = new RegExp(`^.${businessNameMaxNumOfCharGroup}$`)

  const businessNameMaxLengthValidator = {
    $validator(v, context) {
      if (
        !(context[businessField].valueRequired && context[businessField].valueRequired.$invalid)
      ) {
        return businessNameMaxLengthRegex.test(v)
      }
      return true
    },
    $message: $t('ComponentModifyRecipientModal.CharacterLimitExceededErrorText', {
      maxNumOfCharacters: maxNumOfBusinessNameCharacters,
    }).value,
  }

  const businessNameValidator = {
    $validator(v, context) {
      if (
        !(
          (context[businessField].businessNameMaxLengthValidator &&
            context[businessField].businessNameMaxLengthValidator.$invalid) ||
          context[businessField].valueRequired.$invalid
        )
      ) {
        let sanitizedValue = removeDiacritics(v).replaceAll('&', '')
        return businessNameRegex.value.test(sanitizedValue)
      }
      return true
    },
    $message: $t('ComponentModifyRecipientModal.DialogFullNameInfo.ValidationTextSpecialCharacters')
      .value,
  }

  const nicknameRegex = useRecipientsStore().getNicknameFieldRegex
  const nicknameMaxNumOfCharGroup = extractRegexGroup(nicknameRegex)
  const maxNumOfNicknameCharacters = maxNumOfCharacters(nicknameRegex)
  const nicknameMaxLengthRegex = new RegExp(`^.${nicknameMaxNumOfCharGroup}$`)

  const nicknameMaxLengthValidator = {
    $validator(v) {
      if (v.length > 0) {
        return nicknameMaxLengthRegex.test(v)
      }
      return true
    },
    $message: $t('ComponentModifyRecipientModal.CharacterLimitExceededErrorText', {
      maxNumOfCharacters: maxNumOfNicknameCharacters,
    }).value,
  }

  const nicknameValidator = {
    $validator(v, context) {
      if (
        context[nicknameField].nicknameMaxLengthValidator &&
        !context[nicknameField].nicknameMaxLengthValidator.$invalid
      ) {
        let sanitizedValue = removeDiacritics(v)
        return nicknameRegex.test(sanitizedValue)
      }
      return true
    },
    $message: $t('ComponentModifyRecipientModal.InvalidNicknameErrorText').value,
  }

  const sanitizeBusinessField = (name, maxLength) => {
    const enteredBusinessName = removeDiacritics(name)
    return enteredBusinessName.replace('&', '+').slice(0, maxLength)
  }

  return {
    businessNameValidator,
    businessNameMaxLengthValidator,
    nicknameValidator,
    nicknameMaxLengthValidator,
    sanitizeBusinessField,
    extractRegexGroup,
    maxNumOfCharacters,
  }
}
