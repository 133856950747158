import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import VueMeta from 'vue-meta'
import PrismicVue from '@prismicio/vue'
import vueDebounce from 'vue-debounce'
import 'inter-ui/inter.css'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'


import { createPinia, PiniaVuePlugin } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { currency, maxLength } from './utilities/filters'
import { toLocaleDateString } from './utilities/date-convertor.utility'

import './assets/styles/index.css'

let link = document.createElement('link')
link.rel = 'icon'
link.href = ''
document.head.appendChild(link)

Vue.config.productionTip = false

// Plugins
Vue.use(VueCompositionAPI)
Vue.use(VueMeta)
Vue.use(vueDebounce, {
  listenTo: 'input',
})

const accessToken = '' // Leave empty if your repo is public
const endpoint = 'https://rmt4-secure.prismic.io/api/v2'

// Register plugin
Vue.use(PrismicVue, {
  endpoint,
  apiOptions: { accessToken },
})

Vue.use(PiniaVuePlugin)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
Vue.use(pinia)
Vue.filter('currency', currency)
Vue.filter('maxLength', maxLength)
Vue.filter('toLocaleDateString', toLocaleDateString)




// Init
new Vue({
  router,
  pinia,
  render: (h) => h(App),
}).$mount('#app')
