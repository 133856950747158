import API from '@galileo/api/corporate'
import APIHandler from '@galileo/api/APIHandler'
import { CorporateClientInfo } from '@galileo/models/Corporate/interfaces/CorpReg'

export default new APIHandler(
  API,
  () => {
    return {
      method: 'GET',
      url: `client`,
    }
  },
  (response: {data: CorporateClientInfo}) => {
    return { ...response.data }
  }
)
