import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  ({ amount, cardId, trxRef, sessionId, profileId }, config) => {
    //sessionId is from Cardinal, might be used later.
    return {
      ...config,
      method: 'PUT',
      url: 'cards/card-transactions/allocate',
      data: {
        amount,
        cardId,
        referenceId: trxRef,
        transactionReference: trxRef,
        userId: profileId,
        deviceDataCollectionFields: {
          httpBrowserColorDepth: window.screen.colorDepth,
          httpBrowserJavaEnabled: navigator.javaEnabled() ?? false,
          httpBrowserJavaScriptEnabled: true,
          httpBrowserLanguage: navigator.language.substring(0, 5),
          httpBrowserScreenHeight: window.innerHeight,
          httpBrowserScreenWidth: window.innerWidth,
          httpBrowserTimeDifference: new Date().getTimezoneOffset(),
        },
      },
    }
  },
  (response) => {
    let authResponse = null
    if (response.data.payerAuthenticationPostData) {
      authResponse = {
        acsUrl: response.data.payerAuthenticationUrl,
        paReq: response.data.payerAuthenticationPostData.paReq,
        paTransactionId: response.data.payerAuthenticationPostData.transactionId,
      }
    }
    return {
      ...response,
      data: {
        requirePayerAuthentication: response.data.requirePayerAuthentication,
        payerAuthenticateResponse: authResponse,
      },
    }
  }
)
