/**
 * @description Geographical region the application is currently configured for, e.g. US, Spain, Australia.
 * @region Enumeration for this region.
 * @defaultCountryFrom Default value to use for send-from country, i.e. in price calculator
 * @defaultCurrencyFrom Default value to use for send-from currency, i.e. in price calculator
 * @defaultLanguage Default language culture to use, i.e. content translations
 */
export default {
  regionId: 1805,
  defaultCountryFrom: 'NO',
  defaultCountryFromOnfido: 'NOR',
  defaultCountryCodeFrom: 47,
  defaultCurrencyFrom: 'NOK',
  defaultLanguageCulture: 'nb-NO',
  customerServicePhone: '23960260',
  defaultLocation: {
    latitude: 48.878528,
    longitude: 2.353356,
  },
  dateFormat: 'DD/MM/YYYY',
  postalCodeRegex: `^\\d{4}$`,
  phoneRegex: `^[0-9\\.\\-\\+\\(\\)\\[\\] ]{10}$`,
  support: {
    email: 'NO_support@riamoneytransfer.com',
    title: 'Support request',
  },
  links: {
    linkFaq: 'https://www.riamoneytransfer.com/us/en/mobile-content/faqs',
    linkPrivacy: 'https://app.riamoneytransfer.com/app-privacy-policy',
    linkStoredCardAgreement:
      'https://www.riamoneytransfer.com/us/en/mobile-content/stored-card-agreement',
    linkTerms: 'https://www.riamoneytransfer.com/us/en/mobile-content/terms',
  },
  availableLanguageCultures: ['nb-NO', 'en-NO'],
  regionalIp: '66.82.123.234',
  requireAddress: false,
  isPlaidEnabled: false,
  isBankAccountEnabled: false,
  useSecurityQuestions: false,
  oldRemittanceLogin: false,
  onfidoMaxAttempts: 1,
}
