import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  (config) => {
    return {
      ...config,
      method: 'POST',
      url: 'authorization/logout',
    }
  },
  (response) => {
    return {
      ...response,
      data: {
        success: true,
      },
    }
  },
  (error) => {
    return {
      ...error,
      data: {
        success: false,
      },
    }
  }
)
