// Imports
import { isInEUTimeZone, isEULocale } from './useEuLocale'
import {
  getConsentFromDatalayer,
  getConsentFromCookie,
  setClienAffiliateCookie,
  setDfxIdCookie,
  cleanMarketingCookies,
} from './useConsent'

// EU User?
const isInEU = (isInEUTimeZone() || (isInEUTimeZone() && isEULocale() ))

// Assigns the consent state according to the user location
export const getConsentState = () => isInEU ? getConsentFromCookie() : getConsentFromDatalayer()

// Set Consent Manager config
export const consentManager = () => {
  // Assigns the consent state according to the user location
  const consent = getConsentState()

  // Parses queryParams
  const queryParams = new URLSearchParams(window.location.search)

  // Dfxid cookie set logic
  setDfxIdCookie(queryParams)

  // client affiliate set logic
  setClienAffiliateCookie(queryParams)

  if (consent && !consent.marketing) {
    // Delete cookies
    cleanMarketingCookies()
  }

  return getConsentState()
}


