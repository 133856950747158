import API from '@galileo/api/rmt'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  (request, config) => {
    return {
      ...config,
      method: 'POST',
      url: 'account/bank/edit',
      data: { ...request, accountId: request.id },
    }
  },
  (response) => {
    if (response && response.data) {
      return response.data
    } else {
      throw new Error('Failed to update bank account')
    }
  },
  (error) => {
    throw error.response.data.errors[0]
  }
)
