<template>
  <InformationAppModal
    class="new-look-modal"
    :icon="ICON_TYPE.REVERT"
    :shouldDisplayCloseButton="true"
    @close="closeModal"
    :value="showSwitchToFxWebModal"
  >
    <h1 class="modal-title">{{ $t('SwitchToLegacySiteModal.ModalTitle').value }}</h1>

    <p class="modal-text">{{ $t('SwitchToLegacySiteModal.ModalText').value }}</p>

    <template #footer>
      <AppButton class="feedback-button" @click="goToGiveFeedback">
        {{ $t('SwitchToLegacySiteModal.FeedbackButton').value }}
      </AppButton>
      <AppButton class="switch-button" theme="secondary" @click="goToFxWeb">
        {{ $t('SwitchToLegacySiteModal.SwitchButton').value }}
      </AppButton>
    </template>
  </InformationAppModal>
</template>

<script>

import { ref, computed } from '@vue/composition-api'
import InformationAppModal, {
  ICON_TYPE,
} from '@galileo/components/InformationAppModal/InformationAppModal'
import { AppButton, AppLink } from '@oen.web.vue2/ui'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'


import { useAnalyticsStore, useProfileStore, useI18nStore } from '@galileo/stores'

export default {
  name: 'SwitchToLegacySiteModal',
  components: {
    InformationAppModal,
    AppButton,
    AppLink,
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const analyticsStore = useAnalyticsStore()
    const profileStore = useProfileStore

    const showSwitchToFxWebModal = computed(() => {
      let isModalOpen = profileStore.showSwitchToFxWebModal
      if (isModalOpen) {
        analyticsStore.track({
          event: SEGMENT_EVENTS.LEGACY_SITE_PROMPT_DISPLAYED,
        })
      }
      return isModalOpen
    })

    const goToGiveFeedback = () => {
      const url = 'https://riamt.typeform.com/to/CTcfa6CZ'
      window.open(url)
    }

    const goToFxWeb = async () => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.LEGACY_SITE_INITIATED,
      })

      await profileStore.switchToLegacyPlatform()
      showSwitchToFxWebModal.value = false
    }

    const closeModal = () => {
      showSwitchToFxWebModal.value = false
    }

    return {
      $t,
      showSwitchToFxWebModal,
      closeModal,
      ICON_TYPE,
      goToGiveFeedback,
      goToFxWeb,
    }
  },
}
</script>

<style scoped>
::v-deep .overlay-content .card {
  max-width: 33rem;
}

::v-deep .card-content .card-content-block {
  margin-bottom: 1rem;
}
::v-deep .card-header {
  @apply px-12;
}
::v-deep .card-header .card-header-title {
  @apply h-20 mt-12;
}
::v-deep .card-header .card-header-right {
  @apply self-start;
}
::v-deep .card-footer {
  @apply items-center;
}
.modal-title {
  @apply text-3xl font-semibold;
}
::v-deep .modal-body {
  .modal-text {
    @apply mt-4;
    @apply text-sm tracking-normal text-center;
  }
}
.feedback-button {
  @apply mb-4;
}
.switch-button {
  ::v-deep .button-inner {
    text-decoration: underline !important;
  }
}
</style>
