<template>
  <div>
    <XeInputDropdown
      ref="input"
      :pageName="pageName"
      :dropdownLabel="dropdownLabel"
      optionLabel="text"
      optionLabelSubtitle="description"
      optionValue="id"
      :show-clear="false"
      :options="addressSearchResultList"
      :placeholder="placeholder || $t('XeAddressSearch.EnterAddressPlaceholder').value"
      :inputEditable="true"
      :showArrow="false"
      :loading="showLoadingSpinner"
      :showEditableLoading="showEditableLoading"
      :noOptionsMessage="noOptionsMessage"
      @input="onOptionSelected($refs, $event)"
      v-debounce:400ms="onTextInput"
      :value="displayValue"
      :showSearchInputIcon="showSearchInputIcon"
    >
      <template #custom-dropdown-footer-option>
        <div v-if="showManualSelection" class="custom-dropdown-footer">
          {{ $t('XeAddressSearch.DontSeeYourAddressText').value }}
          <a class="custom-footer-button" @click="onEnterManuallyClick($refs)">
            {{ $t('XeAddressSearch.EnterItManuallyText').value }}
          </a>
        </div>
      </template>
    </XeInputDropdown>
    <div v-if="showManualSelection && showManualSelectionBelow" class="manually-enter-section">
      {{ $t('XeAddressSearch.OrText').value }}
      <a class="custom-footer-button" @click="onEnterManuallyClick($refs)">
        {{ $t('XeAddressSearch.EnterItManuallyText').value }}</a
      >
    </div>
  </div>
</template>

<script>
import { reactive, ref } from '@vue/composition-api'

import { getFormattedStreetName } from '@galileo/utilities/formatters.utility.js'
import XeInputDropdown from '@galileo/components/XeInputDropdown/XeInputDropdown'

import { useMediaQuery } from '@oen.web.vue2/ui'

import { useI18nStore, useAppStore } from '@galileo/stores'

export default {
  name: 'XeAddressSearch',
  components: {
    XeInputDropdown,
  },
  props: {
    pageName: {
      type: String,
      default: '',
    },
    dropdownLabel: {
      type: String,
      default: '',
    },
    searchPromiseLazy: {
      type: Object,
      required: true,
    },
    getAddressDetailsPromiseLazy: {
      type: Object,
      required: true,
    },
    showManualSelection: {
      type: Boolean,
      default: true,
      required: false,
    },
    displayValue: {
      type: String,
      default: '',
      required: false,
    },
    shouldOutputEmptyString: {
      type: Boolean,
      default: false,
    },
    showManualSelectionBelow: {
      type: Boolean,
      default: true,
      required: false,
    },
    showSearchInputIcon: {
      type: Boolean,
      default: true,
      required: false,
    },
    placeholder: {
      type: String,
      default: null,
      required: false,
    }
  },
  emit: ['select', 'manual', 'searchFinish'],
  setup(props, { emit }) {
    const appStore = useAppStore()

    const { $t } = useI18nStore()

    const noOptionsMessageChoices = {
      required: $t('XeAddressSearch.PleaseTypeAtLeastACharacterText').value,
      noResult: $t('XeAddressSearch.NoResultFoundText').value,
      tooManyResult: $t('XeAddressSearch.TooManyResultsText').value,
    }

    const showLoadingSpinner = ref(false)
    const showEditableLoading = ref(false)
    const noOptionsMessage = ref(noOptionsMessageChoices.required)
    const addressSearchResultList = ref([])
    let lastSearch = ''

    const onTextInput = async (query) => {
      query = query.trim()
      addressSearchResultList.value = []
      noOptionsMessage.value = ''
      lastSearch = query
      if (query.length) {
        showLoadingSpinner.value = true
        try {
          const date = new Date()
          await props.searchPromiseLazy.exec({ query })
          if (props.searchPromiseLazy.result) {
            addressSearchResultList.value = await props.searchPromiseLazy.result
          } else if (props.searchPromiseLazy.error) {
            addressSearchResultList.value = []
            if (query.length) noOptionsMessage.value = props.searchPromiseLazy.error.message
            else noOptionsMessage.value = noOptionsMessageChoices.required
          }
          if (lastSearch !== query) {
            return
          }

          addressSearchResultList.value.duration = Math.floor(new Date().getTime() - date.getTime())

          emit('searchFinish', addressSearchResultList.value)
          showLoadingSpinner.value = false
          if (addressSearchResultList.value.length === 0 && !props.searchPromiseLazy.error) {
            noOptionsMessage.value = noOptionsMessageChoices.noResult
          }
        } catch (ex) {
          appStore.logException('Exception during searching for address', ex)
          if (lastSearch === query) {
            showLoadingSpinner.value = false
            appStore.messageBoxGenericError()
          }
        }
      } else {
        noOptionsMessage.value = noOptionsMessageChoices.required
        showLoadingSpinner.value = false
      }
      if (props.shouldOutputEmptyString) {
        await props.searchPromiseLazy.exec({ query })
      }
    }

    const onOptionSelected = async (refs, selectedId) => {
      const selectedAddress = addressSearchResultList.value.find(
        (address) => address.id === selectedId
      )

      if (selectedAddress) {
        let showItems = false
        showEditableLoading.value = true
        if (selectedAddress.type === 'Address' || selectedAddress.type === 'Item') {
          let addrDetails

          if (selectedAddress.type === 'Item') {
            addrDetails = [selectedAddress]
          } else {
            addrDetails = await props.getAddressDetailsPromiseLazy.exec(selectedId)
            const engAddrDetails = addrDetails.filter((addr) => addr.language === 'ENG')
            if (engAddrDetails.length > 0) {
              addrDetails = engAddrDetails
            }
          }
          if (addrDetails.length > 1) {
            addressSearchResultList.value = addrDetails.map((address) => {
              return {
                ...address,
                text: address.addressLine1,
                description: address.city,
                type: 'Item',
              }
            })
            showItems = true
          } else if (addrDetails.length === 1) {
            let address = addrDetails[0]
            refs.input.setInputValue(getFormattedStreetName(address))
            emit('select', address)
          } else {
            addressSearchResultList.value = []
            noOptionsMessage.value = noOptionsMessageChoices.noResult
            showItems = true
          }
        } else {
          refs.input.setInputValue(selectedAddress.text)
          addressSearchResultList.value = await props.searchPromiseLazy.exec({
            query: lastSearch,
            selectedId,
          })
          showItems = true
        }
        showEditableLoading.value = false
        if (showItems) {
          window.setTimeout(() => {
            refs.input.show()
          }, 100)
        }
      }
    }

    const onEnterManuallyClick = (refs) => {
      emit('manual', refs.input.hide())
    }

    return {
      $t,
      onTextInput,
      onOptionSelected,
      noOptionsMessage,
      showLoadingSpinner,
      showEditableLoading,
      onEnterManuallyClick,
      addressSearchResultList,
      mq: reactive(useMediaQuery()),
    }
  },
}
</script>

<style lang="postcss" scoped>
.manually-enter-section {
  @apply text-center;
}

.custom-dropdown-footer {
  @apply px-4 py-2.5;
  @apply type-caption;
  @apply cursor-default;
}

.custom-footer-button {
  @apply text-blue;
  @apply cursor-pointer;
}
</style>
