import { computed, reactive } from '@vue/composition-api'
/*  .formatCurrencyToParts(): An Array of objects containing the formatted number in parts

Input: 1000
Output:
   [
    {
        "type": "currency",
        "value": "$"
    },
    {
        "type": "integer",
        "value": "1"
    },
    {
        "type": "group",
        "value": ","
    },
    {
        "type": "integer",
        "value": "000"
    },
    {
        "type": "decimal",
        "value": "."
     },
    {
        "type": "fraction",
        "value": "00"
    }
   ]
   */
export const formatCurrencyToParts = (currency = 'USD', value = 0, locale) => {
  const fullParts = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).formatToParts(value)

  try {
    const shortParts = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      currencyDisplay: 'narrowSymbol',
    }).formatToParts(value)

    // fullCurrency would return AUD as AU$ but we want to convert it to AUD$
    const fullCurrency = fullParts.find(({ type }) => type === 'currency')
    const shortCurrency = shortParts.find(({ type }) => type === 'currency')
    if (fullCurrency && shortCurrency && fullCurrency.value !== shortCurrency.value) {
      if (fullCurrency.value.indexOf(shortCurrency.value) > 0)
        shortCurrency.value = currency + shortCurrency.value
      else shortCurrency.value += currency
    }

    return shortParts
  } catch (ex) {
    // SKIP
    // narrowSymbol might not be supported by every browser
  }
  return fullParts
}

export const formatCurrency = (currency = 'USD', value = 0, locale) => {
  return (
    formatCurrencyToParts(currency, value, locale)
      // Don't need currency and literal
      .filter(({ type }) => !['currency', 'literal'].includes(type))
      .reduce((acc, item) => acc + item.value, '')
  )
}

export const formatCurrencyFromParts = (parts) => {
  return (
    parts
      // Don't need currency and literal
      .filter(({ type }) => !['currency', 'literal'].includes(type))
      .reduce((acc, item) => acc + item.value, '')
  )
}

export const formatExchangeRate = (value) => {
  if (value) {
    let floatValue = parseFloat(value)

    if (floatValue < 1) {
      return floatValue.toFixed(5)
    } else {
      return floatValue.toPrecision(6)
    }
  }
  return ''
}

const removeTrailingZero = (str) => {
  return str.endsWith('.0') ? str.slice(0, -2) : str
}

export const formatShortenCurrency = (value) => {
  if (typeof value !== 'number') {
    throw new TypeError('Value must be a number')
  }
  const fiveSignificantFiguresValue = Number(value.toPrecision(5))

  const absoluteFiveSignificantFiguresValue = Math.abs(fiveSignificantFiguresValue)

  if (absoluteFiveSignificantFiguresValue >= 1e9) {
    return removeTrailingZero((fiveSignificantFiguresValue / 1e9).toFixed(2)) + 'B'
  } else if (absoluteFiveSignificantFiguresValue >= 1e6) {
    return removeTrailingZero((fiveSignificantFiguresValue / 1e6).toFixed(2)) + 'M'
  } else if (absoluteFiveSignificantFiguresValue >= 1e5) {
    return removeTrailingZero((fiveSignificantFiguresValue / 1e3).toFixed(2)) + 'K'
  } else {
    return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
}

export const getCurrencySymbol = (currency) => {
  switch (currency.toUpperCase()) {
    case 'GBP':
      return '£'
      break
    case 'USD':
      return '$'
      break
    case 'CAD':
      return 'C$'
      break
    default:
      return '€'
      break
  }
}

export const useCurrency = () => {
  const currency = (currency = 'USD', value = 0, locale) => {
    const formattedToParts = computed(() => formatCurrencyToParts(currency, value, locale))
    const formattedValue = computed(() => formatCurrencyFromParts(formattedToParts.value))
    const thousands = computed(() => {
      return formattedToParts.value.find(({ type }) => type === 'group')?.value || ''
    })
    const decimal = computed(() => {
      return formattedToParts.value.find(({ type }) => type === 'decimal')?.value || ''
    })
    const precision = computed(() => {
      return formattedToParts.value.find(({ type }) => type === 'fraction')?.value.length || 0
    })

    return reactive({
      formattedValue,
      thousands,
      decimal,
      precision,
    })
  }

  return {
    currency,
    formatExchangeRate,
    formatCurrency,
    formatCurrencyToParts,
    getCurrencySymbol,
  }
}
