import API from '@/api/corporate'
import APIHandler from '@/api/LaunchpadAPIHandler'

export default new APIHandler(
  API,
  () => {
    return {
      method: 'GET',
      url: `marketingpreferenceconsent`,
    }
  },
  (response) => {
    return {
      ...response.data,
      data: response.data,
    }
  }
)
