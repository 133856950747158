import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  ({ cardId, payload }, config) => {
    const payloadType = {
      currencyCode: 'string',
      cardTypeId: 0,
      expiryDate: 'string',
      firstName: 'string',
      lastName: 'string',
      billingAddress: {
        line1: 'string',
        line2: 'string',
        line3: 'string',
        place: 'string',
        state: 'string',
        postCode: 'string',
        countryCode: 'string',
      },
    }
    return {
      ...config,
      method: 'PUT',
      url: `/cardsV2/cards/${cardId}/billing-address`,
      data: payload,
    }
  },
  (response) => {
    return {
      ...response,
    }
  }
)
