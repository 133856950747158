import { PreferenceGroup, ApiPreferenceGroup } from "./Interfaces"
import { PreferenceGroupModelBase } from "./PreferenceGroupModelBase"

/**
 * settings group for scheduled payment preferences
 */
export class ScheduledPaymentsPreferences extends PreferenceGroupModelBase {
    readonly group = PreferenceGroup.ScheduledPayments
    firstScheduledPaymentDone: boolean = false
  
    private static readonly KEY_FIRST_SCHEDULED_PAYMENT : string = "firstScheduledPaymentDone"
    

    fromApiModel(apiModel: ApiPreferenceGroup): void {
      this.firstScheduledPaymentDone = apiModel.preferences.find((p) => p.key === ScheduledPaymentsPreferences.KEY_FIRST_SCHEDULED_PAYMENT)?.value === 'true'
    }
  
    toApiModel(): ApiPreferenceGroup {
      return {
        group: this.group,
        preferences: [
          { key: ScheduledPaymentsPreferences.KEY_FIRST_SCHEDULED_PAYMENT, value: this.firstScheduledPaymentDone.toString() }
        ],
      }
    }
  }
  