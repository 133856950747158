export const PRISMIC_CUSTOM_TYPES = {
  DESIGN: 'galileo_enhanced',
  HOST_MAPPING: 'mapping',
}
export const PRISMIC_HOST_MAPPING = {
  BRAND: 'brands',
  HOST: 'host',
  DESIGN_ID: 'design-id',
}
export const PRISMIC_DATA_TYPES = {
  API_BRAND: 'api-brand',
  ANALYTICS_BRAND: 'analytics-brand',
  URL: 'url',
  VARIABLES: 'variables',
  FEATURE_FLAGS: 'flag',
}
export const PRISMIC_FEATURE_FLAGS = {
  RESTRICT_REGION: 'restrict-regions',
  SHOW_CALCULATOR: 'show-calculator',
  AVAILABLE_REGION: 'available-regions',
  DEFAULT_COUNTRY_CURRENCY_ISO: 'default-country-currency',
  USE_FAVORITE_COUNTRIES: 'use-favorite-countries',
  FAVORITE_COUNTRIES: 'favorite-countries',
}

export const PRISMIC_BRAND_NAMES = {
  BASE: 'base',
  XE: 'xe',
  JLP: 'johnlewis',
  BRITLINE: 'britline',
}
export const BRAND_URL_SEGMENT = {
  JLP: 'johnlewismoney',
  BRITLINE: 'cabips',
}
