export default class UserProfilePhoneNumber {
  constructor(typePhoneNumber, profile) {
    this.areaCode = profile[typePhoneNumber].areaCode
    this.countryCode = profile[typePhoneNumber].countryCode
    this.isValid = profile[typePhoneNumber].isValid
    this.number = profile[typePhoneNumber].number
    this.phoneNumberFormatted = profile[typePhoneNumber].phoneNumberFormatted
    this.phoneNumberNoAreaCode = profile[typePhoneNumber].phoneNumberNoAreaCode
  }
}
