































import { computed, defineComponent } from '@vue/composition-api'
import { storeToRefs } from 'pinia'

import {
  AppModalFooter,
  AppButton,
  AppCardImageTitle,
  AppIcon,
  AppCardHeader
} from '@oen.web.vue2/ui'

import KYCRefreshCorporateCard from './KYCRefreshCorporateCard.vue'
import IconClose from '@oen.web.vue2/icons/src/components/IconClose.vue'
import {
  useI18nStore,
  useAuthStore,
  useKYCRefreshCorporateStore,
} from '@galileo/stores'
import {
  KycRefreshCorporateStatusEnum,
  KycRefreshCorporateStepEnum
} from '@galileo/stores/kycRefreshCorporate'

export default defineComponent({
  name: 'KYCRefreshCorporateSuccessCard',
  components: {
    AppModalFooter,
    AppButton,
    AppCardImageTitle,
    AppIcon,
    IconClose,
    KYCRefreshCorporateCard,
    AppCardHeader,
  },
  setup(_, { emit }) {
    const { $t } = useI18nStore()
    const kycRefreshCorporateStore = useKYCRefreshCorporateStore()
    const authStore = useAuthStore()

    const { user } = storeToRefs(authStore)

    // Store data
    const nowOrContinueMessage = computed((): string => {
      return $t(
        'ComponentKYCRefreshCorporateSuccess.Message',
        {
          nowOrContinue: kycRefreshCorporateStore.savedEKYCStatus === KycRefreshCorporateStatusEnum.GRACE_PERIOD
            ? $t('ComponentKYCRefreshCorporateSuccess.Continue').value
            : $t('ComponentKYCRefreshCorporateSuccess.Now').value
        }
      ).value
    })
    const showGoHomeBtn = computed((): boolean => kycRefreshCorporateStore.showGoHomeBtn)

    return {
      $t,
      emit,
      showGoHomeBtn,
      nowOrContinueMessage,
      user,
      hideModal: kycRefreshCorporateStore.hideModal,
      KycRefreshCorporateStepEnum,
    }
  },
})
