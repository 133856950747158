















































import { PropType, ref, computed, defineComponent, watch, onBeforeMount } from '@vue/composition-api'
import { AppInputText, AppInputDropdown } from '@oen.web.vue2/ui'
import { CorpProfileAddress } from '@galileo/models/Corporate/interfaces/CorpReg'
import { useI18nStore, useCountriesStore } from '@galileo/stores'
import { useValidation } from 'vue-composable'
import getValueRequiredValidation from '@galileo/utilities/validations.utility'
import State from '@galileo/models/State/app'
import { getCorporateAddressValidation } from '@galileo/composables/useCorporateAddress/index'
export default defineComponent({
  name: 'USCorporateAddressForm',
  components: {
    AppInputText,
    AppInputDropdown,
  },
  emits: ['update', 'isValid'],
  props: {
    address: {
      type: Object as PropType<CorpProfileAddress>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const valueRequired = getValueRequiredValidation()
    const countriesStore = useCountriesStore()

    const states = ref<State[]>([])

    const addressLine1 = computed({
      get: () => props.address.freeFormat?.addressLine1 || '',
      set: (newValue) => {
        emit('update', 'freeFormat', 'addressLine1', newValue)
      },
    })

    const unit = computed({
      get: () => props.address.freeFormat?.unit || '',
      set: (newValue) => {
        emit('update', 'freeFormat', 'unit', newValue)
      },
    })

    const place = computed({
      get: () => props.address.freeFormat?.place || '',
      set: (newValue) => {
        emit('update', 'freeFormat', 'place', newValue)
      },
    })
    const county = computed({
      get: () => props.address.freeFormat?.county || '',
      set: (newValue) => {
        emit('update', 'freeFormat', 'county', newValue)
      },
    })
    const postalCode = computed({
      get: () => props.address.freeFormat?.postalCode || '',
      set: (newValue) => {
        emit('update', 'freeFormat', 'postalCode', newValue)
      },
    })

    const formValidation = ref(
      useValidation({
        addressLine1: {
          $value: addressLine1,
          valueRequired,
          isValid: getCorporateAddressValidation(props.address.country, 'addressLine1'),
        },
        unit: {
          $value: unit,
          isValid: getCorporateAddressValidation(props.address.country, 'unit'),
        },
        place: {
          $value: place,
          valueRequired,
          isValid: getCorporateAddressValidation(props.address.country, 'place'),
        },
        county: {
          $value: county,
          valueRequired,
          isValid: getCorporateAddressValidation(props.address.country, 'county'),
        },
        postalCode: {
          $value: postalCode,
          valueRequired,
          isValid: getCorporateAddressValidation(props.address.country, 'postalCode'),
        },
      })
    )

    onBeforeMount(async () => {
      states.value = await countriesStore.getStatesByCountryCode(props.address.country)
      // Checking the form for invalid fields
      setTimeout(() => {
        formValidation.value.$touch()
      }, 250)
    })

    watch(
      formValidation.value,
      (validation) => {
        emit('isValid', validation.$anyInvalid)
      },
      { deep: true, immediate: true }
    )
    return { $t, addressLine1, unit, place, county, postalCode, formValidation, states }
  },
})
