





















































import { PropType, ref, computed, defineComponent, watch, onBeforeMount } from '@vue/composition-api'
import { AppInputText, AppInputDropdown } from '@oen.web.vue2/ui'
import { CorpProfileAddress } from '@galileo/models/Corporate/interfaces/CorpReg'
import { useI18nStore } from '@galileo/stores'
import { useValidation } from 'vue-composable'
import getValueRequiredValidation from '@galileo/utilities/validations.utility'
import { getCorporateAddressValidation } from '@galileo/composables/useCorporateAddress/index'
export default defineComponent({
  name: 'UKCorporateAddressForm',
  components: {
    AppInputText,
    AppInputDropdown,
  },
  emits: ['update', 'isValid'],
  props: {
    address: {
      type: Object as PropType<CorpProfileAddress>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const valueRequired = getValueRequiredValidation()

    const addressLine1 = computed({
      get: () => props.address.freeFormat?.addressLine1 || '',
      set: (newValue) => {
        emit('update', 'freeFormat', 'addressLine1', newValue)
      },
    })
    const addressLine2 = computed({
      get: () => props.address.freeFormat?.addressLine2 || '',
      set: (newValue) => {
        emit('update', 'freeFormat', 'addressLine2', newValue)
      },
    })
    const addressLine3 = computed({
      get: () => props.address.freeFormat?.addressLine3 || '',
      set: (newValue) => {
        emit('update', 'freeFormat', 'addressLine3', newValue)
      },
    })
    const place = computed({
      get: () => props.address.freeFormat?.place || '',
      set: (newValue) => {
        emit('update', 'freeFormat', 'place', newValue)
      },
    })
    const county = computed({
      get: () => props.address.freeFormat?.county || '',
      set: (newValue) => {
        emit('update', 'freeFormat', 'county', newValue)
      },
    })
    const postalCode = computed({
      get: () => props.address.freeFormat?.postalCode || '',
      set: (newValue) => {
        emit('update', 'freeFormat', 'postalCode', newValue)
      },
    })
    const formValidation = ref(
      useValidation({
        addressLine1: {
          $value: addressLine1,
          valueRequired,
          isValid: getCorporateAddressValidation(props.address.country, 'addressLine1'),
        },
        addressLine2: {
          $value: addressLine2,
          isValid: getCorporateAddressValidation(props.address.country, 'addressLine2'),
        },
        addressLine3: {
          $value: addressLine3,
          isValid: getCorporateAddressValidation(props.address.country, 'addressLine3'),
        },
        place: {
          $value: place,
          valueRequired,
          isValid: getCorporateAddressValidation(props.address.country, 'place'),
        },
        county: {
          $value: county,
          isValid: getCorporateAddressValidation(props.address.country, 'county'),
        },
        postalCode: {
          $value: postalCode,
          valueRequired,
          isValid: getCorporateAddressValidation(props.address.country, 'postalCode'),
        },
      })
    )

    onBeforeMount(() => {
      // Checking the form for invalid fields
      setTimeout(() => {
        formValidation.value.$touch()
      }, 250)
    })

    watch(
      formValidation.value,
      (validation) => {
        emit('isValid', validation.$anyInvalid)
      },
      { deep: true, immediate: true }
    )
    return {
      $t,
      addressLine1,
      addressLine2,
      addressLine3,
      place,
      county,
      postalCode,
      formValidation,
    }
  },
})
