import Vue from 'vue'
import VueRouter from 'vue-router'
import { computed } from '@vue/composition-api'

// Guards
import {
  useBeforeEachAuth,
  resetRecipient,
  resetSendMoneyForm,
  useBeforeEachGtmInitialise,
  preventSendMoneyCreationNav,
  isPromoAllowed,
} from '@galileo/router/guards'

import { useAppOverlayRouterGuards } from '@oen.web.vue2/ui'

import { useSendMoneyStore, useAuthStore, useI18nStore } from '@galileo/stores'

Vue.use(VueRouter)

const sharedQuickTransferChildRoutes = [
  {
    path: 'add-recipient',
    name: 'AddRecipient',
    component: () =>
      import(
        /* webpackChunkName: "AddRecipient" */ '@galileo/components/Views/Recipient/AddRecipient'
      ),
    props: () => {
      return { shouldSetRecipient: true }
    },
  },
  {
    path: 'recipient-details/:recipientId',
    name: 'RecipientDetails',
    component: () =>
      import(
        /* webpackChunkName: "RecipientDetails" */ '@galileo/components/Views/Recipient/RecipientDetails.vue'
      ),
  },
  {
    path: 'bank-details',
    name: 'QuickTransferBankDetails',
    component: () =>
      import(
        /* webpackChunkName: "AccountBankDetails" */ '@galileo/components/Views/PaymentMethod/BankDetailsModal.vue'
      ),
    props: () => {
      return { shouldShowVerificationWarning: true }
    },
    meta: {
      modal: {
        showModal: true,
      },
    },
  },
  {
    path: 'contact-us',
    name: 'QuickTransferContactUs',
    component: () =>
      import(
        /* webpackChunkName: "AccountNewPaymentMethod" */ '@galileo/components/Views/PaymentMethod/ContactUs/ContactUsModal.vue'
      ),
    props: () => {
      return { value: true }
    },
  },
  {
    path: 'account-restricted',
    name: 'QuickTransferAccountRestricted',
    component: () =>
      import(
        /* webpackChunkName: "QuickTransferAccountRestricted" */ '@galileo/components/Views/AccountRestrictedModal.vue'
      ),
    props: () => {
      return { value: true }
    },
  },
  {
    path: 'delete',
    name: 'QuickTransferDelete',
    component: () =>
      import(
        /* webpackChunkName: "AccountDelete" */ '@galileo/components/Views/PaymentMethod/DeletePaymentMethodDialog.vue'
      ),
    props: () => {
      return { isQuickTransfer: true }
    },
    meta: {
      modal: {
        showModal: true,
      },
    },
  },
  {
    path: 'add-new',
    name: 'QuickTransferNewPaymentMethod',
    component: () =>
      import(
        /* webpackChunkName: "AccountNewPaymentMethod" */ '@galileo/components/Views/PaymentMethod/AddPaymentMethodModal.vue'
      ),
    meta: {
      modal: {
        showModal: true,
      },
      continueSendFlow: true,
    },
    props: (props) => {
      return { isSendMoney: false, shouldOpenPlaid: props.params?.shouldOpenPlaid }
    },
  },
  {
    path: 'not-verified',
    name: 'QuickTransferBankPaymentNotVerified',
    component: () =>
      import(
        /* webpackChunkName: "QuickTransferBankPaymentNotVerified" */ '@galileo/components/Views/PaymentMethod/BankPaymentNotVerified.vue'
      ),
    meta: {
      modal: {
        showModal: true,
      },
    },
    props: { openAsRoute: true },
  },
]

export const routes = [
  {
    path: '/volt_redirect',
    name: 'VoltRedirect',
    component: () => import('@galileo/views/VoltIframe.vue'),
    meta: { auth: false },
  },
  {
    path: '/',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "Activity" */ '@galileo/views/Dashboard.vue'),
    meta: { auth: true },
  },
  {
    path: '/',
    name: 'Login',
    component: () => import('@galileo/views/Login.vue'),
    meta: { auth: false },
  },
  {
    path: '/volt-iframe',
    name: 'VoltIframe',
    component: () => import('@galileo/views/VoltIframe.vue'),
  },
  {
    path: '/adyen-redirect',
    name: 'AdyenIframe',
    component: () => import('@galileo/views/AdyenIframe.vue'),
  },
  //WHITELABEL TESTING URL - JLP
  {
    path: '/login-jlp-top-secret',
    component: () => import('@galileo/views/Login.vue'),
    meta: { auth: false },
  },
  //WHITELABEL TESTING URL - Britline
  {
    path: '/login-bips-top-secret',
    component: () => import('@galileo/views/Login.vue'),
    meta: { auth: false },
  },
  {
    path: '/profileSelection',
    name: 'ProfileSelection',
    component: () =>
      import(/* webpackChunkName: "LoginNewTerms" */ '@galileo/views/ProfileSelection.vue'),
    meta: { auth: false },
  },
  {
    path: '/login-new-terms',
    name: 'LoginNewTerms',
    component: () =>
      import(/* webpackChunkName: "LoginNewTerms" */ '@galileo/views/LoginNewTerms.vue'),
  },
  {
    path: '/account-locked',
    name: 'AccountLocked',
    component: () =>
      import(/* webpackChunkName: "AccountLocked" */ '@galileo/views/AccountLocked.vue'),
    meta: { auth: false },
  },
  {
    path: '/account-locked-too-many-attempts/:redirect?',
    name: 'AccountLockedTooManyAttempts',
    component: () =>
      import(
        /* webpackChunkName: "AccountLockedTooManyAttempts" */ '@galileo/views/AccountLockedTooManyAttempts.vue'
      ),
    meta: {
      auth: false,
    },
  },
  {
    path: '/otp/:otpType',
    name: 'OTP',
    component: () => import(/* webpackChunkName: "OTP" */ '@galileo/views/OTP.vue'),
    meta: { auth: false },
  },
  {
    path: '/plaid_oauth',
    name: 'PlaidOauth',
    component: () => import(/* webpackChunkName: "PlaidOauth" */ '@galileo/views/PlaidOauth.vue'),
  },
  {
    path: '/',
    name: 'QuickTransferActionsPage',
    component: () =>
      import(
        /* webpackChunkName: "Activity" */ '@galileo/components/Views/QuickTransfer/QuickTransferActionPageWrapper/QuickTransferActionsPageWrapper.vue'
      ),
    children: [
      {
        path: '/fund-balance',
        name: 'FundBalance',
        component: () =>
          import(/* webpackChunkName: "Activity" */ '@galileo/views/FundBalance.vue'),
        children: sharedQuickTransferChildRoutes,
      },
      {
        path: '/convert-balance',
        name: 'ConvertBalances',
        component: () =>
          import(
            /* webpackChunkName: "Activity" */ '@galileo/components/Views/Balances/ConvertBalances.vue'
          ),
        children: [
          {
            path: 'account-restricted',
            name: 'ConvertBalanceAccountRestricted',
            component: () =>
              import(
                /* webpackChunkName: "QuickTransferAccountRestricted" */ '@galileo/components/Views/AccountRestrictedModal.vue'
              ),
            props: () => {
              return { value: true }
            },
          },
        ]
      },
      {
        path: '/one-step-transfer',
        name: 'OneStepTransfer',
        component: () =>
          import(/* webpackChunkName: "OneStepTransfer" */ '@galileo/views/QuickTransfer.vue'),
        children: sharedQuickTransferChildRoutes,
      },
    ],
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "Dashboard" */ '@galileo/views/Dashboard.vue'),
    children: [
      {
        path: '/balances/:currency',
        name: 'IndividualCurrency',
        component: () =>
          import(
            /* webpackChunkName: "IndividualCurrency" */ '@galileo/views/IndividualCurrency.vue'
          ),
      },
      {
        path: '/balances',
        name: 'BalancesOverview',
        component: () => import('@galileo/components/Views/Balances/BalancesOverviewList.vue'),
      },
      {
        path: '/quick-transfer',
        beforeEnter: (to, from, next) => {
          if (useAuthStore().getIsBalancesEnabled) {
            next({ name: 'OneStepTransfer' })
          } else {
            next()
          }
        },
        name: 'QuickTransfer',
        component: () => import(/* webpackChunkName: "Activity" */ '@galileo/views/Activity.vue'),
        props: (props) => {
          return { activeTabName: 'QuickTransfer' }
        },
        children: sharedQuickTransferChildRoutes,
      },
      {
        path: '/activity',
        name: 'Activity',
        component: () => import(/* webpackChunkName: "Activity" */ '@galileo/views/Activity.vue'),
        props: (props) => {
          return { activeTabName: 'Activity' }
        },
      },
      {
        path: '/allLanguages',
        name: 'ActivityNewLanguages',
        beforeEnter: (to, from, next) => {
          useI18nStore().setUseNewLanguages()
          next()
        },
        component: () => import(/* webpackChunkName: "Activity" */ '@galileo/views/Activity.vue'),
        props: (props) => {
          return { activeTabName: 'Activity' }
        },
      },
      {
        path: '/activity/provide-recipient',
        name: 'ProvideRecipientDetails',
        component: () =>
          import(
            /* webpackChunkName: "ProvideRecipientDetails" */ '@galileo/views/ProvideRecipientDetails.vue'
          ),
      },
      {
        path: '/account',
        name: 'Account',
        component: () => import(/* webpackChunkName: "Account" */ '@galileo/views/Account.vue'),
        children: [
          {
            path: 'profile',
            name: 'AccountProfile',
            component: () =>
              import(
                /* webpackChunkName: "AccountProfile" */ '@galileo/components/Views/Account/Profile/ProfileModal'
              ),
            meta: {
              modal: {
                showModal: true,
              },
            },
          },
          {
            path: 'profile/edit-phone',
            name: 'AccountEditPhone',
            component: () =>
              import(
                /* webpackChunkName: "AccountEditPhone" */ '@galileo/components/Views/Account/Profile/EditPhoneModal'
              ),
            meta: {
              modal: {
                showModal: true,
              },
            },
          },
          {
            path: 'verify-identity',
            name: 'AccountVerifyIdentity',
            component: () =>
              import(
                /* webpackChunkName: "AccountVerifyIdentity" */ '@galileo/views/AccountVerifyIdentity.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
            },
          },
          {
            path: 'onfido',
            name: 'AccountOnfido',
            component: () =>
              import(/* webpackChunkName: "AccountOnfido" */ '@galileo/views/AccountOnfido.vue'),
            meta: {
              modal: {
                showModal: true,
              },
            },
          },
        ],
      },
      {
        path: '/payment-methods',
        name: 'AccountPaymentMethods',
        component: () =>
          import(
            /* webpackChunkName: "AccountPaymentMethods" */ '@galileo/views/AccountPaymentMethods.vue'
          ),
        children: [
          {
            path: 'add-new',
            name: 'AccountNewPaymentMethod',
            component: () =>
              import(
                /* webpackChunkName: "AccountNewPaymentMethod" */ '@galileo/components/Views/PaymentMethod/AddPaymentMethodModal.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
            },
            props: (props) => {
              return { isSendMoney: false, shouldOpenPlaid: props.params?.shouldOpenPlaid }
            },
          },
          {
            path: 'contact-us',
            name: 'AccountNewPaymentMethodContactUs',
            component: () =>
              import(
                /* webpackChunkName: "AccountNewPaymentMethod" */ '@galileo/components/Views/PaymentMethod/ContactUs/ContactUsModal.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
            },
            props: () => {
              return { isSendMoney: false }
            },
          },
          {
            path: 'add-bank-type',
            name: 'AccountAddBankType',
            component: () =>
              import(
                /* webpackChunkName: "AccountAddBankType" */ '@galileo/components/Views/PaymentMethod/AddPaymentBankModal.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
            },
          },
          {
            path: 'add-bank-account',
            name: 'AccountManualBank',
            component: () =>
              import(
                /* webpackChunkName: "AccountManualBank" */ '@galileo/components/Views/PaymentMethod/BankAccountModal.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
            },
          },
          {
            path: 'add-bank-verification',
            name: 'AccountManualVerification',
            component: () =>
              import(
                /* webpackChunkName: "AccountManualVerification" */ '@galileo/components/Views/PaymentMethod/MicroDepositModal.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
            },
          },
          {
            path: 'add-debit-card',
            name: 'AccountAddDebitCard',
            component: () =>
              import(
                /* webpackChunkName: "AccountAddDebitCard" */ '@galileo/components/Views/PaymentMethod/AddPaymentCardModal.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
            },
          },
          {
            path: 'add-credit-card',
            name: 'AccountAddCreditCard',
            component: () =>
              import(
                /* webpackChunkName: "AccountAddCreditCard" */ '@galileo/components/Views/PaymentMethod/AddPaymentCardModal.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
            },
          },
          {
            path: 'billing-address',
            name: 'AccountCardBillingAddress',
            component: () =>
              import(
                /* webpackChunkName: "AccountCardBillingAddress" */ '@galileo/components/Views/PaymentMethod/BillingAddressModal.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
            },
          },
          {
            path: 'delete',
            name: 'AccountDelete',
            component: () =>
              import(
                /* webpackChunkName: "AccountDelete" */ '@galileo/components/Views/PaymentMethod/DeletePaymentMethodDialog.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
            },
          },
          {
            path: 'expired',
            name: 'CardExpired',
            component: () =>
              import(
                /* webpackChunkName: "CardExpired" */ '@galileo/components/Views/PaymentMethod/CardPaymentExpired.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
            },
          },
          {
            path: 'card-details',
            name: 'AccountCardDetails',
            component: () =>
              import(
                /* webpackChunkName: "AccountCardDetails" */ '@galileo/components/Views/PaymentMethod/CardDetailsModalComplete.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
            },
          },
          {
            path: 'disabled',
            name: 'BankPaymentDisabled',
            component: () =>
              import(
                /* webpackChunkName: "BankPaymentDisabled" */ '@galileo/components/Views/PaymentMethod/BankPaymentDisabled.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
            },
          },
          {
            path: 'not-verified',
            name: 'BankPaymentNotVerified',
            component: () =>
              import(
                /* webpackChunkName: "BankPaymentNotVerified" */ '@galileo/components/Views/PaymentMethod/BankPaymentNotVerified.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
            },
            props: { isSendMoney: false, isNewAccount: false, openAsRoute: true },
          },
          {
            path: 'verification-pending',
            name: 'BankVerificationPendingModal',
            component: () =>
              import(
                /* webpackChunkName: "BankVerificationPendingModal" */ '@galileo/components/Views/PaymentMethod/BankVerificationPendingModal.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
            },
            props: { isSendMoney: false },
          },
          {
            path: 'not-supported',
            name: 'PlaidProductNotSupported',
            component: () =>
              import(
                /* webpackChunkName: "PlaidProductNotSupported" */ '@galileo/components/Views/PaymentMethod/PlaidProductNotSupportedError.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
            },
            props: { isSendMoney: false, isNewAccount: false },
          },
          {
            path: 'bank-details',
            name: 'AccountBankDetails',
            component: () =>
              import(
                /* webpackChunkName: "AccountBankDetails" */ '@galileo/components/Views/PaymentMethod/BankDetailsModal.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
            },
          },
        ],
      },

      {
        path: '/account/settings',
        name: 'AccountSettings',
        component: () =>
          import(/* webpackChunkName: "AccountSettings" */ '@galileo/views/AccountSettings.vue'),
      },
      {
        path: '/invite-friend',
        name: 'InviteFriend',
        component: () =>
          import(
            /* webpackChunkName: "InviteFriend" */ '@galileo/components/Views/Promotions/InviteFriend.vue'
          ),
        beforeEnter: isPromoAllowed,
      },
      {
        path: '/recipients',
        name: 'Recipients',
        component: () =>
          import(/* webpackChunkName: "Recipients" */ '@galileo/views/Recipients.vue'),
      },
      {
        path: '/all-transactions',
        name: 'AllTransactions',
        component: () =>
          import(/* webpackChunkName: "AllTransactions" */ '@galileo/views/AllTransactions.vue'),
      },
      {
        path: '/market-order',
        name: 'MarketOrder',
        component: () =>
          import(/* webpackChunkName: "MarketOrder" */ '@galileo/views/MarketOrder.vue'),
      },
      // {
      //   path: '/rate-alerts',
      //   name: 'RateAlerts',
      //   component: () => import(/* webpackChunkName: "MarketOrder" */ '@galileo/views/RateAlerts.vue'),
      // },
      {
        path: '/regular-payments',
        name: 'RegularPayments',
        component: () =>
          import(/* webpackChuckName:"RegularPayments" */ '@galileo/views/RegularPayments.vue'),
      },
      {
        path: '/balances-and-payments',
        name: 'BalancesAndPayments',
        component: () =>
          import(
            /* webpackChunkName: "BalancesAndPayments" */ '@galileo/views/BalancesAndPayments.vue'
          ),
      },
      {
        path: '/payments',
        name: 'Payments',
        component: () => import(/* webpackChunkName: "Payments" */ '@galileo/views/Payments.vue'),
      },
      {
        path: '/rate-alerts',
        name: 'RateAlerts',
        component: () =>
          import(/* webpackChunkName: "RateAlerts" */ '@galileo/views/RateAlerts.vue'),
      },
      {
        path: '/document-upload',
        name: 'DocumentUpload',
        component: () =>
          import(/* webpackChunkName: "DocumentUpload" */ '@galileo/views/DocumentUpload.vue'),
      },
      {
        path: '/locations',
        name: 'Locations',
        component: () => import(/* webpackChunkName: "Locations" */ '@galileo/views/Locations.vue'),
      },
      {
        path: '/upload-documents',
        name: 'UploadDocuments',
        component: () =>
          import(/* webpackChunkName: "UploadDocuments" */ '@galileo/views/UploadDocuments.vue'),
      },
    ],
  },
  {
    path: '/onfido-status',
    name: 'OnfidoStatus',
    component: () =>
      import(/* webpackChunkName: "OnfidoStatus" */ '@galileo/views/OnfidoStatus.vue'),
    meta: {
      modal: {
        showModal: true,
      },
    },
  },
  {
    path: '/send-money',
    name: 'SendMoney',
    component: () => import(/* webpackChunkName: "SendMoney" */ '@galileo/views/SendMoney.vue'),
    children: [
      {
        path: '',
        name: 'SendMoneyAmount',
        component: () =>
          import(/* webpackChunkName: "SendMoneyAmount" */ '@galileo/views/SendMoneyAmount.vue'),
        beforeEnter: resetSendMoneyForm,
        children: [
          {
            path: 'promo-code',
            name: 'SendMoneyPromoCodeModal',
            component: () =>
              import(
                /* webpackChunkName: "SendMoneyPromoCode" */ '@galileo/components/Views/SendMoney/Modals/SendMoneyPromoCodeModal.vue'
              ),
            beforeEnter: isPromoAllowed,
            meta: {
              modal: {
                showModal: true,
              },
            },
            props: (props) => {
              return { value: true }
            },
          },
        ],
      },

      {
        path: 'open-banking',
        name: 'SendMoneyVolt',
        component: () => import('@galileo/views/SendMoneyVolt.vue'),
      },
      {
        path: 'open-banking/info',
        name: 'SendMoneyVoltInfo',
        component: () => import('@galileo/views/SendMoneyVoltInfo.vue'),
      },
      {
        path: 'open-banking/cancelled',
        name: 'SendMoneyVoltPaymentCancelled',
        component: () => import('@galileo/views/SendMoneyVoltPaymentCancelled.vue'),
      },
      {
        path: 'resend',
        name: 'SendMoneyAmount',
        component: () =>
          import(/* webpackChunkName: "SendMoneyAmount" */ '@galileo/views/SendMoneyAmount.vue'),
        // beforeEnter: resetSendMoneyForm,
      },
      {
        path: 'recipient',
        name: 'SendMoneyRecipient',
        component: () =>
          import(
            /* webpackChunkName: "SendMoneyRecipient" */ '@galileo/views/SendMoneyRecipient.vue'
          ),
        beforeEnter: resetRecipient,
      },
      {
        path: 'recipient/missing',
        name: 'SendMoneyRecipient',
        component: () =>
          import(
            /* webpackChunkName: "SendMoneyRecipient" */ '@galileo/views/SendMoneyRecipient.vue'
          ),
        meta: {
          displayMissingInformationModal: true,
        },
      },
      {
        path: 'delivery',
        name: 'SendMoneyDelivery',
        component: () =>
          import(
            /* webpackChunkName: "SendMoneyDelivery" */ '@galileo/views/SendMoneyDelivery.vue'
          ),
        children: [
          {
            path: 'bank-account',
            name: 'SendMoneyDeliveryBankAccount',
            component: () =>
              import(
                /* webpackChunkName: "SendMoneyDelivery" */ '@galileo/components/Views/Delivery/BankDeposit.vue'
              ),
          },
          {
            path: 'choose-network',
            name: 'SendMoneyDeliveryChooseNetwork',
            component: () =>
              import(
                /* webpackChunkName: "SendMoneyDelivery" */ '@galileo/components/Views/Delivery/Pickup.vue'
              ),
          },
          {
            path: 'choose-location',
            name: 'SendMoneyDeliveryChooseLocation',
            component: () =>
              import(
                /* webpackChunkName: "SendMoneyDelivery" */ '@galileo/components/Views/Delivery/PickupMap.vue'
              ),
          },
          {
            path: 'open-payment',
            name: 'SendMoneyDeliveryOpenPayment',
            component: () =>
              import(
                /* webpackChunkName: "SendMoneyDeliveryOpenPayment" */ '@galileo/components/Views/Delivery/OpenPayment.vue'
              ),
          },
        ],
      },
      {
        path: 'payment',
        name: 'SendMoneyPayment',
        component: () =>
          import(/* webpackChunkName: "SendMoneyPayment" */ '@galileo/views/SendMoneyPayment.vue'),
        children: [
          {
            path: 'contact-us',
            name: 'SendMoneyPaymentContactUs',
            component: () =>
              import(
                /* webpackChunkName: "AccountNewPaymentMethod" */ '@galileo/components/Views/PaymentMethod/ContactUs/ContactUsModal.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
            },
            props: () => {
              return { isSendMoney: true }
            },
          },
          {
            path: 'verification-pending',
            name: 'SendMoneyBankVerificationPendingModal',
            component: () =>
              import(
                /* webpackChunkName: "SendMoneyBankVerificationPendingModal" */ '@galileo/components/Views/PaymentMethod/BankVerificationPendingModal.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
            },
            props: { isSendMoney: true },
          },
          {
            path: 'add-new',
            name: 'SendMoneyNewPaymentMethod',
            component: () =>
              import(
                /* webpackChunkName: "SendMoneyNewPaymentMethod" */ '@galileo/components/Views/PaymentMethod/AddPaymentMethodModal.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
              continueSendFlow: true,
            },
            props: () => {
              const paymentMethodType = computed(() => useSendMoneyStore().form.paymentMethod.value)

              return { paymentMethodType: paymentMethodType.value, isSendMoney: true }
            },
          },
          {
            path: 'add-bank-type',
            name: 'SendMoneyAddBankType',
            component: () =>
              import(
                /* webpackChunkName: "SendMoneyAddBankType" */ '@galileo/components/Views/PaymentMethod/AddPaymentBankModal.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
              continueSendFlow: true,
            },
          },
          {
            path: 'add-bank-account',
            name: 'SendMoneyManualBank',
            component: () =>
              import(
                /* webpackChunkName: "SendMoneyManualBank" */ '@galileo/components/Views/PaymentMethod/BankAccountModal.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
            },
          },
          {
            path: 'add-bank-verification',
            name: 'SendMoneyManualVerification',
            component: () =>
              import(
                /* webpackChunkName: "SendMoneyManualVerification" */ '@galileo/components/Views/PaymentMethod/MicroDepositModal.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
            },
          },
          {
            path: 'add-debit-card',
            name: 'SendMoneyAddDebitCard',
            component: () =>
              import(
                /* webpackChunkName: "SendMoneyAddDebitCard" */ '@galileo/components/Views/PaymentMethod/AddPaymentCardModal.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
              continueSendFlow: true,
            },
          },
          {
            path: 'add-credit-card',
            name: 'SendMoneyAddCreditCard',
            component: () =>
              import(
                /* webpackChunkName: "SendMoneyAddCreditCard" */ '@galileo/components/Views/PaymentMethod/AddPaymentCardModal.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
              continueSendFlow: true,
            },
          },
          {
            path: 'billing-address',
            name: 'SendMoneyCardBillingAddress',
            component: () =>
              import(
                /* webpackChunkName: "SendMoneyCardBillingAddress" */ '@galileo/components/Views/PaymentMethod/BillingAddressModal.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
            },
          },
          {
            path: 'delete',
            name: 'SendMoneyDelete',
            component: () =>
              import(
                /* webpackChunkName: "SendMoneyDelete" */ '@galileo/components/Views/PaymentMethod/DeletePaymentMethodDialog.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
            },
          },
          {
            path: 'expired',
            name: 'CardExpired',
            component: () =>
              import(
                /* webpackChunkName: "CardExpired" */ '@galileo/components/Views/PaymentMethod/CardPaymentExpired.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
            },
          },
          {
            path: 'card-details',
            name: 'SendMoneyCardDetails',
            component: () =>
              import(
                /* webpackChunkName: "SendMoneyCardDetails" */ '@galileo/components/Views/PaymentMethod/CardDetailsModalComplete.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
            },
          },
          {
            path: 'disabled',
            name: 'BankPaymentDisabled',
            component: () =>
              import(
                /* webpackChunkName: "BankPaymentDisabled" */ '@galileo/components/Views/PaymentMethod/BankPaymentDisabled.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
            },
          },
          {
            path: 'not-verified',
            name: 'SendMoneyBankPaymentNotVerified',
            component: () =>
              import(
                /* webpackChunkName: "BankPaymentNotVerified" */ '@galileo/components/Views/PaymentMethod/BankPaymentNotVerified.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
            },
            props: (route) => ({
              isSendMoney: route.params.isSendMoney,
              isNewAccount: route.params.isNewAccount,
              openAsRoute: true,
            }),
          },
          {
            path: 'not-supported',
            name: 'SendMoneyPlaidProductNotSupported',
            component: () =>
              import(
                /* webpackChunkName: "SendMoneyPlaidProductNotSupported" */ '@galileo/components/Views/PaymentMethod/PlaidProductNotSupportedError.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
            },
            props: (route) => ({
              isSendMoney: route.params.isSendMoney,
              isNewAccount: route.params.isNewAccount,
            }),
          },
          {
            path: 'bank-details',
            name: 'SendMoneyBankDetails',
            component: () =>
              import(
                /* webpackChunkName: "SendMoneyBankDetails" */ '@galileo/components/Views/PaymentMethod/BankDetailsModal.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
            },
          },
          {
            path: 'card-limit-reached',
            name: 'LimitReachedModal',
            component: () =>
              import(
                /* webpackChunkName: "LimitReachedModal" */ '@galileo/components/Views/PaymentMethod/LimitReachedModal.vue'
              ),
            meta: {
              modal: {
                showModal: true,
              },
              continueSendFlow: true,
            },
          },
        ],
      },
      {
        path: 'payment/directid-callback',
        name: 'SendMoneyPaymentDirectId',
        component: () =>
          import(
            /* webpackChunkName: "SendMoneyPaymentDirectId" */ '@galileo/views/SendMoneyPaymentDirectId.vue'
          ),
      },
      {
        path: 'summary',
        name: 'SendMoneySummary',
        component: () =>
          import(/* webpackChunkName: "SendMoneySummary" */ '@galileo/views/SendMoneySummary.vue'),
      },
      {
        path: 'summary/edit',
        name: 'SendMoneySummaryEdit',
        component: () =>
          import(/* webpackChunkName: "SendMoneyAmount" */ '@galileo/views/SendMoneyAmount.vue'),
        meta: {
          summaryEdit: true,
        },
      },
      {
        path: 'summary/verification',
        name: 'SendMoneyVerification',
        component: () =>
          import(
            /* webpackChunkName: "SendMoneyVerification" */ '@galileo/views/SendMoneyVerification.vue'
          ),
      },
      {
        path: 'summary/additional-details',
        name: 'SendMoneyAdditionalDetails',
        component: () =>
          import(
            /* webpackChunkName: "SendMoneyAdditionalDetails" */ '@galileo/views/SendMoneyAdditionalDetails.vue'
          ),
      },
      {
        path: 'summary/pep-questions',
        name: 'SendMoneyPepQuestions',
        component: () =>
          import(
            /* webpackChunkName: "SendMoneyPepQuestions" */ '@galileo/views/SendMoneyPepQuestions.vue'
          ),
      },
      {
        path: 'summary/verify-identity',
        name: 'SendMoneyVerifyIdentity',
        component: () =>
          import(
            /* webpackChunkName: "SendMoneyVerifyIdentity" */ '@galileo/views/SendMoneyVerifyIdentity.vue'
          ),
      },
      {
        path: 'summary/verify-identity-ekyc',
        name: 'SendMoneyVerifyIdentityEKYC',
        component: () =>
          import(
            /* webpackChunkName: "SendMoneyVerifyIdentityEKYC" */ '@galileo/views/SendMoneyVerifyIdentityEKYC.vue'
          ),
      },
      {
        path: 'summary/verify-identity-success',
        name: 'SendMoneyVerifyIdentitySuccess',
        component: () =>
          import(
            /* webpackChunkName: "SendMoneyVerifyIdentitySuccess" */ '@galileo/views/SendMoneyVerifyIdentitySuccess.vue'
          ),
      },
      {
        path: 'summary/verify-identity-failed',
        name: 'SendMoneyVerifyIdentityFailed',
        component: () =>
          import(
            /* webpackChunkName: "SendMoneyVerifyIdentityFailed" */ '@galileo/views/SendMoneyVerifyIdentityFailed.vue'
          ),
      },
      {
        path: 'summary/onfido',
        name: 'SendMoneyOnfido',
        component: () =>
          import(/* webpackChunkName: "SendMoneyOnfido" */ '@galileo/views/SendMoneyOnfido.vue'),
      },
      {
        path: 'summary/veriff',
        name: 'SendMoneyVeriff',
        component: () =>
          import(/* webpackChunkName: "SendMoneyOnfido" */ '@galileo/views/SendMoneyVeriff.vue'),
      },
      {
        path: 'summary/upload-document',
        name: 'SendMoneyUploadDocument',
        component: () =>
          import(
            /* webpackChunkName: "SendMoneyPayment" */ '@galileo/views/SendMoneyUploadDocument.vue'
          ),
      },
      {
        path: 'finished',
        name: 'SendMoneyFinished',
        component: () =>
          import(
            /* webpackChunkName: "SendMoneyFinished" */ '@galileo/views/SendMoneyFinished.vue'
          ),
      },
      {
        path: 'fund',
        name: 'SendMoneyFund',
        component: () =>
          import(/* webpackChunkName: "SendMoneyFund" */ '@galileo/views/SendMoneyFund.vue'),
      },
      {
        path: '/send-money/failed',
        name: 'SendMoneyFailed',
        component: () =>
          import(/* webpackChunkName: "SendMoneyFailed" */ '@galileo/views/SendMoneyFailed.vue'),
      },
    ],
  },
  {
    path: '/creating',
    name: 'SendMoneyCreating',
    component: () =>
      import(/* webpackChunkName: "SendMoneyCreating" */ '@galileo/views/SendMoneyCreating.vue'),
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "NotFound" */ '@galileo/views/PageNotFound.vue'),
    meta: {
      auth: false,
    },
  },
]
if (process.env.VUE_APP_ENV === 'development' || process.env.NODE_ENV === 'development') {
  routes.push({
    path: '/dev',
    name: 'Dev',
    component: () => import(/* webpackChunkName: "Dev" */ '@galileo/views/Dev'),
    meta: { auth: false },
  })
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

useBeforeEachAuth(router)
useAppOverlayRouterGuards(router)
useBeforeEachGtmInitialise(router)
preventSendMoneyCreationNav(router)

export default router
