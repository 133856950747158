import { computed, ref } from '@vue/composition-api'
import { defineStore } from 'pinia'
import instantBankVerificationSettings from '@galileo/api/launchpad/plaid/settings/get'
import bankAccountCreatePlaid from '@galileo/api/launchpad/plaid/confirm-account/v2/put'
import bankAccountCreate from '@galileo/api/rmt/account/bank/create/post'
import verifyBankAccount from '@galileo/api/rmt/account/bank/verify/post'
import { PLAID_CREATION_STATUS } from '@galileo/constants/bankAccCreationStatuses.const'

import VueRouter from 'vue-router'
import { BankAccountForm, BankAccountPlaidArgs, AccountData } from '@galileo/models/BankAccount'
import { BankAccount } from '@galileo/models/payments/interfaces/paymentsTypes'

import { usePaymentsStore, useAuthStore } from '@galileo/stores'

export const useBankAccountStore = defineStore(
  'bankAccount',
  () => {
    const paymentsStore = usePaymentsStore()
    const authStore = useAuthStore()
    const form = ref<BankAccountForm>({
      countryTo: null,
      plaidLinkToken: null,
      plaidParentRoute: null,
      plaidSendMoneyRoute: false,
    })

    const getCustomerReference = computed(async () => {
      const user = authStore.user
      const customerRef = `XE_web_${user?.customer?.id}`
      return customerRef
    })
    const getPlaidLinkToken = computed(() => {
      return form.value.plaidLinkToken
    })
    const resetForm = () => {
      form.value = {
        countryTo: null,
        plaidLinkToken: null,
        plaidParentRoute: null,
        plaidSendMoneyRoute: false,
      }
    }

    const plaidSettings = async (
      getParentRoute: Function,
      currentRouteMeta: VueRouter['currentRoute']['meta']
    ) => {
      const { data } = await instantBankVerificationSettings.exec()

      if (data) {
        form.value = {
          ...form.value,
          plaidLinkToken: data.linkToken,
          plaidParentRoute: '/',
          plaidSendMoneyRoute: false,
        }
        if (getParentRoute && currentRouteMeta) {
          form.value.plaidParentRoute = getParentRoute()
          if (currentRouteMeta?.continueSendFlow) {
            form.value.plaidSendMoneyRoute = true
          }
        }
        return data
      } else {
        throw new Error(`Failed to get plaid settings`)
      }
    }

    const addBankAccountPlaid = async (AddBankAccountPlaidArgs: BankAccountPlaidArgs) => {
      try {
        const confirmAccountData: AccountData = await bankAccountCreatePlaid.exec(
          AddBankAccountPlaidArgs
        )
        if (confirmAccountData) {
          const { bankAccount, creationStatus } = confirmAccountData
          if (creationStatus === PLAID_CREATION_STATUS.CREATED) {
            paymentsStore.getPaymentMethods()
            paymentsStore.setSelectedPaymentMethod(bankAccount as unknown as BankAccount)
          }

          return { bankAccount, creationStatus }
        }
      } catch (ex) {
        console.log('Caught an exception:', ex)
        throw ex
      }
    }

    const addBankAccount = async (accountInfo: any) => {
      const bankAccountDetails = await bankAccountCreate.exec(accountInfo)

      paymentsStore.getPaymentMethods()
      paymentsStore.setSelectedPaymentMethod(bankAccountDetails)
      return bankAccountDetails
    }

    const callVerifyBankAccount = async (microDeposit: any) => {
      const data = await verifyBankAccount.exec(microDeposit)
      paymentsStore.getPaymentMethods()
      return data
    }

    return {
      // Getters
      getCustomerReference,
      getPlaidLinkToken,
      // Actions
      addBankAccountPlaid,
      resetForm,
      plaidSettings,
      addBankAccount,
      callVerifyBankAccount,
    }
  },
  {
    persist: {
      storage: sessionStorage,
    }
  }
)
