import API from '@galileo/api/rmt'
import APIHandler from '@galileo/api/APIHandler'
import { maskFormatToApi } from '@galileo/composables/useMaskHelpers'
import { toApi } from '@galileo/models/Country/countryMapping'

export default new APIHandler(
  API,
  ({ recipient, currency }, config) => {
    recipient.amountFrom = 1 // TODO: needed ?
    recipient.currencyTo = currency
    recipient.country = toApi(recipient.country)
    // API Accepts "*" for validation, but does not accept "•" set in Recipient constructor
    recipient.bankAccountNumber = recipient.bankAccountNumber
      ? maskFormatToApi(recipient.bankAccountNumber)
      : recipient.bankAccountNumber

    return {
      ...config,
      method: 'PUT',
      url: '/recipient/bank-deposit',
      data: recipient,
    }
  },
  (response) => {
    if (!response.data) {
      return response
    }
    return {
      ...response,
      data: response.data,
    }
  }
)
