import API from '@galileo/api/rmt'
import APIHandler from '@galileo/api/APIHandler'
import RmtField from '@galileo/models/DynamicField/RmtField'
import { camelCase } from 'lodash-es'
import { toApi } from '@galileo/models/Country/countryMapping'

export default new APIHandler(
  API,
  ({ bankId, country, currency }, config) => {
    const params = {
      bankId: bankId,
      amountFrom: 1, // TODO: needed ?
      deliveryMethodID: 2, // TODO: needed ?
      countryTo: toApi(country),
      currencyTo: currency,
    }
    return {
      ...config,
      method: 'GET',
      url: 'recipient/bank-deposit',
      params: params,
    }
  },
  (response) => {
    if (
      response &&
      response.data &&
      response.data.formData &&
      response.data.formData.RecipientBankDepositForm &&
      response.data.formData.RecipientBankDepositForm.formFields
    ) {
      const fields = response.data.formData.RecipientBankDepositForm.formFields.map((field) => {
        // TODO - This can probably be removed would need to investigate side effects first
        field.id = camelCase(field.id)
        return new RmtField(field)
      })
      return {
        ...response,
        data: fields.filter((field) => field.id !== 'bankId'),
      }
    }
    return response
  }
)
