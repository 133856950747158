import { render, staticRenderFns } from "./KYCRefreshCorporateModal.vue?vue&type=template&id=0c4118d2&scoped=true&"
import script from "./KYCRefreshCorporateModal.vue?vue&type=script&lang=ts&"
export * from "./KYCRefreshCorporateModal.vue?vue&type=script&lang=ts&"
import style0 from "./KYCRefreshCorporateModal.vue?vue&type=style&index=0&id=0c4118d2&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.2_babel-core@7.0.0-bridge.0_@babel+core@7.26.0__cache-loader@4.1.0_webp_dbc1f76d1931dbdfddfdff69f826e4c8/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c4118d2",
  null
  
)

export default component.exports