import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/LaunchpadAPIHandler'

export default new APIHandler(
  API,
  ({ documentType, file }) => {
    var formData = new FormData()
    formData.append('file', file)

    return {
      method: 'POST',
      url: `/stp/docs/${documentType}`,
      data: formData,
    }
  },
  (response) => {
    return { ...response }
  }
)
