export const VERIFICATION_OUTCOMES: Record<string, VerificationOutcomes> = {
  // Resubmission outcomes
  ResubFaceNotDetected: 'ResubFaceNotDetected',
  ResubUnsupportedType: 'ResubUnsupportedType',
  ResubPoorQuality: 'ResubPoorQuality',
  ResubDocumentFrontMissing: 'ResubDocumentFrontMissing',
  ResubDocumentBackMissing: 'ResubDocumentBackMissing',
  ResubDocumentFrontNotFullyInFrame: 'ResubDocumentFrontNotFullyInFrame',
  ResubDocumentBackNotFullyInFrame: 'ResubDocumentBackNotFullyInFrame',
  ResubPoorIDQuality: 'ResubPoorIDQuality',
  ResubIDExpired: 'ResubIDExpired',
  ResubIDDamaged: 'ResubIDDamaged',
  ResubMissingInformation: 'ResubMissingInformation',
  ResubUnknownError: 'ResubUnknownError',
  // Rejected
  Rejected: 'Rejected',
  // Payment disallowed
  PaymentAlternativeMethodAvailable: 'PaymentAlternativeMethodAvailable',
  PaymentNoAlternativeMethods: 'PaymentNoAlternativeMethods',
  // Pending
  Pending: 'Pending',
  // Success
  Success: 'Success',
  Closed: 'Closed',
  EDDRequired: 'EDDRequired',
}

export type VerificationPlatform = 'Onfido' | 'Veriff'

export const VERIFICATION_PLATFORM = {
  ONFIDO: 'Onfido',
  VERIFF: 'Veriff',
}

export type VerificationOutcomes =
  | 'ResubFaceNotDetected'
  | 'ResubUnsupportedType'
  | 'ResubPoorQuality'
  | 'ResubDocumentFrontMissing'
  | 'ResubDocumentBackMissing'
  | 'ResubDocumentFrontNotFullyInFrame'
  | 'ResubDocumentBackNotFullyInFrame'
  | 'ResubPoorIDQuality'
  | 'ResubIDExpired'
  | 'ResubIDDamaged'
  | 'ResubMissingInformation'
  | 'ResubUnknownError'
  | 'Rejected'
  | 'PaymentAlternativeMethodAvailable'
  | 'PaymentNoAlternativeMethods'
  | 'Pending'
  | 'Success'
  | 'Closed'
  | 'EDDRequired'
