import { computed } from '@vue/composition-api'
import { useMediaQuery } from '@oen.web.vue2/ui'
import { useThemeStore } from '@galileo/stores'

export function hexToRGBValues(hex) {
  if (hex.length === 7 && hex.slice(0, 1) === '#') {
    return {
      red: parseInt(hex.slice(1, 3), 16),
      green: parseInt(hex.slice(3, 5), 16),
      blue: parseInt(hex.slice(5, 7), 16),
    }
  } else if (hex.length === 4 && hex.slice(0, 1) === '#') {
    return {
      red: parseInt(`${hex.slice(1, 2)}${hex.slice(1, 2)}`, 16),
      green: parseInt(`${hex.slice(1, 2)}${hex.slice(1, 2)}`, 16),
      blue: parseInt(`${hex.slice(5, 7)}${hex.slice(5, 7)}`, 16),
    }
  }
  return false
}

export function getDataByTypePrefix(source, type) {
  if (type) {
    return Object.fromEntries(
      Object.entries(source)
        .filter((data) => data[0].split('--').length === 2 && data[0].split('--')[0] === type)
        .map(([key, value]) => [key.split('--')[1], value])
    )
  } else {
    return Object.fromEntries(
      Object.entries(source).filter((data) => data[0].split('--').length === 1)
    )
  }
}

export function parseColorAsRGB(colorAttributes, colorName, prefix = '') {
  if (colorAttributes?.hex) {
    if (prefix.length > 0) {
      prefix = `--${prefix}-`
    } else {
      prefix = '--'
    }
    const RGBValues = hexToRGBValues(colorAttributes.hex)
    if (RGBValues) {
      return colorAttributes.alpha && colorAttributes.alpha !== 1
        ? `${prefix}${colorName}: rgb(${RGBValues.red} ${RGBValues.green} ${RGBValues.blue}/${colorAttributes.alpha});`
        : `${prefix}${colorName}: rgb(${RGBValues.red} ${RGBValues.green} ${RGBValues.blue});`
    }
  }
  return false
}

export function parseStyleProperty(name, value, prefix = '') {
  if (typeof value === 'string' || value instanceof String) {
    if (prefix.length > 0) {
      prefix = `--${prefix}-`
    } else {
      prefix = '--'
    }
    return `${prefix}${name}: ${value};`
  }
  return false
}

export function parseLogoData(logoData, prefix = '') {
  let variables = []
  if (prefix.length > 0) {
    prefix = `--${prefix}-`
  } else {
    prefix = '--'
  }
  if (logoData.image.url) {
    variables.push(`${prefix}logo: url(${logoData.image.url});`)
  }
  if (logoData['image-mobile'].url) {
    variables.push(`${prefix}logo-mobile: url(${logoData['image-mobile'].url});`)
  }
  if (logoData['max-width']) {
    variables.push(`${prefix}logo-max-width: ${logoData['max-width']};`)
  }
  if (logoData['max-width-mobile']) {
    variables.push(`${prefix}logo-max-width-mobile: ${logoData['max-width-mobile']};`)
  }
  if (logoData['max-height']) {
    variables.push(`${prefix}logo-max-height: ${logoData['max-height']};`)
  }
  if (logoData['max-height-mobile']) {
    variables.push(`${prefix}logo-max-height-mobile: ${logoData['max-height-mobile']};`)
  }
  return variables
}

export function parseIllustrations(illustrations, prefix = '') {
  if (prefix.length > 0) {
    prefix = `--${prefix}-`
  } else {
    prefix = '--'
  }
  return illustrations.map(
    (illustration) => `${prefix}${illustration.id}: url(${illustration.image.url});`
  )
}

export function useTheme() {
  const themeName = computed(() => useThemeStore().getThemeName())
  const themeHomepageUrl = computed(() => useThemeStore().getThemeHomepageUrl())
  const isBranded = computed(() => useThemeStore().isBranded)
  const restrictRegions = computed(() => useThemeStore().getRestrictRegions())
  const availableRegions = computed(() => useThemeStore().getAvailableRegions())
  const userInAllowedRegion = computed(() => useThemeStore().isUserInAllowedRegion())

  const mq = useMediaQuery()
  
  const logoSrc = computed(() => {
    if (useThemeStore().getLogoUrl || useThemeStore().getLogoMobileUrl) {
      return mq.current === 'xs' ? useThemeStore().getLogoMobileUrl : useThemeStore().getLogoUrl
    } else {
      return require('@galileo/assets/images/xe-logo.svg')
    }
  })

  const brandedXeLogoSrc = (useMoneyTransferLogo = false) => {
    return require('@galileo/assets/images/xe-logo.svg')
  }

  return {
    themeName,
    themeHomepageUrl,
    isBranded,
    restrictRegions,
    availableRegions,
    userInAllowedRegion,
    logoSrc,
    brandedXeLogoSrc,
  }
}
