import { computed, ref } from '@vue/composition-api'
import { defineStore } from 'pinia'
import { RegistrationForm } from '@galileo/models/Registration/Registration'
import { useSessionStore } from '@galileo/stores'

export const useRegistrationStore = defineStore(
  'registration',
  () => {
    const form = ref({} as RegistrationForm)

    const setPassword = computed((password: string) => {
      form.value.password = password
    })

    function fetchForm() {
      resetForm()
      const sessionStore = useSessionStore()
      sessionStore.setSessionId('')
    }

    function resetForm(): void {
      form.value.countryOfResidenceId = ''
      form.value.phoneDetails = null
      form.value.phoneNumber = null
      form.value.password = ''
      form.value.receiveEmailMarketing = false
      form.value.userSetMarketingPref = false
      form.value.infoForm = {}
      form.value.questions = null
    }

    return {
      fetchForm,
      resetForm,
      setPassword,
      form,
    }
  },
  {
    persist: {
      storage: sessionStorage,
    }
  }
)
