import { useHash } from '@galileo/composables/useHash'
import { computed, ref } from '@vue/composition-api'
import { setHeader } from '@galileo/api/RMTAPIHandler'
import { toApi } from '@galileo/models/Country/countryMapping'
import { defineStore } from 'pinia'
import { PendingLogin } from '@galileo/models/Session/interfaces/Session'
import { GALILEO_PLATFORM } from '@galileo/constants/platformType'
import { PRISMIC_BRAND_NAMES } from '@galileo/constants/prismic'

type SessionType = { isoCode: string | null; language: string | null } | null

export const useSessionStore = defineStore(
  'session',
  () => {
    const { hashWithDateAndBytes } = useHash()
    const sessionDeviceId = ref<string>(hashWithDateAndBytes(GALILEO_PLATFORM))
    const sessionDeviceToken = ref<string>(hashWithDateAndBytes('GalileoToken'))

    const session = ref<SessionType>({ isoCode: null, language: null })
    const sessionId = ref('')
    const token = ref('')
    const pendingLogin = ref<PendingLogin>({} as PendingLogin)
    const brand = ref<string>(PRISMIC_BRAND_NAMES.XE)
    const stpDocumentRequiredAcknowledged = ref<boolean>(false)

    const deleteSession = () => {
      sessionDeviceToken.value = hashWithDateAndBytes('GalileoToken')
    }
    const setSessionIsoCode = (isoCode: string) => {
      setHeader('IsoCode', toApi(isoCode))
    }
    const setPendingLogin = (value: PendingLogin) => {
      pendingLogin.value = value
    }
    const setSessionId = (newSessionId: string) => {
      sessionId.value = newSessionId
    }
    const setSessionDeviceToken = (newSessionDeviceToken: string) => {
      sessionDeviceToken.value = newSessionDeviceToken
    }
    const setSession = (newSession: SessionType) => {
      session.value = newSession
    }
    function setBrand(value: string): void {
      brand.value = value
    }
    function setStpDocumentRequiredAcknowledged(value: boolean): void {
      stpDocumentRequiredAcknowledged.value = value
    }

    return {
      // STATE
      sessionDeviceId,
      sessionDeviceToken,
      session,
      sessionId,
      token,
      pendingLogin,
      brand: computed(() => brand.value ?? PRISMIC_BRAND_NAMES.XE),
      stpDocumentRequiredAcknowledged,
      // ACTIONS
      deleteSession,
      setSessionIsoCode,
      setPendingLogin,
      setSessionId,
      setSessionDeviceToken,
      setSession,
      setBrand,
      setStpDocumentRequiredAcknowledged,
    }
  },
  {
    persist: {
      storage: sessionStorage,
    },
  }
)
