import XeFieldValidation from '@galileo/models/DynamicField/XeFieldValidation'

import { useI18nStore } from '@galileo/stores'

class RecipientField {
  constructor({
    id,
    label,
    displayName,
    type,
    mandatory,
    group,
    match,
    validations,
    children,
    useEnrichment,
    options,
    placeholder,
  }) {
    this.id = id
    this.label = label
    this.displayName = displayName
    this.type = type
    this.mandatory = mandatory
    this.group = group
    this.match = match
    this.validations = validations
    this.children = children
    this.useEnrichment = useEnrichment
    this.options = options
    this.placeholder = placeholder
  }
}

export default class XeRecipientField extends RecipientField {
  constructor(field) {
    const { $t } = useI18nStore()
    let options = null
    const validations = []

    if (field.mandatory) {
      validations.push({
        type: 'required',
      })
    }

    if (field.validation?.values?.length > 0) {
      field.type = 'optionList'
      const fieldId = field.id.toLowerCase()
      const fieldIdTranslatable = ['bank_account_type_code']
      options = []
      for (const element of field.validation.values) {
        const translationKey = `RecipientFieldDropdown.${field.id.toLowerCase()}.${element.id.toLowerCase()}`
        options.push({
          id: element.id,
          name: fieldIdTranslatable.includes(fieldId)
            ? $t(translationKey).value
            : element.description,
        })
      }
    } else if (field.validation?.type) {
      validations.push(new XeFieldValidation(field.validation))
    }

    let children = null
    if (field.children?.length > 0) {
      children = field.children.map((child) => {
        return new XeRecipientField(child)
      })
    }

    const translationKey = `RecipientField.${field.displayName.externalCode.toLowerCase()}`
    let label = $t(`${translationKey}.label`, { fallbackKey: field.displayName.text }).value

    //edge cases if external code is iban or account number we check specifically if it's acocunt number field or iban

    if (field.displayName.externalCode === 'IBAN_OR_ACCOUNT_NUM' && field.id === 'ACCOUNT_NUM') {
      label = $t('UseFieldRules.AccountNumberLabel').value
    }

    if (field.displayName.externalCode === 'IBAN_OR_ACCOUNT_NUM' && field.id === 'IBAN') {
      label = field.displayName.text
    }

    const placeholder = $t('RecipientField.EnterPlaceholder', { fieldName: label }).value

    field.displayName.text = label

    super({
      id: field.id,
      label,
      displayName: field.displayName,
      type: field.type,
      mandatory: field.mandatory,
      group: field.group,
      match: field.match,
      validations,
      children,
      useEnrichment: field.onChangeCanSubmitForEnrichment,
      options,
      placeholder,
    })
    // onChangeCanSubmitForEnrichment,
    // onChangeCanSubmitForValidation
  }
}

//Translations as follow:
// $t(RecipientField.account_name.label).value -> Recipient Name
// $t(RecipientField.address_line1.label).value -> Address Line 1
// $t(RecipientField.address_line2.label).value -> Address Line 2
// $t(RecipientField.address_country_code.label).value -> Address Country Code
// $t(RecipientField.bank_address_line1.label).value -> Bank Address Line 1
// $t(RecipientField.int_bank_address_country_code.label).value -> Intermediate Bank Address Country Code
// $t(RecipientField.int_bank_iban_or_account_num.label).value -> Intermediate Iban or Account Number
// $t(RecipientField.int_bank_nat_clear_code.label).value -> Intermediate Bank National Clearing Code
// $t(RecipientField.int_bank_bic.label).value -> Intermediate Bank Identity Code
// $t(RecipientField.int_bank_name.label).value -> Intermediate Bank Name
// $t(RecipientField.int_bank_address_line1.label).value -> Intermediate Bank Address Line 1
// $t(RecipientField.other_party_ref.label).value -> Other Party Reference
// $t(RecipientField.email.label).value -> Recipient Email Address
// $t(RecipientField.nickname.label).value -> Recipient Nickname
// $t(RecipientField.recipient_state.label).value -> Recipient State
// $t(RecipientField.recipient_city.label).value -> Recipient City
// $t(RecipientField.recipient_post_code.label).value -> Recipient Post Code
// $t(RecipientField.gb_nat_clear_code.label).value -> Sort Code
// $t(RecipientField.bank_name.label).value -> Bank Name
// $t(RecipientField.iban_or_account_num.label).value -> Iban or Account Number
// $t(RecipientField.bank_bic.label).value -> Bank Identity Code
// $t(RecipientField.ca_nat_clear_code.label).value -> Bank Code & Transit
// $t(RecipientField.ca_transit_institution.label).value -> CA Transit Institution
// $t(RecipientField.ca_transit_branch.label).value -> CA Transit Branch
// $t(RecipientField.industry_type.label).value -> Industry Type
// $t(RecipientField.us_nat_clear_code.label).value -> Routing Number
// $t(RecipientField.in_nat_clear_code.label).value -> IFSC
// $t(RecipientField.bank_account_type_code.label).value -> Bank Account Type
// $t(RecipientField.au_nat_clear_code.label).value -> BSB Number
// $t(RecipientField.za_nat_clear_code.label).value -> Bank National Clearing Code
// $t(RecipientField.recipient_phone.label).value -> Recipients Phone
// $t(RecipientField.bank_address_line2.label).value -> Bank Address Line 2
// $t(RecipientField.int_bank_address_line2.label).value -> Intermediate Bank Address Line 2
// $t(RecipientField.recipient_tax_number.label).value -> Recipient Tax Number
// $t(RecipientField.recipient_id_number.label).value -> Recipient Id Number
// $t(RecipientField.recipient_id_number_country.label).value -> Recipient Id Number Country
// $t(RecipientField.id_number_type_code.label).value -> Recipient Id Number Type
// $t(RecipientField.bank_branch_number.label).value -> Bank Branch Number
