import { getDoubleDigitNumbers } from '@galileo/utilities/formatters.utility.js'

export function timeInSeconds(minutes = 0, hours = 0, days = 0) {
  const s = 1
  const m = s * 60
  const h = m * 60
  const d = h * 24
  return minutes * m + hours * h + days * d
}

export function formatTimeWithTimezone(time, timezone) {
  if (timezone === 'GMT') {
    timezone = 'GMT+00:00'
  }
  if (!timezone || timezone.indexOf('GMT+') !== 0) {
    return time
  }

  timezone = timezone.replace('GMT+', '') // convert from "GMT+12:00" to "+12:00"
  let now = new Date()
  let datestr =
    now.getFullYear() +
    '-' +
    getDoubleDigitNumbers(now.getMonth() + 1) +
    '-' +
    getDoubleDigitNumbers(now.getDate())
  datestr += 'T' + time + ':00.000+' + timezone
  const date = new Date(datestr)
  const localtime =
    getDoubleDigitNumbers(date.getHours()) + ':' + getDoubleDigitNumbers(date.getMinutes())
  return localtime
}
