import Address from '@galileo/models/Address/address'
import { fromApi } from '@galileo/models/Country/countryMapping'
import { maskFormatFromApi } from '@galileo/composables/useMaskHelpers'
import { DeliveryMethod } from '@galileo/models/Transaction/app'

export default class Recipient {
  constructor(recipient) {
    this.deactivated = recipient.deactivated
    this.id = recipient.id
    this.currency = recipient.currency
    this.rawData = recipient

    this.nameVerified = recipient.nameVerified

    this.deliveryMethod = recipient.deliveryMethod

    switch (this.deliveryMethod) {
      case DeliveryMethod.BankDeposit.enumName:
        this.parseBankAccountDetails(recipient)
        break
      case DeliveryMethod.MobileWallet.enumName:
        this.parseMobileWalletDetails(recipient)
        break
    }

    this.parseAddressDetails(recipient)
    this.parseNameDetails(recipient)
    this.parseContactDetails(recipient)
    this.parseOtherDetails(recipient)
  }

  parseBankAccountDetails(recipient) {
    this.isBankAccount = true
    this.bankBicSwift = recipient.bankBicSwift
    this.bankIban = recipient.bankIban
    this.bankCountry = recipient.bankCountry
    this.bankName = recipient.bankName
    this.bankRoutingCode = recipient.bankRoutingCode
    this.bankAccountNumber = recipient.bankAccountNumber
      ? maskFormatFromApi(recipient.bankAccountNumber)
      : ''
    this.bankAddressLine1 = recipient.bankAddressLine1
    this.bankAddressLine2 = recipient.bankAddressLine2
    this.bankAddressLine3 = recipient.bankAddressLine3
    this.bankAccountTypeId = recipient.bankAccountTypeId?.toString()
    this.bankAccountType = recipient.bankAccountType
    this.bankAccountNumberUnitary = recipient.bankAccountNumberUnitary
      ? maskFormatFromApi(recipient.bankAccountNumberUnitary)
      : ''
    this.bankAccountTypeUnitary = recipient.bankAccountTypeUnitary
    this.institutionNumber = recipient.institutionNumber
    this.branchNumber = recipient.branchNumber
    this.taxNumber = recipient.taxNumber
    this.bankBranchNumber = recipient.bankBranchNumber
    this.ibanOrAccountNumber = recipient.ibanOrAccountNumber
  }

  parseMobileWalletDetails(recipient) {
    this.isMobileWallet = true
    this.mobileWalletAccountNo = recipient.mobileWalletAccountNo
    this.mobileWalletCorrespondentId = recipient.mobileWalletCorrespondentId
    this.mobileWalletLocationId = recipient.mobileWalletLocationId
    this.mobileWalletPrefix = recipient.mobileWalletPrefix
    this.relationToBeneficiaryCode = recipient.relationToBeneficiaryCode
    this.relationToBeneficiaryOther = recipient.relationToBeneficiaryOther
  }

  parseAddressDetails(recipient) {
    this.address = recipient.address
    this.addressLine2 = recipient.addressLine2
    this.recipientCity = recipient.recipientCity
    this.state = recipient.recipientState
    this.recipientState = recipient.recipientState
    this.postalCode = recipient.recipientPostCode ?? recipient.postalCode
    this.unitNumber = recipient.unitNumber
    this.addressCountry = fromApi(recipient.addressCountry)
    this.country = fromApi(recipient.country)
    this.street = ''
    if (this.address && this.address !== '-') {
      this.street += this.address
    }
    if (this.unitNumber) {
      this.street += ' ' + this.unitNumber
    }

    this.formattedAddress = new Address(
      this.street,
      this.recipientCity ?? this.addressLine2,
      this.state,
      this.postalCode,
      this.addressCountry
    )
  }

  parseNameDetails(recipient) {
    this.nickName = recipient.nickName
    this.accountName = recipient.accountName
    this.firstName = recipient.firstName
    this.middleName = recipient.middleName

    this.lastName = recipient.lastName ?? recipient.lastName1

    this.fullName = recipient.fullName || [this.firstName, this.middleName, this.lastName].join(' ')
    // if there is no middle name remove one extra space
    this.fullName = this.fullName.replace('  ', ' ')
    this.initials = recipient.initials || this.createInitials([this.firstName, this.lastName])
  }

  parseContactDetails(recipient) {
    this.emailAddress = recipient.emailAddress
    this.telephoneNumber = recipient.telephoneNumber
    this.telephoneNumberCell = recipient.telephoneNumberCell
  }

  parseOtherDetails(recipient) {
    this.riaAlertAccepted = recipient.riaAlertAccepted
    this.isBusinessAccount = recipient.isBusinessAccount
    this.isOwnAccount = recipient.isOwnAccount
    this.idDocumentTypeId = recipient.idDocumentTypeId
    this.customerRecipientRelationshipId = recipient.customerRecipientRelationshipId
    this.customerRecipientRelationshipName = recipient.customerRecipientRelationshipName
    this.unitNumber = recipient.unitNumber
    this.industryTypeId = recipient.industryTypeId
    this.recipientIdNumber = recipient.recipientIdNumber
    this.recipientIdNumberCode = recipient.recipientIdNumberCode
  }

  createInitials = (names) => {
    let initials = ''
    if (names) {
      names.forEach((name) => {
        initials += name?.charAt(0).toUpperCase()
      })
    }

    return initials
  }
}
