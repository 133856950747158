import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'
import { LoginResult } from '@galileo/api/launchpad/authorization/signin/post'
import { GALILEO_PLATFORM } from '@galileo/constants/platformType'

export default new APIHandler(
  API,
  ({ username, device, session, refreshToken, selectedProfileId }, config) => {
    // Look for device token with same loginName/username
    const deviceToken = device.tokens.find(({ loginName }) => loginName === username)

    return {
      ...config,
      method: 'POST',
      url: 'authorization/select-profile',
      data: {
        devicePlatform: GALILEO_PLATFORM,
        deviceToken: session.sessionDeviceToken ? session.sessionDeviceToken : deviceToken?.token,
        deviceId: device.id ? device.id : session.sessionDeviceId,
        refreshToken,
        selectedProfileId,
      },
    }
  },
  async (response) => {
    return {
      ...response,
      data: new LoginResult(response.data),
    }
  }
)
