export class VoltPaymentSessionResponse {
  errorCode: string | null = null
  provider: string | null = null
  data: VoltDataStructure | null = null

  constructor() {
    this.errorCode = null
    this.provider = null
    this.data = null
  }
}

export interface VoltDataStructure {
  token: string
  id: string
}