import API from '@galileo/api/rmt'
import APIHandler from '@galileo/api/APIHandler'
import UserProfile from '@galileo/models/UserProfile/app'

export default new APIHandler(
  API,
  (customerProfile, config) => {
    const request = {
      address: customerProfile.street,
      city: customerProfile.city,
      country: customerProfile.country,
      cultureCode: customerProfile.language,
      dateOfBirth: customerProfile.dateOfBirth,
      disabled: customerProfile.disabled,
      emailAddress: customerProfile.emailAddress,
      firstName: customerProfile.firstName,
      //hasTaxId
      homePhone: customerProfile.homePhone,
      id: customerProfile.id,
      //identification
      lastName: customerProfile.lastName,
      //mfaExternalId:,
      //mfaPreference,
      //middleName,
      mobilePhone: customerProfile.mobilePhone,
      //nationality
      //note
      //occupation
      //occupationId
      postalCode: customerProfile.postalCode,
      //securityQuestions
      //sendToCountry
      state: customerProfile.state,
      //status
      //unitNumber
      willReceiveEmailMarketing: customerProfile.willReceiveEmailMarketing,
      willReceiveSmsMarketing: customerProfile.willReceiveSmsMarketing,
      willReceiveSmsNotifications: customerProfile.willReceiveSmsNotifications,
    }

    return {
      ...config,
      method: 'PUT',
      url: '/customer-profile',
      data: request,
    }
  },
  (response) => {
    // TODO - Should this throw an error instead of returning response
    if (!response.data) {
      return response
    }
    return {
      ...response,
      data: new UserProfile(response.data.model),
    }
  }
)
