import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'
import City from '@galileo/models/Agent/city'
import { toApi } from '@galileo/models/Country/countryMapping'

export default new APIHandler(
  API,
  ({ country, city, state = null, controller = null }, config) => {
    const params = {
      country: toApi(country),
      cityName: '*' + city,
    }
    if (state) params['stateName'] = state

    return {
      ...config,
      signal: controller?.signal,
      method: 'GET',
      url: `Destinations/countries/${country}/cities`,
      params: params,
    }
  },
  (response) => {
    const cities = response.data.map((city) => {
      return new City({
        cityId: city.id,
        cityName: city.name,
        stateAbbrev: city.stateCode,
        state: city.stateName,
      })
    })
    cities.sort((a, b) => {
      if (a.name > b.name) {
        return 1
      } else if (a.name < b.name) {
        return -1
      }
      return 0
    })

    if (response.data) {
      return {
        ...response,
        data: cities,
      }
    } else {
      throw new Error('Failed to get cities')
    }
  }
)
