import { defineStore } from 'pinia'
import { ref } from '@vue/composition-api'
import { useAppStore, useSendMoneyStore, useAuthStore } from '@galileo/stores'
import getBalancesAccountsAPI from '@galileo/api/launchpad/balances/accounts/get'
import getBalancesTransactionsAPI from '@galileo/api/launchpad/balances/transactions/get'
import {
  BalanceAccount,
  RawBalanceAccount,
} from '@galileo/models/Balances/interfaces/BalanceAccount'
import { Currency } from '@galileo/models/Resources/interfaces/SystemFields'

export const useBalancesStore = defineStore('balances', () => {
  const tradableBalanceList = ref<Array<BalanceAccount>>([])
  const userActiveBalanceList = ref<Array<BalanceAccount>>([])
  const currenciesList = ref<Array<Currency>>([])
  const appStore = useAppStore()
  const sendMoneyStore = useSendMoneyStore()
  const authStore = useAuthStore()

  const sortBalanceList = async (balances: Array<BalanceAccount>) => {
    if (currenciesList.value.length === 0) {
      currenciesList.value = await sendMoneyStore.setAvailableCurrencies(
        authStore.userProfile?.country ?? 'US'
      )
    }

    const balanceAccounts = balances
      .map((balance: RawBalanceAccount) => {
        let description = ''
        const currency = currenciesList.value.find(
          (item: Currency) => item.value === balance.accountCurrency
        )
        if (currency) {
          description = currency.description
        }
        return {
          ...balance,
          totalBalance: balance.totalBalance,
          value: balance.accountCurrency,
          text: balance.accountCurrency,
          description: description,
        }
      })
      .sort((a: BalanceAccount, b: BalanceAccount) => b.availableBalance - a.availableBalance)

    return balanceAccounts
  }

  async function fetchTradableBalanceList(): Promise<void> {
    try {
      const balanceList = await getBalancesAccountsAPI.exec({ showAll: true })
      tradableBalanceList.value = await sortBalanceList(balanceList)
    } catch (ex) {
      appStore.logException('Unable to fetch currencies accounts', ex)
    }
  }
  async function fetchUserActiveBalanceList(): Promise<void> {
    try {
      const balanceList = await getBalancesAccountsAPI.exec({ showAll: false })
      userActiveBalanceList.value = await sortBalanceList(balanceList)
    } catch (ex) {
      appStore.logException('Unable to fetch currencies accounts', ex)
    }
  }

  async function fetchBalancesTransactions(currency: string): Promise<any> {
    try {
      const transactionsList = await getBalancesTransactionsAPI.exec(currency)
      return transactionsList
    } catch (ex) {
      appStore.logException('Unable to fetch balances transactions', ex)
    }
  }

  return {
    // state
    tradableBalanceList,
    userActiveBalanceList,
    currenciesList,
    // actions
    fetchBalancesTransactions,
    fetchTradableBalanceList,
    fetchUserActiveBalanceList,
  }
})
