import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

import { useI18nStore } from '@galileo/stores'

export default new APIHandler(
  API,
  (
    { deviceId, devicePlatform, deviceToken, newPassword, oldPassword, password, username },
    config
  ) => {
    return {
      ...config,
      method: 'POST',
      url: 'authorization/changePassword',
      data: {
        deviceId,
        devicePlatform,
        deviceToken,
        newPassword,
        oldPassword,
        password,
        username,
        preferredLanguage: useI18nStore().localeLanguagePart,
      },
    }
  },
  async (response) => {
    let savedSucessfully = false

    if (response.status === 200) {
      savedSucessfully = true
    }

    return savedSucessfully
  }
)
