/**
 * @description Geographical region the application is currently configured for, e.g. US, Spain, Australia.
 * @region Enumeration for this region.
 * @defaultCountryFrom Default value to use for send-from country, i.e. in price calculator
 * @defaultCurrencyFrom Default value to use for send-from currency, i.e. in price calculator
 * @defaultLanguage Default language culture to use, i.e. content translations
 */
export default {
  regionId: 705,
  defaultCountryFrom: 'AU',
  defaultCountryFromOnfido: 'AUS',
  defaultCountryCodeFrom: 61,
  defaultCurrencyFrom: 'AUD',
  defaultLanguageCulture: 'en-AU',
  defaultLocation: {
    latitude: -25.274398,
    longitude: 133.775136,
  },
  dateFormat: 'MM/DD/YYYY',
  postalCodeRegex: `^[0-9 -]{5,5}$`,
  phoneRegex: `^[0-9\\.\\-\\+\\(\\)\\[\\] ]{10}$`,
  support: {
    email: 'customerservice@riamoneytransfer.com',
    title: 'Support request',
  },
  links: {
    linkFaq: 'https://www.riamoneytransfer.com/us/en/mobile-content/faqs',
    linkPrivacy: 'https://www.riamoneytransfer.com/us/en/mobile-content/privacy',
    linkStoredCardAgreement:
      'https://www.riamoneytransfer.com/us/en/mobile-content/stored-card-agreement',
    linkTerms: 'https://app.riamoneytransfer.com/app-terms-and-conditions',
  },
  availableLanguageCultures: ['en-AU'],
  regionalIp: '66.82.123.234',
  requireAddress: false,
  paymentProvider: null,
  isPlaidEnabled: true,
  isBankAccountEnabled: true,
  useSecurityQuestions: false,
  oldRemittanceLogin: false,
  onfidoMaxAttempts: 0,
}
