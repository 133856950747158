









































import { PropType, inject, defineComponent } from '@vue/composition-api'
import { CorpProfileAddress } from '@galileo/models/Corporate/interfaces/CorpReg'
import USCorporateAddressForm from './USCorporateAddressForm.vue'
import CACorporateAddressForm from './CACorporateAddressForm.vue'
import UKCorporateAddressForm from './UKCorporateAddressForm.vue'
import AUCorporateAddressForm from './AUCorporateAddressForm.vue'
import NZCorporateAddressForm from './NZCorporateAddressForm.vue'
import EUCorporateAddressForm from './EUCorporateAddressForm.vue'

export default defineComponent({
  name: 'CorporateAddressFieldsSwitcher',
  components: {
    USCorporateAddressForm,
    CACorporateAddressForm,
    UKCorporateAddressForm,
    AUCorporateAddressForm,
    NZCorporateAddressForm,
    EUCorporateAddressForm
  },
  emits: ['input'],
  props: {
    address: {
      type: Object as PropType<CorpProfileAddress>,
      required: true,
    },
  },
  setup() {
    const updateField =
      inject<(format: 'freeFormat' | 'fixedFormat', field: string, value: string) => void>(
        'updateField'
      )!

    const isAddressValidHandler = inject<(isValid: boolean) => void>('isAddressValidHandler')!

    return { updateField, isAddressValidHandler }
  },
})
