import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'
import { CalculateResponse } from '@galileo/api/launchpad/quotes/v2/CalculateResponse'
import { useI18nStore } from '@galileo/stores'
import { GALILEO_PLATFORM } from '@galileo/constants/platformType'

export const SendMoneyFormToRequest = (sendMoneyForm) => {
  let request = {
    userCountry: sendMoneyForm.countryFrom,
    countryTo: sendMoneyForm.countryTo,
    amount: sendMoneyForm.shouldCalcAmountFrom ? sendMoneyForm.amountTo : sendMoneyForm.amountFrom,
    amountTo: sendMoneyForm.amountTo,
    sellCcy: sendMoneyForm.currencyFrom,
    buyCcy: sendMoneyForm.currencyTo,
    fixedCcy: sendMoneyForm.shouldCalcAmountFrom
      ? sendMoneyForm.currencyTo
      : sendMoneyForm.currencyFrom,
    screen: 'quote', // TODO
    platformType: GALILEO_PLATFORM,
    shouldCalcAmountFrom: sendMoneyForm.shouldCalcAmountFrom,
    promotionCodes: sendMoneyForm.promotionCodes,
    deliveryMethod: sendMoneyForm.deliveryMethod,
  }
  return request
}

export default new APIHandler(
  API,
  ({ sendMoneyForm, controller }, config) => {
    const request = SendMoneyFormToRequest(sendMoneyForm)

    return {
      ...config,
      signal: controller?.signal,
      method: 'POST',
      url: `v2/quotes?language=${useI18nStore().localeLanguagePart}`,
      data: request,
    }
  },
  (response) => {
    const calculateResponse = new CalculateResponse(response.data)
    return {
      ...response,
      data: calculateResponse,
    }
  }
)
