// "line1": "string",
// "line2": "string",
// "line3": "string",
// "place": "string",
// "state": "string",
// "postCode": "string",
// "countryCode": "string"

export default class AddressModel {
  line1 = ''
  line2 = ''
  line3 = ''
  place = ''
  state = ''
  postCode = ''
  countryCode = ''


  constructor()
  constructor(line1: string, line2: string, line3: string, place: string, state: string, postCode: string, countryCode: string)
  constructor(line1?: string, line2?: string, line3?: string, place?: string, state?: string, postCode?: string, countryCode?: string) {
    this.line1 = line1 ?? ""
    this.line2 = line2 ?? ""
    this.line3 = line3 ?? ""

    this.place = place ?? ""
    this.state = state ?? ""
    this.postCode = postCode ?? ""

    this.countryCode = countryCode ?? ""
  }


  hasData() {
    return (
      this.line1.length > 0 &&
      this.place.length > 0 &&
      this.state.length > 0 &&
      this.postCode.length > 0
    )
  }
}

export type addressFields = 'line1' | 'line2' | 'line3' | 'place' | 'state' | 'postCode'


export class AddressFieldsValidation {
  fieldName: addressFields = 'line1'
  regex: string = ""


  /**
   *
   */
  constructor(fieldName: addressFields, regex: string) {
    this.fieldName = fieldName
    this.regex = regex
  }
}
