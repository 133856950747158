export default class Agent {
  constructor(agent) {
    this.id = agent.id
    this.name = agent.name
    this.city = agent.city
    this.country = agent.country
    this.locationCount = agent.locationCount
    this.openPayment = agent.openPayment
    this.currency = agent.currency
    this.logo = agent.logo
    this.customerRate = agent.customerRate
  }
}
