function toLocaleDateString(inputDate) {
    try {

        if (!isValidDateString(inputDate)) {
            return inputDate;
        }

        const date = new Date(inputDate);
        const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
        const diffDays = Math.round(Math.abs((new Date() - date) / oneDay));
        if (diffDays < 7) {
            const options = { weekday: 'long' };
            return date.toLocaleDateString(undefined, options);
        } else {
            const options = { year: 'numeric', month: 'short', day: 'numeric' };
            return date.toLocaleDateString(undefined, options);
        }
    } catch (ex) {
        console.error('Error while formatting date to toLocaleDateString', ex);
    }
}

function isValidDateString(inputDateString) {
    return inputDateString && isNaN(inputDateString) && new Date(inputDateString) instanceof Date;
}

export {
    toLocaleDateString
};