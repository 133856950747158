import { defineStore } from 'pinia'
import { computed, ref } from '@vue/composition-api'

import {
  PRISMIC_DATA_TYPES,
  PRISMIC_HOST_MAPPING,
  PRISMIC_FEATURE_FLAGS,
  PRISMIC_CUSTOM_TYPES,
  PRISMIC_BRAND_NAMES,
  BRAND_URL_SEGMENT,
} from '@galileo/constants/prismic'

import {
  getDataByTypePrefix,
  parseColorAsRGB,
  parseIllustrations,
  parseLogoData,
  parseStyleProperty,
} from '@galileo/composables/useTheme'

import { useI18nStore, useEnvStore, useAppStore, useSessionStore } from '@galileo/stores'

import StringObject from '@galileo/types/StringObject'
import { Dictionary } from 'vue-router/types/router'

let themeInitResolver: any
export const themeInitPromise = new Promise((resolve) => {
  themeInitResolver = resolve
})

export const useThemeStore = defineStore('theme', () => {
  //--- STATE -----//
  const logoUrl = ref('')
  const logoMobileUrl = ref('')
  const themeHomepageUrl = ref('')
  const showCalculatorBeforeLogin = ref(false)

  const defaultCurrencyCountryIso = ref({})

  const useFavoriteCountries = ref(true)
  const favoriteCountries = ref([''])

  const themeName = ref(PRISMIC_BRAND_NAMES.BASE)
  const themePrimaryColor = ref('')
  const themeReady = ref(false)

  const restrictRegions = ref([''])
  const availableRegions = ref([''])

  const isBranded = computed(() => themeName.value !== PRISMIC_BRAND_NAMES.BASE)

  const onfidoTheme = ref({})

  const brandId = ref(8)

  //#region white-label mapping definitions
  const jlpBrandMapping: Dictionary<string> = {
    'login-jlp': PRISMIC_BRAND_NAMES.JLP,
    'login-jlp-top-secret': PRISMIC_BRAND_NAMES.JLP,
    johnlewismoney: PRISMIC_BRAND_NAMES.JLP,
    jlp: PRISMIC_BRAND_NAMES.JLP,
  }

  const britlineBrandMapping: Dictionary<string> = {
    'login-bips-top-secret': PRISMIC_BRAND_NAMES.BRITLINE,
    cabips: PRISMIC_BRAND_NAMES.BRITLINE,
  }
  //#endregion


  const isWhiteLabelUrl = computed<boolean>(() => {
    let isWhitelabel = false

    const whiteLabelEntries = Object.entries(BRAND_URL_SEGMENT)

    for (const [_, url_segment] of whiteLabelEntries) {
      const location = window.location.href
      if (location.indexOf(url_segment) !== -1) {
        isWhitelabel = true
        break
      }
    }


    if (isWhitelabel) {
      useAppStore().logIntegration('Detected white-label url. Branding will be applied')
    }

    return isWhitelabel
  })

  //#region actions
  //---- ACTIONS -----//

  async function init($prismic: any) {
    const envStore = useEnvStore()
    if (envStore.env.VUE_APP_SHOW_BRAND_DROPDOWN && envStore.isDevelopment) {
      await setPrismicDesign($prismic, themeName.value)
    }
    // let brand: Nullable<string> = PRISMIC_BRAND_NAMES.JLP
    let brand: Nullable<string> = getBrandBasedOnUrl()

    if (brand && brand != PRISMIC_BRAND_NAMES.XE) {
      await setPrismicDesign($prismic, brand)
      useSessionStore().setBrand(brand)
    } else {
      //set FavIcon
      let title: any = document.createElement('title')
      title.innerText = 'Xe Money Transfer'
      document.head.appendChild(title)
      document.title = title.innerText

      let link = document.createElement('link')
      link.rel = 'icon'
      link.href = `${window.location.origin}/icons/favicon-xe.ico`

      document.head.appendChild(link)
      //set Title
    }
    themeInitResolver()
    setThemeReady(true)
  }

  function getBrandBasedOnUrl(): Nullable<string> {
    let brand = null

    const brandMappings = { ...jlpBrandMapping, ...britlineBrandMapping }

    const currentPath = window.location.pathname
    const currentHostname = window.location.hostname

    for (const path in brandMappings) {

      if (currentPath.includes(path) || currentHostname.includes(path)) {
        brand = brandMappings[path]
        break
      }

    }

    useAppStore().logIntegration(`Brand detected based on URL: ${brand}`)

    return brand
  }

  async function resetDefaultBranding() {
    //reset title
    let title: any = document.createElement('title')
    title.innerText = ''
    document.head.appendChild(title)
    document.title = title.innerText
  }

  async function getPrismicDesigns($prismic: any) {
    try {
      const { results } = await $prismic.client.query('')
      return results.reduce((acc: any, { uids: name, data }: { uids: string; data: any }) => {
        if (name && name.length > 0) {
          acc[name.toLowerCase()] = data
        }
        return acc
      }, {})
    } catch (ex) {
      useAppStore().logException('Failed to get Prismic designs!', ex)
    }
    return ''
  }

  async function getPrismicDesignNameByHost($prismic: any, host: string) {
    const envStore = useEnvStore()

    // always use base theme for main ria hosts to avoid accidentally re-theming them
    const baseHosts = envStore.env.VUE_APP_BASE_HOSTS?.split(',')?.map((baseHost: string) =>
      baseHost.trim()
    )

    if (baseHosts?.includes(host)) {
      return PRISMIC_BRAND_NAMES.BASE
    }

    try {
      const { data } = await $prismic.client.getSingle(PRISMIC_CUSTOM_TYPES.HOST_MAPPING)

      const design = data[PRISMIC_HOST_MAPPING.BRAND].filter((brand: StringObject) => {
        return brand[PRISMIC_HOST_MAPPING.HOST] === host
      })

      if (design[0] && design[0][PRISMIC_HOST_MAPPING.DESIGN_ID]) {
        return design[0][PRISMIC_HOST_MAPPING.DESIGN_ID]
      }
    } catch (ex) {
      useAppStore().logException('Failed to get Prismic host mapping !', ex)
    }
    return PRISMIC_BRAND_NAMES.BASE
  }

  // async function setPrismicDesignByHost($prismic: any, host: string) {
  //     const themeName = await getPrismicDesignNameByHost($prismic, host)

  //     await setPrismicDesign($prismic, themeName)
  // }

  async function fetchPrismicDesign($prismic: any, design: string) {
    try {
      let designName = design
      let response = await $prismic.client.getByUID(PRISMIC_CUSTOM_TYPES.DESIGN, designName)
      if (!response) {
        response = await $prismic.client.getByUID(
          PRISMIC_CUSTOM_TYPES.DESIGN,
          PRISMIC_BRAND_NAMES.BASE
        )

        designName = PRISMIC_BRAND_NAMES.BASE
      }

      return {
        data: response.data,
        designName,
      }
    } catch (ex) {
      useAppStore().logException('Failed to get Prismic design ' + design + '!', ex)
    }
    return null
  }

  async function setPrismicDesign($prismic: any, design: string = PRISMIC_BRAND_NAMES.BASE) {
    const result = await fetchPrismicDesign($prismic, design)
    if (!result) {
      useAppStore().logException('Failed to set Prismic design ' + design)
      return
    }
    const { data, designName } = result

    document.getElementsByTagName('body')[0].className = 'branded'

    setThemeName(designName)

    setThemeHomepageUrl(data[PRISMIC_DATA_TYPES.URL])

    const variables = getDataByTypePrefix(data, PRISMIC_DATA_TYPES.VARIABLES)
    //TODO add those variables to prismic
    if (design === PRISMIC_BRAND_NAMES.JLP) {
      variables['background'] = [{ hex: '#F6F6F6', alpha: null }]
    } else if (design === PRISMIC_BRAND_NAMES.BRITLINE) {
      variables['background'] = [{ hex: '#ffffff', alpha: null }]
      variables['card-background'] = [{ hex: '#ffffff', alpha: null }]
    } else {
      variables['background'] = [{ hex: '#F6F6F6', alpha: null }]
    }

    setThemeStyles(variables, 'prismic-styles')

    setFeatureFlags({ features: getDataByTypePrefix(data, PRISMIC_DATA_TYPES.FEATURE_FLAGS) })
  }

  function setThemeStyles(variables: { [name: string]: any }, id = 'theme-styles') {
    //#region translations
    const translations = Object.entries(variables).filter(([name, _]) =>
      name.startsWith('lokalise')
    )
    let parsedTranslation: { [key: string]: any } = {}

    //parse translations
    translations.forEach((translation) => {
      let key = translation[0].slice(9)
      key = key.replace('-', '.')
      parsedTranslation[key] = translation[1]
    })

    useI18nStore().setBrandSpecificTranslations(parsedTranslation)
    //#endregion

    //get entries that are not related to translations
    let entries = Object.entries(variables).filter(([name, _]) => !name.startsWith('lokalise'))

    //#region helpdesk links
    let helpdeskEntries = entries.filter(([name, _]) => name.startsWith('helpdesk'))

    let parsedHelpDeskEntries: StringObject = {}

    helpdeskEntries.forEach(([name, value]) => {
      name = name.slice(9)
      parsedHelpDeskEntries[name] = value
    })

    useAppStore().setBrandedHelpDeskLinks(parsedHelpDeskEntries)
    //#endregion

    entries = entries.filter(([name, _]) => !name.startsWith('helpdesk'))

    //get entries related to languages
    let languages = entries.filter(([name, _]) => name.startsWith('languages'))

    const availableLanguagesValue = languages.find(([name, _]) => name === 'languages-available')
    const testingLanguagesValue = languages.find(([name, _]) => name === 'languages-testing')

    if (availableLanguagesValue) {
      const availableLanguages: string[] = availableLanguagesValue[1]
        ?.split(',')
        .map((lang: string) => lang.trim())
      useI18nStore().setAvailableLanguages(availableLanguages)
    }
    if (testingLanguagesValue) {
      const testingLanguages: string[] = testingLanguagesValue[1]
        ?.split(',')
        .map((lang: string) => lang.trim())
      useI18nStore().setTestingLanguages(testingLanguages)
    }

    entries = entries.filter(([name, _]) => !name.startsWith('languages'))

    // Set logo URL
    const [logoData] = variables.logo
    const logoUrl = logoData.image.url
    setLogoUrl(logoUrl)

    const prismicFavIcons = variables?.favicons

    //set favicons
    const favIcons = prismicFavIcons[0]
    if (favIcons) {
      setFavIcon(favIcons)
    }

    //set website title
    if (variables['theme-website-title']) {
      const wbesiteTitle = variables['theme-website-title']
      let title: any = document.createElement('title')
      title.innerText = wbesiteTitle
      document.head.appendChild(title)
      document.title = title.innerText
    }

    if (variables['font']) {
      const fontLink = variables['font']?.url
      const fontFamily = variables['font-family']

      document.getElementsByTagName('body')[0].style.fontFamily = fontFamily

      const font = document.createElement('link')
      font.href = fontLink
      font.rel = 'stylesheet'
      document.getElementsByTagName('head')[0].appendChild(font)
    }

    if (variables['brand-id']) {
      const prismicBrandId = variables['brand-id']
      brandId.value = prismicBrandId
    } else {
      console.error('No brandId found for this theme!')
    }

    // Set mobile logo URL
    // Use logo URL as fallback, when not found
    setLogoMobileUrl(logoData['image-mobile'].url || logoUrl)

    // Prefix used for styles
    const prefix = 'theme'
    const hexColor = variables['color-primary'] ? variables['color-primary'][0].hex : ''
    setThemePrimaryColor(hexColor)

    // Add theme style colors
    const styles = entries
      .filter(([name, value]) => value && value[0] && parseColorAsRGB(value[0], name))
      .map(([name, value]) => parseColorAsRGB(value[0], name, prefix))

    const filtered = entries.filter(([name, value]) => parseStyleProperty(name, value))

    const styleProperties = filtered.map(([name, value]) => parseStyleProperty(name, value, prefix))

    styles.push(...parseIllustrations(variables['illustrations'], prefix))
    styles.push(...parseLogoData(logoData, prefix))
    styles.push(...styleProperties)

    // generate stylesheet css from styles array
    const css = `:root { ${styles.join('')} }`
    // Create stylesheet html element
    let style: HTMLElement | any = document.getElementById(id)
    if (style) {
      style.innerHTML = ''
    } else {
      style = document.createElement('style')
      style.type = 'text/css'
      style.id = 'prismic-styles'
    }
    // affix stylesheet to document head
    if (style.styleSheet) {
      // This is required for IE8 and below.
      style.styleSheet.cssText = css
    } else {
      style.appendChild(document.createTextNode(css))
    }

    const head = document.head || document.getElementsByTagName('head')[0]
    head.appendChild(style)

    setOnfidoTheme({
      colorBackgroundButtonPrimary: variables['color-primary'][0].hex,
      colorBackgroundButtonPrimaryHover: variables['color-primary-button-hover'][0].hex,
      colorBorderButtonPrimary: '#ffffff00',
      borderRadiusButton: variables['button-border-radius'],
      fontFamilyTitle: variables['font-family'],
      fontFamilySubtitle: variables['font-family'],
      fontFamilyBody: variables['font-family'],
      borderRadiusSurfaceModal: variables['card-border-radius'],
      colorBorderLinkUnderline: variables['color-primary-button-hover'][0].hex,
      colorBackgroundLinkHover: variables['color-primary-button-hover'][0].hex,
      colorBackgroundInfoPill: variables['color-primary'][0].hex,
      colorBackgroundIcon: variables['color-primary-button-pressed'][0].hex,
      colorBorderButtonSecondary: variables['color-primary'][0].hex,
      colorContentButtonSecondaryText: variables['color-primary'][0].hex,
    })
  }

  //TODO: Refactor
  function setFavIcon(favIcons: any) {
    let icons: { [key: string]: any } = {}
    Object.entries(favIcons).forEach(([key, value]) => {
      icons[key] = value
    })

    var links: any = document.querySelectorAll("link[rel~='icon']")
    links.forEach((link: HTMLElement) => link.remove())

    if (icons) {
      let first = true
      Object.entries(icons).forEach(([name, value]) => {
        if (name.startsWith('favicon') && value) {
          if (first) {
            let link = document.createElement('link')
            link.rel = 'icon'
            document.head.appendChild(link)
            link.href = value.url
            first = false
          }
          let link = document.createElement('link')
          link.rel = 'icon'
          document.head.appendChild(link)
          link.setAttribute('sizes', `${value.dimensions.height}x${value.dimensions.width}`)
          link.href = value.url
        }
      })
    }
  }

  function setOnfidoTheme(_onfidoTheme: object) {
    onfidoTheme.value = _onfidoTheme
  }

  function setFeatureFlags(features: { [name: string]: any }) {
    setRestrictRegions(features[PRISMIC_FEATURE_FLAGS.RESTRICT_REGION])
    setShowCalculatorBeforeLogin(features[PRISMIC_FEATURE_FLAGS.SHOW_CALCULATOR])
    setDefaultCurrencyCountryIso(features[PRISMIC_FEATURE_FLAGS.DEFAULT_COUNTRY_CURRENCY_ISO])
    setUseFavoriteCountries(features[PRISMIC_FEATURE_FLAGS.USE_FAVORITE_COUNTRIES])

    const availableRegions = features[PRISMIC_FEATURE_FLAGS.AVAILABLE_REGION]?.map(
      (region: any) => region.country
    )
    setAvailableRegions(availableRegions)

    setFavoriteCountries(features[PRISMIC_FEATURE_FLAGS.FAVORITE_COUNTRIES])
  }

  //#endregion

  //#region GETTERS
  //----- getters ----- //

  function getThemeHomepageUrl(): string {
    if (isBranded.value) {
      return themeHomepageUrl.value
    }
    return useAppStore().xeAppUrl
  }

  //#endregion

  //#region SETTERS
  ///-------- SETTERS ----- ///

  function setThemeName(prismicThemeName: string) {
    themeName.value = prismicThemeName
  }

  function setThemeHomepageUrl(ThemeHomepageUrl: string) {
    themeHomepageUrl.value = ThemeHomepageUrl
  }
  function setLogoUrl(url: string) {
    logoUrl.value = url
  }
  function setLogoMobileUrl(url: string) {
    logoMobileUrl.value = url
  }
  function setRestrictRegions(_restrictRegions: string[]) {
    restrictRegions.value = _restrictRegions
  }
  function setAvailableRegions(_availableRegions: string[]) {
    availableRegions.value = _availableRegions
  }
  function setShowCalculatorBeforeLogin(_showCalculatorBeforeLogin: boolean) {
    showCalculatorBeforeLogin.value = _showCalculatorBeforeLogin
  }
  function setDefaultCurrencyCountryIso(isoCode: string) {
    defaultCurrencyCountryIso.value = isoCode
  }
  function setUseFavoriteCountries(_useFavoriteCountries: boolean) {
    useFavoriteCountries.value = _useFavoriteCountries
  }
  function setFavoriteCountries(_favoriteCountries: string[]) {
    favoriteCountries.value = _favoriteCountries
  }
  function setThemeReady(_themeReady: boolean) {
    themeReady.value = _themeReady
  }
  function setThemePrimaryColor(_themePrimaryColor: string) {
    themePrimaryColor.value = _themePrimaryColor
  }
  //#endregion

  return {
    init,
    setThemePrimaryColor,
    setThemeReady,
    setFavoriteCountries,
    setUseFavoriteCountries,
    setDefaultCurrencyCountryIso,
    setShowCalculatorBeforeLogin,
    setAvailableRegions,
    setRestrictRegions,
    setLogoMobileUrl,
    setLogoUrl,
    setThemeHomepageUrl,
    setThemeName,
    setThemeStyles,
    setPrismicDesign,
    getPrismicDesignNameByHost,
    getPrismicDesigns,
    getThemeHomepageUrl,
    resetDefaultBranding,

    getThemeReady: computed(() => themeReady.value),
    getThemePrimaryColor: computed(() => themePrimaryColor.value),
    getFavoriteCountries: computed(() => favoriteCountries.value),
    getUseFavoriteCountries: computed(() => useFavoriteCountries.value),
    getDefaultCurrencyCountryIso: computed(() => defaultCurrencyCountryIso.value),
    getAvailableRegions: computed(() => availableRegions.value),
    getRestrictRegions: computed(() => restrictRegions.value),
    isBranded: computed(() => isBranded.value),
    getLogoMobileUrl: computed(() => logoMobileUrl.value),
    getLogoUrl: computed(() => logoUrl.value),
    getThemeName: computed(() => themeName.value),
    getOnfidoTheme: computed(() => onfidoTheme.value),
    brandId: computed(() => brandId.value),
    isWhiteLabelUrl,
  }
})
