import { defineStore, storeToRefs } from 'pinia'
import { computed, ref } from '@vue/composition-api'

import getEmailMarketingConsent from '@galileo/api/launchpad/profiles/_profileId/marketingPreferences/get'
import getHomePageMessage from '@galileo/api/launchpad/profiles/_profileId/homepagemessage/get'
import getTagInformation from '@galileo/api/launchpad/profiles/tagInformation/get'

import updateUserInformation from '@galileo/api/launchpad/profiles/updateUserInformation/put'
import updateEmailMarketingConsent from '@galileo/api/launchpad/profiles/_profileId/marketingPreferences/put'
import updateUserProfileInformation from '@galileo/api/rmt/customer-profile/put'
import switchToLegacyPlatformApi from '@galileo/api/launchpad/profiles/_profileId/legacyPlatformSwitch/put'

import confirmResourceUpdateApi from '@galileo/api/ct/account/confirmResourceUpdate/post'
import postTransfer from '@galileo/api/launchpad/transfer/post'

import changeLanguage from '@galileo/api/launchpad/profiles/changeLanguage/patch'

import CustomError from '@galileo/composables/useCustomError'
import UserPersonalInformation from '@galileo/models/UserProfile/UserPersonalInformation'
import patchUserProfile from '@galileo/api/launchpad/profiles/_profileId/patch'
import { useHash } from '@galileo/composables/useHash'

import lookupPhoneNumberApi from '@galileo/api/launchpad/profiles/lookupPhoneNumber/get'
import { useAlert } from '@oen.web.vue2/ui'
import {
  useCorporateStore,
  useAppStore,
  usePageStore,
  useAnalyticsStore,
  useAuthStore,
  useI18nStore,
} from '@galileo/stores'

const { hashSha1 } = useHash()

import { HomePageMessageType } from '@galileo/models/HomePageMessageType'
// Interfaces imports
import {
  User,
  Language,
  UserProfile,
  UserUpdateError,
  MarketingConsent,
  TransactionGTMData,
  FieldDefinition,
} from '@galileo/models/Profile/interfaces/User'
import { MessageObject } from '@galileo/models/Page/interfaces'

import format from 'date-fns/format'

export const useProfileStore = defineStore('profile', () => {
  // States start
  const profileUpdateErrors = ref<Array<UserUpdateError>>([])
  const promptForMarketingConsent = ref<boolean>(false)
  const ignorePromptForMarketingConsent = ref<boolean>(false)
  const homePageMessage = ref<MessageObject | string>('')
  const gtmData = ref<TransactionGTMData>({} as TransactionGTMData)
  const flagProfilePhoneNumber = ref<boolean>(false)
  const newPhoneNumber = ref<number>(0)
  const resourceInformationPhone = ref<number>(0)
  const firstNameRegex = ref<string>('')
  const middleNameRegex = ref<string>('')
  const lastNameRegex = ref<string>('')
  const showSwitchToFxWebModal = ref<boolean>(false)
  const hasNoTransactions = ref<boolean>(false)
  const shouldCheckStatus = ref<boolean>(true)
  // States end

  const authStore = useAuthStore()
  const analyticsStore = useAnalyticsStore()

  // Getters start
  const getFirstNameRegex = computed(() => {
    const defaultRegex = new RegExp(
      //@ts-ignore
      /^[\p{L}'\u2018\u2019][ \p{L}'\u2018\u2019-]{0,48}[\p{L}]$/,
      'u'
    )
    if (firstNameRegex.value) {
      return new RegExp(firstNameRegex.value, 'u')
    }
    return defaultRegex
  })

  const getMiddleNameRegex = computed(() => {
    const defaultRegex = new RegExp(
      //@ts-ignore
      /^[\p{L}'\u2018\u2019][ \p{L}'\u2018\u2019-]{0,48}[\p{L}]$/,
      'u'
    )
    if (middleNameRegex.value) {
      return new RegExp(middleNameRegex.value, 'u')
    }
    return defaultRegex
  })

  const getLastNameRegex = computed(() => {
    const defaultRegex = new RegExp(
      //@ts-ignore
      /^[\p{L}'\u2018\u2019][ \p{L}'\u2018\u2019-]{0,48}[\p{L}]$/,
      'u'
    )
    if (lastNameRegex.value) {
      return new RegExp(lastNameRegex.value, 'u')
    }
    return defaultRegex
  })
  // Getters end

  // Actions start
  async function updateUserInfo(user: User): Promise<boolean | void | CustomError> {
    const appStore = useAppStore()
    let payload
    // if `user` is an instance of UserPersonalInformation and has
    // the createPayload method...
    if (user.createPayload) {
      payload = user.createPayload()
    }
    // but if it's a plain object...
    else {
      payload = UserPersonalInformation.createPayload(user)
    }

    try {
      let { savedSuccessfully, data } = await updateUserInformation.exec(payload)

      if (data.errors) {
        profileUpdateErrors.value = data.errors

        const isIdType = data.errors.find((error: UserUpdateError) => {
          return (
            error.id === 'idType' ||
            error.id === 'licenceNumber' ||
            error.id === 'licenceVersion' ||
            error.id === 'licenceCardNumber' ||
            error.id === 'licenceIssuer'
          )
        })

        if (isIdType) {
          const error = new CustomError('idType field is missing', 'idType')
          var responseWithError = {
            data: data.data.kycRequirements,
            error: error
          }
          return Promise.reject(responseWithError)
        }
      }
      return savedSuccessfully
    } catch (e) {
      appStore.logException('Unable to update user informations', e)
    }
  }

  async function updateOccupation(occupation: string): Promise<boolean | void> {
    const appStore = useAppStore()
    let user = authStore.user
    const profileId = user?.customer?.id

    const payload = {
      occupation,
    }

    try {
      const result = patchUserProfile.exec({ payload: payload, profileId: profileId })

      return result
    } catch (e) {
      appStore.logException('Unable to change occupation', e)
    }
  }
  async function changePreferredLanguage({ language }: Language): Promise<void> {
    const appStore = useAppStore()
    let user = authStore.user
    const data = {
      profileId: user?.customer.id,
      preferredLanguage: language,
    }

    try {
      await changeLanguage.exec(data)
    } catch (e) {
      appStore.logException('Unable to change preferred language', e)
    }
  }

  // switchToLegacyPlatform forbidden
  async function switchToLegacyPlatform(): Promise<void> {
    let user = authStore.user
    let profileId = user?.customer.id

    try {
      let result = await switchToLegacyPlatformApi.exec({ profileId: profileId })
      if (result) {
        // we should redirect the user to fxWeb
        authStore.redirectToFxWebAndRefreshSession()
      }
    } catch (e) {
      useAppStore().logException('Unable to change platform', e)
    }
  }

  async function updateKYCLastRefresh(): Promise<void> {
    const appStore = useAppStore()
    const user = authStore.user
    const profileId = user?.customer.id

    const payload = {
      lastKycRefreshDate: format(new Date(), 'yyyy-MM-dd'),
    }

    try {
      await patchUserProfile.exec({ payload, profileId })
    } catch (e) {
      appStore.logException('Unable to change preferred language', e)
    }
  }

  async function updateUserProfile(userProfile: UserProfile): Promise<boolean> {
    const { data } = await updateUserProfileInformation.exec(userProfile)
    if (data) {
      authStore.updateUserProfile(data)
      // authStore.userProfile = data
      return true
    } else {
      throw new Error('Update user info failed!')
    }
  }

  // confirmResourceUpdate - is it used?
  async function confirmResourceUpdate(): Promise<boolean> {
    const data = resourceInformationPhone.value
    if (data) {
      const resultResourceUpdate = await confirmResourceUpdateApi.exec(data)
      if (resultResourceUpdate) {
        return true
      } else {
        throw new Error('Update user resource info failed')
      }
    } else {
      return false
    }
  }

  async function getMarketingConsent(): Promise<MarketingConsent> {
    const appStore = useAppStore()
    const corporateStore = useCorporateStore()
    const { isCorporateAccount } = storeToRefs(authStore)

    if (isCorporateAccount.value) {
      return await corporateStore.fetchMarketingPreferenceConsent()
    }

    const user = authStore.user
    const profileId = user?.customer?.id

    try {
      const { data } = await getEmailMarketingConsent.exec({ profileId })

      return data
    } catch (ex) {
      appStore.logException("Can't retrieve marketing consent from API.", ex)
      throw ex
    }
  }

  async function updateMarketingConsent(consent: any): Promise<boolean | void> {
    try {
      const corporateStore = useCorporateStore()
      const { isCorporateAccount } = storeToRefs(authStore)

      if (isCorporateAccount.value) {
        await corporateStore.updateMarketingPreferences(consent.email)
        await getMarketingConsent()
        return
      }

      const user = authStore.user
      const profileId = user?.customer?.id

      try {
        //keep the push as it is stored
        const existingConsent = await getMarketingConsent()
        consent.push = existingConsent.push
      } catch (ex) {
        //there is no existingConsent so we default the push to false.
        consent.push = false
      }

      const response = await updateEmailMarketingConsent.exec({
        promptForMarketingConsent: consent.promptForMarketingConsent,
        email: consent.email,
        push: consent.push,
        profileId,
      })

      if (response.status === 200) {
        promptForMarketingConsent.value = consent.promptForMarketingConsent
        await authStore.getUserAction(profileId)
        return true
      } else {
        throw new Error('Update marketing consent info failed')
      }
    } catch (ex) {
      const { add: showSnackAlert } = useAlert()
      showSnackAlert(useI18nStore().$t('MarketingConsent.UnableToUpdateAtThisTime').value)
      ignorePromptForMarketingConsent.value = true
    }
  }

  function findAdditionalValuesFromBEResponse(child: any, parentArray: any[]): any | null {
    for (const parent of parentArray) {
      if (parent.children && parent.children.length > 0) {
        for (const currentChild of parent.children) {
          if (currentChild.id === child) {
            return currentChild
          }
        }
      }
    }
    return null
  }

  async function loadHomePageMessage(): Promise<void> {
    const pageStore = usePageStore()
    const user = authStore.user
    const profileId = user.customer.id
    let message = null


    switch (user.customer.documentStatus) {
      case 'DocumentsAreRequired':
        message = HomePageMessageType.DocumentRequired
        break
      case 'DocumentsBeingReviewed':
        message = HomePageMessageType.DocumentInReview
        break
      default: {
        let daysLeft = 1

        const response = await getHomePageMessage.exec(profileId)

        if (response.data) {
          message = response.data.message
          daysLeft = response.data.noOfDaysToHideBeforeReshow
        }


        if (!message || message === '') {
          break
        }

        const data = {
          messageHash: hashSha1(message),
          dismissed: false,
          date: new Date().getTime(),
          daysLeft,
        }

        let homePageMessageData = pageStore.getHomePageMessageData(profileId)

        const shouldShow = (data.date - homePageMessageData.date) / (1000 * 3600 * 24) > daysLeft

        if (shouldShow || !homePageMessageData?.messageHash !== data.messageHash) {
          homePageMessageData = data
        } else if (homePageMessageData.dismissed) {
          message = null
        }
        pageStore.setHomePageMessageData({ profileId, homePageMessageData })
      }
    }
    homePageMessage.value = message
  }

  async function getTransactionGTMData(): Promise<TransactionGTMData> {
    const user = authStore.user
    const profileId = user?.customer?.id

    const { data } = await getTagInformation.exec({ profileId })

    if (data) {
      gtmData.value = data
      analyticsStore.gtmTrackVariables({
        variables: {
          ...data,
        },
      })
      analyticsStore.gtmTrack({
        event: 'Event_Dealing_Created',
      })
      return data
    } else {
      throw new Error('No data returned from API')
    }
  }

  async function getNameRegexes(): Promise<void> {
    const appStore = useAppStore()
    const user = authStore.user

    try {
      const userCountry = user?.country
      const userProfileId = user?.customer?.id

      let { data } = await postTransfer.exec({
        profileId: userProfileId,
        country: userCountry,
        isMinimalRequest: true,
      })

      const nameFields = data.fieldDefinitions.find(
        (field: FieldDefinition) => field.id === 'fullName'
      )
      const firstNameRegexField = nameFields.children.find(
        (field: FieldDefinition) => field.id === 'firstName'
      )?.validation.regex
      const middleNameRegexField = nameFields.children.find(
        (field: FieldDefinition) => field.id === 'middleName'
      )?.validation.regex
      const lastNameRegexField = nameFields.children.find(
        (field: FieldDefinition) => field.id === 'lastName'
      )?.validation.regex

      if (firstNameRegexField) firstNameRegex.value = firstNameRegexField
      if (middleNameRegexField) middleNameRegex.value = middleNameRegexField
      if (lastNameRegexField) lastNameRegex.value = lastNameRegexField
    } catch (ex) {
      appStore.logException('Unable to get name regexes.', ex)
    }
  }

  //account/profile/edit-phone-modal - is it used

  function resetForm() {
    flagProfilePhoneNumber.value = false
    // resourceInformationPhone.value = null
    newPhoneNumber.value = 0
  }

  function setMarketingConsent(value: boolean): void {
    promptForMarketingConsent.value = value
  }

  async function validatePhoneNumber(value: string): Promise<any> {
    const response = await lookupPhoneNumberApi.exec(value)
    return response
  }
  // Actions end

  return {
    getFirstNameRegex,
    getMiddleNameRegex,
    getLastNameRegex,
    newPhoneNumber,
    showSwitchToFxWebModal,
    shouldCheckStatus,
    hasNoTransactions,
    homePageMessage,
    updateUserInfo,
    updateOccupation,
    changePreferredLanguage,
    switchToLegacyPlatform,
    updateKYCLastRefresh,
    updateUserProfile,
    getMarketingConsent,
    resourceInformationPhone,
    confirmResourceUpdate,
    updateMarketingConsent,
    loadHomePageMessage,
    getTransactionGTMData,
    getNameRegexes,
    resetForm,
    setMarketingConsent,
    promptForMarketingConsent: computed(() => promptForMarketingConsent.value),
    profileUpdateErrors: computed(() => profileUpdateErrors.value),
    findAdditionalValuesFromBEResponse,
    validatePhoneNumber,
    ignorePromptForMarketingConsent: computed(() => ignorePromptForMarketingConsent.value),
  }
})
