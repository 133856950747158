<template>
  <AppDialog :value="value">
    <template #header>
      <AppDialogHeader>
        <h1>🍪 {{ $t('XeConsentManager.TitleText').value }}</h1>
      </AppDialogHeader>
    </template>

    <p>
      {{ $t('XeConsentManager.DescriptionText').value }}

      <AppLink :href="href" target="cookieNotice">
        {{ $t('XeConsentManager.ButtonCookieNotice').value }}
      </AppLink>
    </p>

    <template #footer>
      <AppDialogFooter>
        <AppButton
          type="button"
          theme="text-secondary"
          analytics-name="consent-manager-essential-cookies"
          class="button-black"
          @click="allowEssentialCookies"
        >
          {{ $t('XeConsentManager.ButtonRejectNonEssentialCookies').value }}
        </AppButton>
        <AppButton
          type="button"
          analytics-name="consent-manager-all-cookies"
          class="button-green"
          @click="allowAllCookies"
        >
          {{ $t('XeConsentManager.ButtonAllowAllCookies').value }}
        </AppButton>
      </AppDialogFooter>
    </template>
  </AppDialog>
</template>

<script>


import { AppDialog, AppDialogHeader, AppDialogFooter, AppButton, AppLink } from '@oen.web.vue2/ui'

import { useI18nStore, useAnalyticsStore, useDeviceStore } from '@galileo/stores'


export default {
  name: 'XeConsentManagerModal',
  components: {
    AppDialog,
    AppDialogFooter,
    AppButton,
    AppDialogHeader,
    AppLink,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { $t } = useI18nStore()
    const deviceStore = useDeviceStore()
    const analyticsStore = useAnalyticsStore()

    const locale = deviceStore.getLocale
    const href = `https://app.riamoneytransfer.com/${locale}/cookies`

    const allowEssentialCookies = () => {
      analyticsStore.setTrackingConsentEssential()
    }
    const allowAllCookies = () => {
      analyticsStore.setTrackingConsentAll()
    }

    return {
      $t,
      href,
      allowAllCookies,
      allowEssentialCookies,
    }
  },
}
</script>

<style scoped>
.button-black {
  @apply text-primary-text !important;
}

.button-green {
  @apply bg-green-text !important;
}
</style>
