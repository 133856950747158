import FieldOption from '@galileo/models/DynamicField/FieldOption'

export default class CstFieldOption extends FieldOption {
  constructor(option) {
    super({
      id: option.Id,
      imageId: option.ImageId,
      imageType: option.ImageType,
      name: option.Name,
    })
  }
}
