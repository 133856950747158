<template>
  <AppModal v-model="model" :title="$t('EditPhoneModal.Title').value">
    <template #header="{ dismiss }">
      <AppCardHeader>
        <h1>{{ $t('EditPhoneModal.Title').value }}</h1>
        <template #right>
          <XeBackButton analytics-name="change-phone-close" icon="x" @click="dismiss" />
        </template>
      </AppCardHeader>
    </template>

    <template #default>
      <form id="phone-form" @submit.prevent>
        <AppInputPhone
          ref="appInputPhoneRef"
          v-model="phoneForm.phone"
          analytics-name="edit-phone-modal-input"
          :label="$t('EditPhoneModal.LabelPhoneNumber').value"
          :options="phoneCountries"
          :validation="validation.phone"
          :show-selected-flag="true"
          @areaCodeInputOption="onAreaCodeInputOption"
          @updateInputValues="onUpdateInputValues"
          should-reset-filter-on-focus
        />
        <p v-if="showValidationErrorMessage" class="input-validation">
          {{ phoneValidationMessage }}
        </p>
      </form>
    </template>

    <template #footer>
      <AppModalFooter>
        <AppButton
          type="submit"
          form="phone-form"
          analytics-name="change-phone-update-button"
          :loading="isSaving"
          :disabled="validation.$anyInvalid"
          @click="updatePhone"
        >
          {{ $t('EditPhoneModal.UpdateButton').value }}
        </AppButton>
      </AppModalFooter>
    </template>
  </AppModal>
</template>

<script>
import { computed, reactive, ref, toRef } from '@vue/composition-api'
import { useValidation } from 'vue-composable'

import { integer, required } from '@vuelidate/validators'
import { storeToRefs } from 'pinia'

import { parsePhoneNumberFromString, parsePhoneNumber } from 'libphonenumber-js'

import XeBackButton from '@galileo/components/XeBackButton/XeBackButton'
import { API_RESPONSE_CODES } from '@galileo/constants/apiResponseCodes.const'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'
import { useRouter } from '@galileo/composables/useRouter'

import {
  useVModel,
  useAlert,
  AppModal,
  AppModalFooter,
  AppButton,
  AppCardHeader,
  AppInputPhone,
} from '@oen.web.vue2/ui'

import {
  useProfileStore,
  useResourcesStore,
  useAppStore,
  useI18nStore,
  useAuthStore,
  useAnalyticsStore,
} from '@galileo/stores'

export default {
  name: 'EditPhoneModal',
  components: {
    AppModal,
    AppModalFooter,
    AppButton,
    AppCardHeader,
    AppInputPhone,
    XeBackButton,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    onSave: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { model } = useVModel(props, emit)
    const { $t } = useI18nStore()
    const { add } = useAlert()
    const resourcesStore = useResourcesStore()
    const authStore = useAuthStore()
    const appStore = useAppStore()
    const profileStore = useProfileStore()
    const analyticsStore = useAnalyticsStore()
    const router = useRouter()

    const { profileUpdateErrors } = storeToRefs(profileStore)
    const mobilePhone = props.user.mobilePhone ? props.user.mobilePhone : {}
    const phoneValidationMessage = ref('')
    const showValidationErrorMessage = ref(false)
    const isValidPhoneNumberCheck = ref(true)
    let countryIso = ''
    let initialPhoneNo = mobilePhone.number

    const formatPhoneNumber = (phoneNumber) => {
      const parsedPhoneNumber = parsePhoneNumber(phoneNumber)
      let formattedPhoneNumber = parsedPhoneNumber.formatInternational()

      return {
        countryCode: parsedPhoneNumber.country,
        formatInternational: parsedPhoneNumber.number,
      }
    }
    const phoneForm = reactive({
      phone: formatPhoneNumber(initialPhoneNo).formatInternational,
      countryCode: '+' + mobilePhone.countryCode,
      country: formatPhoneNumber(initialPhoneNo).countryCode,
    })

    const phoneCountries = computed(() => {
      const countries = resourcesStore.getPhoneCountries
      const index = countries.findIndex((country) => country.valueKey === phoneForm.country)

      if (index > -1) {
        const [country] = countries.splice(index, 1)
        countries.unshift(country)
      }

      return countries
    })

    const isSaving = ref(false)

    const validation = useValidation({
      phone: {
        $value: toRef(phoneForm, 'phone'),
        required: {
          $validator: required.$validator,
          $message: $t('EditPhoneModal.ValidationPhoneRequired').value,
        },
        isValidPhoneNumber: {
          $validator(v) {
            if (v && !integer.$validator(v.replace('+', ''))) {
              return false
            }
            isValidPhoneNumberCheck.value = false
            showValidationErrorMessage.value = false
            const parsePhoneNumberDetails = parsePhoneNumberFromString(v, countryIso)
            if (parsePhoneNumberDetails) {
              isValidPhoneNumberCheck.value = parsePhoneNumberDetails.isValid()
              return parsePhoneNumberDetails.isValid()
            }
            return false
          },
          $message: $t('ComponentEditPhoneModal.ValidationPhoneValid').value,
        },
        isPhoneNumberFound: {
          async $validator(v, context) {
            showValidationErrorMessage.value = false
            if (!isValidPhoneNumberCheck.value) return false

            const response = await profileStore.validatePhoneNumber(v)

            if (response.status === API_RESPONSE_CODES.OK) {
              return true
            }
            if (
              response.status === API_RESPONSE_CODES.NOT_ACCEPTABLE ||
              response.status === API_RESPONSE_CODES.NOT_FOUND
            ) {
              analyticsStore.track({
                event: SEGMENT_EVENTS.NON_SUPPORTED_PHONE_NUMBER_TYPE_ENTERED,
                traits: {
                  location: router.currentRoute.path === '/activity' ? 'kyc-refresh' : 'settings',
                  phoneNumberType: response.data.data.type,
                  phoneNumberCountryPrefix: phoneForm.countryCode,
                  phoneNumberCountryName: phoneForm.country,
                  email: authStore.user.customer.email,
                },
              })
              phoneValidationMessage.value = $t('EditPhoneModal.ValidatePhoneNumberNotFound').value
            } else if (response.status === API_RESPONSE_CODES.INTERNAL_SERVER_ERROR) {
              phoneValidationMessage.value = $t('EditPersonalInfoModal.ErrorOccuredOnSaving').value
            }
            showValidationErrorMessage.value = true
            return false
          },
          $message: ' ',
        },
        phoneNumberSame: {
          $validator(v) {
            if (v.length > 0) {
              return v.replace('-') !== initialPhoneNo
            }
            return true
          },
          $message: ' ',
        },
      },
    })

    const isVerifyNotSupplied = computed(() => {
      return profileUpdateErrors.value.some((err) => err.reasonCode === 'Verify_NotSupplied')
    })

    const { isCorporateAccount } = storeToRefs(authStore)

    const updatePhone = async () => {
      isSaving.value = true
      const payload = {
        mobilePrefix: phoneForm.countryCode,
        mobilePhone: phoneForm.phone.substr(phoneForm.countryCode.length),
      }
      try {
        const success = await profileStore.updateUserInfo(payload)
        if (success) {
          props.onSave()
          add(`${$t('EditPhoneModal.PhoneUpdated').value}`)
          model.value = false
          initialPhoneNo = phoneForm.phone
        } else {
          throw new Error('Failed to update user information')
        }
      } catch (ex) {
        appStore.logException('Exception during updating phone number', ex)
        if (!isVerifyNotSupplied && !isCorporateAccount) {
          appStore.messageBoxGenericError()
        }
      }
      isSaving.value = false
    }

    const onUpdateInputValues = (v) => {
      if (v) {
        countryIso = v.nameAbbr
      }
    }
    const onAreaCodeInputOption = (v) => {
      countryIso = v.nameAbbr
      phoneForm.countryCode = v.value
    }

    return {
      model,
      $t,
      validation,
      isSaving,
      phoneForm,
      updatePhone,
      phoneCountries,
      onAreaCodeInputOption,
      onUpdateInputValues,
      showValidationErrorMessage,
      phoneValidationMessage,
      formatPhoneNumber,
      isValidPhoneNumberCheck,
    }
  },
}
</script>

<style scoped>
::v-deep .card-content {
  @apply px-12 overflow-visible;
  .card-content-block {
    @apply mb-6 text-sm leading-5;
  }
}

.card-header {
  @apply px-12 py-12;
}

::v-deep .card-header .card-header-title {
  @apply h-auto leading-6;
  h1 {
    @apply text-lg leading-6;
  }
}

::v-deep .card.card--has-footer {
  @screen sm {
    @apply pb-12;
  }
}

.card-footer {
  .button {
    @apply w-full;
  }
}

/*[Set as a global css to remove when merge]*/
.button.button--primary {
  &:disabled {
    &:not(.button--loading) {
      @apply text-white;
    }

    background: linear-gradient(
        to right,
        theme('colors.blue.button-disabled'),
        theme('colors.blue.button-disabled')
      ),
      linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
  }
}
.input-validation {
  margin-top: -20px;
}
</style>
