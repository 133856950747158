import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/LaunchpadAPIHandler'
import { ApiPreferenceGroup } from '@galileo/models/UserPreferences'
import { AxiosRequestConfig } from 'axios'

export default new APIHandler(
  API,
  (config: AxiosRequestConfig) => {
    return {
      ...config,
      method: 'GET',
      url: 'user-preferences',
    }
  },
  (response: { data: ApiPreferenceGroup[] }) => {
    return response?.data
  }
)
