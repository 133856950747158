import { ApiPreferenceGroup, PreferenceGroup } from "./Interfaces";

/**
 * abstract base class for preference groups
 * inhert from this class and implement to abstract functions
 */
export abstract class PreferenceGroupModelBase {
    abstract readonly group: PreferenceGroup
    /**
     * map the api model to this model
     * @param apiModel model from the api
     */
    abstract fromApiModel(apiModel: ApiPreferenceGroup): void
    
    /**
     * map this model to the api
     */
    abstract toApiModel(): ApiPreferenceGroup
  }