import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/LaunchpadAPIHandler'
import { ApiPreferenceGroup } from '@galileo/models/UserPreferences'
import { AxiosRequestConfig } from 'axios'

export default new APIHandler(
  API,
  (apiModel: ApiPreferenceGroup, config: AxiosRequestConfig) => {
    return {
      ...config,
      method: 'POST',
      url: 'user-preferences/groups',
      data: apiModel,
    }
  },
  (response: { data: ApiPreferenceGroup }) => {
    return response?.data
  }
)
