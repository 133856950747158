export function maskFormatToApi(maskedNumber) {
  return maskedNumber.replace(/•/g, '*')
}

export function maskFormatFromApi(maskedNumber) {
  return maskedNumber.toUpperCase().replace(/X/g, '•').replace(/\*/g, '•')
}

export function maskFormatAbbreviated(maskedNumber) {
  return `•••• ${maskedNumber.slice(-4)}`
}
