import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/LaunchpadAPIHandler'


export default new APIHandler(
  API,
  (config) => {
    return {
      ...config,
      method: 'POST',
      url: 'promotions/referrals',
    }
  },
  (response) => {
    return {
      data: response.data.referralCode,
    }
  }
)

