import API from '@galileo/api/rmt'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  ({ deviceId, question, answer, securityCode }, config) => {
    return {
      ...config,
      method: 'POST',
      url: 'authorization/verify',
      data: {
        deviceId,
        question,
        answer,
        securityCode,
      },
    }
  },
  (response) => {
    if (response.data.customerProfile && response.data.deviceToken) {
      return {
        ...response,
        data: {
          customerProfile: response.data.customerProfile,
          deviceToken: response.data.deviceToken,
        },
      }
    } else {
      throw new Error('Invalid answer')
    }
  }
)
