import Field from '@galileo/models/DynamicField/Field'
import RmtFieldOption from '@galileo/models/DynamicField/RmtFieldOption'
import RmtFieldValidation from '@galileo/models/DynamicField/XeFieldValidation'
import { camelCase } from 'lodash-es'

export default class RmtField extends Field {
  /*
    {
      "id": "ADDRESS_LINE1",
      "type": "String",
      "mandatory": true,
      "group": "GRP_RECIPIENT_DETAILS",
      "validation": {
        "type": "Regex",
        "regex": "^.{2,50}$"
      },
      "onChangeCanSubmitForEnrichment": false,
      "onChangeCanSubmitForValidation": false
    }
  */
  constructor(field) {
    super({
      id: camelCase(field.id),
      mandatory: field.mandatory,
      group: field.group,
      validations: field.validation.map((validation) => new RmtFieldValidation(validation)),
      useEnrichment: field.useEnrichment,
      label: field.label,
      dependsOn: null,
      hasAsyncValues: null,
      options: field.options.map((option) => new RmtFieldOption(option)),
      parent: field.parent ? camelCase(field.parent) : null,
      readonly: field.disabled,
      showOnParentValues: field.showOnParentValues,
      type: camelCase(field.fieldType),
      value: field.value ? field.value : null,
      hidden: field.fieldType === 'Hidden' ? true : false,
    })
  }
}
