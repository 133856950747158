import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'
import Recipient from '@galileo/models/Recipient/app'

import { BankRecipientResult } from '@galileo/models/Recipient/SpecificRecipientResult/app.js'

export default new APIHandler(
  API,
  ({ profileId, recipientId }, config) => {
    return {
      ...config,
      method: 'GET',
      url: `recipients/${profileId}/recipient/${recipientId}`,
    }
  },
  (response) => {
    if (!response.data) {
      return response
    }
    const recipientResult = new BankRecipientResult(response.data)
    return {
      ...response,
      data: new Recipient(recipientResult),
    }
  }
)
