import API from '@galileo/api/corporate'
import APIHandler from '@galileo/api/APIHandler'
import { CorporateUserDetails } from '@galileo/models/Corporate/interfaces/CorpReg'

export default new APIHandler(
  API,
  (profileId: string) => {
    return {
      method: 'GET',
      url: `corporate/profiles/${profileId}`,
    }
  },
  (response: {data: CorporateUserDetails}) => {
    return { ...response.data }
  }
)
