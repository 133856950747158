import { defineStore } from 'pinia'
import { ref } from '@vue/composition-api'

import promoCodeAPI from '@galileo/api/launchpad/promotions/referrals/post'
import getAvailablePromotions from '@galileo/api/launchpad-cache/promotionsdata/_country/get'



import { useI18nStore, useAuthStore, useSendMoneyStore, useThemeStore, useDeviceStore } from '@galileo/stores'

import { Promotion } from '@galileo/models/Promotions/interfaces/PromotionsTypes'
import { getPromoSymbolByCurrency } from '@galileo/composables/useIsoCountryCurrencyLibrary'
import { PROMOTIONS_TYPE } from '@galileo/constants/promotions.const'

export const usePromotionStore = defineStore(
  'promotions',
  () => {

    const promotionCode = ref('')
    const activePromotion = ref<Nullable<Promotion>>(null)
    const invalidPromotion = ref<Nullable<Promotion>>(null)
    const promotionsNames = ref<Array<string>>([])

    async function setPromotions(payload: Array<Promotion>): Promise<void> {


      if (payload?.length > 0) {
        const promotionsWithCode = payload.filter((el) => el.promotionCode)

        const validPromotion = promotionsWithCode.find(
          (el) => el.promotionCode.valid
        )

        const invalidPromoCode = promotionsWithCode.find((el) => !el.promotionCode.valid)

        if (validPromotion) {
          activePromotion.value = validPromotion

          const promoByCountry = getPromotionDataByPromoName(validPromotion.name)

          if (!promoByCountry) {
            let parsedPromotion = parsePromotionData(validPromotion)
            promotionsPerCountry.value.push(parsedPromotion)
          }
        } else if (invalidPromoCode) {
          invalidPromotion.value = invalidPromoCode
        }

        promotionsNames.value = payload.map((el) => el.name)
      }
    }

    async function getPromotionCode(): Promise<string> {
      const result = await promoCodeAPI.exec()
      promotionCode.value = result.data

      return promotionCode.value
    }

    async function getTransactionShareBody(promoCode: string): Promise<string> {
      const referAfriendPromo = getPromotionDataByPromoName(PROMOTIONS_TYPE.REFER_FRIEND)
      let text = ""

      if (referAfriendPromo) {
        const i18nStore = useI18nStore()
        const url = "https://xeapp.onelink.me/yMY4/raf"
        text = i18nStore.$t('ReferAFriend.BodyDetails', { promoCode, url, qualifyingValue: referAfriendPromo?.data.qualifyingValue, giftCardValue: referAfriendPromo?.data.giftCardValue }).value
      }

      return text
    }

    async function shareGmail(promoCode: string): Promise<void> {
      const subject = ''
      const body = await getTransactionShareBody(promoCode)

      window.open(
        `https://mail.google.com/mail/u/0/?view=cm&fs=1&su=${encodeURIComponent(
          subject
        )}&body=${encodeURIComponent(body)}&tf=1`
      )
    }

    async function shareEmail(promoCode: string): Promise<void> {
      const body = await getTransactionShareBody(promoCode)
      window.location.href = `mailto:?body=${encodeURIComponent(body)}`
    }

    async function shareClipboard(promoCode: string): Promise<void> {
      const body = await getTransactionShareBody(promoCode)

      try {
        await navigator.clipboard.writeText(body)
      } catch (e) {
        let textArea = document.createElement('textarea')
        textArea.value = body

        textArea.style.top = '0'
        textArea.style.left = '0'
        textArea.style.position = 'fixed'

        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()

        document.execCommand('copy')
        document.body.removeChild(textArea)
      }
    }

    function getCanAccessPromotion(): boolean {
      const { isCorporateAccount } = useAuthStore()
      const isBranded = useThemeStore().isBranded

      return !isCorporateAccount && !isBranded
    }

    function resetState() {
      useSendMoneyStore().form.promotionCodes = []
      promotionsNames.value = []
      activePromotion.value = null
      invalidPromotion.value = null
      promotionCode.value = ''
    }


    const promotionsPerCountry = ref<Promotion[]>([])

    async function getAvailablePromotionsPerUserCountry(): Promise<Promotion[]> {
      let userCountry: string | undefined = useDeviceStore().country

      if (!userCountry) {
        userCountry = useAuthStore().userProfile?.country
      }
      if (getCanAccessPromotion() && userCountry) {
        const countryAvailablePromotions = await getAvailablePromotions.exec({ country: userCountry })
        promotionsPerCountry.value = countryAvailablePromotions
      }

      return promotionsPerCountry.value
    }

    function getPromotionDataByPromoName(promotionName: string) {
      return promotionsPerCountry.value.find((promo) => promo.name === promotionName)
    }

    function getActivePromoData() {
      return promotionsPerCountry.value.find((promo) => promo.name === activePromotion.value?.name)
    }

    function parsePromotionData(promotion: Promotion): Promotion {
      if (!promotion.data) {
        return promotion
      }


      const currency = promotion.data.currency
      const symbol = getPromoSymbolByCurrency(currency)
      promotion.data.symbol = symbol
      promotion.data.giftCardValue = `${symbol}${promotion.data.giftCardAmount}`
      promotion.data.qualifyingValue = `${symbol}${promotion.data.qualifyingAmount}`

      return promotion
    }

    return {
      promotionCode,
      getPromotionCode,
      shareGmail,
      shareClipboard,
      shareEmail,
      getCanAccessPromotion,
      activePromotion,
      invalidPromotion,
      promotionsNames,
      setPromotions,
      resetState,
      getAvailablePromotionsPerUserCountry,
      promotionsPerCountry,
      getPromotionDataByPromoName,
      getActivePromoData,
      parsePromotionData,
      getTransactionShareBody,
    }
  },
  { persist: true }
)
