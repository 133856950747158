import API from '@galileo/api/rmt'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  (config) => {
    return {
      ...config,
      method: 'GET',
      url: `account/card/verify-payer-authentication/${config.data.xid}`,
    }
  },
  (response) => {
    return response
  }
)
