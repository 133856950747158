interface BillingAddress {
  address?: string
  city?: string
  postcode?: string
  county?: string
}

interface Card {
  cardTypeId?: string
  cardHolderFirstName?: string | undefined
  cardHolderLastName?: string | undefined
  expiryMonth?: string
  expiryYear?: string
  isReusable?: boolean | string
  billingAddress?: BillingAddress
  panFirstDigits?: string
  panLastDigits?: string
  encryptedExpiryMonth?: string
  encryptedExpiryYear?: string
  encryptedCardNumber?: string
  encryptedSecurityCode?: string
}

export interface AdyenData {
  countryCode: string | undefined
  ccy?: string
  merchantReference?: string | null
  payerAuthReferenceId?: string | null
  amount: number
  cardEncryptedData?: any
  cardId?: string | null
  customerIpAddress?: string | null
  emailAddress?: string | null
  card: Card
  maskedAccountNumber?: string
  cardTypeId?: string
  cardHolderFirstName?: string | undefined
  cardHolderLastName?: string | undefined
  expiryMonth?: string
  expiryYear?: string
  isReusable?: boolean | string
  billingAddress?: BillingAddress
  panFirstDigits?: string
  panLastDigits?: string
  recipientId: string
  browserInfo: BrowserInfo
  returnUrlOverride: string | null
  originUrlOverride: string | null
}

export default AdyenData

export class BrowserInfo {
  userAgent: string
  acceptHeader: string
  language: string
  colorDepth: number
  screenHeight: number
  screenWidth: number
  timeZoneOffset: number
  javaEnabled: boolean
  constructor() {
    this.userAgent = window.navigator.userAgent
    this.acceptHeader =
      'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8'
    this.language = navigator.language.substring(0, 5)
    this.colorDepth = window.screen.colorDepth
    this.screenHeight = window.innerHeight
    this.screenWidth = window.innerWidth
    this.timeZoneOffset = new Date().getTimezoneOffset()
    this.javaEnabled = navigator.javaEnabled() ?? false
  }
}

