import StringObject from '@galileo/types/StringObject'

const configKeys: StringObject = {
  VUE_APP_3DSV2_SCRIPT_URL: 'VUE_APP_3DSV2_SCRIPT_URL',
  VUE_APP_ANALYTICS_ENABLED: 'VUE_APP_ANALYTICS_ENABLED',
  VUE_APP_IS_TESTSYSTEM: 'VUE_APP_IS_TESTSYSTEM',
  VUE_APP_API_VERSION: 'VUE_APP_API_VERSION',
  VUE_APP_XE_VERSION: 'VUE_APP_XE_VERSION',
  VUE_APP_APP_TYPE: 'VUE_APP_APP_TYPE',
  VUE_APP_URL_RATE_ALERTS: 'VUE_APP_URL_RATE_ALERTS',
  VUE_APP_CLIENT_TYPE: 'VUE_APP_CLIENT_TYPE',
  VUE_APP_ENDPOINTS_CTAPI: 'VUE_APP_ENDPOINTS_CTAPI',
  VUE_APP_ENDPOINTS_RMTAPI: 'VUE_APP_ENDPOINTS_RMTAPI',
  VUE_APP_ENDPOINTS_CORPORATEAPI: 'VUE_APP_ENDPOINTS_CORPORATEAPI',
  VUE_APP_ENDPOINTS_CORP_INT: 'VUE_APP_ENDPOINTS_CORP_INT',
  VUE_APP_ENV: 'VUE_APP_ENV',
  VUE_APP_IOVATION_SCRIPT_URL: 'VUE_APP_IOVATION_SCRIPT_URL',
  VUE_APP_KEYS_LOGROCKET_KEY: 'VUE_APP_KEYS_LOGROCKET_KEY',
  VUE_APP_LINK_APP: 'VUE_APP_LINK_APP',
  VUE_APP_MAP_BOX_ACCESS_TOKEN: 'VUE_APP_MAP_BOX_ACCESS_TOKEN',
  VUE_APP_MAP_BOX_MAP_STYLE: 'VUE_APP_MAP_BOX_MAP_STYLE',
  VUE_APP_MAP_BOX_GEO_CODER: 'VUE_APP_MAP_BOX_GEO_CODER',
  VUE_APP_PLAID_SCRIPT_URL: 'VUE_APP_PLAID_SCRIPT_URL',
  VUE_APP_SEGMENT_ACCESS_TOKEN: 'VUE_APP_SEGMENT_ACCESS_TOKEN',
  VUE_APP_THREAT_METRIX_CREDENTIALS: 'VUE_APP_THREAT_METRIX_CREDENTIALS',
  VUE_APP_THREAT_METRIX_TAGS_URL: 'VUE_APP_THREAT_METRIX_TAGS_URL',
  VUE_APP_APEXX_SCRIPT_URL: 'VUE_APP_APEXX_SCRIPT_URL',
  VUE_APP_APEXX_PUBLIC_KEY: 'VUE_APP_APEXX_PUBLIC_KEY',
  VUE_APP_ADYEN_HOSTED_3DS_RETURN_URL: 'VUE_APP_ADYEN_HOSTED_3DS_RETURN_URL',
  VUE_APP_RECAPTCHA_SCRIPT_URL: 'VUE_APP_RECAPTCHA_SCRIPT_URL',
  VUE_APP_RECAPTCHA_PUBLIC_KEY: 'VUE_APP_RECAPTCHA_PUBLIC_KEY',
  VUE_APP_KEYS_GTM: 'VUE_APP_KEYS_GTM',
  CARDINAL_EXTENDED_TIMEOUT: 'CARDINAL_EXTENDED_TIMEOUT',
  VUE_APP_VOLT_SCRIPT_URL: 'VUE_APP_VOLT_SCRIPT_URL',

  VUE_APP_ENDPOINTS_LAUNCHPADAPI: 'VUE_APP_ENDPOINTS_LAUNCHPADAPI',
  VUE_APP_ENDPOINTS_LAUNCHPADCACHE: 'VUE_APP_ENDPOINTS_LAUNCHPADCACHE',

  VUE_APP_ENDPOINTS_LAUNCHPADAPI_JOHNLEWIS: 'VUE_APP_ENDPOINTS_LAUNCHPADAPI_JOHNLEWIS',
  VUE_APP_ENDPOINTS_LAUNCHPADAPI_BRITLINE: 'VUE_APP_ENDPOINTS_LAUNCHPADAPI_BRITLINE',

  VUE_APP_ACCOUNT_SITE: 'VUE_APP_ACCOUNT_SITE',
  VUE_APP_ACCOUNT_SITE_BRITLINE: 'VUE_APP_ACCOUNT_SITE_BRITLINE',
  VUE_APP_ACCOUNT_SITE_JOHNLEWIS: 'VUE_APP_ACCOUNT_SITE_JOHNLEWIS',


  VUE_APP_URL_TRANSFER: 'VUE_APP_URL_TRANSFER',
  VUE_APP_URL_TRANSFER_BRITLINE: 'VUE_APP_URL_TRANSFER_BRITLINE',
  VUE_APP_URL_TRANSFER_JOHNLEWIS: 'VUE_APP_URL_TRANSFER_JOHNLEWIS',

  VUE_APP_HELPCENTRE_URL: 'VUE_APP_HELPCENTRE_URL',
}

export default configKeys
