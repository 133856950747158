import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  (currency, config) => {
    return {
      ...config,
      method: 'GET',
      url: `/our-bank-accounts?sellCcy=${currency}`,
    }
  },
  (response) => {
    if (!response.data) {
      return response
    }
    return {
      ...response,
      data: response.data.data.map((bank) => {
        return {
          id: bank.settlementInstructionId,
          name: bank.bankName + ' - ' + bank.swiftBic,
          bankName: bank.bankName,
          country: bank.country,
          bic: bank.swiftBic,
          bankAccountId: bank.ourBankAccountId,
        }
      }),
    }
  }
)
