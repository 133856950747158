import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

import { BankRecipientResult } from '@galileo/models/Recipient/SpecificRecipientResult/app.js'

export default new APIHandler(
  API,
  ({ profileId, country, currency, fields, business, myself }, config) => {
    return {
      ...config,
      method: 'POST',
      url: `v2/recipients/${profileId}/recipient`,
      data: {
        bankCountryCode: country,
        currencyCode: currency,
        isBusiness: business,
        isOwnAccount: myself,
        fields,
      },
    }
  },
  (response) => {
    if (!response.data) {
      return response
    }
    const fields = response.data.data.fields
    const data = response.data.data

    let bankRecipientResult = new BankRecipientResult(data)
    bankRecipientResult.isCashPayout = false

    return {
      ...response,
      data: {
        fields,
        recipientResponse: bankRecipientResult,
      },
    }
  }
)
