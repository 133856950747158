import CSTAPI from '@/api/ct'
import RMTAPI from '@/api/rmt'
import CorporateAPI from '@/api/corporate'
import LaunchpadAPI from '@/api/launchpad'
import { useAuthStore } from '@galileo/stores'
export default class APIHandler {
  constructor(API, requestHandler, responseHandler, errorHandler = null) {
    this.API = API
    this.requestHandler = requestHandler
    this.responseHandler = responseHandler
    this.errorHandler = errorHandler
    this.interceptors = {}
  }
  async exec(requestConfig) {
    // Wrap the request with the response handler to transform
    // Perform the axios request
    let result = null
    useAuthStore().setLastApiCall()

    try {
      result = await this.API(this.requestHandler(requestConfig))
    } catch (ex) {
      if (ex && ex.response) {
        if (this.errorHandler) {
          result = this.errorHandler(ex)
          if (result) {
            return result
          }
        }

        // try to normalize errors
        if (ex.response.data && ex.response.data.Result) {
          ex.errorCode = ex.response.data.Result.RetVal
          ex.errorMessage = ex.response.data.Result.RetMsg
        } else if (
          ex.response.data &&
          ex.response.data.errors &&
          ex.response.data.errors.length > 0
        ) {
          ex.errorObject = ex.response.data.errors[0]
          ex.errorCode =
            ex.response.data.errors[0].errorCode ?? ex.response.data.errors[0].reasonCode
          ex.errorMessage =
            ex.response.data.errors[0].errorMessage ?? ex.response.data.errors[0].reasonDesc
          ex.friendlyMessage = ex.response.data.errors[0].friendlyMessage
        }
      }
      throw ex
    }
    return this.responseHandler(result)
  }

  async execWithSafeGuard(requestConfig) {
    const request = JSON.stringify(requestConfig)
    this.lastRequest = request

    const result = await this.exec(requestConfig)
    if (this.lastRequest === request) {
      return result
    }
    return { data: null, skipped: true }
  }

  // axios interceptors https://github.com/axios/axios#interceptors
  addRequestInterceptor(params) {
    const interceptor = this.API.interceptors.request.use(
      params.requestHandler,
      params.errorHandler,
      params.options
    )
    this.interceptors[params.key] = {
      type: 'request',
      interceptor,
    }
  }

  addResponseInterceptor(params) {
    const interceptor = this.API.interceptors.response.use(
      params.responseHandler,
      params.errorHandler,
      params.options
    )
    this.interceptors[params.key] = {
      type: 'response',
      interceptor,
    }
  }

  removeInterceptor(key) {
    const interceptor = this.interceptors[key]
    if (interceptor) {
      this.API.interceptors[interceptor.type].eject(interceptor.interceptor)
      delete this.interceptors[key]
    }
  }
}

export const useHeaders = () => {
  const _headers = {}
  _headers['X-SKIP-2FA-NON-PROD'] = 'true'
  const setHeader = (key, value) => {
    _headers[key] = value
  }

  const removeHeader = (key) => {
    delete _headers[key]
  }
  return {
    _headers,
    setHeader,
    removeHeader,
  }
}
export const setHeader = (key, value) => {
  CSTAPI.defaults.headers.common[key] = value
  RMTAPI.defaults.headers.common[key] = value
  CorporateAPI.defaults.headers.common[key] = value
  LaunchpadAPI.defaults.headers.common[key] = value
}
export const removeHeader = (key) => {
  delete CSTAPI.defaults.headers.common[key]
  delete RMTAPI.defaults.headers.common[key]
  delete CorporateAPI.defaults.headers.common[key]
  delete LaunchpadAPI.defaults.headers.common[key]
}
