import FieldValidation from '@galileo/models/DynamicField/FieldValidation'

export default class CstFieldValidation extends FieldValidation {
  constructor(validation) {
    super({
      message: validation.Message,
      type: validation.Type,
      value: validation.Value,
    })
  }
}
