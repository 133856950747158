<template>
    <!-- Logout modal -->
    <InformationAppModal :value="model" icon="warningYellow">
      <h1 class="title">{{ $t('PageAccount.LogoutTitle').value }}</h1>
      <p v-if="rememberDevice">
        {{ $t('PageAccount.ForgetDeviceLogoutInfoText').value }} <br />
      </p>
      <p v-else>
        {{ $t('PageAccount.LogoutInfoText').value }} <br />
        {{ $t('PageAccount.LogoutInfoTextExtra').value }}
      </p>
      <template #footer>
          <AppButton :loading="logoutLoading" @click="logout">
            {{ $t('PageAccount.RememberedButtonLogoutYes').value }}
          </AppButton>

          <AppButton v-if="rememberDevice" theme="secondary" @click="logoutForgetDevice"> 
            {{ $t('PageAccount.ForgetDeviceAndSignOut').value }}
          </AppButton>

          <AppButton theme="secondary" @click="closeModal">
            {{ $t('PageAccount.ButtonLogoutNo').value }}
          </AppButton>  
      </template>
    </InformationAppModal>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'

import {
  useVModel,
  AppButton,
} from '@oen.web.vue2/ui'

import { useI18nStore, useAuthStore, useDeviceStore } from '@galileo/stores'

export default {
  name: 'SignOutModal',
  emit: ['input'],
  components: {
    AppButton,
    InformationAppModal,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const authStore = useAuthStore()
    const deviceStore = useDeviceStore()
    const { model } = useVModel(props, emit)
    const rememberDevice = ref(deviceStore.rememberDevice)
    const logoutLoading = ref(false)

    const logout = async () => {
      logoutLoading.value = true
      await authStore.logout(true)
    }

    const logoutForgetDevice = async () => {
      try {
        const res = await deviceStore.forgetDevice()
        if (res) {
          await logout()
        }
      } catch (ex) {
        let exception = { ...ex }
        appStore.logException('Unable to forget device', ex)
        throw ex
      }
    }

    const closeModal = () => {
      model.value = false
    }

    return {
      $t,
      model,
      rememberDevice,
      logoutLoading,
      logout,
      logoutForgetDevice,
      closeModal,
    }
  },
}
</script>

<style scoped>
.logout-dialog {
  @apply mb-14;
}

@screen sm {
  .logout-dialog {
    @apply mb-0;
  }
}

::v-deep .card-footer {
  @apply flex-col;
  button:first-child {
    order: 0;
  }
}
</style>
