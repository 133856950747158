import { useI18nStore } from '@galileo/stores'

const getValueRequiredValidation = () => {
  const { $t } = useI18nStore()
  return {
    $validator(v) {
      if (v) {
        v = v.toString().trim()
        return v.length > 0
      }
      return false
    },
    $message: $t('PageSendMoneyAmount.AmountToRequired').value,
  }
}

export default getValueRequiredValidation
