import { computed, ref, reactive } from '@vue/composition-api'

import { defineStore } from 'pinia'
import { PAYMENT_GATEWAY, APEXX_REGIONS } from '@galileo/constants/cardPayments.const'
import { debounce } from '@galileo/api/RMTAPIHandler'
import getBinInfo from '@galileo/api/launchpad/cards/bank-identification-numbers/get'
import verifyPayerAuthentication from '@galileo/api/rmt/account/card/verifyPayerAuthentication/_id/get'
import useAuthorize3DSV2 from '@galileo/composables/useAuthorize3DSv2'
import postCardTransaction from '@galileo/api/launchpad/cards/card-transactions/post'
import postCardTransactionV2 from '@galileo/api/launchpad/cardsV2/card-transactions/post'

import getCardPayerAuthTokensV2 from '@galileo/api/launchpad/cardsV2/card-payer-auth-tokens/get'
import getCardSettingsV2 from '@galileo/api/launchpad/cardsV2/card-settings/get'
import getCardSettingsApi from '@galileo/api/launchpad/cards/card-settings/get'
import postCard from '@galileo/api/launchpad/cards/post'
import {
  CardAccountForm,
  CardInformation,
  CardSettings,
  BillingAddress,
  CardFields,
  FlexData,
  VerifyCardAuthenticationData,
  ApexxData,
  CardTransaction,
  BrowserInfo,
  AdyenData,
} from '@galileo/models/CardAccount/index'

import { CardAccount } from '@galileo/models/payments/interfaces/paymentsTypes'

import { AdyenData as AdyenDataConfig } from '@galileo/composables/useAdyenConfig/adyenTypes'
// @ts-ignore
import flexsdk from '@cybersource/flex-sdk-web'
//@ts-ignore
import * as $ from 'jquery' // is in use in encryptCardApexx

import {
  useAuthStore,
  useEnvStore,
  useCountriesStore,
  usePaymentsStore,
  useAppStore,
  useSendMoneyStore,
  useAnalyticsStore,
} from '@galileo/stores'
import { CardTransactionResult } from '@galileo/models/CardAccount/CardTransaction'
import { SEGMENT_EVENTS, SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'

import useCardHelper from '@galileo/composables/useCardHelper'

export const useCardAccountStore = defineStore('cardAccount', () => {
  const cardAccountForm = reactive<CardAccountForm>({} as CardAccountForm)
  const cardInformation = ref({} as CardInformation)

  const transactionReference = ref('')
  const cardTransactionResult = ref<any>()
  const cardSettings = ref<Nullable<CardSettings>>(null)
  const billingAddress = ref<string>()

  const cardBrand = ref<string>('')
  const cardAuthorisationType = ref<string>('')
  const cardBillingCountry = ref<string>('')

  const authStore = useAuthStore()
  const sendMoneyStore = useSendMoneyStore()
  const analyticsStore = useAnalyticsStore()

  const appStore = useAppStore()

  const selectedCardForUpdate = ref<Nullable<CardAccount>>(null)

  const useApexx = computed(() => {
    const user = authStore.user
    if (user?.customer?.cardPaymentGateway) {
      return user?.customer?.cardPaymentGateway === PAYMENT_GATEWAY.APEXX
    }
    return useApexxLegacy.value
  })

  const useCybersource = computed(() => {
    const user = authStore.user
    if (user?.customer?.cardPaymentGateway) {
      return user?.customer?.cardPaymentGateway === PAYMENT_GATEWAY.CYBERSOURCE
    }
    return !useApexxLegacy.value
  })

  const useAdyen = computed(() => {
    const user = authStore.user
    if (user?.customer?.cardPaymentGateway) {
      return user?.customer?.cardPaymentGateway === PAYMENT_GATEWAY.ADYEN
    }
    return useApexxLegacy.value
  })

  const useApexxLegacy = computed(() => {
    const user = authStore.user
    const userTrm = user?.customer?.country ?? ''
    return APEXX_REGIONS.includes(userTrm)
  })

  const getCorrectTransferId = computed(() => (referenceId: string) => {
    if (useApexx.value || useAdyen.value) {
      return sendMoneyStore.form.transferId
    }

    return referenceId
  })

  const getCybersourceData = computed(() => {
    return cardAccountForm.cybersourceData
  })
  const getAdyenData = computed(() => {
    return useSendMoneyStore().adyenData
  })

  const getCardTransactionResult = computed(() => {
    return cardTransactionResult.value
  })

  const getCardSettings = computed(() => {
    return cardSettings.value
  })

  const getCardInformation = computed(() => {
    return cardInformation.value
  })

  function resetForm() {
    cardAccountForm.countryTo = null
    cardAccountForm.apexxData = null
    cardAccountForm.cybersourceData = null
    setAdyenData(null)
    cardTransactionResult.value = null
  }

  function setApexxData(apexxData: Nullable<ApexxData>) {
    cardAccountForm.apexxData = apexxData
  }

  function setAdyenData(adyenData: Nullable<AdyenData>) {
    useSendMoneyStore().adyenData = adyenData
  }

  function storeCardTransactionResult(transactionDetails: any) {
    cardTransactionResult.value = transactionDetails
  }

  function resetCardSettings() {
    cardSettings.value = null
  }

  function setCardSettings(settings: any) {
    cardSettings.value = settings
  }

  function saveCardNumberForIdentification(cardNumber: string | null) {
    cardInformation.value.cardNumber = cardNumber
  }

  function setCybersourceData(data: any) {
    cardAccountForm.cybersourceData = data
  }

  async function requestCardInfo(binNumber: string): Promise<any> {
    return new Promise((resolve) => {
      const executed = debounce(async () => {
        const user = authStore.user
        const request = {
          profileId: user?.customer?.id,
          binNumber,
        }
        try {
          const cardInfo = await getBinInfo.execWithSafeGuard(request)
          resolve(cardInfo)
        } catch (ex: any) {
          let exception = { ...ex }
          exception.toString = null

          if (ex.errorObject) {
            const { getCardErrorMessage } = useCardHelper()
            const errorMessage = getCardErrorMessage(ex)

            resolve({
              error: errorMessage ?? ex.response.data.errors[0].reasonDesc,
              status: 400,
              parameters: ex.errorObject.parameters,
            })

            analyticsStore.track({
              event: SEGMENT_EVENTS.NEW_CARD_ADDED_ERROR,
              traits: {
                category: 'Add Card',
                error: ex.errorObject.reasonCode,
                errorDescription: ex.errorObject.reasonDesc,
                paymentType: sendMoneyStore.form.paymentMethod,
                location: SEGMENT_LOCATIONS.SEND_MONEY,
              },
            })
          }

          // TODO: is there a better way to detect 404 ?
          if (ex.response.status === 404) {
            resolve({ error: 'Invalid card number', status: 404 })
          } else {
            resolve(null)
          }
        }
      })
      if (!executed) {
        resolve(null)
      }
    })
  }

  async function retrieveApexxData(paymentMethod: any) {
    //retrieving all the cards we have stored in the backend
    let cardSetting = await fetchCardSettings()

    //check if paymentMethod is not set correctly then we st paymentMethodId correctly
    if (!paymentMethod?.id) {
      paymentMethod.id = sendMoneyStore.form.paymentMethodId
    }

    //we are getting the card details from the settings
    const card = cardSetting?.cards.find((card: any) => card.id === paymentMethod.id)

    //we are getting the security code
    const securityCode = sendMoneyStore.form.cardSecurityCode
    const appStore = useAppStore()

    const billingAddress: BillingAddress = {
      firstName: card ? card.firstName : '',
      lastName: card ? card.lastName : '',
      city: '',
      country: '',
      postalCode: '',
      state: '',
      street: '',
    }

    try {
      const encryptedData = await encryptCardApexx(
        securityCode,
        undefined,
        card?.expiryAtMonth,
        card?.expiryAtYear,
        {
          firstName: billingAddress.firstName,
          lastName: billingAddress.lastName,
        }
      )

      const user = authStore.user

      //creating apexxdata
      if (encryptedData) {
        let apexxData = {
          merchantReference: null, // will be filled during authorization
          payerAuthReferenceId: null, // will be filled during authorization
          ccy: sendMoneyStore?.getCurrencyFrom,
          amount: null, // will be filled during authorization
          countryCode: card?.billingAddressCountry, //user country code or the one from billing address?
          cardEncryptedData: encryptedData,
          cardId: paymentMethod.id,
          customerIpAddress: null, // is filled in Launchpad
          emailAddress: user?.customer?.email,
        }
        return apexxData
      }
    } catch (exception) {
      appStore.logError('Error while creating data for apexx', 'Payment', exception)
    }
  }

  async function addCard({
    cardNumber,
    expiryMonth,
    expiryYear,
    billingAddress,
    securityCode,
    cardType,
    cardPspCode,
    saveCard,
    adyenData,
  }: {
    cardNumber: string
    expiryMonth: string
    expiryYear: string
    billingAddress: BillingAddress
    securityCode: any
    postalCode: any
    country: string
    cardType: string
    cardPspCode: string
    saveCard: string
    adyenData?: AdyenDataConfig
  }): Promise<any> {
    setApexxData(null)
    setAdyenData(null)

    switch (authStore.userProfile?.customer.cardPaymentGateway) {
      case PAYMENT_GATEWAY.ADYEN:
        if (!adyenData) {
          throw Error('Adyen Data missing')
        }
        return await addCardAdyen(billingAddress, cardType, saveCard, adyenData)

      case PAYMENT_GATEWAY.APEXX:
        return await addCardApexx(
          cardNumber,
          expiryMonth,
          expiryYear,
          billingAddress,
          securityCode,
          cardType,
          saveCard
        )
      case PAYMENT_GATEWAY.CYBERSOURCE:
        return await addCardCyberSource(
          cardNumber,
          expiryMonth,
          expiryYear,
          billingAddress,
          securityCode,
          cardType,
          cardPspCode,
          saveCard
        )
    }
  }

  async function addCardCyberSource(
    cardNumber: string,
    expiryMonth: string,
    expiryYear: string,
    billingAddress: BillingAddress,
    securityCode: string,
    cardType: string,
    cardPspCode: string,
    saveCard: string
  ): Promise<any> {
    const user = authStore.user
    const result = await startCardTransaction(securityCode, true)
    const envStore = useEnvStore()

    let production = envStore.isProduction

    const cybersourceData = {
      paymentType: cardType,
      cardBin: cardNumber.slice(0, 6),
      cardLast4Digits: cardNumber.slice(-4),
      cardExpireDate: `${expiryMonth}-${expiryYear}`,
    }

    setCybersourceData(cybersourceData)

    const flexValues: FlexData = {}
    // .data deleted
    for (const element of result.fields) {
      const field: Record<string, any> = element

      if (field.pspFieldName) {
        flexValues[field.pspFieldName as keyof FlexData] = field.constantValue ?? field.defaultValue
      }
    }

    const options = {
      kid: result.flexPublicKey.jsonWebKey.kid,
      keystore: result.flexPublicKey.jsonWebKey,
      encryptionType: 'rsaoaep',
      cardInfo: {
        cardNumber: cardNumber.substring(0, 6) + '...',
        cardType: cardPspCode,
        cardExpirationMonth: expiryMonth,
        cardExpirationYear: expiryYear,
      },
      production: production,
    }

    //store card number for cardinal
    saveCardNumberForIdentification(cardNumber)

    options.cardInfo.cardNumber = cardNumber // add it after the logging so it won't be logged

    return new Promise((resolve, reject) => {
      flexsdk.createToken(options, (response: any) => {
        if (response.error) {
          appStore.logError(
            'Error from Cybersource Flex SDK',
            'Payment', // TODO !!!!!!!!!!!!!!!!!
            response
          )
          reject(response.error)
          return
        }

        const flexKeyPublic = {
          keyId: result.flexPublicKey.jsonWebKey.kid,
          der: result.flexPublicKey.derPublicKey,
          jwk: result.flexPublicKey.jsonWebKey,
        }

        const flexFields: { [key: string]: string | undefined } = {
          //Token and JWK
          flex_public_key: JSON.stringify(flexKeyPublic),
          flex_token: JSON.stringify(response),

          // Adding fields that are required for creating card against DFX
          req_bill_to_address_city: billingAddress.city,
          req_bill_to_address_line1: billingAddress.street,
          req_bill_to_address_postal_code: billingAddress.postalCode,
          req_bill_to_address_country: billingAddress.country,
          req_bill_to_forename: billingAddress.firstName,
          req_bill_to_surname: billingAddress.lastName,
          req_currency: flexValues['currency'],
          req_card_type: cardPspCode,
          req_card_expiry_date: `${expiryMonth}-${expiryYear}`, // TODO !!!!!!!!!!!!!!!! eventually prefill needed for month ?
          req_merchant_defined_data1: saveCard,
          req_merchant_defined_data2: cardType,
          req_access_key: flexValues['access_key'],
          req_customer_ip_address: flexValues['customer_ip_address'],
          req_override_custom_receipt_page: flexValues['override_custom_receipt_page'],
          req_payment_method: flexValues['payment_method'],
          req_profile_id: flexValues['profile_id'],
          req_transaction_type: flexValues['transaction_type'],
          req_transaction_uuid: flexValues['transaction_uuid'],
          req_bill_to_email: flexValues['bill_to_email'],
          req_locale: flexValues['locale'],
          req_ignore_avs: flexValues['ignore_avs'],
          signed_field_names: flexValues['signed_field_names'],
          signed_date_time: flexValues['signed_date_time'],
          signature: flexValues['signature'],
          payer_authentication_challenge_type: 'CH',
          payer_authentication_challenge_code: '04',
        }
        if (billingAddress.state) {
          // flexFields['req_bill_to_address_state'] = billingAddress.state
          Object.assign(flexFields, { req_bill_to_address_state: billingAddress.state })
        }

        const cardFields = new Array<CardFields>()
        for (const key in flexFields) {
          cardFields.push({
            name: key,
            value: flexFields[key],
          })
        }

        let promise = new Promise(async (resolve, reject) => {
          try {
            let cardData = {
              maskedAccountNumber: '....' + cardNumber.substring(cardNumber.length - 4),
              expiryMonth,
              expiryYear,
            }

            //should we always save the card?
            // saveCard = true
            // if (saveCard) {
            const { data } = await postCard.exec({
              profileId: user?.customer?.id,
              trxRef: result.trxRef,
              cardFields,
            })
            cardData = {
              ...data,
              ...cardData,
            }
            resolve(cardData)
          } catch (ex) {
            appStore.logException('Failed to create card', ex)
            reject(ex)
          }
        })
        resolve(promise)
      })
    })
  }

  async function addCardAdyen(
    billingAddress: BillingAddress,
    cardType: string,
    saveCard: string,
    adyenData: AdyenDataConfig
  ): Promise<AdyenData | null> {
    const envStore = useEnvStore()
    const user = authStore.user

    if (!sendMoneyStore.form.recipient?.id) {
      throw Error('recipient id is missing')
    }

    if (adyenData.paymentMethod) {
      const adyenDataObj: AdyenData = {
        merchantReference: null, // will be filled during authorization
        payerAuthReferenceId: null, // will be filled during authorization
        ccy: sendMoneyStore.getCurrencyFrom,
        amount: 0, // will be filled during authorization
        countryCode: billingAddress.country,
        card: {
          cardTypeId: cardType,
          cardHolderFirstName: billingAddress.firstName,
          cardHolderLastName: billingAddress.lastName,
          isReusable: saveCard,
          billingAddress: {
            address: billingAddress.street,
            city: billingAddress.city,
            postcode: billingAddress.postalCode,
            county: billingAddress.state,
          },
          panFirstDigits: adyenData.binValue, ///bin,
          panLastDigits: adyenData.panLastDigits,
          encryptedExpiryMonth: adyenData.paymentMethod.encryptedExpiryMonth,
          encryptedExpiryYear: adyenData.paymentMethod.encryptedExpiryYear,
          encryptedCardNumber: adyenData.paymentMethod.encryptedCardNumber,
          encryptedSecurityCode: adyenData.paymentMethod.encryptedSecurityCode,
        },
        customerIpAddress: '10.37.69.171', // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! should be in launchpad
        emailAddress: user.customer.email,
        recipientId: sendMoneyStore.form.recipient.id.toString(),
        returnUrlOverride: envStore.env.VUE_APP_ADYEN_HOSTED_3DS_RETURN_URL,
        originUrlOverride: null, //"http://127.0.0.1:8080",
        browserInfo: new BrowserInfo(),
      }

      if (selectedCardForUpdate.value) {
        adyenDataObj.cardId = selectedCardForUpdate.value.id
      }

      const result = {
        ...adyenDataObj,
        ...adyenDataObj.card,
        maskedAccountNumber: `....${adyenDataObj.card.panLastDigits}`,
        adyenData: adyenData,
      }

      setAdyenData(adyenDataObj)
      return result
    } else {
      appStore.logError('Missing returned data from Adyen')
    }
    return null
  }

  async function addCardApexx(
    cardNumber: string,
    expiryMonth: string,
    expiryYear: string,
    billingAddress: BillingAddress,
    securityCode: string,
    cardType: string,
    saveCard: string
  ): Promise<ApexxData | null> {
    const encryptedData = await encryptCardApexx(
      securityCode,
      cardNumber,
      expiryMonth,
      expiryYear,
      billingAddress
    )
    //do we need it here ? - it helps Apexx works with new cards
    await startCardTransaction(securityCode, true)

    const user = authStore.user

    if (encryptedData) {
      const apexxData = {
        merchantReference: null, // will be filled during authorization
        payerAuthReferenceId: null, // will be filled during authorization
        ccy: sendMoneyStore.getCurrencyFrom,
        amount: null, // will be filled during authorization
        countryCode: billingAddress.country,
        cardEncryptedData: encryptedData,
        card: {
          cardTypeId: cardType,
          cardHolderFirstName: billingAddress.firstName,
          cardHolderLastName: billingAddress.lastName,
          expiryMonth: expiryMonth,
          expiryYear: expiryYear.substring(2),
          isReusable: saveCard,
          billingAddress: {
            address: billingAddress.street,
            city: billingAddress.city,
            postcode: billingAddress.postalCode,
            county: billingAddress.state,
          },
          panFirstDigits: cardNumber.substring(0, 6),
          panLastDigits: cardNumber.slice(-4),
        },
        customerIpAddress: '10.37.69.171', // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! should be in launchpad
        emailAddress: user?.customer?.email,
      }

      const result = {
        ...apexxData,
        ...apexxData.card,
        maskedAccountNumber: `....${apexxData.card.panLastDigits}`,
      }

      setApexxData(apexxData)
      return result
    } else {
      appStore.logError('Missing returned data from Apexx')
    }
    return null
  }
  async function encryptCardApexx(
    securityCode: string | null,
    cardNumber?: string,
    expiryMonth?: string,
    expiryYear?: string,
    billingAddress?: any
  ): Promise<string | null> {
    const envStore = useEnvStore()
    const script = document.createElement('script')
    let html = 'var $ = null; function setupJQuery(jquery) { $ = jquery; }'
    script.type = 'text/javascript'
    script.innerHTML = html
    document.getElementsByTagName('head')[0].appendChild(script)
    // eslint-disable-next-line no-undef
    // @ts-ignore
    setupJQuery($) // in the Apexx library $ is used hence we need to set it before otherwise we will run into errors

    await appStore.loadScript(envStore.env.VUE_APP_APEXX_SCRIPT_URL)
    // eslint-disable-next-line no-undef
    // @ts-ignore
    APEXX.setPublicKey(envStore.env.VUE_APP_APEXX_PUBLIC_KEY)

    html = '<form method="post">'
    if (billingAddress) {
      html +=
        '<input data-apexx="card_holder_name" value="' +
        billingAddress.firstName +
        ' ' +
        billingAddress.lastName +
        '">'
    }
    if (cardNumber) {
      html += '<input data-apexx="card_number" value="' + cardNumber + '">'
    }
    if (expiryMonth && expiryYear) {
      html += '<input data-apexx="expiry_month" value="' + expiryMonth + '">'
      html += '<input data-apexx="expiry_year" value="' + expiryYear.substring(2) + '">'
    }
    html += '<input data-apexx="cvv" value="' + securityCode + '">'
    html += '<input type="hidden" name="apexxEncrypted" data-apexx="encrypted_data">'
    html += '<input type="hidden" name="maskedCardNumber" data-apexx="masked_card_number">'
    html += '</form>'

    const container = document.createElement('div')
    container.innerHTML = html
    document.getElementsByTagName('body')[0].appendChild(container)

    appStore.logInfo('Encrypt Apexx data')
    // eslint-disable-next-line no-undef
    // @ts-ignore
    if (cardDataEncryption()) {
      appStore.logInfo('Successfully encrypted Apexx data')
      // @ts-ignore
      const encryptedData = $('input[data-apexx="encrypted_data"]').val()
      document.getElementsByTagName('body')[0].removeChild(container)
      return encryptedData
    } else {
      appStore.logError('Failed to encrypt Apexx data')
    }

    document.getElementsByTagName('body')[0].removeChild(container)
    return null
  }

  async function verifyCardAuthentication(
    xid: string
  ): Promise<VerifyCardAuthenticationData | null> {
    const { data } = await verifyPayerAuthentication.exec({
      data: {
        xid: xid,
      },
    })
    if (data) {
      return data
    } else {
      return null // no exception since the API returns no data if it is not ready yet
    }
  }

  function authorize3DSV2({
    authContainer,
    accessToken,
    referenceId,
    encryptedCvn,
    showCardAuth,
    finishCardAuth,
    cancelCardAuth,
    loadingState = false,
  }: {
    authContainer: any
    accessToken: string
    referenceId: string
    encryptedCvn: string
    showCardAuth: any
    finishCardAuth: any
    cancelCardAuth: any
    loadingState: boolean
  }): Promise<any> {
    const {
      cardinalSetupCompleteHandler,
      cardinalPaymentValidatedHadler,
      cardinalOnInlineSetupHandler,
      resetCardinalListeners,
      setupResolveReject,
    } = useAuthorize3DSV2(
      authContainer,
      referenceId,
      encryptedCvn,
      showCardAuth,
      finishCardAuth,
      cancelCardAuth,
      // @ts-ignore
      loadingState
    )

    appStore.logInfo('Authorize 3DSv2')
    const envStore = useEnvStore()

    return new Promise(async (resolve, reject) => {
      setupResolveReject(resolve, reject)

      resetCardinalListeners()

      //loading 3ds script
      await appStore.loadScriptCardinal()

      appStore.logInfo('3DSv2 script loaded')

      // ---------------------  cardinal configuration
      const extendedTimeout = envStore.getVariable('CARDINAL_EXTENDED_TIMEOUT') || 1200

      const config = {
        timeout: 8000,
        maxRequestRetries: 2,
        extendedTimeout: extendedTimeout,
        logging: { level: 'off' },
        payment: {
          framework: 'inline',
          displayLoading: true,
        },
      }

      if (envStore.isProduction) {
        config.logging.level = 'verbose'
      }

      // init transaction here (songbird)
      // @ts-ignore
      Cardinal.configure(config)
      // @ts-ignore
      Cardinal.on('ui.inline.setup', cardinalOnInlineSetupHandler)
      // @ts-ignore
      Cardinal.on('payments.validated', cardinalPaymentValidatedHadler)
      // @ts-ignore
      Cardinal.on('payments.setupComplete', cardinalSetupCompleteHandler)

      appStore.logInfo('Cardinal start setup')
      // @ts-ignore
      Cardinal.setup('init', {
        jwt: accessToken,
      })

      appStore.logInfo('Cardinal setup started')
    })
  }

  async function startCardTransaction(
    securityCode: string,
    isNewCard: boolean = false
  ): Promise<any> {
    let trxRef = usePaymentsStore().createTransactionReference()

    let cardTransactionResult

    switch (authStore.userProfile?.customer.cardPaymentGateway) {
      case PAYMENT_GATEWAY.ADYEN:
        transactionReference.value =
          sendMoneyStore.form.transferId ?? sendMoneyStore.form.transactionId
        cardTransactionResult = await startCardTransactionAdyen(transactionReference.value)
        break

      case PAYMENT_GATEWAY.APEXX:
        cardTransactionResult = await startCardTransactionApexx(trxRef)
        break

      case PAYMENT_GATEWAY.CYBERSOURCE:
        cardTransactionResult = await startCardTransactionCybersource(
          trxRef,
          securityCode,
          isNewCard
        )
        break
    }

    storeCardTransactionResult(cardTransactionResult)

    return cardTransactionResult
  }

  async function startCardTransactionCybersource(
    trxRef: string,
    securityCode: string,
    isNewCard: boolean
  ): Promise<CardTransaction> {
    //get card country from card billing address instead of the users user country.
    let { billingCountry } = usePaymentsStore().selectedPaymentMethod
    const currency = useSendMoneyStore().getCurrencyFrom

    const user = authStore.user
    if (user?.customer) {
      //if billing country doesn't exist we fallback to the user country
      billingCountry ||= user.customer.country
      const { data } = await postCardTransaction.exec({
        country: billingCountry,
        currency: currency,
        cvn: securityCode,
        trxRef,
        profileId: user.customer.id,
        isNewCard,
      })
      if (data) {
        const cardTransaction: CardTransaction = {
          ...data,
          trxRef,
          payerAuthenticationReferenceId: trxRef,
        }
        return cardTransaction
      }
    }
    throw new Error(`Failed to start card transaction`)
  }

  async function startCardTransactionAdyen(
    trxRef: string
  ): Promise<Nullable<CardTransactionResult>> {
    const envStore = useEnvStore()

    let adyenData = getAdyenData.value
    const form = useSendMoneyStore().form

    if (!adyenData) {
      const paymentMethod = usePaymentsStore().selectedPaymentMethod

      const userProfile = useAuthStore().userProfile

      const senderCountry = userProfile?.country
      const userEmail = userProfile?.customer.email

      adyenData = {
        countryCode: senderCountry,
        ccy: sendMoneyStore.getCurrencyFrom,
        payerAuthReferenceId: null,
        emailAddress: userEmail,
        cardId: paymentMethod.id,
        amount: 0,
        merchantReference: null,
        recipientId: '',
        card: {
          encryptedSecurityCode: form.cardSecurityCode ?? undefined,
        },
        browserInfo: new BrowserInfo(),
        returnUrlOverride: envStore.env.VUE_APP_ADYEN_HOSTED_3DS_RETURN_URL,
        originUrlOverride: null, //"http://127.0.0.1:8080",
      }
    }

    adyenData.amount = form.totalAmount
    adyenData.merchantReference = trxRef

    if (form.recipient) {
      adyenData.recipientId = form.recipient?.id.toString()
    }

    adyenData.browserInfo = new BrowserInfo()

    if (!adyenData.card) {
      console.error("Adyen doesn't contain card data")
      return null
    }
    let result = null

    result = await postCardTransactionV2.exec(adyenData)

    let cardTransaction: CardTransactionResult = {
      data: {
        requirePayerAuthentication: result.data.requirePayerAuthentication,
        payerAuthentication: result.data.payerAuthentication,
      },
    }

    return cardTransaction
  }

  async function startCardTransactionApexx(trxRef: string): Promise<CardTransaction> {
    let apexxData = cardAccountForm.apexxData

    //we dont have apexxData if we use an already existing card
    //it gets populated only when creating a new card.
    if (!apexxData) {
      const senderCountry = useAuthStore().userProfile?.country ?? ''
      apexxData = {
        countryCode: senderCountry,
        ccy: sendMoneyStore.getCurrencyFrom,
      }
    }

    const { data } = await getCardPayerAuthTokensV2.exec({
      country: apexxData.countryCode,
      currency: apexxData.ccy,
    })

    const cardTransaction: CardTransaction = {
      ...data,
      trxRef,
      payerAuthenticationReferenceId: trxRef,
    }
    return cardTransaction
  }

  async function fetchCardSettings(
    country: string | null = null,
    currency: string | null = null
  ): Promise<CardSettings> {
    const user = authStore.user
    const countriesStore = useCountriesStore()
    let cardSettingsResults = getCardSettings.value
    const countryNotSet = country === null

    if (cardSettingsResults && !country) {
      return cardSettingsResults
    }

    if (!country) {
      country = user?.customer?.country ?? null
    }

    if (!currency) {
      currency = sendMoneyStore.getCurrencyFrom
    }

    if (useApexx.value || useAdyen.value) {
      const { data } = await getCardSettingsV2.exec({
        currency,
        country
      })

      cardSettingsResults = data
    } else {
      //fix in order to get the correct value for savedCardLimitReached
      try {
        const { data } = await getCardSettingsApi.exec({
          currency: currency,
          country: country,
          profileId: user?.customer.id,
        })
        if (cardSettingsResults) {
          data.savedCardLimitReached = cardSettingsResults.savedCardLimitReached
          cardSettingsResults = Object.assign(cardSettingsResults, data)
        } else {
          cardSettingsResults = data
        }
      } catch (ex) {
        useSendMoneyStore().form.currencyFrom = countriesStore.getCountryDefaultCurrency

        //if the country was not set try again with the country
        if (countryNotSet) return fetchCardSettings(country)
      }
    }

    setCardSettings(cardSettingsResults)

    if (!cardSettingsResults) {
      throw Error('Error retrieving card settings: cardSettingsResults is not defined.')
    }

    return cardSettingsResults
  }

  function clearCardInformation() {
    saveCardNumberForIdentification(null)
  }

  function setCardNumberForIdentification(cardNumber: string) {
    saveCardNumberForIdentification(cardNumber)
  }

  function getIsNewCard() {
    const paymentMethods = usePaymentsStore().allPaymentMethods
    const paymentMethodId = sendMoneyStore.form.paymentMethodId
    const paymentMethodMatch = paymentMethods.find((pm: any) => pm.id == paymentMethodId)

    let isNewCard = !paymentMethodMatch
    return isNewCard
  }

  return {
    selectedCardForUpdate,
    cardAccountForm,
    cardInformation,
    cardTransactionResult,
    cardSettings,
    billingAddress,
    getAdyenData,
    useAdyen,
    useApexx,
    useCybersource,
    useApexxLegacy,
    getCorrectTransferId,
    getCybersourceData,
    getCardTransactionResult,
    getCardSettings,
    getCardInformation,
    transactionReference,
    resetForm,
    setApexxData,
    storeCardTransactionResult,
    resetCardSettings,
    setCardSettings,
    saveCardNumberForIdentification,
    setCybersourceData,
    requestCardInfo,
    retrieveApexxData,
    addCard,
    addCardCyberSource,
    addCardApexx,
    encryptCardApexx,
    verifyCardAuthentication,
    authorize3DSV2,
    startCardTransaction,
    startCardTransactionCybersource,
    startCardTransactionApexx,
    fetchCardSettings,
    clearCardInformation,
    setCardNumberForIdentification,
    getIsNewCard,
    cardBrand,
    cardAuthorisationType,
    cardBillingCountry,
  }
})
