import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  (phoneNumber, config) => {
    return {
      ...config,
      method: 'GET',
      url: `twofactorauth/LookupPhoneNumber?PhoneNumber=${phoneNumber}`,
    }
  },
  (response) => {
    return {
      ...response,
      data: response.data,
      statusCode: response.statusCode
    }
  },
  (error) => {
    let ex = { ...error }
    ex.toString = null
    return ex.response
  }
)
