



































import { useValidation } from 'vue-composable'
import { defineComponent, ref } from '@vue/composition-api'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { useI18nStore, useCorporateStore, useKYCRefreshCorporateStore } from '@galileo/stores'

// Components
import { AppModalFooter, AppButton, AppInputText } from '@oen.web.vue2/ui'

// Steps
import KYCRefreshCorporateCard from './KYCRefreshCorporateCard.vue'
import { KycRefreshCorporateStepEnum } from '@galileo/stores/kycRefreshCorporate'

export default defineComponent({
  name: 'KYCRefreshCorporateBusinessName',
  components: {
    AppButton,
    AppModalFooter,
    KYCRefreshCorporateCard,
    AppInputText,
  },
  setup() {
    const { $t } = useI18nStore()
    const corporateStore = useCorporateStore()
    const kycRefreshCorporateStore = useKYCRefreshCorporateStore()

    // Input
    const validation = useValidation({
      businessName: {
        $value: ref<string>(kycRefreshCorporateStore.nameStep?.data || corporateStore?.clientUserDetails?.companyDetails?.registeredName || ''),
        required: {
          $validator: required.$validator,
          $message: $t('PageSendMoneyAmount.AmountToRequired').value,
        },
        maxLength: {
          $validator: (value: string) => maxLength(50).$validator(value),
          $message: $t('ComponentKYCRefreshCorporateBusinessName.Error').value,
        },
        minLength: {
          $validator: (value: string) => minLength(0).$validator(value),
          $message: $t('ComponentKYCRefreshCorporateBusinessName.Error').value,
        }
      },
    })

    return {
      $t,
      validation,
      goToStep: kycRefreshCorporateStore.goToStep,
      saveBusinessName: kycRefreshCorporateStore.saveBusinessName,
      KycRefreshCorporateStepEnum,
    }
  },
})
