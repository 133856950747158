<template>
  <div class="h-full">
    <AppModal
      v-model="model"
      :title="$t('EditAddressModal.Title').value"
      @show="reinitializeFormFields"
    >
      <template #header="{ dismiss }">
        <AppModalHeader>
          <h1>{{ $t('EditAddressModal.Title').value }}</h1>
          <template #left>
            <XeBackButton analytics-name="change-address-close" icon="<" @click="dismiss" />
          </template>
        </AppModalHeader>
      </template>

      <template #default>
        <!-- Country selector -->
        <AppInputCountry
          id="address-search"
          :empty-filter-message="$t('AddressSearch.noResult').value"
          v-model="selectedCountry"
          :label="$t('EditAddressModal.CountryOfResidenceLabel').value"
          :countries="countries"
          :disabled="!allowChangeCountry"
          :show-manual-selection="false"
          border
          country-name-after-icon
          analytics-name="edit-address-modal-country-picker"
          :placeholder="$t('ComponentRecipientAddressModal.CountryPlaceholder').value"
          @input="clearFormFields"
          @change="countryChanged"
        />

        <XeAddressSearch
          v-if="!showManualFields"
          :key="`search-${selectedCountry}`"
          analytics-name="edit-address-modal-address-search"
          :dropdown-label="$t('PageAccount.PersonalInfoSectionAddressLabel').value"
          :display-value="formFields.addressLine1"
          :show-manual-selection="false"
          :search-promise-lazy="searchPromiseLazy"
          :get-address-details-promise-lazy="getAddressDetailsPromiseLazy"
          :should-output-empty-string="true"
          :validation="formFieldsValidation.addressLine1"
          @select="onSelectAddress"
          @manual="onManualAddress"
        />

        <!-- Country forms -->
        <!-- United Kingdom -->
        <form
          v-if="formDisplayCountry === 'GB'"
          id="address-form"
          :key="`form-${selectedCountry}`"
          @submit.prevent
        >
          <div class="address-lines">
            <AppInputText
              v-if="showManualFields"
              v-model="formFields.addressLine1"
              :label="$t('PageAccount.PersonalInfoSectionAddressLabel').value"
              :placeholder="
                $t('ComponentRecipientAddressModal.GBAddressFormat.AddressLine1Placeholder').value
              "
              :validation="formFieldsValidation.addressLine1"
              analytics-name="edit-address-modal-address-line-1"
              class="address-line"
            />
            <AppInputText
              v-model="formFields.addressLine2"
              :validation="formFieldsValidation.addressLine2"
              analytics-name="edit-address-modal-address-line-2"
              class="address-line"
            />
            <AppInputText
              v-model="formFields.addressLine3"
              :validation="formFieldsValidation.addressLine3"
              analytics-name="edit-address-modal-address-line-3"
              class="address-line"
            />
          </div>

          <AppInputText
            v-model="formFields.place"
            :validation="formFieldsValidation.place"
            analytics-name="edit-address-modal-place"
            :label="$t('ComponentRecipientAddressModal.GBAddressFormat.PlaceLabel').value"
            :placeholder="
              $t('ComponentRecipientAddressModal.GBAddressFormat.PlacePlaceholder').value
            "
          />
          <AppInputText
            v-model="formFields.county"
            :validation="formFieldsValidation.county"
            analytics-name="edit-address-modal-county"
            :label="`${$t('ComponentRecipientAddressModal.GBAddressFormat.CountyLabel').value} (${
              $t('ComponentRecipientAddressModal.OptionalText').value
            })`"
            :placeholder="
              $t('ComponentRecipientAddressModal.GBAddressFormat.CountyPlaceholder').value
            "
          />
          <AppInputText
            v-model="formFields.postalCode"
            :validation="formFieldsValidation.postalCode"
            analytics-name="edit-address-modal-postal-code"
            :label="$t('ComponentRecipientAddressModal.GBAddressFormat.PostalCodeLabel').value"
            :placeholder="
              $t('ComponentRecipientAddressModal.GBAddressFormat.PostalCodePlaceholder').value
            "
            @input="capitalizePostcode"
          />
        </form>

        <!-- United States -->
        <form
          v-if="formDisplayCountry === 'US'"
          id="address-form"
          :key="`USform-${selectedCountry}`"
          @submit.prevent
        >
          <AppInputText
            v-model="formFields.unit"
            :validation="formFieldsValidation.unit"
            analytics-name="edit-address-modal-unit"
            :label="`${$t('ComponentRecipientAddressModal.USAddressFormat.UnitLabel').value} (${
              $t('ComponentRecipientAddressModal.OptionalText').value
            })`"
            :placeholder="
              $t('ComponentRecipientAddressModal.USAddressFormat.UnitPlaceholder').value
            "
          />
          <AppInputText
            v-model="formFields.streetNumber"
            :validation="formFieldsValidation.streetNumber"
            analytics-name="edit-address-modal-street-number"
            :label="$t('ComponentRecipientAddressModal.USAddressFormat.StreetNumberLabel').value"
            :placeholder="
              $t('ComponentRecipientAddressModal.USAddressFormat.StreetNumberPlaceholder').value
            "
          />
          <AppInputText
            v-model="formFields.streetName"
            :validation="formFieldsValidation.streetName"
            analytics-name="edit-address-modal-street-name"
            :label="$t('ComponentRecipientAddressModal.USAddressFormat.StreetNameLabel').value"
            :placeholder="
              $t('ComponentRecipientAddressModal.USAddressFormat.StreetNamePlaceholder').value
            "
          />
          <AppInputDropdown
            v-model="formFields.streetType"
            :validation="formFieldsValidation.streetType"
            analytics-name="edit-address-modal-street-type"
            :options="streetTypes"
            selected-display-field="name"
            selected-value-field="name"
            data-key="id"
            :label="$t('ComponentRecipientAddressModal.USAddressFormat.StreetTypeLabel').value"
            :placeholder="
              $t('ComponentRecipientAddressModal.USAddressFormat.StreetTypePlaceholder').value
            "
          />
          <AppInputText
            v-model="formFields.place"
            :validation="formFieldsValidation.place"
            analytics-name="edit-address-modal-city"
            :label="$t('ComponentRecipientAddressModal.USAddressFormat.CityLabel').value"
            :placeholder="
              $t('ComponentRecipientAddressModal.USAddressFormat.CityPlaceholder').value
            "
          />
          <div class="side-by-side-inputs">
            <AppInputDropdown
              v-model="formFields.state"
              :validation="formFieldsValidation.state"
              analytics-name="edit-address-modal-state"
              :options="statesProvincesCities"
              selected-display-field="name"
              selected-value-field="name"
              data-key="id"
              :label="$t('ComponentRecipientAddressModal.USAddressFormat.StateLabel').value"
              :placeholder="
                $t('ComponentRecipientAddressModal.USAddressFormat.StatePlaceholder').value
              "
            />
            <AppInputText
              v-model="formFields.postalCode"
              :validation="formFieldsValidation.postalCode"
              analytics-name="edit-address-modal-zip-code"
              :label="$t('ComponentRecipientAddressModal.USAddressFormat.ZipCodeLabel').value"
              :placeholder="
                $t('ComponentRecipientAddressModal.USAddressFormat.ZipCodePlaceholder').value
              "
              @input="capitalizePostcode"
            />
          </div>
        </form>

        <!-- Canada -->
        <form
          v-if="formDisplayCountry === 'CA'"
          id="address-form"
          class="address-form-CA"
          :key="`CAform-${selectedCountry}`"
          @submit.prevent
        >
          <AppInputText
            v-model="formFields.unit"
            :validation="formFieldsValidation.unit"
            analytics-name="edit-address-modal-unit"
            :label="`${$t('ComponentRecipientAddressModal.CAAddressFormat.UnitLabel').value} (${
              $t('ComponentRecipientAddressModal.OptionalText').value
            })`"
            :placeholder="
              $t('ComponentRecipientAddressModal.CAAddressFormat.UnitPlaceholder').value
            "
          />
          <AppInputText
            v-model="formFields.streetNumber"
            :validation="formFieldsValidation.streetNumber"
            analytics-name="edit-address-modal-street-number"
            :label="$t('ComponentRecipientAddressModal.USAddressFormat.StreetNumberLabel').value"
            :placeholder="
              $t('ComponentRecipientAddressModal.USAddressFormat.StreetNumberPlaceholder').value
            "
          />
          <AppInputText
            v-model="formFields.buildingName"
            :validation="formFieldsValidation.buildingName"
            analytics-name="edit-address-modal-building"
            :label="`${$t('ComponentRecipientAddressModal.CAAddressFormat.BuildingLabel').value} (${
              $t('ComponentRecipientAddressModal.OptionalText').value
            })`"
            :placeholder="
              $t('ComponentRecipientAddressModal.CAAddressFormat.BuildingPlaceholder').value
            "
          />
          <AppInputText
            v-model="formFields.streetName"
            :validation="formFieldsValidation.streetName"
            analytics-name="edit-address-modal-street-name"
            :label="$t('ComponentRecipientAddressModal.USAddressFormat.StreetNameLabel').value"
            :placeholder="
              $t('ComponentRecipientAddressModal.USAddressFormat.StreetNamePlaceholder').value
            "
          />
          <AppInputDropdown
            v-model="formFields.streetType"
            :validation="formFieldsValidation.streetType"
            analytics-name="edit-address-modal-street-type"
            :options="streetTypes"
            selected-display-field="name"
            selected-value-field="name"
            data-key="id"
            :label="$t('ComponentRecipientAddressModal.USAddressFormat.StreetTypeLabel').value"
            :placeholder="
              $t('ComponentRecipientAddressModal.USAddressFormat.StreetTypePlaceholder').value
            "
          />
          <AppInputText
            v-model="formFields.place"
            :validation="formFieldsValidation.place"
            analytics-name="edit-address-modal-place"
            :label="$t('ComponentRecipientAddressModal.CAAddressFormat.PlaceLabel').value"
            :placeholder="
              $t('ComponentRecipientAddressModal.CAAddressFormat.PlacePlaceholder').value
            "
          />
          <AppInputDropdown
            v-model="formFields.state"
            :validation="formFieldsValidation.state"
            analytics-name="edit-address-modal-province"
            :options="statesProvincesCities"
            selected-display-field="name"
            selected-value-field="name"
            data-key="id"
            :label="$t('ComponentRecipientAddressModal.CAAddressFormat.ProvinceLabel').value"
            :placeholder="
              $t('ComponentRecipientAddressModal.CAAddressFormat.ProvincePlaceholder').value
            "
          />
          <AppInputText
            v-model="formFields.postalCode"
            :validation="formFieldsValidation.postalCode"
            analytics-name="edit-address-modal-postal-code"
            :label="$t('ComponentRecipientAddressModal.CAAddressFormat.PostalCodeLabel').value"
            :placeholder="
              $t('ComponentRecipientAddressModal.CAAddressFormat.PostalCodePlaceholder').value
            "
            @input="capitalizePostcode"
          />
        </form>

        <!-- Australia -->
        <form
          v-if="formDisplayCountry === 'AU'"
          id="address-form"
          :key="`AUform-${selectedCountry}`"
          @submit.prevent
        >
          <AppInputText
            v-model="formFields.unit"
            :validation="formFieldsValidation.unit"
            analytics-name="edit-address-modal-unit"
            :label="`${$t('ComponentRecipientAddressModal.AUAddressFormat.UnitLabel').value} (${
              $t('ComponentRecipientAddressModal.OptionalText').value
            })`"
            :placeholder="
              $t('ComponentRecipientAddressModal.AUAddressFormat.UnitPlaceholder').value
            "
          />
          <AppInputText
            v-model="formFields.streetNumber"
            :validation="formFieldsValidation.streetNumber"
            analytics-name="edit-address-modal-street-number"
            :label="$t('ComponentRecipientAddressModal.USAddressFormat.StreetNumberLabel').value"
            :placeholder="
              $t('ComponentRecipientAddressModal.USAddressFormat.StreetNumberPlaceholder').value
            "
          />
          <AppInputText
            v-model="formFields.streetName"
            :validation="formFieldsValidation.streetName"
            analytics-name="edit-address-modal-street-name"
            :label="$t('ComponentRecipientAddressModal.USAddressFormat.StreetNameLabel').value"
            :placeholder="
              $t('ComponentRecipientAddressModal.USAddressFormat.StreetNamePlaceholder').value
            "
          />
          <AppInputDropdown
            v-model="formFields.streetType"
            :validation="formFieldsValidation.streetType"
            analytics-name="edit-address-modal-street-type"
            :options="streetTypes"
            selected-display-field="name"
            selected-value-field="name"
            data-key="id"
            :label="$t('ComponentRecipientAddressModal.USAddressFormat.StreetTypeLabel').value"
            :placeholder="
              $t('ComponentRecipientAddressModal.USAddressFormat.StreetTypePlaceholder').value
            "
          />
          <AppInputText
            v-model="formFields.place"
            :validation="formFieldsValidation.place"
            analytics-name="edit-address-modal-suburb"
            :label="$t('ComponentRecipientAddressModal.AUAddressFormat.SuburbLabel').value"
            :placeholder="
              $t('ComponentRecipientAddressModal.AUAddressFormat.SuburbPlaceholder').value
            "
          />
          <AppInputDropdown
            v-model="formFields.state"
            :validation="formFieldsValidation.state"
            analytics-name="edit-address-modal-state"
            :options="statesProvincesCities"
            selected-display-field="name"
            selected-value-field="name"
            data-key="id"
            :label="$t('ComponentRecipientAddressModal.AUAddressFormat.StateLabel').value"
            :placeholder="
              $t('ComponentRecipientAddressModal.AUAddressFormat.StatePlaceholder').value
            "
          />
          <AppInputText
            v-model="formFields.postalCode"
            :validation="formFieldsValidation.postalCode"
            analytics-name="edit-address-modal-postal-code"
            :label="$t('ComponentRecipientAddressModal.AUAddressFormat.PostalCodeLabel').value"
            :placeholder="
              $t('ComponentRecipientAddressModal.AUAddressFormat.PostalCodePlaceholder').value
            "
            @input="capitalizePostcode"
          />
        </form>

        <!-- New Zealand -->
        <form
          v-if="formDisplayCountry === 'NZ'"
          id="address-form"
          :key="`NZform-${selectedCountry}`"
          @submit.prevent
        >
          <AppInputText
            v-model="formFields.unit"
            :validation="formFieldsValidation.unit"
            analytics-name="edit-address-modal-unit"
            :label="`${$t('ComponentRecipientAddressModal.NZAddressFormat.UnitLabel').value} (${
              $t('ComponentRecipientAddressModal.OptionalText').value
            })`"
            :placeholder="
              $t('ComponentRecipientAddressModal.NZAddressFormat.UnitPlaceholder').value
            "
          />
          <AppInputText
            v-model="formFields.streetNumber"
            :validation="formFieldsValidation.streetNumber"
            analytics-name="edit-address-modal-street-number"
            :label="$t('ComponentRecipientAddressModal.USAddressFormat.StreetNumberLabel').value"
            :placeholder="
              $t('ComponentRecipientAddressModal.USAddressFormat.StreetNumberPlaceholder').value
            "
          />
          <AppInputText
            v-model="formFields.streetName"
            :validation="formFieldsValidation.streetName"
            analytics-name="edit-address-modal-street-name"
            :label="$t('ComponentRecipientAddressModal.USAddressFormat.StreetNameLabel').value"
            :placeholder="
              $t('ComponentRecipientAddressModal.USAddressFormat.StreetNamePlaceholder').value
            "
          />
          <AppInputDropdown
            v-model="formFields.streetType"
            :validation="formFieldsValidation.streetType"
            analytics-name="edit-address-modal-street-type"
            :options="streetTypes"
            selected-display-field="name"
            selected-value-field="name"
            data-key="id"
            :label="$t('ComponentRecipientAddressModal.USAddressFormat.StreetTypeLabel').value"
            :placeholder="
              $t('ComponentRecipientAddressModal.USAddressFormat.StreetTypePlaceholder').value
            "
          />
          <AppInputText
            v-model="formFields.area"
            :validation="formFieldsValidation.area"
            analytics-name="edit-address-modal-place"
            :label="`${$t('ComponentRecipientAddressModal.NZAddressFormat.PlaceLabel').value} (${
              $t('ComponentRecipientAddressModal.OptionalText').value
            })`"
            :placeholder="
              $t('ComponentRecipientAddressModal.NZAddressFormat.PlacePlaceholder').value
            "
          />
          <AppInputText
            v-model="formFields.place"
            :validation="formFieldsValidation.place"
            analytics-name="edit-address-modal-city"
            :label="$t('ComponentRecipientAddressModal.NZAddressFormat.CityLabel').value"
            :placeholder="
              $t('ComponentRecipientAddressModal.NZAddressFormat.CityPlaceholder').value
            "
          />
          <AppInputText
            v-model="formFields.postalCode"
            :validation="formFieldsValidation.postalCode"
            analytics-name="edit-address-modal-postal-code"
            :label="$t('ComponentRecipientAddressModal.NZAddressFormat.PostalCodeLabel').value"
            :placeholder="
              $t('ComponentRecipientAddressModal.NZAddressFormat.PostalCodePlaceholder').value
            "
            @input="capitalizePostcode"
          />
        </form>

        <!-- All other countries -->
        <form
          :key="`defaultForm-${selectedCountry}`"
          v-if="!isEnglishSpeakingCountry(formDisplayCountry)"
          id="address-form"
          @submit.prevent
        >
          <div class="address-lines">
            <AppInputText
              v-if="showManualFields"
              v-model="formFields.addressLine1"
              :label="$t('PageAccount.PersonalInfoSectionAddressLabel').value"
              :placeholder="
                $t('ComponentRecipientAddressModal.DefaultAddressFormat.AddressLine1Placeholder')
                  .value
              "
              :validation="formFieldsValidation.addressLine1"
              analytics-name="edit-address-modal-address-line-1"
              class="address-line"
            />
            <AppInputText
              v-model="formFields.addressLine2"
              :validation="formFieldsValidation.addressLine2"
              analytics-name="edit-address-modal-address-line-2"
              class="address-line"
            />
            <AppInputText
              v-model="formFields.addressLine3"
              :validation="formFieldsValidation.addressLine3"
              analytics-name="edit-address-modal-address-line-3"
              class="address-line"
            />
          </div>
          <AppInputText
            v-model="formFields.place"
            :validation="formFieldsValidation.place"
            analytics-name="edit-address-modal-place"
            :label="$t('ComponentRecipientAddressModal.DefaultAddressFormat.PlaceLabel').value"
            :placeholder="
              $t('ComponentRecipientAddressModal.DefaultAddressFormat.PlacePlaceholder').value
            "
          />
          <AppInputText
            v-model="formFields.county"
            :validation="formFieldsValidation.county"
            analytics-name="edit-address-modal-county"
            :label="$t('ComponentRecipientAddressModal.DefaultAddressFormat.CountyLabel').value"
            :placeholder="
              $t('ComponentRecipientAddressModal.DefaultAddressFormat.CountyPlaceholder').value
            "
          />
          <AppInputText
            v-model="formFields.postalCode"
            :validation="formFieldsValidation.postalCode"
            analytics-name="edit-address-modal-postal-code"
            :label="$t('ComponentRecipientAddressModal.DefaultAddressFormat.PostalCodeLabel').value"
            :placeholder="
              $t('ComponentRecipientAddressModal.GBAddressFormat.PostalCodePlaceholder').value
            "
            @input="capitalizePostcode"
          />
        </form>
      </template>

      <template #footer>
        <AppModalFooter>
          <AppButton
            type="submit"
            form="address-form"
            analytics-name="change-address-update-button"
            :loading="isSaving"
            :disabled="formFieldsValidation.$anyInvalid"
            @click="updateAddress"
          >
            {{ $t('EditAddressModal.UpdateButton').value }}
          </AppButton>
        </AppModalFooter>
      </template>
    </AppModal>
    <AddressChangeTermsAndConditionsModal />

    <AddressChangeDisallowedModal
      v-model="showAddressChangeDisallowedModal"
      @close="closeFromWarningModal"
      @gotIt="gotItWarningModal"
    />

    <AdditionalInformationModal
      v-if="showAdditionalInformationModal"
      v-model="showAdditionalInformationModal"
      :user="user"
      :country="selectedCountry"
      :address-fields="formFields"
      :on-save="onSave"
      :idType="idTypesFields"
      @close="closeFromWarningModal"
    />

    <CountryNotSupportedModal v-model="showCountryNotSupportedModal" />
  </div>
</template>

<script>
import { ref, reactive, computed, toRef, onBeforeMount } from '@vue/composition-api'
import { useValidation, usePromiseLazy } from 'vue-composable'
import { required, helpers } from '@vuelidate/validators'
import { removeDiacritics } from '@galileo/utilities/diacritics-convertor.utility'

import XeBackButton from '@galileo/components/XeBackButton/XeBackButton'
import XeAddressSearch from '@galileo/components/XeAddressSearch/XeAddressSearch'
import AdditionalInformationModal from '@galileo/components/Views/Account/EditSettingsModal/EditAddressModal/AdditionalInformationModal'
import AddressChangeDisallowedModal from '@galileo/components/Views/Account/EditSettingsModal/EditAddressModal/AddressChangeDisallowedModal'
import AddressChangeTermsAndConditionsModal from '@galileo/components/Views/Account/EditSettingsModal/EditAddressModal/AddressChangeTermsAndConditionsModal'
import CountryNotSupportedModal from '@galileo/components/Views/Account/EditSettingsModal/EditAddressModal/CountryNotSupportedModal'
import UserPersonalInformation from '@galileo/models/UserProfile/UserPersonalInformation'

import { STREET_TYPES } from '@galileo/constants/streetTypes'

import { useAddressValidation } from '@galileo/composables/useAddress'

import {
  useI18nStore,
  useActivityStore,
  useProfileStore,
  useAppStore,
  useCountriesStore,
  useResourcesStore,
  useAuthStore,
} from '@galileo/stores'

import {
  useVModel,
  useAlert,
  AppModal,
  AppInputCountry,
  AppInputDropdown,
  AppInputText,
  AppModalFooter,
  AppButton,
  AppModalHeader,
} from '@oen.web.vue2/ui'

export default {
  name: 'EditAddressModal',
  components: {
    AppModal,
    AppInputText,
    AppInputDropdown,
    AppInputCountry,
    AppModalFooter,
    AppButton,
    XeBackButton,
    XeAddressSearch,
    AddressChangeDisallowedModal,
    AddressChangeTermsAndConditionsModal,
    AdditionalInformationModal,
    CountryNotSupportedModal,
    AppModalHeader,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    allowChangeCountry: {
      type: Boolean,
      default: true,
    },
    onSave: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { add } = useAlert()
    const { model } = useVModel(props, emit)
    const resourcesStore = useResourcesStore()
    const countriesStore = useCountriesStore()
    const appStore = useAppStore()
    const authStore = useAuthStore()
    const profileStore = useProfileStore()
    const activityStore = useActivityStore()

    const { postalCodeValidator } = useAddressValidation()

    let currentUserCountry = ref(props.user?.country)
    let selectedCountry = ref(props.user?.country)
    let supportedCountries = ref(resourcesStore.getSupportedCountries)

    let userInfo = new UserPersonalInformation(props.user)
    let userTemp = reactive(userInfo)

    const isSaving = ref(false)
    const showAddressChangeDisallowedModal = ref(false)
    const showAdditionalInformationModal = ref(false)
    const showCountryNotSupportedModal = ref(false)

    const showManualFields = ref(false)
    const idTypesFields = ref(null)
    let streetTypes = [...new Set(Object.values(STREET_TYPES))].map((item) => {
      return {
        name: item,
      }
    })

    let formFields = reactive({
      country: selectedCountry.value,
      addressLine1: props.user?.extraFields?.addressLine1 || props.user?.address,
      addressLine2: props.user?.extraFields?.addressLine2 || '',
      addressLine3: props.user?.extraFields?.addressLine3 || '',
      streetNumber: props.user?.extraFields?.streetNumber || '',
      streetName: props.user?.extraFields?.streetName || '',
      streetType: props.user?.extraFields?.streetType || '',
      place: props.user?.extraFields?.place || '', // city, town, locality, suburb (Australia)
      county: props.user?.extraFields?.county || '', // county
      postalCode: props.user?.extraFields?.postalCode || '', // postal code, post code, zip code
      unit: props.user?.extraFields?.unit || '',
      buildingName: props.user?.extraFields?.buildingName || '',
      area: props.user?.extraFields?.area || '', // suburb or locality (New Zealand) - TODO: maybe just use county instead?
      state: props.user?.extraFields?.state || '', // state, province, municipality
    })

    const reinitializeFormFields = () => {
      selectedCountry.value = props.user?.country
      formFields.country = selectedCountry
      formFields.addressLine1 = props.user?.extraFields?.addressLine1 || props.user?.address
      formFields.addressLine2 = props.user?.extraFields?.addressLine2 || ' '
      formFields.addressLine3 = props.user?.extraFields?.addressLine3 || ' '
      formFields.streetNumber = props.user?.extraFields?.streetNumber || ''
      formFields.streetName = props.user?.extraFields?.streetName || ''
      formFields.streetType = props.user?.extraFields?.streetType || ''
      formFields.place = props.user?.extraFields?.place || ''
      formFields.county = props.user?.extraFields?.county || ''
      formFields.postalCode = props.user?.extraFields?.postalCode || ''
      formFields.unit = props.user?.extraFields?.unit || ''
      formFields.buildingName = props.user?.extraFields?.buildingName || ''
      formFields.area = props.user?.extraFields?.area || ''
      formFields.state = props.user?.extraFields?.state || ''
      showManualFields.value = false
    }

    // Returns a list of countries
    const countries = computed(() => {
      return countriesStore.getCountries.map((country, idx) => {
        return {
          id: idx,
          text: country.text,
          value: country.value,
        }
      })
    })

    const statesProvincesCities = ref([])

    onBeforeMount(async () => {
      statesProvincesCities.value = await countriesStore.getStatesByCountryCode(
        selectedCountry.value
      )
    })

    // Trasforms value of post code form field into uppercase
    const capitalizePostcode = () => {
      formFields.postalCode = formFields.postalCode.toUpperCase()
    }

    const isEUCountry = (country) => {
      return [
        'BE',
        'BG',
        'CZ',
        'DK',
        'DE',
        'EE',
        'IE',
        'EL',
        'ES',
        'FR',
        'HR',
        'IT',
        'CY',
        'LV',
        'LT',
        'LU',
        'HU',
        'MT',
        'NL',
        'AT',
        'PL',
        'PT',
        'RO',
        'SI',
        'SK',
        'FI',
        'SE',
      ].includes(country)
    }

    const isEnglishSpeakingCountry = (country) => {
      return ['GB', 'US', 'CA', 'AU', 'NZ'].includes(country)
    }

    const formDisplayCountry = computed(() => {
      if (isEUCountry(selectedCountry.value) || isEnglishSpeakingCountry(selectedCountry.value)) {
        return selectedCountry.value
      } else {
        return currentUserCountry.value
      }
    })

    // Form validation
    let formFieldsValidation = useValidation({
      country: {
        $value: toRef(formFields, 'country'),
        required: {
          $validator: required.$validator,
          $message: $t('EditAddressModal.ErrorRequiredField'),
        },
      },
      addressLine1: {
        $value: toRef(formFields, 'addressLine1'),
        requiredAddressLine1: {
          $validator(v) {
            // Required for countries other than US, CA, NZ and AU
            if (!['US', 'CA', 'NZ', 'AU'].includes(selectedCountry.value)) {
              return !!v
            }
            return true
          },
          $message: $t('EditAddressModal.ErrorRequiredField'),
        },
        addressLine1Valid: {
          $validator: helpers.regex(/^[\p{L}\d'/_&()., "\u2018\u2019-]{1,70}$/u),
          $message: $t('EditAddressModal.ErrorDoesNotMatchRequirements').value,
        },
      },
      addressLine2: {
        $value: toRef(formFields, 'addressLine2'),
        addressLine2Valid: {
          $validator: helpers.regex(/^[\p{L}\d'/_&()., "\u2018\u2019-]{1,70}$/u),
          $message: $t('EditAddressModal.ErrorDoesNotMatchRequirements').value,
        },
      },
      addressLine3: {
        $value: toRef(formFields, 'addressLine3'),
        addressLine3Valid: {
          $validator: helpers.regex(/^[\p{L}\d'/_&()., "\u2018\u2019-]{1,70}$/u),
          $message: $t('EditAddressModal.ErrorDoesNotMatchRequirements').value,
        },
      },
      streetNumber: {
        $value: toRef(formFields, 'streetNumber'),
        requiredStreetNumber: {
          $validator(v) {
            // Required for US, CA, NZ and AU
            if (['US', 'CA', 'NZ', 'AU'].includes(formDisplayCountry.value)) {
              return !!v
            }
            return true
          },
          $message: $t('EditAddressModal.ErrorRequiredField'),
        },
        validStreetNumber: {
          $validator: helpers.regex(/^[\p{L}\d'/_&()., "\u2018\u2019-]{1,20}$/u),
          $message: $t('EditAddressModal.ErrorDoesNotMatchRequirements').value,
        },
      },
      streetName: {
        $value: toRef(formFields, 'streetName'),
        requiredStreetName: {
          $validator(v) {
            // Required for US, CA, NZ and AU
            if (['US', 'CA', 'NZ', 'AU'].includes(formDisplayCountry.value)) {
              return !!v
            }
            return true
          },
          $message: $t('EditAddressModal.ErrorRequiredField'),
        },
        validStreetName: {
          $validator: helpers.regex(/^[\p{L}\d'/_&()., "\u2018\u2019-]{1,70}$/u),
          $message: $t('EditAddressModal.ErrorDoesNotMatchRequirements').value,
        },
      },
      streetType: {
        $value: toRef(formFields, 'streetType'),
        required: {
          $validator(v) {
            // Required for US, CA, NZ and AU
            if (['US', 'CA', 'NZ', 'AU'].includes(formDisplayCountry.value)) {
              required.$validator(v)
            }
            return true
          },
          $message: $t('EditAddressModal.ErrorRequiredField'),
        },
        validStreetType: {
          $validator: helpers.regex(/^[\p{L}\d][ \p{L}\d'/.\u2018\u2019-]{0,68}[\p{L}\d]$/u),
          $message: $t('EditAddressModal.ErrorDoesNotMatchRequirements').value,
        },
      },
      place: {
        $value: toRef(formFields, 'place'),
        required: {
          $validator: required.$validator,
          $message: $t('EditAddressModal.ErrorRequiredField'),
        },
        validPlace: {
          $validator: helpers.regex(/^[\p{L}\d'/_&()., "\u2018\u2019-]{1,70}$/u),
          $message: $t('EditAddressModal.ErrorDoesNotMatchRequirements').value,
        },
      },
      county: {
        $value: toRef(formFields, 'county'),
        // not strictly required anywhere
        validCounty: {
          $validator: helpers.regex(/^[\p{L}][ \p{L}'/.-]{0,68}[\p{L}]$/u),
          $message: $t('EditAddressModal.ErrorDoesNotMatchRequirements').value,
        },
      },
      postalCode: {
        $value: toRef(formFields, 'postalCode'),
        required: {
          $validator: required.$validator,
          $message: $t('EditAddressModal.ErrorRequiredField'),
        },
        postalCodeValidation: {
          $validator: () => postalCodeValidator(selectedCountry.value),
          $message: $t('EditAddressModal.ErrorDoesNotMatchRequirements').value,
        },
      },
      unit: {
        $value: toRef(formFields, 'unit'),
        // not strictly required anywhere
        validUnit: {
          $validator: helpers.regex(/^[\p{L}\d'/_&()., "\u2018\u2019-]{1,20}$/u),
          $message: $t('EditAddressModal.ErrorDoesNotMatchRequirements').value,
        },
      },
      buildingName: {
        $value: toRef(formFields, 'buildingName'),
        // not strictly required anywhere
        validBuildingName: {
          $validator: helpers.regex(/^[\p{L}\d'/_&()., "\u2018\u2019-]{1,70}$/u),
          $message: $t('EditAddressModal.ErrorDoesNotMatchRequirements').value,
        },
      },
      area: {
        $value: toRef(formFields, 'area'),
        // not strictly required anywhere
        validArea: {
          $validator: helpers.regex(/^[\p{L}\d'/_&()., "\u2018\u2019-]{1,70}$/u),
          $message: $t('EditAddressModal.ErrorDoesNotMatchRequirements').value,
        },
      },
      state: {
        $value: toRef(formFields, 'state'),
        requiredState: {
          $validator(v) {
            // if not using the selected country's particular form, don't require state
            if (
              selectedCountry.value !== formDisplayCountry.value &&
              !['US', 'CA', 'AU'].includes(formDisplayCountry.value)
            ) {
              return true
            }
            // required in US, CA, AU
            if (
              formDisplayCountry.value === 'US' ||
              formDisplayCountry.value === 'CA' ||
              formDisplayCountry.value === 'AU'
            ) {
              return !!v
            }
            return true
          },
          $message: $t('EditAddressModal.ErrorRequiredField'),
        },
        validState: {
          $validator(v) {
            if (
              selectedCountry.value !== formDisplayCountry.value &&
              !['US', 'CA', 'AU'].includes(formDisplayCountry.value)
            ) {
              return true
            }
            if (
              formDisplayCountry.value === 'US' ||
              formDisplayCountry.value === 'CA' ||
              formDisplayCountry.value === 'AU'
            ) {
              const validStateRegex = new RegExp(/^[\p{L}][ \p{L}]{0,68}[\p{L}]$/u)
              return validStateRegex.test(v)
            }
            return true
          },
          $message: $t('EditAddressModal.ErrorDoesNotMatchRequirements').value,
        },
      },
    })

    // Sets all form fields (excluding country) to an empty string
    // (used on changing country in dropdown)
    const clearFormFields = () => {
      formFields.addressLine1 = ''
      formFields.addressLine2 = ''
      formFields.addressLine3 = ''
      formFields.streetNumber = ''
      formFields.streetName = ''
      formFields.streetType = ''
      formFields.place = ''
      formFields.county = ''
      formFields.postalCode = ''
      formFields.unit = ''
      formFields.buildingName = ''
      formFields.area = ''
      formFields.state = ''
      formFieldsValidation.$reset()
    }

    const transactionsInProgress = computed(async () => {
      await activityStore.getTransactions()
      const transactions = activityStore.getLastTransactions

      if (transactions) {
        const pendingTransactions = transactions.filter((transaction) => {
          return transaction.status !== 'Completed'
        })
        if (pendingTransactions) {
          return pendingTransactions.length
        }
      }
      return null
    })

    const searchPromiseLazy = reactive(
      usePromiseLazy(({ query, selectedId = null }) => {
        formFields.addressLine1 = query
        let result = resourcesStore.getAddressSearchResult({
          searchTerm: query,
          searchContext: selectedId,
          country: selectedCountry.value,
        })
        return result
      })
    )

    const getAddressDetailsPromiseLazy = reactive(
      usePromiseLazy((selectedId) => {
        let result = resourcesStore.getAddressDetails(selectedId)
        return result
      })
    )

    const extractStreetType = (streetName) => {
      if (!streetName) {
        return null
      }

      const streetTypes = STREET_TYPES
      let parts = streetName.split(' ')
      if (parts.length < 0) {
        return null
      }

      // Last part (word) of streetName is the street type
      let streetType = parts[parts.length - 1]
      if (streetType.length === 1 && parts.length >= 3) {
        // Handle the case when the street type contains 2 words e.g. Avenue North (Ave N)
        const streetTypePrefix = parts[parts.length - 2]
        streetType = `${streetTypePrefix} ${streetType}`
      }
      let newStreetName = streetName.replace(streetType, '').trim()
      streetType = streetType.toUpperCase()
      let streetTypeValue = null
      if (streetTypes[streetType]) {
        streetTypeValue = streetTypes[streetType]
      } else {
        for (const key in streetTypes) {
          const value = streetTypes[key]
          if (value && value.toUpperCase() === streetType) {
            streetTypeValue = streetTypes[key]
            break
          }
        }
      }

      if (!streetTypeValue) {
        newStreetName = streetName
      }

      const newStreetValues = {
        streetName: newStreetName,
        streetType: streetTypeValue,
      }

      return newStreetValues
    }

    const applyStreetType = (values) => {
      let streetType = values.streetType
      if (!streetType) {
        return
      }
      let streetName = values.streetName
      if (!streetName) {
        return
      }

      streetName = streetName.toUpperCase()
      let pos = streetName.lastIndexOf(streetType.toUpperCase())
      if (pos <= 0) {
        const streetTypes = STREET_TYPES
        for (const key in streetTypes) {
          const value = streetTypes[key]
          if (value && value === streetType) {
            pos = streetName.lastIndexOf(key.toUpperCase())
            break
          }
        }
      }

      // remove street type from street name
      if (pos > 0) {
        values.streetName = values.streetName.substr(0, pos).trim()
      }
    }

    const onSelectAddress = (address) => {
      clearFormFields()
      formFields.addressLine1 = removeDiacritics(address.addressLine1)
      formFields.addressLine2 = removeDiacritics(address.addressLine2)
      formFields.addressLine3 = removeDiacritics(address.addressLine3)
      formFields.place = address.city
      formFields.postalCode = address.postCode
      formFields.streetName = address.street
      formFields.streetNumber = address.buildingNumber
      formFields.country = address.countryCode
      if (['US', 'CA', 'AU', 'NZ'].includes(selectedCountry.value)) {
        let streetInfo = extractStreetType(address.street)
        formFields.streetType = streetInfo.streetType
        formFields.streetName = streetInfo.streetName
      }
      formFields.buildingName = address.buildingName
      if (['US', 'CA', 'AU'].includes(formDisplayCountry.value)) {
        formFields.state = address.provinceName
      }
      formFields.county = address.provinceName
      formFields.area = address.district
    }

    const onManualAddress = (hideDropdownFn) => {
      showManualFields.value = true
      hideDropdownFn()
    }

    const checkIsSupportedCountry = async () => {
      if (!supportedCountries.value.length) {
        await resourcesStore.getSystemFields(true)
        supportedCountries.value = resourcesStore.getSupportedCountries
      }

      const isCountryFieldsPresent =
        supportedCountries.value && supportedCountries.value.length && userTemp && userTemp.country
      const isSupportedCountry = supportedCountries.value
        .map((country) => country.value)
        .includes(userTemp.country)

      if (isCountryFieldsPresent && !isSupportedCountry) {
        return false
      }
      return true
    }

    const updateAddress = async () => {
      if (transactionsInProgress.value > 0 && currentUserCountry.value !== selectedCountry.value) {
        showAddressChangeDisallowedModal.value = true
        return
      }

      isSaving.value = true
      userTemp.removeNotMappedProperties()
      userTemp.removePhoneNumber()
      userTemp.removeFullName()
      userTemp.setAddress(formFields)

      try {
        const success = await profileStore.updateUserInfo(userTemp)
        if (success) {
          add(`${$t('EditAddressModal.AddressUpdated').value}`)
          authStore.showTACAfterAddressChange = true
          props.onSave()
          if (!checkIsSupportedCountry()) {
            throw new Error('Unsupported country')
          }
          model.value = false
        } else {
          appStore.messageBoxGenericError()
        }
      } catch (err) {
        // model.value = false
        if (err.error.code === 'idType') {
          idTypesFields.value = profileStore.findAdditionalValuesFromBEResponse(
            err.error.code,
            err.data
          )

          showAdditionalInformationModal.value = true
        } else if (err.message === 'Unsupported country') {
          showCountryNotSupportedModal.value = true
        } else {
          appStore.messageBoxGenericError()
        }
      }
      isSaving.value = false
    }

    const closeFromWarningModal = () => {
      showAddressChangeDisallowedModal.value = false
      model.value = false
    }

    const gotItWarningModal = () => {
      showAddressChangeDisallowedModal.value = false
    }

    const countryChanged = async (event) => {
      statesProvincesCities.value = await countriesStore.getStatesByCountryCode(event.value)
      selectedCountry.value = event.value
    }
    return {
      countryChanged,
      $t,
      model,
      countries,
      currentUserCountry,
      selectedCountry,
      statesProvincesCities,
      formFields,
      formFieldsValidation,
      capitalizePostcode,
      clearFormFields,
      transactionsInProgress,
      isEUCountry,
      isEnglishSpeakingCountry,
      formDisplayCountry,
      isSaving,
      showAddressChangeDisallowedModal,
      showAdditionalInformationModal,
      showCountryNotSupportedModal,
      showManualFields,
      streetTypes,
      updateAddress,
      closeFromWarningModal,
      gotItWarningModal,
      searchPromiseLazy,
      getAddressDetailsPromiseLazy,
      onSelectAddress,
      onManualAddress,
      reinitializeFormFields,
      supportedCountries,
      idTypesFields,
    }
  },
}
</script>

<style scoped>
.modal {
  ::v-deep .card-content-block {
    @apply mb-0;
    @screen sm {
      @apply mb-0;
    }
  }
}
.address-lines {
  @apply -mt-2;
}
.address-line {
  @apply mb-4;
}
.address-lines div:last-of-type {
  @apply mb-6;
}
.side-by-side-inputs {
  @apply flex gap-4;
  div {
    @apply flex-1;
  }
}
::v-deep .card-footer .button {
  @apply w-full;
}

::v-deep .card-content {
  @apply px-6;

  @screen sm {
    @apply px-12;
  }

  .card-content-block {
    @apply mb-6 text-sm leading-5;
  }
}

::v-deep .card.card--has-footer {
  @screen sm {
    @apply pb-12;
  }
}

.card-footer {
  .button {
    @apply w-full;
  }
}

.button.button--primary {
  &:disabled {
    &:not(.button--loading) {
      @apply text-white;
    }

    background: linear-gradient(
        to right,
        theme('colors.blue.button-disabled'),
        theme('colors.blue.button-disabled')
      ),
      linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
  }
}

::v-deep .input-input,
::v-deep .p-dropdown {
  @apply text-base leading-6 font-normal text-text-primary;
  @apply border border-solid;
  border-color: rgba(221, 221, 221, 1);
  @apply shadow-none;
}

::v-deep .input-label {
  @apply text-base leading-6 font-medium;
}

::v-deep .p-dropdown-items-wrapper {
  max-width: 22rem !important;
}
</style>
