const GALILEO_ACTIONS = {
  OPEN_PLAID_SAME_DAY: 'plaidSameDayOpen',
}

export const GALILEO_LANDING = {
  [GALILEO_ACTIONS.OPEN_PLAID_SAME_DAY]: 'payment-methods',
}

export function actionExists(action = null, actionName = 'galileoAction') {
  if (!action) {
    const urlParams = new URLSearchParams(window.location.search)
    action = urlParams.get(actionName)
  }

  const actions = Object.keys(GALILEO_ACTIONS)

  let result = null

  actions.forEach((key) => {
    let pivot = GALILEO_ACTIONS[key]
    if (pivot === action) {
      result = pivot
    }
  })

  return result
}

export function getActionRouteIfExists() {
  const action = actionExists(null, 'galileoAction')
  let route = null
  if (action === GALILEO_ACTIONS.OPEN_PLAID_SAME_DAY) {
    route = { name: 'AccountNewPaymentMethod', params: { shouldOpenPlaid: true } }
  }
  return route
}

export default GALILEO_ACTIONS
