import { FixedFormat } from "@galileo/models/Corporate/interfaces/CorpReg"

export function parseFixedFormatLine1({ unit, streetNumber, streetName, streetType }: Partial<FixedFormat>): string {
  const address = [
    unit && `${unit}-${streetNumber}`,
    !unit && streetNumber,
    streetName,
    streetType
  ]

  return address
    .filter(Boolean)
    .join(' ')
    .trim()
}

export function appendAddressString (currentAddress: string, newPart: string, separator = ', '): string {
  if (newPart && newPart.length > 0) {
    return `${currentAddress}${separator}${newPart}`
  }
  return currentAddress
}
