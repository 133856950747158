import { defineStore } from 'pinia'
import { computed, ref } from '@vue/composition-api'
import getRecipientEndpoint from '@galileo/constants/recipients/recipientEndpoints.const'
import { API_VERSIONS } from '@galileo/constants/recipients/recipientFields.const.js'
import { getAllInfoByISO } from 'iso-country-currency'

import getCashRecipients from '@galileo/api/launchpad/cashRecipients/get'
import getMobileWalletRecipients from '@galileo/api/launchpad/mobileWalletRecipients/get'
import getBankDetailsApi from '@galileo/api/launchpad/recipients/bankdetails/get'
import getCashFieldsApi from '@galileo/api/launchpad/cashRecipients/fields/get'
import addCashRecipientApi from '@galileo/api/launchpad/cashRecipients/post'
import deleteCashRecipientApi from '@galileo/api/launchpad/cashRecipients/delete'
import getCountries from '@galileo/api/launchpad/recipients/countries/_currencyCode/get'
import deleteRecipientApi from '@galileo/api/launchpad/recipients/_profileId/recipient/_id/delete'
import getBanksByNameOrCode from '@galileo/api/rmt/bank/search/put'
import getBankById from '@galileo/api/rmt/recipient/bankDeposit/get'
import RecipientModel from '@galileo/models/Recipient/app'
import updateBankDeposit from '@galileo/api/rmt/recipient/bankDeposit/put'
import getAgentCities from '@galileo/api/launchpad/destinations/countries/_countryIso/cities/get'
import getAgents from '@galileo/api/launchpad/destinations/correspondents/get'
import getLocations from '@galileo/api/launchpad/destinations/correspondents/_agentId/locations/get'
import getIndustryCategoriesApi from '@galileo/api/launchpad/resources/industrycategories/get'
import getIndustryTypesByCategoryApi from '@galileo/api/launchpad/resources/industrytypesbycategory/get'
import updateCashRecipientApi from '@galileo/api/launchpad/cashRecipients/patch'

import DefaultCurrencies from '@galileo/models/DefaultCurrencies'
import { BankRecipientResult } from '@galileo/models/Recipient/SpecificRecipientResult/app.js'
import { useFavorites } from '@galileo/composables/useFavorites'

import Agent from '@galileo/models/Agent/agent'
import City from '@galileo/models/Agent/city'
import Location from '@galileo/models/Agent/location'
import State from '@galileo/models/State/app'
import { IndustryCategory } from '@galileo/models/Industry/industryCategory'
import { IndustryType } from '@galileo/models/Industry/industryType'
import {
  Recipient,
  MobileWalletRecipient,
  CashRecipient,
  CashRecipientField,
  XeRecipientField,
  DeliveryMethod,
} from '@galileo/models/Recipient/interfaces'

import {
  useSendMoneyStore,
  useResourcesStore,
  useCountriesStore,
  useApiStore,
  useAppStore,
  useAuthStore,
  usePaymentReferenceStore,
} from '@galileo/stores'

import { REQUEST_CANCELATION_TOKEN_ENUMS } from '@galileo/constants/requestCancelationTokens'

export const useRecipientsStore = defineStore(
  'recipients',
  () => {
    // State
    const recipients = ref<Array<Recipient>>([])
    const recipientsAll = ref<Array<Recipient>>([])
    const recipientsCash = ref<Array<CashRecipient>>([])
    const recipientsMobileWallet = ref<Array<MobileWalletRecipient>>([])
    const selectedRecipient = ref<Recipient>({} as Recipient)
    const showAccountAddRecipient = ref<boolean>(false)
    const businessNameFieldRegex = ref<string>('')
    const nicknameFieldRegex = ref<string>('')
    const addressLine1FieldRegex = ref<string>('')
    const selectedAgentLocations = ref<Array<Location>>([])
    const selectedAgent = ref<Agent>()

    const authStore = useAuthStore()

    // Getters
    function getCorrectApi(call: string): any {
      let apiTable = getRecipientEndpoint(call, API_VERSIONS.V2)

      return apiTable
    }

    const getNicknameFieldRegex = computed((): RegExp => {
      let defaultRegex = new RegExp(/^[\sa-zA-Z\p{Script=Latin}0-9,\.:'\+\(\)\?\-/\x26@]{0,35}$/u)
      if (nicknameFieldRegex.value) {
        return new RegExp(nicknameFieldRegex.value, 'u')
      }
      return defaultRegex
    })

    const getBusinessNameFieldRegex = computed((): RegExp => {
      let defaultRegex = new RegExp(/^[a-zA-Z\p{Script=Latin}0-9\s\(\)\+\-'/\?\.:,]{1,70}$/u)
      if (businessNameFieldRegex.value) {
        return new RegExp(businessNameFieldRegex.value, 'u')
      }
      return defaultRegex
    })

    const getAddressLine1FieldRegex = computed((): RegExp => {
      let defaultRegex = new RegExp(
        /^(?!.*(?:(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(box|bin)[-.\s]*)|.*((p |post)[-.\s]*(box|bin)[-.\s]*))))(?:[a-zA-Z0-9,\s\(\)\+\-'\/\?\.:]{3,35})?$/
      )
      if (addressLine1FieldRegex.value) {
        let regexString = ''
        let flags = ''
        let addressLine1Field = addressLine1FieldRegex.value

        // this particular regex is returned in C#  regex syntax from the backend, so it needs
        // to be converted to Javascript regex syntax
        const conversionRegex = /^\(\?([ims])\)(.+)$/
        if (conversionRegex.test(addressLine1Field)) {
          const matches = addressLine1Field.match(conversionRegex)
          if (matches) {
            regexString = matches[2]
            flags = matches[1]
          }
        }

        return RegExp(regexString, flags)
      }
      return defaultRegex
    })

    // Mutations

    function setRecipients(value: Array<Recipient>): void {
      recipients.value = value
    }

    function setRecipientsCash(value: Array<CashRecipient>): void {
      recipientsCash.value = value
    }

    function setRecipientsMobileWallet(value: Array<MobileWalletRecipient>): void {
      recipientsMobileWallet.value = value
    }

    function setRecipientsAll(value: Array<Recipient>): void {
      recipientsAll.value = value
    }

    function setSelectedAgentLocations(value: Array<Location>): void {
      selectedAgentLocations.value = value
    }

    function setSelectedAgent(value: Agent): void {
      selectedAgent.value = value
    }

    function setNicknameFieldRegex(value: string): void {
      nicknameFieldRegex.value = value
    }

    function setBusinessNameFieldRegex(value: string): void {
      businessNameFieldRegex.value = value
    }

    function setAddressLine1FieldRegex(value: string): void {
      addressLine1FieldRegex.value = value
    }

    function setSelectedRecipient(value: Recipient): void {
      selectedRecipient.value = value
    }

    function setShowAccountAddRecipient(value: boolean): void {
      showAccountAddRecipient.value = value
    }

    function resetForm() {
      recipients.value = []
      recipientsAll.value = []
      recipientsCash.value = []
      selectedAgentLocations.value = []
      selectedAgent.value = undefined
    }

    // Actions

    async function validateRecipient(id: number): Promise<boolean> {
      let user = authStore.user
      let profileId = user?.customer.id

      try {
        let api = getCorrectApi('validateRecipient')
        await api.exec({ profileId, recipientId: id })
      } catch (ex: any) {
        return ex
      }
      return true
    }

    async function getRecipient(id: number): Promise<Recipient> {
      let recipientId = id
      let user = authStore.user
      let profileId = user?.customer.id

      let api = getCorrectApi('getRecipient')
      const { data } = await api.exec({ profileId, recipientId })
      if (data) {
        return data
      } else {
        throw new Error(`No data returned from API`)
      }
    }

    async function getRecipients(
      deliveryMethod: DeliveryMethod | string | null = null
    ): Promise<void> {
      const appStore = useAppStore()

      const user = authStore.user

      let recipients: Array<Recipient> = []
      try {
        const promises = []
        if ((!deliveryMethod || deliveryMethod === 'BankAccount') && user) {
          let api = getCorrectApi('getRecipients')
          promises.push(api.exec(user.customer.id))
        } else {
          promises.push(null)
        }

        if ((!deliveryMethod || deliveryMethod === 'CashPayout') && !authStore.isCorporateAccount) {
          promises.push(getCashRecipients.exec())
        } else {
          promises.push(null)
        }

        if (
          (!deliveryMethod || deliveryMethod === 'MobileWallet') &&
          !authStore.isCorporateAccount
        ) {
          promises.push(getMobileWalletRecipients.exec())
        } else {
          promises.push(null)
        }

        const results = await Promise.all(promises)
        const recipientsResult = results[0]
        const cashRecipientsResult = results[1]
        const mobileWalletRecipientsResult = results[2]

        if (recipientsResult?.data) {
          recipients = recipients.concat(recipientsResult.data)
        }
        if (cashRecipientsResult?.data) {
          recipients = recipients.concat(cashRecipientsResult.data)
          recipientsCash.value = cashRecipientsResult.data
        }
        if (mobileWalletRecipientsResult?.data) {
          recipients = recipients.concat(mobileWalletRecipientsResult.data)
          recipientsMobileWallet.value = mobileWalletRecipientsResult.data
        }
      } catch (ex) {
        appStore.messageBoxGenericError()
        throw new Error('ERROR retrieving recipients')
      }

      setRecipientsAll(recipients)
      setRecipients(recipients)
    }

    async function getRecipientsByCountryAndCurrency({
      country,
      currency,
      deliveryMethod = null,
    }: {
      country: string
      currency: string
      deliveryMethod: DeliveryMethod | null
    }): Promise<void> {
      await getRecipients(deliveryMethod)

      let recipients: Array<Recipient> = []
      if (recipientsAll.value) {
        recipients = recipientsAll.value.filter((recipient) => {
          if (recipient && recipient.country === country && recipient.currency === currency) {
            return !(deliveryMethod && deliveryMethod !== recipient.deliveryMethod)
          }
        })
      }
      setRecipients(recipients)
    }

    async function getFields({
      id,
      country,
      currency,
      isBusiness = false,
      addressCountry,
    }: {
      id: number
      country: string
      isBusiness?: boolean
      currency: string
      addressCountry?: string
    }): Promise<any> {
      let api = getCorrectApi('getFields')
      try {
        const { data } = await api.exec({ id, country, currency, isBusiness, addressCountry })

        if (data) {
          return data
        } else {
          throw new Error("CAN'T GET FIELDS")
        }
      } catch (error: any) {
        let ex = { ...error }
        ex.toString = null
        var errorList = new Array()
        if (typeof ex.response.data === 'object') {
          errorList = ex.response.data.data.errors
        }
        return Promise.reject(errorList)
      }
    }

    async function getRecipientFieldsRegex({
      id,
      country,
      currency,
    }: {
      id: number
      country: string
      currency: string
    }): Promise<void> {
      const data = await getFields({ id, country, currency })
      if (data) {
        const paymentReferenceField = data.find(
          (field: XeRecipientField) => field.id === 'OTHER_PARTY_REF'
        )
        const nicknameField = data.find((field: XeRecipientField) => field.id === 'NICKNAME')
        const businessNameField = data.find(
          (field: XeRecipientField) => field.id === 'ACCOUNT_NAME'
        )
        const addressLine1Field = data.find(
          (field: XeRecipientField) => field.id === 'ADDRESS_LINE1'
        )

        const regexValue = (field: XeRecipientField): string => {
          if (field) {
            const regex = field.validations.find((item) => item.type === 'Regex')
            if (regex) {
              return regex.value
            }
          }
          return ''
        }

        usePaymentReferenceStore().setPaymentReferenceFieldRegex(regexValue(paymentReferenceField))
        setNicknameFieldRegex(
          regexValue(nicknameField).replaceAll(`a-zA-Z`, `a-zA-Z\\p{Script=Latin}`)
        )
        setBusinessNameFieldRegex(
          regexValue(businessNameField).replaceAll(`a-zA-Z`, `a-zA-Z\\p{Script=Latin}`)
        )
        setAddressLine1FieldRegex(regexValue(addressLine1Field))
      }
    }

    async function getBankDetails({
      country,
      currency,
      params,
    }: {
      country: string
      currency: string
      params: object
    }): Promise<any> {
      const apiStore = useApiStore()

      const controller = apiStore.createAndRetrieveCancellationToken(REQUEST_CANCELATION_TOKEN_ENUMS.GET_BANK_DETAILS)
      const { data } = await getBankDetailsApi.exec({ country, currency, params, controller })
      if (data) {
        return data
      } else {
        throw new Error("CAN'T GET BANK FIELDS")
      }
    }

    async function getCountriesForCurrency(currency: string): Promise<any> {
      const { data } = await getCountries.exec(currency)
      if (data) {
        const resourcesStore = useResourcesStore()
        const countries = resourcesStore.getCountries
        const countryMap: any = {}
        for (const country of countries) {
          countryMap[country.value] = country
        }

        const topCountries = resourcesStore.getTopCountries
        const topCountryMap: any = {}

        for (const country of topCountries) {
          topCountryMap[country.value] = country
        }

        // try to get the countries used in the default currencies for that currency as favorites
        // if one of these countries is then part of the top countries put that as the first country
        const countryCurrencies = []
        for (const country in DefaultCurrencies) {
          // @ts-ignore
          if (DefaultCurrencies[country][0] === currency) {
            const value = countryMap[country]
            if (value) {
              if (topCountryMap[country] || country === currency.substr(0, 2)) {
                countryCurrencies.unshift(value)
              } else {
                countryCurrencies.push(value)
              }
            }
          }
        }

        let result = data.map((country: string) => {
          return countryMap[country]
        })
        result.sort((a: any, b: any) => {
          const x = a.text.toLowerCase()
          const y = b.text.toLowerCase()
          if (x < y) {
            return -1
          }
          if (x > y) {
            return 1
          }
          return 0
        })
        result = useFavorites(result, countryCurrencies)
        return result
      } else {
        throw new Error("CAN'T GET COUNTRIES")
      }
    }

    async function addRecipient({
      country,
      currency,
      fields,
      business,
      myself,
    }: {
      country: string
      currency: string
      fields: any
      business: boolean
      myself: boolean
    }): Promise<RecipientModel> {
      const requestFields = []

      for (const field of fields) {
        requestFields.push({ name: field.id, value: field.value })
      }

      const user = authStore.user

      let api = getCorrectApi('addRecipient')
      const { data } = await api.exec({
        profileId: user?.customer.id,
        country,
        currency,
        fields: requestFields,
        business,
        myself,
      })

      if (data) {
        const recs = recipients.value
        let recipientResult = new BankRecipientResult(data)
        const newRecipient = new RecipientModel(recipientResult)

        recs.push(newRecipient as Recipient)
        // FIGURE OUT WHERE TO PUT SORT FUNCTION SO IT IS NOT DUPLICTED
        recs.sort(function (a, b) {
          // First sort by first name
          if (a.firstName < b.firstName) {
            return -1
          }
          if (a.firstName > b.firstName) {
            return 1
          }
          // Sort by last name if first name is the same
          if (a.lastName < b.lastName) {
            return -1
          }
          if (a.lastName > b.lastName) {
            return 1
          }
          // names must be equal
          return 0
        })

        setRecipients(recs)
        return newRecipient
      } else {
        throw new Error('ADD RECIPIENT FAILED')
      }
    }

    async function addCashRecipient({
      fields,
      deliveryMethod,
    }: {
      fields: Array<CashRecipientField>
      deliveryMethod: DeliveryMethod
      isSendMoney: boolean
    }): Promise<Recipient | void> {
      const currencyField = fields.find((field) => field.id === 'CurrencyCode')
      const countryField = fields.find((field) => field.id === 'CountryCode')

      //We find the currencyField in case we didn't assign it yet from SMF
      if (currencyField && !currencyField?.value && countryField?.value) {
        currencyField.value = getAllInfoByISO(countryField.value).currency
      }
      const requestFields = []

      for (const field of fields) {
        requestFields.push({ name: field.id, value: field.value })
      }

      if (deliveryMethod === 'MobileWallet') {
        requestFields.push({ name: 'PaymentMethod', value: 'MobileWallet' })
      }

      const { data } = await addCashRecipientApi.exec(requestFields)
      if (data) {
        await getRecipients()
        const recs = recipients.value
        const newRecipient = recs.find(
          (recipient) => recipient && recipient.id.toString() === data.id.toString()
        ) // TODO IMPROVE TOSTRING ?????
        if (newRecipient) {
          return newRecipient
        }
      } else {
        throw new Error('ADD RECIPIENT FAILED')
      }
    }

    async function updateRecipient({
      id,
      formData,
    }: {
      id: number
      formData: any
    }): Promise<Recipient> {
      let recipientId = id
      const user = authStore.user
      let profileId = user?.customer.id

      try {
        if (!formData.nameVerified) {
          const firstNameField = formData.fields.find((field: any) => field.name === 'FIRST_NAME')
          if (firstNameField) {
            formData.nameVerified = true
          }
        }
        let api = getCorrectApi('putRecipient')

        const { data } = await api.exec({ profileId, recipientId, formData })
        if (data && !data.message) {
          const updatedRecipient = data
          const recs = recipients.value
          const index = recs.findIndex(
            (recipient) => recipient?.rawData && recipient.rawData.id == recipientId
          )

          if (index !== -1) {
            recs[index] = updatedRecipient
            let newRecipientList = Object.assign([], recs)
            setRecipients(newRecipientList)
          }

          return updatedRecipient
        } else {
          const recs = recipients.value
          const index = recs.findIndex(
            (recipient) => recipient?.rawData && recipient.rawData.id == recipientId
          )

          let updatedRecipient = recs[index]
          if (index !== -1) {
            let name = formData.fields.find((field: any) => field.name === 'ACCOUNT_NAME').value
            updatedRecipient.fullName = name
            recs[index] = updatedRecipient
            let newRecipientList = Object.assign([], recs)
            setRecipients(newRecipientList)
          }
          return updatedRecipient
        }
      } catch (ex: any) {
        throw ex.message
      }
    }

    async function getCashRecipient(id: number): Promise<CashRecipient> {
      const cashRecipients = await getCashRecipients.exec()
      const result = cashRecipients.data.find((recipient: CashRecipient) => {
        return recipient.id == id
      })
      if (result) {
        return result
      } else {
        throw new Error(`No recipient found`)
      }
    }

    async function getMobileWalletRecipient(id: number): Promise<MobileWalletRecipient> {
      //we get the updated recipients
      const mobileWalletRecipients = await getMobileWalletRecipients.exec()

      //selected recipient
      const result = mobileWalletRecipients.data.find(
        (recipient: MobileWalletRecipient) => recipient.id == id
      )

      //if the recipient have a mobile wallet we retrieve it's name
      if (result?.mobileWalletCorrespondentId) {
        try {
          await useSendMoneyStore().setMobileWalletProviders({
            country: result.country,
            currency: result.currency,
            amount: 1,
          })

          const availableWallets = useSendMoneyStore().mobileWalletProviders.providers
          const currentWallet = availableWallets.find(
            (wallet: any) => wallet.partnerId == result?.mobileWalletCorrespondentId
          )

          //setMobileWalletProvider
          useSendMoneyStore().setMobileWalletProvider(currentWallet)

          result.mobileWalletCorrespondentName = currentWallet?.name
          return result
        } catch (ex) {
          useAppStore().logException('Exception retrieving mobile wallet information', ex)
        }
      }

      return result
    }

    async function updateCashRecipient({
      id,
      formData,
      deliveryMethod,
    }: {
      id: number
      formData: any
      deliveryMethod: string
    }): Promise<CashRecipient | void> {
      let recipientId = id

      try {
        const updateSuccess = await updateCashRecipientApi.exec({ recipientId, formData })

        if (updateSuccess) {
          let updatedRecipient = null
          if (deliveryMethod === 'MobileWallet') {
            updatedRecipient = await getMobileWalletRecipient(id)
          } else {
            updatedRecipient = await getCashRecipient(id)
          }
          const rec = recipients.value
          const index = rec.findIndex(
            (recipient) => recipient?.rawData && recipient.rawData.id == recipientId
          )

          if (index !== -1) {
            updatedRecipient.accountName = updatedRecipient.fullName
            rec[index] = updatedRecipient
            let newRecipientList = Object.assign([], rec)
            setRecipients(newRecipientList)
          }
          return updatedRecipient
        }
      } catch (ex: any) {
        throw ex.message
      }
    }

    async function deleteRecipient({
      profileId,
      recipientId,
    }: {
      profileId: number
      recipientId: number
    }): Promise<void> {
      const { data } = await deleteRecipientApi.exec({ profileId, recipientId })
      if (data) {
        let rec = recipients.value
        const index = rec.findIndex((recipient: Recipient) => recipient.id === recipientId)
        if (index !== -1) {
          rec.splice(index, 1)
          setRecipients(rec)
        }
      } else {
        throw new Error('Failed to delete recipient')
      }
    }

    async function deleteCashRecipient({
      recipientId,
      isMobileWallet = false,
    }: {
      recipientId: number
      isMobileWallet: boolean
    }): Promise<void> {
      const response = await deleteCashRecipientApi.exec({
        recipientId: recipientId,
        isMobileWallet: isMobileWallet,
      })
      if (response) {
        let rec = recipients.value
        const index = rec.findIndex((recipient: any) => recipient && recipient.id === recipientId)
        if (index !== -1) {
          rec.splice(index, 1)
          setRecipients(rec)
        }
      } else {
        throw new Error('Failed to delete recipient')
      }
    }

    async function searchBank({
      nameOrCode,
      country,
    }: {
      nameOrCode: string
      country: string
    }): Promise<any> {
      const result = await getBanksByNameOrCode.execWithSafeGuard({
        nameOrCode,
        country,
      })
      if (result && result.data) {
        return result.data
      } else if (result && result.skipped) {
        return null
      } else {
        throw new Error('Failed to search bank')
      }
    }

    async function getBankFields({
      bankId,
      country,
      currency,
    }: {
      bankId: string
      country: string
      currency: string
    }): Promise<any> {
      const { data } = await getBankById.exec({ bankId, country, currency })
      if (data) {
        return data
      } else {
        throw new Error('Failed to get bank details')
      }
    }

    async function saveBankDeposit({
      recipient,
      currency,
    }: {
      recipient: any
      currency: string
    }): Promise<void> {
      // if successful we don't get any data, just a 200 response
      await updateBankDeposit.exec({ recipient, currency })
    }

    async function searchCity({
      city,
      state = null,
      country,
    }: {
      city: string
      state: string | null
      country: string
    }): Promise<Array<City>> {
      try {
        const apiStore = useApiStore()
        const controller = apiStore.createAndRetrieveCancellationToken(REQUEST_CANCELATION_TOKEN_ENUMS.GET_AGENT_CITIES)

        const { data } = await getAgentCities.exec({
          city,
          state,
          country,
          controller,
        })
        if (data) {
          return data
        } else {
          throw new Error('Failed to get cities')
        }
      } catch (ex: any) {
        // TODO: is there a better way to detect 404 ?
        if (ex.message && ex.message.indexOf('code 404') >= 0) {
          return []
        } else {
          throw ex
        }
      }
    }

    async function getAgentsByCity({
      city,
      country,
      deliveryMethod,
      currency,
      amount,
    }: {
      city: string
      country: string
      deliveryMethod: DeliveryMethod
      currency: string
      amount: string
    }): Promise<Array<Agent>> {
      const { data } = await getAgents.exec({
        city,
        country,
        deliveryMethod,
        currency,
        amount,
      })
      if (data) {
        return data
      } else {
        throw new Error('Failed to get cities')
      }
    }

    async function getAgentLocations({
      agent,
      country,
      city,
      deliveryMethod,
      amount,
      currency,
    }: {
      agent: string
      country: string
      city: string
      deliveryMethod: string
      amount: string
      currency: string
    }): Promise<Array<Location>> {
      const { data } = await getLocations.exec({
        agent,
        country,
        city,
        deliveryMethod,
        amount,
        currency,
      })
      if (data) {
        return data
      } else {
        throw new Error('Failed to get locations')
      }
    }

    async function getStates(country: string): Promise<Array<State>> {
      return useCountriesStore().getStatesByCountryCode(country)
    }

    async function getCashFields(): Promise<XeRecipientField> {
      const { data } = await getCashFieldsApi.exec()
      if (data) {
        return data
      } else {
        throw new Error("CAN'T GET FIELDS")
      }
    }

    async function getIndustryCategories(): Promise<Array<IndustryCategory>> {
      const { data } = await getIndustryCategoriesApi.exec()
      if (data) {
        return data
      } else {
        throw new Error(`No data returned from API`)
      }
    }

    async function getIndustryTypesByCategory(): Promise<Array<IndustryType>> {
      const { data } = await getIndustryTypesByCategoryApi.exec()
      if (data) {
        return data
      } else {
        throw new Error(`No data returned from API`)
      }
    }

    return {
      recipients,
      recipientsAll,
      recipientsCash,
      recipientsMobileWallet,
      selectedAgentLocations,
      selectedAgent,
      businessNameFieldRegex,
      nicknameFieldRegex,
      addressLine1FieldRegex,
      selectedRecipient,
      showAccountAddRecipient,
      getCorrectApi,
      getNicknameFieldRegex,
      getBusinessNameFieldRegex,
      getAddressLine1FieldRegex,
      setRecipientsCash,
      setRecipientsMobileWallet,
      setSelectedAgentLocations,
      setSelectedAgent,
      setSelectedRecipient,
      setShowAccountAddRecipient,
      resetForm,
      validateRecipient,
      getRecipient,
      getRecipients,
      getRecipientsByCountryAndCurrency,
      getFields,
      getRecipientFieldsRegex,
      getBankDetails,
      getCountriesForCurrency,
      addRecipient,
      addCashRecipient,
      updateRecipient,
      getCashRecipient,
      getMobileWalletRecipient,
      deleteRecipient,
      deleteCashRecipient,
      searchBank,
      getBankFields,
      saveBankDeposit,
      searchCity,
      getAgentsByCity,
      getAgentLocations,
      getStates,
      getCashFields,
      getIndustryCategories,
      getIndustryTypesByCategory,
      updateCashRecipient,
    }
  },
  {
    persist: {
      storage: sessionStorage,
    },
  }
)
