import UserProfileStatus from '@galileo/models/UserProfile/UserProfileStatus'
import UserProfilePhoneNumber from '@galileo/models/UserProfile/UserProfilePhoneNumber'
import { useI18nStore } from '@galileo/stores'
export default class UserProfile {
  constructor(profile) {
    const i18nStore = useI18nStore()
    this.id = profile.id
    this.firstName = profile.firstName
    this.middleName = profile.middleName
    this.lastName = profile.lastName

    //adding this field cause is require to update the userProfile info
    this.address = profile.address
    this.street = profile.address
    this.city = profile.city
    this.state = profile.state
    this.postalCode = profile.postalCode
    this.country = profile.country

    this.language = i18nStore.getClosestMatchingLocale(profile.cultureCode, true)
    this.dateOfBirth = profile.dateOfBirth
    this.disabled = profile.disabled
    this.emailAddress = profile.emailAddress

    //extra fields
    this.countryOfBirth = profile.countryOfBirth
    this.hasTaxId = profile.hasTaxId
    this.nationality = profile.nationality
    this.note = profile.note
    this.occupation = profile.occupation
    this.occupationId = profile.occupationId
    this.securityQuestions = profile.securityQuestions

    this.isDisabled = profile.isDisabled
    this.status = new UserProfileStatus(
      (this.profileActionsRequired = profile.status.profileActionsRequired),
      (this.canTransact = profile.status.canTransact),
      (this.kycFailed = profile.status.kycFailed)
    )

    this.willReceiveEmailMarketing = profile.willReceiveEmailMarketing
    this.willReceiveSmsMarketing = profile.willReceiveSmsMarketing
    this.willReceiveSmsNotifications = profile.willReceiveSmsNotifications

    //The Website only update the HomePhoneNumber
    this.phoneNumberFull = profile.homePhone.number
    this.phoneNumberCountry = profile.homePhone.areaCode
    this.phoneNumber = profile.homePhone.phoneNumberNoAreaCode

    // The below phoneNumber vars I don't see being used anywhere.
    if (profile.mobilePhone) {
      this.phoneNumberFull = profile.mobilePhone.number
      this.phoneNumberCountry = profile.mobilePhone.areaCode
      this.phoneNumber = profile.mobilePhone.phoneNumberNoAreaCode
      this.mobilePhone = new UserProfilePhoneNumber('mobilePhone', profile)
    } else if (profile.homePhone) {
      this.phoneNumberFull = profile.homePhone.number
      this.phoneNumberCountry = profile.homePhone.areaCode
      this.phoneNumber = profile.homePhone.phoneNumberNoAreaCode
      this.homePhone = new UserProfilePhoneNumber('homePhone', profile)
    }

    if (profile.identification) {
      this.identification = {}
      this.identification.details = profile.identification.details
      this.identification.documentVerificationAuthority =
        profile.identification.documentVerificationAuthority
      this.identification.documentVerificationStatus =
        profile.identification.documentVerificationStatus
      this.identification.idVerificationAuthority = profile.identification.idVerificationAuthority
      this.identification.idVerificationRequired = profile.identification.idVerificationRequired
      this.identification.idVerificationStatus = profile.identification.idVerificationStatus
      this.identification.manualApprovalFound = profile.identification.manualApprovalFound
    }

    this.homePhone = profile.homePhone
    this.mobilePhone = profile.mobilePhone
    this.willReceiveEmailMarketing = profile.willReceiveEmailMarketing
    this.willReceiveSmsMarketing = profile.willReceiveSmsMarketing
    this.willReceiveSmsNotifications = profile.willReceiveSmsNotifications
  }
}
