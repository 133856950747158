import API from '@galileo/api/launchpad'
import APIHandler from '@/api/LaunchpadAPIHandler'

class ProfileResult {
  constructor(data) {
    this.accountType = data.accountType
    this.onlineCredentialId = data.onlineCredentialId
    this.id = data.profileId
    this.region = data.region
    this.status = data.accountStatus
    this.restricted = data.accountRestricted
    this.clientNumber = data.clientNumber
    this.lastLogin = data.lastLogin
    if (data.fullName) {
      this.name = `${data.fullName.firstName} ${data.fullName.lastName}`
    }
  }
}

export default new APIHandler(
  API,
  (config) => {
    return {
      ...config,
      method: 'GET',
      url: `profiles`,
    }
  },
  (response) => {
    return {
      ...response,
      data: response.data.map((profile) => new ProfileResult(profile)),
    }
  }
)
