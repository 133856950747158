import { ref, computed } from '@vue/composition-api'
import { defineStore } from 'pinia'
import getRequiredDocuments from '@galileo/api/launchpad/stp/get'
import uploadFile from '@galileo/api/launchpad/stp/docs/_documentType/post'

import { useSessionStore } from '@galileo/stores'

interface RequiredDocument {
  documentType: string
  documentDescriptionId?: string
}

export const useStpStore = defineStore('stp', () => {
  const requiredDocuments = ref<Array<RequiredDocument>>([])
  const shouldShowStpDocumentRequiredModal = ref(false)

  async function getRequiredDocumentsList(): Promise<void> {
    try {
      const { data } = await getRequiredDocuments.exec()
      requiredDocuments.value = data.requiredDocuments
    } catch (ex) {
      console.error('Excpetion fetching documents', ex)
    }

    if (requiredDocuments.value.length > 0) {
      shouldShowStpDocumentRequiredModal.value = true
    }
  }

  async function uploadRequiredDocument({
    documentType,
    file,
  }: {
    documentType: string
    file: any
  }): Promise<boolean> {
    try {
      const { data } = await uploadFile.exec({ documentType, file })
      return data?.statusCode === 'OK'
    } catch (ex) {
      console.error('Exception during file upload ', ex)
      return false
    }
  }

  return {
    getRequiredDocumentsList,
    uploadRequiredDocument,
    requiredDocuments,
    hasDocumentsToUpload: computed(() => requiredDocuments.value.length > 0),
    shouldShowStpDocumentRequiredModal: computed(
      () =>
        shouldShowStpDocumentRequiredModal.value &&
        !useSessionStore().stpDocumentRequiredAcknowledged
    ),
  }
})
