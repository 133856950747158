import { default as CTAPI } from '@galileo/api/ct'
import { default as RMTAPI } from '@galileo/api/rmt'
import { default as LaunchpadAPI } from '@galileo/api/launchpad'
import { default as LaunchpadCache } from '@galileo/api/launchpad-cache'

import { default as CorporateAPI } from '@galileo/api/corporate'

import { computed, ref } from '@vue/composition-api'
import { defineStore } from 'pinia'
import { setHeader } from '@galileo/api/RMTAPIHandler'

import { useI18nStore, useSessionStore, useEnvStore } from '@galileo/stores'

export const useApiStore = defineStore(
  'api',
  () => {
    const cancellationControllers = ref<{ [key: string]: AbortController }>({})
    const newCustomerRequiredFields = ref<any>(null)

    const getNewCustomerFields = computed(() => (stage: string) => {
      if (newCustomerRequiredFields) {
        const item = newCustomerRequiredFields[stage] || {}
        /*
        Map the Strings from the API to a translated value, since its not possible to have them
         translated on the backend for some reason
        https://euronet.atlassian.net/browse/RMT4-780
      */
        const i18nStore = useI18nStore()
        switch (item.title) {
          case 'Tell us about yourself':
            item.title = i18nStore.$t('PageRegisterInfo.CustomerInfoFormTitle').value
            break
          case `What's your address?`:
            item.title = i18nStore.$t('PageRegisterInfo.AddressFormTitle').value
            break
          default:
            break
        }
        return item
      }
      return {}
    })
    const getNewCustomerFieldById = computed(() => (stage: string, id: string) => {
      const { fields } = getNewCustomerFields.value(stage)
      if (fields) {
        return fields.find((field: any) => field.id === id)
      }
      return {}
    })

    const abortApiRequest = (requestName: string) => {
      let controllerToAbort = cancellationControllers.value[requestName]
      if (controllerToAbort?.signal) {
        controllerToAbort.abort()
      }
    }

    const createAndRetrieveCancellationToken = (requestName: string): AbortController => {
      abortApiRequest(requestName)

      const controller = new AbortController()
      cancellationControllers.value[requestName] = controller
      return controller
    }

    const init = (deviceId: string) => {
      const envStore = useEnvStore()
      const sessionStore = useSessionStore()
      CTAPI.defaults.baseURL = envStore.env.VUE_APP_ENDPOINTS_CTAPI
      RMTAPI.defaults.baseURL = envStore.env.VUE_APP_ENDPOINTS_RMTAPI
      LaunchpadAPI.defaults.baseURL = envStore.appLaunchpadEndpoint
      CorporateAPI.defaults.baseURL = envStore.env.VUE_APP_ENDPOINTS_CORP_INT

      LaunchpadCache.defaults.baseURL = envStore.cacheLaunchpadEndpoint

      setHeader('AppType', envStore.env.VUE_APP_APP_TYPE)
      setHeader('AppVersion', envStore.env.VUE_APP_API_VERSION)
      setHeader('Client-Type', envStore.env.VUE_APP_CLIENT_TYPE)
      setHeader('Platform', 'Web')
      // setHeader('DeviceId', deviceId ?? sessionDeviceId ?? null)
      setHeader('DeviceId', deviceId ? deviceId : sessionStore.sessionDeviceId)
    }
    return {
      // GETTERS
      getNewCustomerFields,
      getNewCustomerFieldById,
      // ACTIONS
      createAndRetrieveCancellationToken,
      init,
      abortApiRequest,
    }
  },
  {
    persist: {
      storage: sessionStorage,
    },
  }
)
