import FieldOption from '@galileo/models/DynamicField/FieldOption'

export default class RmtOption extends FieldOption {
  constructor(option) {
    super({
      id: option.value,
      imageId: null,
      imageType: null,
      name: option.text,
    })
  }
}
