import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'
import { CalculateResponse } from '@galileo/api/launchpad/quotes/v2/CalculateResponse'
import { useCardAccountStore, useI18nStore } from '@galileo/stores'
import { GALILEO_PLATFORM } from '@galileo/constants/platformType'
import { DELIVERY_METHODS, PAYMENT_METHODS } from '@galileo/constants/sendMoneyFlow.const'
export default new APIHandler(
  API,
  (form, config) => {
    let directDebitBankAccountId = null

    if (form.paymentMethod === PAYMENT_METHODS.DIRECT_DEBIT) {
      directDebitBankAccountId = form.paymentMethodId
    }

    let settlementMethodId = null

    if (
      (useCardAccountStore().useCybersource && !directDebitBankAccountId) ||
      [PAYMENT_METHODS.BANK_TRANSFER, PAYMENT_METHODS.INTERAC].includes(form.paymentMethod)
    ) {
      settlementMethodId = form.paymentMethodId
    }

    const data = {
      deliveryMethod: form.deliveryMethod,
      directDebitBankAccountId,
      isConfirmationQuote: true,
      settlementMethod: form.paymentMethod,
      settlementMethodId,
    }

    if (form.deliveryMethod !== DELIVERY_METHODS.FUNDS_ON_BALANCE) {
      data.payment = {
        reference: form.transferReferenceText,
        purposeOfPaymentCode: form.transferReason,
        purposeOfPaymentFreeText: form.transferReasonText,
        recipientId: form.recipient.id,
      }
    }

    if (form.deliveryMethod === DELIVERY_METHODS.CASH_PAYOUT) {
      data.riaCorrespondentId = form.deliveryMethodAgent?.id
      data.riaCorrespondentLocId = form.deliveryMethodLocation?.id
    }
    if (form.deliveryMethod === DELIVERY_METHODS.MOBILE_WALLET) {
      data.riaCorrespondentId = form.riaCorrespondentId
      data.riaCorrespondentLocId = form.riaCorrespondentLocId
    }

    data.platformType = GALILEO_PLATFORM

    return {
      ...config,
      method: 'PUT',
      url: `v2/quotes/${form.quoteId}?language=${useI18nStore().localeLanguagePart}`,
      data: data,
    }
  },
  (response) => {
    const calculateResponse = new CalculateResponse(response.data)
    if (!calculateResponse.availableQuotes || calculateResponse.availableQuotes.length === 0) {
      throw new Error('No quotes available')
    }
    return {
      ...response,
      data: calculateResponse,
    }
  }
)
