import { computed } from '@vue/composition-api'

export function useGalileoLoginUrlValidator() {
  const urlPattern: RegExp = /^https?:\/\/(?:(?:localhost|127\.0\.0\.1)(?::\d+)?|feature-(?:consumer|corporate)[1-3]-app\.xe\.com)(?:\/|$)/i
  
  const isGalileoLoginUrl = computed<boolean>(() => {
    return urlPattern.test(window.location.href)
  })

  return {
    isGalileoLoginUrl
  }
}