import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'
import Agent from '@galileo/models/Agent/agent'
import { toApi } from '@galileo/models/Country/countryMapping'

export default new APIHandler(
  API,
  ({ city, country, deliveryMethod, currency, amount }, config) => {
    const params = {
      City: city.cityName,
      State: city.state,
      CountryCode: toApi(country),
      DeliveryMethod: deliveryMethod,
      CurrencyCode: currency,
      Amount: amount,
    }
    return {
      ...config,
      method: 'GET',
      url: `Destinations/correspondents`,
      params: params,
    }
  },
  (response) => {
    if (response.data) {
      return {
        ...response,
        data: response.data.map((agent) => {
          return new Agent({
            ...agent,
            openPayment: agent.openNetwork,
          })
        }),
      }
    } else {
      throw new Error('Failed to get agents')
    }
  },
  (error) => {
    if (error?.response?.status === 404) {
      //no agents
      return {
        ...error,
        data: [],
      }
    }

    throw error
  }
)
