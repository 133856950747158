import { useCountriesStore } from '@galileo/stores'

export default function () {
  const countriesStore = useCountriesStore()

  //overloading the getRecipientCountryName to accept countryProperty by default is country, but it can be addressCountry

  const getRecipientCountryName = (recipient, countryProperty = 'country') => {
    // Returns country name from store, default to country ISO code
    const country = countriesStore.getOfferedCountryByIsoCode(recipient[countryProperty])
    return country?.text || recipient[countryProperty]
  }

  return {
    getRecipientCountryName,
  }
}
