import API from '@galileo/api/rmt'
import APIHandler from '@galileo/api/APIHandler'
import BankAccount from '@galileo/models/Payment/BankAccount'

export default new APIHandler(
  API,
  (
    {
      accountType,
      localRoutingCode,
      accountNumber,
      firstName,
      billingAddress,
      billingCity,
      billingState,
      billingPostalCode,
      billingCountry,
    },
    config
  ) => {
    const request = {
      accountType,
      localRoutingCode,
      accountNumber,
      firstName,
      billingAddress,
      billingCity,
      billingPostalCode,
      billingState,
      billingCountry,
    }

    return {
      ...config,
      method: 'POST',
      url: 'account/bank/create',
      data: request,
    }
  },
  (response) => {
    if (response && response.data) {
      return new BankAccount(response.data)
    } else {
      throw new Error('Failed to create bank account')
    }
  },
  (error) => {
    if (error?.response?.data?.errors?.length > 0) {
      throw error.response.data.errors[0]
    }
  }
)
