import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/RMTAPIHandler'

export default new APIHandler(
  API,
  (gsoUrl) => {
    return {
      method: 'GET',
      url: 'getGsoTokens',
      withCredentials: true,
      transformRequest: (data, headers) => {
        // the GSO call needs to be a basic HTTP call otherwise it will run into CORS problems hence we need to remove any custom headers
        const keys = Object.keys(headers.common)
        for (const key of keys) {
          if (key !== 'Accept') delete headers.common[key]
        }
        return data
      },
    }
  },
  (response) => {
    return {
      ...response,
      data: {
        accessToken: response.data.tokens.access_token,
        idToken: response.data.tokens.id_token,
        refreshToken: response.data.tokens.refresh_token,
      },
    }
  }
)
