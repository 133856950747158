/**
 * @description Geographical region the application is currently configured for, e.g. US, Spain, Australia.
 * @region Enumeration for this region.
 * @defaultCountryFrom Default value to use for send-from country, i.e. in price calculator
 * @defaultCurrencyFrom Default value to use for send-from currency, i.e. in price calculator
 * @defaultLanguage Default language culture to use, i.e. content translations
 */
export default {
  regionId: 9305,
  defaultCountryFrom: 'ES',
  defaultCountryFromOnfido: 'ESP',
  defaultCountryCodeFrom: 34,
  defaultCurrencyFrom: 'EUR',
  defaultLanguageCulture: 'es-ES',
  customerServicePhone: '612123123',
  defaultLocation: {
    latitude: 40.46366700000001,
    longitude: -3.74922,
  },
  dateFormat: 'DD/MM/YYYY',
  postalCodeRegex: `^[0-9 -]{5,5}$`,
  support: {
    email: 'customerservice@riamoneytransfer.com',
    title: 'Support request',
  },
  links: {
    linkFaq: 'https://www.riamoneytransfer.com/us/en/mobile-content/faqs',
    linkPrivacy: 'https://www.riamoneytransfer.com/us/en/mobile-content/privacy',
    linkStoredCardAgreement:
      'https://www.riamoneytransfer.com/us/en/mobile-content/stored-card-agreement',
    linkTerms: 'https://app.riamoneytransfer.com/app-terms-and-conditions',
  },
  availableLanguageCultures: ['en-ES', 'es-ES'],
  regionalIp: '66.82.123.234',
  requireAddress: false,
  paymentProvider: null,
  isPlaidEnabled: true,
  isBankAccountEnabled: false,
  useSecurityQuestions: false,
  oldRemittanceLogin: false,
  onfidoMaxAttempts: 1,
}
