import { defineStore } from 'pinia'
import { ref, computed } from '@vue/composition-api'
import config from '@galileo/constants/i18nConfig'

import { I18n } from '@galileo/models/I18n/interfaces/I18n'
import { isEmpty } from 'lodash-es'

import { useAppStore, useAuthStore } from '@galileo/stores'
export const useI18nStore = defineStore('i18n', () => {
  const separator = '-'
  const i18n = ref({} as I18n)
  const brandSpecificTranslations = ref<{ [name: string]: any }>()


  const testingLanguages = ref<Nullable<string[]>>(null)

  const availableLanguages = ref<Nullable<string[]>>(null)

  const useNewLanguages = ref(false)


  const setLocale = (value: string): void => {
    i18n.value.locale = value
  }

  const languageTagParts = (languageTag: string): Array<string> => {
    return languageTag.split(separator)
  }

  const languageTagLanguage = (languageTag: string): string => {
    return languageTagParts(languageTag)[0]
  }

  const languageTagCountry = (languageTag: string): string => {
    return languageTagParts(languageTag)[1]
  }

  const setI18n = (_i18n: I18n) => {
    i18n.value = _i18n
  }

  // Return an array of the Supported Iso Codes
  const locales = computed(() => {
    let availableLocales = Object.keys(config.messages)

    if (availableLanguages.value) {
      availableLocales = availableLocales.filter((locale) => {
        const language = languageTagLanguage(locale)
        return availableLanguages.value?.includes(language)
      })
    }

    if (testingLanguages.value) {
      availableLocales = availableLocales.filter((locale) => {
        const language = languageTagLanguage(locale)
        return !testingLanguages.value?.includes(language)
      })
    }
    return availableLocales
  })

  const localeLanguagePart = computed(() => {
    return languageTagLanguage(i18n.value.locale)
  })





  // Check if the passed in Iso Code is supported
  const isSupportedLocale = (languageTag: string): boolean => {
    return !!locales.value.find((code: string) => code === languageTag)
  }

  const isSupportedLocaleLanguagePart = (language: string): boolean => {
    const supportedLanguagesIso639 = locales.value.map((code: string) => languageTagLanguage(code))
    const isSupported = supportedLanguagesIso639.includes(language)
    return isSupported
  }

  const getClosestMatchingLocale = (languageTag: string, useFallback: boolean = false): string => {
    if (i18n.value.locales) {
      const matchingLocales = i18n.value.locales

      if (!languageTag) {
        return matchingLocales[0]
      }

      // Get matching based on full code (language + country) `en-US`
      const exactMatch = matchingLocales.find((locale: string) => locale === languageTag)
      if (exactMatch) {
        return exactMatch
      }

      // Get matching based on the language `en`
      const languageMatch = matchingLocales.find(
        (locale: string) => languageTagLanguage(locale) === languageTagLanguage(languageTag)
      )

      if (languageMatch) {
        // eslint-disable-next-line no-console
        console.warn(
          `User tried to use languageTag ${languageTag}, we served them with ${languageMatch}`
        )
        return languageMatch
      }

      if (useFallback) {
        // Get Fallback (default)
        const fallback = matchingLocales[0]
        // eslint-disable-next-line no-console
        console.warn(
          `User tried to use languageTag ${languageTag}, we served them with ${fallback}`
        )
        return fallback
      }
    }

    return ''
  }

  function $tNoop(key: string, args?: any) {
    // eslint-disable-next-line no-console
    console.warn(`[tNoop] execute for '${key}'`, args)
    return {
      value: '',
    }
  }

  function $t(key: string, args?: any) {
    if (brandSpecificTranslations.value) {
      let lowerKey = key.toLocaleLowerCase()
      const value = brandSpecificTranslations.value[lowerKey]
      if (value) {
        key = value
      }
    }

    if (i18n.value && !isEmpty(i18n.value) && !isEmpty(key)) {
      let result = i18n.value.$t(key, args)

      if (result.value === key && args?.fallbackKey) {
        console.warn(`Fallback translation used for: ${key}: ${args.fallbackKey}`)
        result = i18n.value.$t(args.fallbackKey, args)
      }
      return result
    }
    return $tNoop(key)
  }

  function setBrandSpecificTranslations(translations: { [name: string]: any }) {
    brandSpecificTranslations.value = translations
  }


  function setUseNewLanguages() {
    useNewLanguages.value = true
  }


  function setAvailableLanguages(_availableLanguages: string[]) {
    //limit the available languages based on the argument
    useAppStore().logIntegration('setAvailableLanguages', _availableLanguages)
    availableLanguages.value = _availableLanguages
  }

  //TODO to use this function if we enable new languages in the future
  function setTestingLanguages(_testingLanguages: string[]) {
    useAppStore().logIntegration('setTestingLanguages', _testingLanguages)

    if (useAuthStore().isCorporateAccount) {
      testingLanguages.value = ['nb', 'sv', 'da', 'it', 'pt']
    } else {
      testingLanguages.value = _testingLanguages
    }
  }



  return {
    $t,
    $tNoop,
    separator,
    i18n,
    config,
    languageTagParts,
    languageTagLanguage,
    languageTagCountry,
    locales,
    localeLanguagePart,
    isSupportedLocale,
    isSupportedLocaleLanguagePart,
    getClosestMatchingLocale,
    setI18n,
    setBrandSpecificTranslations,
    setLocale,
    useNewLanguages,
    setUseNewLanguages,
    testingLanguages,
    setAvailableLanguages,
    availableLanguages,
    setTestingLanguages
  }
})
