import { defineStore } from 'pinia'
import { ref, computed, Ref } from '@vue/composition-api'
import patchBeneficiary from '@galileo/api/launchpad/transfer/_orderNumber/beneficiary/_recipientId/patch'
import { DELIVERY_PROVIDERS, DeliveryMethod } from '@galileo/models/Transaction/app'
import getTransactionList from '@galileo/api/launchpad/transactions/get'
import getTransactionOrderNumber from '@galileo/api/launchpad/transactions/_orderNumber/get'
import postTransferCancel from '@galileo/api/launchpad/transfer/_orderNumber/cancellation/post'
import getContractDocument from '@galileo/api/launchpad/transactions/contract-document/get'
import {
  ACTION_REQUIRED,
  TRANSACTION_ACTIVITY_STATUSES,
  TRANSACTION_STATUSES_STEPS,
} from '@galileo/constants/transactionStatuses'

// Interfaces imports
import {
  TransactionListItem,
  TransactionsByCategory,
} from '@galileo/models/Activity/interfaces/Transactions'

import StoredOrder from '@galileo/models/StoredOrder/interfaces/StoredOrder'

import {
  useAppStore,
  useI18nStore,
  useCountriesStore,
  useAuthStore,
  useTransactionStore,
  useStoredOrderStore,
  useProfileStore,
} from '@galileo/stores'
import { TransactionDetails } from '@galileo/models/SendMoney/interfaces/TransactionDetails'
import { PAYMENT_METHODS } from '@galileo/constants/sendMoneyFlow.const'

export const useActivityStore = defineStore('activity', () => {
  const appStore = useAppStore()
  const { $t } = useI18nStore()
  const transactionStore = useTransactionStore()
  const profileStore = useProfileStore()

  // States
  const lastTransactions = ref<Array<TransactionListItem>>([])
  const transactionPages = ref<number>(1)
  const totalTransactions = ref<number | null>(null)
  const transactionDetails = ref<TransactionDetails | null>(null)
  const selectedOrderNumber = ref<string>('')
  const transactionsChecked = ref<boolean>(false)
  const transactionsByCategory = ref<TransactionsByCategory>()
  const hasTransactions = ref<boolean>(false)
  // End of states

  // Getters
  const getLastTransactions = computed((): Array<TransactionListItem> => {
    return lastTransactions.value
  })

  const getTransactionPages = computed((): number => {
    return transactionPages.value
  })

  const getLastTransaction = computed((): Nullable<TransactionListItem> => {
    if (lastTransactions.value.length > 0) {
      return lastTransactions.value[0]
    }
    return null
  })

  const getSelectedOrderNumber = computed((): string => {
    return selectedOrderNumber.value
  })

  const getTransactionsChecked = computed((): boolean => {
    return transactionsChecked.value
  })

  const getTotalTransactions = computed((): number | null => {
    return totalTransactions.value
  })
  const showActivityTabs = computed((): boolean => {
    const authStore = useAuthStore()
    return (
      authStore.isCorporateAccount &&
      authStore.getIsQuickTransferEnabled &&
      !authStore.getIsBalancesEnabled
    )
  })
  // End of getters

  // Actions
  async function getTransactions(
    pageNumber: number = 1,
    currencyCode: Nullable<string> = null
  ): Promise<Array<TransactionListItem>> {
    // change when auth store is migrated to pinia
    const user = useAuthStore().userProfile
    const { data } = await getTransactionList.exec({
      profileId: user?.customer?.id,
      pageNumber,
      currencyCode,
    })

    if (data) {
      lastTransactions.value = data.transactions
      transactionPages.value = data.pages
      totalTransactions.value = data.totalItems

      hasTransactions.value = false
      if (data.totalItems > 0) {
        hasTransactions.value = true
      }

      return data.transactions
    } else {
      throw new Error(`Failed to get transactions`)
    }
  }

  async function getTransactionDetails(orderNumber: string): Promise<TransactionDetails> {
    const { data } = await getTransactionOrderNumber.exec(orderNumber)

    if (data) {
      const country = useAuthStore().userProfile?.country
      if (data.paymentMethod && data.paymentMethod.value === 'DirectDebit') {
        if (country === 'US') {
          data.paymentMethod.textKey = 'PageActivity.PaymentMethodDirectDebitACH'
        } else if (country === 'CA') {
          data.paymentMethod.textKey = 'PageActivity.PaymentMethodDirectDebitEFT'
        }
      }

      transactionDetails.value = data
      return data
    } else {
      throw new Error(`Failed to get transaction details`)
    }
  }

  function resetTransactionDetails() {
    transactionDetails.value = null
  }

  // modifyRecipientName - is this still in use???
  async function modifyRecipientName({
    transaction,
    recipientFields,
  }: {
    transaction: TransactionDetails
    recipientFields: any
  }) {
    return await patchBeneficiary.exec({
      recipientId: transaction.recipientId,
      orderNumber: transaction.orderNumber,
      recipientFields,
    })
  }

  async function cancelTransaction(orderNumber: string): Promise<void> {
    await postTransferCancel.exec({
      orderNumber,
      reason: '0', // hard coded like in the mobile app
    })
  }

  async function showPdf(orderNumber: string): Promise<void> {
    const { data } = await getContractDocument.exec(orderNumber)
    const fileURL = URL.createObjectURL(data)

    useAppStore().openWindow(fileURL)
  }

  async function shareGmail(
    transaction: TransactionDetails,
    loadingRef: Ref<boolean>
  ): Promise<void> {
    const subject = ''
    const body = await getTransactionShareBody({ transaction, loadingRef })

    window.open(
      `https://mail.google.com/mail/u/0/?view=cm&fs=1&su=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}&tf=1`
    )
  }

  async function shareEmail(
    transaction: TransactionDetails,
    loadingRef: Ref<boolean>
  ): Promise<void> {
    const body = await getTransactionShareBody({ transaction, loadingRef })
    window.location.href = `mailto:?body=${encodeURIComponent(body)}`
  }

  async function shareClipboard(
    transaction: TransactionDetails,
    loadingRef: Ref<boolean>
  ): Promise<void> {
    const body = await getTransactionShareBody({ transaction, loadingRef })

    try {
      await navigator.clipboard.writeText(body)
    } catch (e) {
      let textArea = document.createElement('textarea')
      textArea.value = body

      textArea.style.top = '0'
      textArea.style.left = '0'
      textArea.style.position = 'fixed'

      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      document.execCommand('copy')
      document.body.removeChild(textArea)
    }
  }

  async function getTransactionShareBody({
    transaction,
    loadingRef,
  }: {
    transaction: TransactionDetails
    loadingRef: Ref<boolean>
  }): Promise<string> {
    const i18nStore = useI18nStore()
    let text: string = ''

    const recipientFirstName = transaction.recipientFirstName
    const amount = transaction.amountTo
    const currency = transaction.currencyTo
    const pin = transaction.pin
    const agent = transaction.agentTo ? transaction.agentTo.name : ''
    const bankName = transaction.bankDepositBankName
    const bankAccount = ''
    const orderNumber = transaction.orderNumber

    const transactionStore = useTransactionStore()

    const location = await transactionStore.getLocationUrl({
      country: transaction.countryTo,
      currency: transaction.currencyTo,
      cityName: transaction.recipientAddressCity,
      stateName: transaction.recipientAddressState,
      agent: transaction.agentTo,
      agentLocation: transaction.agentToLocation,
      loadingRef,
    })

    switch (transaction.deliveryMethod) {
      case DeliveryMethod.BankDeposit:
        text = i18nStore.$t('ShareTransactionDetails.BodyBankDeposit', {
          recipientFirstName,
          amount,
          currency,
          bankName,
          bankAccount,
          pin,
          orderNumber,
        }).value
        break
      case DeliveryMethod.CashPickup:
        if (transaction.riaCashNetwork === 'OpenNetwork') {
          text = i18nStore.$t('ShareTransactionDetails.BodyOpenPaymentAgent', {
            recipientFirstName,
            amount,
            currency,
            pin,
            agent,
            location,
          }).value
        } else if (transaction.riaCashNetwork === 'PointToPoint') {
          text = i18nStore.$t('ShareTransactionDetails.BodyPointToPointAgent', {
            recipientFirstName,
            amount,
            currency,
            pin,
            location,
          }).value
        } else if (transaction.riaCashNetwork === 'OpenPayment') {
          text = i18nStore.$t('ShareTransactionDetails.BodyOpenPaymentCountry', {
            recipientFirstName,
            amount,
            currency,
            pin,
            location,
          }).value
        }
        break
      case DeliveryMethod.MobileWallet:
        text = i18nStore.$t('ShareTransactionDetails.BodyMobileWallet', {
          recipientFirstName,
          amount,
          currency,
        }).value
        break
      case DeliveryMethod.HomeDelivery:
        text = i18nStore.$t('ShareTransactionDetails.BodyHomeDelivery', {
          recipientFirstName,
          amount,
          currency,
          pin,
          orderNumber,
        }).value
        break
      default:
        break
    }

    let countryName: string = transaction.countryTo
    const countriesStore = useCountriesStore()
    const country = countriesStore.getCountryByIsoCode(countryName)
    if (country) {
      countryName = country.name
    }

    return text
  }

  function setSelectedOrderNumber(value: string): void {
    selectedOrderNumber.value = value
  }

  function resetForm(): void {
    lastTransactions.value = []
    transactionDetails.value = null
    selectedOrderNumber.value = ''
  }

  function setTransactionsChecked(value: boolean): void {
    transactionsChecked.value = value
  }

  const hasValidRecipient = (transaction: TransactionListItem): boolean => {
    if (transaction.recipientFullName || transaction.recipientFirstName) {
      return true
    }
    return false
  }

  async function reloadTransactions(
    pageNumber = 1,
    currencyCode: Nullable<string> = null
  ): Promise<void> {
    let transactions: Array<TransactionListItem | StoredOrder> = []

    try {
      await getTransactions(pageNumber, currencyCode).then((trxs) => {
        //we filter put transactions that are completed but don't have a valid recipient
        transactions = trxs.filter(
          (transaction: TransactionListItem) =>
            !(
              transaction?.transactionActivityStatus ===
              TRANSACTION_ACTIVITY_STATUSES.COMPLETED.name && !hasValidRecipient(transaction)
            )
        )
      })
    } catch (ex) {
      // TODO anything ?
      appStore.logException('Exception during getting transactions', ex)
      appStore.messageBoxGenericError()
      return
    }

    const storedOrder: StoredOrder | null = useStoredOrderStore().getStoredOrder

    const pendingTransactionsStatus = TRANSACTION_STATUSES_STEPS.AWAITING_BALANCE.name

    if (storedOrder) {
      storedOrder.transactionActivityStatus = TRANSACTION_ACTIVITY_STATUSES.ACTION_REQUIRED.name
      storedOrder.button = transactionStore.buttons.ProvideInfo
      transactions.unshift({
        ...storedOrder,
        continueOrder: true,
        recipientFullName: storedOrder.recipient.fullName,
        status: pendingTransactionsStatus,
        statusKey: $t('PageActivity.StatusDocumentVerification').value,
      })
    }

    fillTransactionsByCategory(transactions)
  }

  const fillTransactionsByCategory = (
    transactions: Array<TransactionListItem | StoredOrder> = []
  ) => {
    // fill transaction by category array with no transactions
    transactionsByCategory.value = {
      ACTION_REQUIRED: {
        title: $t('PageActivity.ActionRequiredTitle').value,
        theme: 'yellow',
        icon: require('@galileo/assets/images/activity-action-required.svg'),
        dot: true,
        transactions: [],
      },
      IN_PROGRESS: {
        title: $t('PageActivity.ProgressTitle').value,
        theme: 'blue',
        icon: require('@galileo/assets/images/activity-in-progress.svg'),
        transactions: [],
      },
      COMPLETED: {
        title: $t('PageActivity.CompletedTitle').value,
        theme: 'green',
        icon: require('@galileo/assets/images/activity-completed.svg'),
        transactions: [],
      },
      CANCELED: {
        title: $t('PageActivity.CanceledTitle').value,
        theme: 'gray',
        icon: require('@galileo/assets/images/activity-cancelled.svg'),
        transactions: [],
      },
    }

    setTransactionsChecked(true)

    if (transactions.length === 0) {
      profileStore.hasNoTransactions = true
    } else if (transactions.length > 0) {
      hasTransactions.value = true

      transactions.forEach((transaction: any) => {
        // Button
        if (
          transaction.transactionActivityStatus === TRANSACTION_STATUSES_STEPS.READY_FOR_PICKUP.name
        ) {
          transaction.button = transactionStore.buttons.Share
        } else if (
          transaction.transactionActivityStatus === TRANSACTION_STATUSES_STEPS.COMPLETED.name
        ) {
          if (transaction.deliveryMethod.enumName === DELIVERY_PROVIDERS.BALANCE) {
            transaction.button = transactionStore.buttons.Details
          } else {
            transaction.button = transactionStore.buttons.Resend
          }
        } else if (transaction.status === TRANSACTION_STATUSES_STEPS.CANCELLED.name) {
          transaction.button = transactionStore.buttons.Details
        } else if (
          !transaction.continueOrder &&
          (transaction.actionsRequiredType.includes(ACTION_REQUIRED.RECIPIENT_REQUIRED) ||
            transaction.actionsRequiredType.includes(ACTION_REQUIRED.EDD_OUTSTANDING))
        ) {
          transaction.button = transactionStore.buttons.ProvideInfo
        } else if (
          transaction.transactionDetailStatus === TRANSACTION_STATUSES_STEPS.COMPLETED.name &&
          transaction.transactionActivityStatus === TRANSACTION_ACTIVITY_STATUSES.COMPLETED.name
        ) {
        } else {
          transaction.button = transactionStore.buttons.Details
        }

        // Action required
        if (
          transaction.isActionRequired ||
          (transaction.transactionActivityStatus ===
            TRANSACTION_ACTIVITY_STATUSES.ACTION_REQUIRED.name &&
            transaction.paymentMethod !== PAYMENT_METHODS.FUNDS_ON_BALANCE)
        ) {
          transaction.transactionStatusTextKey = {
            status: TRANSACTION_ACTIVITY_STATUSES.ACTION_REQUIRED.name,
          }

          transactionsByCategory.value?.ACTION_REQUIRED.transactions.push(transaction)
          return
        }

        if (
          transaction.transactionActivityStatus === TRANSACTION_STATUSES_STEPS.READY_FOR_PICKUP.name
        ) {
          transaction.transactionStatusTextKey = {
            status: TRANSACTION_STATUSES_STEPS.READY_FOR_PICKUP.name,
          }

          transactionsByCategory.value?.IN_PROGRESS.transactions.push(transaction)
          return
        }

        // Completed
        if (
          transaction.transactionDetailStatus === TRANSACTION_STATUSES_STEPS.COMPLETED.name || 
          transaction.transactionActivityStatus === TRANSACTION_STATUSES_STEPS.COMPLETED.name 
        ) {
          transaction.transactionStatusTextKey = {
            status: TRANSACTION_STATUSES_STEPS.COMPLETED.name,
          }

          transactionsByCategory.value?.COMPLETED.transactions.push(transaction)
          return
        }

        // Canceled
        if (transaction.status === TRANSACTION_STATUSES_STEPS.CANCELLED.name) {
          transaction.transactionStatusTextKey = {
            status: TRANSACTION_STATUSES_STEPS.CANCELLED.name,
          }

          transactionsByCategory.value?.CANCELED.transactions.push(transaction)
          return
        }

        // In progress (everything else)
        transactionsByCategory.value?.IN_PROGRESS.transactions.push(transaction)
      })
    }
  }

  return {
    lastTransactions,
    transactionPages,
    totalTransactions,
    transactionDetails,
    getLastTransactions,
    getTransactionPages,
    getLastTransaction,
    getSelectedOrderNumber,
    getTransactionsChecked,
    getTotalTransactions,
    showActivityTabs,
    transactionsByCategory,
    hasTransactions: computed(() => hasTransactions.value),
    getTransactions,
    getTransactionDetails,
    resetTransactionDetails,
    modifyRecipientName,
    cancelTransaction,
    showPdf,
    shareGmail,
    shareEmail,
    shareClipboard,
    setSelectedOrderNumber,
    resetForm,
    setTransactionsChecked,
    reloadTransactions,
    getTransactionShareBody,
  }
})
