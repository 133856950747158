import { differenceInCalendarYears, parseISO } from 'date-fns'

export default class SessionLogPayload {
  constructor(userProfile) {
    let customer = userProfile.customer
    if (!customer) {
      customer = {}
    }
    this.accountType = customer.accountType
    this.name = `${userProfile.firstName} ${userProfile.lastName}`
    this.dateOfBirth = customer.dateOfBirth
    this.age = customer.dateOfBirth
      ? differenceInCalendarYears(new Date(), parseISO(customer.dateOfBirth))
      : null
    this.country = userProfile.country
    this.phoneNumber = userProfile.mobilePhone ? userProfile.mobilePhone.number : ''
    this.email = customer.email
    this.userId = customer.id
    this.city = userProfile.city
    this.userLanguage = userProfile.language
    this.locale = userProfile.cultureCode
    this.product = 'Web'
    this.transactionPushEnabled = userProfile.willReceiveSmsNotifications
    this.marketingPushEnabled = userProfile.willReceiveSmsMarketing
    this.marketingMailEnabled = userProfile.willReceiveEmailMarketing
    this.fxWebCorpMigrated = userProfile.fxWebCorpMigrated
  }

  getPayload() {
    return {
      accountType: this.accountType,
      name: this.name,
      dateOfBirth: this.dateOfBirth,
      age: this.age,
      country: this.country,
      phoneNumber: this.phoneNumber,
      email: this.email,
      userId: this.userId,
      city: this.city,
      userLanguage: this.userLanguage,
      locale: this.locale,
      product: this.product,
      transactionPushEnabled: this.transactionPushEnabled,
      marketingPushEnabled: this.marketingPushEnabled,
      marketingMailEnabled: this.marketingMailEnabled,
      fxWebCorpMigrated: this.fxWebCorpMigrated,
    }
  }
}
