/**
 * this store handles the user preferences
 * see MT-42372 for more information and how to use it
 */

import { defineStore } from 'pinia'
import {
  PreferenceGroupModelBase,
  PreferenceGroup,
  ApiPreferenceGroup,
  GroupNotFoundError,
  ScheduledPaymentsPreferences,
  StoreNotReadyError,
} from '@galileo/models/UserPreferences'

import getUserPreferences from '@galileo/api/launchpad/userPreferences/get'
import postUserPreferences from '@galileo/api/launchpad/userPreferences/post'
import { useAppStore } from '@galileo/stores'


// Define a Result type for operations that might fail
export type Result<T> = { success: true; value: T } | { success: false; error: Error }

// define store
export const useUserPreferencesStore = defineStore('userPreferences', () => {
  const _preferenceGroups :Record<PreferenceGroup, InstanceType<typeof PreferenceGroupModelBase>> = {
    // initilize preference groups with the default
    // add all preference groups here
    [PreferenceGroup.ScheduledPayments]: new ScheduledPaymentsPreferences()
    
  } as Record<PreferenceGroup, InstanceType<typeof PreferenceGroupModelBase>>

  let isLoading : boolean = false
  let isInitialized : boolean = false
  let appStore = useAppStore()
  
  /**
   * get a preference group from the store
   * does not re-fetch it from the DB, only returns the locally stored version
   */
  function getPreferenceGroup<T extends PreferenceGroupModelBase>(group: PreferenceGroup): Result<T>{
      if (!isInitialized) {
        appStore.logError("getPreferenceGroup store not ready")
        return { success: false, error: new StoreNotReadyError() }
      }
      const groupModel = _preferenceGroups[group] as T
      if (groupModel) {
        return { success: true, value: groupModel }
      }
      return { success: false, error: new GroupNotFoundError(group) }
  }
  
  /**
   * fetch all user preference groups from the API and replace the locally stored versions with it
   * normally it should not be necessary to call this (it's called internally by the init function)
   * @returns Result to indicate if successful or not
   */
  async function _fetchUserPreferenceGroups(): Promise<boolean> {
    if (isLoading) {
      appStore.logError("_fetchUserPreferenceGroups already fetching settings, Please wait and try again.")
      return false
    }

    isLoading = true
    
    try {
      const apiGroups : ApiPreferenceGroup[] = await getUserPreferences.exec()
      if (apiGroups) {
          apiGroups.forEach((apiGroup) => {
          const processedGroup = _processApiPreferenceGroup(apiGroup)
          if (processedGroup) {
            _setPreferenceGroup(processedGroup.group, processedGroup)
          }
        })
      }
      
      isInitialized = true
      return true
    } catch (err) {
      appStore.logException('Error fetching user preference groups:', err)
      return false
    } finally {
      isLoading = false
    }
  }

  /**
   * saves a spefic user preference group
   * @param groupModel the group to save
   * @returns the saved group as received by the API after saving
   */
  async function saveUserPreferenceGroup(groupModel: PreferenceGroupModelBase): Promise<boolean> {
    try {
      const apiModel = groupModel.toApiModel()
      const responseData : ApiPreferenceGroup = await postUserPreferences.exec(apiModel)
      const processedGroup = _processApiPreferenceGroup(responseData)
      if(processedGroup){
        _setPreferenceGroup(processedGroup.group, processedGroup)
        return true
      }
      else {
        appStore.logError(`Error processing user preference group: ${apiModel.group}`)
        return false
      }
    } catch (err) {
      appStore.logException('Error saving user preference group:', err)
      return false
    }
  }

  /**
   * init function to load the data from the API
   * call this first before doing anything else
   */
  async function init() {
    await _fetchUserPreferenceGroups()
  }

  /**
   * internal function to save a preference group to the store
   * @param group preference group enum to save
   * @param model group
   */
  function _setPreferenceGroup(group: PreferenceGroup, model: PreferenceGroupModelBase): void {
    _preferenceGroups[group] = model
  }

  /**
   * internal function to convert a preference group received from the API to the local model
   * @param apiGroup group received from the backend
   * @returns object of type Nullable<PreferenceGroupModelBase>
   */
  function _processApiPreferenceGroup(apiGroup: ApiPreferenceGroup): Nullable<PreferenceGroupModelBase> {
    let groupModel: PreferenceGroupModelBase
    switch (apiGroup.group.toLowerCase()) {
      case PreferenceGroup.ScheduledPayments.toLowerCase():
          groupModel = new ScheduledPaymentsPreferences()
          break
      default:
        appStore.log(`Unknown preference group: ${apiGroup.group}`)
        return null
    }
    groupModel.fromApiModel(apiGroup)
    return groupModel
  }

  return {
    isLoading,
    getPreferenceGroup,
    PreferenceGroup,
    saveUserPreferenceGroup,
    init
  }
})
