import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

import { useI18nStore } from '@galileo/stores'

export default new APIHandler(
  API,
  (config) => {
    const i18nStore = useI18nStore()
    const plaidLanguage = i18nStore.languageTagLanguage(i18nStore.i18n.locale)

    return {
      ...config,
      method: 'GET',
      url: `plaid/settings?language=${plaidLanguage}&platform=Galileo&redirectUrl=${encodeURIComponent(
        window.location.origin + '/plaid_oauth'
      )}`,
    }
  },
  (response) => {
    return response
  }
)
