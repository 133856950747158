import { ref } from '@vue/composition-api'
import { useAppStore, useAuthStore, useCardAccountStore, useI18nStore, useSendMoneyStore } from '@galileo/stores'

import { PAYMENT_METHODS } from '@galileo/constants/sendMoneyFlow.const'

import { ADYEN_CARD_BRAND } from '../useAdyenConfig/adyenTypes'
import { getAllInfoByCountry } from '../useIsoCountryCurrencyLibrary'
import { CARD_FULL_TYPE, CARD_LOOKUP_ERRORS, } from '@galileo/constants/cardPayments.const'





export default function () {
  const cardTypeList = ref<any[]>([])
  const cardLimitReached = ref<boolean>(false)

  const retrieveCardSettings = async (country = null) => {
    if (useAuthStore().isCorporateAccount || !useSendMoneyStore().getCardPaymentAvailable) {
      return
    }

    try {
      const cardAccountStore = useCardAccountStore()

      const { cardTypes, savedCardLimitReached } = await cardAccountStore.fetchCardSettings(country)

      cardTypeList.value = cardTypes ?? []
      cardLimitReached.value = savedCardLimitReached ?? true
    } catch (ex) {
      useAppStore().messageBoxGenericError()
      console.error('Exception retrieveing card settings', ex)
    }
  }

  const findTypeByProperty = (prop: string, value: string) => {
    if (cardTypeList.value?.length < 1) {
      throw new Error('Card types are not defined')
    }

    const result = cardTypeList.value.find((type: any) => type[prop] === value)
    return result
  }

  const createCardTitle = ({ maskedAccountNumber }: any) => {
    const cardNumberMasked = maskedAccountNumber.replaceAll('.', '•')
    const parts = cardNumberMasked.match(/\•+|\d+/g)

    const cardTitle = parts.join(' ')
    return cardTitle
  }

  //as a parameter we accept an object that has expiryYear and expiryMonth
  const createCardCaption = ({ expiryYear, expiryMonth }: any) => {
    //Expires on
    let cardCaption = useI18nStore().$t('ComponentAddPaymentCardModal.ExpiresOn').value

    //check if the year is already formatted correctly
    const fullExpiryYear = expiryYear.length === 2 ? `20${expiryYear}` : expiryYear
    //append expiration date
    cardCaption += ` ${expiryMonth}/${fullExpiryYear}`

    return cardCaption
  }

  function getCardFullName(data: { cardBrand: string }, cardType: string): string {
    let cardFullName = ''

    if (data?.cardBrand) {
      if (data.cardBrand === ADYEN_CARD_BRAND.VISA) {
        if (cardType === PAYMENT_METHODS.DEBIT_CARD) {
          cardFullName = CARD_FULL_TYPE.VISA_DEBIT
        } else {
          cardFullName = CARD_FULL_TYPE.VISA_CREDIT
        }
      } else if (data.cardBrand === 'mastercard' || data.cardBrand === ADYEN_CARD_BRAND.MASTERCARD) {
        if (cardType === PAYMENT_METHODS.DEBIT_CARD) {
          cardFullName = CARD_FULL_TYPE.MASTERCARD_DEBIT
        } else {
          cardFullName = CARD_FULL_TYPE.MASTERCARD_CREDIT
        }
      }
    }
    return cardFullName
  }

  function getBrandByCardType(fullCardType: string): string {
    if (fullCardType.toLocaleLowerCase().includes('visa')) {
      return ADYEN_CARD_BRAND.VISA
    }
    if (fullCardType.toLocaleLowerCase().includes('mastercard')) {
      return ADYEN_CARD_BRAND.MASTERCARD
    }

    throw Error("Brand not found")
  }


  function getCardErrorMessage(ex: any): Nullable<string> {
    const { $t } = useI18nStore()

    const CARD_LOOKUP_ERRORS_TRANSLATION_KEYS = {
      [CARD_LOOKUP_ERRORS.CDAPI_001]: (brand: string) => {
        return $t('CardHelper.BinLookupErrors.CardTypeNotAccepted', { brand }).value
      },
      [CARD_LOOKUP_ERRORS.CDAPI_002]: (type: string) => {
        return $t('CardHelper.BinLookupErrors.CardTypeNotAccepted', { brand: type }).value
      },
      [CARD_LOOKUP_ERRORS.CDAPI_004]: (country: string) => {
        return $t('CardHelper.BinLookupErrors.CardIssuerNotAccepted', { country }).value
      },
      [CARD_LOOKUP_ERRORS.CDAPI_005]: (brand: string) => {
        return $t('CardHelper.BinLookupErrors.CardTypeNotAccepted', { brand }).value
      },
    }


    const params = ex.errorObject.parameters

    let errorMessage: Nullable<string> = null

    switch (ex.errorObject?.reasonCode) {
      case CARD_LOOKUP_ERRORS.CDAPI_001: {
        const brand = params.find((pm: any) => pm.key === "brand")?.value
        if (brand) {
          errorMessage = CARD_LOOKUP_ERRORS_TRANSLATION_KEYS[CARD_LOOKUP_ERRORS.CDAPI_001](brand)
        }
        break
      }
      case CARD_LOOKUP_ERRORS.CDAPI_002: {
        const type = params.find((pm: any) => pm.key === "type")?.value
        if (type) {
          errorMessage = CARD_LOOKUP_ERRORS_TRANSLATION_KEYS[CARD_LOOKUP_ERRORS.CDAPI_002](type)
        }
        break
      }
      case CARD_LOOKUP_ERRORS.CDAPI_004: {
        const issuerCountry = params.find((pm: any) => pm.key === "issuer-country")?.value
        if (issuerCountry) {
          const countryInfo = getAllInfoByCountry(issuerCountry)
          errorMessage = CARD_LOOKUP_ERRORS_TRANSLATION_KEYS[CARD_LOOKUP_ERRORS.CDAPI_004](countryInfo.countryName)
        }
        break;
      }
      case CARD_LOOKUP_ERRORS.CDAPI_005: {
        const type = params.find((pm: any) => pm.key === "sub-type")?.value
        if (type) {
          errorMessage = CARD_LOOKUP_ERRORS_TRANSLATION_KEYS[CARD_LOOKUP_ERRORS.CDAPI_005](type)
        }
        break
      }
    }


    return errorMessage
  }

  return {
    retrieveCardSettings,
    cardTypeList,
    cardLimitReached,
    findTypeByProperty,
    createCardTitle,
    createCardCaption,
    getCardFullName,
    getBrandByCardType,
    getCardErrorMessage
  }
}
