import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  (orderNumber, config) => {
    return {
      ...config,
      method: 'GET',
      url: `transactions/contract-document?reference=${orderNumber}`,
      responseType: 'blob',
    }
  },
  (response) => {
    return response
  }
)
