import StringObject from '@galileo/types/StringObject'

const HelpDesk: StringObject = {
  base: 'https://help.xe.com',
  'what-documents-might-i-need-for-a-large-transfer': 'hc/articles/4409691358225',
  'paying-for-your-transfer-by-credit-card': 'hc/articles/360019656798',
  'how-does-xe-keep-my-money-and-information-safe': 'hc/articles/360019471677',
  'why-has-my-send-rate-changed': 'hc/articles/4407190167441',
  'paying-for-your-transfer-by-bank-or-wire-transfer': 'hc/articles/360019656558',
  'introducing-the-new-xe-web-experience': 'hc/articles/7051599819921',
  'how-do-i-cancel-my-money-transfer': 'hc/articles/360019471597',
  'why-couldn-t-my-transfer-be-completed': 'hc/articles/14669530362897',
  'paying-your-australian-taxation-office-ato-bill-using-xe': 'hc/articles/4402897555089',
  'how-do-i-update-my-account-details': 'hc/articles/16547551198353',
  'contact-details': 'hc/p/contact-us',
  'important-information': 'hc/sections/360005756477',
  'how-long-do-refunds-take': 'hc/articles/15204920947473',
  'terms-and-conditions-refer-a-friend': 'hc/articles/360020025678-Xe-s-refer-a-friend-programme',
  'sending-large-amounts': 'hc/articles/360019861137',
  'how-long-do-transfer-take': 'hc/articles/13769861901329',
  'how-long-do-transfer-take_corporate': 'hc/articles/16548874066065',
  'interac-email': 'interacCa@xe.com',
  'how-do-i-complete-verification': 'hc/articles/360019467417',
}

export const HelpDeskProperties: StringObject = {
  BASE: 'base',
  WHAT_DOCUMENTS_MIGHT_I_NEED_FOR_A_LARGE_TRANSFER:
    'what-documents-might-i-need-for-a-large-transfer',
  PAYING_FOR_YOUR_TRANSFER_BY_CREDIT_CARD: 'paying-for-your-transfer-by-credit-card',
  HOW_DOES_XE_KEEP_MY_MONEY_AND_INFORMATION_SAFE: 'how-does-xe-keep-my-money-and-information-safe',
  WHY_HAS_MY_SEND_RATE_CHANGED: 'why-has-my-send-rate-changed',
  PAYING_FOR_YOUR_TRANSFER_BY_BANK_OR_WIRE_TRANSFER:
    'paying-for-your-transfer-by-bank-or-wire-transfer',
  INTRODUCING_THE_NEW_XE_WEB_EXPERIENCE: 'introducing-the-new-xe-web-experience',
  HOW_DO_I_CANCEL_MY_MONEY_TRANSFER: 'how-do-i-cancel-my-money-transfer',
  WHY_COULDN_T_MY_TRANSFER_BE_COMPLETED: 'why-couldn-t-my-transfer-be-completed',
  PAYING_YOUR_AUSTRALIAN_TAXATION_OFFICE_ATO_BILL_USING_XE:
    'paying-your-australian-taxation-office-ato-bill-using-xe',
  HOW_DO_I_UPDATE_MY_ACCOUNT_DETAILS: 'how-do-i-update-my-account-details',
  CONTACT_DETAILS: 'contact-details',
  IMPORTANT_INFORMATION: 'important-information',
  HOW_LONG_DO_REFUNDS_TAKE: 'how-long-do-refunds-take',
  TERMS_AND_CONDITIONS_REFER_A_FRIEND: 'terms-and-conditions-refer-a-friend',
  SENDING_LARGE_AMOUNTS: 'sending-large-amounts',
  INTERAC_EMAIL: 'interac-email',
  HOW_LONG_DO_TRANSFERS_TAKE: 'how-long-do-transfer-take',
  HOW_DO_I_COMPLETE_VERIFICATION: 'how-do-i-complete-verification',
}

export default HelpDesk
