import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  ({ binNumber, profileId }, config) => {
    return {
      ...config,
      method: 'GET',
      url: `cardsV2/card-types/bin/${binNumber}`,
    }
  },
  (response) => {
    let data = null
    //cardV2
    if (response.data.data) {
      let result = response.data.data
      data = {
        id: result.id,
        name: result.name,
        cardType: result.paymentType,
        cardPspCode: result.id.toString().padStart(3, 0),
        cardBrand: result.code,
      }
    }

    return {
      response,
      data,
    }
  }
)
