
































import { PropType, ref, watch, defineComponent, provide } from '@vue/composition-api'
import { AppModalFooter, AppButton, AppInputCountry } from '@oen.web.vue2/ui'
import { useI18nStore, useKYCRefreshCorporateStore } from '@galileo/stores'
import KYCRefreshCorporateCard from '.././KYCRefreshCorporateCard.vue'
import { KycRefreshCorporateStepEnum } from '@galileo/stores/kycRefreshCorporate'
import CorporateAddressPicker from './CorporateAddressPicker.vue'
import { BvdAddressDetails, CorpProfileAddress } from '@galileo/models/Corporate/interfaces/CorpReg'
import { mapBvdAddressToCorpProfileAddress } from '@galileo/composables/useAddress/useCorporateAddress'
import { cloneDeep } from 'lodash-es'

export default defineComponent({
  name: 'KYCRefreshCorporateAddress',
  components: {
    KYCRefreshCorporateCard,
    AppModalFooter,
    AppButton,
    AppInputCountry,
    CorporateAddressPicker,
  },
  props: {
    address: {
      type: Object as PropType<CorpProfileAddress>,
      required: true,
    },
    addressType: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { $t } = useI18nStore()
    const kycRefreshCorporateStore = useKYCRefreshCorporateStore()

    // Reactive snapshot of the address data
    const addressData = ref<CorpProfileAddress>(cloneDeep(props.address))

    const isAddressValid = ref<boolean>(false)
    const updateCountry = (country: string) => {
      addressData.value = {
        country,
      }
    }
    const updateField = (format: 'freeFormat' | 'fixedFormat', field: string, newValue: string) => {
      addressData.value = {
        country: addressData.value.country,
        [format]: {
          ...(addressData.value[format] ?? {}),
          [field]: newValue,
        },
      }
    }

    const selectAddress = (addressDetails: BvdAddressDetails) => {
      const corpProfileAddress = mapBvdAddressToCorpProfileAddress(
        addressDetails,
        addressData.value.country
      )
      addressData.value = corpProfileAddress
    }

    const isAddressValidHandler = (isValid: boolean) => {
      isAddressValid.value = isValid
    }

    provide('updateField', updateField)
    provide('isAddressValidHandler', isAddressValidHandler)

    const confirmAddress = () => {
      kycRefreshCorporateStore.confirmAddress(props.addressType, cloneDeep(addressData.value))
    }

    watch(
      () => props.address,
      (newAddressData) => {
        addressData.value = cloneDeep(newAddressData)
      }
    )

    return {
      $t,
      KycRefreshCorporateStepEnum,
      goToStep: kycRefreshCorporateStore.goToStep,
      addressData,
      updateCountry,
      updateField,
      confirmAddress,
      isAddressValid,
      selectAddress,
    }
  },
})
