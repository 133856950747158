import {
  PaymentMethod,
  TransactionStatus,
} from '@galileo/api/launchpad/transactions/_orderNumber/get'
import { DeliveryMethod, DELIVERY_PROVIDERS } from '@galileo/models/Transaction/app'
import { AgentTo } from './Transaction'
import { AgentToLocation } from '@galileo/models/Activity/interfaces/Transactions'
import {
  ACTION_REQUIRED,
  TRANSACTION_ACTIVITY_STATUSES,
  TRANSACTION_STATUSES_STEPS,
} from '@galileo/constants/transactionStatuses'
import { DELIVERY_METHODS, PAYMENT_METHODS } from '@galileo/constants/sendMoneyFlow.const'
import useDeriveTransactionStatus from '@galileo/composables/useDeriveTransactionStatus'

export class Transaction {
  actionsRequired: string = ''
  deliveryMethodName: string = ''
  paymentMethodName: string = ''


  public get isOfflineTransaction(): boolean {
    return (
      this.actionsRequired !== ACTION_REQUIRED.NONE &&
      this.actionsRequired !== ACTION_REQUIRED.AWAITING_BALANCE &&
      !this.isFundingBalance
    )
  }

  public get isFundingBalance(): boolean {
    return this.deliveryMethodName === DELIVERY_METHODS.FUNDS_ON_BALANCE
  }

  public get isPaidFromBalance(): boolean {
    return this.paymentMethodName === PAYMENT_METHODS.FUNDS_ON_BALANCE
  }
}

type PaymentMethodKey = keyof typeof PaymentMethod

export class TransactionDetails extends Transaction {
  id: string
  amountFrom: number
  amountTo: number
  launchpadTransferId: string
  recipientId: number
  recipientFirstName: string
  recipientMiddleName: string
  recipientLastName: string
  recipientFullName: string
  countryTo: string
  currencyFrom: string
  currencyTo: string
  dateCreated: string
  date: string
  datePaymentReceived: string
  datePaymentSent: string
  valueDate: Date
  orderNumber: string
  amountTotal: number
  rate: number
  amountFee: number
  doesContractNoteExist: boolean
  recipientAddressCity: string
  recipientAddressState: string
  recipientBankName: string
  amountFixed: number
  currencyFixed: string
  transferReason: string
  actionsRequired: string
  transferReasonText: string
  pdfUrl: string
  bankAccountXe: any
  addressXe: any
  cancellable: boolean
  modifiable: boolean
  isResendable: boolean
  deliveryProvider: string
  pin: string
  riaOrderNumber: string
  riaCashNetwork: string
  agentTo: AgentTo
  agentId?: number
  agentName?: string
  agentToLocation: AgentToLocation
  agentLocationId?: number
  deliveryMethod: {
    id: number
    enumName: string
    text: string
    textKey: string
  }
  paymentMethod: { textKey: string; value: string }

  deliveryMethodAgentId?: string
  deliveryMethodLocation!: { agentId: any; agentName: any; id: any; city: any }
  bankAccountId: number
  cardId: number
  paymentMethodId: number
  liquidityManager: string
  transactionActivityStatus: string
  transactionDetailStatus: string
  status?: {
    value: string
    textKey: string
  }
  figureColor: string
  iconComponent: string
  bankDepositBankName: undefined
  destinationCity: any
  isOpenCountry: boolean
  transactionStatusTextKey: { textKey: string; status: string }

  constructor(transaction: any) {
    super()
    this.id = transaction.contractReference
    this.amountFrom = transaction.sendAmount
    this.amountTo = transaction.receiveAmount
    this.launchpadTransferId = transaction.launchpadTransferId
    this.recipientId = transaction.userSelections.recipientId
    this.recipientFirstName = transaction.recipientFirstName || transaction.recipientName
    this.recipientMiddleName = transaction.recipientMiddleName || ''
    this.recipientLastName = transaction.recipientLastName || ''
    this.recipientFullName = transaction.recipientName
    if (!this.recipientFullName) {
      this.recipientFullName = `${this.recipientFirstName} ${this.recipientLastName}`.trim()
    }
    this.countryTo = transaction.recipientCountry
    this.currencyFrom = transaction.sendCurrency
    this.currencyTo = transaction.receiveCurrency

    this.dateCreated = transaction.transactionDate
    this.date = transaction.transactionDate
    this.valueDate = new Date(transaction.valueDate)

    this.orderNumber = transaction.contractReference
    this.amountTotal = transaction.totalSendAmount

    this.rate = transaction.rate
    this.amountFee = transaction.totalFeesAmount
    this.doesContractNoteExist = transaction.doesContractNoteExist

    this.recipientAddressCity = transaction.recipientAddressCity
    this.recipientAddressState = transaction.recipientAddressState
    this.recipientBankName = transaction.recipientBank

    this.amountFixed = transaction.userSelections.fixedAmount
    this.currencyFixed = transaction.userSelections.fixedCcyCode
    this.transferReason = transaction.userSelections.purposeOfPaymentCode

    this.actionsRequired = transaction.actionsRequired

    this.transferReasonText = transaction?.userSelections?.purposeOfPaymentFreeText

    this.pdfUrl = transaction.contractNoteUrl
    this.bankAccountXe = transaction.ourBankAccount
    if (!this.bankAccountXe) {
      this.bankAccountXe = {}
    }
    this.addressXe = transaction.ourCompanyAddress
    if (this.addressXe) {
      let address = ''
      if (this.addressXe.companyAddressOne) {
        address += this.addressXe.companyAddressOne
      }
      if (this.addressXe.companyAddressTwo) {
        address += ` ${this.addressXe.companyAddressTwo}`
      }
      if (this.addressXe.companyAddressThree) {
        address += ` ${this.addressXe.companyAddressThree}`
      }
      this.addressXe.address = address
    } else {
      this.addressXe = {}
    }

    this.cancellable = transaction.canCancel
    this.modifiable = transaction.canEditBeneficiary
    this.isResendable = true // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    this.deliveryProvider = transaction.deliveryProvider

    this.datePaymentReceived = transaction.singlePaymentReceivedDate
    this.datePaymentSent = transaction.singlePaymentSentDate

    this.pin = transaction.riaOrderPin
    this.riaOrderNumber = transaction.riaOrderNumber
    this.riaCashNetwork = transaction.riaCashNetwork

    this.agentTo = transaction.userSelections.riaCorrespondent
    if (this.agentTo) {
      this.agentId = this.agentTo.id
      this.agentName = this.agentTo.name
    }
    this.agentToLocation = transaction.userSelections.riaLocation
    if (this.agentToLocation) {
      this.agentLocationId = this.agentToLocation.id
    }

    if (transaction.deliveryProvider === DELIVERY_PROVIDERS.CASH_PAYOUT) {
      this.deliveryMethod = DeliveryMethod.CashPickup
    } else if (transaction.deliveryProvider === DELIVERY_PROVIDERS.MOBILE_WALLET) {
      this.deliveryMethod = DeliveryMethod.MobileWallet
    } else if (this.deliveryProvider === DELIVERY_PROVIDERS.BALANCE) {
      this.deliveryMethod = DeliveryMethod.FundsOnBalance
    } else {
      this.deliveryMethod = DeliveryMethod.BankDeposit
    }

    this.deliveryMethodName = this.deliveryMethod.enumName

    this.paymentMethod = PaymentMethod[transaction.paymentMethod as PaymentMethodKey]
    this.paymentMethodName = this.paymentMethod.value

    if (
      this.deliveryMethod === DeliveryMethod.CashPickup ||
      this.deliveryMethod === DeliveryMethod.HomeDelivery
    ) {
      this.deliveryMethodAgentId = transaction.PayAgentId
      this.deliveryMethodLocation = {
        agentId: transaction.PayAgentId,
        agentName: transaction.PayAgentName,
        id: transaction.PayAgentLocId,
        city: transaction.PayAgentLocName,
      }
    }


    this.bankAccountId = transaction.userSelections.directDebitBankAccountId


    this.cardId = transaction.userSelections.cardId

    this.paymentMethodId = transaction?.userSelections?.settlementInstructionId


    if (this.paymentMethod) {
      switch (this.paymentMethod.value) {
        case 'DirectDebit':
          this.paymentMethodId = this.bankAccountId
          break
        case 'DebitCard':
        case 'CreditCard':
          this.paymentMethodId = this.cardId
          break
        case 'BankTransfer':
        default:
          this.paymentMethodId = transaction?.userSelections?.settlementInstructionId
          break
      }
    }

    this.liquidityManager = transaction.liquidityManager
    const { determineStatus } = useDeriveTransactionStatus()
    const { statusKey, transactionDetailStatus, transactionActivityStatus } =
      determineStatus(transaction)

    transaction.transactionDetailStatus = transactionDetailStatus
    transaction.transactionActivityStatus = transactionActivityStatus

    this.transactionActivityStatus = transaction.transactionActivityStatus

    this.transactionDetailStatus = transaction.transactionDetailStatus

    const statusValue =
      transaction.deliveryProvider === 'RiaCashPayout' ||
        transaction.deliveryProvider === 'RiaMobileWallet'
        ? transaction.transactionDetailStatus
        : transaction.transactionStatus

    for (const key in TransactionStatus) {
      const prop = key as keyof typeof TransactionStatus
      const value = TransactionStatus[prop]
      if (value.value === statusValue) {
        this.status = value
        break
      }
    }

    if (
      transaction.transactionActivityStatus === 'ActionRequired' &&
      !this.recipientId &&
      this.actionsRequired === 'None'
    ) {
      this.status = TransactionStatus.AwaitingRecipientDetails
    }

    this.figureColor = 'purple'
    this.iconComponent = 'IconSendAlt'
    if (
      this.status === TransactionStatus.Processing ||
      this.status === TransactionStatus.RecipientCanCollect
    ) {
      this.figureColor = 'green'
      this.iconComponent = 'IconTime'
    } else if (this.status === TransactionStatus.Completed) {
      this.figureColor = 'green'
      this.iconComponent = 'IconCheckmark'
    } else if (
      this.status === TransactionStatus.Expired ||
      this.status === TransactionStatus.Cancelled
    ) {
      this.figureColor = 'red'
      this.iconComponent = 'IconClose'
    }
    this.bankDepositBankName = transaction.ourBankAccount?.bankName || ''

    this.destinationCity = transaction.recipientAddressCity

    this.isOpenCountry = this.agentTo?.openPayment

    this.transactionStatusTextKey = { textKey: statusKey, status: this.transactionActivityStatus }
  }

  getTransactionStatusTextKey = (transaction: any): { textKey: string; status: string } => {
    if (
      transaction.isActionRequired ||
      transaction.transactionActivityStatus === TRANSACTION_ACTIVITY_STATUSES.ACTION_REQUIRED.name
    ) {
      return {
        textKey: 'PageActivity.ActionRequiredTitle',
        status: TRANSACTION_ACTIVITY_STATUSES.ACTION_REQUIRED.name,
      }
    }

    if (
      transaction.transactionActivityStatus === TRANSACTION_STATUSES_STEPS.READY_FOR_PICKUP.name
    ) {
      return {
        textKey: 'PageActivity.StatusReadyForPickup',
        status: TRANSACTION_STATUSES_STEPS.READY_FOR_PICKUP.name,
      }
    }

    // Completed
    if (
      transaction.status === TRANSACTION_STATUSES_STEPS.COMPLETED.name &&
      transaction.transactionActivityStatus !== TRANSACTION_ACTIVITY_STATUSES.IN_PROGRESS.name
    ) {
      return {
        textKey: 'PageActivity.CompletedTitle',
        status: TRANSACTION_STATUSES_STEPS.COMPLETED.name,
      }
    }

    // Canceled
    if (transaction.status === TRANSACTION_STATUSES_STEPS.CANCELLED.name) {
      return {
        textKey: 'PageActivity.CanceledTitle',
        status: TRANSACTION_STATUSES_STEPS.CANCELLED.name,
      }
    }

    // In progress (everything else)
    return {
      textKey: 'PageActivity.ProgressTitle',
      status: TRANSACTION_ACTIVITY_STATUSES.IN_PROGRESS.name,
    }
  }

  public get isOfflineTransaction(): boolean {
    return (
      this.actionsRequired !== ACTION_REQUIRED.NONE &&
      this.actionsRequired !== ACTION_REQUIRED.AWAITING_BALANCE &&
      !this.isFundingBalance
    )
  }

  public get isFundingBalance(): boolean {
    return this.deliveryMethod.enumName === DeliveryMethod.FundsOnBalance.enumName
  }

  public get isPaidFromBalance(): boolean {
    return this.paymentMethod?.value === PAYMENT_METHODS.FUNDS_ON_BALANCE
  }
}
