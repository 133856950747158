import { defineStore } from 'pinia'
import { ref, computed } from '@vue/composition-api'
import {
  useAnalyticsStore,
  useCorporateStore,
  useI18nStore
} from '@galileo/stores'
import {
  BaseCorpProfileAddress,
  CorpProfileAddress,
  UboStepData,
  EKYCRefreshItem,
  PendingActionsData,
  PersonnelItem,
  PersonnelItemEnhanced,
} from '@galileo/models/Corporate/interfaces/CorpReg'
import {
  SEGMENT_EVENTS,
  SEGMENT_EKYC_REFRESH_TYPE
} from '@galileo/constants/segmentAnalytics'

export enum KycRefreshCorporateStatusEnum {
  OK = 'ok',
  GRACE_PERIOD = 'grace period',
  OVERDUE = 'overdue',
}
export enum KycRefreshCorporateStepStatus {
  SAVED = 'saved',
  UNCHANGED = 'unchanged',
}
export type KycRefreshCorporateStepObj<T> = {
  status: KycRefreshCorporateStepStatus
  data: T | null
}
export enum KycRefreshCorporateStepEnum {
  WARNING,
  BUSINESS_NAME,
  SUCCESS,
  CONFIRMATION,
  REGISTRATION_ADDRESS,
  TRADING_ADDRESS,
  UBO_LIST,
  UBO_FORM,
}

export type KycRefreshCorporateStatus = KycRefreshCorporateStatusEnum

// milliseconds * minutes * seconds * hours
const milisecondsPerHour = 1000 * 60 * 60 * 24

export const useKYCRefreshCorporateStore = defineStore('kycRefreshCorporate', () => {
  // Stores
  const corporateStore = useCorporateStore()
  const analyticsStore = useAnalyticsStore()

  const { $t } = useI18nStore()

  // Aux date function (this is done to avoid issues with dates and timezones)
  const getDateInLocalTimezone = (isoDate: string) => {
    // isoDate format: "2028-01-13T15:02:21.435661+00:00"
    const [year, monthPlusOne, day] = isoDate.split('T')[0].split('-')
    const dateInLocalTz = new Date(parseInt(year), parseInt(monthPlusOne) - 1, parseInt(day)) // month is 0-indexed
    dateInLocalTz.setHours(0, 0, 0)
    return dateInLocalTz
  }

  // KYC refresh due date
  const dueDate = computed<Date | null>(() => {
    // return getDateInLocalTimezone('2025-01-11T15:02:21.435661+00:00') // ! FAKE DATA
    if (!corporateStore.clientUserDetails?.kycRefreshDueDate) {
      return null
    }
    return getDateInLocalTimezone(corporateStore.clientUserDetails.kycRefreshDueDate)
  })

  // KYC refresh last submission
  const lastRefreshDate = computed<Date | null>(() => {
    // return getDateInLocalTimezone("2010-01-15T15:02:21.435661+00:00") // ! FAKE DATA
    const allRefreshDates =
      corporateStore.clientInfo?.ekycRefresh.map((item: EKYCRefreshItem) => item.refreshDate) || []
    if (allRefreshDates.length === 0) {
      return null
    }
    // maxDate format: "2024-12-26T19:24:42.934+00:00"
    const maxDate = allRefreshDates.reduce((max: string, dt: string) => (dt > max ? dt : max))
    return getDateInLocalTimezone(maxDate)
  })

  // Days left (only used within grace period OR overdue period)
  const DAYS_LEFT_WARNING = 7
  const DAYS_LEFT_GRACE_PERIOD = 30
  const daysLeft = computed<number>(() => {
    if (!dueDate.value) {
      return 0
    }
    let today = new Date()
    today.setHours(0, 0, 0)

    return Math.round((dueDate.value.getTime() - today.getTime()) / milisecondsPerHour)
  })

  // KYC refresh status
  const status = computed<KycRefreshCorporateStatus>(() => {
    // No due date, all good
    if (!dueDate.value) {
      return KycRefreshCorporateStatusEnum.OK
    }

    let submissionDone: boolean = false
    if (lastRefreshDate.value) {
      // days * milisecondsPerHour
      let refreshTimePlus30Days = lastRefreshDate.value.getTime() + 30 * milisecondsPerHour
      let dueDateTime = dueDate.value.getTime()
      if (refreshTimePlus30Days >= dueDateTime) {
        // this means the last refresh was done somewhere between 30 days before due date OR after the due date
        submissionDone = true
      }
    }
    if (daysLeft.value > DAYS_LEFT_GRACE_PERIOD || submissionDone) {
      return KycRefreshCorporateStatusEnum.OK
    }
    if (daysLeft.value >= 0) {
      return KycRefreshCorporateStatusEnum.GRACE_PERIOD
    }
    return KycRefreshCorporateStatusEnum.OVERDUE
  })
  const isGracePeriod = computed<boolean>(
    () => status.value === KycRefreshCorporateStatusEnum.GRACE_PERIOD
  )
  //! REMOVE THE CHECK FOR 'isRolledOut' AFTER ROLLOUT IS DONE!
  const isOverdue = computed<boolean>(() => status.value === KycRefreshCorporateStatusEnum.OVERDUE && isRolledOut.value)
  const savedEKYCStatus = ref<string>('')

  // Modal
  const isModalVisible = ref(false)
  const showGoHomeBtn = ref(false)
  const verifyButtonClicked = () => {
    isModalVisible.value = true
  }
  function showModal({ isBlockingTransaction = false }: { isBlockingTransaction?: boolean } = {}): void {
    if (isBlockingTransaction) {
      // if blocking, show first card (warning)
      showGoHomeBtn.value = true
      goToStep(KycRefreshCorporateStepEnum.WARNING)
    }
    isModalVisible.value = true
  }
  const hideModal = (): void => {
    isModalVisible.value = false
  }
  //! TEMPORARY ROLLOUT
  //! Files KYCRefreshCorporateModal.vue and KYCRefreshCorporateAlert.vue have rollout logic that should be removed.
  //! After all regions are enabled, FIX THE isOverdue COMPUTED PROPERTY
  const isRolledOut = computed(() => {
    // ! MT-48566 && MT-49260
    // This is a temporary computed property to check if the KYC Refresh Corporate Modal is enabled
    // This can be removed after the we rollout every region
    return !!corporateStore.clientUserDetails?.kycRefreshEnabled
  })

  // **** User data and related functions
  const countryCode = computed(() =>
    corporateStore.clientUserDetails
      ? corporateStore.clientUserDetails.registrationAddress.country
      : ''
  )
  const isAPAC = computed(() => ['AU', 'NF', 'NZ', 'CK'].includes(countryCode.value))
  // * Name
  const nameStep = ref<KycRefreshCorporateStepObj<string>>({
    status: KycRefreshCorporateStepStatus.UNCHANGED,
    data: '',
  })
  const saveBusinessName = (fieldValue: string): void => {
    nameStep.value.status = KycRefreshCorporateStepStatus.SAVED
    nameStep.value.data = fieldValue
    // Trigger "KYC Refresh Business Name Saved" event
    analyticsStore.track({ event: SEGMENT_EVENTS.KYC_REFRESH_BUSINESS_NAME_SAVED })
    goToStep(KycRefreshCorporateStepEnum.CONFIRMATION)
  }
  const spliFirstNameForApac = (firstName: string) => {
    if (isAPAC.value && firstName.includes(' ')) {
      const pieces = firstName.split(' ')

      return {
        firstName: pieces[0],
        middleName: pieces.slice(1).join(' '),
      }
    } else {
      return {
        firstName,
        middleName: '',
      }
    }
  }

  // * Address
  const registrationAddressStep = ref<KycRefreshCorporateStepObj<CorpProfileAddress>>({
    status: KycRefreshCorporateStepStatus.UNCHANGED,
    data: null,
  })
  const tradingAddressStep = ref<KycRefreshCorporateStepObj<CorpProfileAddress>>({
    status: KycRefreshCorporateStepStatus.UNCHANGED,
    data: null,
  })
  const confirmAddress = (
    addressType: KycRefreshCorporateStepEnum,
    addressData: CorpProfileAddress
  ) => {
    let selectedAddressType = ''
    switch (addressType) {
      case KycRefreshCorporateStepEnum.REGISTRATION_ADDRESS:
        registrationAddressStep.value = {
          status: KycRefreshCorporateStepStatus.SAVED,
          data: addressData,
        }
        selectedAddressType = SEGMENT_EKYC_REFRESH_TYPE.REGISTERED_ADDRESS
        break
      case KycRefreshCorporateStepEnum.TRADING_ADDRESS:
        tradingAddressStep.value = {
          status: KycRefreshCorporateStepStatus.SAVED,
          data: addressData,
        }
        selectedAddressType = SEGMENT_EKYC_REFRESH_TYPE.TRADING_ADDRESS
        break
    }
    // Trigger "KYC Refresh Address Saved" event
    analyticsStore.track({
      event: SEGMENT_EVENTS.KYC_REFRESH_ADDRESS_SAVED,
      traits: {
        addressType: selectedAddressType
      }
    })
    goToStep(KycRefreshCorporateStepEnum.CONFIRMATION)
  }
  const getSingleLineFromAddress = (
    addressStepData: CorpProfileAddress | null,
    addressFromApi: CorpProfileAddress | undefined,
    countryCode: string
  ): string => {
    if (addressStepData) {
      return corporateStore.getLineAddress(
        addressStepData,
        countryCode
      )
    } else if (addressFromApi) {
      return corporateStore.getLineAddress(
        addressFromApi,
        countryCode
      )
    }
    return ''
  }


  // * UBOs
  const uboStep = ref<KycRefreshCorporateStepObj<UboStepData[]>>({
    status: KycRefreshCorporateStepStatus.UNCHANGED,
    data: [],
  })
  const selectedUbo = ref<PersonnelItemEnhanced>() // this will contain the dirtyValues of a UBO
  const selectUbo = (uboId: string | null) => {
    selectedUbo.value = undefined
    if (uboId) {
      const ubo = uboStep.value.data?.find((uboData) => uboData.dirtyValues.id === uboId)
      selectedUbo.value = ubo ? ubo.dirtyValues : undefined
    }
  }
  const updateUbo = (uboFormData: Partial<PersonnelItemEnhanced>): void => {
    if (selectedUbo.value) {
      selectedUbo.value.firstName = uboFormData.firstName
      selectedUbo.value.middleName = uboFormData.middleName || selectedUbo.value.middleName
      selectedUbo.value.occupation = uboFormData.occupation || selectedUbo.value.occupation
      selectedUbo.value.lastName = uboFormData.lastName
      selectedUbo.value.dob = uboFormData.dob
      selectedUbo.value.email = uboFormData.email
      selectedUbo.value.address = uboFormData.address
      if (uboFormData.isOther) {
        selectedUbo.value.isUbo = false
        selectedUbo.value.isDirector = false
        selectedUbo.value.isOther = true
        selectedUbo.value.role = uboFormData.role ?? ''
      } else {
        selectedUbo.value.isOther = false
        // * the "other role" is saved as occupation in dynamo
        selectedUbo.value.role = ''
        selectedUbo.value.isUbo = uboFormData.isUbo
        selectedUbo.value.isDirector = uboFormData.isDirector
      }
      selectedUbo.value.isDeleted = false
      selectedUbo.value.isSaved = true
    }
  }
  const createUbo = (uboFormData: Partial<PersonnelItemEnhanced>): void => {
    const newId = Math.random().toString().substring(2) // creates pseudo-random unique-enough ids

    uboStep.value.data?.push({
      confirmedValues: {
        id: newId,
        firstName: '',
        middleName: '',
        lastName: '',
        dob: '',
        email: '',
        occupation: '',
        isUbo: false,
        isDirector: false,
        isOther: false,
        role: '',
        isDeleted: false,
        isSaved: false,
        isSignatory: false,
        address: '',
      },
      dirtyValues: {
        id: newId,
        firstName: uboFormData.firstName,
        middleName: uboFormData.middleName ?? '',
        lastName: uboFormData.lastName,
        dob: uboFormData.dob,
        email: uboFormData.email,
        occupation: uboFormData.occupation ?? '',
        isUbo: uboFormData.isOther ? false : uboFormData.isUbo,
        isDirector: uboFormData.isOther ? false : uboFormData.isDirector,
        isOther: !!uboFormData.isOther,
        role: uboFormData.isOther ? uboFormData.role ?? '' : '',
        isDeleted: false,
        isSaved: true,
        isSignatory: false,
        address: uboFormData.address,
      },
    })
  }
  const toggleUboDeletion = (uboId: string): void => {
    // always toggle in the dirtyValues first
    const ubo = uboStep.value.data?.find((uboData) => uboData.dirtyValues.id === uboId)
    if (ubo) {
      if (!ubo.dirtyValues.isDeleted) {
        // Trigger "KYC Refresh Individual Deleted" event
        analyticsStore.track({ event: SEGMENT_EVENTS.KYC_REFRESH_INDIVIDUAL_DELETED })
      }
      ubo.dirtyValues.isDeleted = !ubo.dirtyValues.isDeleted
    }
  }
  const getModifiedUboKeys = (
    original: Partial<PersonnelItemEnhanced>,
    edited: Partial<PersonnelItemEnhanced>,
  ): string => {
    const modifiedKeys = [];
    // Iterate over all keys in the edited object
    for (const key in edited) {
      if (edited[key as keyof typeof edited] !== undefined) {
        // Compare the value in the edited object with the original
        if (
          String(original[key as keyof Partial<PersonnelItemEnhanced>] ?? '') !==
          String(edited[key as keyof Partial<PersonnelItemEnhanced>] ?? '')
        ) {
          modifiedKeys.push(key);
        }
      }
    }
    return modifiedKeys.join(', ');
  };
  const confirmUboList = (): void => {
    uboStep.value.data?.forEach((uboData) => {
      // This consolidates the changes made to each UBO
      uboData.confirmedValues = { ...uboData.dirtyValues }
    })
    uboStep.value.status = KycRefreshCorporateStepStatus.SAVED
    // Trigger "KYC Refresh All Individuals Saved" event
    analyticsStore.track({ event: SEGMENT_EVENTS.KYC_REFRESH_ALL_INDIVIDUALS_SAVED })
    goToStep(KycRefreshCorporateStepEnum.CONFIRMATION)
  }
  const confirmedBusinessOwnersToString = computed<string>(() => {
    return (
      (uboStep.value.data ?? [])
        // shows consolidated values only
        .map((uboStepData: UboStepData) => uboStepData.confirmedValues)
        .filter((ubo: PersonnelItemEnhanced) => !ubo.isDeleted && ubo.firstName && ubo.lastName)
        .map((ubo: PersonnelItemEnhanced) => {
          if (ubo.middleName) {
            return `${ubo.firstName} ${ubo.middleName} ${ubo.lastName}`
          }
          return `${ubo.firstName} ${ubo.lastName}`
        })
        .join(', ')
    )
  })
  const makeRegionalFixesToUbo = (ubo: PersonnelItemEnhanced): PersonnelItemEnhanced => {
    return {
      ...ubo,
      ...(countryCode.value !== 'CA' && ubo.isOther ? { occupation: ubo.role } : {}),
      ...(isAPAC.value && ubo.middleName
        ? { firstName: `${ubo.firstName} ${ubo.middleName}` }
        : {}),
    }
  }

  // * Computed prop for display purposes
  const accountDetailsData = computed(() => {
    // group data to populate account details array
    const titleList: string[] = [
      $t('ComponentKYCRefreshCorporateConfirmation.BusinessName').value,
      $t('ComponentKYCRefreshCorporateConfirmation.TradingAddress').value,
      $t('ComponentKYCRefreshCorporateConfirmation.RegistrationAddress').value,
      $t('ComponentKYCRefreshCorporateConfirmation.AuthorizedPersonnel').value,
    ]
    const descriptionList: string[] = [
      nameStep.value.data ?? '',
      getSingleLineFromAddress(
        tradingAddressStep.value.data,
        corporateStore.clientUserDetails?.tradingAddress,
        countryCode.value,
      ),
      getSingleLineFromAddress(
        registrationAddressStep.value.data,
        corporateStore.clientUserDetails?.registrationAddress,
        countryCode.value,
      ),
      confirmedBusinessOwnersToString.value,
    ]
    const editionStep: KycRefreshCorporateStepEnum[] = [
      KycRefreshCorporateStepEnum.BUSINESS_NAME,
      KycRefreshCorporateStepEnum.TRADING_ADDRESS,
      KycRefreshCorporateStepEnum.REGISTRATION_ADDRESS,
      KycRefreshCorporateStepEnum.UBO_LIST,
    ]
    const isSaved: boolean[] = [
      nameStep.value.status === KycRefreshCorporateStepStatus.SAVED,
      tradingAddressStep.value.status === KycRefreshCorporateStepStatus.SAVED,
      registrationAddressStep.value.status === KycRefreshCorporateStepStatus.SAVED,
      uboStep.value.status === KycRefreshCorporateStepStatus.SAVED,
    ]
    const parsedData = titleList.map((title, i) => {
      return {
        title: title,
        description: descriptionList[i],
        editionStepName: editionStep[i],
        isSaved: isSaved[i],
      }
    })
    return parsedData
  })

  // Parsed data for submission

  const parseEkycData = computed((): PendingActionsData => {
    const registeredName = nameStep.value.data ?? ''
    const registrationAddress =
      registrationAddressStep.value.data ||
      (corporateStore.clientUserDetails?.registrationAddress as Partial<BaseCorpProfileAddress>)
    const tradingAddress =
      tradingAddressStep.value.data ||
      (corporateStore.clientUserDetails?.tradingAddress as Partial<BaseCorpProfileAddress>)
    const authorizedPersonnel = (uboStep.value.data ?? [])
      .map((it) => makeRegionalFixesToUbo(it.confirmedValues))
      .filter((ubo) => !ubo.isDeleted)

    return {
      registeredName,
      registrationAddress,
      tradingAddress,
      authorizedPersonnel: authorizedPersonnel,
    }
  })

  // Creates data to be edited on the KYC refresh flow
  const populateKycRefreshData = (): void => {
    // Populate registration Address
    const registrationAddress = corporateStore.clientUserDetails?.registrationAddress
    registrationAddressStep.value.data = registrationAddress ?? null
    // Populate trading Address
    // ? If there's no trading address, we use registration address instead
    const tradingAddress = corporateStore.clientUserDetails?.tradingAddress ?? registrationAddress
    tradingAddressStep.value.data = tradingAddress ?? null
    
    // Populate UBOs
    // * Populate Name
    nameStep.value.data = corporateStore.clientUserDetails?.companyDetails.registeredName ?? ''

    // * Populate UBOs
    const mainContact = corporateStore.clientUserDetails?.mainContact
    let mainContactVerifStr = ''
    if (mainContact?.firstName && mainContact?.lastName && mainContact.email) {
      mainContactVerifStr = `${mainContact.firstName}${mainContact.lastName}${mainContact.email}`
    }

    // If accounts has auth personnel (data coming from client info)
    if (
      corporateStore.clientInfo?.authorizedPersonnel &&
      corporateStore.clientInfo.authorizedPersonnel.length > 0
    ) {
      uboStep.value.data = corporateStore.clientInfo.authorizedPersonnel.map(
        (ubo: PersonnelItem) => {
          const names = spliFirstNameForApac(ubo.firstName ?? '')
          const uboData: PersonnelItemEnhanced = {
            ...ubo,
            firstName: names.firstName,
            middleName: names.middleName,
            lastName: ubo.lastName ?? '',
            isSignatory:
              mainContactVerifStr.length > 0 &&
              mainContactVerifStr === `${ubo?.firstName}${ubo?.lastName}${ubo?.email}`,
            role: !ubo.isDirector && !ubo.isUbo && ubo.occupation ? ubo.occupation : '',
            isOther: (!ubo.isDirector && !ubo.isUbo && !!ubo.occupation) || false,
            isSaved: false,
            isDeleted: false,
            id: Math.random().toString().substring(2), // creates pseudo-random unique-enough ids
            address: ubo.address as string,
          }
          return {
            confirmedValues: { ...uboData },
            dirtyValues: { ...uboData },
          }
        }
      )
    } else if (mainContact) {
      // otherwise, there's just the main contact (auth signatory, coming from corporate/profiles endpoint)
      const names = spliFirstNameForApac(mainContact.firstName || '')

      const uboData: PersonnelItemEnhanced = {
        id: Math.random().toString().substring(2), // creates pseudo-random unique-enough ids
        firstName: names.firstName,
        middleName: names.middleName,
        lastName: mainContact.lastName,
        dob: mainContact.dateOfBirth,
        isDirector: mainContact.isDirector || false,
        isUbo: mainContact.isUbo || false,
        isPep: mainContact.isPep || false,
        email: mainContact.email,
        isSelected: false,
        isFinished: false,
        isEdit: false,
        isDone: false,
        isSignatory: true,
        isSaved: false,
        isDeleted: false,
        role: '',
        isOther: false,
        address: corporateStore.getLineAddress(mainContact.address, mainContact.address.country),
      }
      uboStep.value.data = [
        {
          confirmedValues: { ...uboData },
          dirtyValues: { ...uboData },
        },
      ]
    }
  }
  const resetStepsStatus = (): void => {
    uboStep.value.status = KycRefreshCorporateStepStatus.UNCHANGED
    nameStep.value.status = KycRefreshCorporateStepStatus.UNCHANGED
    tradingAddressStep.value.status = KycRefreshCorporateStepStatus.UNCHANGED
    registrationAddressStep.value.status = KycRefreshCorporateStepStatus.UNCHANGED
  }

  // *  KYC refresh flow control
  const currentStep = ref<KycRefreshCorporateStepEnum>(KycRefreshCorporateStepEnum.WARNING)
  const startKycRefresh = (alertLinkClicked: boolean = false): void => {
    populateKycRefreshData()
    resetStepsStatus()
    if (alertLinkClicked) {
      goToStep(KycRefreshCorporateStepEnum.CONFIRMATION)
    }
    showModal()
  }
  const goToStep = (step: KycRefreshCorporateStepEnum): void => {
    currentStep.value = step
  }

  return {
    populateKycRefreshData,
    dueDate,
    daysLeft,
    DAYS_LEFT_WARNING,
    status,
    isGracePeriod,
    isOverdue,
    isModalVisible,
    showModal,
    showGoHomeBtn,
    hideModal,
    accountDetailsData,
    savedEKYCStatus,
    getModifiedUboKeys,
    // user input
    registrationAddressStep,
    tradingAddressStep,
    uboStep,
    selectUbo,
    selectedUbo,
    updateUbo,
    createUbo,
    toggleUboDeletion,
    nameStep,
    // submission handler
    confirmUboList,
    confirmAddress,
    parseEkycData,
    saveBusinessName,
    // flow control
    currentStep,
    startKycRefresh,
    goToStep,
    // Data verification
    verifyButtonClicked,
    //! rollout (temporary)
    isRolledOut
  }
})
