import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  ({ orderNumber, recipientId, recipientFields }, config) => {
    return {
      ...config,
      method: 'PATCH',
      url: `/transfer/${orderNumber}/beneficiary/${recipientId}`,
      data: recipientFields,
    }
  },
  (response) => {
    return {
      ...response,
      data: {
        success:
          response.data.data.status === 'Successful' ||
          response.data.data.status === 'NameWasTheSame' ||
          response.data.data.status === 'SuccessfulNewOrder' ||
          response.data.data.status === 'OnHold',
        status: response.data.data.status, // SuccessfulNewOrder, OnHold, InvalidPin, InvalidOrderNo, NotAllowed, ContractNotReleased
      },
    }
  }
)
