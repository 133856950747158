import { API_VERSIONS } from '@galileo/constants/recipients/recipientFields.const.js'

//------------------- get recipients APIs -----------

import getRecipients from '@galileo/api/launchpad/recipients/_profileId/get'
import getRecipientsV2 from '@galileo/api/launchpad/v2/recipients/_profileId/get'

import getFields from '@galileo/api/launchpad/recipients/fields/get'
import getFieldsV2 from '@galileo/api/launchpad/v2/recipients/fields/get'

import getRecipient from '@galileo/api/launchpad/recipients/_profileId/recipient/_id/get'
import getRecipientV2 from '@galileo/api/launchpad/v2/recipients/_profileId/recipient/_id/get'

import putRecipient from '@galileo/api/launchpad/recipients/_profileId/recipient/_id/put'
import putRecipientV2 from '@galileo/api/launchpad/v2/recipients/_profileId/recipient/_id/put'

import addRecipient from '@galileo/api/launchpad/recipients/_profileId/recipient/post'
import addRecipientV2 from '@galileo/api/launchpad/v2/recipients/_profileId/recipient/post'

import validateRecipient from '@galileo/api/launchpad/recipients/_profileId/validate/_id/get'
import validateRecipientV2 from '@galileo/api/launchpad/v2/recipients/_profileId/validate/_recipientId/get'

export const getRecipientEndpoint = (call, version = API_VERSIONS.default) => {
  const recipientsAPI = {
    default: {
      getRecipients: getRecipients,
      getFields: getFields,
      getRecipient: getRecipient,
      putRecipient: putRecipient,
      addRecipient: addRecipient,
      validateRecipient: validateRecipient,
    },
    v2: {
      getRecipients: getRecipientsV2,
      getFields: getFieldsV2,
      getRecipient: getRecipientV2,
      putRecipient: putRecipientV2,
      addRecipient: addRecipientV2,
      validateRecipient: validateRecipientV2,
    },
  }

  return recipientsAPI[version][call]
}

export default getRecipientEndpoint
