var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppModal',{attrs:{"title":_vm.$t('ComponentAddressChangeTermsAndConditionsModal.Title').value},on:{"show":_vm.onShow},scopedSlots:_vm._u([{key:"figureTitle",fn:function(){return [_c('AppCardImageTitle',{attrs:{"alt":"Alarm clock","title":_vm.$t('ComponentAddressChangeTermsAndConditionsModal.Title').value,"src":require("@galileo/assets/images/warning.svg")}})]},proxy:true},{key:"default",fn:function(){return [_c('p',{staticClass:"address-changed-text"},[_vm._v(" "+_vm._s(_vm.$t('ComponentAddressChangeTermsAndConditionsModal.TextAddressChanged').value)+" ")]),_c('div',{staticClass:"mini-card"},[_c('p',{domProps:{"innerHTML":_vm._s(
            _vm.$t('ComponentAddressChangeTermsAndConditionsModal.TextTermsAndConditions', {
              privacyNoticeURL: _vm.privacyNoticeURL,
              termsAndConditionsURL: _vm.termsAndConditionsURL,
            }).value
          )}}),_c('AppInputCheckbox',{model:{value:(_vm.isAcceptedTermsAndConditionsCheckmark),callback:function ($$v) {_vm.isAcceptedTermsAndConditionsCheckmark=$$v},expression:"isAcceptedTermsAndConditionsCheckmark"}},[_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.$t('ComponentAddressChangeTermsAndConditionsModal.TextIAgree', {
                termsAndConditionsURL: _vm.termsAndConditionsURL,
              }).value
            )}})])],1)]},proxy:true},{key:"footer",fn:function(){return [_c('AppModalFooter',[_c('AppButton',{attrs:{"analytics-name":"login-new-terms-agree","loading":_vm.loading,"disabled":!_vm.isAcceptedTermsAndConditionsCheckmark},on:{"click":_vm.acceptTermsAndConditions}},[_vm._v(" "+_vm._s(_vm.$t('ComponentAddressChangeTermsAndConditionsModal.ButtonContinue').value)+" ")])],1)]},proxy:true}]),model:{value:(_vm.showTermsAndConditionsModal),callback:function ($$v) {_vm.showTermsAndConditionsModal=$$v},expression:"showTermsAndConditionsModal"}}),_c('InformationAppModal',{staticClass:"wire-transfer-notice-modal",attrs:{"value":_vm.showWireTransferNoticeModal,"icon":"bank"},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('AppButton',{on:{"click":function($event){_vm.showWireTransferNoticeModal = false}}},[_vm._v(" "+_vm._s(_vm.$t('ComponentAddressChangeTermsAndConditionsModal.ButtonContinue').value)+" ")])]},proxy:true}])},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('WireTransferNoticeModal.Title').value))]),_c('p',{staticClass:"wire-notice-text"},[_vm._v(_vm._s(_vm.$t('WireTransferNoticeModal.FirstParagraph').value))]),_c('p',{staticClass:"wire-notice-text"},[_vm._v(_vm._s(_vm.$t('WireTransferNoticeModal.SecondParagraph').value))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }