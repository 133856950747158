export default class CustomError extends Error {
  statusCode = 400
  code: string

  constructor(message: string, code: string) {
    super(message)
    Object.setPrototypeOf(this, CustomError.prototype)

    this.code = code
  }

  getErrorMessage() {
    return 'Something went wrong: ' + this.message
  }
}
