import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  ({ recipientId, isMobileWallet = false }, config) => {
    let url = `/CashRecipients/${recipientId}`

    if (isMobileWallet) {
      url = `/CashRecipients/${recipientId}?paymentMethod=MobileWallet`
    }

    return {
      ...config,
      method: 'DELETE',
      url: url,
    }
  },
  (response) => {
    if (response.status === 204) {
      return true
    } else {
      throw false
    }
  }
)
