














































import { defineComponent, computed } from '@vue/composition-api'
import {
  AppCard,
  AppCardHeader,
  AppIcon,
  useMediaQuery,
} from '@oen.web.vue2/ui'
import IconClose from '@oen.web.vue2/icons/src/components/IconClose.vue'
import IconChevronLeft from '@oen.web.vue2/icons/src/components/carbon/IconChevronLeft.vue'
import { useKYCRefreshCorporateStore } from '@galileo/stores'

export default defineComponent({
name: 'KYCRefreshCorporateCard',
components: {
  AppCard,
  AppCardHeader,
  AppIcon,
  IconClose,
  IconChevronLeft,
},
emits: ['close', 'back'],
props: {
  title: {
    type: String,
    default: '',
  },
  showCloseButton: {
    type: Boolean,
    default: false,
  },
  showBackButton: {
    type: Boolean,
    default: false,
  },
},
  setup(_, { slots, emit }) {
    const kycRefreshCorporateStore = useKYCRefreshCorporateStore()

    // Layout
    const mq = useMediaQuery()
    const cardClasses = computed(() => {
      if (mq.current === 'xs') {
        return 'kyc-card-mobile'
      }
      return 'kyc-card'
    })

    // Event handlers
    const hideModal = () => {
      kycRefreshCorporateStore.hideModal()
    }

    return {
      slots,
      emit,
      cardClasses,
      // handlers
      hideModal
    }
  },
})
