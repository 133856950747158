import { PAYMENT_METHODS } from '@galileo/constants/sendMoneyFlow.const'
import {
  ACTION_REQUIRED,
  TRANSACTION_ACTIVITY_STATUSES,
  TRANSACTION_DETAIL_STATUSES,
  TRANSACTION_STATUSES_STEPS,
} from '@galileo/constants/transactionStatuses'
import { DELIVERY_PROVIDERS } from '@galileo/models/Transaction/app'
import { TransactionData } from '@galileo/models/Transaction/interfaces/Transaction'

const statusMap = {
  ReadyForPickup: 'PageActivity.StatusReadyForPickup',
  PendingCancellation: 'PageActivity.StatusPendingCancelation',
  Completed: 'PageActivity.StatusTransferCompleted',
  Cancelled: 'PageActivity.StatusCanceled',
  InProgress: 'PageActivity.StatusInProgress',
  ActionRequired: 'PageActivity.StatusActionRequired',
  AdditionalInformationRequired: 'PageActivity.StatusAdditionalInformationRequired',
  AwaitingRecipientDetails: 'PageActivity.StatusAwaitingRecipientDetails',
  Pending: 'PageActivity.StatusPending',
  FundingInProgress: 'PageActivity.StatusFundingInProgress',
  ConversionInProgress: 'PageActivity.StatusConversionInProgress',
  FundsConverted: 'PageActivity.StatusFundsConverted',
  FundsAdded: 'PageActivity.StatusFundsAdded',
}

export default function () {
  const determineStatus = (
    data: TransactionData
  ): {
    statusKey: string
    actionRequired: boolean
    transactionDetailStatus: string
    transactionActivityStatus: string
  } => {
    let statusKey = ''
    let action = false
    let detailStatus = data.transactionStatus
    let activityStatus = data.transactionActivityStatus

    // Early return for cancelled transactions
    if (activityStatus === TRANSACTION_STATUSES_STEPS.CANCELLED.name) {
      return {
        statusKey: statusMap.Cancelled,
        actionRequired: false,
        transactionDetailStatus: TRANSACTION_STATUSES_STEPS.CANCELLED.name,
        transactionActivityStatus: activityStatus,
      }
    }

    // Early return for missing recipient details
    if (
      activityStatus === TRANSACTION_ACTIVITY_STATUSES.ACTION_REQUIRED.name &&
      !data.recipientAccountName &&
      !data.recipientFirstName &&
      !data.recipientLastName &&
      data.deliveryProvider !== PAYMENT_METHODS.FUNDS_ON_BALANCE
    ) {
      return {
        statusKey: statusMap.AwaitingRecipientDetails,
        actionRequired: true,
        transactionDetailStatus: TRANSACTION_STATUSES_STEPS.AWAITING_RECIPIENT_DETAILS.name,
        transactionActivityStatus: activityStatus,
      }
    }

    if (
      (data.actionsRequired && isAdditionalInformationRequired(data.actionsRequired)) ||
      isBankPending(data.paymentMethod, detailStatus) ||
      isActionRequired(detailStatus) ||
      isPendingCancellation(activityStatus) ||
      isPendingConfirmation(detailStatus)
    ) {
      statusKey = statusMap.ActionRequired
      action = true
    } else if (isReadyForPickup(activityStatus)) {
      statusKey = statusMap.ReadyForPickup
    } else if (isInProgress(detailStatus, activityStatus)) {
      statusKey = statusMap.InProgress
    } else if (isCompleted(detailStatus)) {
      statusKey = statusMap.Completed
    }

    // Handle balance methods in a separate function
    const balanceStatus = handleBalanceMethods(data, data.paymentMethod, statusMap)
    if (balanceStatus) {
      return balanceStatus
    }

    return {
      statusKey,
      actionRequired: action,
      transactionDetailStatus: detailStatus,
      transactionActivityStatus: activityStatus,
    }
  }

  const isAdditionalInformationRequired = (actionsRequiredType: string): boolean => {
    return (
      !!actionsRequiredType &&
      (actionsRequiredType.includes(ACTION_REQUIRED.RECIPIENT_REQUIRED) ||
        actionsRequiredType.includes(ACTION_REQUIRED.EDD_OUTSTANDING))
    )
  }

  const isBankPending = (paymentMethod: string, detailStatus: string): boolean => {
    const bankPending = ['Pending', 'AwaitingBalance']
    return paymentMethod === PAYMENT_METHODS.BANK_TRANSFER && bankPending.includes(detailStatus)
  }

  const isActionRequired = (detailStatus: string): boolean => {
    const actionRequired = [
      'AwaitingRecipientDetails',
      'AwaitingInitialMargin',
      'HoldOnAccount',
      'AwaitingPaymentAuthorisation',
    ]
    return actionRequired.includes(detailStatus)
  }

  const isReadyForPickup = (activityStatus: string): boolean => {
    return activityStatus === TRANSACTION_STATUSES_STEPS.READY_FOR_PICKUP.name
  }

  const isPendingCancellation = (activityStatus: string): boolean => {
    return activityStatus === TRANSACTION_STATUSES_STEPS.PENDING_CANCELLATION.name
  }

  const isPendingConfirmation = (detailStatus: string): boolean => {
    return detailStatus === TRANSACTION_STATUSES_STEPS.AWAITING_CONFIRMATION.name
  }

  const isInProgress = (detailStatus: string, activityStatus: string): boolean => {
    const inProgress = [
      'Confirmed',
      'AwaitingBalance',
      'BalanceReceived',
      'Pending',
      'AwaitingRelease',
      'UnclearedFundsReceived',
      'AwaitingFundsInvestigation',
      'AwaitingVerification',
      'FundsInvestigation',
    ]
    return (
      inProgress.includes(detailStatus) ||
      activityStatus === TRANSACTION_ACTIVITY_STATUSES.IN_PROGRESS.name
    )
  }

  const isCompleted = (detailStatus: string): boolean => {
    return detailStatus === TRANSACTION_STATUSES_STEPS.COMPLETED.name
  }

  const handleBalanceMethods = (
    data: TransactionData,
    paymentMethod: string,
    statusMap: { [key: string]: string }
  ): {
    statusKey: string
    actionRequired: boolean
    transactionDetailStatus: string
    transactionActivityStatus: string
  } | null => {
    const { transactionStatus: detailStatus, transactionActivityStatus: activityStatus } = data

    // Early return if not related to balance
    if (data.deliveryProvider !== DELIVERY_PROVIDERS.BALANCE) {
      return null
    }

    switch (activityStatus) {
      case TRANSACTION_ACTIVITY_STATUSES.IN_PROGRESS.name:
        return {
          statusKey:
            paymentMethod !== PAYMENT_METHODS.FUNDS_ON_BALANCE
              ? statusMap.FundingInProgress
              : statusMap.ConversionInProgress,
          actionRequired: false,
          transactionDetailStatus: detailStatus,
          transactionActivityStatus: activityStatus,
        }

      case TRANSACTION_ACTIVITY_STATUSES.CANCELED.name:
        return {
          statusKey: statusMap.Cancelled,
          actionRequired: false,
          transactionDetailStatus: detailStatus,
          transactionActivityStatus: activityStatus,
        }

      case TRANSACTION_ACTIVITY_STATUSES.ACTION_REQUIRED.name:
        if (detailStatus === TRANSACTION_DETAIL_STATUSES.HOLD_ON_ACCOUNT) {
          return {
            statusKey:
              paymentMethod === PAYMENT_METHODS.FUNDS_ON_BALANCE
                ? statusMap.FundsConverted
                : statusMap.FundsAdded,
            actionRequired: false,
            transactionDetailStatus: TRANSACTION_DETAIL_STATUSES.COMPLETED,
            transactionActivityStatus: TRANSACTION_ACTIVITY_STATUSES.COMPLETED.name,
          }
        }
        if (
          detailStatus === TRANSACTION_DETAIL_STATUSES.AWAITING_BALANCE &&
          paymentMethod === PAYMENT_METHODS.FUNDS_ON_BALANCE
        ) {
          return {
            statusKey: statusMap.InProgress,
            actionRequired: false,
            transactionDetailStatus: detailStatus,
            transactionActivityStatus: activityStatus,
          }
        }
        if (
          detailStatus === TRANSACTION_DETAIL_STATUSES.AWAITING_BALANCE &&
          paymentMethod === PAYMENT_METHODS.BANK_TRANSFER
        ) {
          return {
            statusKey: statusMap.ActionRequired,
            actionRequired: true,
            transactionDetailStatus: detailStatus,
            transactionActivityStatus: activityStatus,
          }
        }
        return {
          statusKey:
            paymentMethod === PAYMENT_METHODS.FUNDS_ON_BALANCE
              ? statusMap.InProgress
              : statusMap.ActionRequired,
          actionRequired: paymentMethod !== PAYMENT_METHODS.FUNDS_ON_BALANCE,
          transactionDetailStatus: detailStatus,
          transactionActivityStatus: activityStatus,
        }

      case TRANSACTION_ACTIVITY_STATUSES.COMPLETED.name:
        return {
          statusKey:
            paymentMethod === PAYMENT_METHODS.FUNDS_ON_BALANCE
              ? statusMap.FundsConverted
              : statusMap.FundsAdded,
          actionRequired: false,
          transactionDetailStatus: detailStatus,
          transactionActivityStatus: activityStatus,
        }

      default:
        return null
    }
  }

  return { determineStatus }
}
