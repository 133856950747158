import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  ({ cardId, profileId }, config) => {
    return {
      ...config,
      method: 'DELETE',
      url: `cardsV2/card?ProfileId=${profileId}&CardId=${cardId}`,
    }
  },
  (response) => {
    return {
      ...response,
      data: {
        id: response.data.cardId,
      },
    }
  }
)
