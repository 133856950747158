import * as randomBytes from 'randombytes'
import md5 from 'crypto-js/md5'
import sha1 from 'crypto-js/sha1'

export const useHash = () => {
  const hash = (input) => {
    return md5(input).toString()
  }

  const hashSha1 = (input) => {
    return sha1(input).toString()
  }

  // Adds suffix of current date ISO to input
  const hashWithDate = (input) => {
    return hash(`${input}-${new Date().toISOString()}`)
  }

  // Adds suffix of current date ISO and random bytes to input
  const hashWithDateAndBytes = (input, bytes = 16) => {
    return hash(`${input}-${new Date().toISOString()}-${randomBytes(bytes)}`)
  }

  return {
    hash,
    hashSha1,
    hashWithDate,
    hashWithDateAndBytes,
  }
}
