























  import { defineComponent } from '@vue/composition-api'
  import {
  AppButton,
  AppIcon,
} from '@oen.web.vue2/ui'
import IconLocation from '@oen.web.vue2/icons/src/components/carbon/IconLocation.vue'
import IconEdit from '@oen.web.vue2/icons/src/components/carbon/IconEdit.vue'

  export default defineComponent({
    name: '',
    components: {
      AppButton,
      AppIcon,
      IconLocation,
      IconEdit,
    },
    emits: ['edit'],
    props: {
      addressString: {
        type: String,
        default: null,
      },
      label: {
        type: String,
        required: true,
      },
    },
  })
