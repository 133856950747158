import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  (currency, config) => {
    return {
      ...config,
      method: 'GET',
      url: `/recipients/BeneficiaryRelationships`,
    }
  },
  (response) => {
    return {
      ...response,
      data: response.data.data.map((field) => {
        return {
          value: field.code,
          text: field.description,
        }
      })
    }
  }
)
