import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'
import { IndustriesPayload } from './interfaces'
import { AxiosResponse } from 'axios'
import { IndustryType } from '@galileo/models/Industry/industryType'

export default new APIHandler(
  API,
  ({ config, lang }: IndustriesPayload) => {
    return {
      ...config,
      method: 'GET',
      url: `resources/industries?language=${lang}`,
    }
  },
  (response: AxiosResponse): IndustryType[] => {
    return response.data
  }
)
