





















import { PropType, ref, reactive, defineComponent } from '@vue/composition-api'
import { useI18nStore, useCountriesStore, useResourcesStore } from '@galileo/stores'
import { AppInputCountry } from '@oen.web.vue2/ui'
import { CorpProfileAddress, BvdAddressDetails } from '@galileo/models/Corporate/interfaces/CorpReg'
import XeAddressSearch from '@galileo/components/XeAddressSearch/XeAddressSearch.vue'
import CorporateAddressFieldsSwitcher from './CorporateAddressFieldsSwitcher.vue'
import { usePromiseLazy } from 'vue-composable'

export default defineComponent({
  name: 'CorporateAddressPicker',
  emits: ['updateCountry', 'selectAddress'],
  components: {
    AppInputCountry,
    CorporateAddressFieldsSwitcher,
    XeAddressSearch,
  },
  props: {
    address: {
      type: Object as PropType<CorpProfileAddress>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const countriesStore = useCountriesStore()
    const resourcesStore = useResourcesStore()

    const { $t } = useI18nStore()
    const filteredCountries = ref<any>()
    filteredCountries.value = countriesStore.getCountries
    const updateCountry = (country: string) => {
      emit('updateCountry', country)
    }

    const getAddressDetailsPromiseLazy = reactive(
      usePromiseLazy((selectedId) => {
        let result = resourcesStore.getAddressDetails(selectedId)
        return result
      })
    )

    const searchPromiseLazy = reactive(
      usePromiseLazy(({ query, selectedId = null }) => {
        let result = resourcesStore.getAddressSearchResult({
          searchTerm: query,
          searchContext: selectedId,
          country: props.address.country,
        })
        return result
      })
    )

    const onSelectAddress = (address: BvdAddressDetails) => {
      emit('selectAddress', address)
    }

    return {
      $t,
      filteredCountries,
      updateCountry,
      searchPromiseLazy,
      getAddressDetailsPromiseLazy,
      onSelectAddress,
    }
  },
})
