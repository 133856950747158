export default class Location {
  constructor(location) {
    this.agentId = location.agentToId
    this.agentName = location.agentToName
    this.businessHours = location.businessHours
    this.collectionLocation = location.collectionLocation
    this.currency = location.currency
    this.latitude = location.latitude
    this.street = location.locationAddress
    this.street2 = location.locationAddress2
    this.city = location.locationCity
    this.id = location.locationId
    this.name = location.locationName
    this.state = location.locationState
    this.type = location.locationType
    this.longitude = location.longitude
    this.payoutLocation = location.payoutLocation
    this.phone = location.phone
    this.sendLocation = location.sendLocation
  }
}
