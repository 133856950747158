
















































































import { PropType, ref, computed, defineComponent, watch, onBeforeMount } from '@vue/composition-api'
import { AppInputText, AppInputDropdown } from '@oen.web.vue2/ui'
import { CorpProfileAddress } from '@galileo/models/Corporate/interfaces/CorpReg'
import { useI18nStore, useCountriesStore } from '@galileo/stores'
import { useValidation } from 'vue-composable'
import getValueRequiredValidation from '@galileo/utilities/validations.utility'
import State from '@galileo/models/State/app'
import { getCorporateAddressValidation } from '@galileo/composables/useCorporateAddress/index'
import { STREET_TYPES } from '@galileo/constants/streetTypes'

export default defineComponent({
  name: 'CACorporateAddressForm',
  components: {
    AppInputText,
    AppInputDropdown,
  },
  emits: ['update', 'isValid'],
  props: {
    address: {
      type: Object as PropType<CorpProfileAddress>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const valueRequired = getValueRequiredValidation()
    const countriesStore = useCountriesStore()

    const states = ref<Array<State>>([])
    const streetTypes = [...new Set(Object.values(STREET_TYPES))].map((item) => {
      return {
        name: item,
      }
    })
    const unit = computed({
      get: () => props.address.fixedFormat?.unit || '',
      set: (newValue) => {
        emit('update', 'fixedFormat', 'unit', newValue)
      },
    })
    const streetNumber = computed({
      get: () => props.address.fixedFormat?.streetNumber || '',
      set: (newValue) => {
        emit('update', 'fixedFormat', 'streetNumber', newValue)
      },
    })
    const streetName = computed({
      get: () => props.address.fixedFormat?.streetName || '',
      set: (newValue) => {
        emit('update', 'fixedFormat', 'streetName', newValue)
      },
    })
    const streetType = computed({
      get: () => props.address.fixedFormat?.streetType || '',
      set: (newValue) => {
        emit('update', 'fixedFormat', 'streetType', newValue)
      },
    })
    const buildingName = computed({
      get: () => props.address.fixedFormat?.buildingName || '',
      set: (newValue) => {
        emit('update', 'fixedFormat', 'buildingName', newValue)
      },
    })
    const place = computed({
      get: () => props.address.fixedFormat?.place || '',
      set: (newValue) => {
        emit('update', 'fixedFormat', 'place', newValue)
      },
    })

    const state = computed({
      get: () => props.address.fixedFormat?.state || '',
      set: (newValue) => {
        emit('update', 'fixedFormat', 'state', newValue)
      },
    })

    const postalCode = computed({
      get: () => props.address.fixedFormat?.postalCode || '',
      set: (newValue) => {
        emit('update', 'fixedFormat', 'postalCode', newValue)
      },
    })
    const formValidation = ref(
      useValidation({
        unit: {
          $value: unit,
          isValid: getCorporateAddressValidation(props.address.country, 'unit'),
        },
        streetNumber: {
          $value: streetNumber,
          valueRequired,
          isValid: getCorporateAddressValidation(props.address.country, 'streetNumber'),
        },
        streetName: {
          $value: streetName,
          valueRequired,
          isValid: getCorporateAddressValidation(props.address.country, 'streetName'),
        },
        streetType: {
          $value: streetType,
          valueRequired,
          isValid: getCorporateAddressValidation(props.address.country, 'streetType'),
        },
        buildingName: {
          $value: buildingName,
          isValid: getCorporateAddressValidation(props.address.country, 'buildingName'),
        },
        place: {
          $value: place,
          valueRequired,
          isValid: getCorporateAddressValidation(props.address.country, 'place'),
        },
        state: {
          $value: state,
          valueRequired,
          isValid: getCorporateAddressValidation(props.address.country, 'state'),
        },
        postalCode: {
          $value: postalCode,
          valueRequired,
          isValid: getCorporateAddressValidation(props.address.country, 'postalCode'),
        },
      })
    )

    onBeforeMount(async () => {
      states.value = await countriesStore.getStatesByCountryCode(props.address.country)
      // Checking the form for invalid fields
      setTimeout(() => {
        formValidation.value.$touch()
      }, 250)
    })

    watch(
      formValidation.value,
      (validation) => {
        emit('isValid', validation.$anyInvalid)
      },
      { deep: true, immediate: true }
    )
    return {
      $t,
      streetNumber,
      unit,
      buildingName,
      streetName,
      place,
      state,
      postalCode,
      formValidation,
      states,
      streetTypes,
      streetType,
    }
  },
})
