import { ref } from '@vue/composition-api'
import { defineStore } from 'pinia'

import { ConsentState } from '@galileo/models/App/interfaces/ConsentState'

export const useConsentStore = defineStore('consent', () => {
  const consentState = ref<boolean | ConsentState | any>(false)

  const setConsent = (consent: boolean | ConsentState) => {
    consentState.value = consent
  }

  return {
    // State
    consentState,
    // Actions
    setConsent,
  }
})
