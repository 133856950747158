import { defineStore } from 'pinia'
import { isAfter, parse, parseISO } from 'date-fns'
import Cookies, { Cookie } from 'universal-cookie'
import { computed, ref } from '@vue/composition-api'
import { setHeader } from '@galileo/api/RMTAPIHandler'
import { Tokens } from '@galileo/models/Session/Tokens'
import forgetDeviceApi from '@galileo/api/launchpad/authorization/forgetDevice/delete'

import {
  useI18nStore,
  useCountriesStore,
  useSessionStore,
  useAuthStore,
  useProfileStore,
  useThemeStore,
} from '@galileo/stores'
import { PRISMIC_BRAND_NAMES } from '@galileo/constants/prismic'

const localeCookieKey = 'NEXT_LOCALE'

export const useDeviceStore = defineStore(
  'device',
  () => {
    const publicSiteCookie = ref<any>()
    const tokens = ref<Array<Tokens>>([])
    const deviceId = ref<string>('')
    const countryId = ref<number>(0)
    const country = ref<string>('US')
    const locale = ref<string>('en-GB')
    const lastTransactionCountryTo = ref<string>('')
    const welcomePageMessage = ref<boolean>(true)
    const newLookModalAlreadyDisplayed = ref<boolean>(false)
    const rememberDevice = ref(false)

    function setCountryId(id: number) {
      setHeader('CountryId', id)
      countryId.value = id
    }

    const getCountryId = computed(() => {
      return countryId.value
    })

    function setCountry(selectedCountry: string) {
      country.value = selectedCountry
    }

    function setRememberDevice(isRemembered: boolean) {
      rememberDevice.value = isRemembered
    }

    const getCountry = computed(() => {
      return country.value
    })

    function setId(id: string) {
      deviceId.value = id
    }

    function getId(): string {
      const sessionStore = useSessionStore()
      const retVal = deviceId ? deviceId.value : sessionStore.sessionDeviceId
      return retVal || ''
    }

    function getDeviceId(): string {
      return deviceId.value || ''
    }

    function setLocale(payload: string) {
      const i18nStore = useI18nStore()

      let supportedLocale = i18nStore.getClosestMatchingLocale(payload, true)

      locale.value = supportedLocale
      if (locale.value) {
        i18nStore.i18n.locale = supportedLocale
        setLocaleCookie(supportedLocale)
        if (locale.value) {
          setHeader('Locale', locale.value)
          setHeader('CultureCode', locale.value)
          document.documentElement.setAttribute('lang', locale.value)
        }
      } else {
        throw new Error('No locale set')
      }
    }

    const getBaseDomainForCookie = (): string => {
      const completeDomain = window.location.hostname

      if (completeDomain === 'localhost') {
        return 'localhost'
      }

      const parts = completeDomain.split('.')
      const lastTwoHostnameParts = parts.slice().slice(-2)
      const baseDomain = lastTwoHostnameParts.join('.')

      return `.${baseDomain}`
    }

    const setLocaleCookie = (value: string): void => {
      const cookies = new Cookies()
      const baseDomain = getBaseDomainForCookie()
      const options = {
        domain: baseDomain,
        path: '/',
        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      }
      const cookieValue = useI18nStore().languageTagLanguage(value)
      cookies.set(localeCookieKey, cookieValue, options)
    }

    const getCookieLocale = (): string => {
      const cookies = new Cookies()
      const rawCookie = cookies.get(localeCookieKey)
      const localeCookie = rawCookie ?? ''
      return localeCookie
    }

    const getProfileLocale = (): string => {
      return useAuthStore().user?.language ?? ''
    }

    const getBrowserLocale = (): string => {
      return navigator.language
    }

    const setInitialLocale = async () => {
      const i18nStore = useI18nStore()
      const cookieLocale = getCookieLocale()
      const profileLocale = getProfileLocale()
      const browserLocale = getBrowserLocale()

      let locale = 'en-GB'

      const isSupportedCookieLocale =
        i18nStore.isSupportedLocale(cookieLocale) ||
        i18nStore.isSupportedLocaleLanguagePart(cookieLocale)


      const isSupportedProfileLocale = i18nStore.isSupportedLocaleLanguagePart(profileLocale)
      const isSupportedBrowserLocale = i18nStore.isSupportedLocale(browserLocale)

      if (cookieLocale && isSupportedCookieLocale) {
        locale = i18nStore.getClosestMatchingLocale(cookieLocale)

        const languageTagLanguage = i18nStore.languageTagLanguage(cookieLocale)

        if (profileLocale && languageTagLanguage !== profileLocale) {
          await useProfileStore().changePreferredLanguage({
            language: languageTagLanguage,
          })
        }
      } else if (profileLocale && isSupportedProfileLocale) {
        locale = i18nStore.getClosestMatchingLocale(profileLocale)
      } else if (browserLocale && isSupportedBrowserLocale) {
        locale = browserLocale
      }

      setLocaleCookie(locale)
      i18nStore.setLocale(locale)

      setHeader('Locale', locale)
      setHeader('CultureCode', locale)
      document.documentElement.setAttribute('lang', locale)
    }

    function fetchLocale(locale: string) {
      const i18nStore = useI18nStore()
      let supportedLocale = i18nStore.getClosestMatchingLocale(locale)


      setLocale(supportedLocale)
      setLocaleCookie(supportedLocale)
      i18nStore.setLocale(supportedLocale)
    }

    const getLocale = computed(() => {
      return locale.value
    })

    const getLocaleLowerCase = computed(() => {
      return locale.value ? locale.value.toLowerCase() : ''
    })

    const getLocaleLanguageLowerCase = computed(() => {
      return useI18nStore().languageTagLanguage(getLocaleLowerCase.value)
    })

    function setWelcomeMessage(value: boolean) {
      welcomePageMessage.value = value
    }

    function getWelcomeMessage(): boolean {
      const authStore = useAuthStore()
      //we dont display the wleocme message if user is registered after given date112

      const themeStore = useThemeStore()

      //#region we check if user did register after Feb 2nd 2023
      const { user } = authStore
      let isAfterTargetDate = true
      const userRegistrationDate = user?.customer?.createdDate

      let targetDate = parse('2002-02-02', 'yyyy-MM-dd', new Date())

      //adding placeholders for other brands
      switch (themeStore.getThemeName) {
        case PRISMIC_BRAND_NAMES.BRITLINE:
          targetDate = parse('2024-03-05', 'yyyy-MM-dd', new Date())
          break;
        case PRISMIC_BRAND_NAMES.JLP:
          targetDate = parse('2025-03-05', 'yyyy-MM-dd', new Date())
          break
      }

      if (userRegistrationDate) {
        const givenDate = parseISO(userRegistrationDate ?? '')

        isAfterTargetDate = isAfter(givenDate, targetDate)
      }
      //#endregion

      const shouldShow = !isAfterTargetDate && welcomePageMessage.value && themeStore.isBranded
      return shouldShow
    }

    function setLastTransaction(countryTo: string) {
      lastTransactionCountryTo.value = countryTo
    }

    const getLastTransaction = computed(() => {
      return lastTransactionCountryTo.value
    })

    function setNewLookModalAlreadyDisplayed(value: boolean) {
      newLookModalAlreadyDisplayed.value = value
    }

    const getNewLookModalAlreadyDisplayed = computed(() => {
      return newLookModalAlreadyDisplayed.value
    })

    //TODO - CHECK - i18n
    function getLocaleWithFallback(): string {
      // TODO since sessionLocale will always be defined, we need to move some logic to the set locale part
      const sessionStore = useSessionStore()
      const langs = useCountriesStore().getAvailableLanguages
      const cookieLocale = publicSiteCookie.value?.i18n?.locale
      const sessionLocale = sessionStore.session?.language
      const localeToReturn =
        locale.value ?? cookieLocale ?? sessionLocale ?? langs[0] ?? navigator.language ?? 'en-GB'
      console.info('Locale fallback to', localeToReturn)
      return localeToReturn
    }

    function getPublicSiteCookie() {
      /*
       * Take a cookie from the public site, and set it to store, once consumed
       * we should remove cookie from store and browser cookies
       * */
      const cookies = new Cookies()
      // Get Cookie
      const cookie = cookies.get('public-private')
      if (cookie) {
        // Store
        storePublicSiteCookie(cookie)
        // Remove
        cookies.remove('public-private')
      }
    }

    function deletePublicSiteCookie() {
      if (publicSiteCookie.value) {
        // Store
        storePublicSiteCookie(null)
      }
    }

    function resetLocale() {
      setLocale('en-GB')
    }

    function setTokens(tokensList: Array<Tokens>) {
      tokens.value = tokensList
    }

    async function forgetDevice(): Promise<boolean> {
      const result = await forgetDeviceApi.exec()
      return result
    }

    async function init() {
      getPublicSiteCookie()
      await setInitialLocale()
      deletePublicSiteCookie()
    }

    function storePublicSiteCookie(cookie: Cookie) {
      publicSiteCookie.value = cookie
    }

    return {
      publicSiteCookie,
      tokens,
      deviceId,
      countryId,
      country,
      locale,
      lastTransactionCountryTo,
      welcomePageMessage,
      newLookModalAlreadyDisplayed,
      storePublicSiteCookie,
      init,
      forgetDevice,
      setTokens,
      resetLocale,
      deletePublicSiteCookie,
      getPublicSiteCookie,
      getLocaleWithFallback,
      getNewLookModalAlreadyDisplayed,
      setNewLookModalAlreadyDisplayed,
      getLastTransaction,
      setLastTransaction,
      getWelcomeMessage,
      setWelcomeMessage,
      getLocaleLanguageLowerCase,
      getLocaleLowerCase,
      getLocale,
      fetchLocale,
      setLocale,
      getId,
      getDeviceId,
      setId,
      getCountry,
      setCountry,
      getCountryId,
      setCountryId,
      setInitialLocale,
      setLocaleCookie,
      getCookieLocale,
      getProfileLocale,
      getBrowserLocale,
      getBaseDomainForCookie,
      rememberDevice,
      setRememberDevice,
    }
  },
  {
    persist: true,
  }
)
