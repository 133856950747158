<template>
  <AppModal v-model="model" title="Information Dialog" class="info-app-modal" @show="onShow">
    <template #header>
      <AppModalHeader>
        <template #default>
          <h1 v-if="title && noIcon">
            {{ title }}
          </h1>
          <div v-if="!noIcon">
            <IconWarningRed v-if="icon === 'warning'" />
            <IconStarship v-if="icon === 'rocket'" />
            <IconHand v-if="icon === 'hand'" />
            <IconWarningYellow v-if="icon === 'warningYellow'" />
            <IconChart v-if="icon === 'chart'" />
            <IconCheckmarkBig v-if="icon === 'checkmark'" />
            <IconCheckmarkCircle v-if="icon === 'checkmarkCircle'" />
            <IconCrossmark v-if="icon === 'crossmark'" />
            <IconQuestionmark v-if="icon === 'questionmark'" />
            <IconHourglass v-if="icon === 'hourglass'" />
            <IconMoneyWings v-if="icon === 'moneyWings'" />
            <IconMailBox v-if="icon === 'mailBox'" />
            <IconInfoBlue v-if="icon === 'info'" />
            <IconInfoCircle v-if="icon === 'infoCircle'" />
            <IconBank v-if="icon === 'bank'" />
            <IconXeGlobe v-if="icon === 'globe'" />
            <IconLock v-if="icon === 'lock'" />
            <IconBlindedAlley v-if="icon === 'blindedAlley'" />
            <IconBubbleCloud v-if="icon === 'cloud'" />
            <IconPhoneRed v-if="icon === 'phoneRed'" />
            <IconStars v-if="icon === 'stars'" />
            <IconRevert v-if="icon === 'revert'" />
            <IconCreditCard v-if="icon === 'creditCard'" />
            <IconEditDocument v-if="icon === 'editDocument'" />
            <IconInfo v-if="icon === 'altInfo'" />
            <IconWarningTriangle v-if="icon === 'warningTriangle'" />
            <IconInfoAlt v-if="icon === 'InfoIconAlt'" />
          </div>
          <slot name="header" />
        </template>
        <template v-if="shouldDisplayCloseButton" #right>
          <XeBackButton
            :disabled="isLoading"
            icon="x"
            :class="{ 'sm:hidden': isCloseButtonMobileOnly }"
            :name="$t('RecipientDetailsModal.CloseTooltipText').value"
            analytics-name="bank-details-modal-close"
            @click="closeModal"
          />
        </template>
        <template v-if="shouldDisplayBackButton" #left>
          <XeBackButton
            :disabled="isLoading"
            icon="<"
            :class="{ 'sm:hidden': isCloseButtonMobileOnly }"
            :name="$t('RecipientDetailsModal.CloseTooltipText').value"
            analytics-name="bank-details-modal-close"
            @click="backButtonkAction"
          />
        </template>
      </AppModalHeader>
    </template>
    <div class="modal-body">
      <slot />
    </div>
    <template #footer>
      <AppModalFooter v-if="shouldDisplayFooter">
        <slot name="footer" class="button-group">
          <AppButton :loading="isLoading" @click="confirm"> {{ buttonTitle }} </AppButton>
        </slot>
      </AppModalFooter>
    </template>
  </AppModal>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import XeBackButton from '@galileo/components/XeBackButton/XeBackButton'
import { useI18nStore } from '@galileo/stores'
import {
  AppModal,
  AppModalFooter,
  AppButton,
  AppModalHeader,
  AppIcon,
  useVModel,
} from '@oen.web.vue2/ui'
import {
  IconWarningRed,
  IconHand,
  IconStarship,
  IconWarningYellow,
  IconChart,
  IconCrossmark,
  IconCheckmarkBig,
  IconQuestionmark,
  IconInfoBlue,
  IconMoneyWings,
  IconMailBox,
  IconBank,
  IconXeGlobe,
  IconLock,
  IconBlindedAlley,
  IconBubbleCloud,
  IconCreditCard,
} from '@oen.web.vue2/icons'

import {
  IconPhoneRed,
  IconStars,
  IconRevert,
  IconEditDocument,
  IconInfo,
  IconCheckmarkCircle,
  IconInfoCircle,
  IconHourglass,
  IconWarningTriangle,
  IconInfoAlt,
} from '@galileo/assets/icons/vue'

export const ICON_TYPE = {
  WARNING: 'warning',
  ROCKET: 'rocket',
  HAND: 'hand',
  WARNING_YELLOW: 'warningYellow',
  CHART: 'chart',
  CHECK_MARK: 'checkmark',
  CHECKMARK_CIRCLE: 'checkmarkCircle',
  INFO_CIRCLE: 'infoCircle',
  CROSS_MARK: 'crossmark',
  QUESTION_MARK: 'questionmark',
  HOURGLASS: 'hourglass',
  MONEY_WINGS: 'moneyWings',
  CLOUD: 'cloud',
  PHONE: 'phoneRed',
  STARS: 'stars',
  REVERT: 'revert',
  CREDIT_CARD: 'creditCard',
  ICON_INFO: 'altInfo',
  ICON_INFO_ALT: 'InfoIconAlt',
}
export default defineComponent({
  components: {
    AppModal,
    AppModalFooter,
    AppButton,
    AppModalHeader,
    AppIcon,
    IconWarningRed,
    IconStarship,
    IconHand,
    IconWarningYellow,
    IconChart,
    IconCrossmark,
    IconCheckmarkBig,
    IconQuestionmark,
    IconMoneyWings,
    IconMailBox,
    XeBackButton,
    IconInfoAlt,
    IconInfoBlue,
    IconBank,
    IconXeGlobe,
    IconLock,
    IconBlindedAlley,
    IconBubbleCloud,
    IconCreditCard,
    IconPhoneRed,
    IconStars,
    IconRevert,
    IconHourglass,
    IconCheckmarkCircle,
    IconEditDocument,
    IconInfo,
    IconInfoCircle,
    IconWarningTriangle,
  },
  emits: ['close'],
  props: {
    icon: {
      type: String,
      default: 'warning',
    },
    title: {
      type: String,
      default: '',
    },
    buttonTitle: {
      type: String,
      default: 'Continue',
    },
    value: {
      type: Boolean,
    },
    isLoading: {
      type: Boolean,
    },
    shouldDisplayCloseButton: {
      type: Boolean,
      default: false,
    },
    shouldDisplayBackButton: {
      type: Boolean,
      default: false,
    },
    isCloseButtonMobileOnly: {
      type: Boolean,
      default: false,
    },
    noIcon: {
      type: Boolean,
      default: false,
    },
    shouldEmitBack: {
      type: Boolean,
      default: false,
    },
    shouldDisplayFooter: {
      type: Boolean,
      default: true,
    },
    onShow: {
      type: Function,
      default: () => {},
      required: false,
    },
  },
  setup(props, { emit }) {
    const { model } = useVModel(props, emit)
    const { $t } = useI18nStore()
    const closeModal = () => {
      emit('close')
    }
    const backButtonkAction = () => {
      if (props.shouldEmitBack) {
        emit('emit-back')
      } else {
        emit('close')
      }
    }
    const confirm = () => {
      emit('confirm')
    }
    return { model, confirm, closeModal, backButtonkAction, $t }
  },
})
</script>
<style scoped>
.info-app-modal {
  scrollbar-gutter: stable both-edges; /* fixes horizontal layout shift when the modal is too long */
}

.title {
  @apply font-medium text-2xl leading-8;
}

::v-deep .modal-body > h1 {
  @apply font-medium text-2xl leading-8 text-primary-text;
}

.modal-body {
  @apply text-center;
}
::v-deep .modal-body > p {
  @apply mt-4 text-gray text-base font-normal;
}

::v-deep .card.card--has-header .card-header {
  @apply pb-8 !important;
}

::v-deep .card-footer {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
}

::v-deep .card-footer > .button {
  @apply w-full mt-6 !important;
}

::v-deep .card-footer > .button.button--primary {
  @apply mt-0 !important;
}
</style>
