import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'
import State from '@galileo/models/State/app'

export default new APIHandler(
  API,
  (country, config) => {
    return {
      ...config,
      method: 'GET',
      url: `resources/countries/${country}/states`,
    }
  },
  (response) => {
    return {
      ...response,
      data: response.data.map((state) => {
        return new State({
          id: state.id,
          name: state.stateName,
          code: state.shortName,
        })
      }),
    }
  }
)
