<script>
import { AppModalHeader } from '@oen.web.vue2/ui'

export default {
  name: 'XeModalHeader',
  extends: AppModalHeader,
}
</script>

<style scoped>
.card-header {
  @apply flex justify-between content-center;
  ::v-deep .card-header-title {
    @apply text-lg leading-6 h-auto;
  }
  @screen sm {
    @apply text-lg leading-6 h-auto;
    @apply px-12 py-12;
  }
}

.card-header-title,
.card-header-left,
.card-header-right {
  button {
    @apply block;
  }
}
</style>
