<template>
  <AppModal v-model="model" :title="$t('EditOccupationModal.Title').value">
    <template #header="{ dismiss }">
      <AppModalHeader>
        <h1>{{ $t('EditOccupationModal.Title').value }}</h1>
        <template #right>
          <XeBackButton analytics-name="change-occupation-close" icon="x" @click="dismiss" />
        </template>
      </AppModalHeader>
    </template>

    <template #default>
      <form id="name-form" @submit.prevent>
        <AppInputDropdown
          v-model="occupation"
          :options="occupations"
          selected-display-field="text"
          selected-value-field="value"
          data-key="id"
          type="text"
          :label="$t('EditOccupationModal.OccupationLabel').value"
        />
      </form>
    </template>

    <template #footer>
      <AppModalFooter>
        <AppButton
          type="submit"
          form="name-form"
          analytics-name="change-occupation-update-button"
          :loading="isSaving"
          @click="updateOccupation"
        >
          {{ $t('EditOccupationModal.UpdateButton').value }}
        </AppButton>
      </AppModalFooter>
    </template>
  </AppModal>
</template>

<script>
import { ref } from '@vue/composition-api'


import XeBackButton from '@galileo/components/XeBackButton/XeBackButton'



import {
  useVModel,
  useAlert,
  AppModal,
  AppModalFooter,
  AppButton,
  AppInputDropdown,
  AppModalHeader,
} from '@oen.web.vue2/ui'


import { useI18nStore, useProfileStore, useResourcesStore, useAppStore} from '@galileo/stores'

export default {
  name: 'EditOccupationModal',
  components: {
    AppModal,
    AppModalFooter,
    AppButton,
    AppInputDropdown,
    XeBackButton,
    AppModalHeader,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    onSave: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { model } = useVModel(props, emit)
    const { $t } = useI18nStore()
    const { add } = useAlert()
    const appStore = useAppStore()
    const profileStore = useProfileStore()
    const resourcesStore = useResourcesStore()

    const occupations = resourcesStore.getOccupations
    const occupation = ref(null)
    if (occupations) {
      const item = occupations.find(
        (occupation) => occupation.text === props.user?.customer?.occupation
      )
      if (item) {
        occupation.value = item.value
      }
    }

    const isSaving = ref(false)

    const updateOccupation = async () => {
      isSaving.value = true
      profileStore.updateOccupation(occupation.value).then((success) => {
        if (success) {
          props.onSave()
          add(`${$t('EditOccupationModal.OccupationUpdated').value}`)
          model.value = false
        } else {
          appStore.messageBoxGenericError()
        }
        isSaving.value = false
      })
    }

    return {
      model,
      $t,
      isSaving,
      occupation,
      occupations,
      updateOccupation,
    }
  },
}
</script>

<style scoped>
.name-note {
  @apply mb-6 text-gray-text;
}

::v-deep .card-content {
  @apply px-12;
  .card-content-block {
    @apply mb-6 text-sm leading-5;
  }
}

::v-deep .card.card--has-footer {
  @screen sm {
    @apply pb-12;
  }
}

.card-footer {
  .button {
    @apply w-full;
  }
}

.button.button--primary {
  &:disabled {
    &:not(.button--loading) {
      @apply text-white;
    }

    background: linear-gradient(
        to right,
        theme('colors.blue.button-disabled'),
        theme('colors.blue.button-disabled')
      ),
      linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
  }
}
</style>
