import { ref } from '@vue/composition-api'
import { defineStore } from 'pinia'

export const useQuickTransferStore = defineStore('quickTransfer', () => {
  const isProcessingTransaction = ref(false)
  const isCurrentTransactionQuickTransfer = ref(false)
  const isValidRecipient = ref(true)
  const shouldShowRateChangedModal = ref(false)

  const showRateChangedModal = () => {
    shouldShowRateChangedModal.value = true
  }

  const hideRateChangedModal = () => {
    shouldShowRateChangedModal.value = false
  }

  return {
    // State
    isProcessingTransaction,
    isCurrentTransactionQuickTransfer,
    isValidRecipient,
    shouldShowRateChangedModal,
    // Getters
    // Actions
    showRateChangedModal,
    hideRateChangedModal,
  }
})
