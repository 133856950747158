import API from '@/api/corporate'
import APIHandler from '@/api/LaunchpadAPIHandler'

export default new APIHandler(
  API,
  ({ promptForMarketingConsent, consentedToMarketing }) => {
    return {
      method: 'PUT',
      url: `marketingpreferenceconsent`,
      data: {
        promptForMarketingConsent,
        consentedToMarketing,
      },
    }
  },
  (response) => {
    return {
      ...response.data,
      data: response.data,
    }
  }
)
