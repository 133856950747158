import axios from 'axios'

const API = axios.create()

export default API

export class ResponseFromCorporateApi {
  constructor(result, data) {
    this.result = result
    this.status = new StatusFromCorporateAPI(data)
  }
}
export class StatusFromCorporateAPI {
  constructor(data) {
    this.code = data.Result.RetVal
    this.message = data.Result.RetMsg
  }
}
