function fromXeAmount(amount) {
  if (!amount) {
    return null
  }

  try {
    const parts = amount.toString().split(' ')
    if (parts.length > 1) {
      amount = parts[1]
    }
    const value = parseFloat(amount.replace(/,/g, ''))
    return value
  } catch (ex) {
    console.error('Error while formatting amount in fromXeAmount', ex)
  }
  return null
}

export { fromXeAmount }