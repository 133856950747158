import API from '@galileo/api/rmt'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  (request) => {
    return {
      method: 'POST',
      url: 'account/bank/verify',
      data: {
        accountId: request.accountId,
        verificationDeposit1: request.deposit1,
        verificationDeposit2: request.deposit2,
      },
    }
  },
  (response) => {
    if (response && response.data) {
      return response.data
    } else {
      throw new Error('Failed to verify bank account')
    }
  },
  (error) => {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors &&
      error.response.data.errors.length > 0
    ) {
      throw error.response.data.errors[0]
    }
  }
)
