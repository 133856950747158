import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  ({ promptForMarketingConsent, email, push, profileId }) => {
    return {
      method: 'PUT',
      url: `profiles/${profileId}/marketingpreferences`,
      data: {
        email,
        push,
        promptForMarketingConsent,
        changedBy: 'launchpad-api@xe.com',
      },
    }
  },
  (response) => {
    return {
      ...response,
    }
  }
)
