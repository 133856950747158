import Field from '@galileo/models/DynamicField/Field'
import CstFieldOption from '@galileo/models/DynamicField/CstFieldOption'
import CstFieldValidation from '@galileo/models/DynamicField/CstFieldValidation'

export default class CstField extends Field {
  constructor(field) {
    super({
      id: field.FieldId,
      validations: field.FieldValidations.map((validation) => new CstFieldValidation(validation)),
      label: field.Label,
      dependsOn: field.DependsOn,
      hasAsyncValues: field.HasAsyncValues,
      options: field.Options.map((option) => new CstFieldOption(option)),
      parent: field.Parent,
      readonly: field.Readonly,
      showOnParentValues: field.ShowOnParentValues,
      type: field.Type,
      value: field.Value,
    })
  }
}
