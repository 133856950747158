import { defineStore } from 'pinia'
import { ref, computed } from '@vue/composition-api'

import getMarketingPreferenceConsent from '@galileo/api/corporate/marketingPreferenceConsent/get'
import putMarketingPreferenceConsent from '@galileo/api/corporate/marketingPreferenceConsent/put'
import getTermsConditions from '@galileo/api/corporate/termsConditions/get'
import putTermsAndConditions from '@galileo/api/corporate/termsConditions/put'
import getClientInfo from '@galileo/api/corporate/client/get'
import getUserDetails from '@galileo/api/corporate/profiles/get'
import postEkycRefresh from '@galileo/api/corporate/kycRefresh/post'
import { MarketingConsent, PrivacyData } from '@galileo/models/Corporate/interfaces/Steps'
import { useAppStore, useAuthStore, useProfileStore } from '@galileo/stores'
import { CorporateUserDetails, CorporateClientInfo, CorpProfileAddress, PendingActionsData, PersonnelItem } from '@galileo/models/Corporate/interfaces/CorpReg'
import { getOneLineAddress, parseCorpProfileAddress } from '@galileo/composables/useAddress/useCorporateAddress'

export const useCorporateStore = defineStore('corporate', () => {
  // State start
  const termsAndConditions = ref<PrivacyData | null>(null)
  const clientInfo = ref<CorporateClientInfo | null>(null)
  const clientUserDetails = ref<CorporateUserDetails | null>(null)
  const corporateClientDataFetched = computed(() => !!clientInfo.value && !!clientUserDetails.value)
  // State end

  // Getters start
  const termsAndConditionsGetter = computed(() => {
    return termsAndConditions.value
  })
  const getBusinessOwners = computed((): string => {
    const clientInfoAuthPersonnel = clientInfo.value?.authorizedPersonnel
    const userDetailsAuthSignatory = `${clientUserDetails.value?.mainContact.firstName} ${clientUserDetails.value?.mainContact.lastName}`

    if (clientInfoAuthPersonnel && clientInfoAuthPersonnel.length > 0) {
      return clientInfoAuthPersonnel.map(
        (item: PersonnelItem) => `${item.firstName} ${item.lastName}`.toLocaleLowerCase()
      ).join(', ')
    }
    return userDetailsAuthSignatory.toLocaleLowerCase()
  })
  // Getters end

  // Actions start
  async function fetchClientInfo(): Promise<CorporateClientInfo> {
    const authStore = useAuthStore()
    const { data } = await getClientInfo.exec(authStore.profileId)
    clientInfo.value = data
    return data
  }

  async function fetchUserDetails(): Promise<CorporateUserDetails> {
    const authStore = useAuthStore()
    const { data } = await getUserDetails.exec(authStore.profileId)
    clientUserDetails.value = data
    return data
  }

  async function fetchMarketingPreferenceConsent(): Promise<MarketingConsent> {
    const profileStore = useProfileStore()
    const { data } = await getMarketingPreferenceConsent.exec()
    profileStore.setMarketingConsent(data)

    return data
  }

  async function submitEkycRefresh(ekycRefreshData: PendingActionsData): Promise<PendingActionsData> {
    const appStore = useAppStore()
    try {
      const { data } = await postEkycRefresh.exec(ekycRefreshData)
      return data
    } catch (ex) {
      appStore.logException('Exception during updating EKYC refresh', ex)
      appStore.messageBoxGenericError()
      throw ex;
    }
  }

  async function getTermsAndConditions(): Promise<PrivacyData> {
    const authStore = useAuthStore()
    const companyCountry = authStore?.userProfile?.country

    const { data } = await getTermsConditions.exec(companyCountry)
    termsAndConditions.value = data

    return data
  }

  async function agreeTermsAndConditions(version: any): Promise<PrivacyData> {
    const { data } = await putTermsAndConditions.exec(version)

    return data
  }

  async function updateMarketingPreferences(consentedToMarketing: boolean): Promise<void> {
    await putMarketingPreferenceConsent.exec({
      promptForMarketingConsent: false,
      consentedToMarketing,
    })
  }

  const getLineAddress = (address: CorpProfileAddress, country: string): string => {
    return getOneLineAddress(
      parseCorpProfileAddress(address) ,
      country,
    ) || ''
  }
  // Actions end

  return {
    clientInfo,
    clientUserDetails,
    corporateClientDataFetched,
    fetchClientInfo,
    fetchUserDetails,
    submitEkycRefresh,
    termsAndConditionsGetter,
    fetchMarketingPreferenceConsent,
    getTermsAndConditions,
    agreeTermsAndConditions,
    updateMarketingPreferences,
    getBusinessOwners,
    getLineAddress,
  }
})
