<template>
  <AppDialog v-model="showPromoDialog" class="consent-dialog">
    <AppDialogHeader class="consent-header">
      <div class="consent-header-empty-div"></div>
      <img
        src="@galileo/assets/images/illustrations/mailbox.svg"
        alt=""
        class="consent-header-img"
      />
    </AppDialogHeader>
    <h1 class="consent-h1">
      {{ $t('EmailConsentModal.ConsentHeaderTitle').value }}
    </h1>
    <p class="consent-p">
      {{ $t('EmailConsentModal.PromoText').value }}
    </p>

    <AppDialogFooter>
      <AppButton
        type="button"
        analytics-name="register-info-promos-yes"
        class="consent-footer-okay-button"
        :loading="isSaving"
        @click="sendConsent"
      >
        {{ $t('EmailConsentModal.ButtonPromoYes').value }}
      </AppButton>
      <AppButton
        theme="text"
        type="button"
        analytics-name="register-info-promos-no"
        :disabled="isSaving"
        :loading="isDismissing"
        class="consent-footer-okay-button"
        @click="dismiss"
      >
        {{ $t('EmailConsentModal.ButtonCloseText').value }}
      </AppButton>
    </AppDialogFooter>
  </AppDialog>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'

import { AppDialog, AppDialogHeader, AppDialogFooter, AppButton } from '@oen.web.vue2/ui'

import { useI18nStore, useAnalyticsStore, useAuthStore, useProfileStore } from '@galileo/stores'

export default {
  name: 'EmailConsentModal',
  components: {
    AppDialog,
    AppDialogHeader,
    AppDialogFooter,
    AppButton,
  },
  setup() {
    const { $t } = useI18nStore()
    const analyticsStore = useAnalyticsStore()
    const authStore = useAuthStore()
    const marketingConsentSwitch = ref(false)
    const profileStore = useProfileStore()
    const { promptForMarketingConsent, ignorePromptForMarketingConsent } = profileStore
    const { isCorporateAccount } = authStore

    const showPromoDialog = computed(() => {
      const user = authStore.userProfile

      if (isCorporateAccount) {
        const showConsentPrompt = promptForMarketingConsent
        return showConsentPrompt?.promptForMarketingConsent
      }

      if (user?.customer) {
        return (
          user.customer.promptForMarketingConsent &&
          !useProfileStore().ignorePromptForMarketingConsent
        )
      }
      return false
    })

    const isSaving = ref(false)

    //reset the marketing consent switch if it has been changed in the same "session"
    watch(showPromoDialog, (val, oldValue) => {
      if (!oldValue && val && marketingConsentSwitch.value) {
        marketingConsentSwitch.value = false
      }
    })

    const sendConsent = async () => {
      isSaving.value = true
      let userEmail = authStore.userProfile.customer.email
      await profileStore.updateMarketingConsent({
        promptForMarketingConsent: false,
        email: true,
        push: false,
      })
      await analyticsStore.track({
        event: SEGMENT_EVENTS.MARKETING_CONSENT_EMAIL_SEND,
        traits: {
          email: userEmail,
        },
      })
      isSaving.value = false
    }

    const isDismissing = ref(false)

    const dismiss = async () => {
      isDismissing.value = true
      await profileStore.updateMarketingConsent({
        promptForMarketingConsent: false,
        email: false,
        push: false,
      })
      isDismissing.value = false
    }

    return {
      $t,
      showPromoDialog,
      sendConsent,
      marketingConsentSwitch,
      isSaving,
      dismiss,
      isDismissing,
      ignorePromptForMarketingConsent,
    }
  },
}
</script>

<style scoped>
.consent-header {
  @apply flex p-0 mt-0;
}
.consent-header-back-button {
  @apply mt-0 w-6;
  max-width: 1.5rem;
}
.consent-header-img {
  @apply m-auto;
}
.consent-header-empty-div {
  @apply p-2;
}
.consent-p {
  @apply text-center text-secondary-text pb-4 text-base;
}
.consent-h1 {
  @apply text-2xl mt-6 mb-4 text-center font-medium;
}
.consent-dialog {
  ::v-deep .card-content {
    @apply px-12 pt-12 pb-8 !important;
  }
  ::v-deep .overlay-content {
    width: 440px;
  }
}
.consent-card-header {
  @apply p-0 !important;
}

.stay-in-touch-h2 {
  @apply text-sm !important;
  @apply font-medium;
}
.stay-in-touch-p {
  @apply text-sm text-secondary-text;
}
.consent-card-text {
  @apply shadow-ria-1;
  ::v-deep .card-content {
    @apply p-0 !important;
  }
  @screen sm {
    ::v-deep .card-content {
      @apply p-6 !important;
    }
  }
  ::v-deep .card-content h2 {
    @apply type-h2;
  }
}
.consent-footer-okay-button {
  @apply mt-6 !important;
}
::v-deep .dialog-footer button {
  @apply w-full !important;
}
</style>
