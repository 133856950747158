import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  (fields, config) => {
    return {
      ...config,
      method: 'POST',
      url: `/CashRecipients`,
      data: {
        fields,
      },
    }
  },
  (response) => {
    if (!response.data) {
      return response
    }
    return {
      ...response,
      data: {
        id: response.data.id,
      },
    }
  }
)
