import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'
import Recipient from '@galileo/models/Recipient/app'

import { MobileWalletRecipientResult } from '@galileo/models/Recipient/SpecificRecipientResult/app.js'

//#region Api Response
/*
XE mobile wallet recipient response
[
  {
    fields: [
      {
        name: 'Id',
        value: '2565473',
      },
      {
        name: 'FirstName',
        value: 'MW',
      },
      {
        name: 'LastNames',
        value: 'Test',
      },
      {
        name: 'AddressState',
        value: 'Baja California',
      },
      {
        name: 'AddressCity',
        value: 'Tijuana',
      },
      {
        name: 'CurrencyCode',
        value: 'MXN',
      },
      {
        name: 'CountryCode',
        value: 'MX',
      },
      {
        name: 'PaymentMethod',
        value: 'MobileWallet',
      },
      {
        name: 'MobileWalletAccountNo',
        value: '7734468618',
      },
      {
        name: 'MobileWalletPrefix',
        value: '0091',
      },
      {
        name: 'MobileWalletLocationId',
        value: '121615311',
      },
      {
        name: 'MobileWalletCorrespondentId',
        value: '18667511',
      },
    ],
  },
]
*/
//#endregion

export default new APIHandler(
  API,
  (config) => {
    return {
      ...config,
      method: 'GET',
      url: `CashRecipients?paymentMethod=MobileWallet`,
    }
  },
  (response) => {
    return {
      ...response,
      data: response.data.map((profile) => {
        const result = new MobileWalletRecipientResult(profile)
        return new Recipient(result)
      }),
    }
  }
)
