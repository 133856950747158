import {
  SEGMENT_PAYMENT_METHOD_TYPES,
  SEGMENT_PAYOUT_METHODS,
} from '@galileo/constants/segmentAnalytics'

import { DeliveryMethod, PaymentMethod } from '@galileo/models/Transaction/app'

export const getSegmentPaymentMethod = (paymentMethod) => {
  switch (paymentMethod) {
    case PaymentMethod.BankAccount.enumName:
      return SEGMENT_PAYMENT_METHOD_TYPES.BANK_ACCOUNT
    case PaymentMethod.CreditCard.enumName:
      return SEGMENT_PAYMENT_METHOD_TYPES.CREDIT
    case PaymentMethod.DebitCard.enumName:
      return SEGMENT_PAYMENT_METHOD_TYPES.DEBIT
    case PaymentMethod.Cash.enumName:
    case PaymentMethod.PayNearMe.enumName:
    case PaymentMethod.StagedRIA.enumName:
      return SEGMENT_PAYMENT_METHOD_TYPES.STAGED
    case PaymentMethod.Interac.enumName:
      return SEGMENT_PAYMENT_METHOD_TYPES.INTERAC
    default:
      return paymentMethod
  }
}

export const getSegmentPayoutMethod = (payoutMethod) => {
  switch (payoutMethod) {
    case DeliveryMethod.BankDeposit.enumName:
      return SEGMENT_PAYOUT_METHODS.BANK_DEPOSIT
    case DeliveryMethod.CashPickup.enumName:
      return SEGMENT_PAYOUT_METHODS.OFFICE_PICKUP
    case DeliveryMethod.HomeDelivery.enumName:
      return SEGMENT_PAYOUT_METHODS.HOME_DELIVERY
    case DeliveryMethod.MobileWallet.enumName:
      return SEGMENT_PAYOUT_METHODS.MOBILE_PAYMENT
    default:
      return payoutMethod
  }
}
