<template>
  <InformationAppModal :value="model" icon="globe" class="country-unsupported-modal">
    <h1 class="title">{{ $t('CountryNotSupportedModal.Title').value }}</h1>
    <p>{{ $t('CountryNotSupportedModal.FirstParagraph').value }}</p>
    <p class="mb-6">{{ $t('CountryNotSupportedModal.SecondParagraph').value }}</p>

    <template #footer>
      <AppButton @click="gotIt">
        {{ $t('CountryNotSupportedModal.GotItButton').value }}
      </AppButton>
    </template>
  </InformationAppModal>
</template>

<script>
import { useI18nStore } from '@galileo/stores'
import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'

import { AppButton, useVModel } from '@oen.web.vue2/ui'

export default {
  name: 'CountryNotSupportedModal',
  components: {
    AppButton,
    InformationAppModal,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)

    const gotIt = () => {
      model.value = false
    }

    return {
      $t,
      model,
      gotIt,
    }
  },
}
</script>

<style scoped>
.country-unsupported-modal {
  ::v-deep .card-header {
    @apply pt-12 pb-8;
  }

  ::v-deep .card-header-title {
    @apply h-auto;
  }

  p {
    @apply text-base font-normal leading-6 mt-4;
  }
}
</style>
