import { fromXeAmount } from '@galileo/utilities/amount-convertor.utility'
import { useI18nStore, usePromotionStore } from '@galileo/stores'

export class CalculateResponse {
  constructor(calculateResponse) {
    if (calculateResponse && calculateResponse.quote) {
      if (
        calculateResponse.quote.quoteStatus === 'Error' ||
        calculateResponse.quote.quoteStatus === 'FailedValidation'
      ) {
        throw {
          ...calculateResponse,
          response: {
            data: calculateResponse,
          },
        }
      }

      const promotionStore = usePromotionStore()

      this.warnings = []
      for (const prop in calculateResponse.quote.warnMessages) {
        let warning = {
          type: prop,
          warning: calculateResponse.quote.warnMessages[prop],
        }
        this.warnings.push(warning)
      }

      const calculations = calculateResponse.quote

      this.expiryTime = calculations.expiryTimeMillis
      this.quoteId = calculations.quoteId

      this.variableRates = {}
      this.splitCurrencies = null

      this.error = null
      if (calculations.errorMessages && calculations.errorMessages.length > 0) {
        this.error = calculations.errorMessages[0]
      }

      this.countryTo = calculations.countryTo
      this.currencyFrom = calculations.sellCcy
      this.currencyTo = calculations.buyCcy
      this.fixedAmountInUsd = calculations.fixedAmountInUsd

      // promotions are saved to the store and not to the form - because we don't get promotion
      // information from API response when updating the quote ('@galileo/api/launchpad/quotes/v2/_quoteId/get'),
      // instead we get that info only when creating the quote ('@galileo/api/launchpad/quotes/v2/post').
      if (calculations.promotions) {
        promotionStore.setPromotions(calculations.promotions)
      }

      this.isOpenPaymentCountry = false
      if (calculations.offeredDeliveryMethods) {
        this.deliveryMethods = calculations.offeredDeliveryMethods.map((method) => {
          if (method.cashOpenPaymentAvailable) this.isOpenPaymentCountry = true

          return {
            value: method.method,
            text: method.method, // will be filled later
            isEnabled: method.unavailableReason ? method.isEnabled : false,
            isAvailable: method.unavailableReason ? method.isEnabled : false,
            unavailableReason: method.unavailableReason,
          }
        })
      }
      if (calculations.individualQuotes) {
        this.availableQuotes = calculations.individualQuotes.map((method) => {
          return {
            paymentMethod: method.settlementMethod,
            deliveryMethod: method.deliveryMethod,
            fixedCcy: calculations.fixedCcy,
            nonFixedCcy:
              calculations.baseCcy === calculations.fixedCcy
                ? calculations.buyCcy
                : calculations.baseCcy,
            amountFrom: fromXeAmount(method.sellAmount),
            amountTo: fromXeAmount(method.buyAmount),
            rate: method.rate,
            fee: fromXeAmount(method.transferFee) + fromXeAmount(method.paymentMethodFee),
            transferFee: fromXeAmount(method.transferFee),
            paymentMethodFee: fromXeAmount(method.paymentMethodFee),
            paymentMethodMarginFee: fromXeAmount(method.paymentMethodMarginFee),
            amountRounded: method.paymentAmountRounded,
            liquidityManager: method.liquidityManager,
            isDefault: method.isDefault,
            isEnabled: !method.unavailableReason ? method.isEnabled : false,
            time: method.leadTime,
            totalAmount: method.totalCostAmount ? fromXeAmount(method.totalCostAmount) : '',
            unavailableReason: method.unavailableReason,
          }
        })
      }
    } else {
      throw new Error('Invalid quote')
    }
  }
}
