import { PreferenceGroup } from "./Interfaces"

export class PreferenceError extends Error {
    constructor(message: string) {
        super(message)
        this.name = 'PreferenceError'
    }
}

export class GroupNotFoundError extends PreferenceError {
    constructor(group: PreferenceGroup) {
        super(`Preference group '${group}' not found`)
        this.name = 'GroupNotFoundError'
    }
}

export class StoreNotReadyError extends PreferenceError {
    constructor() {
        super(`Store has not been initilized propertly. Please call init() first`)
        this.name = 'StoreNotReadyError'
    }
}
