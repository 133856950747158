import { camelCase, lowerCase } from 'lodash-es'
import { helpers } from '@vuelidate/validators'
import { toRef, ref } from '@vue/composition-api'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import differenceInYears from 'date-fns/differenceInYears'
import { parse, isValid } from 'date-fns'
import { useI18nStore } from '@galileo/stores'

import { DynamicFormField } from '@oen.web.vue2/ui'

import useFieldVisibilityHelper from './useFieldVisibilityHelper'

// TODO - Add localization validation error messages
// Suggestion - Add new param for localization prefix
// Update to useI18n
// Update $message to {localizationPrefix}.{fieldId}.Validation.{validatorType}
// Example localization key - 'RegistrationInfoForm.8601.Validation.Required'

export const useFieldValidation = () => {
  //Get this in case you want to use the defaut Validations
  const createFormFieldValidation = (formField) => {
    const validation = getFieldValidation(formField)
    return {
      ...validation,
    }
  }

  //Example overriding
  /*getFieldValidation should be deconstructed and passed 


  {
    ...
    const {getFieldvalidation } = useFieldValidation()

    ...
    const validation = createFormFieldValidation(formField, getFieldValidation)
  }


  const createFormFieldValidation = (formField, getFieldValidation) => {
    const validation = getFieldValidation(formField)
    const otherValidation = getFieldCustomValidation(formField)
    return {
      ...validation,
      ...otherValidation,
    }
  }

  */

  //Get this if you want a custom createFormFieldValidation and pass this object as an argument
  //and use it to get the validation as above
  const getFieldValidation = (field) => {
    const i18nStore = useI18nStore()
    const { label } = field
    const isAppDynamicFormField = field instanceof DynamicFormField
    const validations = isAppDynamicFormField ? field.validation : field.validations

    const isNotEditableOrNotRequired = (val) =>
      !isVisible(field) || !helpers.req(val) || field.disabled

    const isPastDate = (val) => {
      if (isNotEditableOrNotRequired(val)) {
        return true
      }

      //check if value is after date now
      const dateNow = Date.now()
      const date = parse(val, 'yyyy-MM-dd', new Date())
      const minDate = parse('1910-01-01', 'yyyy-MM-dd', new Date())

      return isBefore(date, dateNow) && isAfter(date, minDate)
    }

    const isValidState = (val) => {
      return field.props.options.find((el) => el.value === val)
    }

    const isNotOver120yo = (val) => {
      if (isNotEditableOrNotRequired(val)) {
        return true
      }

      //check if value is at ax 90 years from now
      const dateNow = Date.now()
      const date = parse(val, 'yyyy-MM-dd', new Date())
      if (!isValid(date)) {
        return true
      }
      const age = differenceInYears(dateNow, date)
      return age <= 120
    }

    const is18yo = (val) => {
      if (isNotEditableOrNotRequired(val)) {
        return true
      }

      //check if value is atleast 18 years from now
      const dateNow = Date.now()
      const date = parse(val, 'yyyy-MM-dd', new Date())
      if (!isValid(date)) {
        return true
      }
      const age = differenceInYears(dateNow, date)
      return age >= 18
    }

    // Create validation object
    const validation = {
      $value: toRef(field, 'value'),
    }

    const isPoboxActive = ref(false)

    if (validations) {
      for (const validator of validations) {
        const type = camelCase(validator.type)
        let value = validator.value
        let $validator, $message

        // Lookup validation type and create validator
        if (type === 'required') {
          $validator = (v) => {
            v && (v = v.trim())
            // Short circuit validation if not visible or if the field is disabled
            if (!isVisible(field) || field.disabled) {
              return true
            }
            return helpers.req(v)
          }
          $message = validator.message ?? i18nStore.$t('UseFieldValidation.PleaseEnterValueError') //`Please enter ${lowerCaseLabel}`
        } else if (type === 'minLength') {
          $validator = (v) => {
            if (isNotEditableOrNotRequired(v)) {
              return true
            }
            return v.length >= parseInt(value)
          }
          $message = i18nStore.$t('UseFieldValidation.MinimumLenghtRequiredError', {
            minLenght: value,
          }) //`${label} does not reach min length of ${value}`
        } else if (type === 'maxLength') {
          $validator = (v) => {
            if (isNotEditableOrNotRequired(v)) {
              return true
            }
            return v.length <= parseInt(value)
          }
          $message = i18nStore.$t('UseFieldValidation.MaximumLengthExceededError', {
            maxLength: value,
          }) //`${label} exceeded max length of ${value}`
        } else if (type === 'regex') {
          //we're fixing invalid REGEX for account number
          //TODO warn the backend team
          if (field.id === 'ACCOUNT_NUM') {
            value += '$'
          }

          const result = fixRegexAndGetValidation(field, validator, isPoboxActive)

          $validator = result.$validator
          $message = result.$message
        } else if (type === 'brazilianTaxNumber') {
          const result = fixRegexAndGetValidation(field, validator, isPoboxActive)

          $validator = result.$validator
          $message = validator.isBusinessAccount
            ? i18nStore.$t('UseFieldValidation.BrazilianTaxNumberErrorBusiness')
            : i18nStore.$t('UseFieldValidation.BrazilianTaxNumberErrorIndividual')
        } else if (type === 'relativeMinDate' || type === 'date') {
          $validator = (v) => {
            if (isNotEditableOrNotRequired(v)) {
              return true
            }

            //check if value is after date now
            const dateNow = Date.now()
            const date = parse(v, 'yyyy-MM-dd', new Date())
            return isAfter(date, dateNow)
          }
          $message = i18nStore.$t('UseFieldValidation.FieldIsNotValidError')
        } else if (type === 'pobox') {
          $validator = (v) => {
            const poBoxRegex = new RegExp(
              /(?:(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(box|bin)[-.\s]*)|.*((p |post)[-.\s]*(box|bin)[-.\s]*)))/i
            )
            const isPoBox = poBoxRegex.test(v)
            isPoboxActive.value = isPoBox
            return !isPoBox
          }
          $message = i18nStore.$t('UseFieldValidation.POBoxPermitted')
        } else if (type === 'pastDate') {
          $validator = (v) => isPastDate(v)
          $message = i18nStore.$t('UseFieldValidation.FieldIsNotValidError')
        } else if (type === 'validState') {
          $validator = (v) => isValidState(v)
          $message = i18nStore.$t('UseFieldValidation.FieldIsNotValidError')
        } else if (type === 'dob') {
          $validator = (v) => isNotOver120yo(v)
          $message = i18nStore.$t('UseFieldValidation.MaxAge120Error')

          validation['notOver90'] = {
            $validator,
            $message: validator.message || $message,
          }

          $validator = (v) => {
            if (!isNotOver120yo(v)) {
              return true
            }
            return isPastDate(v)
          }
          $message = i18nStore.$t('UseFieldValidation.FieldIsNotValidError')

          validation['pastDate'] = {
            $validator,
            $message: validator.message || $message,
          }

          $validator = (v) => is18yo(v)
          $message = i18nStore.$t('UseFieldValidation.MinAge18Error') //'You must be at least 18 years old to send money'
        }

        if ($validator) {
          // Add validator to validation
          validation[type] = {
            $validator,
            $message: validator.message || $message,
          }
        }
      }
    }

    /**
     * Return vue composable validation object
     * Example
     * {
     *    $value: toRef(field, 'value'),
     *    required: {
     *      $validator(value) {
     *         return helpers.req(value)
     *      },
     *      $message: 'Required field',
     *    },
     * }
     */

    return validation
  }

  const isVisible = (field) => {
    if (field.treatAsVisible) {
      return true
    }
    if (field instanceof DynamicFormField) {
      return field.isVisible()
    }
    return useFieldVisibilityHelper(field)
  }

  const fixRegexAndGetValidation = (field, validator, isPoboxActive) => {
    const i18nStore = useI18nStore()
    let $validator, $message
    let validationRegex = new RegExp()
    let value = validator.value
    let regexString = value
    let flags = ''

    const isNotEditableOrNotRequired = (val) =>
      !isVisible(field) || !helpers.req(val) || field.disabled

    // Convert from C#-style regex into JS-style regex
    const conversionRegex = /^\(\?([ims])\)(.+)$/

    if (conversionRegex.test(value)) {
      const matches = value.match(conversionRegex)
      regexString = matches[2]
      flags = matches[1]
    }

    // support for unicode characters in cash recipient name validation and bank recipient name fields validation
    if (
      [
        'ACCOUNT_NAME',
        'FIRST_NAME',
        'MIDDLE_NAME',
        'LAST_NAME',
        'FirstName',
        'MiddleName',
        'LastNames',
        'NICKNAME',
      ].includes(field.id)
    ) {
      flags = 'u'
    }

    try {
      validationRegex = new RegExp(regexString, flags)
    } catch (ex) {
      console.warn('Exception using regex')
    }

    $validator = (v) => {
      if (isNotEditableOrNotRequired(v) || isPoboxActive.value) {
        return true
      }
      return validationRegex.test(v)
    }

    $message = i18nStore.$t('UseFieldValidation.FieldIsNotValidError')

    return { $validator, $message }
  }

  return { getFieldValidation, createFormFieldValidation, fixRegexAndGetValidation }
}

export default function (field) {
  const { label } = field
  const lowerCaseLabel = lowerCase(label)
  const isAppDynamicFormField = field instanceof AppDynamicFormField

  const validations = isAppDynamicFormField ? field.validation : field.validations

  const isNotEditableOrNotRequired = (val) => !isVisible(field) || !helpers.req(val)

  // Create validation object
  const validation = {
    $value: toRef(field, 'value'),
  }

  if (validations) {
    for (const validator of validations) {
      const type = camelCase(validator.type)
      let value = validator.value

      let $validator, $message

      // Lookup validation type and create validator
      if (type === 'required') {
        $validator = (v) => {
          // Short circuit validation if not visible
          if (!isVisible(field)) {
            return true
          }
          return helpers.req(v)
        }
        $message = `Please enter ${lowerCaseLabel}`
      } else if (type === 'minLength') {
        $validator = (v) => {
          if (isNotEditableOrNotRequired(v)) {
            return true
          }
          return v.length >= parseInt(value)
        }
        $message = `${label} does not reach min length of ${value}`
      } else if (type === 'maxLength') {
        $validator = (v) => {
          if (isNotEditableOrNotRequired(v)) {
            return true
          }
          return v.length <= parseInt(value)
        }
        $message = `${label} exceeded max length of ${value}`
      } else if (type === 'regex') {
        //we're fixing invalid REGEX for account number
        //TODO warn the backend team
        if (field.id === 'ACCOUNT_NUM') {
          value += '$'
        }
        const validationRegex = new RegExp(value)
        $validator = (v) => {
          if (isNotEditableOrNotRequired(v)) {
            return true
          }
          return validationRegex.test(v)
        }
        //Uniforming the error message
        $message = 'Please double-check the marked fields' //`Please enter a valid ${lowerCaseLabel}`
      } else if (type === 'relativeMinDate') {
        $validator = (v) => {
          if (isNotEditableOrNotRequired(v)) {
            return true
          }

          //check if value is after date now
          const dateNow = Date.now()
          const date = parse(v, 'yyyy-MM-dd', new Date())
          return isAfter(date, dateNow)
        }
      }

      // Add validator to validation
      if ($validator) {
        validation[type] = {
          $validator,
          $message: validator.message || $message,
        }
      }
    }
  }

  /**
   * Return vue composable validation object
   * Example
   * {
   *    $value: toRef(field, 'value'),
   *    required: {
   *      $validator(value) {
   *         return helpers.req(value)
   *      },
   *      $message: 'Required field',
   *    },
   * }
   */
  return validation
}
