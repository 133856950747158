import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'
import BankAccount from '@galileo/models/Payment/BankAccount'
import CardAccount from '@galileo/models/Payment/CardAccount'

export const mapBankAccountFields = (bankAccount, fields) => {
  fields.forEach((field) => {
    if (field.name === 'BankName') {
      bankAccount['bankName'] = field.value
    } else if (field.name === 'AccountName') {
      bankAccount['accountName'] = field.value
    } else if (field.name === 'Nickname') {
      bankAccount['accountAlias'] = field.value
    } else if (field.name === 'AccountNumber') {
      bankAccount['accountNumberUnmasked'] = field.value
    } else if (field.name === 'MaskedAccountNumber') {
      bankAccount['maskedAccountNumber'] = field.value
    } else if (field.name === 'AccountType') {
      bankAccount['accountType'] = field.value
    } else if (field.name === 'RoutingNumber') {
      bankAccount['localRoutingCode'] = field.value
    } else if (field.name === 'Id') {
      bankAccount['paymentId'] = field.value
    }
  })
}

class PaymentAccountsResult {
  constructor(data) {
    this.bankAccounts = data.bankAccounts.map((bank) => {
      mapBankAccountFields(bank, bank.fields)
      return new BankAccount(bank) // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    })
    this.cardAccounts = data.cardAccounts.map((account) => {
      const title = account.cardTypeName.toLowerCase()
      const subTitle = account.isDebit ? 'Debit card' : 'Credit card'

      account.title = title
      account.subTitle = subTitle

      let cardAccount = new CardAccount(account)
      return cardAccount
    })
  }
}

export default new APIHandler(
  API,
  ({ profileId, country, currency }, config) => {
    return {
      ...config,
      method: 'GET',
      url: `cards/V2/accounts/${profileId}?country=${country}`,
    }
  },
  (response) => {
    return new PaymentAccountsResult(response.data)
  }
)
