import { translit } from '../nonlatin-latin-convertor.utility'
import { removeDiacritics } from '../diacritics-convertor.utility'

function sanitizeString(inputString) {
  if (typeof inputString !== 'string') {
    return '' // Or throw an error, depending on your needs
  }
  return inputString
    .replace(/[^\w\s-]/g, ' ')
    .replace(/\s+/g, ' ')
    .trim()
}

function getCorrectedString(inputString) {
  return removeDiacritics(translit(sanitizeString(inputString)))
}

export { sanitizeString, getCorrectedString }
