import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/LaunchpadAPIHandler'
import AddressSearchResult from '@/models/Corporate/AddressSearchResult'

export default new APIHandler(
  API,
  ({ searchTerm, country, searchContext }) => {
    return {
      method: 'GET',
      url: `address?searchTerm=${searchTerm}&country=${country}${
        searchContext ? `&searchContext=${searchContext}` : ''
      }`,
    }
  },
  (response) => {
    return {
      ...response,
      data: response.data.items.map((item) => new AddressSearchResult(item)),
    }
  },
  (error) => {
    let ex = { ...error }
    ex.toString = null
    if (ex.response.data.message === 'PO Boxes are not permitted') {
      throw new Error('PO Boxes are not permitted')
    }
    return null
  }
)
