<template>
  <InformationAppModal
    :icon="ICON_TYPE.WARNING_YELLOW"
    :shouldDisplayCloseButton="true"
    @close="closeModal"
    :value="showDocRequiredModal"
  >
    <h1 class="modal-title">{{ $t('DocumentsRequiredModal.ModalTitle').value }}</h1>
    <p class="modal-text">{{ $t('DocumentsRequiredModal.ModalText').value }}</p>

    <template #footer>
      <AppButton class="upload-button" @click="goToUploadDoc">
        {{ $t('DocumentsRequiredModal.ButtonText').value }}
      </AppButton>
    </template>
  </InformationAppModal>
</template>

<script>
import { useI18n } from 'vue-composable'
import { computed } from '@vue/composition-api'
import InformationAppModal, {
  ICON_TYPE,
} from '@galileo/components/InformationAppModal/InformationAppModal'
import { AppButton, AppLink } from '@oen.web.vue2/ui'
import { useRouter } from '@galileo/composables/useRouter'

import { useAuthStore, useStpStore, useSessionStore } from '@galileo/stores'

export default {
  name: 'DocumentRequiredModal',
  components: {
    InformationAppModal,
    AppButton,
    AppLink,
  },
  setup() {
    const { $t } = useI18n()
    const router = useRouter()
    const stpStore = useStpStore()
    const authStore = useAuthStore()

    const showDocRequiredModal = computed(
      () => authStore.isCorporateAccount && stpStore.shouldShowStpDocumentRequiredModal
    )

    const closeModal = () => {
      useSessionStore().setStpDocumentRequiredAcknowledged(true)
    }

    const goToUploadDoc = () => {
      closeModal()
      router.replace('/upload-documents')
    }

    return {
      $t,
      showDocRequiredModal,
      goToUploadDoc,
      closeModal,
      ICON_TYPE,
    }
  },
}
</script>

<style scoped>
::v-deep .overlay-content .card {
  max-width: 30rem;
}
::v-deep .card-header {
  @apply px-12;
}
::v-deep .card-header .card-header-title {
  @apply h-20 mt-12 mb-8;
}
::v-deep .card-header .card-header-title svg {
  width: 88px;
  height: 88px;
}
::v-deep .card-header .card-header-right {
  @apply self-start;
}
::v-deep .card-content .card-content-block {
  @apply mb-8;
}
::v-deep .card-footer {
  @apply items-center;
}
::v-deep .modal-body {
  .modal-title {
    @apply text-3xl font-semibold;
  }
  .modal-text {
    @apply text-sm font-normal tracking-normal text-center;
    @apply mt-4;
  }
}
.upload-button {
  ::v-deep .button-inner {
    @apply text-sm;
  }
}
</style>
