import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'
import { DeliveryMethod } from '@galileo/models/Transaction/app'
import { TRANSFER, PAYMENT_METHODS } from '@galileo/constants/sendMoneyFlow.const'
import { usePaymentsStore, useSendMoneyStore, useCardAccountStore } from '@galileo/stores'
import { GALILEO_PLATFORM } from '@galileo/constants/platformType'

export default new APIHandler(
  API,
  (
    {
      quoteId,
      profileId,
      filledAdditionalFields,
      country,
      stage,
      sendMoneyForm = null,
      recipientId = null,
      isMinimalRequest = false,
    },
    config
  ) => {
    if (isMinimalRequest) {
      const data = {
        id: null,
        client: {
          profileId: profileId,
          blackbox: null,
        },
        kycInformation: null,
        quote: null,
        recipients: null,
        stage: TRANSFER.STAGES.BASIC,
      }

      return {
        ...config,
        method: 'POST',
        url: 'transfer',
        data,
      }
    }

    let recipients = null
    if (recipientId && !sendMoneyForm.contractId) {
      recipients = [{ recipientId }]
    } else if (recipientId != null) {
      recipients = [{ recipientId, contractId: sendMoneyForm.contractId }]
    } else {
      recipients = [
        {
          contractId: sendMoneyForm.contractId,
        },
      ]
    }

    let transferId = null
    let blackbox = null
    let kycFields = null
    let quote = { quoteId }
    if (sendMoneyForm) {
      transferId = sendMoneyForm.transferId
      blackbox = sendMoneyForm.blackbox

      let settlementCard = sendMoneyForm.cardAuthenticationResult

      if (settlementCard) {
        settlementCard = {
          ...settlementCard,
          buyCurrency: sendMoneyForm.currencyTo,
          recipientId: sendMoneyForm.recipient?.id,
        }
      } else{
        settlementCard = {
          encryptedCvn: sendMoneyForm.cardSecurityCode,
          deviceFingerprint: sendMoneyForm.transferId,
          transactionReference: sendMoneyForm.transferId,
          buyCurrency: sendMoneyForm.currencyTo,
          recipientId: sendMoneyForm.recipient?.id,
        }
      }

      const isDirectDebit = sendMoneyForm.paymentMethod === PAYMENT_METHODS.DIRECT_DEBIT

      // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!
      quote = {
        ...quote,
        amount: sendMoneyForm.amountFrom,
        fixedCcy: sendMoneyForm.currencyFrom,
        isConfirmationQuote: true,
        paymentReference: sendMoneyForm.transferReferenceText,
        settlementCard,
        settlementMethod: sendMoneyForm.paymentMethod,
        settlementMethodId: sendMoneyForm.paymentMethodId,
        transferReason: sendMoneyForm.transferReason,
      }
      if (isDirectDebit) {
        quote.directDebitBankAccountId = sendMoneyForm.paymentMethodId
      }
    }

    if (filledAdditionalFields) {
      kycFields = []
      for (const key in filledAdditionalFields) {
        kycFields.push({
          id: key,
          value: filledAdditionalFields[key],
        })
      }
    }
    let transferType = 'XeRails'
    if (sendMoneyForm.deliveryMethod === DeliveryMethod.CashPickup.enumName) {
      transferType = 'CashPayout'
    } else if (sendMoneyForm.deliveryMethod === DeliveryMethod.MobileWallet.enumName) {
      transferType = 'MobileWallet'
    } else if (
      sendMoneyForm.selectedQuote &&
      sendMoneyForm.selectedQuote.liquidityManager === 'Ria'
    ) {
      transferType = 'RiaRails'
    }

    const data = {
      id: transferId,
      client: {
        profileId,
        country,
        blackbox,
      },
      kycInformation: kycFields,
      quote,
      recipients,
      stage,
      transferType,
      caller: GALILEO_PLATFORM,
      platformType: GALILEO_PLATFORM,
      promotionCodes: sendMoneyForm.promotionCodes,
    }
    // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! TEMPORARY ??????????????
    if (stage !== TRANSFER.STAGES.CONFIRM) {
      // Workaround: if we supply caller FxWeb for the Confirm stage it seems the backend runs into problems finding the quoteId hence we only use that caller for the calls before Confirm stage
      // Before the Confirm stage we need the caller FxWeb for Onfido to supply the referrerUrl
      //data.caller = GALILEO_PLATFORM // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
      data.client.ipAddress = '127.0.0.1' // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! really needed ? i don't think so
      data.client.referrerUrl = window.location.protocol + '//*/*' // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    }

    //#region Cybersource card related info  LEGACY
    const cardAccount = useCardAccountStore()
    const useCybersource = cardAccount.useCybersource
    const sendMoneyStore = useSendMoneyStore()
    if (stage === TRANSFER.STAGES.CONFIRM && sendMoneyStore.isCardPayment && useCybersource) {
      const paymentsStore = usePaymentsStore()
      const paymentMethod = paymentsStore.selectedPaymentMethod

      let cybersourceData = cardAccount.getCybersourceData
      //if new card we should have everything in cyversourceData
      if (cybersourceData) {
        data.providerPayment = cybersourceData
        data.providerPayment.paymentType = sendMoneyForm.paymentMethod
      } else {
        let cardExpiryDate = ''
        if (paymentMethod.expiryMonth) {
          //we get the required information from selected payment method
          cardExpiryDate = `${paymentMethod.expiryMonth.toString().padStart(2, '0')}-${
            paymentMethod.expiryYear
          }`
        }
        data.providerPayment = {
          paymentType: paymentMethod.paymentMethod,
          cardBin: paymentMethod.panFirstDigits,
          cardExpireDate: cardExpiryDate,
          cardLast4Digits: paymentMethod.panLastDigits,
        }
      }
    }
    //#endregion

    return {
      ...config,
      method: 'POST',
      url: 'transfer',
      data,
    }
  },
  async (response) => {
    let data = null
    if (response.data?.data) {
      data = {
        transferId: response.data.data.id,
        fieldDefinitions: response.data.data.kycRequirements,
        errors: response.data.errors,
        verified: response.data.data.verified,
        success: response.data.statusCode === 'OK',
        orderNumber: response.data.data.contractReference,
        message: response.data.message,
      }
    }
    return {
      ...response,
      data: data,
    }
  }
)
