<template>
  <InformationAppModal :value="modelToUse" icon="lock">
    <h1 class="title">{{ $t('VerificationOutcomeModal.Closed.Title').value }}</h1>
    <p>{{ $t('VerificationOutcomeModal.Closed.Text').value }}</p>

    <template #footer>
      <AppButton @click="gotIt">
        {{ $t('VerificationOutcomeModal.Closed.PrimaryButton').value }}
      </AppButton>
      <AppButton theme="secondary" @click="helpCentre">
        {{ $t('VerificationOutcomeModal.Closed.SecondaryButton').value }}
      </AppButton>
    </template>
  </InformationAppModal>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { useRouter } from '@galileo/composables/useRouter'
import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'
import { AppButton, useVModel } from '@oen.web.vue2/ui'

import { useAuthStore, useI18nStore, useAppStore } from '@galileo/stores'

export default {
  name: 'AccountRestrictedModal',
  components: {
    AppButton,
    InformationAppModal,
  },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: undefined,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)
    const appStore = useAppStore()
    const router = useRouter()
    const authStore = useAuthStore()

    const modelToUse = computed({
      get() {
        if (model.value === undefined) {
          return authStore.isAccountRestricted
        } else {
          return model.value
        }
      },
      set(newValue) {
        if (model.value === undefined) {
          authStore.isAccountRestricted = newValue
        } else {
          model.value = newValue
        }
      },
    })
    const loadingHelpDesk = ref(false)

    const gotIt = () => {
      if(appStore.currentRoute.name !== 'Activity') {
        router.push({ name: 'Activity' })
      } else {
        modelToUse.value = false
      }
    }

    const helpCentre = () => {
      appStore.openHelpDesk({ loadingRef: loadingHelpDesk })
    }

    return {
      $t,
      model,
      modelToUse,
      gotIt,
      helpCentre,
    }
  },
}
</script>

<style scoped></style>
