export const TRANSACTION_STATUSES_STEPS = {
  CANCELLED: {
    name: 'Cancelled',
    cashPickupStep: 1,
    mobileWalletStep: 1,
    bankAccountStep: 2,
  },
  PENDING_CANCELLATION: {
    name: 'PendingCancellation',
    cashPickupStep: 1,
    mobileWalletStep: 1,
    bankAccountStep: 2,
  },
  AWAITING_BALANCE: {
    name: 'AwaitingBalance',
    bankAccountStep: 0,
  },
  UNCLEARED_FUNDS_RECEIVED: {
    name: 'UnclearedFundsReceived',
    cashPickupStep: -1,
    mobileWalletStep: 0,
    bankAccountStep: 1,
  },
  UNCLEARED_FUNDS: {
    name: 'UnclearedFunds',
    cashPickupStep: 0,
    mobileWalletStep: 0,
    bankAccountStep: 1,
  },
  BALANCE_RECEIVED: {
    name: 'BalanceReceived',
    cashPickupStep: 2,
    mobileWalletStep: 1,
    bankAccountStep: 2,
  },
  AWAITING_VERIFICATION: {
    name: 'AwaitingVerification',
    cashPickupStep: 2,
    mobileWalletStep: 1,
    bankAccountStep: 2,
  },
  AWAITING_CONFIRMATION: {
    name: 'AwaitingConfirmation',
  },
  AWAITING_FUNDS_INVESTIGATION: {
    name: 'AwaitingFundsInvestigation',
    cashPickupStep: 2,
    mobileWalletStep: 1,
    bankAccountStep: 2,
  },
  FUNDS_UNDER_INVESTIGATION: {
    name: 'FundsUnderInvestigation',
    cashPickupStep: 2,
    mobileWalletStep: 1,
    bankAccountStep: 2,
  },
  PAYMENT_RECEIVED: {
    name: 'PaymentReceived',
    cashPickupStep: 2,
    mobileWalletStep: 1,
    bankAccountStep: 2,
  },
  READY_FOR_RELEASE: {
    name: 'ReadyForRelease',
    cashPickupStep: 2,
    mobileWalletStep: 1,
    bankAccountStep: 2,
  },
  AWAITING_RELEASE: {
    name: 'AwaitingRelease',
    cashPickupStep: 2,
    mobileWalletStep: 2,
    bankAccountStep: 2,
  },
  AWAITING_RECIPIENT_DETAILS: {
    name: 'AwaitingRecipientDetails',
    bankAccountStep: 3,
  },
  HOLD_ON_ACCOUNT: {
    name: 'HoldOnAccount',
    bankAccountStep: 3,
  },
  READY_FOR_PICKUP: {
    name: 'ReadyForPickup',
    cashPickupStep: 3,
  },
  COMPLETED: {
    name: 'Completed',
    cashPickupStep: 4,
    mobileWalletStep: 2,
    bankAccountStep: 4,
  },
  COMPLETE: {
    name: 'Complete',
    cashPickupStep: 4,
    mobileWalletStep: 2,
    bankAccountStep: 4,
  },
}

export const TRANSACTION_ACTIVITY_STATUSES = {
  IN_PROGRESS: {
    name: 'InProgress',
  },
  CHANGE_IN_PROGRESS: {
    name: 'ChangeInProgress',
  },
  ACTION_REQUIRED: {
    name: 'ActionRequired',
  },
  COMPLETED: {
    name: 'Completed',
  },
  PENDING_CANCELLATION: {
    name: 'PendingCancellation',
  },
  CANCELED: {
    name: 'Canceled',
  },
  FUNDS_ADDED: {
    name: 'Funds added',
  },
}

export const TRANSACTION_DETAIL_STATUSES = {
  HOLD_ON_ACCOUNT: 'HoldOnAccount',
  COMPLETED: 'Completed',
  AWAITING_BALANCE: 'AwaitingBalance',
}

export const TRANSACTION_SENDAGIN_ERROR = {
  RECIPIENT_NOT_FOUND: 'RecipientNotFound',
  PAYMENT_METHOD_UNAVAILABLE: 'PaymentMethodUnavailable',
  SEND_AGAIN_CANNOT_BE_PERFORMED: 'SendAgainCannotBePerformed',
}

export const ACTION_REQUIRED = {
  NONE: 'None',
  RECIPIENT_REQUIRED: 'RecipientRequired',
  EDD_OUTSTANDING: 'EddOutstanding',
  AWAITING_BALANCE: 'AwaitingBalance',
}

/*
If you want single steps use this:

const {
  CANCELLED,
  PENDING_CANCELLATION,
  AWAITING_BALANCE,
  UNCLEARED_FUNDS_RECEIVED,
  UNCLEARED_FUNDS,
  BALANCE_RECEIVED,
  AWAITING_VERIFICATION,
  AWAITING_FUNDS_INVESTIGATION,
  FUNDS_UNDER_INVESTIGATION,
  PAYMENT_RECEIVED,
  READY_FOR_RELEASE,
  AWAITING_RELEASE,
  AWAITING_RECIPIENT_DETAILS,
  HOLD_ON_ACCOUNT,
  READY_FOR_PICKUP,
  COMPLETED,
  COMPLETE,
} = TRANSACTION_STATUSES_STEPS

*/
