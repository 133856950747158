import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'

export default new APIHandler(
  API,
  ({ orderNumber, reason }, config) => {
    return {
      ...config,
      method: 'POST',
      url: `/transfer/${orderNumber}/cancellation`,
      data: {
        orderNumber,
        cancelReason: reason,
      },
    }
  },
  (response) => {
    return {
      ...response,
      data: response.data,
    }
  }
)
