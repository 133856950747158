const truncateRecipientName = (str: string, num: number) => {
  if (str.length > num) {
    return str.slice(0, num) + '...'
  } else {
    return str
  }
}

const truncateString = (str: string, length: number) => {
  if (str.length <= length) {
    return str
  }
  return str.slice(0, length)
}

export { truncateRecipientName, truncateString }
