import { ref } from '@vue/composition-api'
import { useRecipientsStore } from '@galileo/stores'
import {
  disallowedFullNameWordsAndCharactersRegex,
  allowedFullNameCharactersRegex,
} from '@galileo/composables/useRecipientHelpers'

export default function () {
  const recipientsStore = useRecipientsStore()
  const showMissingInformationModal = ref(false)
  const showEditMissingRecipientInformation = ref(false)
  const recipientValidationErrors = ref([])

  const isRecipientValid = async (recipient) => {
    // if the recipient is cash pickup, no validation needed
    if (!recipient.isBankAccount) {
      return true
    }

    let forceEditMissingRecipientInformation = false
    // we are validating if the recipient is eligible, have all the required information
    try {
      let isRecipientValid = await recipientsStore.validateRecipient(recipient.id)
      let shouldThrowError = false
      if (Array.isArray(isRecipientValid)) {
        recipientValidationErrors.value = isRecipientValid
        shouldThrowError = true
      }

      if (recipient.isOwnAccount === undefined || recipient.isBusinessAccount === undefined) {
        recipientValidationErrors.value.push('IS_BUSINESS')
        shouldThrowError = true
      }

      // The validation endpoint doesn't check if recipient's name is valid
      // so we're checking it on the frontend
      if (recipient.accountName) {
        const containsFirstAndLastName = recipient.accountName.trim().split(/\s+/).length >= 2
        const containsAtLeastTwoCharsInNames = recipient.accountName
          .trim()
          .split(/\s+/)
          .every((word) => word.length >= 2)

        if (
          (disallowedFullNameWordsAndCharactersRegex.test(recipient.accountName) ||
            !allowedFullNameCharactersRegex.test(recipient.accountName) ||
            !containsFirstAndLastName ||
            !containsAtLeastTwoCharsInNames) &&
          !recipient.isBusinessAccount
        ) {
          recipientValidationErrors.value.push('fullNameError')
          shouldThrowError = true
        }
      }

      if (recipient.nameVerified !== undefined && !recipient.nameVerified) {
        recipientValidationErrors.value.push('nameNotVerified')
        forceEditMissingRecipientInformation = true
      }

      if (shouldThrowError) {
        throw new Error(`Recipient information is missing or invalid`)
      }
    } catch (e) {
      showMissingInformationModal.value = true
      return false
    }

    if (forceEditMissingRecipientInformation) {
      showEditMissingRecipientInformation.value = true
    }
    //finish with no missing information
    //return recipient is valid
    return true
  }

  const editRecipientWithMissingInformation = () => {
    showMissingInformationModal.value = false
    showEditMissingRecipientInformation.value = true
  }

  const resetEditMissingInformation = () => {
    showEditMissingRecipientInformation.value = false
    recipientValidationErrors.value = []
  }

  const closeMissingInformationModal = () => {
    showMissingInformationModal.value = false
  }

  return {
    showMissingInformationModal,
    showEditMissingRecipientInformation,
    recipientValidationErrors,
    isRecipientValid,
    editRecipientWithMissingInformation,
    resetEditMissingInformation,
    closeMissingInformationModal,
  }
}
