import {
  AppInputPhone,
  AppInputText,
  AppInputDropdown,
  AppInputCountry,
  AppInputMask,
} from '@oen.web.vue2/ui'

export default function (type) {
  const components = {
    phoneNumber: AppInputPhone,
    searchableSelect: AppInputDropdown,
    optionList: AppInputDropdown,
    country: AppInputCountry,
    nationality: AppInputDropdown,
    inputMask: AppInputMask,
    default: AppInputText,
    date: () => import('@galileo/components/RiaInputDate/RiaInputDate'),
    city: () => import('@galileo/components/XeInputCity/XeInputCity'),
    inputMaskWithTooltip: () =>
      import('@galileo/components/XeInputMaskWithTooltip/XeInputMaskWithTooltip'),
  }
  return components[type] || components.default
}
