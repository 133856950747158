import { every } from 'lodash-es'
import { computed, ref } from '@vue/composition-api'
import { defineStore } from 'pinia'
import { Traits, SegmentEvent, IdentifyData } from '@galileo/models/Analytics/interfaces'
import { TRANSFER_TYPE, TRANSFER_TYPE_NAME } from '@galileo/constants/sendMoneyFlow.const'
import router from '@galileo/router'

import LogRocket from 'logrocket'
import LogRocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer'
import TrackConsentAllIntegrations from '@galileo/constants/trackConsentIntegrations/all.const'
import TrackConsentEssentialIntegrations from '@galileo/constants/trackConsentIntegrations/essential.const'
import { timeInSeconds } from '@galileo/composables/useTimeHelpers'

import Cookies from 'universal-cookie'
import { differenceInCalendarYears, parseISO } from 'date-fns'
import { setHeader } from '@galileo/api/RMTAPIHandler'
import { GALILEO_PLATFORM } from '@galileo/constants/platformType'

import { Customer } from '@galileo/models/Auth/Interfaces/AuthFields'

import { useSessionStore, useEnvStore, useAuthStore, useI18nStore } from '@galileo/stores'
import { PRISMIC_BRAND_NAMES } from '@galileo/constants/prismic'

const cookies: Cookies = new Cookies()

let analyticsResolve: any = null // TODO: figure out the correct type here
const analyticsPromise = new Promise((resolve) => {
  analyticsResolve = resolve
})

let trackingConsentResolve: any = null // TODO: figure out the correct type here

export const trackingConsentPromise = new Promise((resolve) => {
  trackingConsentResolve = resolve
})

export const useAnalyticsStore = defineStore('analytics', () => {
  // State
  const paymentLocation = ref<string>('')
  const trackingCookieKey = ref<string>('xeConsentState')
  const trackingConsent = ref<any>(null)
  const identifyData = ref<IdentifyData>()
  const amplitudeSessionId = ref<number>()
  const emit = ref<Function>(() => { })
  const logRocketSessionUrl = ref<string>('')

  // Getters
  const getTrackingConsentedToAll = computed<boolean>(() => {
    return every(trackingConsent.value)
  })

  function getSegmentEventCurrentLocation(): string {
    switch (router.currentRoute.name) {
      case TRANSFER_TYPE.ONE_STEP_TRANSFER:
      case TRANSFER_TYPE.QUICK_TRANSFER:
        return TRANSFER_TYPE_NAME.QUICK_TRANSFER
      case TRANSFER_TYPE.CONVERT_BALANCE:
        return TRANSFER_TYPE_NAME.CONVERT_BALANCE
      case TRANSFER_TYPE.FUND_BALANCE:
        return TRANSFER_TYPE_NAME.FUND_BALANCE
      default:
        return TRANSFER_TYPE_NAME.SEND_MONEY_FLOW
    }
  }
  // Actions

  async function init(e: VoidFunction): Promise<void> {
    if (e) {
      emit.value = e
    }
    await checkTrackingConsent()
    if (trackingConsent.value?.performance) {
      initLogRocket()
    }
    if (trackingConsent.value?.compliance) {
      initSegment()
    }
  }

  async function checkTrackingConsent(): Promise<void> {
    getTrackingConsentCookie()
    if (trackingConsent.value === null) {
      await trackingConsentPromise
    }
  }

  function setTrackingConsentCookie({ compliance }: { compliance: boolean }): void {
    const data = {
      analytics: true, // Always TRUE, even when user accepts ONLY essential cookies
      compliance: compliance, // TRUE if user accepts ALL cookies
    }
    const options = {
      domain: '',
      path: '/',
      secure: true,
      maxAge: timeInSeconds(0, 0, 365), // Set to expire in one year
    }
    cookies.set(trackingCookieKey.value, data, options)

    //set newly created cookie to store
    getTrackingConsentCookie()
  }

  function getTrackingConsentCookie(): void {
    const cookie = cookies.get(trackingCookieKey.value)
    trackingConsent.value = cookie
    if (trackingConsent.value) {
      trackingConsentResolve()
    }
  }

  async function trackUser(): Promise<void> {
    const i18nStore = useI18nStore()
    await trackingConsentPromise
    const data = { ...useAuthStore().userProfile }
    if (!data.customer) {
      data.customer = {} as Customer
    }
    if (!data.mobilePhone) {
      data.mobilePhone = {}
    }
    const anonymousId = await getAnonymousId()
    //const transactions = rootGetters['activity/getLastTransactions']
    const identData: IdentifyData = {
      TBU: data.customer.trmBusinessUnitId,
      AccountType: data.customer.accountType,
      accountStatus: data.isDisabled ? 'disable' : 'active', // TODO do we really get disabled accounts ?
      age: data.customer.dateOfBirth
        ? differenceInCalendarYears(new Date(), parseISO(data.customer.dateOfBirth))
        : '',
      anonymousId: anonymousId,
      city: data.city,
      clientNumber: data.customer.clientNumber,
      country: data.country,
      dateOfBirth: data.customer.dateOfBirth,
      email: data.customer.email,
      firstName: data.firstName,
      middleName: data.customer.middleName,
      lastName: data.lastName,
      locale: data.language,
      emailMarketingAllowed: data.willReceiveEmailMarketing,
      phoneCountry: data.mobilePhone.countryCode,
      phoneNumber: data.mobilePhone.number,
      product: GALILEO_PLATFORM,
      region: data.customer.region,
      userId: useAuthStore().userProfile.customer.id,
      userLanguage: i18nStore.i18n.locale,
      userLanguageCode: i18nStore.languageTagLanguage(i18nStore.i18n.locale),
      userLanguageCountryCode: i18nStore.languageTagCountry(i18nStore.i18n.locale),
      hasMoneyTransferProfile: data.firstName && data.lastName ? true : false,
      firstTransactionDate: '', // TODO ? also empty in mobile app
      lastTransactionDate: '', // TODO ? also empty in mobile app
      transactionCount: '', // TODO ? also empty in mobile app
      registrationDate: '', // TODO ? also empty in mobile app
      registrationPlatform: '', // TODO ? also empty in mobile app
      fxWebCorpMigrated: data.fxWebCorpMigrated,
    }
    if (JSON.stringify(identData) !== JSON.stringify(identifyData.value)) {
      await identify({
        userId: useAuthStore().userProfile.customer.id,
        traits: {
          ...identData,
          /*
          firstTransactionDate:
            transactions && transactions.length > 0
              ? transactions[transactions.length - 1].dateCreated
              : '',
          lastTransactionDate:
            transactions && transactions.length > 0 ? transactions[0].dateCreated : '',
          transactionCount: transactions ? transactions.length : 0,
          */
        },
      })
      identifyData.value = identData
    }
  }

  function setAnalyticsReady(): void {
    analyticsResolve()
  }

  function setConsentReady(): void {
    trackingConsentResolve()
  }

  async function setTrackingConsentAll(): Promise<void> {
    // Once trackConsent is accepted, create cookie to a llow tracking on refresh, and future sessions
    setTrackingConsentCookie({ compliance: true })
    trackingConsentResolve()
  }

  function setTrackingConsentEssential(): void {
    // Once trackConsent is accepted, create cookie to allow tracking on refresh, and future sessions
    setTrackingConsentCookie({ compliance: false })
    trackingConsentResolve()
  }

  function initSegment(): void {
    const envStore = useEnvStore()
    const TOKEN = envStore.env.VUE_APP_SEGMENT_ACCESS_TOKEN
    // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/ajs-classic/#load-options
    const integrations = getTrackingConsentedToAll.value
      ? TrackConsentAllIntegrations
      : TrackConsentEssentialIntegrations

    // Segment
    // TODO copied from Segment like recommended eventually replace by our own loadScript ?
    function segmentAnalytics() {
      let analytics: any = (window.analytics = window.analytics || [])
      if (!analytics.initialize) {
        if (analytics.invoked) {
          window.console && console.error && console.error('Segment snippet included twice.')
        } else {
          analytics.invoked = !0
          analytics.methods = [
            'trackSubmit',
            'trackClick',
            'trackLink',
            'trackForm',
            'pageview',
            'identify',
            'reset',
            'group',
            'track',
            'ready',
            'alias',
            'debug',
            'page',
            'once',
            'off',
            'on',
            'addSourceMiddleware',
            'addIntegrationMiddleware',
            'setAnonymousId',
            'addDestinationMiddleware',
          ]
          analytics.factory = function (e: any) {
            return function () {
              let t = Array.prototype.slice.call(arguments)
              t.unshift(e)
              analytics.push(t)
              return analytics
            }
          }

          for (const method of analytics.methods) {
            analytics[method] = analytics.factory(method)
          }


          analytics.load = function (key: any, e: any) {
            let t = document.createElement('script')
            t.type = 'text/javascript'
            t.async = !0
            t.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js'
            let n: any = document.getElementsByTagName('script')[0]
            n.parentNode.insertBefore(t, n)
            analytics._loadOptions = e
          }
          analytics.SNIPPET_VERSION = '4.13.1'
          analytics.load(TOKEN, integrations)
          analyticsResolve()
        }
      }
    }
    segmentAnalytics()
  }

  function initLogRocket() {
    const envStore = useEnvStore()
    let settings
    if (process.env.NODE_ENV === 'development') {
      settings = {
        release: envStore.env.VUE_APP_VERSION + '-develop',
        dom: {
          isEnabled: true, //disable|enable all DOM recording
          inputSanitizer: false, // obfuscate all user-input elements <select> and <input>
        },
      }
    } else {
      const privateFieldNames = [
        'password',
        'currentpassword',
        'newpassword',
        'confirmpassword',
        'cardnumber',
        'cvv',
        'cvn',
        'cardsecuritycode',
        'accountexpirationmonth',
        'accountexpirationyear',
        'accountnumber',
        'securityquestionanswer1',
        'securityquestionanswer2',
        'securityquestionanswer3',
        'answer',
        'securityquestions',
        'fingerprint',
        'access-token',
        'access_token',
        'accesstoken',
        'refreshtoken',
        'refresh_token',
        'devicetoken',
        'device_token',
        'devicesimcard',
        'username',
        'firstName',
        'middleName',
        'lastName',
        'lastName1',
        'lastName2',
        'phoneNumber',
        'phonenumber',
        'phonenumbervalue',
        'mobilephone',
        'otp',
        'providedfields',
        'firstname',
        'lastname',
        'address',
        'unitNumber',
        'city',
        'state',
        'address',
        'postalCode',
        'securityQuestions',
        'areaCode',
        'number',
        'phoneNumberNoAreaCode',
        'phoneNumberFormatted',
        'areaCode',
        'pin',
        'bankAccountNumber',
        'bankAccountNumberUnitary',
        'bankBicSwift',
        'telephoneNumber',
        'telephoneNumberCell',
        'idDocumentNumber',
        'mobileWalletAccountNo',
        'billingAddress',
        'billingCity',
        'billingState',
        'billingPostalCode',
        'zipcode',
        'jwtToken',
        'BinNumber',
        'Authorization',
        'VerifiedDevice',
        'Code',
        'bearer',
      ]
      const { requestSanitizer, responseSanitizer } = LogRocketFuzzySanitizer.setup([
        ...privateFieldNames,
      ])
      const sanitizeHeader = (network: any) => {
        if (network.headers) {
          for (const key in network.headers) {
            const header = key.toLowerCase()
            if (header === 'authorization' || header === 'bearer') {
              network.headers[key] = '*'
            }
          }
        }
      }

      const sanitizeBodyUrls = ['.onfido.com']
      const sanitizeBody = (network: any) => {
        if (sanitizeBodyUrls.some((url) => network.url.indexOf(url) !== -1)) {
          if (network.method !== 'GET') {
            network.body = null
          }
        }
      }

      settings = {
        release: envStore.env.VUE_APP_VERSION,
        network: {
          requestSanitizer: (request: any) => {
            sanitizeHeader(request)
            sanitizeBody(request)
            return requestSanitizer(request)
          },
          responseSanitizer: (response: any) => {
            sanitizeHeader(response)
            sanitizeBody(response)
            return responseSanitizer(response)
          },
        },
        dom: {
          isEnabled: true, //disable|enable all DOM recording
          inputSanitizer: true, // obfuscate all user-input elements <select> and <input>
        },
      }
    }

    // Log Rocket
    LogRocket.init(envStore.env.VUE_APP_KEYS_LOGROCKET_KEY, settings)
    LogRocket.getSessionURL((sessionUrl: string) => {
      track({
        event: 'LogRocket',
        traits: {
          sessionURL: sessionUrl,
        },
      })
      logRocketSessionUrl.value = sessionUrl
    })
  }

  function getAmplitudeSessionId(event: SegmentEvent): void {

    const { session_id } = event?.event?.integrations['Actions Amplitude']

    if (!amplitudeSessionId.value) {
      setHeader('AmplitudeSessionID', session_id)
      amplitudeSessionId.value = session_id
    }
  }

  async function page(pageName: string): Promise<void> {
    await analyticsPromise
    await trackingConsentPromise
    emit.value(`page: ${pageName}`, pageName)
    let traits = {}
    const brand = useSessionStore().brand
    if (brand && brand !== '') {
      traits = { ...traits, brand: brand }
    } else {
      traits = { ...traits, brand: PRISMIC_BRAND_NAMES.XE }
    }

    window.analytics?.page(pageName, traits, (event: SegmentEvent) => getAmplitudeSessionId(event))
  }

  async function identify({ userId, traits }: { userId: string; traits: Traits }) {
    await analyticsPromise
    await trackingConsentPromise
    emit.value(`identify: ${userId}`, traits, { userId, traits })


    window.analytics?.identify(userId, traits, (event: SegmentEvent) =>
      getAmplitudeSessionId(event)
    )
  }

  async function track({
    event,
    traits = null,
  }: {
    event: string
    traits?: Traits | any
  }): Promise<void> {
    await analyticsPromise
    await trackingConsentPromise
    const tempTraits = { ...traits }
    if (!tempTraits.accountType && identifyData.value?.AccountType) {
      tempTraits.accountType = identifyData.value.AccountType
    }
    if (!tempTraits.email && identifyData.value?.email) {
      tempTraits.email = identifyData.value.email
    }
    if (!tempTraits.logRocketSessionUrl || !tempTraits.sessionURL) {
      tempTraits.logRocketSessionUrl = logRocketSessionUrl.value
    }
    if (!tempTraits.brand) {
      const brand = useSessionStore().brand
      if (brand && brand !== '') {
        tempTraits.brand = brand
      }
    }
    emit.value(`track: ${event}`, tempTraits, { event, tempTraits })


    window.analytics?.track(event, tempTraits, (event: SegmentEvent) =>
      getAmplitudeSessionId(event)
    )
  }

  async function getAnonymousId() {
    // TODO: which type is this returning? Probably string
    await analyticsPromise
    await trackingConsentPromise
    return window.analytics?.user()?.anonymousId()
  }

  function setPaymentLocation(location: string): void {
    paymentLocation.value = location
  }

  async function gtmTrack({
    event,
    variables,
  }: {
    event: string
    variables?: object
  }): Promise<void> {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ event: event, ...variables })
  }

  async function gtmTrackVariables({ variables }: { variables: any }): Promise<void> {
    window.dataLayer = window.dataLayer || []

    for (let prop in variables) {
      // skip loop if the property is from prototype
      if (!variables.hasOwnProperty(prop)) continue
      window.dataLayer.push({ [prop]: variables[prop] })
    }
  }

  async function gtmInitialize(): Promise<void> {
    //const gtmKey =  rootState.env.VUE_APP_KEYS_GTM;
    const gtmKey = 'GTM-WNCL796'

    const gtm = document.querySelector(
      `script[src="https://www.googletagmanager.com/gtm.js?id=${gtmKey}"]`
    )
    // GTM snippet
    // More info: https://developers.google.com/tag-platform/tag-manager/web
    const gtmInit = (gtmKey: string) => {
      ; (function (w: any, d: any, s: any, l: any, i: any) {
        w[l] = w[l] || []
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
        let f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : ''
        j.async = true
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
        f.parentNode.insertBefore(j, f)
      })(window, document, 'script', 'dataLayer', gtmKey)
    }
    if (!gtm) {
      gtmInit(gtmKey)
    }
  }

  async function gtmDeInitialize(): Promise<void> {
    //const gtmKey =  rootState.env.VUE_APP_KEYS_GTM;
    const gtmKey: string = 'GTM-WNCL796'
    const gtm = document.querySelector(
      `script[src="https://www.googletagmanager.com/gtm.js?id=${gtmKey}"]`
    )
    if (gtm?.parentElement) {
      gtm.parentElement.removeChild(gtm)
    }
  }

  return {
    paymentLocation,
    trackingCookieKey,
    trackingConsent,
    identifyData,
    amplitudeSessionId,
    emit,
    logRocketSessionUrl,
    getTrackingConsentedToAll,
    getSegmentEventCurrentLocation,
    init,
    checkTrackingConsent,
    setTrackingConsentCookie,
    getTrackingConsentCookie,
    trackUser,
    setAnalyticsReady,
    setConsentReady,
    setTrackingConsentAll,
    setTrackingConsentEssential,
    initSegment,
    initLogRocket,
    getAmplitudeSessionId,
    page,
    identify,
    track,
    getAnonymousId,
    setPaymentLocation,
    gtmTrack,
    gtmTrackVariables,
    gtmInitialize,
    gtmDeInitialize,
  }
})
