import { flow, each, sortBy, map } from 'lodash-es'
import { computed, ref } from '@vue/composition-api'
import { defineStore } from 'pinia'
import State from '@galileo/models/State/app'
import getCountryList from '@galileo/api/launchpad/destinations/countries/get'
import getStatesList from '@galileo/api/launchpad/destinations/countries/_countryIso/states/get'
import US from '@galileo/regions/US'
import countryConfigs from '@galileo/constants/countriesConfig'
import languages from '@galileo/constants/languagesConfig'
import { getAllInfoByCountry } from '@galileo/composables/useIsoCountryCurrencyLibrary'

import { useI18nStore, useAuthStore, useDeviceStore, useResourcesStore } from '@galileo/stores'

import { GenericField, PhoneCountry } from '@galileo/models/Resources/interfaces/SystemFields'
import Country from '@galileo/models/Country/app'

export const useCountriesStore = defineStore('countries', () => {
  const statesByCountryCode = ref<{ [key: string]: Array<State> }>({})
  const offeredCountries = ref<Array<GenericField>>([])
  const countrySettings = ref<Array<GenericField>>([])
  const registrationCountryIds = ref<Array<GenericField>>([])
  let countryListResult = ref<Array<Country>>([])
  const phoneCountries = ref<Array<PhoneCountry>>([])

  const authStore = useAuthStore()

  const getCountries = computed(() => {
    const resourcesStore = useResourcesStore()
    return resourcesStore.getCountries
  })

  async function fetchOfferedCountries(): Promise<Array<GenericField>> {
    const selectedCountry = useDeviceStore().country
    const result = await getCountryList.exec(selectedCountry)

    if (result.data) {
      const countryNames: { [key: string]: string } = {}
      const resourcesStore = useResourcesStore()
      countryListResult.value = resourcesStore.getCountries
      // TODO what when not available yet ? !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
      if (countryListResult.value && result.data) {
        countryListResult.value.forEach((country) => {
          countryNames[country.value] = country.name
        })
        each(result.data, (value: string) => {
          value = countryNames[value]
        })
      }
      offeredCountries.value = result.data
      return offeredCountries.value
    } else {
      throw new Error(`No data returned from API`)
    }
  }

  function setOfferedCountries(countries: Array<GenericField>) {
    offeredCountries.value = countries
  }

  const getOfferedCountries = computed(() => {
    return offeredCountries.value
  })

  async function getStatesByCountryCode(countryCode: string): Promise<Array<State>> {
    const match = statesByCountryCode.value[countryCode]
    if (match) {
      return match
    } else {
      const { data } = await getStatesList.exec(countryCode)

      if (data) {
        statesByCountryCode.value[countryCode] = data
      }
      return statesByCountryCode.value[countryCode]
    }
  }

  const getCountryByIsoCode = computed(() => (isoCode: string) => {
    const resourcesStore = useResourcesStore()
    countryListResult.value = resourcesStore.getCountries
    if (countryListResult.value) {
      return countryListResult.value.find((country) => country.value === isoCode)
    }
    return null
  })

  async function getCountryStateById(countryCode: string, stateId: number): Promise<State> {
    const states = await getStatesByCountryCode(countryCode)
    if (states) {
      const countryState = states.find(({ id }: { id: number }) => id === stateId)
      if (countryState) {
        return countryState
      } else {
        throw new Error(`State with id ${stateId} not found in country ${countryCode}`)
      }
    } else {
      throw new Error(`States not available for country ${countryCode}`)
    }
  }

  const getOfferedCountryByIsoCode = computed(() => (isoCode: string) => {
    const resourcesStore = useResourcesStore()
    countryListResult.value = resourcesStore.getCountries

    const country = countryListResult.value.find((country) => country.value === isoCode)
    if (country) {
      return {
        ...country,
        name: country.name,
      }
    }
  })

  const getCountryConfigById = (id: number) => {
    const country = countryConfigs.find((country) => country.regionId === id)
    if (country) {
      return country
    }
    return US
  }

  const getCountryDefaultCurrency = computed(() => {
    const user = authStore.user
    if (user && user.customer && user?.customer?.country) {
      return getAllInfoByCountry(user?.customer?.country).currency
    }
    return 'USD'
  })

  const getAvailableLanguages = computed(() => {
    const countryId = useDeviceStore().countryId
    const countryConfig = getCountryConfigById(countryId)
    const availableLanguageCultures = countryConfig.availableLanguageCultures
    return availableLanguageCultures
  })

  const getAvailableLanguagesWithLabels = computed(() => {
    return getAvailableLanguages.value.map((languageTag) => {
      const languageTagLanguage = useI18nStore().languageTagLanguage(languageTag)
      return {
        label: languages[languageTagLanguage],
        value: languageTag,
      }
    })
  })

  const getShortAvailableLanguagesWithLabel = computed(() => {
    const availableLanguages = getAvailableLanguages.value
    return availableLanguages.map((languageTag: string) => {
      const languageTagLanguage = useI18nStore().languageTagLanguage(languageTag)
      return {
        label: languages[languageTagLanguage],
        value: languageTag.split('-')[0],
      }
    })
  })

  const offeredCountriesDialingCodesOptions = computed(() => {
    phoneCountries.value = useResourcesStore().getPhoneCountries
    return flow(
      // Sort them by countryCode
      (countries: Array<PhoneCountry>) => sortBy(countries, 'countryCode'),
      // Map to text value pair
      (countries: Array<PhoneCountry>) =>
        map(countries, (country: PhoneCountry) => {
          const { nameAbbr, text, value, valueKey } = country
          return {
            text: text,
            value: value.replace('+', ''),
            nameAbbr,
            valueKey,
          }
        })
    )(phoneCountries.value)
  })

  const getAllOfferedCountries = computed(() => {
    const resourcesStore = useResourcesStore()
    return resourcesStore.getCountries
  })

  return {
    statesByCountryCode,
    countrySettings,
    registrationCountryIds,
    fetchOfferedCountries,
    offeredCountries,
    getCountries,
    getOfferedCountries,
    getStatesByCountryCode,
    getCountryByIsoCode,
    getCountryStateById,
    getOfferedCountryByIsoCode,
    getCountryConfigById,
    getCountryDefaultCurrency,
    getAvailableLanguages,
    getAvailableLanguagesWithLabels,
    getShortAvailableLanguagesWithLabel,
    offeredCountriesDialingCodesOptions,
    getAllOfferedCountries,
    setOfferedCountries,
  }
})
