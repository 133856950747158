import { DeliveryMethod, Recipient } from '@galileo/models/Recipient/interfaces'
import { DeliveryMethodAgent, DeliveryMethodType, Quote, Warning } from '@galileo/models/SendMoney/interfaces'


export class FormBase {
  amountFixed = 100
  currencyFixed = ''

  countryTo = ''
  amountTo = 0
  currencyTo = ''
  amountFrom = 0
  currencyFrom = ''

  paymentMethod = ''
  paymentMethodId: Nullable<string> = null

  deliveryMethod = ''
  //if during send again fixedccy != from currencyFrom then we set  should calc amount from to true
  shouldCalcAmountFrom = false

  deliveryMethodAgentId?: number
  deliveryMethodLocationId?: number
  nextPath = ''
}


export class DefaultFormState {
  transferId: string | null = null
  blackbox = ''
  countryFrom = ''
  countryTo = ''
  amountFrom = 0
  amountTo = 0
  currencyFrom = ''
  currencyTo = ''
  isApexx = false
  paymentMethod = ''
  paymentMethodId?: string | number | null = null
  paymentMethodTitle?: string = ''
  paymentMethodCaption?: string = ''
  paymentMethodText?: string = ''
  paymentMethodNumber?: string | null = null
  deliveryMethod: DeliveryMethod | null = null
  deliveryMethodLocation: any | null = null
  deliveryMethodAgent: DeliveryMethodAgent | null = null
  deliveryMethodText: string | null = null
  cardSecurityCode: string | null = null
  recipient: Recipient | null = null
  transferReason: string | null = null
  transferReasonText: string | null = null
  transferReference: boolean | null = null
  transferReferenceText: string | null = null
  sourceOfFunds: Array<any> | null = null
  bankId = ''
  login: any = null
  transactionId = ''
  shouldCalcAmountFrom = false
  rate = 0
  quoteId = ''
  expiryTime = ''
  isOpenPaymentCountry = false
  fee = 0
  delivery = ''
  taxAmount = 0
  totalAmount = 0
  paymentMethods?: Array<DeliveryMethodType> | null = null
  deliveryMethods?: Array<DeliveryMethodType> | null = null
  tempDeliveryMethods?: Array<DeliveryMethodType> | null = null
  createdOrderNumber = ''
  createdExternalPaymentUrl = ''
  createdPin = ''
  cardAuthenticationResult: any = null
  cardTransactionId = ''
  sessionId: string | null = null
  confirmedRate: number | null = null
  splitCurrencies: Array<any> | null = null //TODO - TYPE
  isValidPromo: boolean | null
  feeDiscount: number | null = null
  lastPaymentMethod = ''
  lastDeliveryMethod: DeliveryMethod | null = null
  sendAgain: boolean | null = false
  agentCity: string | null = null
  availableQuotes: Array<Quote> | null = null
  selectedQuote: Quote | null = null
  lastUpdate: Date | null = null
  filledAdditionalFields: Record<string, any> | null = null //TODO - TYPE
  requireAdditionalVerification: boolean | null
  uploadDocId: string | null = null
  quoteErrorFrom: string | null = null
  quoteErrorTo: string | null = null
  warnings: Array<Warning> | null = null
  paymentMethodType: string | null = null
  isFromRecipient: boolean | null = false
  contractId: string | null = null
  riaCorrespondentLocId: string | null = null
  riaCorrespondentId: string | null = null
  mobileWalletAdditionalFields: Array<any> | null = null //TODO - TYPE
  sendAmountUSD = 0
  amountFee = 0
  amountPromoBonus = 0
  similarQuote: { errorFrom: string; errorTo: string } | null = null
  currenciesFrom: { value: string; text: string }[] | null = null
  shouldDisplayRateChangedAlert = false
  shouldCheckForRateChange = false
  currenciesTo: string[] | null = null
  promotionCodes: Array<string> = []
  fixedAmountInUsd = 0
  transferType = ''

  constructor() {
    this.shouldCalcAmountFrom = false
    this.isOpenPaymentCountry = false
    this.sendAgain = false
    this.requireAdditionalVerification = false
    this.isFromRecipient = false
    this.isValidPromo = false
    this.amountFee = 0
    this.amountPromoBonus = 0
    this.sendAmountUSD = 0
    this.shouldDisplayRateChangedAlert = false
    this.shouldCheckForRateChange = false
    this.transferId = null
    this.blackbox = ''
    this.countryFrom = ''
    this.countryTo = ''
    this.amountFrom = 0
    this.amountTo = 0
    this.currencyFrom = ''
    this.currencyTo = ''
    this.isApexx = false
    this.paymentMethod = ''
    this.paymentMethodId = null
    this.paymentMethodTitle = ''
    this.paymentMethodCaption = ''
    this.paymentMethodText = ''
    this.paymentMethodNumber = null
    this.deliveryMethod = null
    this.deliveryMethodLocation = null
    this.deliveryMethodAgent = null
    this.deliveryMethodText = null
    this.cardSecurityCode = null
    this.recipient = null
    this.transferReason = null
    this.transferReasonText = null
    this.transferReference = null
    this.transferReferenceText = null
    this.sourceOfFunds = null
    this.bankId = ''
    this.transactionId = ''
    this.shouldCalcAmountFrom = false
    this.rate = 0
    this.quoteId = ''
    this.expiryTime = ''
    this.isOpenPaymentCountry = false
    this.fee = 0
    this.delivery = ''
    this.taxAmount = 0
    this.totalAmount = 0
    this.paymentMethods = []
    this.deliveryMethods = []
    this.tempDeliveryMethods = []
    this.createdOrderNumber = ''
    this.createdExternalPaymentUrl = ''
    this.createdPin = ''
    this.cardAuthenticationResult = null
    this.cardTransactionId = ''
    this.sessionId = null
    this.confirmedRate = null
    this.splitCurrencies = null //TODO - TYPE
    this.isValidPromo = null
    this.feeDiscount = null
    this.lastPaymentMethod = ''
    this.lastDeliveryMethod = null
    this.sendAgain = false
    this.agentCity = null
    this.availableQuotes = null
    this.selectedQuote = null
    this.lastUpdate = null
    this.filledAdditionalFields = null //TODO - TYPE
    this.requireAdditionalVerification = null
    this.uploadDocId = null
    this.quoteErrorFrom = null
    this.quoteErrorTo = null
    this.warnings = null
    this.paymentMethodType = null
    this.isFromRecipient = false
    this.contractId = null
    this.riaCorrespondentLocId = null
    this.riaCorrespondentId = null
    this.mobileWalletAdditionalFields = null //TODO - TYPE
    this.sendAmountUSD = 0
    this.amountFee = 0
    this.amountPromoBonus = 0
    this.similarQuote = null
    this.currenciesFrom = null
    this.shouldDisplayRateChangedAlert = false
    this.shouldCheckForRateChange = false
    this.currenciesTo = null
    this.promotionCodes = []
    this.fixedAmountInUsd = 0
    this.transferType = ''
  }
}
