import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/LaunchpadAPIHandler'

export default new APIHandler(
  API,
  (profileId) => {
    return {
      method: 'GET',
      url: `profiles/${profileId}/homepagemessage`,
    }
  },
  (response) => {
    return {
      ...response,
      data: response.data,
    }
  }
)
