import { getAllInfoByISO, getAllISOByCurrencyOrSymbol, getParamByParam } from 'iso-country-currency'

export const getAllInfoByCountry = (value) => {
  let result = getAllInfoByISO(value)
  switch (value) {
    case 'HR':
      result = Object.assign(result, { currency: 'EUR', symbol: '€' })
      break
    case 'SL':
      result = Object.assign(result, { currency: 'SLE' })
      break
    case 'GI':
      result = Object.assign(result, { currency: 'GBP', symbol: '£' })
  }
  return result
}

export const getAllISOByCurrency = (currency) => {
  if (currency === 'SLE') {
    currency = 'SLL'
  }
  return getAllISOByCurrencyOrSymbol('currency', currency)
}
export const getSymbolByCurrency = (currency) => {
  const symbol = getParamByParam('currency', currency, 'symbol')

  return symbol
}

export const getPromoSymbolByCurrency = (currency) => {
  const symbol = getParamByParam('currency', currency, 'symbol')
  if (symbol.length > 1 && symbol.includes('$')) {
    return '$'
  }

  return symbol
}
