import { DeliveryMethod, DELIVERY_PROVIDERS } from '@galileo/models/Transaction/app'
import { toLocaleDateString } from '@galileo/utilities/date-convertor.utility'
import { TransactionData } from './interfaces/Transaction'

import useDeriveTransactionStatus from '@galileo/composables/useDeriveTransactionStatus'
import { DeliveryMethodInterface, TransactionListItem } from '../Activity/interfaces/Transactions'
import { Transaction } from '../SendMoney/interfaces/TransactionDetails'
import { useI18nStore } from '@galileo/stores'

export default class TransactionResult extends Transaction implements TransactionListItem {
  actionsRequiredType: string
  id: string
  orderNumber: string
  paymentMethod: string
  amountTo: number
  amountFrom: number
  currencyFrom: string
  currencyTo: string
  recipientFirstName: string
  recipientLastName: string
  recipientFullName: string
  recipientAddressCity: string
  recipientAddressState: string
  transactionActivityStatus: string
  transactionDetailStatus: string
  date: Date
  dateCreated: Date
  iconComponent: string
  relativeDate: string
  isActionRequired: boolean
  launchpadTransferId: string
  deliveryMethod: DeliveryMethodInterface
  statusKey: string

  button: string
  status: string
  continueOrder: boolean = false
  actionsRequired: string
  deliveryMethodName: string
  paymentMethodName: string

  constructor(data: TransactionData) {
    super()
    this.actionsRequiredType = data.actionsRequired
    this.actionsRequired = this.actionsRequiredType

    this.id = data.contractReference
    this.orderNumber = data.contractReference
    this.paymentMethod = data.paymentMethod
    this.paymentMethodName = this.paymentMethod

    this.amountTo = data.receiveAmount
    this.amountFrom = data.sellAmount
    this.currencyFrom = data.sellCcyCode
    this.currencyTo = data.receiveCurrency
    this.recipientFirstName = data.recipientFirstName ?? ''
    this.recipientLastName = data.recipientLastName ?? ''
    this.deliveryMethod = this.mapDeliveryMethod(data.deliveryProvider)

    this.deliveryMethodName = this.deliveryMethod.enumName

    this.recipientFullName = this.buildFullName(data.recipientAccountName)
    this.recipientAddressCity = data.recipientAddressCity
    this.recipientAddressState = data.recipientAddressState
    this.date = data.valueDate
    this.dateCreated = data.transactionDate
    this.iconComponent = 'IconMoney'
    this.relativeDate = toLocaleDateString(this.dateCreated)
    this.isActionRequired = false
    this.launchpadTransferId = data.launchpadTransferId
    const { determineStatus } = useDeriveTransactionStatus()

    const { statusKey, actionRequired, transactionDetailStatus, transactionActivityStatus } =
      determineStatus(data)

    this.isActionRequired = actionRequired
    this.statusKey = statusKey
    this.transactionDetailStatus = transactionDetailStatus
    this.transactionActivityStatus = transactionActivityStatus
    this.button = ''
    this.status = ''
  }

  // Define and map delivery method
  private mapDeliveryMethod(deliveryProvider: string) {
    switch (deliveryProvider) {
      case DELIVERY_PROVIDERS.CASH_PAYOUT:
        return DeliveryMethod.CashPickup
      case DELIVERY_PROVIDERS.MOBILE_WALLET:
        return DeliveryMethod.MobileWallet
      case DELIVERY_PROVIDERS.BALANCE:
        return DeliveryMethod.FundsOnBalance
      default:
        return DeliveryMethod.BankDeposit
    }
  }

  // Name split
  private buildFullName(accountName: string | undefined): string {
    const { $t } = useI18nStore()
    if (this.deliveryMethod === DeliveryMethod.FundsOnBalance) {
      return $t('ComponentBalancesOverviewItem.BalanceTitle', {
        currency: this.currencyTo,
      }).value
    }
    if (!accountName) {
      // If accountName is not provided, return concatenated first and last names
      return `${this.recipientFirstName} ${this.recipientLastName}`.trim()
    } else {
      // Split the full name into separate parts
      const names = accountName.split(' ')

      // If first or last name is missing, assign them based on the split names
      if (!this.recipientFirstName) {
        this.recipientFirstName = names[0]
      }

      if (!this.recipientLastName && names.length > 1) {
        this.recipientLastName = names[names.length - 1]
      }

      // Return the accountName
      return accountName
    }
  }
}
