import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'
import XeRecipientField from '@galileo/models/DynamicField/XeRecipientField'

export default new APIHandler(
  API,
  (config) => {
    return {
      ...config,
      method: 'GET',
      url: `/CashRecipients/fields`,
    }
  },
  (response) => {
    if (!response.data) {
      return response
    }
    return {
      ...response,
      data: response.data.map((field) => new XeRecipientField(field)),
    }
  }
)
