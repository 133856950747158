import API from '@galileo/api/launchpad'
import APIHandler from '@galileo/api/APIHandler'
import State from '@galileo/models/State/app'

export default new APIHandler(
  API,
  (country, config) => {
    return {
      ...config,
      method: 'GET',
      url: `Destinations/countries/${country}/states`,
    }
  },
  (response) => {
    if (response.data) {
      return {
        ...response,
        data: response.data.map((state) => {
          return new State(state)
        }),
      }
    }

    return {
      data: [],
    }
  }
)
