/**
 * enum for all preference groups
 */
export enum PreferenceGroup {
  ScheduledPayments = 'ScheduledPayments'
}

/**
 * api model for a single preference within a preference group
 */
export interface ApiPreference {
  key: string
  value: string
}

/**
 * api model for a preference group
 */
export interface ApiPreferenceGroup {
  group: string
  preferences: ApiPreference[]
}